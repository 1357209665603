import Vue from 'vue';
import moment from 'moment';

// 数字を3桁ごとにカンマ区切りにする
Vue.filter('separate', function(value) {
  if (value == 0) return 0;
  return String(value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
});

// Momentの日付フォーマット変換
Vue.filter('moment', function(value, format) {
  return moment(value).format(format);
});
