<template lang="pug">
.content-wrapper(v-if="Object.keys(network).length > 0")
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | {{ $t('admin.coOccurrenceNetworks.new.title') }}
  .row.flex-grow
    .col-10
      .card
        .card-body.vc(v-if="errorMessages.length > 0 || message")
          .row
            .col-12
              error-message(:errors="errorMessages" v-show="errorMessages.length > 0")
              ul.alert.alert-success(v-show="message")
                button.close.vc-message-close(@click="resetMessages")
                  span(aria-hidden='true') ×
                li.ml-1
                  | {{ message }}
        .card-body.vc
          .row
            .col-6(v-if="(network.nodes && network.nodes.length > 0) && (network.edges && network.edges.length > 0)")
              #network
              .network-conditions.small
                .row
                  .col-3
                    | ストップワード：
                  .col-9
                    | {{ network.stop_words }}
                .row
                  .col-3
                    | 必須ワード ：
                  .col-9
                    | {{ network.required_words }}
                .row
                  .col-3
                    | 最小出現数 ：
                  .col-9
                    | {{ network.minimum_num }}
                .row
                  .col-3
                    | 共起関係の係数：
                  .col-9
                    | {{ network.coefficient }}
              .download-image.text-right
                a.link(href="" @click.prevent="modalOpen")
                  | 条件付き画像ダウンロード
            .col-6(v-else)
              .vc-content-main-wrap
                .row.align-items-center.mb-1
                  .col-12
                    p 共起ネットワーク図の生成後表示できます。
            .col-6
              .row
                .col-12
                  p.mb-0 ストップワード（カンマ区切りで入力）
                  small ネットワーク図に表示させないワードを指定できます。
                  textarea.form-control.mb-2(
                    placeholder="ストップワードを入力してください"
                    rows="2"
                    v-model="stopWords"
                  )
                .col-12
                  p.mb-0 必須ワード（カンマ区切りで入力）
                  small ネットワーク図に必ず表示させたいワードを指定できます。
                  textarea.form-control.mb-2(
                    placeholder="必須ワードを入力してください"
                    rows="2"
                    v-model="requiredWords"
                  )
                .col-12
                  p.mb-0 最小出現数（1以上の整数で入力）
                  small 最低何回からネットワーク図に単語が表示されるようにするか指定できます。
                  input.form-control.mb-2(
                    type="text"
                    placeholder="最小出現数を入力してください"
                    v-model="minimumNum"
                  )
                .col-12
                  p.mb-0 共起関係の係数（0.10〜1.00までの少数で入力）
                  small どれくらい単語と単語の関係性が強いとネットワークとするかに影響します。（小数点第2位以下も指定できます）
                  input.form-control.mb-2(
                    type="text"
                    placeholder="共起関係の係数を入力してください"
                    v-model="coefficient"
                  )
              .row.mt-3
                .col-12.text-right
                  a.cc-primary-btn.cc-inline-btn.action-btns(href="" @click.prevent="request") データをアップして共起ネットワーク図生成
    ModalDownloadImage(ref="modalDownloadImage")
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { Network } from 'vis-network/dist/vis-network.min.js';
import ErrorMessage from 'src/components/ErrorMessage';
import i18n from '../../../locales/index';
import ModalDownloadImage from '../../../components/x/co_occurrence_networks/ModalDownloadImage';

export default {
  components: {
    ErrorMessage,
    ModalDownloadImage
  },
  data() {
    return {
      processing: false,
      stopWords: '',
      requiredWords: '',
      minimumNum: '',
      coefficient: '',
      options: {},
      download: false,
      errorMessages: [],
      message: ''
    };
  },
  computed: {
    ...mapState('resources/x_co_occurrence_networks', ['network'])
  },
  created() {
    this.load();
  },
  mounted() {},
  methods: {
    ...mapActions('resources/x_co_occurrence_networks', [
      'loadGenerateImage',
      'requestCoOccurrence'
    ]),
    load() {
      const id = this.$route.params.id;
      this.loadGenerateImage(id).then(response => {
        const network = response.body.network;
        if (network.nodes.length > 0 && network.edges.length > 0) {
          this.draw();
        }
        this.stopWords = network.stop_words;
        this.requiredWords = network.required_words;
        this.minimumNum = network.minimum_num;
        this.coefficient = network.coefficient;
      });
    },
    request() {
      this.resetMessages();
      const params = {
        id: this.$route.params.id,
        stop_words: this.stopWords,
        required_words: this.requiredWords,
        minimum_num: this.minimumNum,
        coefficient: this.coefficient
      };
      this.requestCoOccurrence(params).then(response => {
        if (response.status === 200) {
          this.message = i18n.tc('admin.surveys.messages.processing');
        } else if (response.status === 422) {
          response.json().then(body => {
            this.errorMessages = body.errors;
          });
        }
      });
    },
    draw() {
      let container = document.getElementById('network');
      let data = {
        nodes: this.network.nodes,
        edges: this.network.edges
      };
      new Network(container, data, this.options);
    },
    resetMessages() {
      this.errorMessages = [];
      this.message = '';
    },
    modalOpen() {
      this.$refs.modalDownloadImage.modalOpen(this.network);
    }
  }
};
</script>
<style scoped>
.action-btns {
  text-align: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.link {
  color: #4bafaf;
  font-size: 0.85rem;
}
.link:hover {
  color: #4bafaf;
}
.transition-link {
  font-size: 1rem;
}
.transition-link-wrap {
  margin-top: 70px;
}
.disable-link {
  cursor: not-allowed;
}
textarea {
  background-color: #fff !important;
}
#network {
  width: 100%;
  height: 450px;
  border: 1px solid lightgray;
  background-color: white;
}
.vis-network {
  position: relative;
  overflow: hidden;
  touch-action: pan-y;
  user-select: none;
  width: 100%;
  height: 100%;
}
</style>
