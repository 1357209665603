<template lang="pug">
  .content-wrapper
    .content-header.d-flex.flex-column.flex-md-row
      h4.mb-0
        | Dashboard
    .row
      .col-12.grid-margin
        .card
          .card-body
            label 日付で検索
            form
              .row
                .col-3
                  .form-group
                    input.form-control(
                      type="date"
                      :placeholder="Date.now()"
                      v-model="startAt"
                    )
                .mt-1
                  | 〜
                .col-3
                  .form-group
                    input.form-control(
                      type="date"
                      :placeholder="Date.now()"
                      v-model="endAt"
                    )
                .col-3
                  button.btn.btn-primary(type="button" @click.prevent="search()") 検索

        .card
          .card-body
            h5 操作ログ
            .row(v-for="activity in activities")
              .col-12.log-content
                p
                  | {{ activity.created_at }}
                  br
                  | {{ $t('admin.publicActivities.'+activity.key, textParams(activity)) }}
            .mt-1.float-right
              paginate(
                :page-count="pageParams.pages"
                :click-handler="clickCallback"
                containerClass="pagination"
                pageClass="page-item"
                pageLinkClass="page-link"
                prevText="«"
                prevClass="page-item"
                prevLinkClass="page-link"
                nextText="»"
                nextClass="page-item"
                nextLinkClass="page-link"
              )

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Paginate from 'vuejs-paginate';

export default {
  components: {
    Paginate
  },

  data() {
    return {
      defaultPer: 10,
      startAt: '',
      endAt: ''
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/x_dashboard', ['activities']),
    ...mapState('pages/x_dashboard/list/pagination', ['pageParams'])
  },

  created() {
    this.setPer(this.defaultPer);
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_dashboard', ['loadPublicActivities']),
    ...mapMutations('pages/x_dashboard/list/pagination', [
      'setTotal',
      'setPages',
      'setPage',
      'setPer'
    ]),
    load() {
      let dateParams = {
        start_at: this.startAt,
        end_at: this.endAt
      };
      const params = Object.assign(dateParams, this.pageParams);
      this.loadPublicActivities(params).then(response => {
        this.setTotal(response.body.page_options.total);
        this.setPages(response.body.page_options.pages);
      });
    },
    clickCallback: function(pageNum) {
      this.setPage(pageNum);
      this.load();
    },
    textParams(activity) {
      const params = {
        owner: activity.owner,
        trackable: activity.trackable,
        prefix: activity.prefix,
        changed: activity.changed,
        actor: activity.actor
      };
      return params;
    },
    search() {
      this.setPage(1);
      this.load();
    }
  }
};
</script>
<style scoped>
.log-content {
  border-bottom: 1px solid #f0f0f0;
  margin-top: 1rem;
}
</style>
