<template lang="pug">
  #left-filter
    p.vc-left-col-title
    .vc-cat
      ul.vc-parameter-nav
        li(v-for="(level, i) in orgLevels")
          a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': orgHierarchy === i+1}" @click="setOrgHierarchy(i+1)" v-show="level")
            | {{level}}
      .row.mt-3
        .col-6
          button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="allSelect") すべて選択
        .col-6
          button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="clearSelect") 選択解除
    .vc-cat
      ul
        li
          ul.organization-ul
            li(v-if="pageName === 'matrix'")
              .custom-control.custom-checkbox.vc-custom-checkbox
                input#organization-all(
                  type="checkbox"
                  class="custom-control-input"
                  :value="'all'"
                  v-model="currentSelected"
                  @click="toggleSelectedOrganizations('all')"
                )
                label(
                  class="custom-control-label"
                  for="organization-all"
                )
                  | 全社
            li(v-for="(organization, i) in navOrganizations")
              .custom-control.custom-checkbox.vc-custom-checkbox
                input(
                  type="checkbox"
                  class="custom-control-input"
                  :id="'organization'+organization.id"
                  :value="organization.id"
                  v-model="currentSelected"
                  @click="toggleSelectedOrganizations(organization.id)"
                  :disabled="orgHierarchy === 3"
                )
                label(
                  class="custom-control-label"
                  :for="'organization'+organization.id"
                )
                  | {{organization.name}}
                span.float-right
                  img.organizations-hide-btn.ml-2(
                    src="../../../assets/images/excheck/chevron-down.png" width="18"
                    :class="{'reverse': displayOrganizations[organization.id]}"
                    @click.prevent="toggleDisplayOrganizations(organization.id)"
                  )
              ul.organization-ul(v-show="displayOrganizations[organization.id]")
                li(v-for="(childOrg, ii) in organization.children")
                  .custom-control.custom-checkbox.vc-custom-checkbox
                    input(
                      type="checkbox"
                      class="custom-control-input"
                      :id="'organization'+childOrg.id"
                      :value="childOrg.id"
                      v-model="currentSelected"
                      :disabled="orgHierarchy === 1"
                    )
                    label(
                      class="custom-control-label"
                      :for="'organization'+childOrg.id"
                    )
                      | {{childOrg.name}}
                    span.float-right.second-arrow
                      img.organizations-hide-btn.ml-2(
                        src="../../../assets/images/excheck/chevron-down.png" width="18"
                        :class="{'reverse': displayOrganizations[childOrg.id]}"
                        @click.prevent="toggleDisplayOrganizations(childOrg.id)"
                      )
                  ul.organization-ul(v-show="displayOrganizations[childOrg.id]")
                    li(v-for="(grandChildOrg, ii) in childOrg.children")
                      .custom-control.custom-checkbox.vc-custom-checkbox
                        input(
                          type="checkbox"
                          class="custom-control-input"
                          :id="'organization'+grandChildOrg.id"
                          :value="grandChildOrg.id"
                          v-model="currentSelected"
                          :disabled="orgHierarchy !== 3"
                        )
                        label(
                          class="custom-control-label"
                          :for="'organization'+grandChildOrg.id"
                        )
                          | {{grandChildOrg.name}}

</template>
<script>
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';

export default {
  components: {},

  props: {
    pageName: {
      type: String,
      require: true,
      default: ''
    }
  },

  data() {
    return {
      currentSelected: [],
      selectAll: true,
      displayOrganizations: {},
      organizationIds: []
    };
  },

  computed: {
    ...mapState('resources/u_quantitative', [
      'navOrganizations',
      'displayAttributeValues',
      'orgLevels',
      'orgHierarchy'
    ])
  },

  watch: {
    currentSelected() {
      if (this.currentSelected.length > 0)
        this.setOrganizationIds(this.currentSelected);
      else this.setOrganizationIds([]);
    },
    navOrganizations() {
      if (this.navOrganizations.length > 0) this.setDefaultOrgHierarchy();
    }
  },

  created() {
    if (this.navOrganizations.length > 0) this.setDefaultOrgHierarchy();
  },

  mounted() {},

  methods: {
    ...mapMutations('resources/u_quantitative', [
      'setOrganizationIds',
      'setOrganizationHierarchy'
    ]),
    allSelect() {
      this.selectAll = true;
      const orgs = this.navOrganizations;
      let selected = [];
      for (let i in orgs) {
        const children = orgs[i].children;
        if ([1, 2].includes(this.orgHierarchy)) selected.push(orgs[i].id);
        for (let ii in children) {
          const grandChildren = children[ii].children;
          if ([2, 3].includes(this.orgHierarchy))
            selected.push(children[ii].id);
          for (let iii in grandChildren) {
            if (this.orgHierarchy === 3) selected.push(grandChildren[iii].id);
          }
        }
      }
      selected.unshift('all');
      this.currentSelected = selected.sort();
    },
    clearSelect() {
      this.selectAll = false;
      this.currentSelected = [];
    },
    selectWithHierarchy(hierarchy) {
      const orgs = this.navOrganizations;
      let selected = [];
      for (let i in orgs) {
        const children = orgs[i].children;
        const isFirstHierarchy = orgs[i].organization_hierarchy === hierarchy;
        if (isFirstHierarchy || hierarchy === 2) selected.push(orgs[i].id);
        for (let ii in children) {
          const grandChildren = children[ii].children;
          const isSecondHierarchy =
            children[ii].organization_hierarchy === hierarchy;
          if (isSecondHierarchy || hierarchy === 3)
            selected.push(children[ii].id);
          for (let iii in grandChildren) {
            if (grandChildren[iii].organization_hierarchy === hierarchy)
              selected.push(grandChildren[iii].id);
          }
        }
      }
      selected.unshift('all');
      this.currentSelected = selected.sort();
    },
    toggleDisplayOrganizations(organizationId) {
      Vue.set(
        this.displayOrganizations,
        organizationId,
        !this.displayOrganizations[organizationId]
      );
    },
    openDisplayOrganizations(hierarchy) {
      for (let i = 0; i < this.navOrganizations.length; i++) {
        const org = this.navOrganizations[i];
        const children = org.children;
        Vue.set(
          this.displayOrganizations,
          org.id,
          [2, 3].includes(hierarchy) ? true : false
        );
        for (let ii in children) {
          Vue.set(
            this.displayOrganizations,
            children[ii].id,
            hierarchy === 3 ? true : false
          );
        }
      }
    },
    toggleSelectedOrganizations(organizationId) {
      let ids = [];
      if (organizationId !== 'all') {
        const parent = this.navOrganizations.find(
          org => org.id === organizationId
        );
        const children = parent.children;
        ids = children.map(org => org.id);
      }
      ids.push(organizationId);
      if (this.currentSelected.includes(organizationId))
        ids.forEach(
          id =>
            (this.currentSelected = this.currentSelected.filter(
              cId => cId !== id
            ))
        );
      else {
        const arr = this.currentSelected.concat(ids);
        this.currentSelected = arr;
      }
    },
    setOrgHierarchy(hierarchy) {
      // this.orgHierarchy = hierarchy;
      this.selectWithHierarchy(hierarchy);
      this.openDisplayOrganizations(hierarchy);
      this.setOrganizationHierarchy(hierarchy);
    },
    toggleSelectAll() {
      this.selectAll = !this.selectAll;
      this.selectAll ? this.allSelect() : this.clearSelect();
    },
    setDefaultOrgHierarchy() {
      let childrenSize = 0;
      this.navOrganizations.forEach(org => {
        this.organizationIds.push(org.id);
        Vue.set(this.displayOrganizations, org.id, false);
        childrenSize += org.children.length;
        if (childrenSize > 0) this.setOrgHierarchy(2);
        else this.setOrgHierarchy(1);
        org.children.forEach(child => {
          this.organizationIds.push(child.id);
          Vue.set(this.displayOrganizations, child.id, false);
          child.children.forEach(grand => {
            this.organizationIds.push(grand.id);
          });
        });
      });
    }
  }
};
</script>
<style scoped>
.organization-ul {
  margin-left: 1rem;
}
#left-filter .attribute-values-btn {
  cursor: pointer;
  color: #444 !important;
}
#left-filter .attribute-values-btn:hover {
  color: #444 !important;
}
.organizations-hide-btn {
  cursor: pointer;
}
.custom-control-label {
  width: 82%;
}
.vc .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #f3154a !important;
  background: #f3154a !important;
}
span.second-arrow {
  position: absolute;
}
</style>
