<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content#aboutData
        .container-fluid.vc-dashboard-sub-select-wrap
          .row
            .col-12
              .vc-top-tab.container.pb-0.pt-4
                a.nav-link.vc-midashi(href="#" v-scroll-to="'#ad1'")
                  | データについて
                a.nav-link.vc-midashi(href="#" v-scroll-to="'#ad2'")
                  | データの見方
                a.nav-link.vc-midashi(href="#" v-scroll-to="'#ad3'")
                  | 分類の定義
        .container.d-block
          #ad1.pt-4
            .vc-content-col
              .container
                .row
                  .col-12
                    h3.vc-midashi.mb-4
                      | データについて
                hr.vc-full-divider
                .row.mb-4
                  .col-12
                    table.table.table-borderless
                      thead
                        tr
                          th(width='250px')
                            | 調査/調査期間
                          th
                            | 質問文
                          //- th.text-center.text-nowrap.text-gray
                          //-   | 対象者数（参考）
                          th.text-center.text-nowrap
                            | 回答者数
                          th.text-center.text-nowrap
                            | 意見数
                          th(width='150px')
                      tbody(v-for="survey in surveys")
                        tr(
                          v-for="question in survey.questions"
                          v-bind:value="question.id"
                          v-bind:key="question.id"
                        )
                          td
                            .topic-mark(v-if="survey.is_topic")
                              | TOPIC
                            h4.survey-tit(v-show="survey.questions[0] === question")
                              | {{survey.name}}
                            p.mb-0(v-show="survey.questions[0] === question")
                              | {{survey.start_at | moment('YYYY.MM.DD')}} - {{survey.end_at | moment('YYYY.MM.DD')}}
                          td.question-text
                            | {{question.question_text}}
                          //- td.count-num.text-center.text-gray
                          //-   | {{question.target_count | separate}}
                          td.count-num.text-center
                            | {{question.qualitative_responses_count | separate}}
                          td.count-num.text-center
                            | {{question.opinions_count | separate}}
                          td
                            button.btn-analyse.btn.btn-primary.vc-primary-btn.mb-1(
                              @click.prevent="toTeamAnalyse(survey, question)"
                              )
                              | 分析する
                            button.btn-analyse.btn.btn-primary.vc-primary-btn(
                              @click.prevent="modalOpen(survey)"
                              )
                              | フォルダ
          #ad2.pt-5
            .vc-content-col
              .container
                .row
                  .col-12
                    h3.vc-midashi.mb-4
                      | データの見方
                hr.vc-full-divider
                .row
                  .col-12
                    h4.mt-2
                      | 1. 回答者数や意見数について
                    p
                      | 回答者数とはアンケートの回答者の数のことです。
                    p
                      | このサービスでは、この回答者の回答を「文章に分割」して、分析しています。
                      span.font-weight-bold
                        | 回答を文章単位にしたものを「意見」
                      | としております。
                      br
                      span.font-weight-bold
                        | これを集計したものが意見数
                      | となります。
                    img.pb-1(src="../../assets/images/excheck/vc-how-to-1.png" width="715")
                    h4.mt-4
                      | 2. 意見の分類の種類とAIによる分類について
                    p
                      | このサービスでは、意見を分類して分析できるようにしています。
                      br
                      | 例えば、ネガティブな意見かポジティブな意見かを分類しています。これを
                      span.font-weight-bold
                        | 感情分類
                      | と呼びます。
                      br
                      | 例えば、上司に関する意見なのか、給与に関する意見なのかなどの「何について」の意見なのかも分類しています。
                      br
                      | これを
                      span.font-weight-bold
                        | 意見分類
                      | と呼びます。
                    p
                      | これらの分類は、
                      span.font-weight-bold
                        | 意見に対してAIが自動で判別し、分類しています。
                      br
                      span.font-weight-bold
                        | AIは段々と学習していくため、分類の正しさも段々と向上していくことをご了承ください。
                    img.pb-1(src="../../assets/images/excheck/vc-how-to-2.png" width="715")
                    h4.mt-4
                      | 3. 意見単位で分類する理由と注意点
                    p
                      | 「会社の雰囲気は良いと思う。ただ人手不足が改善されない。」というように１つの回答の中で
                      br
                      | ポジティブ、ネガティブの両方の意見が入っていたり、会社の雰囲気に関すること、人手・仕事量に関する２つの意見分類が含まれることがあります。
                      br
                      | そのため、
                      span.font-weight-bold
                        | 回答の内容全体ではなく、意見（文章）ごとに分類を判別する仕組みにしています。
                    p
                      | 注意点としては、5人のチーム内で1人の回答者が、例えば10や20のネガティブな意見（文）を書き、他の4人がそれぞれ1つのポジティブな意見を書くと
                      br
                      | チーム全体としてはネガティブな意見が多いチームに見えてしまいます。
                    p.font-weight-bold.text-danger
                      | そのため、集計結果を重視するのではなく、実際の意見の内容を必ず見るようにしてください。
          #ad3.pt-5.pb-5
            .vc-content-col
              .container
                .row
                  .col-12
                    h3.vc-midashi.mb-4
                      | 分類の定義
                hr.vc-full-divider
                h4.mb-4
                  | 感情分類
                .row.mt4.mb-4(
                  v-for="ec in emotion_classifications"
                  v-bind:value="ec.id"
                  v-bind:key="ec.id"
                )
                  .col-2.pl-5.font-weight-bold
                    | {{ec.name}}
                  .col-10
                    | {{ec.description}}
                h4.mt-5.mb-4
                  | 意見分類
                .row.mt4.mb-4(
                  v-for="oc in opinion_classifications"
                  v-bind:value="oc.id"
                  v-bind:key="oc.id"
                )
                  .col-2.pl-5.font-weight-bold
                    | {{oc.name}}
                  .col-10
                    | {{oc.description}}

    ModalUploadReports(ref="modalUploadReports")

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ModalUploadReports from '../../components/u/aboutData/ModalUploadReports';
import dc from '../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    ModalUploadReports
  },

  data() {
    return {};
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_about_data', [
      'surveys',
      'emotion_classifications',
      'opinion_classifications'
    ]),
    ...mapState('resources/u_control_settings', ['controlSettings'])
  },

  watch: {},

  created() {
    this.loadAboutData();
    this.loadControlSettings();
  },

  mounted() {
    const hash = this.$route.hash;
    if (hash && hash.match(/^#.+$/)) {
      setTimeout(() => {
        this.$scrollTo(hash);
      }, 300);
    }
  },

  methods: {
    ...mapActions('resources/u_about_data', ['loadAboutData']),
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapMutations('pages/u_surveys/select', ['setSurveyId', 'setIsTopic']),
    ...mapMutations('pages/u_organizations/select', ['setSelect']),
    ...mapMutations('resources/u_quantitative', ['setQuestionId']),
    toTeamAnalyse: function(survey, question) {
      this.setSurveyId(survey.id);
      this.setIsTopic(survey.is_topic);
      this.setSelect({ beforeSelected: [], started: true });
      defaultCookie.set({ questionId: question.id });
      this.setQuestionId(question.id);
      const cs = this.controlSettings.default_qualitative_menu;
      let pageName;
      if (question.question_type === 'company')
        pageName = cs.enabled
          ? 'u_' + cs.values
          : 'u_qualitative_emotion_classifications_team';
      else
        pageName = cs.enabled ? 'u_' + cs.values : 'u_qualitative_text_mining';
      this.$router.push({ name: pageName });
    },
    toTopic: function(survey) {
      this.setSurveyId(survey.id);
      this.setIsTopic(survey.is_topic);
      this.setSelect({ beforeSelected: [], started: true });
      this.$router.push({ name: 'u_topic' });
    },
    modalOpen: function(survey) {
      this.$refs.modalUploadReports.modalOpen(survey);
    }
  }
};
</script>
