<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | {{ $t('admin.surveys.new.title') }}
  .row.flex-grow
    .col-6
      .card
        .card-body
          form(v-if="ready")
            error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
            validation-observer(v-slot="{ invalid }")
              validation-provider(name="name" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.name') }}
                  input(
                    type="text"
                    placeholder="2018年7月クラシエ社インナー施策従業員調査"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="newSurvey.name"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="shortName" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.shortName') }}
                  input(
                    type="text"
                    placeholder="2018年7月従業員調査"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="newSurvey.short_name"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="serialNo" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.serialNo') }}
                  input(
                    type="text"
                    placeholder="1"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="newSurvey.serial_no"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="startAt" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.startAt') }}
                  input(
                    type="date"
                    placeholder="2020/06/03"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="newSurvey.start_at"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="endAt" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.endAt') }}
                  input(
                    type="date"
                    placeholder="2020/06/03"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="newSurvey.end_at"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="accountGroupId" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.accountGroup') }}
                  select(
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="newSurvey.account_group_id"
                  )
                    option(
                      v-for="accountGroup in accountGroups"
                      v-bind:value="accountGroup.id"
                      v-bind:key="accountGroup.id"
                    )
                      | {{accountGroup.name}}
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="organizationVersionId" rules="required" v-slot="{ errors }")
                .form-group
                  label
                    | {{ $t('admin.surveys.organizationVersion') }}
                  select.form-control(v-model="newSurvey.organization_version_id")
                    option(
                      v-for="organizationVersion in organizationVersions"
                      v-bind:value="organizationVersion.id"
                      v-bind:key="organizationVersion.id"
                    )
                      | {{organizationVersion.name}}

              validation-provider(name="questionText")
                .form-group
                  label
                    | {{ $t('admin.surveys.questionText') }}
                  textarea.form-control(
                    rows="10"
                    placeholder="質問文を入力してください。"
                    v-model="newSurvey.question_text"
                  )

              validation-provider(name="targetCount" rules="numeric|required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.targetCount') }}
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    placeholder="対象者数を入力してください。"
                    v-model="newSurvey.target_count"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              .mt-1.float-right
                router-link.btn.btn-light.mr-2(:to="{ name: 'x_surveys' }")
                  | {{ $t('actions.cancel') }}
                button.btn.btn-success(
                  :disabled="processing || invalid"
                  @click.prevent="submit"
                )
                  | {{ $t('actions.update') }}
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';

export default {
  components: {
    ErrorMessage
  },

  data() {
    return {
      newSurvey: {},
      errorMessages: [],
      processing: false,
      ready: false
    };
  },

  computed: {
    ...mapState('resources/x_surveys', [
      'accountGroups',
      'survey',
      'organizationVersions'
    ])
  },

  watch: {
    'newSurvey.account_group_id'() {
      this.setOrganizationVersions(this.newSurvey.account_group_id);
    }
  },

  created() {
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_surveys', ['loadEditSurvey', 'updateSurvey']),
    ...mapMutations('resources/x_surveys', ['setOrganizationVersions']),

    load() {
      const params = this.$route.params;
      this.loadEditSurvey(params.id).then(() => {
        this.newSurvey = {
          id: this.survey.id,
          name: this.survey.name,
          short_name: this.survey.short_name,
          serial_no: this.survey.serial_no,
          start_at: this.survey.start_at,
          end_at: this.survey.end_at,
          account_group_id: this.survey.account_group_id,
          organization_version_id: this.survey.organization_version_id,
          question_text: this.survey.question_text,
          target_count: this.survey.target_count
        };
        this.ready = true;
      });
    },

    resetErrorMessages() {
      this.errorMessages = [];
    },

    submit() {
      this.processing = true;
      this.resetErrorMessages();
      let matched = this.organizationVersions.find(
        organizationVersion =>
          organizationVersion.id === this.newSurvey.organization_version_id
      );
      if (!matched) this.newSurvey.organization_version_id = null;
      this.updateSurvey({ id: this.newSurvey.id, survey: this.newSurvey })
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'x_surveys' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
