<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content
        SurveyOrganizationSelect(
          @input="changeSurveyOrganization"
          @changeTargetOrganization="changeTargetOrganization"
          :is-display-button="sourceCount <= getNumberOfDisplayableData() || destinationCount <= getNumberOfDisplayableData()"
        )
        .vc-main-container.container(v-show="!isTopic && sourceCount > getNumberOfDisplayableData() && destinationCount > getNumberOfDisplayableData()")
          .vc-left-col
            AttributeValuesSelect(@input="changeAttributeValueIds" ref="attributeValuesSelect")
          div
            .vc-content-col.mb-3
              TargetOrganizationSelect(v-show="!isTopic" @input="changeTargetOrganization" :organization-id="organizationId")
            .vc-content-col
              OpinionTendenciesUserCompare(
                @modalOpen="modalOpen"
                @modalOpinionCountsOpen="modalOpinionCountsOpen"
                @toOpinionSearch="toOpinionSearch"
                @updateCount="updateCount"
                :survey-id="surveyId"
              )
              TopicUserCompare(:survey-id="surveyId" v-if="isTopic")
        .vc-main-container.container(
          v-if="!isTopic && sourceCount != null && (sourceCount <= getNumberOfDisplayableData() || destinationCount <= getNumberOfDisplayableData())"
          )
          .vc-content-col
            div(v-if="sourceCount <= getNumberOfDisplayableData()")
              | 回答が{{getNumberOfDisplayableData()}}件以下は表示できません。分析対象の組織を選びなおしてください
            div(v-if="destinationCount <= getNumberOfDisplayableData()")
              | 回答が{{getNumberOfDisplayableData()}}件以下は表示できません。比較対象の組織を選びなおしてください
        .vc-main-container.container(v-if="isTopic")
          .vc-content-col
            .container
              | トピック調査については、この画面はご利用できません。調査選択から、トピック調査以外の調査を選択ください。
    ModalOpinionAttrValUserTeam(ref="modalOpinionAttrVal" v-if="!isTopic")
    ModalOpinionCounts(ref="modalOpinionCounts" :position-top="popupPosition.top" :position-left="popupPosition.left")

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SurveyOrganizationSelect from '../../../components/u/SurveyOrganizationSelect';
import TargetOrganizationSelect from '../../../components/u/analyse/TargetOrganizationSelect';
import AttributeValuesSelect from '../../../components/u/analyse/AttributeValuesSelect';
import OpinionTendenciesUserCompare from '../../../components/u/analyse/OpinionTendenciesUserCompare';
import TopicUserCompare from '../../../components/u/analyse/TopicUserCompare';
import ModalOpinionAttrValUserTeam from '../../../components/u/analyse/ModalOpinionAttrValUserTeam';
import ModalOpinionCounts from '../../../components/u/analyse/ModalOpinionCounts';

export default {
  components: {
    SurveyOrganizationSelect,
    TargetOrganizationSelect,
    AttributeValuesSelect,
    OpinionTendenciesUserCompare,
    TopicUserCompare,
    ModalOpinionAttrValUserTeam,
    ModalOpinionCounts
  },

  data() {
    return {
      selectOpinionClass: 'opinion_count',
      surveyId: null,
      isTopic: false,
      organizationId: null,
      targetOrganizationId: null,
      attributeValueIds: [],
      beforeOrganizationId: null,
      beforeTargetOrganizationId: null,
      params: null,
      sourceCount: null,
      destinationCount: 0
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('resources/u_opinion_search', ['keepedAttributeValues']),
    ...mapState('resources/u_analyse_compare', ['popupPosition'])
  },

  watch: {},

  created() {
    this.loadControlSettings();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapActions('resources/u_analyse_compare', [
      'loadOpinionTendencies',
      'loadTopic'
    ]),
    ...mapMutations('resources/u_opinion_search', ['setKeepedAttributeValues']),
    getNumberOfDisplayableData() {
      const name = 'number_of_displayable_data';
      if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
        return this.controlSettings[name]['values']
          ? this.controlSettings[name]['values']
          : 0;
      }
      return 0;
    },
    changeSurveyOrganization: function(datas) {
      this.surveyId = datas.surveyId;
      this.isTopic = datas.isTopic;
      this.organizationId = datas.isTopic ? null : datas.organizationId;
      setTimeout(() => {
        this.surveyId = datas.surveyId;
        this.isTopic = datas.isTopic;
        this.organizationId = datas.isTopic ? null : datas.organizationId;
      }, 200);
    },
    changeTargetOrganization: function(targetOrganizationId, index) {
      this.targetOrganizationId = targetOrganizationId.slice(0, index);
      if (this.keepedAttributeValues.length > 0)
        this.attributeValueIds = this.keepedAttributeValues;
      else {
        this.attributeValueIds = [];
        if (this.$refs.attributeValuesSelect)
          this.$refs.attributeValuesSelect.clearSelect();
      }
      this.updateOpinionTendencies();
    },
    changeAttributeValueIds: function(attributeValueIds) {
      this.attributeValueIds = attributeValueIds;
      this.setKeepedAttributeValues(attributeValueIds);
      this.updateOpinionTendencies();
    },
    updateOpinionTendencies: function() {
      if (!this.surveyId) return;
      let params = {
        survey_id: this.surveyId,
        organization_id: this.organizationId,
        target_organization_id: this.targetOrganizationId,
        attribute_value_ids: this.attributeValueIds
      };
      this.params = params;
      this.loadOpinionTendencies(params);
      this.loadTopic(params);
    },
    modalOpen: function(
      organizationId,
      opinionClassificationId,
      emotionClassificationId
    ) {
      const params = {
        survey_id: this.params.survey_id,
        organization_id: organizationId,
        opinion_classification_id: opinionClassificationId,
        attribute_value_ids: this.params.attribute_value_ids,
        emotion_classification_id: emotionClassificationId
      };
      this.$refs.modalOpinionAttrVal.modalOpen(params);
    },
    toOpinionSearch: function(
      opinionClassificationId,
      emotionClassificationId
    ) {
      this.$router.push({
        name: 'u_opinion_search',
        params: {
          opinion_classification: opinionClassificationId,
          emotion_classification: emotionClassificationId,
          attribute_values: this.attributeValueIds,
          survey_id: this.params.survey_id,
          backToName: 'u_compare_analyse'
        }
      });
    },
    updateCount: function(sourceCount, destinationCount) {
      if (
        this.beforeOrganizationId != this.organizationId ||
        this.beforeTargetOrganizationId != this.targetOrganizationId
      ) {
        this.sourceCount = sourceCount;
        this.destinationCount = destinationCount;
        this.beforeOrganizationId = this.organizationId;
        this.beforeTargetOrganizationId = this.targetOrganizationId;
      }
    },

    modalOpinionCountsOpen: function(
      source,
      destinations,
      selectOpinionCountName,
      selectSurveyResponsenCountName
    ) {
      this.$refs.modalOpinionCounts.modalOpen(
        source,
        destinations,
        selectOpinionCountName,
        selectSurveyResponsenCountName
      );
    }
  }
};
</script>
