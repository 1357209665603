import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_co_occurrences');

export default {
  namespaced: true,
  state: {
    nodes: [],
    edges: []
  },
  actions: {
    loadCoOccurenceNetwork({ commit }, params) {
      return gateway.post({ action: 'create' }, params).then(response => {
        if (response.status === 200) {
          commit('setNodes', response.body.nodes);
          commit('setEdges', response.body.edges);
        }
        return response;
      });
    }
  },

  mutations: {
    setNodes(state, nodes) {
      state.nodes = nodes;
    },
    setEdges(state, edges) {
      state.edges = edges;
    }
  }
};
