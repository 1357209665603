import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_account_groups');

export default {
  namespaced: true,

  state: {
    accountGroups: [],
    organizations: [],
    roles: [],
    accountGroup: {},
    controlSettings: [],
    attributeValues: []
  },

  getters: {},

  actions: {
    /**
     * アカウントグループ一覧取得
     */
    loadAccountGroups({ commit }) {
      return gateway.get({ action: 'index' }).then(response => {
        if (response.status === 200) {
          const accountGroups = response.body.account_groups;
          commit('setAccountGroups', accountGroups);
        }
        return response;
      });
    },
    /**
     * 指定アカウントグループに紐づく組織一覧取得
     */
    loadOrganizations({ commit }, accountGroupId) {
      return gateway
        .get({ action: 'organizations' }, { account_group_id: accountGroupId })
        .then(response => {
          if (response.status === 200) {
            const organizations = response.body.organizations;
            commit('setOrganizations', organizations);
          }
          return response;
        });
    },
    /**
     * 指定アカウントグループに紐づく組織一覧取得
     */
    loadRoles({ commit }, accountGroupId) {
      return gateway
        .get({ action: 'roles' }, { account_group_id: accountGroupId })
        .then(response => {
          if (response.status === 200) {
            const roles = response.body.roles;
            commit('setRoles', roles);
          }
          return response;
        });
    },

    createAccountGroup(_, params) {
      return gateway
        .post(
          {
            action: 'create'
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    editAccountGroup({ commit }, id) {
      return gateway.get({ action: 'edit', id: id }).then(response => {
        if (response.status === 200) {
          const accountGroup = response.body.account_group;
          const controlSettings = response.body.control_settings;
          commit('setAccountGroup', accountGroup);
          commit('setControlSettings', controlSettings);
        }
        return response;
      });
    },

    updateAccountGroup(_, params) {
      return gateway
        .put(
          {
            action: 'update',
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    deleteAccountGroup(_, id) {
      return gateway.delete({ action: 'remove', id }).then(response => {
        return response;
      });
    },

    loadAttributeValues({ commit }, id) {
      return gateway.get({ action: 'attribute_values', id }).then(response => {
        if (response.status === 200) {
          const account_group = response.body.account_group;
          const attribute_values = response.body.attribute_values;
          commit('setAccountGroup', account_group);
          commit('setAttributeValues', attribute_values);
        }
        return response;
      });
    }
  },

  mutations: {
    setAccountGroups(state, accountGroups) {
      state.accountGroups = accountGroups;
    },
    setOrganizations(state, organizations) {
      state.organizations = organizations;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setAccountGroup(state, accountGroup) {
      state.accountGroup = accountGroup;
    },
    setControlSettings(state, controlSettings) {
      state.controlSettings = controlSettings;
    },
    setAttributeValues(state, attributeValues) {
      state.attributeValues = attributeValues;
    }
  }
};
