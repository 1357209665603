import Vue from 'vue';
import VueRouter from 'vue-router';
import admins from './admins';

Vue.use(VueRouter);

const routes = [...admins];

const router = new VueRouter({ base: '/', mode: 'history', routes });

export default router;
