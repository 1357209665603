<template lang="pug">
  div
    modal(
      ref="modalOtherSurveyResponses"
      name="modalOtherSurveyResponses"
      id="modalOtherSurveyResponses"
      :minWidth="600"
      :minHeight="320"
      :width="600"
      :height="320"
      :reset="true"
      :scrollable="true"
      :pivotX="0.9"
      :pivotY="0.9"
    )
      header.modal-header.align-items-end
        h6.modal-title.vc-midashi
          | ID: {{response.responder_code}}
        dl.respondent-num.align-items-center
          dt
            button(type="button" @click="$modal.hide('modalOtherSurveyResponses')" class="close vc-close")
              | ×
        span.mr-2(v-for="value in newAttributeValues(attributeValues)")
          | {{value.response_attribute.name}}: {{value.name}}
        span.organization-wrap(v-if="response.organization_name")
          | 所属: 
          | {{response.grand_parent_organization_name + (response.grand_parent_organization_name ? ' > ' : '')}}
          | {{response.parent_organization_name + (response.parent_organization_name ? ' > ' : '')}}
          | {{response.organization_name}}
      .row.no-gutters.modal-content
        div(v-if="responses.length > 0")
          div(v-for="res in responses")
            .col-12.mt-2
              span.vc-midashi
                | {{res.survey.name}}
              span.float-right
                | {{newDate(res.survey.start_at) + ' - ' + newDate(res.survey.end_at)}}
            .col-12.mt-3.mb-3
              span
                | {{res.contents}}
        div(v-else)
          .col-12.mt-3.mb-3
            span
              | 別調査回答がありません

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      response: {},
      attributeValues: []
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_opinion_search', ['responses'])
  },

  watch: {
    response: function() {
      this.attributeValues = this.response.attribute_values;
    }
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_opinion_search', ['loadSurveyResponses']),
    modalOpen: function(response) {
      const params = {
        survey_id: response.survey_id,
        responder_code: response.responder_code
      };

      this.response = response;
      this.loadSurveyResponses(params);
      this.$modal.show('modalOtherSurveyResponses');
    },
    newAttributeValues: function(values) {
      return values.filter(value =>
        ['性別', '役職', '年代'].includes(value.response_attribute.name)
      );
    },
    newDate: function(date) {
      let newDate = new Date(date);
      return (
        newDate.getFullYear() +
        '.' +
        (newDate.getMonth() + 1) +
        '.' +
        newDate.getDate()
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-header {
  display: block;
  span {
    font-size: 0.8rem;
  }
  span.organization-wrap {
    float: right;
    margin-top: 1px;
  }
}
.modal-content {
  overflow-y: scroll;
  height: 75%;
  display: block;
  flex-direction: initial;
  border: none;
}
</style>
