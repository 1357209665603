<template lang="pug">
  .container-fruid
    .main-panel(v-if="capabilityCode && capabilityCode.includes('01')")
      section.vc-content
        .vc-main-container.container
          .vc-content-col#userList
            .container
              .row
                .col-12
                  h3.vc-midashi.mb-3
                    | ユーザー招待
                  hr.vc-full-divider
              .row
                .col-6
                  form
                    error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
                    validation-observer(v-slot="{ invalid }")
                      validation-provider(name="email" rules="required|email" v-slot="{ errors }")
                        .form-group(:class="{ 'has-danger': errors.length}")
                          label.cc-label-mid
                            | メールアドレス
                          input(
                            type="email"
                            placeholder="admin@example.com"
                            maxlength="64"
                            :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                            v-model="email"
                            required
                          )
                          label.error.mt-2.text-danger(v-show="errors.length")
                            | {{ errors[0] }}

                      validation-provider(name="userName" rules="max:16" v-slot="{ errors }")
                        .form-group(:class="{ 'has-danger': errors.length}")
                          label.cc-label-mid
                            | ユーザー名
                          input(
                            type="text"
                            placeholder="Suzuki Taro"
                            maxlength="16"
                            :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                            v-model="name"
                          )
                          label.error.mt-2.text-danger(v-show="errors.length")
                            | {{ errors[0] }}

                      validation-provider(name="roleId" rules="required" v-slot="{ errors }")
                        .form-group(:class="{ 'has-danger': errors.length}")
                          label
                            | 権限
                          select(
                            :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                            v-model="roleId"
                          )
                            option(
                              v-for="role in roles"
                              v-bind:value="role.id"
                              v-bind:key="role.id"
                            )
                              | {{role.name}}
                          label.error.mt-2.text-danger(v-show="errors.length")
                            | {{ errors[0] }}

                      //- validation-provider(name="organizationId" rules="required" v-slot="{ errors }")
                      //-   .form-group(:class="{ 'has-danger': errors.length}")
                      //-     label.cc-label-mid
                      //-       | {{ $t('admin.users.organization') }}
                      //-     select(
                      //-       :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      //-       v-model="organizationId"
                      //-     )
                      //-       option(disabled value="")
                      //-         | 選択してください
                      //-       optgroup(
                      //-         v-for="organizations in versionOrganizations"
                      //-         v-bind:label="organizations[0].organization_version_name"
                      //-         )
                      //-         option(
                      //-           v-for="organization in organizations"
                      //-           v-bind:value="organization.id"
                      //-           v-bind:key="organization.id"
                      //-         )
                      //-           | {{organization.code}} {{organization.name}}
                      //-     label.error.mt-2.text-danger(v-show="errors.length")
                      //-       | {{ errors[0] }}

                      .mt-1.float-right
                        button.btn.btn-primary.vc-primary-btn(
                          :disabled="processing || invalid"
                          @click.prevent="submit"
                        )
                          | 招待メールを送信
                        router-link.btn.btn-light.vc-btn.ml-4(:to="{ name: 'u_users' }")
                          | キャンセル

</template>

<script>
import { mapState, mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';

export default {
  components: {
    ErrorMessage
  },

  data() {
    return {
      name: '',
      email: '',
      roleId: '',
      // organizationId: '',
      errorMessages: [],
      processing: false
      // versionOrganizations: {}
    };
  },

  computed: {
    ...mapState('resources/u_roles', ['capabilityCode', 'roles']),
    // ...mapState('resources/u_users', [
    //   'organizations'
    // ]),
    /**
     * サーバサイドに渡す user のパラメータを生成
     *
     * @return {object}
     */
    userParams() {
      return {
        user: {
          name: this.name,
          email: this.email,
          role_id: this.roleId
          // organization_id: this.organizationId
        }
      };
    }
  },

  watch: {
    // organizations: function() {
    //   var versionOrganizations = {};
    //   for (let organization of this.organizations) {
    //     if (!versionOrganizations[organization.organization_version_id])
    //       versionOrganizations[organization.organization_version_id] = [];
    //     versionOrganizations[organization.organization_version_id].push(
    //       organization
    //     );
    //   }
    //   this.versionOrganizations = versionOrganizations;
    // }
  },

  created() {
    this.loadRoles();
    // this.loadOrganizations(this.accountGroupId);
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_roles', ['loadRoles']),
    ...mapActions('resources/u_users', [
      // 'loadOrganizations',
      'createUser'
    ]),

    resetErrorMessages() {
      this.errorMessages = [];
    },

    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.createUser(this.userParams)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'u_users' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
