export const RESOURCES = {
  x_account_groups: {
    index: 'x/account_groups',
    organizations: 'x/account_groups/organizations',
    roles: 'x/account_groups/roles',
    create: 'x/account_groups',
    edit: 'x/account_groups/:id/edit',
    update: 'x/account_groups/:id',
    remove: 'x/account_groups/:id',
    attribute_values: 'x/account_groups/:id/attribute_values'
  },
  x_users: {
    index: 'x/users',
    create: 'x/users',
    edit: 'x/users/:id/edit',
    update: 'x/users/:id',
    remove: 'x/users/:id',
    unlock: 'x/users/:id/unlock',
    enable: 'x/users/:id/enable_user',
    disable: 'x/users/:id/disable_user',
    account_groups: 'x/users/:id/account_groups',
    add_account_group: 'x/users/:id/add_account_group',
    roles: 'x/users/:id/roles'
  },
  x_admins: {
    index: 'x/admins',
    create: 'x/admins',
    edit: 'x/admins/:id/edit',
    update: 'x/admins/:id',
    remove: 'x/admins/:id'
  },
  x_profile: {
    update: 'x/profile'
  },
  x_surveys: {
    index: 'x/surveys',
    new: 'x/surveys/new',
    create: 'x/surveys',
    edit: 'x/surveys/:id/edit',
    update: 'x/surveys/:id',
    show: 'x/surveys/:id',
    export_csv: 'x/surveys/:id/export_csv',
    activate: 'x/surveys/:id/activate',
    undo: 'x/surveys/:id/undo',
    export_current_responses: 'x/surveys/:id/export_current_responses',
    export_current_opinions: 'x/surveys/:id/export_current_opinions',
    request_classifications_api: 'x/surveys/:id/request_classifications_api',
    download_classifications_api: 'x/surveys/:id/download_classifications_api',
    export_opinions_with_classifications:
      'x/surveys/:id/export_opinions_with_classifications',
    request_response_ranking_api: 'x/surveys/:id/request_response_ranking_api',
    export_response_ranking: 'x/surveys/:id/export_response_ranking_data',
    request_feature_words_api: 'x/surveys/:id/request_feature_words_api'
  },
  x_organization_versions: {
    index: 'x/organization_versions',
    create: 'x/organization_versions',
    show: 'x/organization_versions/:id',
    edit: 'x/organization_versions/:id/edit',
    update: 'x/organization_versions/:id',
    remove: 'x/organization_versions/:id'
  },
  x_classification_requests: {
    index: 'x/classification_requests'
  },
  x_classification_request_items: {
    index: 'x/classification_request_items',
    update: 'x/classification_request_items/:id'
  },
  x_dashboard: {
    index: 'x/dashboard'
  },
  x_inquiries: {
    index: 'x/inquiries',
    stop: 'x/inquiries/:id/stop',
    reopen: 'x/inquiries/:id/reopen',
    remove: 'x/inquiries/:id'
  },
  x_newsletter_templates: {
    index: 'x/newsletter_templates',
    show: 'x/newsletter_templates/:id',
    remove: 'x/newsletter_templates/:id',
    remove_img: 'x/newsletter_templates/delete_img/:image_id',
    preview_html: 'x/newsletter_templates/:id/preview_html'
  },
  x_newsletters: {
    index: 'x/newsletters',
    edit: 'x/newsletters/:id/edit',
    remove: 'x/newsletters/:id',
    surveys: 'x/newsletters/surveys',
    templates: 'x/newsletters/templates',
    preview: 'x/newsletters/:id/preview',
    send: 'x/newsletters/:id/send_newsletter',
    test_send: 'x/newsletters/:id/test_send_newsletter',
    copy: 'x/newsletters/:id/copy_newsletter'
  },
  x_co_occurrence_networks: {
    index: 'x/co_occurrence_networks',
    show: 'x/co_occurrence_networks/:id',
    create: 'x/co_occurrence_networks',
    edit: 'x/co_occurrence_networks/:id/edit',
    update: 'x/co_occurrence_networks/:id',
    destroy: 'x/co_occurrence_networks/:id',
    morphological_analysis:
      'x/co_occurrence_networks/:id/morphological_analysis',
    request_api: 'x/co_occurrence_networks/:id/request_api',
    co_occurrence_networks:
      'x/co_occurrence_networks/:id/co_occurrence_networks',
    generate_image: 'x/co_occurrence_networks/:id/generate_image'
  },
  x_questions: {
    index: 'x/surveys/:survey_id/questions',
    show: 'x/surveys/:survey_id/questions/:id',
    create: 'x/surveys/:survey_id/questions',
    edit: 'x/surveys/:survey_id/questions/:id/edit',
    update: 'x/surveys/:survey_id/questions/:id',
    destroy: 'x/surveys/:survey_id/questions/:id',
    export_csv: 'x/surveys/:survey_id/questions/:id/export_csv',
    export_current_responses:
      'x/surveys/:survey_id/questions/:id/export_current_responses',
    export_current_opinions:
      'x/surveys/:survey_id/questions/:id/export_current_opinions',
    request_classifications_api:
      'x/surveys/:survey_id/questions/:id/request_classifications_api',
    download_classifications_api:
      'x/surveys/:survey_id/questions/:id/download_classifications_api',
    export_opinions_with_classifications:
      'x/surveys/:survey_id/questions/:id/export_opinions_with_classifications',
    request_response_ranking_api:
      'x/surveys/:survey_id/questions/:id/request_response_ranking_api',
    export_response_ranking:
      'x/surveys/:survey_id/questions/:id/export_response_ranking_data',
    request_feature_words_api:
      'x/surveys/:survey_id/questions/:id/request_feature_words_api'
  },
  x_quantitative_questions: {
    index: 'x/surveys/:survey_id/questions/:question_id/quantitative_questions',
    create:
      'x/surveys/:survey_id/questions/:question_id/quantitative_questions',
    edit:
      'x/surveys/:survey_id/questions/:question_id/quantitative_questions/:id/edit',
    update:
      'x/surveys/:survey_id/questions/:question_id/quantitative_questions/:id',
    destroy:
      'x/surveys/:survey_id/questions/:question_id/quantitative_questions/:id'
  },
  x_quantitative_answers: {
    index:
      'x/surveys/:survey_id/questions/:question_id/quantitative_questions/:quantitative_question_id/quantitative_answers',
    create:
      'x/surveys/:survey_id/questions/:question_id/quantitative_questions/:quantitative_question_id/quantitative_answers',
    edit:
      'x/surveys/:survey_id/questions/:question_id/quantitative_questions/:quantitative_question_id/quantitative_answers/:id/edit',
    update:
      'x/surveys/:survey_id/questions/:question_id/quantitative_questions/:quantitative_question_id/quantitative_answers/:id',
    destroy:
      'x/surveys/:survey_id/questions/:question_id/quantitative_questions/:quantitative_question_id/quantitative_answers/:id'
  },
  x_chatgpt_classifications: {
    index: 'x/chatgpt_classifications',
    show: 'x/chatgpt_classifications/:id',
    create: 'x/chatgpt_classifications',
    update: 'x/chatgpt_classifications/:id',
    confirm: 'x/chatgpt_classifications/:id/confirm',
    reclustering: 'x/chatgpt_classifications/:id/reclustering',
    import: 'x/chatgpt_classifications/:id/import',
    result: 'x/chatgpt_classifications/:id/result'
  },
  x_chatgpt_sentiment_analysis: {
    index: 'x/chatgpt_sentiment_analysis',
    show: 'x/chatgpt_sentiment_analysis/:id',
    create: 'x/chatgpt_sentiment_analysis',
    import: 'x/chatgpt_sentiment_analysis/:id/import',
    result: 'x/chatgpt_sentiment_analysis/:id/result'
  },
  u_control_settings: {
    index: 'u/control_settings'
  },
  u_roles: {
    index: 'u/roles'
  },
  u_dashboard: {
    volume_map: 'u/dashboard/volume_map',
    opinion_class_map: 'u/dashboard/opinion_class_map',
    emotion_class_per: 'u/dashboard/emotion_class_per',
    index: 'u/dashboard',
    organizations: 'u/dashboard/organizations'
  },
  u_mypage: {
    index: 'u/mypage',
    update_account_group: 'u/mypage/:id/update_account_group'
  },
  u_about_data: {
    index: 'u/about_data'
  },
  u_organizations: {
    index: 'u/organizations',
    organization_comparison: 'u/organizations/organization_comparison'
  },
  u_surveys: {
    index: 'u/surveys',
    reports: 'u/surveys/:id/reports',
    delete_report: 'u/surveys/:id/delete_report'
  },
  u_response_attributes: {
    index: 'u/response_attributes'
  },
  u_analyse_team: {
    opinion_tendencies: 'u/analyse_team/opinion_tendencies',
    opinion_trends: 'u/analyse_team/opinion_trends',
    opinion_per_attribute_values: 'u/analyse_team/opinion_per_attribute_values',
    ns_opinion_per_attribute_values:
      'u/analyse_team/ns_opinion_per_attribute_values',
    opinion_per_attribute_values_for_ec:
      'u/analyse_team/opinion_per_attribute_values_for_ec',
    ns_opinion_per_attribute_values_for_ec:
      'u/analyse_team/ns_opinion_per_attribute_values_for_ec',
    topic: 'u/analyse_team/topic',
    ranking_by_opinion_classifications:
      'u/analyse_team/ranking_by_opinion_classifications'
  },
  u_analyse_compare: {
    opinion_tendencies: 'u/analyse_compare/opinion_tendencies',
    topic: 'u/analyse_compare/topic'
  },
  u_analyse_matrix: {
    index: 'u/analyse_matrix',
    matrix_data: 'u/analyse_matrix/matrix_data'
  },
  u_analyse_heatmap: {
    index: 'u/analyse_heatmap',
    heatmap_data: 'u/analyse_heatmap/heatmap_data'
  },
  u_opinion_search: {
    index: 'u/opinion_search',
    conditions: 'u/opinion_search/conditions',
    export_csv: 'u/opinion_search/export_csv',
    export_pdf: 'u/opinion_search/export_pdf',
    default_organization_id: 'u/opinion_search/default_organization_id',
    other_survey_responses: 'u/opinion_search/other_survey_responses',
    total_classifications: 'u/opinion_search/total_classifications',
    request_classifications: 'u/opinion_search/request_classifications'
  },
  u_users: {
    index: 'u/users',
    create: 'u/users',
    edit: 'u/users/:id/edit',
    update: 'u/users/:id',
    remove: 'u/users/:id',
    organizations: 'u/users/organizations',
    unlock: 'u/users/:id/unlock',
    send_invitation: 'u/users/:id/send_invitation'
  },
  u_topic: {
    index: 'u/topic',
    ranking: 'u/topic/ranking'
  },
  u_text_mining: {
    index: 'u/text_mining',
    update: 'u/text_mining/:id'
  },
  u_account_groups: {
    quantitative_analyse: 'u/account_groups/quantitative_analyse'
  },
  u_select_account_group: {
    index: 'u/select_account_group',
    update_account_group: 'u/select_account_group/:id/update_account_group'
  },
  u_classification_requests: {
    index: 'u/classification_requests'
  },
  u_classification_request_items: {
    index: 'u/classification_request_items',
    reject: 'u/classification_request_items/:id/reject',
    approve: 'u/classification_request_items/:id/approve'
  },
  u_public_activities: {
    create: 'u/public_activities'
  },
  u_quantitative_analyse: {
    index: 'u/quantitative_analyse',
    details: 'u/quantitative_analyse/:id/details',
    all_responses: 'u/quantitative_analyse/all_responses',
    responders_info: 'u/quantitative_analyse/responders_info',
    organizations: 'u/quantitative_analyse/organizations'
  },
  u_inquiries: {
    index: 'u/inquiries',
    create: 'u/inquiries'
  },
  u_chat_interviews: {
    index: 'u/inquiries/:inquiry_id/chat_interviews',
    update: 'u/inquiries/:inquiry_id/chat_interviews/:id',
    remove: 'u/inquiries/:inquiry_id/chat_interviews/:id',
    export_pdf: 'u/inquiries/:inquiry_id/chat_interviews/export_pdf',
    complete: 'u/inquiries/:inquiry_id/chat_interviews/complete',
    update_memo: 'u/inquiries/:inquiry_id/chat_interviews/update_memo',
    update_status: 'u/inquiries/:inquiry_id/chat_interviews/update_status'
  },
  u_response_ranking: {
    index: 'u/response_ranking',
    opinion_values: 'u/response_ranking/opinion_values',
    export_csv: 'u/response_ranking/export_csv',
    export_pdf: 'u/response_ranking/export_pdf'
  },
  u_quantitative: {
    questions: 'u/qualitative/questions'
  },
  u_quantitative: {
    analyse: 'u/quantitative/analyse',
    questions: 'u/quantitative/questions',
    analyse_changes: 'u/quantitative/analyse_changes',
    calculated_analyse: 'u/quantitative/calculated_analyse',
    calculated_analyse_changes: 'u/quantitative/calculated_analyse_changes',
    calculated_analyse_details: 'u/quantitative/calculated_analyse_details',
    organizations: 'u/quantitative/organizations',
    responders_info: 'u/quantitative/responders_info',
    nav_organizations: 'u/quantitative/nav_organizations'
  },
  u_analyse: {
    display_menu: 'u/analyse/:id/display_menu'
  },
  u_co_occurrences: {
    create: 'u/co_occurrences'
  },
  r_inquiries: {
    accept: 'r/inquiries/:id/accept'
  },
  r_chat_interviews: {
    index: 'r/inquiries/:inquiry_id/chat_interviews'
  },
  r_select_nickname: {
    index: 'r/select_nickname',
    update: 'r/select_nickname/:id',
    is_chat_interviews: 'r/select_nickname/:id/chat_interviews'
  }
};
