<template lang="pug">
  div
    p.vc-left-col-title
      | フィルタ
    .vc-cat
      .row
        .col-6
          button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="allSelect") すべて選択
        .col-6
          button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="clearSelect") 選択解除
    .vc-cat(
      v-for="(response_attribute, i) in response_attributes" :key="i"
      )
      p.vc-left-head-1
        | {{response_attribute.name}}
      ul
        li(
          v-for="(attribute_value, ii) in response_attribute.attribute_values.slice(0,4)" :key="ii"
          )
          .custom-control.custom-checkbox.vc-custom-checkbox
            input(
              type="checkbox"
              class="custom-control-input"
              :id="'attributeValue'+attribute_value.id"
              :value="attribute_value.id"
              v-model="selected"
              )
            label(
              class="custom-control-label"
              :for="'attributeValue'+attribute_value.id"
              )
              | {{attribute_value.name}}
      a(
        v-if="response_attribute.attribute_values.length>=5"
        :href="'#collapse'+i"
        data-toggle="collapse"
        class="collapsed vc-collapse"
        )
        | すべて表示
      div(
        v-if="response_attribute.attribute_values.length>=5"
        class="collapse"
        :id="'collapse'+i"
        )
        ul
          li(
            v-for="(attribute_value, ii) in response_attribute.attribute_values.slice(4)" :key="ii"
            )
            .custom-control.custom-checkbox.vc-custom-checkbox
              input(
                type="checkbox"
                class="custom-control-input"
                :id="'attributeValue'+attribute_value.id"
                :value="attribute_value.id"
                v-model="selected"
                )
              label(
                class="custom-control-label"
                :for="'attributeValue'+attribute_value.id"
                )
                | {{attribute_value.name}}
        a(
          :href="'#collapse'+i"
          data-toggle="collapse"
          class="collapsed vc-collapse-open"
          )
          | 閉じる
    .vc-submit-wrap
      button(
        class="btn btn-primary btn-block vc-primary-btn"
        :disabled="processing"
        @click.prevent="submit"
        )
        | 絞り込み

</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},

  props: {},

  data() {
    return {
      selected: [],
      processing: false
    };
  },

  computed: {
    ...mapState('resources/u_response_attributes', ['response_attributes']),
    ...mapState('resources/u_opinion_search', ['keepedAttributeValues'])
  },

  watch: {
    comparisonTargetIds: function() {}
  },

  created() {
    this.loadResponseAttributes();
    if (this.keepedAttributeValues.length > 0)
      this.selected = this.keepedAttributeValues;
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_response_attributes', [
      'loadResponseAttributes'
    ]),
    allSelect() {
      const response_attributes = this.response_attributes;
      let selected = [];
      for (let i in response_attributes) {
        for (let ii in response_attributes[i].attribute_values) {
          selected.push(response_attributes[i].attribute_values[ii].id);
        }
      }
      this.selected = selected;
    },
    clearSelect() {
      this.selected = [];
    },
    submit() {
      this.processing = true;
      const names = this.selected_names();
      this.$emit('input', [this.selected, names]);
      this.processing = false;
    },
    selected_names() {
      const response_attributes = this.response_attributes;
      let names = [];
      for (let i in response_attributes) {
        for (let ii in response_attributes[i].attribute_values) {
          const id = response_attributes[i].attribute_values[ii].id;
          if (this.selected.includes(id))
            names.push(response_attributes[i].attribute_values[ii].name);
        }
      }
      return names;
    }
  }
};
</script>
