<template lang="pug">
  .card.select-wrap
    .card-header.select-account-group-header
      h4.vc-midashi アカウントグループの選択
    .card-body
      .row
        .col-8
          .form-group
            p 分析するアカウントグループを選択し、「送信」を押してください。
            select.form-control(v-model="accountGroupId")
              option(
                v-for="accountGroup in accountGroups"
                v-bind:value="accountGroup.id"
                v-bind:key="accountGroup.id"
              )
                | {{accountGroup.name}}
            .mt-3.text-right
              button.btn.btn-primary.vc-primary-btn.btn-select(
                :disabled="processing || !accountGroupId"
                @click.prevent="submit"
              )
                | {{ $t('actions.select') }}
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},
  data() {
    return {
      accountGroupId: '',
      processing: false
    };
  },
  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_select_account_group', ['accountGroups'])
  },
  created() {
    this.loadAccountGroups();
  },
  mounted() {},
  methods: {
    ...mapActions('resources/u_select_account_group', [
      'loadAccountGroups',
      'updateMainAccountGroup'
    ]),
    submit() {
      this.processing = true;
      const params = {
        id: this.operator.id,
        account_group_id: this.accountGroupId
      };
      this.updateMainAccountGroup(params).then(() => {
        location.href = 'quantitative/analyse';
      });
    }
  }
};
</script>
<style scoped>
.select-wrap {
  width: 60%;
  margin: auto;
}
.vc .card .card-header.select-account-group-header {
  padding: 20px 40px;
}
.select-wrap .btn.btn-primary.vc-primary-btn.btn-select {
  padding: 0.4rem 1rem;
  cursor: pointer;
}
</style>
