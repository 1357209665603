<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | メルマガ編集
  .row.flex-grow
    .col-12
      .card
        .card-body
          form
            .alert.alert-danger(v-if="errorMessages.length > 0")
              ul.mb-0
                li(v-for="error in errorMessages")
                  | {{ error }}
            validation-observer(v-slot="{ invalid }")
              validation-provider(name="accountGroup" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | アカウントグループ
                  select.form-control(v-model="accountGroup" @change="changeAccountGroup")
                    option(
                      v-for="ag in accountGroups"
                      :key="ag.id"
                      :value="ag.id"
                    )
                      | {{ag.name}}
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="survey" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 調査
                  select.form-control(v-model="survey")
                    option(
                      v-for="s in currentSurveys"
                      :key="s.id"
                      :value="s.id"
                    )
                      | {{s.name}}
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              .form-group
                label
                  | テンプレ選択
                select.form-control(v-model="template" @change="changeTemplate")
                  option(
                    v-for="temp in templates"
                    :key="temp.id"
                    :value="temp.id"
                  )
                    | {{temp.name}}

              .row
                .col-6
                  validation-provider(name="senderEmail" rules="required" v-slot="{ errors }")
                    .form-group(:class="{ 'has-danger': errors.length}")
                      label
                        | 送信者アドレス
                      input(
                        type="text"
                        :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                        v-model="senderEmail"
                      )
                      label.error.mt-2.text-danger(v-show="errors.length")
                        | {{ errors[0] }}
                .col-6
                  validation-provider(name="senderName" rules="required" v-slot="{ errors }")
                    .form-group(:class="{ 'has-danger': errors.length}")
                      label
                        | 送信者名
                      input(
                        type="text"
                        :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                        v-model="senderName"
                      )
                      label.error.mt-2.text-danger(v-show="errors.length")
                        | {{ errors[0] }}

              validation-provider(name="recipients" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 宛先アドレス
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="recipients"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="newsletterName" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | タイトル
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="name"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              .form-group
                label
                  | サムネイル画像
                .row
                  .col-10
                    input.form-control(
                      type="text"
                      v-model="thumbnailFileTxt"
                      readonly
                    )
                  .col-2.text-right
                    label.btn.btn-secondary ファイル添付
                      input.d-none(
                        type="file"
                        :accept="acceptThumbnailFiletypes"
                        @change="onFileChange($event)"
                      )
                  .col-4(v-if="thumbnailUrl")
                    .row.small
                      .col-6
                        input#copy-img-url(type="hidden" :value="thumbnailUrl")
                        a.img-action-link.mr-2(@click="copyImgFileUrl")
                          | URLコピー

              validation-provider(name="body" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 本文
                  textarea(
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    rows='30'
                    v-model="body"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              .mt-5
                .text-left.float-left
                  router-link.btn.btn-outline-secondary.action-btns(
                    :to="{ name: 'x_newsletters' }"
                  )
                    | 戻る
                .text-right
                  button.btn.btn-outline-secondary.mr-2.action-btns(
                    @click.prevent="submit('save')"
                  )
                    | 下書き保存
                  button.btn.btn-success.action-btns(
                    @click.prevent="submit('preview')"
                  )
                      | プレビューへ

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},
  data() {
    return {
      currentSurveys: [],
      accountGroup: '',
      survey: '',
      template: '',
      senderEmail: '',
      senderName: '',
      recipients: '',
      name: '',
      body: '',
      thumbnailFileTxt: '',
      thumbnailUrl: '',
      thumbnailFile: null,
      errorMessages: [],
      processing: false,
      acceptThumbnailFiletypes: 'image/jpeg,image/png,image/gif'
    };
  },
  computed: {
    ...mapState('resources/x_newsletters', [
      'accountGroups',
      'surveys',
      'templates',
      'newsletter'
    ])
  },
  created() {
    const newsletterId = this.$route.params.id;
    this.load();
    this.editNewsletter(newsletterId).then(response => {
      this.survey = response.body.newsletter.survey.id;
      this.accountGroup = response.body.newsletter.survey.account_group_id;
      const temp = response.body.newsletter.template;
      this.template = temp ? temp.id : '';
      this.senderEmail = response.body.newsletter.sender_email;
      this.senderName = response.body.newsletter.sender_name;
      this.recipients = response.body.newsletter.recipients;
      this.name = response.body.newsletter.name;
      this.body = response.body.newsletter.body;
      this.thumbnailUrl = response.body.newsletter.thumbnail_url;
      this.thumbnailFileTxt = response.body.newsletter.thumbnail_name;
      this.changeAccountGroup();
    });
  },
  mounted() {},
  methods: {
    ...mapActions('resources/x_newsletters', [
      'loadTemplates',
      'loadSurveys',
      'editNewsletter'
    ]),
    load() {
      this.loadTemplates();
      this.loadSurveys();
    },
    resetErrorMessages() {
      this.errorMessages = [];
    },
    onFileChange(e) {
      this.resetErrorMessages();
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.thumbnailFile = files[0];
      this.thumbnailFileTxt = this.thumbnailFile.name;
    },
    submit(type) {
      this.resetErrorMessages();
      this.noticeMessages = [
        '処理中...。アップロードの完了までもう少しお待ちください。'
      ];
      const id = this.$route.params.id;
      let path = '/api/x/newsletters/' + id;
      const formData = new FormData();
      formData.append('survey_id', this.survey);
      formData.append('template_id', this.template);
      formData.append('sender_email', this.senderEmail);
      formData.append('sender_name', this.senderName);
      formData.append('recipients', this.recipients);
      formData.append('name', this.name);
      formData.append('body', this.body);
      if (this.thumbnailFile) formData.append('thumbnail', this.thumbnailFile);

      fetch(path, {
        method: 'PUT',
        body: formData
      }).then(response => {
        this.noticeMessages = [];
        if (response.status === 200) {
          if (type === 'save') this.$router.push({ name: 'x_newsletters' });
          else
            this.$router.push({
              name: 'x_preview_newsletter',
              params: { id: id }
            });
        } else {
          response.json().then(body => {
            this.errorMessages = body.errors;
          });
        }
      });
    },
    changeAccountGroup() {
      const ag = this.accountGroups.find(el => el.id === this.accountGroup);
      this.currentSurveys = this.surveys[ag.id];
    },
    changeTemplate() {
      const temp = this.templates.find(el => el.id === this.template);
      this.body = temp.html_txt;
    },
    copyImgFileUrl() {
      let urlEl = document.querySelector('#copy-img-url');

      urlEl.setAttribute('type', 'text');
      urlEl.select();

      document.execCommand('copy');

      urlEl.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    }
  }
};
</script>
<style scoped>
.form-control[readonly] {
  background-color: #fff;
}
.action-btns {
  width: 120px;
}
.img-action-link {
  font-size: inherit !important;
  color: #444 !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}
</style>
