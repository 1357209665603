<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content.vc-dashboard.vc-topic(v-show="ready")
        SurveyOrganizationSelect(@input="load")
        TopicNav
        .vc-main-container.container(v-if="isTopic")
          .vc-content-col(v-if="responseCount < 1")
            .container
              | 回答が{{diplayableNum()}}以下は表示できません。分析対象の組織を選びなおしてください
          .vc-content-col(v-else-if="!isExists")
            .container
              | 共起ネットワークのテキストマイニングデータがありません。データをアップロードしてください。
          div(v-else)
            .vc-dashboard-box.mb-4
              .container-fluid
                .row.ml-1(v-show="survey.question_text")
                  .col-12
                    | {{ survey.question_text }}
            .vc-dashboard-box.mb-4
              .container-fluid
                .row
                  .col-6
                  .col-6.text-right
                    ul.vc-parameter-nav.mb-4
                      li
                        a.vpn-nega(
                          @click.prevent="changeEmotionType('negative')"
                          :class="{'active': emotionType === 'negative'}"
                        )
                          | ネガティブ
                      li
                        a.vpn-posi(
                          @click.prevent="changeEmotionType('positive')"
                          :class="{'active': emotionType === 'positive'}"
                        )
                          | ポジティブ
                .row.no-gutters.mb-5
                  .col-5.pr-4
                    img.w-100(:src='survey.nega_tm_image_url' v-if="emotionType === 'negative'")
                    img.w-100(:src='survey.posi_tm_image_url' v-else)
                  .col-7
                    .opinion-search-form.mb-3
                      .word-input
                        input.form-control(type="text" v-model="n1")
                      .search-type-select
                        .vc-sub-select-box
                          select.form-control.vc-sub-select(v-model="t1")
                            option(value="OR")
                              | OR
                            option(value="AND")
                              | AND
                      .word-input
                        input.form-control(type="text" v-model="n2")
                      .search-type-select
                        .vc-sub-select-box
                          select.form-control.vc-sub-select(v-model="t2")
                            option(value="OR")
                              | OR
                            option(value="AND")
                              | AND
                      .word-input
                        input.form-control(type="text" v-model="n3")
                      .search-btn
                        button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="toOpinionSearch") 意見検索
                    .row.no-gutters.vc-respondent-table
                      .col-12.table-header
                        .row
                          .col-2
                            .row
                              .col-3
                              .col-9
                                | 単語数
                          .col-8
                            | 解釈コメント
                    .row.no-gutters.vc-respondent-table(v-for="mining in (emotionType === 'negative' ? negaTextMinings : posiTextMinings)")
                      TextMiningItems(:mining="mining" @updateComment="updateComment")
        .vc-main-container.container(v-if="!isTopic")
          .vc-content-col
            .container
              | トピック調査以外の調査については、この画面はご利用できません。調査選択から、トピック調査を選択ください。
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SurveyOrganizationSelect from '../../../components/u/SurveyOrganizationSelect';
import TopicNav from '../../../components/u/topic/TopicNav';
import TextMiningItems from '../../../components/u/textMining/Items';
export default {
  components: {
    SurveyOrganizationSelect,
    TopicNav,
    TextMiningItems
  },

  data() {
    return {
      surveyId: null,
      n1: '',
      n2: '',
      n3: '',
      t1: '',
      t2: '',
      ready: false
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('pages/u_surveys/select', ['isTopic']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('resources/u_text_mining', [
      'survey',
      'negaTextMinings',
      'posiTextMinings',
      'emotionTypes',
      'emotionType',
      'responseCount',
      'isExists',
      'node1',
      'node2',
      'node3',
      'type1',
      'type2'
    ])
  },

  watch: {
    n1: function() {
      this.setNode1(this.n1);
    },
    n2: function() {
      this.setNode2(this.n2);
    },
    n3: function() {
      this.setNode3(this.n3);
    },
    t1: function() {
      this.setType1(this.t1);
    },
    t2: function() {
      this.setType2(this.t2);
    }
  },

  created() {
    this.loadControlSettings();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_text_mining', [
      'loadTextMining',
      'updateTextComment'
    ]),
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapMutations('resources/u_text_mining', [
      'setNode1',
      'setNode2',
      'setNode3',
      'setType1',
      'setType2',
      'setEmotionType'
    ]),
    load: function(data) {
      this.surveyId = data['surveyId'];
      this.loadTextMining({ survey_id: data['surveyId'] }).then(() => {
        this.n1 = this.node1;
        this.n2 = this.node2;
        this.n3 = this.node3;
        this.t1 = this.type1;
        this.t2 = this.type2;
        this.ready = true;
      });
    },

    toOpinionSearch: function() {
      this.$router.push({
        name: 'u_opinion_search',
        params: {
          survey_id: this.surveyId,
          emotion_classification: this.emotionClassificationId(),
          emotion_type: this.emotionType,
          nodes: [this.node1, this.node2, this.node3],
          node_search_types: [this.type1, this.type2],
          backToName: 'u_text_mining'
        }
      });
    },

    diplayableNum() {
      const name = 'number_of_displayable_data';
      if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
        return this.controlSettings[name]['values']
          ? this.controlSettings[name]['values']
          : 0;
      }
      return 0;
    },

    updateComment(params) {
      this.updateTextComment(params);
    },

    changeEmotionType(emotionType) {
      this.setEmotionType(emotionType);
    },

    emotionClassificationId() {
      return this.emotionTypes[
        this.emotionType === 'negative' ? 'ネガティブ' : 'ポジティブ'
      ];
    }
  }
};
</script>
<style lang="scss" scoped>
.opinion-search-form .word-input {
  display: inline-block;
  width: 20%;
  margin-right: 0.3rem;
}
.opinion-search-form .search-type-select {
  display: inline-block;
  width: 10%;
  margin-right: 0.3rem;
}
.opinion-search-form .search-btn {
  display: inline-block;
  width: 15%;
  // float: right;
}
.opinion-search-form input {
  padding: 5px 8px !important;
  margin: 0;
  height: 100%;
}
.opinion-search-form select {
  padding: 5px 8px !important;
  margin: 0;
  height: 100%;
}
.opinion-search-form .btn {
  height: 100%;
}
.vc-respondent-table .table-header {
  font-weight: bold;
  text-align: left;
}
</style>
