var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_vm._m(0),_c('div',{staticClass:"row flex-grow"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',[(_vm.errorMessages.length > 0)?_c('error-message',{attrs:{"errors":_vm.errorMessages}}):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("選択肢コード")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newQuantitativeAnswer.code),expression:"newQuantitativeAnswer.code"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text"},domProps:{"value":(_vm.newQuantitativeAnswer.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newQuantitativeAnswer, "code", $event.target.value)}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"selectText","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("選択肢テキスト")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newQuantitativeAnswer.select_text),expression:"newQuantitativeAnswer.select_text"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text"},domProps:{"value":(_vm.newQuantitativeAnswer.select_text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newQuantitativeAnswer, "select_text", $event.target.value)}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"selectNum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("選択肢番号")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newQuantitativeAnswer.select_num),expression:"newQuantitativeAnswer.select_num"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text"},domProps:{"value":(_vm.newQuantitativeAnswer.select_num)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newQuantitativeAnswer, "select_num", $event.target.value)}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"point","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("ポイント")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newQuantitativeAnswer.point),expression:"newQuantitativeAnswer.point"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text"},domProps:{"value":(_vm.newQuantitativeAnswer.point)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newQuantitativeAnswer, "point", $event.target.value)}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"mt-1 float-right"},[_c('router-link',{staticClass:"btn btn-light mr-2",attrs:{"to":{ name: 'x_quantitative_answers' }}},[_vm._v(_vm._s(_vm.$t('actions.cancel')))]),_c('button',{staticClass:"btn btn-success",attrs:{"disabled":_vm.processing || invalid},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v(_vm._s(_vm.$t('actions.update')))])],1)]}}])})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-header d-flex flex-column flex-md-row"},[_c('h4',{staticClass:"mb-0"},[_vm._v("選択肢編集")])])}]

export { render, staticRenderFns }