import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_analyse_matrix');

export default {
  namespaced: true,

  state: {
    organizations: [],
    attributeValues: [],
    opinionClassifications: [],
    emotionClassifications: [],
    matrixDataArray: [],
    currentResAttrs: []
  },

  getters: {},

  actions: {
    /**
     * PIVOTの表頭、表側のデータ取得
     */
    loadOuterFrameData({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          commit('setOrganizations', response.body.organizations);
          commit('setAttributeValues', response.body.attribute_values);
          commit(
            'setOpinionClassifications',
            response.body.opinion_classifications
          );
          commit(
            'setEmotionClassifications',
            response.body.emotion_classifications
          );
        }
        return response;
      });
    },

    /**
     * 分析対象の意見傾向データ取得
     */
    loadMatrixData({ commit }, params) {
      return gateway.post({ action: 'matrix_data' }, params).then(response => {
        if (response.status === 200) {
          commit('setMatrixDataArray', response.body);
        }
        return response;
      });
    }
  },
  mutations: {
    setMatrixDataArray(state, data) {
      state.matrixDataArray = data;
    },
    setOrganizations(state, data) {
      state.organizations = data;
    },
    setAttributeValues(state, data) {
      state.attributeValues = data;
    },
    setOpinionClassifications(state, data) {
      state.opinionClassifications = data;
    },
    setEmotionClassifications(state, data) {
      state.emotionClassifications = data;
    },
    setCurrentResAttrs(state, data) {
      state.currentResAttrs = data;
    }
  }
};
