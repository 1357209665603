<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content.vc-quantitative
        SurveySelect
        div(v-if="dataLoaded")
          .vc-main-container.container
            .vc-topic-content.mb-4(v-if="!calculated")
              .vc-left-col(:class="displayAttributeValues ? 'mr-1' : ''")
                QuantitativeOrganizations(:surveyId="surveyId" @matchHeights="matchHeights")
              .vc-left-col(v-show="displayAttributeValues")
                QuantitativeAttributeValues(@input="changeAttributeValueIds" :surveyId="surveyId" @matchHeights="matchHeights")
            .vc-content-col
              .container(v-if="calculated")
                .row(
                  :class="{'quantitative-divider': Object.keys(questions)[0] !== Object.keys(questions).find(key => questions[key] === qs)}"
                  v-for="qs in questions"
                )
                  .col-4.pr-4.pl-4(v-for="q in qs")
                    .h4.vc-midashi.mt-2.mb-2(:id="'quantitative-question-title'+q.code")
                      | {{ q.title }}
                    p.small(:id="'quantitative-question-text'+q.code")
                      | {{ q.question_text }}
                    p.small(:id="'quantitative-question-description'+q.code")
                      | {{ q.description }}
                    .text-right
                      router-link.btn.btn-primary.vc-primary-btn.gray-btn.vc-detail-btn.mt-3.mb-3(
                        type="button"
                        :to="{ name: 'u_quantitative_analyse_details', params: { surveyId: surveyId, questionId: q.id } }"
                        :class="{'btn-disabled': compareValues[q.code] < (q.reference_point === 0 ? 0.0 : 0)}"
                      )
                        | 変動の内訳をみる
                    vertical-bar-chart(
                      :chart-data="datacollection[q.code]" :options="newOptions(q)" v-if="datacollection[q.code]" :styles="chartStyle"
                    )
                    .mt-4
                    pie-chart(:chart-data="pieChartData[q.code] || {}" :options="pieChartOptions(q.title)" v-if="pieChartData[q.code]" :styles="pieChartStyle")
                .row(v-show="emptyQuestions")
                  .col-12
                    p
                      | 表示できるデータがありません
              .container(v-else)
                .row(
                  :class="{'quantitative-divider': Object.keys(questions)[0] !== Object.keys(questions).find(key => questions[key] === qs)}"
                  v-for="qs in questions"
                )
                  .col-4.pr-4.pl-4(v-for="q in qs.filter(q => q.question_type === 'weighted_average')")
                    .h4.vc-midashi.mt-2.mb-2(:id="'quantitative-question-title'+q.code")
                      | {{ q.title }}
                    p.small(:id="'quantitative-question-text'+q.code")
                      | {{ q.question_text }}
                    p.small(:id="'quantitative-question-description'+q.code")
                      | {{ q.description }}
                    .text-right
                      router-link.btn.btn-primary.vc-primary-btn.gray-btn.vc-detail-btn.mt-3.mb-3(
                        type="button"
                        :to="{ name: 'u_quantitative_analyse_details', params: { surveyId: surveyId, questionId: q.id } }"
                        :class="{'btn-disabled': compareValues[q.code] < (q.reference_point === 0 ? 0.0 : 0)}"
                      )
                        | 変動の内訳をみる
                    vertical-bar-chart(
                      :chart-data="datacollection[q.code]" :options="newOptions(q)" v-if="datacollection[q.code]" :styles="chartStyle"
                    )
                    .mt-4
                    pie-chart(:chart-data="pieChartData[q.code] || {}" :options="pieChartOptions(q.title)" v-if="pieChartData[q.code]" :styles="pieChartStyle")
                .row.mt-4(
                  :class="{'quantitative-divider': Object.keys(questions)[0] !== Object.keys(questions).find(key => questions[key] === qs)}"
                  v-for="qs in questions"
                )
                  .col-12.mt-4(v-for="q in qs.filter(q => q.question_type === 'count_vertical')")
                    .h4.vc-midashi.mt-2.mb-2(:id="'quantitative-question-title'+q.code")
                      | {{ q.title }}
                    p.small(:id="'quantitative-question-text'+q.code" v-show="q.question_text")
                      | {{ q.question_text }}
                    p.small(:id="'quantitative-question-description'+q.code" v-show="q.description")
                      | {{ q.description }}
                    .text-right
                      router-link.btn.btn-primary.vc-primary-btn.gray-btn.vc-detail-btn.mb-3(
                        type="button"
                        :to="{ name: 'u_quantitative_analyse_details', params: { surveyId: surveyId, questionId: q.id } }"
                        :class="{'btn-disabled': compareValues[q.code] < (q.reference_point === 0 ? 0.0 : 0)}"
                      )
                        | 変動の内訳をみる
                    vertical-bar-chart(
                      :chart-data="verticalBarChartData[q.code]"
                      :options="newOptions(q)"
                      :styles="chartStyle"
                      v-if="verticalBarChartData[q.code]"
                    )
                .row(v-show="emptyQuestions")
                  .col-12
                    p
                      | 表示できるデータがありません
        .vc-main-container.container(v-else)
          .vc-content-col
            .container
              | 読み込み中です。しばらくお待ちください。

</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import SurveySelect from '../../../components/u/dashboard/SurveySelect';
import VerticalBarChart from '../../../components/u/chartJs/VerticalBarChart';
import PieChart from '../../../components/u/chartJs/PieChart';
import QuantitativeOrganizations from '../../../components/u/quantitativeAnalyse/QuantitativeOrganizations';
import QuantitativeAttributeValues from '../../../components/u/quantitativeAnalyse/QuantitativeAttributeValues';
export default {
  components: {
    SurveySelect,
    VerticalBarChart,
    PieChart,
    QuantitativeOrganizations,
    QuantitativeAttributeValues
  },
  data() {
    return {
      datacollection: {},
      graphBarColors: ['#00ead4', '#00c3b1', '#00a897', '#008275', '#00423c'],
      pieGraphBackgroundColors: [
        '#22BED3',
        '#22BED3',
        '#BEE6FB',
        '#D1D1D1',
        '#D1D1D1'
      ],
      compareValues: {},
      emptyQuestions: false,
      displayable: false,
      attributeValueIds: [],
      pieChartData: {},
      dataLoaded: false,
      chartStyle: { height: '400px', position: 'relative' },
      pieChartStyle: { height: '450px', position: 'relative' },
      verticalBarChartData: {}
    };
  },
  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('resources/u_quantitative_analyse', [
      'surveys',
      'questions',
      'responses',
      'displayAttributeValues',
      'organizationIds',
      'calculated',
      'responseAttributes'
    ]),
    ...mapState('pages/u_surveys/select', ['surveyId'])
  },
  watch: {
    responses() {
      this.updateGraph();
    },
    questions: function() {
      if (Object.keys(this.questions).length <= 0) this.emptyQuestions = true;
    },
    surveyId: function() {
      this.emptyQuestions = false;
      this.load();
    },
    organizationIds() {
      this.updateGraph();
    }
  },
  created() {
    this.loadControlSettings();
  },
  mounted() {
    let vcd = defaultCookie.get();
    if (vcd['surveyId'] || this.surveyId) this.load();
  },
  methods: {
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapActions('resources/u_quantitative_analyse', [
      'loadQuantitativeAnalyse'
    ]),
    load() {
      this.dataLoaded = false;
      let vcd = defaultCookie.get();
      if (vcd['surveyId'] || this.surveyId) {
        this.loadQuantitativeAnalyse({
          survey_id: vcd['surveyId'] || this.surveyId
        }).then(() => {
          this.matchHeights();
        });
      }
    },
    updateGraph() {
      if (this.calculated) this.updateGraphForCalculated();
      else this.updateGraphForNormal();
    },
    updateGraphForNormal() {
      let vcd = defaultCookie.get();
      let responses = [];
      let qIds = [];
      let qIds2 = [];
      this.organizationIds.forEach(orgId => {
        responses = responses.concat(
          this.responses.filter(d => d.include_org_ids.includes(orgId))
        );
      });
      responses = [...new Set(responses)];
      if (this.attributeValueIds.length > 0) {
        for (let i = 0; i < this.responseAttributes.length; i++) {
          const avIds = this.responseAttributes[i].attribute_values.map(
            av => av.id
          );
          let intersection = this.attributeValueIds.filter(id =>
            avIds.includes(id)
          );
          if (intersection.length < 1) continue;
          responses = responses.filter(d => {
            const raId = this.responseAttributes[i].id;
            const av = d.attribute_values.find(
              attr => attr.response_attribute_id == raId
            );
            if (av) return this.attributeValueIds.includes(av.id);
            else return false;
          });
        }
      }
      for (let key in this.questions) {
        const qs = this.questions[key].filter(
          q => q.question_type === 'weighted_average'
        );
        const qs2 = this.questions[key].filter(
          q => q.question_type === 'count_vertical'
        );
        const qId = qs.map(q => q.code);
        const qId2 = qs2.map(q => q.code);
        qIds = qIds.concat(qId);
        qIds2 = qIds2.concat(qId2);
      }
      for (let qId of qIds) {
        let dataLabels = {};
        let data = [];
        let labels = [];
        let pieDataLabels = [];
        let pieData = [];
        let qResponses = responses.filter(d => d.question_code === qId);
        const resGroup = qResponses.reduce((group, ress) => {
          const { survey_id } = ress;
          group[survey_id] = group[survey_id] ? group[survey_id] : [];
          group[survey_id].push(ress);
          return group;
        }, {});
        for (let survey of this.surveys) {
          const resLength = Object.keys(resGroup).length;
          if (resLength > 0) {
            const resBySurvey = resGroup[survey.serial_no];
            if (resBySurvey) {
              for (let res of resBySurvey) {
                if (!dataLabels[survey.short_name || survey.name])
                  dataLabels[survey.short_name || survey.name] = [];
                const answer = res.quantitative_answer;
                if (answer)
                  dataLabels[survey.short_name || survey.name].push(
                    answer.select_num
                  );
                if (survey.id === (vcd['surveyId'] || this.surveyId)) {
                  if (answer) pieData.push(answer.select_num);
                }
              }
            }
          } else {
            if (!dataLabels[survey.short_name || survey.name])
              dataLabels[survey.short_name || survey.name] = [];
          }
        }
        for (let label in dataLabels) {
          data.push(this.calculate(dataLabels[label].map(d => d)));
        }
        labels = labels.concat(Object.keys(dataLabels));
        let datasets = [
          {
            backgroundColor: this.graphBarColors,
            borderWidth: 1,
            borderColor: this.graphBarColors,
            data: data
          }
        ];
        const dc = {
          labels: labels.map(v => v.split('')),
          datasets: datasets
        };
        Vue.set(this.datacollection, qId, dc);
        let lastValue = data[data.length - 2];
        let currentValue = data[data.length - 1];
        let compareValue = lastValue - currentValue;
        const cv = compareValue < 0 ? compareValue * -1 : compareValue;
        Vue.set(this.compareValues, qId, cv);
        pieDataLabels = [...new Set(pieData)].sort().reverse();
        let counts = {};
        for (const num of pieData) {
          counts[num] = counts[num] ? counts[num] + 1 : 1;
        }
        let pieCounts = pieDataLabels.map(lb => counts[lb]);
        this.pieChartData[qId] = {
          labels: pieDataLabels,
          datasets: [
            {
              backgroundColor: this.pieGraphBackgroundColors,
              data: pieCounts
            }
          ]
        };
      }
      for (let qId2 of qIds2) {
        let verticalData = [];
        let verticalDataLabels = [];
        let backgroundColor = [];
        let qResponses = responses
          .filter(
            d =>
              d.question_code === qId2 &&
              d.survey_id === (vcd['surveyId'] || this.surveyId)
          )
          .sort((a, b) => {
            return a.quantitative_answer.id - b.quantitative_answer.id;
          });
        for (let qIdx in this.questions) {
          let question = this.questions[qIdx].find(q => q.code === qId2);
          let answers = question.answers;
          for (let answer of answers) {
            verticalData.push(answer.select_text);
          }
        }
        for (let res of qResponses) {
          const answer = res.quantitative_answer;
          if (answer) verticalData.push(answer.select_text);
        }
        verticalDataLabels = [...new Set(verticalData)];
        let counts = {};
        for (const txt of verticalData) {
          counts[txt] = counts[txt] ? counts[txt] + 1 : 1;
          backgroundColor.push('#00ead4');
        }
        let verticalCounts = verticalDataLabels.map(lb => counts[lb] - 1);
        this.verticalBarChartData[qId2] = {
          labels: verticalDataLabels.map(v => v.split('')),
          datasets: [
            {
              backgroundColor: backgroundColor,
              data: verticalCounts
            }
          ]
        };
      }
    },
    updateGraphForCalculated() {
      for (let key in this.responses) {
        let data = [];
        let labels = [];
        for (let key2 in this.responses[key]) {
          let survey = this.surveys.find(
            survey => survey.serial_no === Number(key2)
          );
          labels.push(survey.short_name || survey.name);
          data.push(this.responses[key][key2].toFixed(1));
        }
        let datasets = [
          {
            backgroundColor: this.graphBarColors,
            borderWidth: 1,
            borderColor: this.graphBarColors,
            data: data
          }
        ];
        this.datacollection[key] = {
          labels: labels.map(v => v.split('')),
          datasets: datasets
        };
        let lastValue = data[data.length - 2];
        let currentValue = data[data.length - 1];
        let compareValue = lastValue - currentValue;
        this.compareValues[key] =
          compareValue < 0 ? compareValue * -1 : compareValue;
      }
    },
    matchHeights() {
      let promise = new Promise((resolve, _reject) => {
        this.dataLoaded = true;
        resolve();
      });
      promise.then(() => {
        const keys = Object.keys(this.questions);
        keys.forEach(key => {
          let qs = this.questions[key].filter(
            q => q.question_type !== 'count_vertical'
          );
          let titleArr = [];
          let textArr = [];
          let descriptionArr = [];
          qs.forEach(q => {
            let titleEl = document.getElementById(
              'quantitative-question-title' + q.code
            );
            let textEl = document.getElementById(
              'quantitative-question-text' + q.code
            );
            let descriptionEl = document.getElementById(
              'quantitative-question-description' + q.code
            );
            titleEl.style.height = '';
            textEl.style.height = '';
            descriptionEl.style.height = '';
          });
          qs.forEach(q => {
            let titleHeight = document.getElementById(
              'quantitative-question-title' + q.code
            ).clientHeight;
            let textHeight = document.getElementById(
              'quantitative-question-text' + q.code
            ).clientHeight;
            let descriptionHeight = document.getElementById(
              'quantitative-question-description' + q.code
            ).clientHeight;
            titleArr.push(titleHeight);
            textArr.push(textHeight);
            descriptionArr.push(descriptionHeight);
          });
          const titleMax = Math.max(...titleArr);
          const textMax = Math.max(...textArr);
          const descriptionMax = Math.max(...descriptionArr);
          qs.forEach(q => {
            let titleEl = document.getElementById(
              'quantitative-question-title' + q.code
            );
            let textEl = document.getElementById(
              'quantitative-question-text' + q.code
            );
            let descriptionEl = document.getElementById(
              'quantitative-question-description' + q.code
            );
            titleEl.style.height = titleMax + 'px';
            textEl.style.height = textMax + 'px';
            descriptionEl.style.height = descriptionMax + 'px';
          });
        });
      });
    },
    options(question) {
      return {
        title: {
          display: false
        },
        tooltips: {
          enabled: true,
          backgroundColor: 'rgba(0,0,0,0.6)',
          callbacks: {
            title: function(tooltipItem) {
              return tooltipItem[0].label.replaceAll(',', '');
            },
            label: function(tooltipItem) {
              if (tooltipItem.xLabel == 0) return;
              let num = tooltipItem.yLabel;
              return num.toFixed(1) + (question.unit ? question.unit : '');
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: -1,
            bottom: 0
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.3,
              ticks: {
                fontSize: 10
              }
            }
          ],
          yAxes: [
            {
              barPercentage: 0.5,
              ticks: {
                beginAtZero: true,
                fontSize: 10,
                maxTicksLimit: 4,
                callback: function(value) {
                  return (
                    value.toFixed(1) + (question.unit ? question.unit : '')
                  );
                }
              }
            }
          ]
        }
      };
    },
    newOptions(question) {
      const defaultOptions = this.options(question);
      if (question.question_type !== 'weighted_average') return defaultOptions;
      let options = {};
      Object.assign(options, defaultOptions);
      const answersSize = question.answers_size;
      options.scales.yAxes[0].ticks.max = answersSize;
      options.scales.yAxes[0].ticks.maxTicksLimit = answersSize * 2;
      return options;
    },
    pieChartOptions(title) {
      return {
        responsive: true,
        title: {
          display: true,
          text: title + 'の件数内訳'
        },
        layout: {
          padding: {
            left: 12,
            right: 12,
            top: 0,
            bottom: 0
          }
        },
        tooltips: {
          enabled: true,
          backgroundColor: 'rgba(0,0,0,0.6)',
          callbacks: {
            title: function(tooltipItem, data) {
              const num = data.labels[tooltipItem[0].index];
              let label;
              if (num === 1) label = '1：全く満足していない';
              if (num === 2) label = '2：満足していない';
              if (num === 3) label = '3：どちらともいえない';
              if (num === 4) label = '4：満足している';
              if (num === 5) label = '5：非常に満足している';
              return label;
            },
            label: function(tooltipItem, data) {
              let num = data.datasets[0].data[tooltipItem.index];
              return num;
            }
          }
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            generateLabels: function(data) {
              const labelHash = {
                1: '全く満足していない',
                2: '満足していない',
                3: 'どちらともいえない',
                4: '満足している',
                5: '非常に満足している'
              };
              const chartData = data.tooltip._data;
              const chartDataset = chartData.datasets[0];
              const chartLabels = chartData.labels;
              const legendArray = [];
              for (let i = 0; i < chartLabels.length; i++) {
                const eachLabel = chartLabels[i];
                const backgroundColor = chartDataset.backgroundColor[i];
                const eachLengend = {
                  text: `${eachLabel}：${labelHash[eachLabel]}`,
                  fillStyle: backgroundColor,
                  hidden: false,
                  lineCap: 'square',
                  lineDash: [0],
                  lineDashOffset: 0,
                  lineJoin: 'bevel',
                  lineWidth: 1,
                  strokeStyle: '',
                  pointStyle: ''
                };
                legendArray.push(eachLengend);
              }
              return legendArray;
            }
          },
          align: 'left'
        },
        maintainAspectRatio: false
      };
    },
    changeAttributeValueIds(attributeValueIds) {
      this.attributeValueIds = attributeValueIds;
      this.updateGraph();
    },
    calculate(answers) {
      let counts = {};
      for (const num of answers) {
        counts[num] = counts[num] ? counts[num] + 1 : 1;
      }
      const keys = Object.keys(counts).sort();
      let result = 0;
      keys.map(key => {
        result += key * counts[key];
      });
      return (result / answers.length).toFixed(1);
    }
  }
};
</script>
<style scoped>
.quantitative-divider {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #eaeaea;
}
.vc-quantitative a.vc-detail-btn {
  font-size: 0.7rem;
  width: 150px;
  padding: 7px;
}
.vc-topic-content {
  display: flex;
  align-items: flex-start;
}
</style>
