<template lang="pug">
  b-modal(
    id="newsletter-template-delete-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    title="メルマガのテンプレートの削除"
  )
    b-container
      p
        | {{$t('actions.confirm')}}
      p
        span.text-danger
          | {{ deleteTarget.name }}
    div(slot="modal-footer")
      button.btn.btn-secondary(@click="close")
        | {{$t('actions.close')}}
      button.btn.btn-danger.ml-2(@click.once="destroy")
        | {{$t('actions.delete')}}
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    deleteTarget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions('resources/x_newsletter_templates', [
      'deleteNewsletterTemplate'
    ]),

    close() {
      this.$bvModal.hide('newsletter-template-delete-modal');
    },

    destroy() {
      this.deleteNewsletterTemplate(this.deleteTarget.id).then(response => {
        if (response.status === 200) {
          this.$bvModal.hide('newsletter-template-delete-modal');
          this.$router.go({ name: 'x_newsletter_templates' });
        }
      });
    }
  }
};
</script>
