<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | {{ $t('admin.chatGPTClassifications.list.title') }}

  .row.flex-grow
    .col-12
      .card.mb-4
        .card-body
          .row.mb-4
            .col-3
              | ID
            .col-9
              | {{ task.id }}
          .row.mb-4
            .col-3
              | ステータス
            .col-9
              | {{ task.status }}

  .row.flex-grow
    .col-12
      .card.mb-4
        .card-body
          .row.mb-4
            .col-12
              | クラスタリング結果
          .row.mb-4
            .col-12
              .card(v-if="task.clustering_result")
                .card-body
                  .row.mb-2(v-for="(v, k, idx) in clusters")
                    .col-1
                      | {{ idx + 1 }}
                    .col-3
                      | {{ k }}
                    .col-8
                      | {{ v }}
          .row.mb-4
            .col-8
            .col-4
              form
                .alert.alert-danger(v-if="errorMessages.length > 0")
                  ul.mb-0
                    li(v-for="error in errorMessages")
                      | {{ error }}

                validation-observer(v-slot="{ invalid }")
                  validation-provider(name="labelsNum" rules="required|numeric" v-slot="{ errors }")
                    .form-group(:class="{ 'has-danger': errors.length}")
                      label
                        | Number of clusters
                      input(
                        type="text"
                        :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                        v-model="labels_num"
                      )
                      label.error.mt-2.text-danger(v-show="errors.length")
                        | {{ errors[0] }}

                  .mt-1.float-right
                    button.btn.btn-success(
                      :disabled="processing || invalid"
                      @click.prevent="reClustering"
                    )
                      | 再クラスタリング実行

          hr
          .row.mb-4
            .col-12
              form
                .alert.alert-danger(v-if="errorMessages.length > 0")
                  ul.mb-0
                    li(v-for="error in errorMessages")
                      | {{ error }}

                validation-observer(v-slot="{ invalid }")
                  validation-provider(name="labels" rules="required" v-slot="{ errors }")
                    .form-group(:class="{ 'has-danger': errors.length}")
                      label
                        | Labels
                      input(
                        type="text"
                        :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                        v-model="labels"
                      )
                      label.error.mt-2.text-danger(v-show="errors.length")
                        | {{ errors[0] }}

          .row.mb-4
            .col-6
            .col-3
              form
                .mt-5.float-right
                  button.btn.btn-success(
                    :disabled="processing"
                    @click.prevent="testSubmit"
                  )
                    | 多クラス分類(テスト実行)
            .col-3
              form
                .mt-5.float-right
                  button.btn.btn-success(
                    :disabled="processing"
                    @click.prevent="submit"
                  )
                    | 多クラス分類実行

  .row.flex-grow
    .col-12
      .card.mb-4
        .card-body
          .row.mb-4
            .col-12
              | 多クラス分類用ラベル
          .row.mb-4
            .col-12
              .card
                .card-body
                  .row.mb-2(v-for="(label, idx) in task.labels")
                    .col-2
                      | {{ idx + 1 }}
                    .col-10
                      | {{ label }}

          .row.mb-4
            .col-12
              form
                .mt-5.float-right
                  button.btn.btn-success(
                    :disabled="processing"
                    @click.prevent="confirmResult"
                  )
                    | 完了チェック

          .row.mb-4
            .col-12
              form
                .mt-5.float-right
                  button.btn.btn-success(
                    :disabled="task.status !== 'classification_finished' || processing"
                    @click.prevent="importResult"
                  )
                    | インポート

          .row.mb-4
            .col-12
              .mt-5.float-right
                router-link(:to="{ name: 'x_result_chatgpt_classification' }")
                  | 分類結果確認

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      clusters: [],
      labels_num: 10,
      errorMessages: [],
      processing: false,
      labels: ''
    };
  },

  computed: {
    ...mapState('resources/x_chatgpt_classifications', ['task'])
  },

  created() {
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_chatgpt_classifications', [
      'fetchTask',
      'requestReclustering',
      'requestClassification',
      'requestConfirmation',
      'requestImport',
      'requestTestClassification'
    ]),

    load() {
      let params = this.$route.params;
      this.fetchTask(params.id).then(() => {
        if (this.task.clustering_result) {
          this.clusters = JSON.parse(this.task.clustering_result);
          this.setLabels();
        }
      });
    },

    setLabels() {
      if (this.clusters.length === 0) {
        return;
      }

      this.labels = Object.keys(this.clusters).join(',');
    },

    reClustering() {
      this.processing = true;
      this.requestReclustering({
        id: this.task.id,
        labels_num: this.labels_num
      }).then(() => {
        this.processing = false;
      });
    },

    submit() {
      this.processing = true;
      this.requestClassification({
        id: this.task.id,
        labels: this.labels
      }).then(() => {
        this.processing = false;
      });
    },

    confirmResult() {
      this.processing = true;
      this.requestConfirmation(this.task).then(() => {
        this.processing = false;
      });
    },

    importResult() {
      if (this.task.status !== 'classification_finished') {
        return;
      }

      this.processing = true;
      this.requestImport(this.task).then(() => {
        this.processing = false;
      });
    },

    testSubmit() {
      this.processing = true;
      this.requestTestClassification({
        id: this.task.id,
        labels: this.labels
      }).then(() => {
        this.processing = false;
      });
    }
  }
};
</script>

<style scoped></style>
