<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content.vc-dashboard.vc-topic
        SurveyOrganizationSelect(@input="changeSurveyId")
        TopicNav
        div(v-if="dataLoaded")
          .vc-main-container.container(v-if="isTopic")
            .vc-content-col(v-if="topicRanking.length < 1")
              .container
                | 回答が{{diplayableNum()}}以下は表示できません。分析対象の組織を選びなおしてください
            div(v-else)
              .vc-dashboard-box.mb-4
                .container-fluid
                  .row.ml-1(v-show="survey.question_text")
                    .col-12
                      | {{ survey.question_text }}
              .vc-dashboard-box.mb-4(v-for="contents in topicRanking")
                .container-fluid
                  .row
                    p.small.text-right.w-100.pr-3.mb-0
                      | ※ 割合の算出方法：「{{contents[0]}}」属性値の「ネガティブ | ポジティブ」意見数
                      | ／
                      | 全体意見数
                    .col-12
                      .row.vc-dashboard-box-content
                        .col-12.vc-dashboard-box-title
                          h4.vc-midashi.mb-2
                            | {{ contents[0] }}
                        .dashboard-comp-table-col.vc-dashboard-box-table-wrap.topic-comp-table-col(
                          v-for="negaposi in contents.filter(content => content !== contents[0])"
                        )
                          .row
                            .col-12.vc-dashboard-box-title
                              h5.vc-midashi.mb-2
                                | {{ negaposi === contents[1] ? 'ネガティブランキング' : 'ポジティブランキング' }}
                          .row.no-gutters.vc-respondent-table
                            .col-1
                              | 順位
                            .col-6.text-left.overflow-hidden.text-nowrap
                              | {{ contents[0] }}
                            .col-5
                              .row.no-gutters
                                .col-4
                                .col-4 割合
                                .col-4 件数
                          .vc-dashboard-box-table-data-row.no-scroll
                            .row.no-gutters.vc-respondent-table(v-for="data in negaposi" v-show="negaposi.indexOf(data) < 5 || displayAll[contents[0]]")
                              .col-1
                                | {{ranking(negaposi, data[2])}}
                              .col-6.text-left.overflow-hidden.text-nowrap.table-column-name
                                div
                                  | {{ data[0][0] }}
                              .col-5
                                .row.no-gutters.vc-respondent-table-num
                                  .col-4.text-right
                                    a(
                                      role="button"
                                      @click="toOpinionSearch(emotionIds[negaposi === contents[1] ? 'ネガティブ' : 'ポジティブ'], data[0][1])"
                                    )
                                      svg.to-opinion-search(
                                        xmlns="http://www.w3.org/2000/svg"
                                        enable-background="new 0 0 24 24"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        fill="#000000"
                                      )
                                        g
                                          rect(fill="none" height="24" width="24")
                                        g
                                          g
                                            rect(height="2" width="9" x="13" y="7")
                                            rect(height="2" width="9" x="13" y="15")
                                            rect(height="2" width="6" x="16" y="11")
                                            polygon(points="13,12 8,7 8,11 2,11 2,13 8,13 8,17")
                                  .col-4
                                    div
                                      | {{ data[1].toFixed(1) }}%
                                  .col-4
                                    div
                                      | {{ data[2] }}
                        .col-12.text-right.mt-2(v-show="contents[1].length > 5")
                          a(role="button" v-show="!displayAll[contents[0]]" @click="toggleDisplayAll(contents[0])")
                            | ランキングを全て表示 ▽
                          a(role="button" v-show="displayAll[contents[0]]" @click="toggleDisplayAll(contents[0])")
                            | 閉じる △
          .vc-main-container.container(v-if="!isTopic")
            .vc-content-col
              .container
                | トピック調査以外の調査については、この画面はご利用できません。調査選択から、トピック調査を選択ください。
        .vc-main-container.container(v-else)
          .vc-content-col
            .container
              | 読み込み中です。しばらくお待ちください。
</template>
<script>
import { mapState, mapActions } from 'vuex';
import SurveyOrganizationSelect from '../../../components/u/SurveyOrganizationSelect';
import TopicNav from '../../../components/u/topic/TopicNav';

export default {
  components: {
    SurveyOrganizationSelect,
    TopicNav
  },
  data() {
    return {
      surveyId: null,
      dataLoaded: false,
      displayAll: {}
    };
  },
  computed: {
    ...mapState('pages/u_surveys/select', ['isTopic']),
    ...mapState('resources/u_topic', ['topicRanking', 'emotionIds', 'survey'])
  },
  watch: {
    surveyId(newId, oldId) {
      if (newId !== oldId) this.loadRanking();
    },
    topicRanking() {
      this.setDisplayAll();
    }
  },
  created() {
    this.loadControlSettings();
  },
  mounted() {},
  methods: {
    ...mapActions('resources/u_topic', ['loadTopicRanking']),
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    loadRanking() {
      this.loadTopicRanking({
        survey_id: this.surveyId
      }).then(() => {
        this.dataLoaded = true;
      });
    },
    changeSurveyId(data) {
      this.surveyId = data['surveyId'];
    },
    toOpinionSearch: function(emotionClassificationId, attributeValue) {
      let params = {
        emotion_classification: emotionClassificationId,
        survey_id: this.surveyId,
        backToName: 'u_topic_ranking'
      };
      if (attributeValue) params.attribute_values = [attributeValue];
      this.$router.push({
        name: 'u_opinion_search',
        params: params
      });
    },
    diplayableNum() {
      const name = 'number_of_displayable_data';
      if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
        return this.controlSettings[name]['values']
          ? this.controlSettings[name]['values']
          : 0;
      }
      return 0;
    },
    toggleDisplayAll(category) {
      this.displayAll[category] = !this.displayAll[category];
    },
    setDisplayAll() {
      let displayAll = {};
      this.topicRanking.forEach(contents => {
        displayAll[contents[0]] = false;
      });
      this.displayAll = displayAll;
    },
    ranking(categories, counting) {
      let arr = [];
      categories.forEach(element => {
        arr.push(element[2]);
      });
      let arr1 = [...new Set(arr)];
      arr1 = arr1.sort(function(a, b) {
        return b - a;
      });
      return arr1.indexOf(counting) + 1;
    }
  }
};
</script>
