<template lang="pug">
  b-modal(
    id="user-disable-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    :title="$t('admin.users.disable.title')"
  )
    b-container
      p
        | {{$t('actions.disableConfirm')}}
      p
        span.text-danger
          | {{ disableTarget.name }}
    div(slot="modal-footer")
      button.btn.btn-secondary(@click="close")
        | {{$t('actions.close')}}
      button.btn.btn-danger.ml-2(@click.once="disable")
        | {{$t('actions.disable')}}
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    disableTarget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions('resources/x_users', ['disableUser']),

    close() {
      this.$bvModal.hide('user-disable-modal');
    },

    disable() {
      this.disableUser(this.disableTarget.id).then(response => {
        if (response.status === 200) {
          this.$bvModal.hide('user-disable-modal');
          this.$router.go({ name: 'x_users' });
        }
      });
    }
  }
};
</script>
