import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('r_chat_interviews');

export default {
  namespaced: true,

  state: {
    inquiry: {},
    chatInterviews: []
  },

  getters: {},

  actions: {
    /**
     * ChatInterview一覧取得
     */
    loadChatInterviews({ commit }, inquiry_id) {
      return gateway.get({ action: 'index', inquiry_id }).then(response => {
        if (response.status === 200) {
          if (!response.body.empty_nickname) {
            const chatInterviews = response.body.chat_interviews;
            const inquiry = response.body.inquiry;
            commit('setChatInterviews', chatInterviews);
            commit('setInquiry', inquiry);
          }
        }
        return response;
      });
    },

    /**
     * ChatInterviewの更新
     * @param {*} _
     * @param {*} params パラメーター
     */
    updateChatInterview(_, params) {
      return gateway
        .put(
          {
            action: 'update',
            inquiry_id: params.inquiry_id,
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    /**
     * ChatInterviewの削除
     * @param {*} _
     * @param {*} params パラメーター
     */
    removeChatInterview(_, params) {
      return gateway
        .delete({
          action: 'remove',
          inquiry_id: params.inquiry_id,
          id: params.id
        })
        .then(response => {
          return response;
        });
    }
  },

  mutations: {
    setChatInterviews(state, chatInterviews) {
      state.chatInterviews = chatInterviews;
    },

    setInquiry(state, inquiry) {
      state.inquiry = inquiry;
    },

    setChatInterview(state, chatInterview) {
      state.chatInterviews.push(chatInterview);
    },

    /**
     * chatInterviewsのリストから削除アイテムを取り除く
     * @param {*} state
     * @param {*} params
     */
    spliceChatInterview(state, params) {
      state.chatInterviews.splice(state.chatInterviews.indexOf(params.item), 1);
    }
  }
};
