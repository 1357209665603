<template lang="pug">
  .content-wrapper
    .row.flex-grow
      .col-6
        .card
          .card-body
            h4.card-title
              | {{ $t('admin.accountGroups.new.title') }}

            form
              .alert.alert-danger(v-if="errorMessages.length > 0")
                ul.mb-0
                  li(v-for="error in errorMessages")
                    | {{ error }}
              validation-observer(v-slot="{ invalid }")
                validation-provider(name="name" rules="required" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.accountGroups.name') }}
                    input(
                      type="text"
                      placeholder="アカウントグループ名"
                      :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      v-model="name"
                    )
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                validation-provider(name="code" rules="required" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.accountGroups.code') }}
                    input(
                      type="text"
                      placeholder="アカウントグループコード"
                      :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      v-model="code"
                    )
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                .form-group
                  input#default_qualitative_menu(
                    type="checkbox"
                    v-model="default_qualitative_menu.enabled"
                  )
                  label.ml-1.align-middle(for="default_qualitative_menu")
                    | 定性分析デフォルトメニュー
                  select.form-control.ml-3.w-50(
                    v-model="default_qualitative_menu.values"
                    :disabled="!default_qualitative_menu.enabled"
                  )
                    option(
                      v-for="(name, key) in menuNames.qualitative"
                      :value="key"
                      :key="key"
                    )
                      | {{name}}

                .form-group
                  input#default_quantitative_menu(
                    type="checkbox"
                    v-model="default_quantitative_menu.enabled"
                  )
                  label.ml-1.align-middle(for="default_quantitative_menu")
                    | 定量分析デフォルトメニュー
                  select.form-control.ml-3.w-50(
                    v-model="default_quantitative_menu.values"
                    :disabled="!default_quantitative_menu.enabled"
                  )
                    option(
                      v-for="(name, key) in menuNames.quantitative"
                      :value="key"
                      :key="key"
                    )
                      | {{name}}

                .form-group
                  input#number_of_displayable_data(
                    type="checkbox"
                    v-model="number_of_displayable_data.enabled"
                  )
                  label.ml-1.align-middle(for="number_of_displayable_data")
                    | 分析可能回答者数
                  input.form-control.w-25.ml-3(
                    type="text"
                    placeholder="3"
                    v-model="number_of_displayable_data.values"
                    :disabled="!number_of_displayable_data.enabled"
                  )

                .form-group
                  input#hide_opinion_search_attributes(
                    type="checkbox"
                    v-model="hide_opinion_search_attributes.values"
                  )
                  label.ml-1.align-middle(for="hide_opinion_search_attributes")
                    | 意見検索時属性を表示

                .form-group
                  input#default_ranking_filter(
                    type="checkbox"
                    v-model="default_ranking_filter.enabled"
                  )
                  label.ml-1.align-middle(for="default_ranking_filter")
                    | ランキング表示階層のデフォルト値
                  input#business.ml-3(
                    type="radio"
                    name="business"
                    value="business"
                    v-model="default_ranking_filter.values"
                    :disabled="!default_ranking_filter.enabled"
                  )
                  label.ml-1.align-middle(for="business")
                    | 第一階層
                  input#base.ml-3(
                    type="radio"
                    name="base"
                    value="base"
                    v-model="default_ranking_filter.values"
                    :disabled="!default_ranking_filter.enabled"
                  )
                  label.ml-1.align-middle(for="base")
                    | 第二階層
                  input#department.ml-3(
                    type="radio"
                    name="department"
                    value="department"
                    v-model="default_ranking_filter.values"
                    :disabled="!default_ranking_filter.enabled"
                  )
                  label.ml-1.align-middle(for="department")
                    | 第三階層

                //- .form-group
                //-   input#display_topic_survey_menu(
                //-     type="checkbox"
                //-     v-model="display_topic_survey_menu.values"
                //-   )
                //-   label.ml-1.align-middle(for="display_topic_survey_menu")
                //-     | ヘッダのトピック調査のメニューを表示

                .form-group
                  input#period_to_user_disabled(
                    type="checkbox"
                    v-model="period_to_user_disabled.enabled"
                  )
                  label.ml-1.align-middle(for="period_to_user_disabled")
                    | アカウント無効化までの期間
                  input.form-control.w-25.ml-3(
                    type="text"
                    placeholder="12"
                    v-model="period_to_user_disabled.values"
                    :disabled="!period_to_user_disabled.enabled"
                  )
                  small
                    | ヶ月

                .form-group
                  label.ml-1.align-middle(for="co_occurrence_type")
                    | 共起ネットワークの種類
                  input#khcoder.ml-3(
                    type="radio"
                    name="khcoder"
                    value="khcoder"
                    v-model="co_occurrence_type"
                  )
                  label.ml-1.align-middle(for="khcoder")
                    | KHCoder
                  input#python.ml-3(
                    type="radio"
                    name="python"
                    value="python"
                    v-model="co_occurrence_type"
                  )
                  label.ml-1.align-middle(for="python")
                    | オリジナル共起ネットワーク

                //- .form-group
                //-   input#display_chats(
                //-     type="checkbox"
                //-     v-model="display_chats.values"
                //-   )
                //-   label.ml-1.align-middle(for="display_chats")
                //-     | チャット機能を表示

                .mt-1.float-right
                  router-link.btn.btn-light.mr-2(:to="{ name: 'x_account_groups' }")
                    | {{ $t('actions.cancel') }}
                  button.btn.btn-success(
                    :disabled="processing || invalid"
                    @click.prevent="submit"
                  )
                    | {{ $t('actions.create') }}

</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      name: '',
      code: '',
      number_of_displayable_data: {
        enabled: false,
        values: 3
      },
      hide_opinion_search_attributes: {
        enabled: false,
        values: false
      },
      default_ranking_filter: {
        enabled: false,
        values: 'base'
      },
      display_topic_survey_menu: {
        enabled: false,
        values: false
      },
      period_to_user_disabled: {
        enabled: false,
        values: 12
      },
      display_chats: {
        enabled: true,
        values: true
      },
      default_qualitative_menu: {
        enabled: false,
        values: 'qualitative_emotion_classifications_team'
      },
      default_quantitative_menu: {
        enabled: false,
        values: 'quantitative_analyse'
      },
      co_occurrence_type: 'khcoder',
      menuNames: [],
      errorMessages: [],
      processing: false
    };
  },

  computed: {
    accountGroupParams() {
      return {
        account_group: {
          name: this.name,
          code: this.code,
          co_occurrence_type: this.co_occurrence_type
        },
        number_of_displayable_data: this.number_of_displayable_data,
        hide_opinion_search_attributes: this.hide_opinion_search_attributes,
        default_ranking_filter: this.default_ranking_filter,
        display_topic_survey_menu: this.display_topic_survey_menu,
        period_to_user_disabled: this.period_to_user_disabled,
        display_chats: this.display_chats,
        default_qualitative_menu: this.default_qualitative_menu,
        default_quantitative_menu: this.default_quantitative_menu
      };
    }
  },

  created() {
    this.menuNames = this.$t('user.nav');
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_account_groups', ['createAccountGroup']),

    resetErrorMessages() {
      this.errorMessages = [];
    },

    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.createAccountGroup(this.accountGroupParams)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'x_account_groups' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
