<template lang="pug">
  #left-filter
    p.vc-left-col-title
    .text-right
      a.attribute-values-btn.mr-3.mb-0(v-show="!displayAttributeValues" @click="toggleDisplayAttributeValues") >> 属性でフィルタ
    .vc-cat
      ul
        li
          .custom-control.custom-checkbox.vc-custom-checkbox
            input#organization-all(
              type="checkbox"
              class="custom-control-input"
              v-model="selectAll"
            )
            label(
              class="custom-control-label"
              for="organization-all"
            )
              | 全社
          ul.organization-ul
            li(v-for="(organization, i) in navOrganizations")
              .custom-control.custom-checkbox.vc-custom-checkbox
                input(
                  type="checkbox"
                  class="custom-control-input"
                  :id="'organization'+organization.id"
                  :value="organization.id"
                  v-model="currentSelected"
                  @click="toggleSelectedOrganizations(organization.id)"
                )
                label(
                  class="custom-control-label"
                  :for="'organization'+organization.id"
                )
                  | {{organization.name}}
                span.float-right
                  img.organizations-hide-btn.ml-2(
                    src="../../../assets/images/excheck/chevron-down.png" width="18"
                    :class="{'reverse': displayOrganizations[organization.id]}"
                    @click.prevent="toggleDisplayOrganizations(organization.id)"
                  )
              ul.organization-ul(v-show="displayOrganizations[organization.id]")
                li(v-for="(childOrg, ii) in organization.children")
                  .custom-control.custom-checkbox.vc-custom-checkbox
                    input(
                      type="checkbox"
                      class="custom-control-input"
                      :id="'organization'+childOrg.id"
                      :value="childOrg.id"
                      v-model="currentSelected"
                    )
                    label(
                      class="custom-control-label"
                      :for="'organization'+childOrg.id"
                    )
                      | {{childOrg.name}}

    //- .vc-submit-wrap.mb-4
    //-   button(
    //-     class="btn btn-primary btn-block vc-primary-btn"
    //-     :disabled="processing"
    //-     @click.prevent="submit"
    //-   )
    //-     | フィルタを設定

</template>
<script>
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';

export default {
  components: {},

  props: {},

  data() {
    return {
      currentSelected: [],
      processing: false,
      selectAll: true,
      displayOrganizations: {},
      isStarted: false
    };
  },

  computed: {
    ...mapState('resources/u_quantitative', [
      'navOrganizations',
      'displayAttributeValues'
    ])
  },

  watch: {
    selectAll() {
      if (this.selectAll) this.allSelect();
      else this.clearSelect();
    },
    currentSelected() {
      this.processing = true;
      if (this.currentSelected.length > 0) {
        if (this.isStarted) this.setOrganizationIds(this.currentSelected);
      } else this.setOrganizationIds([]);
      this.processing = false;
    },
    navOrganizations() {
      if (this.navOrganizations.length > 0) this.allSelect();
    }
  },

  created() {
    let promise = new Promise((resolve, _reject) => {
      if (this.navOrganizations.length > 0) this.allSelect();
      resolve();
    });
    promise.then(() => {
      this.isStarted = true;
    });
    this.navOrganizations.forEach(org => {
      Vue.set(this.displayOrganizations, org.id, false);
    });
  },

  mounted() {},

  methods: {
    ...mapMutations('resources/u_quantitative', [
      'setOrganizationIds',
      'setDisplayAttributeValues'
    ]),
    allSelect() {
      const orgs = this.navOrganizations;
      let selected = [];
      for (let i in orgs) {
        const children = orgs[i].children;
        selected.push(orgs[i].id);
        for (let ii in children) {
          const grandChildren = children[ii].children;
          selected.push(children[ii].id);
          for (let iii in grandChildren) {
            selected.push(grandChildren[iii].id);
          }
        }
      }
      this.currentSelected = selected.sort();
    },
    clearSelect() {
      this.currentSelected = [];
    },
    toggleDisplayAttributeValues() {
      this.setDisplayAttributeValues(!this.displayAttributeValues);
      this.$emit('matchHeights');
    },
    toggleDisplayOrganizations(organizationId) {
      Vue.set(
        this.displayOrganizations,
        organizationId,
        !this.displayOrganizations[organizationId]
      );
    },
    toggleSelectedOrganizations(organizationId) {
      const parent = this.navOrganizations.find(
        org => org.id === organizationId
      );
      const children = parent.children;
      let ids = children.map(org => org.id);
      ids.push(organizationId);
      if (this.currentSelected.includes(organizationId))
        ids.forEach(
          id =>
            (this.currentSelected = this.currentSelected.filter(
              cId => cId !== id
            ))
        );
      else {
        const arr = this.currentSelected.concat(ids);
        this.currentSelected = arr;
      }
    }
  }
};
</script>
<style scoped>
.organization-ul {
  margin-left: 1rem;
}
#left-filter .attribute-values-btn {
  cursor: pointer;
  color: #444 !important;
}
#left-filter .attribute-values-btn:hover {
  color: #444 !important;
}
.organizations-hide-btn {
  cursor: pointer;
}
.custom-control-label {
  width: 82%;
}
.vc .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #f3154a !important;
  background: #f3154a !important;
}
</style>
