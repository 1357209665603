<template lang="pug">
.container-fruid
  .main-panel
    section.vc-content
      SurveyOrganizationSelect(@input="search")
      OpinionSearchNav
      .vc-main-container.container
        OpinionClassificationsSelect(id="leftNav" @input="search")
        Result(@input="search")
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SurveyOrganizationSelect from '../../../components/u/SurveyOrganizationSelect';
import OpinionClassificationsSelect from '../../../components/u/response_ranking/OpinionClassificationsSelect';
import Result from '../../../components/u/response_ranking/Result';
import OpinionSearchNav from '../../../components/u/response_ranking/OpinionSearchNav';

export default {
  components: {
    SurveyOrganizationSelect,
    OpinionClassificationsSelect,
    Result,
    OpinionSearchNav
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('pages/u_surveys/select', ['surveyId']),
    ...mapState('resources/u_response_ranking', [
      'organizationId',
      'currentOpinionValue',
      'opinionValues'
    ])
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    ...mapActions('resources/u_response_ranking', ['loadResponses']),
    ...mapMutations('resources/u_response_ranking', [
      'setLoading',
      'setStarted',
      'setOrganizationId',
      'setCurrentOpinionValueName',
      'setCurrentResponseId'
    ]),
    search(data) {
      const surveyId = data ? data.surveyId : this.surveyId;
      const organizationId = data ? data.organizationId : this.organizationId;
      const params = {
        survey_id: surveyId,
        organization_id: organizationId,
        opinion_classification_id: this.currentOpinionValue
      };
      if (data) this.setOrganizationId(organizationId);
      this.setStarted(true);
      this.loadResponses(params).then(() => {
        this.setCurrentResponseId(null);
        this.setCurrentOpinionValueName(
          this.opinionValues.find(arr => arr[0] === this.currentOpinionValue)[1]
        );
        this.setLoading(false);
      });
    }
  }
};
</script>
