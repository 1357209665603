<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content
        .vc-main-container.container
          .vc-content-col
            .container
              .row
                .col-12
                  h3.vc-midashi.mb-3
                    | マイページ
              hr.vc-full-divider
              .row.vc-other-page-col
                .col-6
                  dl
                    //- dt
                    //-   | 所属組織
                    //- dd.mb-4
                    //-   | {{organization.name}}
                    dt
                      | メールアドレス
                    dd.mb-4
                      | {{operator.email}}
                    dt
                      | パスワード
                    dd
                      | ********
                      br
                      | パスワードを変更したい場合はログアウトし、ログイン画面の「パスワードをお忘れですか？」から、再設定をしてください。
                    dt(v-if="accountGroups.length > 1")
                      | メインアカウントグループ
                    dd(v-if="accountGroups.length > 1")
                      .row
                        .col-10
                          select.form-control(v-model="accountGroupId")
                            option(
                              v-for="accountGroup in accountGroups"
                              v-bind:value="accountGroup.id"
                              v-bind:key="accountGroup.id"
                            )
                              | {{accountGroup.name}}
                        button.btn.btn-primary.vc-primary-btn.btn-select.ml-2(
                          :disabled="processing"
                          @click.prevent="submit"
                        )
                          | {{ $t('actions.update') }}
                    a(@click.prevent.stop="signOut" class="text-decoration-underline")
                      | ログアウトする

</template>

<script>
import { mapState, mapActions } from 'vuex';
import dc from '../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {},

  data() {
    return {
      accountGroupId: '',
      processing: false
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_mypage', ['user', 'accountGroups', 'organization'])
  },

  watch: {
    user: function() {
      this.accountGroupId = this.user.account_group_id;
    }
  },

  created() {
    this.loadMypage();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_mypage', ['updateAccountGroup', 'loadMypage']),
    signOut() {
      fetch(this.operator.sign_out_path, { method: 'DELETE' })
        .then(response => {
          if (response.status === 200) {
            return response.json().then(body => {
              return {
                status: response.status,
                redirect_path: body.redirect_url
              };
            });
          }
        })
        .then(response => {
          location.href = response.redirect_path;
        });
    },

    submit() {
      this.processing = true;
      const params = {
        id: this.user.id,
        account_group_id: this.accountGroupId
      };
      this.updateAccountGroup(params).then(response => {
        const surveyId = response.body.survey.id;
        defaultCookie.set({ surveyId: surveyId });
        location.href = 'qualitative/emotion_classifications/team';
      });
    }
  }
};
</script>
<style scoped>
.btn.btn-primary.vc-primary-btn.btn-select {
  padding: 0.4rem 1rem;
}
</style>
