<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | テンプレートアップロード
  .row.flex-grow
    .col-12
      .card
        .card-body
          form
            .alert.alert-danger(v-if="errorMessages.length > 0")
              ul.mb-0
                li(v-for="error in errorMessages")
                  | {{ error }}
            validation-observer(v-slot="{ invalid }")
              validation-provider(name="templateName" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | テンプレート名
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="name"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              .form-group
                label
                  | テンプレート内画像
                .row
                  .col-10
                    input.form-control(
                      type="text"
                      v-model="imgFilesTxt"
                      readonly
                    )
                  .col-2.text-right
                    label.btn.btn-secondary ファイル添付
                      input.d-none(
                        type="file"
                        :accept="acceptImgFiletypes"
                        @change="onFileChange($event, 'imgs')"
                        multiple
                      )
                  .col-4
                    .row.small(v-for="img in uploadedImgFiles")
                      .col-6
                        | {{ img.name }}
                      .col-6
                        input(type="hidden" :id="'copy-img-url' + img.id" :value="img.image_url")
                        a.img-action-link.mr-2(@click="copyImgFileUrl(img)")
                          | URLコピー
                        a.img-action-link(@click="deleteImgFile(img.id)")
                          | 削除

              .form-group
                label
                  | HTMLファイル
                .row
                  .col-10
                    input.form-control(
                      type="text"
                      v-model="htmlFileTxt"
                      readonly
                    )
                  .col-2.text-right
                    label.btn.btn-secondary ファイル添付
                      input.d-none(
                        type="file"
                        :accept="acceptHtmlFiletypes"
                        @change="onFileChange($event, 'html')"
                        multiple
                      )

              .mt-5.float-right
                router-link.btn.btn-light.mr-2(:to="{ name: 'x_newsletter_templates' }")
                  | {{ $t('actions.cancel') }}
                button.btn.btn-success(
                  :disabled="processing || invalid"
                  @click.prevent="submit"
                )
                  | アップロード

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},
  data() {
    return {
      name: '',
      imgFilesTxt: '',
      uploadedImgFiles: [],
      htmlFileTxt: '',
      imgFiles: [],
      htmlFile: '',
      errorMessages: [],
      processing: false,
      acceptImgFiletypes: 'image/jpeg,image/png,image/gif',
      acceptHtmlFiletypes: 'text/html'
    };
  },
  computed: {
    ...mapState('resources/x_newsletter_templates', ['template'])
  },
  watch: {
    template: function() {
      this.name = this.template.name;
      this.htmlFileTxt = this.template.html_file_name;
      this.uploadedImgFiles = this.template.images;
    }
  },
  created() {
    this.loadTemplate(this.$route.params.id).then(response => {
      const template = response.body.template;
      this.name = template.name;
      this.htmlFileTxt = template.html_file_name;
      this.uploadedImgFiles = template.images;
    });
  },
  mounted() {},
  methods: {
    ...mapActions('resources/x_newsletter_templates', [
      'loadTemplate',
      'deleteTemplateImgFile'
    ]),
    resetErrorMessages() {
      this.errorMessages = [];
    },
    onFileChange(e, filetype) {
      this.resetErrorMessages();
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      if (filetype === 'imgs') {
        this.imgFiles = files;
        let arr = [];
        for (let i = 0; i < this.imgFiles.length; i++) {
          const file = this.imgFiles[i];
          arr.push(file.name);
        }
        this.imgFilesTxt = arr.join(', ');
      }
      if (filetype === 'html') {
        this.htmlFile = files[0];
        this.htmlFileTxt = this.htmlFile.name;
      }
    },
    submit() {
      this.resetErrorMessages();
      this.noticeMessages = [
        '処理中...。アップロードの完了までもう少しお待ちください。'
      ];
      let path = '/api/x/newsletter_templates/' + this.$route.params.id;
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('html_file', this.htmlFile);
      for (let idx = 0; idx < this.imgFiles.length; idx++) {
        formData.append('img_files[]', this.imgFiles[idx]);
      }

      fetch(path, {
        method: 'PUT',
        body: formData
      }).then(response => {
        this.noticeMessages = [];
        if (response.status === 200) {
          this.$router.push({ name: 'x_newsletter_templates' });
        } else {
          response.json().then(body => {
            this.errorMessages = body.errors;
          });
        }
      });
    },
    deleteImgFile(targetId) {
      this.deleteTemplateImgFile(targetId);
    },
    copyImgFileUrl(img) {
      let urlEl = document.querySelector('#copy-img-url' + img.id);

      urlEl.setAttribute('type', 'text');
      urlEl.select();

      document.execCommand('copy');

      urlEl.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    }
  }
};
</script>
<style scoped>
.form-control[readonly] {
  background-color: #fff;
}
.img-action-link {
  font-size: inherit !important;
  color: #444 !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}
</style>
