import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_organizations');

export default {
  namespaced: true,

  state: {
    defaultOrganizationId: null,
    organizations: {},
    organizationIds: [],
    parentIdByIds: {},
    comparisonTargetIds: []
  },

  getters: {},

  actions: {
    /**
     * 組織一覧取得
     */
    loadOrganizations({ commit }, survey_id) {
      return gateway
        .get({ action: 'index' }, { survey_id: survey_id })
        .then(response => {
          if (response.status === 200) {
            commit('setOrganizations', response.body.organizations);
            commit(
              'setDefaultOrganizationId',
              response.body.default_organization_id
            );
          }
          return response;
        });
    },
    /**
     * 組織比較対象取得
     */
    loadOrganizationComparison({ commit }, organization_id) {
      return gateway
        .get(
          { action: 'organization_comparison' },
          { organization_id: organization_id }
        )
        .then(response => {
          if (response.status === 200) {
            commit(
              'setComparisonTargetIds',
              response.body.organization_comparison
            );
          }
          return response;
        });
    }
  },

  mutations: {
    setOrganizations(state, organizations) {
      const _organizations = {};
      const _parentIdByIds = {};
      const _organizationIds = [];
      for (const i in organizations) {
        _parentIdByIds[organizations[i].id] = organizations[i].parent_id;
        _organizationIds.push(organizations[i].id);
        const index = organizations[i].parent_id
          ? organizations[i].parent_id
          : 0;
        if (!_organizations[index]) _organizations[index] = [];
        _organizations[index].push(organizations[i]);
      }
      state.organizations = _organizations;
      state.organizationIds = _organizationIds;
      state.parentIdByIds = _parentIdByIds;
    },
    setDefaultOrganizationId(state, defaultOrganizationId) {
      state.defaultOrganizationId = defaultOrganizationId;
    },
    setComparisonTargetIds(state, organization_comparison) {
      state.comparisonTargetIds = organization_comparison
        ? organization_comparison.destination_ids.map(Number)
        : [];
    }
  }
};
