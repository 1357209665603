import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_mypage');

export default {
  namespaced: true,

  state: {
    user: {},
    accountGroups: [],
    organization: {}
  },

  getters: {},

  actions: {
    /**
     * マイページのデータ取得
     */
    loadMypage({ commit }) {
      return gateway.get({ action: 'index' }).then(response => {
        if (response.status === 200) {
          const user = response.body.user;
          const accountGroups = response.body.account_groups;
          const organization = response.body.organization;
          commit('setUser', user);
          commit('setAccountGroups', accountGroups);
          commit('setOrganization', organization);
        }
        return response;
      });
    },

    updateAccountGroup(_, params) {
      return gateway
        .put(
          {
            action: 'update_account_group',
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    }
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setAccountGroups(state, accountGroups) {
      state.accountGroups = accountGroups;
    },
    setOrganization(state, organization) {
      state.organization = organization;
    }
  }
};
