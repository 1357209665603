<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content
        .vc-main-container.container
          .vc-content-col#userList
            .container
              .row
                .col-12
                  h3.vc-midashi.mb-3
                    | ユーザー編集
                  hr.vc-full-divider
              .row(v-if="ready")
                .col-6
                  form
                    error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
                    validation-observer(v-slot="{ invalid }")
                      validation-provider(name="userName" rules="" v-slot="{ errors }")
                        .form-group(:class="{ 'has-danger': errors.length}")
                          label.cc-label-mid
                            | ユーザー名
                          input(
                            type="text"
                            placeholder="Suzuki Taro"
                            maxlength="16"
                            :class="{ 'form-control': true, 'form-control-danger': errors.length}"
                            v-model="userName"
                          )
                          label.error.mt-2.text-danger(v-show="errors.length")
                            | {{ errors[0] }}

                      validation-provider(name="email" rules="required|email" v-slot="{ errors }")
                        .form-group(:class="{ 'has-danger': errors.length}")
                          label.cc-label-mid
                            | メールアドレス
                          input(
                            type="email"
                            placeholder="admin@example.com"
                            maxlength="64"
                            :class="{ 'form-control': true, 'form-control-danger': errors.length}"
                            v-model="userEmail"
                            required
                          )
                          label.error.mt-2.text-danger(v-show="errors.length")
                            | {{ errors[0] }}

                      validation-provider(name="roleId" rules="required" v-slot="{ errors }")
                        .form-group(:class="{ 'has-danger': errors.length}")
                          label
                            | 権限
                          select(
                            :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                            v-model="roleId"
                          )
                            option(
                              v-for="role in roles"
                              v-bind:value="role.id"
                              v-bind:key="role.id"
                            )
                              | {{role.name}}
                          label.error.mt-2.text-danger(v-show="errors.length")
                            | {{ errors[0] }}

                      //- validation-provider(name="organizationId" rules="required" v-slot="{ errors }")
                      //-   .form-group(:class="{ 'has-danger': errors.length}")
                      //-     label.cc-label-mid
                      //-       | {{ $t('admin.users.organization') }}
                      //-     select(
                      //-       :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      //-       v-model="userOrganizationId"
                      //-     )
                      //-       optgroup(
                      //-         v-for="organizations in versionOrganizations"
                      //-         v-bind:label="organizations[0].organization_version_name"
                      //-         )
                      //-         option(
                      //-           v-for="organization in organizations"
                      //-           v-bind:value="organization.id"
                      //-           v-bind:key="organization.id"
                      //-         )
                      //-           | {{organization.code}} {{organization.name}}
                      //-     label.error.mt-2.text-danger(v-show="errors.length")
                      //-       | {{ errors[0] }}
                      .form-group.pt-3
                        label.cc-label-mid
                          | ステータス
                        div(v-if="user.invitation_accepted_at")
                          | {{ user.current_sign_in_at | moment('YYYY.MM.DD')}}
                          | ログイン
                        div(v-else)
                          | 未ログイン
                          a(@click.prevent.stop="submitInvitation()" class="text-decoration-underline ml-5" v-if="!sent")
                            | 招待メールを再送する
                          span.ml-5(v-else)
                            | 招待メールを送信しました
                          p.mt-1
                            small.help-block.text-muted
                              | ※ 招待メールの送り先を変更したい場合は、メールアドレス変更を保存した後、招待メールを再送するをクリックしてください。
                      .form-group.pt-3
                        label.cc-label-mid
                          | ロック
                        p(v-if="user.locked_at" v-show="user.locked_at")
                          i.fa.fa-lock.font-size-12
                          a(@click.prevent.stop="submitUnlock()" class="text-decoration-underline ml-5")
                            | ロックを解除する
                        p(v-else-if="unlocked")
                          | ロックを解除しました
                        p(v-else)
                          | -
                      .row.pt-3
                        .col-4.pt-2
                          a(@click.prevent.stop="showDeleteModal()" class="text-decoration-underline" v-if="operator.id != user.id")
                            | ユーザーを削除する
                        .col-8.text-right
                          button.btn.btn-primary.vc-primary-btn(
                            :disabled="processing || invalid"
                            @click.prevent="submit"
                          )
                            | 変更を保存する
                          router-link.btn.btn-light.vc-btn.ml-4(:to="{ name: 'u_users' }")
                            | キャンセル
                        
    UserDeleteModal(:deleteTarget="deleteTarget")

</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';
import UserDeleteModal from 'src/components/u/users/UserDeleteModal';

export default {
  components: {
    ErrorMessage,
    UserDeleteModal
  },

  data() {
    return {
      ready: false,
      errorMessages: [],
      processing: false,
      // versionOrganizations: {},
      deleteTarget: {},
      sent: false,
      unlocked: false,
      roleId: ''
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    // ...mapState('resources/u_users', [
    //   'organizations'
    // ]),
    ...mapState('pages/u_users/user', { user: state => state }),
    ...mapState('resources/u_roles', ['roles']),

    userName: {
      get() {
        return this.user.name;
      },
      set(newName) {
        this.setUser({ user: { name: newName } });
      }
    },

    userEmail: {
      get() {
        return this.user.email;
      },
      set(newName) {
        this.setUser({ user: { email: newName } });
      }
    },

    // userOrganizationId: {
    //   get() {
    //     return this.user.organizationId;
    //   },
    //   set(newOrganizationId) {
    //     this.setUser({ user: { organization_id: newOrganizationId } });
    //   }
    // },

    /**
     * サーバサイドに渡す user のパラメータを生成
     *
     * @return {object}
     */
    userParams() {
      return {
        id: this.user.id,
        user: {
          name: this.user.name,
          email: this.user.email,
          account_group_id: this.user.accountGroupId,
          // organization_id: this.user.organizationId,
          role_id: this.roleId
        }
      };
    }
  },

  watch: {},

  created() {
    this.loadUser();
    // this.loadOrganizations();
  },

  mounted() {},

  methods: {
    // ...mapActions('resources/u_users', [
    //   'loadOrganizations'
    // ]),
    ...mapActions('resources/u_users', [
      'editUser',
      'updateUser',
      'sendInvitation',
      'unlock'
    ]),
    ...mapMutations('pages/u_users/user', ['setUser']),

    loadUser() {
      this.editUser(this.$route.params.id).then(response => {
        if (response.status === 200) {
          this.setUser({
            user: response.body.user
          });
          this.roleId = response.body.user.role_id;
          this.ready = true;
        }
      });
    },

    resetErrorMessages() {
      this.errorMessages = [];
    },

    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.updateUser(this.userParams)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'u_users' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },

    submitUnlock() {
      this.unlock(this.user.id).then(response => {
        if (response.status === 200) {
          this.unlocked = true;
          this.setUser({ user: response.body.user });
        }
      });
    },

    submitInvitation() {
      this.sendInvitation(this.user.id).then(response => {
        if (response.status === 200) {
          this.sent = true;
        }
      });
    },

    /**
     * 削除モーダルを表示する
     *
     * @return {void}
     */
    showDeleteModal() {
      this.deleteTarget = this.user;
      this.$bvModal.show('user-delete-modal');
    }
  }
};
</script>
