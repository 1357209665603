import AcceptChatInterview from '../pages/r/AcceptChatInterview';
import ChatInterviews from '../pages/r/chat_interviews/index';
import SelectNickname from '../pages/r/select_nickname/index';

export default [
  {
    path: '/inquiries/:inquiry_id/accept',
    component: AcceptChatInterview,
    name: 'r_inquiry_accept'
  },
  {
    path: '/inquiries/:inquiry_id/chat_interviews',
    component: ChatInterviews,
    name: 'r_chat_interviews'
  },
  {
    path: '/select_nickname',
    component: SelectNickname,
    name: 'r_select_nickname'
  }
];
