<template lang="pug">
  nav.navbar.default-layout.col-lg-12.col-12.p-0.fixed-top.d-flex.flex-row
    .text-center.navbar-brand-wrapper.d-flex.align-items-top.justify-content-center
      router-link.navbar-brand.brand-logo(:to="{ name: 'x_dashboard' }")
        img(src="../../assets/images/excheck/vc-logo.png" alt="logo")
      router-link.navbar-brand.brand-logo-mini(:to="{ name: 'x_dashboard' }")
        img(src="../../assets/images/excheck/vc-logo.png" alt="logo")
    .navbar-menu-wrapper.d-flex.align-items-center
      button.navbar-toggler.navbar-toggler.align-self-center(type="button" data-toggle="minimize")
        span.mdi.mdi-menu
      .ml-auto.d-none.d-md-flex
      ul.navbar-nav
        li.nav-item.dropdown.d-none.d-xl-inline-flex
          a#UserDropdown.nav-link.dropdown-toggle.pl-4.d-flex.align-items-center(href="#" data-toggle="dropdown" aria-expanded="false")
            .count-indicator.d-inline-flex.mr-3
              img.img-xs.rounded-circle(:src="operator.thumbnail_url")
              span.count.count-sm.bg-inverse-primary
            span.profile-text.font-weight-medium
              | {{ operator.email }}
          .dropdown-menu.dropdown-menu-right.navbar-dropdown(aria-labelledby="UserDropdown")
            a.dropdown-item.p-0
              .d-flex.border-bottom
                .py-3.px-4.d-flex.align-items-center.justify-content-center
                  i.mdi.mdi-bookmark-plus-outline.mr-0.text-gray
                .py-3.px-4.d-flex.align-items-center.justify-content-center.border-left.border-right
                  i.mdi.mdi-account-outline.mr-0.text-gray
                .py-3.px-4.d-flex.align-items-center.justify-content-center
                  i.mdi.mdi-alarm-check.mr-0.text-gray
            router-link.dropdown-item.mt-2(:to="{ name: 'x_profile' }")
              |  Manage Accounts
            a.dropdown-item
              |  Change Password
            a.dropdown-item
              |  Check Inbox
            a.dropdown-item(@click.prevent.stop="signOut")
              |  Sign Out
      button.navbar-toggler.navbar-toggler-right.d-lg-none.align-self-center(type="button" data-toggle="offcanvas")
        span.icon-menu
</template>

<script>
export default {
  components: {},

  props: {
    operator: { type: Object, required: true }
  },

  data() {
    return {};
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    signOut() {
      fetch(this.operator.sign_out_path, { method: 'DELETE' })
        .then(response => {
          if (response.status === 200) {
            return response.json().then(body => {
              return {
                status: response.status,
                redirect_path: body.redirect_url
              };
            });
          }
        })
        .then(response => {
          location.href = response.redirect_path;
        });
    }
  }
};
</script>
