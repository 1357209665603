import AdminDashboard from '../pages/x/AdminDashboard';
import AdminProfile from '../pages/x/AdminProfile';

import UsersList from '../pages/x/users/UsersList';
import AdminsList from '../pages/x/admins/AdminsList';
import NewUser from '../pages/x/users/NewUser';
import EditUser from '../pages/x/users/EditUser';
import AddAccountGroup from '../pages/x/users/AddAccountGroup';
import NewAdmin from '../pages/x/admins/NewAdmin';
import EditAdmin from '../pages/x/admins/EditAdmin';

import SurveysList from '../pages/x/surveys/SurveysList';
import ShowSurvey from '../pages/x/surveys/ShowSurvey';
import NewSurvey from '../pages/x/surveys/NewSurvey';
import EditSurvey from '../pages/x/surveys/EditSurvey';
import QuestionsList from '../pages/x/questions/QuestionsList';
import ShowQuestion from '../pages/x/questions/ShowQuestion';
import NewQuestion from '../pages/x/questions/NewQuestion';
import EditQuestion from '../pages/x/questions/EditQuestion';
import QuantitativeQuestionsList from '../pages/x/quantitative_questions/QuantitativeQuestionsList';
import NewQuantitativeQuestion from '../pages/x/quantitative_questions/NewQuantitativeQuestion';
import EditQuantitativeQuestion from '../pages/x/quantitative_questions/EditQuantitativeQuestion';
import QuantitativeAnswersList from '../pages/x/quantitative_answers/QuantitativeAnswersList';
import NewQuantitativeAnswer from '../pages/x/quantitative_answers/NewQuantitativeAnswer';
import EditQuantitativeAnswer from '../pages/x/quantitative_answers/EditQuantitativeAnswer';
import QualitativeQuestionsList from '../pages/x/qualitative_questions/QualitativeQuestionsList';
// import QuantitativeData from '../pages/x/quantitative_data/index';
// import QualitativeData from '../pages/x/qualitative_data/index';

import AccountGroupsList from '../pages/x/account_groups/AccountGroupsList';
import NewAccountGroup from '../pages/x/account_groups/NewAccountGroup';
import EditAccountGroup from '../pages/x/account_groups/EditAccountGroup';
import AttributeValues from '../pages/x/account_groups/AttributeValues';

import OrganizationVersionsList from '../pages/x/organization_versions/OrganizationVersionList';
import NewOrganizationVersion from '../pages/x/organization_versions/NewOrganizationVersion';
import EditOrganizationVersion from '../pages/x/organization_versions/EditOrganizationVersion';
import ShowOrganizationVersion from '../pages/x/organization_versions/ShowOrganizationVersion';

import ClassificationRequestsList from '../pages/x/classification_requests/ClassificationRequestsList';
import ShowClassificationRequest from '../pages/x/classification_requests/ShowClassificationRequest';

import InquiriesList from '../pages/x/inquiries/InquiriesList';

import NewsletterTemplatesList from '../pages/x/newsletter_templates/NewsletterTemplatesList';
import NewNewsletterTemplate from '../pages/x/newsletter_templates/NewNewsletterTemplate';
import ShowNewsletterTemplate from '../pages/x/newsletter_templates/ShowNewsletterTemplate';
import PreviewNewsletterTemplate from '../pages/x/newsletter_templates/PreviewNewsletterTemplate';
import NewslettersList from '../pages/x/newsletters/NewslettersList';
import ShowNewsletter from '../pages/x/newsletters/ShowNewsletter';
import NewNewsletter from '../pages/x/newsletters/NewNewsletter';
import EditNewsletter from '../pages/x/newsletters/EditNewsletter';
import PreviewNewsletter from '../pages/x/newsletters/PreviewNewsletter';

import CoOccurrenceNetworksList from '../pages/x/co_occurrence_networks/List';
import ShowCoOccurrenceNetwork from '../pages/x/co_occurrence_networks/Show';
import RequestApiCoOccurrenceNetwork from '../pages/x/co_occurrence_networks/RequestApi';
import GenerateImageCoOccurrenceNetwork from '../pages/x/co_occurrence_networks/GenerateImage';

import ChatGPTClassificationsList from '../pages/x/chatgpt_classifications/List';
import ChatGPTClassificationsRequest from '../pages/x/chatgpt_classifications/Request';
import ChatGPTClassificationsDetail from '../pages/x/chatgpt_classifications/Detail';
import ChatGPTClassificationsResult from '../pages/x/chatgpt_classifications/Result';

import ChatGPTSentimentAnalysisList from '../pages/x/chatgpt_sentiment_analysis/List';
import ChatGPTSentimentAnalysisDetail from '../pages/x/chatgpt_sentiment_analysis/Detail';
import ChatGPTSentimentAnalysisRequest from '../pages/x/chatgpt_sentiment_analysis/Request';
import ChatGPTSentimentAnalysisResult from '../pages/x/chatgpt_sentiment_analysis/Result';

export default [
  {
    path: '/dashboard',
    component: AdminDashboard,
    name: 'x_dashboard'
  },
  {
    path: '/profile',
    component: AdminProfile,
    name: 'x_profile'
  },
  {
    path: '/users',
    component: UsersList,
    name: 'x_users'
  },
  {
    path: '/users/new',
    component: NewUser,
    name: 'x_new_user'
  },
  {
    path: '/users/:id/edit',
    component: EditUser,
    name: 'x_edit_user'
  },
  {
    path: '/users/:id/add_account_group',
    component: AddAccountGroup,
    name: 'x_add_account_group'
  },
  {
    path: '/admins',
    component: AdminsList,
    name: 'x_admins'
  },
  {
    path: '/admins/new',
    component: NewAdmin,
    name: 'x_new_admin'
  },
  {
    path: '/admins/:id/edit',
    component: EditAdmin,
    name: 'x_edit_admin'
  },
  {
    path: '/surveys',
    component: SurveysList,
    name: 'x_surveys'
  },
  {
    path: '/surveys/new',
    component: NewSurvey,
    name: 'x_new_survey'
  },
  {
    path: '/surveys/:id',
    component: ShowSurvey,
    name: 'x_show_survey'
  },
  {
    path: '/surveys/:id/edit',
    component: EditSurvey,
    name: 'x_edit_survey'
  },
  {
    path: '/surveys/:id/questions',
    component: QuestionsList,
    name: 'x_questions'
  },
  {
    path: '/surveys/:survey_id/questions/:id',
    component: ShowQuestion,
    name: 'x_show_question'
  },
  {
    path: '/surveys/:id/questions/new',
    component: NewQuestion,
    name: 'x_new_question'
  },
  {
    path: '/surveys/:survey_id/questions/:id/edit',
    component: EditQuestion,
    name: 'x_edit_question'
  },
  {
    path: '/api/x/surveys/:survey_id/questions/:id/export_template',
    name: 'x_export_template_question'
  },
  {
    path: '/surveys/:survey_id/questions/:id/quantitative_questions',
    component: QuantitativeQuestionsList,
    name: 'x_quantitative_questions'
  },
  {
    path: '/surveys/:survey_id/questions/:id/quantitative_questions/new',
    component: NewQuantitativeQuestion,
    name: 'x_new_quantitative_question'
  },
  {
    path:
      '/surveys/:survey_id/questions/:question_id/quantitative_questions/:id/edit',
    component: EditQuantitativeQuestion,
    name: 'x_edit_quantitative_question'
  },
  {
    path:
      '/surveys/:survey_id/questions/:question_id/quantitative_questions/:id/quantitative_answers',
    component: QuantitativeAnswersList,
    name: 'x_quantitative_answers'
  },
  {
    path:
      '/surveys/:survey_id/questions/:question_id/quantitative_questions/:id/quantitative_answers/new',
    component: NewQuantitativeAnswer,
    name: 'x_new_quantitative_answer'
  },
  {
    path:
      '/surveys/:survey_id/questions/:question_id/quantitative_questions/:id/quantitative_answers/:id/edit',
    component: EditQuantitativeAnswer,
    name: 'x_edit_quantitative_answer'
  },
  {
    path: '/surveys/:survey_id/questions/:id/qualitative_questions',
    component: QualitativeQuestionsList,
    name: 'x_qualitative_questions'
  },
  // {
  //   path: '/surveys/:id/quantitative',
  //   component: QuantitativeData,
  //   name: 'x_quantitative'
  // },
  // {
  //   path: '/surveys/:id/qualitative',
  //   component: QualitativeData,
  //   name: 'x_qualitative'
  // },
  {
    path: '/api/x/surveys/:id/export_template',
    name: 'x_export_template_survey'
  },
  {
    path: '/api/x/surveys/export_text_minings_template',
    name: 'x_export_text_minings_template'
  },
  {
    path: '/account_groups',
    component: AccountGroupsList,
    name: 'x_account_groups'
  },
  {
    path: '/account_groups/new',
    component: NewAccountGroup,
    name: 'x_new_account_group'
  },
  {
    path: '/account_groups/:id/edit',
    component: EditAccountGroup,
    name: 'x_edit_account_group'
  },
  {
    path: '/account_groups/:id/attribute_values',
    component: AttributeValues,
    name: 'x_attribute_values'
  },
  {
    path: '/organization_versions',
    component: OrganizationVersionsList,
    name: 'x_organization_versions'
  },
  {
    path: '/organization_versions/new',
    component: NewOrganizationVersion,
    name: 'x_new_organization_version'
  },
  {
    path: '/organization_versions/:id/edit',
    component: EditOrganizationVersion,
    name: 'x_edit_organization_version'
  },
  {
    path: '/organization_versions/:id',
    component: ShowOrganizationVersion,
    name: 'x_show_organization_version'
  },
  {
    path: '/api/x/organization_versions/:id/export_template',
    name: 'x_export_template_organization_version'
  },
  {
    path: '/classification_requests',
    component: ClassificationRequestsList,
    name: 'x_classification_requests'
  },
  {
    path: '/classification_requests/:id',
    component: ShowClassificationRequest,
    name: 'x_show_classification_request'
  },
  {
    path: '/inquiries',
    component: InquiriesList,
    name: 'x_inquiries'
  },
  {
    path: '/newsletter_templates',
    component: NewsletterTemplatesList,
    name: 'x_newsletter_templates'
  },
  {
    path: '/newsletter_templates/new',
    component: NewNewsletterTemplate,
    name: 'x_new_newsletter_template'
  },
  {
    path: '/newsletter_templates/:id',
    component: ShowNewsletterTemplate,
    name: 'x_show_newsletter_template'
  },
  {
    path: '/newsletter_templates/:id/preview',
    component: PreviewNewsletterTemplate,
    name: 'x_preview_newsletter_template'
  },
  {
    path: '/newsletters',
    component: NewslettersList,
    name: 'x_newsletters'
  },
  {
    path: '/newsletters/new',
    component: NewNewsletter,
    name: 'x_new_newsletter'
  },
  {
    path: '/newsletters/:id',
    component: ShowNewsletter,
    name: 'x_show_newsletter'
  },
  {
    path: '/newsletters/:id/edit',
    component: EditNewsletter,
    name: 'x_edit_newsletter'
  },
  {
    path: '/newsletters/:id/preview',
    component: PreviewNewsletter,
    name: 'x_preview_newsletter'
  },
  {
    path: '/co_occurrence_networks',
    component: CoOccurrenceNetworksList,
    name: 'x_co_occurrence_networks'
  },
  {
    path: '/co_occurrence_networks/:id',
    component: ShowCoOccurrenceNetwork,
    name: 'x_show_co_occurrence_network'
  },
  {
    path: '/co_occurrence_networks/:id/request_api',
    component: RequestApiCoOccurrenceNetwork,
    name: 'x_request_api_co_occurrence_networks'
  },
  {
    path: '/co_occurrence_networks/:id/generate_image',
    component: GenerateImageCoOccurrenceNetwork,
    name: 'x_generate_image_co_occurrence_networks'
  },
  {
    path: '/api/x/co_occurrence_networks/:id/filer/:filer_id/download',
    name: 'x_download_csv_co_occurrence_networks'
  },
  {
    path: '/chatgpt_classifications',
    component: ChatGPTClassificationsList,
    name: 'x_chatgpt_classifications'
  },
  {
    path: '/chatgpt_classifications/new',
    component: ChatGPTClassificationsRequest,
    name: 'x_new_chatgpt_classification'
  },
  {
    path: '/chatgpt_classifications/:id',
    component: ChatGPTClassificationsDetail,
    name: 'x_show_chatgpt_classification'
  },
  {
    path: '/chatgpt_classifications/:id/result',
    component: ChatGPTClassificationsResult,
    name: 'x_result_chatgpt_classification'
  },
  {
    path: '/chatgpt_sentiment_analysis',
    component: ChatGPTSentimentAnalysisList,
    name: 'x_chatgpt_sentiment_analysis'
  },
  {
    path: '/chatgpt_sentiment_analysis/:id',
    component: ChatGPTSentimentAnalysisDetail,
    name: 'x_show_chatgpt_sentiment_analysis'
  },
  {
    path: '/chatgpt_sentiment_analysis/new',
    component: ChatGPTSentimentAnalysisRequest,
    name: 'x_new_chatgpt_sentiment_analysis'
  },
  {
    path: '/chatgpt_sentiment_analysis/:id/result',
    component: ChatGPTSentimentAnalysisResult,
    name: 'x_result_chatgpt_sentiment_analysis'
  }
];
