import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_analyse');

export default {
  namespaced: true,
  state: {
    isDisplay: {
      analyse: false,
      text_mining: false
    },
    started: false
  },
  actions: {
    loadDisplayMenu({ commit }, surveyId) {
      return gateway
        .get({ action: 'display_menu', id: surveyId })
        .then(response => {
          if (response.status === 200) {
            if (response.body.error === 'Question not found') return;
            commit('setIsDisplay', response.body);
          }
          return response;
        });
    }
  },
  mutations: {
    setIsDisplay(state, isDisplay) {
      state.isDisplay = isDisplay;
    },
    setStarted(state, started) {
      state.started = started;
    }
  }
};
