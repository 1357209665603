<template lang="pug">
  div
    modal(
      ref="modalDownloadImage"
      name="modalDownloadImage"
      id="modalDownloadImage"
      :minWidth="modalWidth"
      :minHeight="modalHeight"
      :width="modalWidth"
      :height="modalHeight"
      :reset="true"
      :resizable="false"
      :clickToClose="true"
      :scrollable="true"
      :draggable="true"
      :pivotY="0.7"
    )
      header.modal-header.align-items-end
        h6.modal-title.vc-midashi
          | 共起ネットワーク図のダウンロード
        dl.respondent-num.align-items-center
          dt.mb-0
            button.modal-close-btn(type="button" @click="$modal.hide('modalDownloadImage')" class="close vc-close")
              | ×
      .modal-contents(:style="{height: (modalHeight * 0.89) - 57 + 'px', overflow: 'scroll'}")
        .vc-dashboard-box
          .vc-dashboard-box-content
            #download-network(:style="{width: modalWidth + 'px', height: modalWidth + 'px'}")
            a#download-img-link
      footer.modal-footer
        .text-right
          button.btn.btn-primary.vc-primary-btn.btn-select(
            :disabled="processing"
            :class="{'btn-disabled': processing}"
            @click.prevent="submit"
          )
            | ダウンロード

</template>

<script>
import { Network } from 'vis-network/dist/vis-network.min.js';

export default {
  components: {},
  data() {
    return {
      modalWidth: 0,
      modalHeight: 0,
      network: '',
      processing: false,
      options: {
        interaction: {
          zoomView: false
        }
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    this.modalWidth = window.outerWidth * 0.9;
    this.modalHeight = window.outerHeight * 0.75;
  },
  methods: {
    modalOpen(network) {
      this.network = network;
      this.draw();
      this.$modal.show('modalDownloadImage');
    },
    submit() {
      this.processing = true;
      this.downloadImage();
      this.processing = false;
    },
    draw() {
      setTimeout(() => {
        let container = document.getElementById('download-network');
        let data = {
          nodes: this.network.nodes,
          edges: this.network.edges
        };
        let network = new Network(container, data, this.options);
        network.on('beforeDrawing', function(ctx) {
          ctx.fillStyle = '#fff';
          ctx.fillRect(
            (ctx.canvas.width / 2) * -1,
            (ctx.canvas.height / 2) * -1,
            ctx.canvas.width,
            ctx.canvas.height
          );
        });
      }, 100);
    },
    downloadImage() {
      this.processing = true;
      let container = document.getElementById('download-network');
      let canvas = container.children[0].canvas;
      if (canvas.msToBlob) {
        let blob = canvas.msToBlob();
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let downloadLink = document.getElementById('download-img-link');
        downloadLink.href = canvas.toDataURL('image/png');
        downloadLink.download = '共起ネットワーク図.png';
        downloadLink.click();
      }
      this.processing = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-header {
  display: block;
  span {
    font-size: 0.8rem;
  }
}
.modal-footer {
  display: block;
}
.modal-close-btn {
  padding: 0.5rem 2rem 0.5rem !important;
}
#download-network {
  border: 1px solid lightgray;
  background-color: white;
}
.vis-network {
  position: relative;
  overflow: hidden;
  touch-action: pan-y;
  user-select: none;
  width: 100%;
  height: 100%;
}
</style>
