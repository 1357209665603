<template lang="pug">
  div
    modal(
      ref="modalQuantitativeDetails"
      name="modalQuantitativeDetails"
      id="modalQuantitativeDetails"
      :minWidth="modalWidth"
      :minHeight="modalHeight"
      :width="modalWidth"
      :height="modalHeight"
      :reset="true"
      :resizable="false"
      :clickToClose="true"
      :scrollable="true"
      :draggable="true"
      :pivotY="0.7"
    )
      header.modal-header.align-items-end
        h6.modal-title.vc-midashi
          | 内訳
        dl.respondent-num.align-items-center
          dt
            button(type="button" @click="$modal.hide('modalQuantitativeDetails')" class="close vc-close")
              | ×
      .modal-contents.vc-dashboard(:style="{height: (modalHeight * 0.89) - 57 + 'px', overflow: 'scroll'}")
        .vc-dashboard-box
          .vc-dashboard-box-content(v-if="started")
            .dashboard-comp-table-col.vc-dashboard-box-table-wrap.opinion-search-comp-table-col(v-if="respondersInfo.length > 0")
              .row.no-gutters.vc-respondent-table.text-left.font-weight-bold.table-padding
                .col-1
                .col-1(v-for="name in responseAttributeNames")
                .col-4.pr-2.text-center
                  .row
                    .col-4 前回ポイント
                    .col-4 今回ポイント
                    .col-4 差分
                .col-2.pl-2
              .row.no-gutters.vc-respondent-table.text-left.font-weight-bold.percentage-box.table-padding
                .col-1
                .col-1(v-for="name in responseAttributeNames")
                .col-4.pr-2.text-center
                  .row
                    .col-4
                      | {{ teamPoints[0] && teamPoints[0] !== '-' ? point(teamPoints[0]) : '-' }}
                    .col-4
                      | {{ teamPoints[1] && teamPoints[1] !== '-' ? point(teamPoints[1]) : '-' }}
                    .col-4(v-if="teamPoints[2] && teamPoints[2] !== '-'")
                      | {{ (teamPoints[2] > 0 ? '+' : '') + point(teamPoints[2]) }}
                    .col-4(v-else)
                      | -
                .col-2.pl-2
              .row.no-gutters.vc-respondent-table.text-left.font-weight-bold.table-padding
                .col-1 回答者ID
                .col-1(v-for="name in responseAttributeNames")
                  | {{ name }}
                .col-4.pr-2.text-center
                  .row
                    .col-4 前回回答値
                    .col-4 今回回答値
                    .col-4 差分
                .col-2.pl-2 アクション
              .vc-dashboard-box-table-data-row.no-scroll
                .row.no-gutters.vc-respondent-table.vc-quantitative-table.text-left.table-padding(v-for="info in filterValues()")
                  .col-1.pr-2.break-all
                    span
                      | {{ info.responder_code }}
                  .col-1.pr-2(v-for="av in info.attribute_values")
                    span
                      | {{ av.name }}
                  .col-1.pr-2(v-for="name in responseAttributeNames" v-if="info.attribute_values.length < 1")
                  .col-4.pr-2.text-center
                    .row
                      .col-4
                        span
                          | {{ info.prev_response }}
                      .col-4
                        span
                          | {{ info.current_response }}
                      .col-4
                        span(:class="{'plus': info.difference && info.difference > 0, 'minus': info.difference && info.difference < 0}")
                          | {{ info.difference ? (info.difference > 0 ? '+' : '') + info.difference : info.difference === 0 ? info.difference : '-' }}
                  .col-2.pl-2
                    .action-wrap
                      a.btn.btn-primary.vc-primary-btn.gray-btn.vc-detail-btn.btn-block(
                        type="button"
                        :class="{'btn-disabled': !info.is_survey_response}"
                        @click.prevent="modalAllResOpen(info.responder_code)"
                      )
                        | 会社への意見を見る
                    .action-wrap.mt-1(v-if="displayChats")
                      a.btn.btn-primary.vc-primary-btn.gray-btn.vc-chat-btn.btn-block(
                        type="button"
                        :class="{'btn-disabled': !info.response.responder_email}"
                        @click.prevent="chatAction(info.response, info.inquiry)"
                      )
                        | チャット
            div(v-else)
              p 表示する回答がありません。
          .vc-dashboard-box-content(v-else)
            p 読み込み中です。しばらくお待ちください。

</template>
<script>
import { mapState, mapActions } from 'vuex';
export default {
  components: {},
  data() {
    return {
      modalWidth: 0,
      modalHeight: 0,
      respondersInfo: [],
      teamPoints: [],
      responseAttributeNames: [],
      surveyId: '',
      organizationId: '',
      displayChats: false,
      started: false
    };
  },
  computed: {
    ...mapState('resources/u_quantitative', ['quantitativeQuestion'])
  },
  watch: {},
  created() {
    this.modalWidth = window.outerWidth * 0.85;
    this.modalHeight = window.outerHeight * 0.65;
  },
  methods: {
    ...mapActions('resources/u_quantitative', ['loadRespondersInfo']),
    load() {
      const params = {
        survey_id: this.surveyId,
        quantitative_question_id: this.quantitativeQuestionId,
        organization_id: this.organizationId
      };
      this.loadRespondersInfo(params).then(response => {
        this.respondersInfo = response.body.responders_info;
        this.started = true;
      });
    },
    modalOpen(
      teamPoints,
      responseAttributeNames,
      surveyId,
      quantitativeQuestionId
    ) {
      this.started = false;
      this.respondersInfo = [];
      this.teamPoints = teamPoints;
      this.responseAttributeNames = responseAttributeNames;
      this.surveyId = surveyId;
      this.quantitativeQuestionId = quantitativeQuestionId;
      this.organizationId = teamPoints[4];
      this.load();
      this.$modal.show('modalQuantitativeDetails');
    },
    modalAllResOpen(responderId) {
      this.$emit('modalAllResOpen', responderId);
    },
    filterValues() {
      let respondersInfo = [];
      respondersInfo = respondersInfo.concat(this.respondersInfo);
      respondersInfo = respondersInfo.sort(function(a, b) {
        if (a.difference < b.difference) return 1;
        if (a.difference > b.difference) return -1;
        return 0;
      });
      return respondersInfo;
    },
    chatAction(response, inquiry) {
      if (!inquiry || inquiry.status === 'created') {
        if (!response.responder_email) return;
        this.$emit('modalReqChatOpen', response);
      } else this.toChatInterview(inquiry);
    },
    toChatInterview(inquiry) {
      this.$router.push({
        name: 'u_chat_interviews',
        params: { inquiry_id: inquiry.code }
      });
    },
    displayChatBtn() {
      this.displayChats = false;
    },
    point(point) {
      return point.toFixed(1) + (this.quantitativeQuestion.unit || '');
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-header {
  display: block;
  span {
    font-size: 0.8rem;
  }
}
.modal-footer {
  display: block;
}
.opinion-search-comp-table-col
  .vc-respondent-table.vc-quantitative-table:first-child {
  border-bottom: 1px solid #f0f0f0;
}
a.vc-detail-btn,
a.vc-chat-btn {
  font-size: 0.6rem !important;
  // width: 120px;
  padding: 7px;
}
.vc-chat-btn {
  border: 1px solid #f3154a;
  color: #f3154a !important;
}
.vc-chat-btn:hover {
  color: #fff !important;
}
.vc-quantitative-table div {
  line-height: 3;
}
.vc-quantitative-table div span {
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
}
.vc-quantitative-table span.plus {
  color: #1fc2b1;
}
.vc-quantitative-table span.minus {
  color: #999;
}
.vc-quantitative-table .action-wrap {
  width: 107px;
}
.vc-respondent-table.percentage-box {
  padding-bottom: 15px;
}
.vc-dashboard-box-table-data-row {
  .vc-quantitative-table {
    padding: 0 10px;
  }
  .vc-quantitative-table:nth-child(odd) {
    background: rgba(0, 0, 0, 0.05);
  }
  .vc-quantitative-table:nth-child(even) {
    background: transparent;
  }
}
.table-padding {
  padding-left: 10px;
  padding-right: 10px;
}
.vc-respondent-table:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.break-all {
  word-break: break-all;
}
</style>
