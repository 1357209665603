<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content.vc-dashboard.vc-quantitative
        .vc-main-container.container.mt-5
          .vc-dashboard-box.ranking.mb-4
            .container-fluid
              div
                .row.back-btn-wrap.mb-4
                  .col-12
                    .mb-4.pl-4
                      button#back-btn.btn.btn-primary.vc-primary-btn(@click.prevent="back")
                        = '<< 分析画面に戻る'
                .row.vc-midashi-wrap
                  .col-12
                    .row
                      .col-2.mb-4
                        span.vc-midashi 設問
                      .col-10.mb-4
                        .vc-midashi
                          | {{ question.question_text }}
                        p.ml-4
                          | {{ question.description }}
                .row.vc-dashboard-ranking_submenu
                  .col-6.ml-4.mt-3.pl-3
                    .vc-dashboard-ranking_submenu-row
                      .col-4.mb-2 ランキング表示階層
                      .col-8.mb-2
                        ul.vc-parameter-nav.text-left
                          li(v-for="(level, i) in orgLevels")
                            a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': orgHierarchy === i+1}" @click="setOrgHierarchy(i+1)")
                              | {{ level }}
                    .vc-dashboard-ranking_submenu-row.vc-top-col.pt-0.mt-1
                      .col-4.mb-4 チームでフィルタ
                      .col-12.mb-4
                        .row
                          .vc-sub-select-box
                            select(
                              class="form-control vc-sub-select ranking-team-filter1"
                              v-model="selectedOrg[0]"
                              :disabled="orgHierarchy === 1"
                            )
                              option(value="undefined")
                                | すべて
                              option(
                                v-for="organization in organizations[0]"
                                :value="organization.id"
                                :key="organization.id"
                              )
                                | {{organization.name}}
                  .col-12.sort-box
                    .vc-dashboard-ranking_submenu-row.vc-top-col.pt-0.mt-1
                      .col-6
                        ul.vc-parameter-nav.mb-4
                          li
                            a.vpn-all(href="javascript:void(0)" :class="{'active': sortType === 0}" @click="setSortType(0)") 前回ポイント順
                          li
                            a.vpn-all(href="javascript:void(0)" :class="{'active': sortType === 1}" @click="setSortType(1)") 今回ポイント順
                          li
                            a.vpn-all(href="javascript:void(0)" :class="{'active': sortType === 2}" @click="setSortType(2)") 差分順
                      .col-6.text-right
                        ul.vc-parameter-nav.mb-4
                          li
                            a.vpn-all(href="javascript:void(0)" :class="{'active': orderType === 0}" @click="setOrderType(0)") 昇順
                          li
                            a.vpn-all(href="javascript:void(0)" :class="{'active': orderType === 1}" @click="setOrderType(1)") 降順
                .row.vc-dashboard-box-content(v-show="started")
                  .col-12.vc-dashboard-box-ranking.vc-quantitative-box-details
                    .row.vc-dashboard-ranking-board.vc-dashboard-ranking-header.pl-3.pr-3
                      .col-4 チーム
                      .col-6
                        .row
                          .col-4.text-center 前回ポイント
                          .col-4.text-center 今回ポイント
                          .col-4.text-center 差分
                      .col-2
                    .row.vc-dashboard-ranking-board.vc-quantitative-detail-board.pl-3.pr-3(v-for="values in filterValues()")
                      .col-4
                        span
                          | {{ values[0] }}
                      .col-6
                        .row
                          .col-4.text-center
                            span
                              | {{ (values[1][0] && values[1][0] !== '-') || values[1][0] === 0.0 ? values[1][0].toFixed(1) + unit() : '-' }}
                          .col-4.text-center
                            span
                              | {{ (values[1][1] && values[1][1] !== '-') || values[1][1] === 0.0 ? values[1][1].toFixed(1) + unit() : '-' }}
                          .col-4.text-center
                            span(:class="{'plus': values[1][2] !== '-' && values[1][2] > 0, 'minus': values[1][2] !== '-' && values[1][2] < 0}")
                              | {{ values[1][2] !== '-' ? (values[1][2] > 0 ? '+' : '') + values[1][2].toFixed(1) + unit() : '-' }}
                      .col-2
                        a.btn.btn-primary.vc-primary-btn.gray-btn.vc-detail-btn(type="button" @click.prevent="modalOpen(values[1])") 内訳
                .row(v-show="!started")
                  .col-12.ml-4.pl-3
                    p.wait-sentence 読み込み中です。しばらくお待ちください。
    ModalQuantitativeDetails(ref="modalQuantitativeDetails" @modalAllResOpen="modalAllResOpen" @modalReqChatOpen="modalReqChatOpen")
    ModalAllSurveyResponses(ref="modalAllSurveyResponses")
    ModalRequestChatInterview(ref="modalRequestChatInterview")
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SurveySelect from '../../../components/u/dashboard/SurveySelect';
import ModalQuantitativeDetails from '../../../components/u/quantitativeAnalyse/ModalQuantitativeDetails';
import ModalAllSurveyResponses from '../../../components/u/quantitativeAnalyse/ModalAllSurveyResponses';
import ModalRequestChatInterview from '../../../components/u/opinionSearch/ModalRequestChatInterview';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    SurveySelect,
    ModalQuantitativeDetails,
    ModalAllSurveyResponses,
    ModalRequestChatInterview
  },

  data() {
    return {
      surveyId: '',
      questionId: '',
      emotionType: 0,
      orgHierarchy: null,
      sortType: null,
      orderType: null,
      selectedOrg: []
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_quantitative_analyse', [
      'question',
      'compares',
      'surveyName',
      'prevSurveyName',
      'responseAttributeNames',
      'organizations',
      'parentIdByIds',
      'orgLevels'
    ]),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('pages/u_organizations/select', ['beforeSelected', 'started'])
  },

  watch: {
    orgHierarchy: function() {
      const vcd = defaultCookie.get();
      if (vcd['orgHierarchy'] !== this.orgHierarchy)
        defaultCookie.set({ orgHierarchy: this.orgHierarchy });
    },
    sortType: function() {
      const vcd = defaultCookie.get();
      if (vcd['quantitativeSortType'] !== this.sortType)
        defaultCookie.set({ quantitativeSortType: this.sortType });
    },
    selectedOrg: function() {
      const vcd = defaultCookie.get();
      let del_index = null;
      let del_count = 0;
      for (let i = 0; i < this.selectedOrg.length; i++) {
        if (this.selectedOrg[i] == 'undefined') this.selectedOrg[i] = '';
        if (del_index) {
          del_count++;
        } else {
          if (this.beforeSelected[i] != this.selectedOrg[i]) del_index = i + 1;
        }
      }
      if (del_index && del_count > 0) {
        this.selectedOrg.splice(del_index, del_count);
      }
      if (!this.selectedOrg.slice(-1)[0] && this.selectedOrg.length > 0)
        this.selectedOrg.pop();
      this.setSelect({ beforeSelected: Array.from(this.selectedOrg) });
      const selectedOrg = this.selectedOrg.slice(-1)[0];
      this.surveyId = vcd['surveyId'];
      this.questionId = vcd['questionId'];
      this.loadQuantitativeDetails({
        survey_id: vcd['surveyId'],
        question_id: vcd['questionId'],
        organization_id: selectedOrg ? selectedOrg : null
      });
    }
  },

  created() {
    this.setStarted(false);
    this.loadControlSettings().then(() => {
      this.defaultSelected();
      if (this.$route.params.questionId)
        defaultCookie.set({ questionId: this.$route.params.questionId });
    });
  },

  mounted() {
    this.load();
  },

  methods: {
    ...mapActions('resources/u_quantitative_analyse', [
      'loadQuantitativeDetails',
      'loadOrganizations'
    ]),
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapMutations('pages/u_organizations/select', [
      'setSelect',
      'setStarted'
    ]),
    ...mapMutations('pages/u_surveys/select', ['setSurveyId']),
    load() {
      const vcd = defaultCookie.get();
      if (!this.started) {
        this.loadOrganizations({ survey_id: vcd['surveyId'] }).then(() => {
          this.selectedOrg = [];
        });
        this.setSurveyId(vcd['surveyId']);
      } else {
        this.loadOrganizations({ survey_id: vcd['surveyId'] }).then(() => {
          this.selectedOrg = [];
        });
      }
    },
    filterValues() {
      let that = this;
      let compares = {};
      compares = this.compares.filter(
        compare => compare[1][3] === this.orgHierarchy
      );
      compares = compares.sort(function(a, b) {
        if (that.orderType > 0) {
          if (a[1][that.sortType] < b[1][that.sortType]) return 1;
          if (a[1][that.sortType] > b[1][that.sortType]) return -1;
          return 0;
        } else {
          if (a[1][that.sortType] > b[1][that.sortType]) return 1;
          if (a[1][that.sortType] < b[1][that.sortType]) return -1;
          return 0;
        }
      });
      if (compares.length > 1) this.setStarted(true);
      return compares;
    },
    setOrgHierarchy(hierarchy) {
      this.orgHierarchy = hierarchy;
      if (hierarchy === 1) this.selectedOrg = [undefined, undefined];
      if (hierarchy !== 3) this.selectedOrg = [this.selectedOrg[0], undefined];
    },
    setSortType(type) {
      this.sortType = type;
    },
    setOrderType(type) {
      this.orderType = type;
    },
    defaultSelected() {
      let vcd = defaultCookie.get();
      this.questionId = vcd['questionId'];
      const name = 'default_ranking_filter';
      let defaultHierarchy = 1;
      if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
        if (this.controlSettings[name]['values'] === 'base')
          defaultHierarchy = 2;
        else if (this.controlSettings[name]['values'] === 'department')
          defaultHierarchy = 3;
      }
      this.orgHierarchy = vcd['orgHierarchy'] || defaultHierarchy;
      this.sortType = 2;
      this.orderType = 1;
    },
    modalOpen(teamPoints) {
      let vcd = defaultCookie.get();
      this.$refs.modalQuantitativeDetails.modalOpen(
        teamPoints,
        this.responseAttributeNames,
        vcd['surveyId'] || this.surveyId,
        this.questionId
      );
    },
    modalAllResOpen(responderId) {
      let vcd = defaultCookie.get();
      this.$refs.modalAllSurveyResponses.modalOpen(
        vcd['surveyId'],
        responderId
      );
    },
    modalReqChatOpen(response) {
      this.$refs.modalRequestChatInterview.modalOpen(response, 'quantitative');
    },
    back() {
      this.$router.push({ name: 'u_quantitative_analyse' });
    },
    unit() {
      return this.question.unit ? this.question.unit : '';
    }
  }
};
</script>
<style lang="scss" scoped>
.vc-quantitative a.vc-detail-btn {
  font-size: 0.7rem;
  width: 150px;
  padding: 7px;
}
.vc-quantitative .vc-quantitative-box-details {
  max-width: none !important;
}
.vc-quantitative .back-btn-wrap {
  padding: 0;
  border-bottom: 1px solid #f0f0f0;
}
.vc-quantitative #back-btn {
  background: none;
}
.vc-quantitative #back-btn:hover {
  background: none !important;
  border: 1px solid #f3154a;
  color: #f3154a;
}
.vc-quantitative .vc-quantitative-detail-board div {
  line-height: 2;
}
.vc-quantitative .vc-quantitative-detail-board div span {
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
}
.vc-quantitative .vc-quantitative-detail-board div span.plus {
  color: #1fc2b1;
}
.vc-quantitative .vc-quantitative-detail-board div span.minus {
  color: #999;
}
.vc-quantitative .sort-box {
  margin: 1rem 0 0 1.5rem !important;
  padding: 0 4rem 0 1rem !important;
}
.vc-quantitative-box-details {
  .vc-dashboard-ranking-board:nth-child(even) {
    background: rgba(0, 0, 0, 0.05);
  }
  .vc-dashboard-ranking-board:nth-child(odd) {
    background: transparent;
  }
}
p.wait-sentence {
  padding-right: 12.5px;
  padding-left: 12.5px;
}
</style>
