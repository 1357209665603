<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | 感情分析結果

  .row.flex-grow
    .col-12
      .card.mb-4
        .card-body
          .row.mb-4
            .col-3
              | ID
            .col-9
              | {{ task.id }}
          .row.mb-4
            .col-3
              | ステータス
            .col-9
              | {{ task.status }}

  .row.flex-grow
    .col-12
      .card.mb-4
        .card-body
          .row.mb-4
            .col-12
              | 分類結果
          .row.mb-4
            .col-md-12.grid-margin.stretch-card.clearfix
              .card
                .card-body.pb-0
                  .table-responsive.gpt-responses
                    table.table.table-striped
                      thead
                        tr
                          th
                            | ID
                          th
                            | オピニオン
                          th
                            | カテゴリ
                      tbody
                        tr(v-for="(opinion, idx) in opinions")
                          td
                            | {{ opinion.qualitative_opinion.id }}
                          td
                            | {{ opinion.qualitative_opinion.sentence }}
                          td.text-center
                            | {{ opinion.sentiment }}

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      clusters: [],
      errorMessages: [],
      processing: false
    };
  },

  computed: {
    ...mapState('resources/x_chatgpt_sentiment_analysis', ['task', 'opinions'])
  },

  created() {
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_chatgpt_sentiment_analysis', [
      'fetchTask',
      'fetchResult'
    ]),

    load() {
      let params = this.$route.params;
      this.fetchTask(params.id);
      // 結果を更に取得
      this.fetchResult(params.id);
    }
  }
};
</script>

<style scoped></style>
