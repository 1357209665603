<template lang="pug">
  div(v-if="Object.keys(sourceTopic).length && Object.keys(surveyOpCounts).length")
    hr(style="margin: 0 -25px;")
    .vc-dashboard
      .row.mt-4(v-for="(surveyOpCount, key1) of surveyOpCounts")
        .col-12
          h4.vc-midashi.mt-3.mb-2
            | {{ surveyOpCount['sourceTopic']['survey_name'] }}
          p.period
            | {{ surveyOpCount['sourceTopic']['start_at'] | moment('YYYY.MM.DD')}}
            | - 
            | {{ surveyOpCount['sourceTopic']['end_at'] | moment('YYYY.MM.DD')}}
        .col-6(v-for="key2 of ['sourceTopic', 'destinationTopic']")
          .row.vc-dashboard-box-content
            .col-12.vc-dashboard-box-title
              h5.vc-midashi.mt-3.mb-2
                | {{ key2 == 'sourceTopic' ? sourceTopic.organization_name : destinationTopic.organization_name }}
            .dashboard-comp-graph-col.vc-dashboard-box-graph-wrap.topic-comp-graph-col(v-if="surveyOpCount[key2]['opinion_count']>0")
              pie-chart(:chart-data="orgChartDatas[key1][key2]" :options="chartOptions")
            .dashboard-comp-table-col.vc-dashboard-box-table-wrap.topic-comp-table-col(v-if="surveyOpCount[key2]['opinion_count']>0")
              .row
                .col-12.vc-dashboard-box-title
                  h5.vc-midashi.mt-3.mb-2 感情意見割合
              .row.no-gutters.vc-respondent-table
                .col-7.text-left.overflow-hidden.text-nowrap 感情分類
                .col-5
                  .row.no-gutters
                    .col-6
                    .col-6 割合
              .vc-dashboard-box-table-data-row.no-scroll
                .row.no-gutters.vc-respondent-table(v-for="(d, i) of surveyOpCount[key2]['data']")
                  .col-7.text-left.overflow-hidden.text-nowrap.table-column-name
                    | {{ orgChartDatas[key1][key2]['labels'][i] }}
                  .col-5
                    .row.no-gutters.vc-respondent-table-num
                      .col-6
                        a(
                          v-if="key2 == 'sourceTopic'"
                          @click.prevent="toOpinionSearch(surveyOpCount[key2]['id'], sourceTopic.organization_id, surveyOpCount[key2]['ecId'][i])"
                        )
                          svg.to-opinion-search(
                            xmlns="http://www.w3.org/2000/svg"
                            enable-background="new 0 0 24 24"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#000000"
                          )
                            g
                              rect(fill="none" height="24" width="24")
                            g
                              g
                                rect(height="2" width="9" x="13" y="7")
                                rect(height="2" width="9" x="13" y="15")
                                rect(height="2" width="6" x="16" y="11")
                                polygon(points="13,12 8,7 8,11 2,11 2,13 8,13 8,17")
                        a(
                          v-else
                          @click.prevent="toOpinionSearch(surveyOpCount[key2]['id'], destinationTopic.organization_id, surveyOpCount[key2]['ecId'][i])"
                        )
                          svg.to-opinion-search(
                            xmlns="http://www.w3.org/2000/svg"
                            enable-background="new 0 0 24 24"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#000000"
                          )
                            g
                              rect(fill="none" height="24" width="24")
                            g
                              g
                                rect(height="2" width="9" x="13" y="7")
                                rect(height="2" width="9" x="13" y="15")
                                rect(height="2" width="6" x="16" y="11")
                                polygon(points="13,12 8,7 8,11 2,11 2,13 8,13 8,17")
                      .col-6
                        | {{ ((d/surveyOpCount[key2]['opinion_count'])*100).toFixed(1) }}%
            .col-12(v-if="surveyOpCount[key2]['opinion_count']==0")
              | データがありません
</template>

<script>
import { mapState } from 'vuex';
import PieChart from '../../../components/u/chartJs/PieChart';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    PieChart
  },

  props: {
    surveyId: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data() {
    return {
      surveyOpCounts: {},
      orgChartDatas: {},
      chartOptions: {
        responsive: true,
        layout: {
          padding: {
            left: 12,
            right: 12,
            top: 0,
            bottom: 0
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          align: 'start'
        }
      }
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('resources/u_analyse_compare', [
      'emotionClassificationTopic',
      'sourceTopic',
      'destinationTopic'
    ]),
    ...mapState('resources/u_opinion_search', ['keepedEmotionValues'])
  },

  watch: {
    sourceTopic: function() {
      const emotionClassificationTopic = this.emotionClassificationTopic;

      var surveyOpCounts = {};

      for (const key of ['sourceTopic', 'destinationTopic']) {
        for (const survey_opinion_count of this[key].survey_opinion_counts) {
          if (!surveyOpCounts[survey_opinion_count.id])
            surveyOpCounts[survey_opinion_count.id] = {
              sourceTopic: {},
              destinationTopic: {}
            };
          surveyOpCounts[survey_opinion_count.id][key] = survey_opinion_count;
          surveyOpCounts[survey_opinion_count.id][key]['ecId'] = [];
          surveyOpCounts[survey_opinion_count.id][key]['labels'] = [];
          surveyOpCounts[survey_opinion_count.id][key]['data'] = [];
          for (const ec of emotionClassificationTopic) {
            surveyOpCounts[survey_opinion_count.id][key]['ecId'].push(ec.id);
            surveyOpCounts[survey_opinion_count.id][key]['labels'].push(
              ec.name
            );
            surveyOpCounts[survey_opinion_count.id][key]['data'].push(
              survey_opinion_count['emotion_opinion_count' + ec.id]
            );
          }
        }
      }
      this.surveyOpCounts = surveyOpCounts;
      this.pieGraphUpdate();
    }
  },

  created() {},

  mounted() {},

  methods: {
    pieGraphUpdate: function() {
      const backgroundColors = [
        'rgba(232, 94, 94, 0.8)',
        'rgba(34, 190, 211, 0.8)',
        'rgba(133, 133, 133, 0.8)'
      ];
      var orgChartDatas = {};
      for (const key in this.surveyOpCounts) {
        orgChartDatas[key] = { sourceTopic: {}, destinationTopic: {} };
        for (const key2 of ['sourceTopic', 'destinationTopic']) {
          orgChartDatas[key][key2] = {
            labels: this.surveyOpCounts[key][key2]['labels'],
            datasets: [
              {
                backgroundColor: backgroundColors,
                data: this.surveyOpCounts[key][key2]['data']
              }
            ]
          };
        }
      }
      this.orgChartDatas = orgChartDatas;
    },

    toOpinionSearch: function(
      surveyId,
      organizationId,
      emotionClassificationId
    ) {
      const vcd = defaultCookie.get();
      this.$router.push({
        name: 'u_opinion_search',
        params: {
          emotion_classification: emotionClassificationId,
          survey_id: surveyId,
          organization_id: organizationId,
          backToSurveyId: vcd['surveyId'],
          backToName: 'u_compare_analyse'
        }
      });
    }
  }
};
</script>
