<template lang="pug">
  div(v-if="ready")
    form
      .research-page.interview-page.dashboard-body
        .container
          .card.dashboard-card.research-card
            .card-header
              .row
                .col-12
                  .interview-people
                    p.card-title
                      | {{ inquiry.survey_name }}
                      | ({{ inquiry.survey_period }})
              .row.mt-3
                .col-2(v-if="inquiry.question_text" :class="{'mt-2': inquiry.question_text}")
                  p.font-weight-bold 調査時の質問：
                .col-10(v-if="inquiry.question_text" :class="{'mt-2': inquiry.question_text}")
                  .row
                    .col-11
                      p
                        | {{ displayQuestionText ? inquiry.question_text : truncate(inquiry.question_text, 60) }}
                    .col-1.text-right
                      img.question-text-hide-btn.ml-2(
                        src="../../../assets/images/excheck/chevron-down.png" width="18"
                        :class="{'reverse': displayQuestionText}"
                        @click.prevent="toggleQuestionText"
                      )
                .col-2(:class="{'mt-2': inquiry.question_text}")
                  p.font-weight-bold あなたの自由記述回答：
                .col-10(:class="{'mt-2': inquiry.question_text}")
                  p.p-3.response-contents-box(
                    v-if="inquiry.survey_response"
                    :class="{'pb-5': inquiry.survey_response.contents.length > 150}"
                  )
                    | {{ displayResponseText ? inquiry.survey_response.contents : truncate(inquiry.survey_response.contents, 150) }}
                    span.text-right.response-text-hide-btn(
                      v-show="inquiry.survey_response.contents.length > 150"
                      @click.prevent="toggleResponseText"
                    )
                      | 全文章を表示
                      img.question-text-hide-btn.ml-2(
                        src="../../../assets/images/excheck/chevron-down.png" width="10"
                        :class="{'reverse': displayResponseText}"
                        @click.prevent="toggleQuestionText"
                      )
            .card-body
              .interview-timeline
                ChatItem(
                  v-for="chat in chatInterviews"
                  :chat="chat"
                  :key="chat.id"
                )
                #chat-bottom
            .card-footer
              .row
                .col-sm-10.col-12
                  textarea.chat-msg-box(
                    placeholder='入力してください'
                    v-model="msgBox"
                    :disabled="['completed', 'stopped'].includes(inquiry.status)"
                    :class="{'disabled': ['completed', 'stopped'].includes(inquiry.status)}"
                    @keyup.enter.exact="onKeyupEnter($event)"
                    @keydown.enter.exact="onKeydownEnter($event)"
                    @compositionstart="onCompositionStart"
                  )
                .col-sm-2.col-12.text-center.mt-2
                  .row
                    .col-sm
                      a.cc-primary-btn.cc-inline-btn.btn-block.ml-3.m-1(
                        href="#"
                        role="button"
                        v-if="messageChannel"
                        :disabled="['completed', 'stopped'].includes(inquiry.status)"
                        :class="{'btn-disabled': ['completed', 'stopped'].includes(inquiry.status)}"
                        @click.prevent="speak"
                      )
                        | 送信

</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import ChatItem from 'src/components/r/chat_interviews/ChatItem';

export default {
  components: {
    ChatItem
  },

  data() {
    return {
      ready: false,
      msgBox: '',
      messageChannel: null,
      flashMessage: '',
      errorMessages: [],
      processing: false,
      imageUrl: '',
      isComposing: false,
      displayQuestionText: false,
      displayResponseText: false
    };
  },

  computed: {
    ...mapState('resources/r_chat_interviews', ['chatInterviews', 'inquiry'])
  },

  created() {
    this.$on('remove-chat-item', this.removeChatItem);
    this.$root.$on('drag-todo-item', this.onItemDrag);
    this.$root.$on('dragend-todo-item', this.onItemDragEnd);
    this.$root.$on('create-todo-item', this.createTodoItem);
    this.$on('show-image-modal', this.showImageModal);

    this.messageChannel = this.$cable.subscriptions.create(
      'ChatInterviewChannel',
      {
        connected: () => {
          this.followCurrentChannel();
        },

        received: data => {
          this.setChatInterview(data);
          this.moveToChatButtom();
        }
      }
    );

    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/r_chat_interviews', ['loadChatInterviews']),
    ...mapMutations('resources/r_chat_interviews', ['setChatInterview']),

    load() {
      this.ready = true;
      this.loadChatInterviews(this.$route.params.inquiry_id).then(response => {
        if (response.status === 200) {
          if (response.body.empty_nickname)
            this.$router.push({
              name: 'r_select_nickname',
              params: { inquiry_code: this.$route.params.inquiry_id }
            });
          this.ready = true;
          this.moveToChatButtom();
        }
      });
    },

    followCurrentChannel() {
      this.messageChannel.perform('follow', {
        channel_id: this.$route.params.inquiry_id
      });
    },

    speak() {
      if (!this.msgBox) return;
      this.messageChannel.perform('speak', {
        channel_id: this.$route.params.inquiry_id,
        message: this.msgBox
      });

      this.resetChatBox();
    },

    onKeyupEnter(e) {
      if (e.keyCode !== 13) return;
      if (this.isComposing) this.onCompositionEnd();
      else {
        e.preventDefault();
        this.speak();
      }
    },

    onKeydownEnter(e) {
      if (e.keyCode !== 13) return;
      e.preventDefault();
    },

    onCompositionStart() {
      this.isComposing = true;
    },

    onCompositionEnd() {
      this.isComposing = false;
    },

    moveToChatButtom() {
      var bottom = this.$el.querySelector('#chat-bottom');
      this.$scrollTo(bottom, 800, {
        container: '.interview-timeline',
        easing: 'ease-in-out'
      });
    },

    removeChatItem(item) {
      this.removeChatInterview({
        inquiry_id: this.$route.params.inquiry_id,
        id: item.id
      }).then(response => {
        if (response.status === 200) {
          this.spliceChatInterview({ item: item });
        } else if (response.status === 422) {
          this.errorMessages = response.body.errors;
        }
      });
    },

    /**
     * メッセージのクリア
     */
    resetMessages() {
      this.errorMessages = [];
      this.flashMessage = '';
    },

    /**
     * チャット入力ボックスをリセット
     */
    resetChatBox() {
      this.msgBox = '';
    },

    /**
     * 画像モーダルを表示する
     *
     * @return {void}
     */
    showImageModal(url) {
      this.imageUrl = url;
      this.$bvModal.show('image-modal');
    },

    truncate(str, num) {
      return str.length <= num ? str : str.substr(0, num) + '....';
    },

    toggleQuestionText() {
      this.displayQuestionText = !this.displayQuestionText;
    },

    toggleResponseText() {
      this.displayResponseText = !this.displayResponseText;
    }
  }
};
</script>
<style scoped>
.chat-msg-box.disabled {
  border-color: #ddd;
}
.response-contents-box {
  background: #f7f7f7;
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 8px;
}
.question-text-hide-btn,
.response-text-hide-btn {
  cursor: pointer;
}
.question-text-hide-btn.reverse,
.response-text-hide-btn.reverse {
  -webkit-transform: rotatex(180deg);
  transform: rotatex(180deg);
}
span.response-text-hide-btn {
  position: absolute;
  right: 40px;
  bottom: 15px;
}
</style>
