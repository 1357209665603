<template lang="pug">
  div
    modal(
      ref="modalNsOpinionAttrVal"
      name="modalNsOpinionAttrVal"
      id="modalNsOpinionAttrVal"
      :minWidth="750"
      :minHeight="570"
      :width="750"
      :height="570"
      :reset="true"
      :resizable="false"
      :clickToClose="false"
      :draggable="true")
      header.modal-header.align-items-end
        h6.modal-title.vc-midashi
          | 属性別の意見数の内訳グラフ
          br
          | {{organizationName}} {{opinionClassificationName}}
        dl.respondent-num.align-items-center
          dt
            | 意見数
          dd
            | {{opinionCount | separate}}
          dt
            | 回答者数
          dd.mr-0
            | {{surveyResponseCount | separate}}
          dt.mb-4.ml-0
            button(type="button" @click="$modal.hide('modalNsOpinionAttrVal')" class="close vc-close")
              | ×
      .row.no-gutters
        .col-12.mt-2
          .vc-box-sub-select.container
            a.nav-link.vc-midashi(
              v-for="response_attribute in response_attributes"
              @click.prevent="changeResponseAttribute(response_attribute.id)"
              :class="{'active': response_attribute.id == response_attribute_id}"
              )
              | {{response_attribute.name}}
        .col-4.mt-3.mb-3(
          v-for="(aggregateData, i) of aggregateDatas"
          )
          h5.vc-midashi.text-center(
            :class='`tit-graph-${i}`'
          )
            | {{aggregateData[0].name}}
          pie-chart(:chart-data="aggregateData.datacollection" :options="aggregateData.options")
        .col-12.mt-2.d-flex.align-items-center.justify-content-center
          p.font-weight-bold
            | 【数字の見方】
          p.small.inline-block
            | 例えば性別の場合、女性の
            span.font-weight-bold
              | 意見数
            | がxx%、男性の
            span.font-weight-bold
              | 意見数
            | がxx%という見方をします
            br
            |（女性回答者数や男性
            span.font-weight-bold
              | 回答者数の内訳グラフではありません
            | ）

</template>

<script>
import { mapState, mapActions } from 'vuex';
import PieChart from '../chartJs/PieChart';

export default {
  components: {
    PieChart
  },

  data() {
    return {
      params: {},
      organizationName: '',
      opinionClassificationName: '',
      surveyResponseCount: 0,
      opinionCount: 0,
      aggregateDatas: {},
      response_attribute_id: null
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_analyse_team', [
      'response_attributes',
      'ns_opinion_per_attribute_values'
    ])
  },

  watch: {
    ns_opinion_per_attribute_values: function() {
      if (!this.response_attribute_id)
        this.response_attribute_id = this.response_attributes[0].id;
      this.organizationName = this.ns_opinion_per_attribute_values.organization_name;
      this.opinionClassificationName = this.ns_opinion_per_attribute_values.opinion_classification_name;
      this.surveyResponseCount = this.ns_opinion_per_attribute_values.survey_response_count;
      this.opinionCount = this.ns_opinion_per_attribute_values.opinion_count;
      const aggregateDatas = {};
      aggregateDatas[0] = [];
      aggregateDatas[1] = [];
      aggregateDatas[2] = [];
      for (const aggregateData of this.ns_opinion_per_attribute_values
        .aggregate_datas) {
        aggregateDatas[0].push({
          name: '全体',
          attribute_value_name: aggregateData.attribute_value_name,
          opinion_count: aggregateData.opinion_count
        });
        aggregateDatas[1].push({
          name: 'ポジティブ',
          attribute_value_name: aggregateData.attribute_value_name,
          opinion_count: aggregateData.positive_count
        });
        aggregateDatas[2].push({
          name: 'ネガティブ',
          attribute_value_name: aggregateData.attribute_value_name,
          opinion_count: aggregateData.negative_count
        });
      }
      this.aggregateDatas = aggregateDatas;
      this.pieGraphUpdate();
    }
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_analyse_team', [
      'loadNsOpinionPerAttributeValues'
    ]),
    modalOpen: function(data) {
      const params = {
        survey_id: data.survey_id,
        question_id: data.question_id,
        organization_id: data.organization_id,
        opinion_classification_id: data.opinion_classification_id,
        attribute_value_ids: data.attribute_value_ids
          ? data.attribute_value_ids
          : [],
        response_attribute_id: null
      };
      this.response_attribute_id = null;

      this.params = params;

      this.loadNsOpinionPerAttributeValues(params);
      this.$modal.show('modalNsOpinionAttrVal');
    },
    changeResponseAttribute: function(response_attribute_id) {
      this.params['response_attribute_id'] = response_attribute_id;
      this.response_attribute_id = response_attribute_id;
      this.loadNsOpinionPerAttributeValues(this.params);
    },
    pieGraphUpdate: function() {
      const colors = [
        '#A6CEE3',
        '#E4CCF0',
        '#FFC1C1',
        '#FF6F70',
        '#AE81DE',
        '#5DB6F2',
        '#FFFFC2',
        '#BEE6FB',
        '#FFB266',
        '#FFD9A7',
        '#C8F5A0',
        '#EB9362',
        '#93EB62',
        '#6293EB',
        '#C8A0F5'
      ];
      for (const i in this.aggregateDatas) {
        const labels = [];
        const data = [];
        for (const ad of this.aggregateDatas[i]) {
          labels.push(ad.attribute_value_name);
          data.push(ad.opinion_count);
        }
        this.aggregateDatas[i]['datacollection'] = {
          labels: labels,
          datasets: [
            {
              backgroundColor: colors,
              data: data
            }
          ]
        };
        this.aggregateDatas[i]['options'] = {
          responsive: true,
          layout: {
            padding: {
              left: 12,
              right: 12,
              top: 0,
              bottom: 0
            }
          },
          legend: {
            display: false,
            position: 'bottom',
            align: 'start'
          }
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tit-graph-1::before,
.tit-graph-2::before {
  content: '●';
  padding-right: 7px;
}
.tit-graph-1::before {
  color: #22bed3;
}
.tit-graph-2::before {
  color: #e85e5e;
}
.vc-tab-menu {
  button {
    display: inline-block;
    color: #444;
    font-size: 17px;
    height: 50px;
    line-height: 50px;
    border: 1px solid #f0f0f0;
    padding: 0 25px;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    background-color: #fff;
  }
  button:first-child {
    border-radius: 25px 0 0 25px;
  }
  button:last-child {
    border-radius: 0 25px 25px 0;
  }
  button.active,
  button:hover {
    background: #ff003c;
    color: #fff;
    text-decoration: none;
  }
}
</style>
