<template lang="pug">
  div
    div(v-if="dataLoaded")
      .vc-main-container.container(v-if="!emptyQuestions")
        .vc-topic-content.mb-4
          .vc-left-col(:class="displayAttributeValues ? 'mr-1' : ''")
            QuantitativeOrganizations(:surveyId="surveyId" @matchHeights="matchHeights")
          .vc-left-col(v-show="displayAttributeValues")
            QuantitativeAttributeValues(:surveyId="surveyId" @matchHeights="matchHeights")
        .vc-content-col
          .container
            .row
              .col-6.pr-4.pl-4(v-for="q in quantitativeQuestions")
                .h4.vc-midashi.mt-2.mb-2(:id="'quantitative-question-title'+q.code")
                  | {{ q.title }}
                p.small(:id="'quantitative-question-text'+q.code")
                  | {{ q.question_text }}
                p.small(:id="'quantitative-question-description'+q.code")
                  | {{ q.description }}
                vertical-bar-chart(
                  :chart-data="datacollection[q.code]" :options="newOptions(q)" :addDataLabels="true" v-if="datacollection[q.code]" :styles="chartStyle"
                )
                .mt-4
                pie-chart(:chart-data="pieChartData[q.code] || {}" :options="pieChartOptions(q.title)" v-if="pieChartData[q.code]" :styles="pieChartStyle")
      .vc-main-container.container(v-else)
        .vc-content-col
          .container
            | 表示できるデータがありません
    .vc-main-container.container(v-else)
      .vc-content-col
        .container
          | 読み込み中です。しばらくお待ちください。

</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import VerticalBarChart from '../../../components/u/chartJs/VerticalBarChart';
import PieChart from '../../../components/u/chartJs/PieChart';
import QuantitativeOrganizations from '../../../components/u/quantitativeAnalyse/QuantitativeOrganizations';
import QuantitativeAttributeValues from '../../../components/u/quantitativeAnalyse/QuantitativeAttributeValues';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    VerticalBarChart,
    PieChart,
    QuantitativeOrganizations,
    QuantitativeAttributeValues
  },

  data() {
    return {
      datacollection: {},
      graphBarColors: ['#00ead4', '#00c3b1', '#00a897', '#008275', '#00423c'],
      pieGraphBackgroundColors: [
        '#22BED3',
        '#22BED3',
        '#BEE6FB',
        '#D1D1D1',
        '#D1D1D1'
      ],
      compareValues: {},
      emptyQuestions: false,
      displayable: false,
      pieChartData: {},
      dataLoaded: false,
      chartStyle: { height: '400px', position: 'relative' },
      pieChartStyle: { height: '450px', position: 'relative' },
      verticalBarChartData: {}
    };
  },

  computed: {
    ...mapState('resources/u_quantitative', [
      'surveys',
      'displayAttributeValues',
      'organizationIds',
      'attributeValueIds',
      'analyseChangesData',
      'quantitativeQuestions',
      'navOrganizations'
    ]),
    ...mapState('pages/u_surveys/select', ['surveyId'])
  },

  watch: {
    analyseChangesData() {
      this.updateGraph();
    },
    quantitativeQuestions: function() {
      if (Object.keys(this.quantitativeQuestions).length <= 0)
        this.emptyQuestions = true;
    },
    surveyId: function() {
      this.emptyQuestions = false;
      this.loadNavOrganizations({ survey_id: this.surveyId }).then(_ => {
        if (this.surveyId) this.setOrganizations();
      });
    },
    organizationIds() {
      this.load();
    },
    attributeValueIds() {
      this.load();
    }
  },

  created() {
    const vcd = defaultCookie.get();
    if (vcd['surveyId'] || this.surveyId) {
      this.loadNavOrganizations({
        survey_id: vcd['surveyId'] || this.surveyId
      }).then(_ => {
        this.setOrganizations();
      });
    }
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_quantitative', [
      'loadAnalyseChangesData',
      'loadNavOrganizations'
    ]),
    ...mapMutations('resources/u_quantitative', ['setOrganizationIds']),
    load() {
      const vcd = defaultCookie.get();
      const params = {
        survey_id: vcd['surveyId'] || this.surveyId,
        organization_ids: this.organizationIds,
        attribute_value_ids: this.attributeValueIds
      };
      this.loadAnalyseChangesData(params).then(() => {
        if (!this.dataLoaded) this.matchHeights();
      });
    },
    updateGraph() {
      for (let key in this.analyseChangesData) {
        let data = [];
        let labels = [];
        for (let key2 in this.analyseChangesData[key]) {
          let survey = this.surveys.find(survey => survey.name === key2);
          if (survey) {
            labels.push(survey.short_name || survey.name);
            data.push(this.analyseChangesData[key][key2].toFixed(1));
          }
        }
        let datasets = [
          {
            backgroundColor: this.graphBarColors,
            borderWidth: 1,
            borderColor: this.graphBarColors,
            data: data
          }
        ];
        const dc = {
          labels: labels.map(v => v.split('')),
          datasets: datasets
        };
        Vue.set(this.datacollection, key, dc);
        let lastValue = data[data.length - 2];
        let currentValue = data[data.length - 1];
        let compareValue = lastValue - currentValue;
        const cv = compareValue < 0 ? compareValue * -1 : compareValue;
        Vue.set(this.compareValues, key, cv);
      }
    },
    matchHeights() {
      let promise = new Promise((resolve, _reject) => {
        this.dataLoaded = true;
        resolve();
      });
      promise.then(() => {
        let titleArr = [];
        let textArr = [];
        let descriptionArr = [];
        this.quantitativeQuestions.forEach(q => {
          let titleEl = document.getElementById(
            'quantitative-question-title' + q.code
          );
          let textEl = document.getElementById(
            'quantitative-question-text' + q.code
          );
          let descriptionEl = document.getElementById(
            'quantitative-question-description' + q.code
          );
          titleEl.style.height = '';
          textEl.style.height = '';
          descriptionEl.style.height = '';
        });
        this.quantitativeQuestions.forEach(q => {
          let titleHeight = document.getElementById(
            'quantitative-question-title' + q.code
          ).clientHeight;
          let textHeight = document.getElementById(
            'quantitative-question-text' + q.code
          ).clientHeight;
          let descriptionHeight = document.getElementById(
            'quantitative-question-description' + q.code
          ).clientHeight;
          titleArr.push(titleHeight);
          textArr.push(textHeight);
          descriptionArr.push(descriptionHeight);
        });
        const titleMax = Math.max(...titleArr);
        const textMax = Math.max(...textArr);
        const descriptionMax = Math.max(...descriptionArr);
        this.quantitativeQuestions.forEach(q => {
          let titleEl = document.getElementById(
            'quantitative-question-title' + q.code
          );
          let textEl = document.getElementById(
            'quantitative-question-text' + q.code
          );
          let descriptionEl = document.getElementById(
            'quantitative-question-description' + q.code
          );
          titleEl.style.height = titleMax + 'px';
          textEl.style.height = textMax + 'px';
          descriptionEl.style.height = descriptionMax + 'px';
        });
      });
    },
    options(question) {
      return {
        title: {
          display: false
        },
        tooltips: {
          enabled: true,
          backgroundColor: 'rgba(0,0,0,0.6)',
          callbacks: {
            title: function(tooltipItem) {
              return tooltipItem[0].label.replaceAll(',', '');
            },
            label: function(tooltipItem) {
              if (tooltipItem.xLabel == 0) return;
              let num = tooltipItem.yLabel;
              return num.toFixed(1) + (question.unit ? question.unit : '');
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: -1,
            bottom: 0
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.3,
              ticks: {
                fontSize: 10
              }
            }
          ],
          yAxes: [
            {
              barPercentage: 0.5,
              ticks: {
                beginAtZero: true,
                fontSize: 10,
                maxTicksLimit: 4,
                callback: function(value) {
                  return (
                    value.toFixed(1) + (question.unit ? question.unit : '')
                  );
                }
              }
            }
          ]
        }
      };
    },
    newOptions(question) {
      const defaultOptions = this.options(question);

      let options = {};
      Object.assign(options, defaultOptions);
      const answersSize = question.answers_size;
      options.scales.yAxes[0].ticks.max = answersSize;
      options.scales.yAxes[0].ticks.maxTicksLimit = answersSize * 2;
      return options;
    },
    pieChartOptions(title) {
      return {
        responsive: true,
        title: {
          display: true,
          text: title + 'の件数内訳'
        },
        layout: {
          padding: {
            left: 12,
            right: 12,
            top: 0,
            bottom: 0
          }
        },
        tooltips: {
          enabled: true,
          backgroundColor: 'rgba(0,0,0,0.6)',
          callbacks: {
            title: function(tooltipItem, data) {
              const num = data.labels[tooltipItem[0].index];
              let label;
              if (num === 1) label = '1：全く満足していない';
              if (num === 2) label = '2：満足していない';
              if (num === 3) label = '3：どちらともいえない';
              if (num === 4) label = '4：満足している';
              if (num === 5) label = '5：非常に満足している';
              return label;
            },
            label: function(tooltipItem, data) {
              let num = data.datasets[0].data[tooltipItem.index];
              return num;
            }
          }
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            generateLabels: function(data) {
              const labelHash = {
                1: '全く満足していない',
                2: '満足していない',
                3: 'どちらともいえない',
                4: '満足している',
                5: '非常に満足している'
              };
              const chartData = data.tooltip._data;
              const chartDataset = chartData.datasets[0];
              const chartLabels = chartData.labels;
              const legendArray = [];

              for (let i = 0; i < chartLabels.length; i++) {
                const eachLabel = chartLabels[i];
                const backgroundColor = chartDataset.backgroundColor[i];

                const eachLengend = {
                  text: `${eachLabel}：${labelHash[eachLabel]}`,
                  fillStyle: backgroundColor,
                  hidden: false,
                  lineCap: 'square',
                  lineDash: [0],
                  lineDashOffset: 0,
                  lineJoin: 'bevel',
                  lineWidth: 1,
                  strokeStyle: '',
                  pointStyle: ''
                };
                legendArray.push(eachLengend);
              }

              return legendArray;
            }
          },
          align: 'left'
        },
        maintainAspectRatio: false
      };
    },
    calculate(answers) {
      let counts = {};
      for (const num of answers) {
        counts[num] = counts[num] ? counts[num] + 1 : 1;
      }
      const keys = Object.keys(counts).sort();
      let result = 0;
      keys.map(key => {
        result += key * counts[key];
      });
      return (result / answers.length).toFixed(1);
    },
    setOrganizations() {
      const orgs = this.navOrganizations;
      let selected = [];
      for (let i in orgs) {
        const children = orgs[i].children;
        selected.push(orgs[i].id);
        for (let ii in children) {
          const grandChildren = children[ii].children;
          selected.push(children[ii].id);
          for (let iii in grandChildren) {
            selected.push(grandChildren[iii]);
          }
        }
      }
      this.setOrganizationIds(selected.sort());
    }
  }
};
</script>
<style scoped>
.vc-topic-content {
  display: flex;
  align-items: flex-start;
}
</style>
