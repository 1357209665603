<template lang="pug">
  #container.container-scroller
    AdminNavbar(:operator="operator")
    .right-sidebar-toggler-wrapper
    .container-fluid.page-body-wrapper
      AdminLeftNav
      .main-panel
        router-view
        footer.footer
          .container-fluid.clearfix
            span.text-muted.d-block.text-center.text-sm-left.d-sm-inline-block
              | Copyright © 2020 VoiScope All rights reserved.
</template>

<script>
// -- global header --
import AdminNavbar from 'src/components/x/AdminNavbar';
import AdminLeftNav from 'src/components/x/AdminLeftNav';

import { mapState, mapMutations } from 'vuex';

export default {
  components: {
    AdminNavbar,
    AdminLeftNav
  },

  data() {
    return {};
  },

  computed: {
    ...mapState('config', ['operator'])
  },

  created() {
    this.initConfig(this.$parent.config);
  },

  mounted() {},

  methods: {
    ...mapMutations('config', { initConfig: 'init' })
  }
};
</script>
