<template lang="pug">
  div
    p.vc-left-col-title
    .text-right
      a.attribute-values-btn.mr-3.mb-0(@click="toggleDisplayAttributeValues") << 閉じる
    .vc-cat
      .row
        .col-6
          button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="allSelect") すべて選択
        .col-6
          button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="clearSelect") 選択解除
    .vc-cat(
      v-for="(response_attribute, i) in response_attributes" :key="i"
      )
      p.vc-left-head-1
        | {{response_attribute.name}}
      ul
        li(
          v-for="(attribute_value, ii) in response_attribute.attribute_values.slice(0,4)" :key="ii"
          )
          .custom-control.custom-checkbox.vc-custom-checkbox
            input(
              type="checkbox"
              class="custom-control-input"
              :id="'attributeValue'+attribute_value.id"
              :value="attribute_value.id"
              v-model="selected"
              )
            label(
              class="custom-control-label"
              :for="'attributeValue'+attribute_value.id"
              )
              | {{attribute_value.name}}
      a(
        v-if="response_attribute.attribute_values.length>=5"
        :href="'#collapse'+i"
        data-toggle="collapse"
        class="collapsed vc-collapse"
        )
        | すべて表示
      div(
        v-if="response_attribute.attribute_values.length>=5"
        class="collapse"
        :id="'collapse'+i"
        )
        ul
          li(
            v-for="(attribute_value, ii) in response_attribute.attribute_values.slice(4)" :key="ii"
            )
            .custom-control.custom-checkbox.vc-custom-checkbox
              input(
                type="checkbox"
                class="custom-control-input"
                :id="'attributeValue'+attribute_value.id"
                :value="attribute_value.id"
                v-model="selected"
                )
              label(
                class="custom-control-label"
                :for="'attributeValue'+attribute_value.id"
                )
                | {{attribute_value.name}}
        a(
          :href="'#collapse'+i"
          data-toggle="collapse"
          class="collapsed vc-collapse-open"
          )
          | 閉じる
    //- .vc-submit-wrap
    //-   button(
    //-     class="btn btn-primary btn-block vc-primary-btn"
    //-     :disabled="processing"
    //-     @click.prevent="submit"
    //-     )
    //-     | フィルタを設定

</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  components: {},
  props: {},
  data() {
    return {
      selected: [],
      processing: false
    };
  },
  computed: {
    ...mapState('resources/u_response_attributes', ['response_attributes']),
    ...mapState('resources/u_opinion_search', ['keepedAttributeValues']),
    ...mapState('resources/u_topic', ['displayAttributeValues', 'started'])
  },
  watch: {
    selected() {
      this.submit();
    }
  },
  created() {
    this.loadResponseAttributes();
    if (this.keepedAttributeValues.length > 0)
      this.selected = this.keepedAttributeValues;
    if (this.started) this.submit();
    this.setStarted(true);
  },
  mounted() {},
  methods: {
    ...mapActions('resources/u_response_attributes', [
      'loadResponseAttributes'
    ]),
    ...mapMutations('resources/u_topic', [
      'setDisplayAttributeValues',
      'setStarted'
    ]),
    allSelect() {
      const response_attributes = this.response_attributes;
      let selected = [];
      for (let i in response_attributes) {
        for (let ii in response_attributes[i].attribute_values) {
          selected.push(response_attributes[i].attribute_values[ii].id);
        }
      }
      this.selected = selected;
    },
    clearSelect() {
      this.selected = [];
    },
    submit() {
      this.processing = true;
      this.$emit('input', this.selected);
      this.processing = false;
    },
    toggleDisplayAttributeValues() {
      this.setDisplayAttributeValues(!this.displayAttributeValues);
    }
  }
};
</script>
