<template lang="pug">
  div
    div(v-if="dataLoaded")
      .vc-main-container.container
        .vc-content-col
          .container
            .row
              .col-4.pr-4.pl-4(v-for="q in quantitativeQuestions")
                .h4.vc-midashi.mt-2.mb-2(:id="'quantitative-question-title'+q.code")
                  | {{ q.title }}
                p.small(:id="'quantitative-question-text'+q.code")
                  | {{ q.question_text }}
                p.small(:id="'quantitative-question-description'+q.code")
                  | {{ q.description }}
                .text-right
                  router-link.btn.btn-primary.vc-primary-btn.vc-detail-btn.mt-3.mb-3(
                    type="button"
                    :to="{ name: 'u_quantitative_calculated_analyse_details', params: { surveyId: surveyId, quantitativeQuestionId: q.id } }"
                    :class="{'btn-disabled': compareValues[q.code] < (q.reference_point === 0 ? 0.0 : 0)}"
                  )
                    | 変動の内訳をみる
                vertical-bar-chart(
                  :chart-data="datacollection[q.code]" :options="options(q)" :addDataLabels="true" v-if="datacollection[q.code]" :styles="chartStyle"
                )
                .mt-4
                pie-chart(:chart-data="pieChartData[q.code] || {}" :options="pieChartOptions(q.title)" v-if="pieChartData[q.code]" :styles="pieChartStyle")
            .row(v-show="emptyQuestions")
              .col-12
                p
                  | 表示できるデータがありません
    .vc-main-container.container(v-else)
      .vc-content-col
        .container
          | 読み込み中です。しばらくお待ちください。

</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import VerticalBarChart from '../../../components/u/chartJs/VerticalBarChart';
import PieChart from '../../../components/u/chartJs/PieChart';
import QuantitativeOrganizations from '../../../components/u/quantitativeAnalyse/QuantitativeOrganizations';
import QuantitativeAttributeValues from '../../../components/u/quantitativeAnalyse/QuantitativeAttributeValues';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    VerticalBarChart,
    PieChart,
    QuantitativeOrganizations,
    QuantitativeAttributeValues
  },
  data() {
    return {
      datacollection: {},
      graphBarColors: ['#00ead4', '#00c3b1', '#00a897', '#008275', '#00423c'],
      pieGraphBackgroundColors: [
        '#22BED3',
        '#22BED3',
        '#BEE6FB',
        '#D1D1D1',
        '#D1D1D1'
      ],
      compareValues: {},
      emptyQuestions: false,
      displayable: false,
      attributeValueIds: [],
      pieChartData: {},
      dataLoaded: false,
      chartStyle: { height: '400px', position: 'relative' },
      pieChartStyle: { height: '450px', position: 'relative' },
      verticalBarChartData: {}
    };
  },
  computed: {
    ...mapState('resources/u_quantitative', [
      'surveys',
      'calculatedAnalyseChangesData',
      'quantitativeQuestions'
    ]),
    ...mapState('pages/u_surveys/select', ['surveyId'])
  },
  watch: {
    calculatedAnalyseChangesData() {
      if (this.dataLoaded) this.updateGraph();
    },
    quantitativeQuestions: function() {
      if (Object.keys(this.quantitativeQuestions).length <= 0)
        this.emptyQuestions = true;
    },
    surveyId: function() {
      this.emptyQuestions = false;
      this.load();
    }
  },
  created() {},
  mounted() {
    const vcd = defaultCookie.get();
    if (vcd['surveyId'] || this.surveyId) this.load();
  },
  methods: {
    ...mapActions('resources/u_quantitative', [
      'loadCalculatedAnalyseChangesData'
    ]),
    load() {
      this.dataLoaded = false;
      const vcd = defaultCookie.get();
      this.loadCalculatedAnalyseChangesData({
        survey_id: vcd['surveyId'] || this.surveyId
      }).then(() => {
        this.updateGraph();
        this.matchHeights();
      });
    },
    updateGraph() {
      for (let key in this.calculatedAnalyseChangesData) {
        let data = [];
        let labels = [];
        for (let key2 in this.calculatedAnalyseChangesData[key]) {
          let survey = this.surveys.find(
            survey => survey.serial_no === Number(key2)
          );
          labels.push(survey.short_name || survey.name);
          data.push(this.calculatedAnalyseChangesData[key][key2].toFixed(1));
        }
        let datasets = [
          {
            backgroundColor: this.graphBarColors,
            borderWidth: 1,
            borderColor: this.graphBarColors,
            data: data
          }
        ];
        const dc = {
          labels: labels.map(v => v.split('')),
          datasets: datasets
        };
        Vue.set(this.datacollection, key, dc);
        let lastValue = data[data.length - 2];
        let currentValue = data[data.length - 1];
        let compareValue = lastValue - currentValue;
        const cv = compareValue < 0 ? compareValue * -1 : compareValue;
        Vue.set(this.compareValues, key, cv);
      }
    },
    matchHeights() {
      let promise = new Promise((resolve, _reject) => {
        this.dataLoaded = true;
        resolve();
      });
      promise.then(() => {
        let titleArr = [];
        let textArr = [];
        let descriptionArr = [];
        this.quantitativeQuestions.forEach(q => {
          let titleEl = document.getElementById(
            'quantitative-question-title' + q.code
          );
          let textEl = document.getElementById(
            'quantitative-question-text' + q.code
          );
          let descriptionEl = document.getElementById(
            'quantitative-question-description' + q.code
          );
          titleEl.style.height = '';
          textEl.style.height = '';
          descriptionEl.style.height = '';
        });
        this.quantitativeQuestions.forEach(q => {
          let titleHeight = document.getElementById(
            'quantitative-question-title' + q.code
          ).clientHeight;
          let textHeight = document.getElementById(
            'quantitative-question-text' + q.code
          ).clientHeight;
          let descriptionHeight = document.getElementById(
            'quantitative-question-description' + q.code
          ).clientHeight;
          titleArr.push(titleHeight);
          textArr.push(textHeight);
          descriptionArr.push(descriptionHeight);
        });
        const titleMax = Math.max(...titleArr);
        const textMax = Math.max(...textArr);
        const descriptionMax = Math.max(...descriptionArr);
        this.quantitativeQuestions.forEach(q => {
          let titleEl = document.getElementById(
            'quantitative-question-title' + q.code
          );
          let textEl = document.getElementById(
            'quantitative-question-text' + q.code
          );
          let descriptionEl = document.getElementById(
            'quantitative-question-description' + q.code
          );
          titleEl.style.height = titleMax + 'px';
          textEl.style.height = textMax + 'px';
          descriptionEl.style.height = descriptionMax + 'px';
        });
      });
    },
    options(question) {
      return {
        title: {
          display: false
        },
        tooltips: {
          enabled: true,
          backgroundColor: 'rgba(0,0,0,0.6)',
          callbacks: {
            title: function(tooltipItem) {
              return tooltipItem[0].label.replaceAll(',', '');
            },
            label: function(tooltipItem) {
              if (tooltipItem.xLabel == 0) return;
              let num = tooltipItem.yLabel;
              return num.toFixed(1) + (question.unit ? question.unit : '');
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: -1,
            bottom: 0
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.3,
              ticks: {
                fontSize: 10
              }
            }
          ],
          yAxes: [
            {
              barPercentage: 0.5,
              ticks: {
                beginAtZero: true,
                fontSize: 10,
                maxTicksLimit: 4,
                callback: function(value) {
                  return (
                    value.toFixed(1) + (question.unit ? question.unit : '')
                  );
                }
              }
            }
          ]
        }
      };
    },
    newOptions(question) {
      const defaultOptions = this.options(question);
      let options = {};
      Object.assign(options, defaultOptions);
      const answersSize = question.answers_size;
      options.scales.yAxes[0].ticks.max = answersSize;
      options.scales.yAxes[0].ticks.maxTicksLimit = answersSize * 2;
      return options;
    },
    pieChartOptions(title) {
      return {
        responsive: true,
        title: {
          display: true,
          text: title + 'の件数内訳'
        },
        layout: {
          padding: {
            left: 12,
            right: 12,
            top: 0,
            bottom: 0
          }
        },
        tooltips: {
          enabled: true,
          backgroundColor: 'rgba(0,0,0,0.6)',
          callbacks: {
            title: function(tooltipItem, data) {
              const num = data.labels[tooltipItem[0].index];
              let label;
              if (num === 1) label = '1：全く満足していない';
              if (num === 2) label = '2：満足していない';
              if (num === 3) label = '3：どちらともいえない';
              if (num === 4) label = '4：満足している';
              if (num === 5) label = '5：非常に満足している';
              return label;
            },
            label: function(tooltipItem, data) {
              let num = data.datasets[0].data[tooltipItem.index];
              return num;
            }
          }
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            generateLabels: function(data) {
              const labelHash = {
                1: '全く満足していない',
                2: '満足していない',
                3: 'どちらともいえない',
                4: '満足している',
                5: '非常に満足している'
              };
              const chartData = data.tooltip._data;
              const chartDataset = chartData.datasets[0];
              const chartLabels = chartData.labels;
              const legendArray = [];

              for (let i = 0; i < chartLabels.length; i++) {
                const eachLabel = chartLabels[i];
                const backgroundColor = chartDataset.backgroundColor[i];

                const eachLengend = {
                  text: `${eachLabel}：${labelHash[eachLabel]}`,
                  fillStyle: backgroundColor,
                  hidden: false,
                  lineCap: 'square',
                  lineDash: [0],
                  lineDashOffset: 0,
                  lineJoin: 'bevel',
                  lineWidth: 1,
                  strokeStyle: '',
                  pointStyle: ''
                };
                legendArray.push(eachLengend);
              }

              return legendArray;
            }
          },
          align: 'left'
        },
        maintainAspectRatio: false
      };
    }
  }
};
</script>
<style scoped>
.quantitative-divider {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #eaeaea;
}
.vc-quantitative a.vc-detail-btn {
  font-size: 0.7rem;
  width: 150px;
  padding: 7px;
}
.vc-topic-content {
  display: flex;
  align-items: flex-start;
}
</style>
