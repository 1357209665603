<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | {{ $t('admin.chatGPTSentimentAnalysis.new.title') }}
  .row.flex-grow
    .col-12
      .card
        .card-body
          form
            .alert.alert-danger(v-if="errorMessages.length > 0")
              ul.mb-0
                li(v-for="error in errorMessages")
                  | {{ error }}

            validation-observer(v-slot="{ invalid }")
              validation-provider(name="surveyId" rules="required|numeric" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | SurveyId
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="survey_id"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="questionId" rules="required|numeric" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | QuestionId
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="question_id"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              //- チェックボックスを追加
              .form-group
                .custom-control.custom-checkbox.vc-custom-checkbox
                  input.custom-control-input(
                    type="checkbox"
                    id="require-upload"
                    v-model="requireUpload"
                  )
                  label.col-form-label.custom-control-label(for="require-upload")
                    | 回答データをアップロードする
              //- ファイルアップロード 
              .form-group(v-if="!requireUpload")
                label
                  | ファイルアップロード
                .row
                  .col-12
                    label.btn.btn-secondary ファイル添付
                      input.d-none(
                        type="file"
                        :accept="acceptFiletypes"
                        @change="onFileChange($event)"
                      )

              .mt-5.float-right
                button.btn.btn-success(
                  :disabled="processing || invalid"
                  @click.prevent="submit"
                )
                  | クラスタリング実行

</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {},
  data() {
    return {
      survey_id: '',
      question_id: '',
      requireUpload: false,
      errorMessages: [],
      processing: false,
      acceptFiletypes: 'text/csv',
      inputFile: ''
    };
  },
  computed: {
    /**
     * リクエストパラメータ
     * @return {Object}
     */
    requestParams() {
      return {
        survey_id: this.survey_id,
        question_id: this.question_id,
        require_upload: this.requireUpload,
        input_file: this.inputFile
      };
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('resources/x_chatgpt_sentiment_analysis', [
      'requestClassification'
    ]),

    /**
     * エラーメッセージをリセット
     */
    resetErrorMessages() {
      this.errorMessages = [];
    },

    /**
     * リセットパラメータ
     */
    resetParams() {
      this.survey_id = '';
      this.question_id = '';
      this.requireUpload = true;
      this.inputFile = '';
    },

    /**
     * ファイル変更時
     * @param e
     */
    onFileChange(e) {
      this.resetErrorMessages();

      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      if (files[0].type === 'text/csv') {
        this.inputFile = files[0];
      }
    },

    /**
     * フォーム送信
     */
    submit() {
      this.processing = true;
      this.resetErrorMessages();

      // Create FormData object to handle file upload
      const formData = new FormData();
      formData.append('survey_id', this.survey_id);
      formData.append('question_id', this.question_id);
      formData.append('require_upload', this.requireUpload);
      if (this.inputFile) {
        formData.append('input_file', this.inputFile);
      }

      fetch('/api/x/chatgpt_sentiment_analysis', {
        method: 'POST',
        body: formData
      })
        .then(response => {
          if (response.status === 200) {
            this.resetParams();
            this.$router.push({ name: 'x_chatgpt_sentiment_analysis' });
          } else if (response.status === 422) {
            return response.json();
          }
        })
        .then(jsonResponse => {
          this.errorMessages = jsonResponse.errors;
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>

<style scoped></style>
