import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const en = require('./messages/en.json');
const ja = require('./messages/ja.json');

export default new VueI18n({
  locale: 'ja',
  messages: {
    en: en,
    ja: ja
  }
});
