<template lang="pug">
  div
    modal(
      ref="modalPrOpinionAttrVal"
      name="modalPrOpinionAttrVal"
      id="modalPrOpinionAttrVal"
      :minWidth="750"
      :minHeight="570"
      :width="750"
      :height="570"
      :reset="true"
      :resizable="false"
      :clickToClose="false"
      :scrollable="true"
      :draggable="true")
      header.modal-header.align-items-end
        h6.modal-title.vc-midashi
          | 意見分類別ポジティブのランキング
        dl.respondent-num.align-items-center
          dt.mb-0.ml-0
            button(type="button" @click="$modal.hide('modalPrOpinionAttrVal')" class="close vc-close")
              | ×
      .vc-dashboard(:style="{height: (570 * 0.89) - 57 + 'px', overflow: 'scroll'}")
        .vc-dashboard-box.ranking(v-show="ranking.length > 0")
          .container-fluid
            .row.vc-dashboard-box-content.ranking-positive
              .col-12.vc-dashboard-box-ranking.modal-ranking
                h4.vc-midashi.mt-3.mb-2
                  | {{ opinion_type }}
                .row.vc-dashboard-ranking-board.vc-dashboard-ranking-header.pl-3.pr-3
                  .col-1.p-0.text-center
                    .row
                      .col-6.p-0.text-center 今回
                      .col-6.p-0.text-center 変動
                  .col-6.p-0.offset-1 チーム
                  .col-2.p-0.negaposi-value-left
                    | {{'ポジティブ意見' + ([0, 1].includes(displayType) ? '率' : '数')}}
                  .col-2.p-0.negaposi-value-right
                    | {{'ネガティブ意見' + ([0, 1].includes(displayType) ? '率' : '数')}}
                .row.vc-dashboard-ranking-board.pl-3.pr-3(v-for="attr in rankingSort()" :class="{'font-weight-bold': organizationId === attr.organization_id}")
                  .col-1.p-0
                    .row(v-if="[0, 1].includes(displayType)")
                      .col-6
                        | {{attr.posi_rate_rank}}
                      .col-6.p-0.text-center(v-html='fluctuation(attr.posi_rate_rank_fluctuation,attr.prev_display)')
                    .row(v-else)
                      .col-6
                        | {{attr.posi_count_rank}}
                      .col-6.p-0.text-center(v-html='fluctuation(attr.posi_count_rank_fluctuation,attr.prev_display)')
                  .col-6.p-0.offset-1
                    | {{attr.organization_name}}
                  .col-2.negaposi-value-left
                    .row
                      .col-5.p-0
                        a(
                          href="javascript:void(0)"
                          @click="toOpinionSearch(attr.organization_id, opinionIds[opinion_type], emotionIds['ネガティブ'])"
                        )
                          svg.vc-dashboard-to-opinion-search(
                            xmlns="http://www.w3.org/2000/svg"
                            enable-background="new 0 0 24 24"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#000000"
                          )
                            g
                              rect(fill="none" height="24" width="24")
                            g
                              g
                                rect(height="2" width="9" x="13" y="7")
                                rect(height="2" width="9" x="13" y="15")
                                rect(height="2" width="6" x="16" y="11")
                                polygon(points="13,12 8,7 8,11 2,11 2,13 8,13 8,17")
                      .col-7.p-0.pl-1.fluctuation-col
                        .row(v-if="[0, 1].includes(displayType)")
                          .col-5
                            | {{toFloat(attr.posi_rate)}}
                          .col-6
                            | {{'(' + (attr.posi_rate_fluctuation * -1 > 0 ? '+' : '') + (toFloat(attr.posi_rate_fluctuation) * -1) + ')'}}
                        .row(v-else)
                          .col-5
                            | {{attr.posi_count}}
                          .col-6
                            | {{'(' + (attr.posi_count_fluctuation * -1 > 0 ? '+' : '') + (attr.posi_count_fluctuation * -1) + ')'}}
                  .col-2.negaposi-value-right
                    .row
                      .col-5.p-0
                        a(
                          href="javascript:void(0)"
                          @click="toOpinionSearch(attr.organization_id, opinionIds[opinion_type], emotionIds['ポジティブ'])"
                        )
                          svg.vc-dashboard-to-opinion-search(
                            xmlns="http://www.w3.org/2000/svg"
                            enable-background="new 0 0 24 24"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#000000"
                          )
                            g
                              rect(fill="none" height="24" width="24")
                            g
                              g
                                rect(height="2" width="9" x="13" y="7")
                                rect(height="2" width="9" x="13" y="15")
                                rect(height="2" width="6" x="16" y="11")
                                polygon(points="13,12 8,7 8,11 2,11 2,13 8,13 8,17")
                      .col-7.p-0.pl-1.fluctuation-col
                        .row(v-if="[0, 1].includes(displayType)")
                          .col-5
                            | {{toFloat(attr.nega_rate)}}
                          .col-6
                            | {{'(' + (attr.nega_rate_fluctuation * -1 > 0 ? '+' : '') + (toFloat(attr.nega_rate_fluctuation) * -1) + ')'}}
                        .row(v-else)
                          .col-5
                            | {{attr.nega_count}}
                          .col-6
                            | {{'(' + (attr.nega_count_fluctuation * -1 > 0 ? '+' : '') + (attr.nega_count_fluctuation * -1) + ')'}}

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      organizationId: null,
      displayType: null
    };
  },

  computed: {
    ...mapState('resources/u_analyse_team', [
      'ranking',
      'opinion_type',
      'opinionIds',
      'emotionIds'
    ])
  },

  watch: {},

  created() {},

  mounted() {},

  methods: {
    ...mapActions('resources/u_analyse_team', [
      'loadRankingByOpinionClassifications'
    ]),
    modalOpen(data) {
      const params = {
        survey_id: data.survey_id,
        question_id: data.question_id,
        organization_id: data.organization_id,
        opinion_classification_id: data.opinion_classification_id
      };

      this.organizationId = data.organization_id;
      this.displayType = data.display_type;

      this.loadRankingByOpinionClassifications(params);
      this.$modal.show('modalPrOpinionAttrVal');
    },
    rankingSort() {
      let that = this;
      let ranks = [...this.ranking];
      ranks = ranks.sort(function(a, b) {
        if ([0, 1].includes(that.displayType)) {
          if (a.posi_rate < b.posi_rate) return 1;
          if (a.posi_rate > b.posi_rate) return -1;
        } else {
          if (a.posi_count < b.posi_count) return 1;
          if (a.posi_count > b.posi_count) return -1;
        }
        return 0;
      });
      return ranks;
    },
    fluctuation(fluc, prev_display) {
      let newFluc = fluc * -1;
      if (!prev_display) {
        return '<span>-</span>';
      } else if (Math.sign(newFluc) === 1) {
        if (newFluc >= 10)
          return (
            '<span class="ranking-item-rankup ranking-item-number-hilight">' +
            this.fluctuationIcon('up') +
            newFluc +
            '</span>'
          );
        else
          return (
            '<span class="ranking-item-rankup">' +
            this.fluctuationIcon('up') +
            newFluc +
            '</span>'
          );
      } else if (Math.sign(newFluc) === -1) {
        if (newFluc <= -10)
          return (
            '<span class="ranking-item-rankdown ranking-item-number-hilight">' +
            this.fluctuationIcon('down') +
            fluc +
            '</span>'
          );
        else
          return (
            '<span class="ranking-item-rankdown">' +
            this.fluctuationIcon('down') +
            fluc +
            '</span>'
          );
      } else {
        return '<span>0</span>';
      }
    },
    fluctuationIcon(upDown) {
      if (upDown === 'up') {
        return (
          '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24"' +
          ' width="14px" fill="#000000"><rect fill="none" height="24" width="24"/><path d="M9,5v2h6.59L4,18.59L5.41,20L17,8.41V15h2V5H9z"/></svg>'
        );
      } else {
        return (
          '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24"' +
          ' width="14px" fill="#000000"><rect fill="none" height="24" width="24"/><path d="M19,9h-2v6.59L5.41,4L4,5.41L15.59,17H9v2h10V9z"/></svg>'
        );
      }
    },
    toFloat(rate) {
      if (rate % 1 === 0) {
        return parseFloat(rate).toFixed(1);
      } else {
        return rate;
      }
    },
    toOpinionSearch(
      organizationId,
      opinionClassificationId,
      emotionClassificationId
    ) {
      this.$emit(
        'toOpinionSearch',
        opinionClassificationId,
        emotionClassificationId,
        undefined,
        organizationId
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.vc-dashboard-box-ranking.modal-ranking {
  max-width: 100% !important;
}
</style>
