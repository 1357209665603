<template lang="pug">
  .vc-content-col#test
    .container
      .vc-content-main.mt-4
        .row.align-items-center
          .col-6
            p より具体性のある順番でピックアップしています。
        .vc-content-main-wrap(v-if="loading")
          .row.align-items-center.mb-1
            .col-6
              | 読み込み中です。しばらくお待ちください。
        .vc-content-main-wrap(v-else-if="responses.length <= diplayableNum()")
          .row.align-items-center.mb-1
            | 回答数が{{diplayableNum()}}以下の場合は表示できません。分析対象の組織を選びなおしてください。
        .vc-content-main-wrap(v-else)
          .row
            p
              | {{ '「'+(currentOpinionValueName || 'すべて')+'」を含む具体意見' }}
          .row.align-items-center.mb-1
            .col-6
              ul.vc-content-quantity
                li
                  a(href="javascript:void(0)" @click="checkAll") すべて選択
                  | 　／
                li
                  a(href="javascript:void(0)" @click="uncheckAll") すべてクリア
            .col-6
              .row
                .col-6
                  button.btn.btn-primary.vc-primary-btn.btn-block.vc-export-btn.gray-btn(@click.prevent="exportOpinions") 選択意見をCSV出力
                .col-6
                  button.btn.btn-primary.vc-primary-btn.btn-block.vc-export-btn.gray-btn(@click.prevent="exportOpinionsPdf") 選択意見をPDF出力
          .row
            .col-12(v-show="message")
              ul.alert.alert-success
                button.close.vc-message-close(@click="resetMessage")
                  span(aria-hidden='true') ×
                li.ml-1
                  | {{ message }}
          .vc-content-main-box(v-for="(item, idx) in responses")
            .row
              .col-12
                .row
                  .col-6
                    .custom-control.custom-checkbox
                      input.custom-control-input(
                        type="checkbox"
                        :id="'response-check-' + item.id"
                        :value="item.id"
                        v-model="responseIds"
                      )
                      label.custom-control-label(:for="'response-check-' + item.id")
                        | {{ item.grand_parent_organization_name }}
                        span(v-if="item.grand_parent_organization_name")
                          |  >>
                        | {{ item.parent_organization_name }}
                        span(v-if="item.parent_organization_name")
                          |  >>
                        | {{ item.organization_name }}
                  .col-6
                    .text-right
                      button.btn.btn-primary.vc-primary-btn.vc-opinionsearch-btn(
                        type="button"
                        @click.prevent="toOpinionSearch(item.survey_response_id)"
                      )
                        | 意見検索へ
              .col-12.vc-voice-persona.mb-3
                span(v-if="displayAttrs()")
                  span(v-for="val in item.attribute_values")
                    | {{ val.name }}
            ResponseItem(:item="item" :textIds="textIds" @input="changeTextIds($event)")
            .text-right.mt-5(:id="'action-btns-'+item.id")
              span.mr-1
                input(type="hidden" :id="'original-sentence' + item.id" :value="item.original_sentences")
                a.vc-normal-btn-sm(href="javascript:void(0)" @click.prevent="copy(item.id)")
                  | コピー
                  img.ml-1(src="../../../assets/images/excheck/copy.png" width="17")
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ResponseItem from '../../../components/u/response_ranking/ResponseItem';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    ResponseItem
  },
  props: {},
  data() {
    return {
      responseIds: [],
      displayChatMemo: {},
      textIds: []
    };
  },
  computed: {
    ...mapState('resources/u_response_ranking', [
      'loading',
      'started',
      'totalResId',
      'responses',
      'currentOpinionValueName',
      'opinionValues',
      'message'
    ]),
    ...mapState('pages/u_surveys/select', ['surveyId', 'isTopic']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('resources/u_quantitative', ['questionId'])
  },
  watch: {
    surveyId: function() {
      this.responseIds = [];
    },
    responses: function() {
      this.responses.forEach(response => {
        this.$set(this.displayChatMemo, response.id, false);
        this.displayChatMemo[response.id] = false;
      });
    }
  },
  created() {},
  mounted() {},

  methods: {
    ...mapActions('resources/u_response_ranking', ['exportCsv', 'exportPdf']),
    ...mapMutations('resources/u_response_ranking', [
      'setCurrentResponseId',
      'setMessage'
    ]),
    copy(resId) {
      let originalSentence = document.querySelector(
        '#original-sentence' + resId
      );

      originalSentence.setAttribute('type', 'text');
      originalSentence.select();

      document.execCommand('copy');

      originalSentence.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },
    exportOpinions() {
      this.resetMessage();
      if (this.responseIds.length === 0) return false;

      const vcd = defaultCookie.get();
      let params = {
        survey_id: vcd['surveyId'],
        question_id: this.questionId,
        response_ids: this.responseIds
      };
      this.exportCsv(params);
    },
    checkAll() {
      this.responseIds = this.totalResId;
    },
    uncheckAll() {
      this.responseIds = [];
    },
    diplayableNum() {
      const name = 'number_of_displayable_data';
      if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
        return this.controlSettings[name]['values']
          ? this.controlSettings[name]['values']
          : 0;
      }
      return 0;
    },
    displayAttrs() {
      const name = 'hide_opinion_search_attributes';
      if (this.controlSettings[name] && this.controlSettings[name].enabled)
        return this.controlSettings[name].values;
      else return false;
    },
    resetMessage() {
      this.setMessage('');
    },
    exportOpinionsPdf() {
      this.resetMessage();
      if (this.responseIds.length === 0) return false;

      const vcd = defaultCookie.get();
      let params = {
        survey_id: vcd['surveyId'],
        question_id: this.questionId,
        response_ids: this.responseIds,
        sort: this.sort
      };
      this.exportPdf(params);
    },
    toOpinionSearch(responseId) {
      this.setCurrentResponseId(responseId);
      const vcd = defaultCookie.get();
      this.$router.push({
        name: 'u_qualitative_opinion_search',
        params: {
          survey_id: vcd['surveyId'],
          backToName: 'u_qualitative_analyse_rankings'
        }
      });
    },
    changeTextIds(id) {
      this.textIds.push(id);
    }
  }
};
</script>
<style scoped>
.vc-content-main-box .vc-opinionsearch-btn {
  font-size: 0.7rem;
  width: 150px;
  padding: 7px;
}
.vc-content-main-box .vc-memo-wrap {
  border: 1px #999 solid;
  margin-top: 15px;
  padding: 15px;
}
.vc-content-main-box .vc-memo-wrap p {
  margin: 0;
}
.vc-content-main-box .vc-ranking-num {
  font-size: 2rem;
  margin: -5px 0 0 5px;
}
</style>
