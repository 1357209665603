<template lang="pug">
  nav#sidebar.sidebar.sidebar-offcanvas.dynamic-active-class-disabled
    ul.nav
      li.nav-item
        a.nav-link(data-toggle="collapse" href="#dashboard-dropdown" aria-expanded="false" aria-controls="dashboard-dropdown")
          i.menu-icon.mdi.mdi-speedometer
          span.menu-title
            | {{ $t('admin.leftNav.dashboard') }}
          i.menu-arrow
        #dashboard-dropdown.collapse
          ul.nav.flex-column.sub-menu
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_dashboard' }")
                | {{ $t('admin.leftNav.overview') }}
      li.nav-item
        a.nav-link(data-toggle="collapse" href="#message-layouts" aria-expanded="false" aria-controls="message-layouts")
          i.menu-icon.mdi.mdi-account-multiple-outline
          span.menu-title
            | {{ $t('admin.leftNav.users') }}
          i.menu-arrow
        #message-layouts.collapse
          ul.nav.flex-column.sub-menu
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_users' }")
                | {{ $t('admin.leftNav.list') }}
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_new_user' }")
                | {{ $t('admin.leftNav.new') }}
      li.nav-item
        a.nav-link(data-toggle="collapse" href="#admins-dropdown" aria-expanded="false" aria-controls="#admins-dropdown")
          i.menu-icon.mdi.mdi-account-multiple-outline
          span.menu-title
            | {{ $t('admin.leftNav.admins') }}
          i.menu-arrow
        #admins-dropdown.collapse
          ul.nav.flex-column.sub-menu
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_admins' }")
                | {{ $t('admin.leftNav.list') }}
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_new_admin' }")
                | {{ $t('admin.leftNav.new') }}
      li.nav-item
        a.nav-link(data-toggle="collapse" href="#surveys-dropdown" aria-expanded="false" aria-controls="#surveys-dropdown")
          i.menu-icon.mdi.mdi-file-document
          span.menu-title
            | {{ $t('admin.leftNav.surveys') }}
          i.menu-arrow
        #surveys-dropdown.collapse
          ul.nav.flex-column.sub-menu
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_surveys' }")
                | {{ $t('admin.leftNav.list') }}
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_new_survey' }")
                | {{ $t('admin.leftNav.new') }}
      li.nav-item
        a.nav-link(data-toggle="collapse" href="#account-groups-dropdown" aria-expanded="false" aria-controls="#account-groups-dropdown")
          i.menu-icon.mdi.mdi-file-document
          span.menu-title
            | {{ $t('admin.leftNav.accountGroups') }}
          i.menu-arrow
        #account-groups-dropdown.collapse
          ul.nav.flex-column.sub-menu
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_account_groups' }")
                | {{ $t('admin.leftNav.list') }}
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_new_account_group' }")
                | {{ $t('admin.leftNav.new') }}
      li.nav-item
        a.nav-link(data-toggle="collapse" href="#organization-versions-dropdown" aria-expanded="false" aria-controls="#organization-versions-dropdown")
          i.menu-icon.mdi.mdi-file-document
          span.menu-title
            | {{ $t('admin.leftNav.organizationVersions') }}
          i.menu-arrow
        #organization-versions-dropdown.collapse
          ul.nav.flex-column.sub-menu
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_organization_versions' }")
                | {{ $t('admin.leftNav.list') }}
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_new_organization_version' }")
                | {{ $t('admin.leftNav.new') }}
      li.nav-item
        a.nav-link(data-toggle="collapse" href="#classification-requests-dropdown" aria-expanded="false" aria-controls="#classification-requests-dropdown")
          i.menu-icon.mdi.mdi-file-document
          span.menu-title
            | {{ $t('admin.leftNav.classificationRequests') }}
          i.menu-arrow
        #classification-requests-dropdown.collapse
          ul.nav.flex-column.sub-menu
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_classification_requests' }")
                | {{ $t('admin.leftNav.list') }}
      li.nav-item
        a.nav-link(data-toggle="collapse" href="#inquiries-dropdown" aria-expanded="false" aria-controls="#inquiries-dropdown")
          i.menu-icon.mdi.mdi-file-document
          span.menu-title
            | {{ $t('admin.leftNav.inquiries') }}
          i.menu-arrow
        #inquiries-dropdown.collapse
          ul.nav.flex-column.sub-menu
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_inquiries' }")
                | {{ $t('admin.leftNav.list') }}
      li.nav-item
        a.nav-link(data-toggle="collapse" href="#newsletters-dropdown" aria-expanded="false" aria-controls="#newsletters-dropdown")
          i.menu-icon.mdi.mdi-file-document
          span.menu-title
            | {{ $t('admin.leftNav.newsletters') }}
          i.menu-arrow
        #newsletters-dropdown.collapse
          ul.nav.flex-column.sub-menu
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_newsletter_templates' }")
                | {{ $t('admin.leftNav.newsletterTemplatesList') }}
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_newsletters' }")
                | {{ $t('admin.leftNav.newslettersList') }}
      li.nav-item
        a.nav-link(data-toggle="collapse" href="#co-occurrence-network-dropdown" aria-expanded="false" aria-controls="#co-occurrence-network-dropdown")
          i.menu-icon.mdi.mdi-file-document
          span.menu-title
            | {{ $t('admin.leftNav.coOccurrenceNetwork') }}
          i.menu-arrow
        #co-occurrence-network-dropdown.collapse
          ul.nav.flex-column.sub-menu
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_co_occurrence_networks' }")
                | {{ $t('admin.leftNav.list') }}
      li.nav-item
        a.nav-link(data-toggle="collapse" href="#chatgpt-classification-dropdown" aria-expanded="false" aria-controls="#chatgpt-classification-dropdown")
          i.menu-icon.mdi.mdi-file-document
          span.menu-title
            | {{ $t('admin.leftNav.chatGPTClassification') }}
          i.menu-arrow
        #chatgpt-classification-dropdown.collapse
          ul.nav.flex-column.sub-menu
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_chatgpt_classifications' }")
                | 多クラス分類
            li.nav-item
              router-link.nav-link(:to="{ name: 'x_chatgpt_sentiment_analysis' }")
                | 感情分析

</template>

<script>
export default {
  components: {},

  props: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {}
};
</script>
