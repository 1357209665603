<template lang="pug">
  div
    .vc-top-col.pt-0
      .container-fluid
        .container
          .ml-2
            SurveyOrganizationSelect(@input="changeSurveyOrganization")
    .vc-main-container.container
      .vc-topic-content.mb-4
        .vc-left-col
          AttributeValuesSelect
      .vc-content-col
        .mb-3
          TargetOrganizationSelect(@input="changeTargetOrganization" :organization-id="organizationId")
        div
          OpinionTendenciesUserCompare(
            @modalOpen="modalOpen"
            @modalOpinionCountsOpen="modalOpinionCountsOpen"
            @toOpinionSearch="toOpinionSearch"
            @updateCount="updateCount"
            :survey-id="surveyId"
          )
    .vc-main-container.container(
      v-if="sourceCount != null && (sourceCount <= getNumberOfDisplayableData() || destinationCount <= getNumberOfDisplayableData())"
    )
      .vc-content-col
        div(v-if="sourceCount <= getNumberOfDisplayableData()")
          | 回答が{{getNumberOfDisplayableData()}}件以下は表示できません。分析対象の組織を選びなおしてください
        div(v-if="destinationCount <= getNumberOfDisplayableData()")
          | 回答が{{getNumberOfDisplayableData()}}件以下は表示できません。比較対象の組織を選びなおしてください
    ModalOpinionAttrValUserTeam(ref="modalOpinionAttrVal")
    ModalOpinionCounts(ref="modalOpinionCounts" :position-top="popupPosition.top" :position-left="popupPosition.left")

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SurveyOrganizationSelect from '../../../components/u/SurveyOrganizationSelect';
import AnalyseSecondNav from '../../../components/u/AnalyseSecondNav';
import TargetOrganizationSelect from '../../../components/u/analyse/TargetOrganizationSelect';
import AttributeValuesSelect from '../../../components/u/qualitative/QualitativeAttributeValuesForAnalyse';
import OpinionTendenciesUserCompare from '../../../components/u/analyse/OpinionTendenciesUserCompare';
import ModalOpinionAttrValUserTeam from '../../../components/u/analyse/ModalOpinionAttrValUserTeam';
import ModalOpinionCounts from '../../../components/u/analyse/ModalOpinionCounts';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    SurveyOrganizationSelect,
    AnalyseSecondNav,
    TargetOrganizationSelect,
    AttributeValuesSelect,
    OpinionTendenciesUserCompare,
    ModalOpinionAttrValUserTeam,
    ModalOpinionCounts
  },

  data() {
    return {
      selectOpinionClass: 'opinion_count',
      organizationId: null,
      targetOrganizationId: null,
      beforeOrganizationId: null,
      beforeTargetOrganizationId: null,
      params: null,
      sourceCount: null,
      destinationCount: 0
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('resources/u_opinion_search', ['keepedAttributeValues']),
    ...mapState('resources/u_analyse_compare', ['popupPosition']),
    ...mapState('pages/u_surveys/select', ['surveyId']),
    ...mapState('pages/u_organizations/select', ['started']),
    ...mapState('resources/u_quantitative', ['attributeValueIds', 'questionId'])
  },

  watch: {
    surveyId() {
      if (!this.started) this.loadQuestions({ survey_id: this.surveyId });
    },
    attributeValueIds() {
      this.changeAttributeValueIds(this.attributeValueIds);
    },
    questionId() {
      this.updateOpinionTendencies();
    }
  },

  created() {
    this.setStarted(false);
    const vcd = defaultCookie.get();
    if (vcd['surveyId']) this.loadQuestions({ survey_id: vcd['surveyId'] });
    this.loadControlSettings();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapActions('resources/u_analyse_compare', ['loadOpinionTendencies']),
    ...mapActions('resources/u_quantitative', ['loadQuestions']),
    ...mapMutations('pages/u_organizations/select', ['setStarted']),
    ...mapMutations('resources/u_opinion_search', ['setKeepedAttributeValues']),
    ...mapMutations('resources/u_quantitative', ['setAttributeValueIds']),
    getNumberOfDisplayableData() {
      const name = 'number_of_displayable_data';
      if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
        return this.controlSettings[name]['values']
          ? this.controlSettings[name]['values']
          : 0;
      }
      return 0;
    },
    changeSurveyOrganization(datas) {
      this.organizationId = datas.organizationId;
      setTimeout(() => {
        this.organizationId = datas.organizationId;
      }, 200);
      if (this.questionId) this.updateOpinionTendencies();
    },
    changeTargetOrganization(targetOrganizationId, index) {
      this.targetOrganizationId = targetOrganizationId.slice(0, index);
      if (this.keepedAttributeValues.length > 0)
        this.setAttributeValueIds(this.keepedAttributeValues);
      else {
        this.setAttributeValueIds([]);
        if (this.$refs.attributeValuesSelect)
          this.$refs.attributeValuesSelect.clearSelect();
      }
      if (this.questionId) this.updateOpinionTendencies();
    },
    changeAttributeValueIds() {
      this.setKeepedAttributeValues(this.attributeValueIds);
      if (this.questionId) this.updateOpinionTendencies();
    },
    updateOpinionTendencies() {
      const vcd = defaultCookie.get();
      if (!vcd['surveyId']) return;
      let params = {
        survey_id: vcd['surveyId'],
        question_id: this.questionId,
        organization_id: this.organizationId,
        target_organization_id: this.targetOrganizationId,
        attribute_value_ids: this.attributeValueIds
      };
      this.params = params;
      this.loadOpinionTendencies(params);
      this.setStarted(true);
    },
    modalOpen(
      organizationId,
      opinionClassificationId,
      emotionClassificationId
    ) {
      const params = {
        survey_id: this.params.survey_id,
        question_id: this.questionId,
        organization_id: organizationId,
        opinion_classification_id: opinionClassificationId,
        attribute_value_ids: this.params.attribute_value_ids,
        emotion_classification_id: emotionClassificationId
      };
      this.$refs.modalOpinionAttrVal.modalOpen(params);
    },
    toOpinionSearch(opinionClassificationId, emotionClassificationId) {
      this.$router.push({
        name: 'u_qualitative_opinion_search',
        params: {
          opinion_classification: opinionClassificationId,
          emotion_classification: emotionClassificationId,
          attribute_values: this.attributeValueIds,
          survey_id: this.params.survey_id,
          backToName: 'u_qualitative_analyse_compare'
        }
      });
    },
    updateCount(sourceCount, destinationCount) {
      if (
        this.beforeOrganizationId != this.organizationId ||
        this.beforeTargetOrganizationId != this.targetOrganizationId
      ) {
        this.sourceCount = sourceCount;
        this.destinationCount = destinationCount;
        this.beforeOrganizationId = this.organizationId;
        this.beforeTargetOrganizationId = this.targetOrganizationId;
      }
    },

    modalOpinionCountsOpen(
      source,
      destinations,
      selectOpinionCountName,
      selectSurveyResponsenCountName
    ) {
      this.$refs.modalOpinionCounts.modalOpen(
        source,
        destinations,
        selectOpinionCountName,
        selectSurveyResponsenCountName
      );
    }
  }
};
</script>
