export default {
  namespaced: true,

  modules: {},

  state: {
    id: null,
    email: '',
    name: '',
    organizationId: null,
    invitation_accepted_at: null,
    locked_at: null,
    current_sign_in_at: null
  },

  getters: {},

  mutations: {
    setUser(state, payload) {
      if (payload.user.id != null) {
        state.id = payload.user.id;
      }

      if (payload.user.email != null) {
        state.email = payload.user.email;
      }

      if ('name' in payload.user) {
        state.name = payload.user.name;
      }

      if ('organization_id' in payload.user) {
        state.organizationId = payload.user.organization_id;
      }

      if ('invitation_accepted_at' in payload.user) {
        state.invitation_accepted_at = payload.user.invitation_accepted_at;
      }

      if ('locked_at' in payload.user) {
        state.locked_at = payload.user.locked_at;
      }

      if ('current_sign_in_at' in payload.user) {
        state.current_sign_in_at = payload.user.current_sign_in_at;
      }
    }
  }
};
