<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content
        SurveyOrganizationSelect(@input="search")
        OpinionSearchNav
        .vc-main-container.container
          AttributeValueSelect(id="leftNav" 
                              @input="search" :backToName="backToName" 
                              :backToSurveyId="backToSurveyId" 
                              :backToOrganizationId="backToOrganizationId" 
                              :dMainView="dMainView"
                              :isTopic="isTopic")
          OpinionSearchResult(@input="search" 
                              @addMore="addMore" 
                              :backToName="backToName" 
                              :backToSurveyId="backToSurveyId" 
                              :backToOrganizationId="backToOrganizationId" 
                              :dMainView="dMainView"
                              @modalOpen="modalOpen")
    ModalOtherSurveyResponses(ref="modalOtherSurveyResponses")
    ModalFixClassificationRequests(ref="modalFixClassificationRequests")
    ModalRequestChatInterview(ref="modalRequestChatInterview")
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SurveyOrganizationSelect from '../../../components/u/SurveyOrganizationSelect';
import AttributeValueSelect from '../../../components/u/AttributeValueSelect';
import OpinionSearchResult from '../../../components/u/opinionSearch/OpinionSearchResult';
import ModalOtherSurveyResponses from '../../../components/u/opinionSearch/ModalOtherSurveyResponses';
import ModalFixClassificationRequests from '../../../components/u/opinionSearch/ModalFixClassificationRequests';
import ModalRequestChatInterview from '../../../components/u/opinionSearch/ModalRequestChatInterview';
import OpinionSearchNav from '../../../components/u/response_ranking/OpinionSearchNav';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    SurveyOrganizationSelect,
    AttributeValueSelect,
    OpinionSearchResult,
    ModalOtherSurveyResponses,
    ModalFixClassificationRequests,
    ModalRequestChatInterview,
    OpinionSearchNav
  },

  data() {
    return {
      defaultOrganizationId: '',
      backToSurveyId: null,
      backToOrganizationId: null,
      backToName: null,
      dMainView: null
    };
  },

  computed: {
    ...mapState('resources/u_opinion_search', [
      'organizationId',
      'attributeValues',
      'emotionValues',
      'opinionValues',
      'page',
      'per',
      'keywords',
      'quickKeywords',
      'orAnd',
      'sort',
      'nodes',
      'nodeSearchTypes'
    ]),
    ...mapState('resources/u_topic', ['keepedSelectedOrganizations']),
    ...mapState('pages/u_surveys/select', ['surveyId', 'isTopic']),
    ...mapState('pages/u_organizations/select', ['started']),
    ...mapState('resources/u_response_ranking', ['currentResponseId'])
  },

  watch: {
    backToName: function() {
      if (!this.backToName) {
        this.setKeepedAttributeValues([]);
        this.setKeepedEmotionValues(0);
        this.setStarted(true);
      }
    },
    isTopic() {
      if (this.isTopic) defaultCookie.set({ organizationId: null });
    }
  },

  created() {
    this.initializeState();
    this.setStarted(false);
    this.loadControlSettings();
    this.load();
  },

  mounted() {
    let scrollPos = 0;
    let scrollUpCount = 0;
    let scrollDownCount = 0;
    let totalHeight;
    let leftNavTopPosition;
    let leftNavBottomPosition;
    let fixedTopPosition;
    let leftNavEl = document.getElementById('leftNav');
    window.addEventListener('scroll', function() {
      let rect = leftNavEl.getBoundingClientRect();
      totalHeight = window.innerHeight;
      leftNavTopPosition = rect.top;
      leftNavBottomPosition = rect.bottom;

      if (document.body.getBoundingClientRect().top > scrollPos) {
        scrollDownCount = 0;
        if (leftNavTopPosition > 100) {
          leftNavEl.style.position = 'sticky';
          leftNavEl.style.top = '100px';
          return;
        }
        if (scrollUpCount === 0) {
          leftNavEl.style.position = 'relative';
          leftNavEl.style.top = fixedTopPosition - 270 + 'px';
          scrollUpCount += 1;
        }
        fixedTopPosition = leftNavEl.offsetTop;
      } else {
        scrollUpCount = 0;
        if (
          scrollDownCount !== 0 &&
          leftNavBottomPosition > 0 &&
          totalHeight - 30 >= leftNavBottomPosition
        ) {
          leftNavEl.style.position = 'sticky';
          leftNavEl.style.top =
            totalHeight - leftNavEl.offsetHeight - 30 + 'px';
        }
        if (scrollDownCount === 0) {
          leftNavEl.style.position = 'relative';
          leftNavEl.style.top = fixedTopPosition - 270 + 'px';
          scrollDownCount += 1;
        }
        fixedTopPosition = leftNavEl.offsetTop;
      }
      scrollPos = document.body.getBoundingClientRect().top;
    });
  },

  methods: {
    ...mapActions('resources/u_opinion_search', [
      'loadOpinions',
      'loadConditions',
      'addMoreOpinions',
      'loadDefaultOrganizationId'
    ]),
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapMutations('resources/u_opinion_search', [
      'setOrganizationId',
      'setCheckedEmotionValues',
      'setCheckedOpinionValues',
      'setAttributeValues',
      'setEmotionValues',
      'setOpinionValues',
      'incrementPage',
      'resetPage',
      'initializeState',
      'setKeepedAttributeValues',
      'setKeepedEmotionValues',
      'setNodes',
      'setNodeSearchTypes'
    ]),
    ...mapMutations('pages/u_surveys/select', ['setSurveyId']),
    ...mapMutations('pages/u_organizations/select', ['setStarted']),

    load() {
      this.loadConditions();
      const params = this.$route.params;
      if (params.survey_id) this.setSurveyId(params.survey_id);
      if (params.organization_id) {
        const vcd = defaultCookie.get();
        this.backToOrganizationId = vcd.organizationId ? vcd.organizationId : 0;
        defaultCookie.set({ organizationId: params.organization_id });
      }
      if (
        params.attribute_values ||
        params.emotion_classification ||
        params.opinion_classification ||
        params.nodes ||
        this.currentResponseId
      ) {
        this.backToName = params.backToName;
        this.backToSurveyId = params.backToSurveyId;
        if (params.backToName === 'u_dashboard') {
          this.dMainView = params.mainView;
        }
        if (params.attribute_values) {
          this.setAttributeValues(params.attribute_values);
          if (
            ['u_compare_analyse', 'u_team_analyse', 'u_topic'].includes(
              params.backToName
            )
          )
            this.setKeepedAttributeValues(params.attribute_values);
        }
        if (params.emotion_classification) {
          this.setEmotionValues(params.emotion_classification);
          if (params.backToName == 'u_compare_analyse')
            this.setKeepedEmotionValues(params.emotion_classification);
        }
        if (params.opinion_classification)
          this.setOpinionValues(params.opinion_classification);
        if (params.nodes) {
          this.setNodes(params.nodes);
          this.setNodeSearchTypes(params.node_search_types);
        }
      }
      setTimeout(() => {
        defaultCookie.set({ organizationType: 0 });
        this.setStarted(true);
      }, 2000);
    },

    setupParams() {
      const conditions = {
        attribute_values: this.attributeValues,
        emotion_classification: this.emotionValues,
        opinion_classification: this.opinionValues,
        page: this.page,
        per: this.per,
        keywords: [this.keywords, this.orAnd],
        quick_keywords: this.quickKeywords,
        sort: this.sort,
        nodes: this.nodes,
        node_search_types: this.nodeSearchTypes,
        response_id: this.currentResponseId
      };

      const params = {
        survey_id: this.surveyId,
        organization_id: this.organizationId,
        conditions: JSON.stringify(conditions)
      };

      return params;
    },

    search(data) {
      if (data && this.started) this.backToName = null;
      const vcd = defaultCookie.get();
      this.setCheckedEmotionValues(this.emotionValues);
      this.setCheckedOpinionValues(this.opinionValues);
      this.resetPage();

      if (vcd) {
        if (vcd.organizationType) {
          if (!vcd.organizationId && !vcd.targetOrganizationId) {
            this.loadDefaultOrganizationId({ survey_id: this.survey_id }).then(
              response => {
                if (response.status === 200) {
                  this.defaultOrganizationId =
                    response.body.default_organization_id;
                  this.setOrganizationId(this.defaultOrganizationId);
                  const params = this.setupParams();
                  this.loadOpinions(params).then(() => {
                    this.setStarted(true);
                  });
                }
              }
            );
          } else {
            this.setOrganizationId(vcd.targetOrganizationId);
            const params = this.setupParams();
            this.loadOpinions(params).then(() => {
              this.setStarted(true);
            });
          }
        } else {
          this.setOrganizationId(vcd.organizationId);
          const params = this.setupParams();
          this.loadOpinions(params).then(() => {
            this.setStarted(true);
          });
        }
      } else {
        const params = this.setupParams();
        this.loadOpinions(params).then(() => {
          this.setStarted(true);
        });
      }
    },

    addMore() {
      this.incrementPage();
      const params = this.setupParams();

      this.addMoreOpinions(params);
    },

    modalOpen: function(type, response) {
      if (type === 'other_survey_responses')
        this.$refs.modalOtherSurveyResponses.modalOpen(response);
      else if (type === 'fix_classification_requests')
        this.$refs.modalFixClassificationRequests.modalOpen(response);
      else if (type === 'requestChatInterview')
        this.$refs.modalRequestChatInterview.modalOpen(response, 'survey');
    }
  }
};
</script>
