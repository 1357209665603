<template lang="pug">
  div
    modal(
      ref="modalInterviewTips"
      name="modalInterviewTips"
      id="modalInterviewTips"
      centered
      :minWidth="modalWidth"
      :minHeight="modalHeight"
      :width="modalWidth"
      :height="modalHeight"
      :clickToClose="true"
      :scrollable="true"
    )
      header.modal-header.align-items-end
        h6.modal-title.vc-midashi
          | インタビューTips
        dl.respondent-num.align-items-center
          dt
            button(type="button" @click="$modal.hide('modalInterviewTips')" class="close vc-close")
              | ×
      .modal-contents
        .m-3
          p.font-weight-bold 1. チャットの始め方
          .ml-2
            p
              span.pointer(@click="toggleDisplayContents(0)")
                span ・自己紹介から入り、柔らかいトピックで会話しやすい雰囲気を作る
                img.display-contents-btn.ml-2(
                  src="../../../assets/images/excheck/chevron-down.png" width="18"
                  :class="{'reverse': displayContents[0]}"
                )
            p.ml-3(v-show="displayContents[0]")
              | ※必須ではありません＋相手の深刻度を見極めてトピックは判断
              br
              | こんにちは、今回チャットを担当させていただく○○です。
              br
              | 最近、冷凍パンのお取り寄せにはまってます！
              br
              | ○○さんが最近興味をお持ちなこと、はまっていることなどあれば是非教えてください。
            p
              span.pointer(@click="toggleDisplayContents(1)")
                span ・アンケート回答の感謝を伝える
                img.display-contents-btn.ml-2(
                  src="../../../assets/images/excheck/chevron-down.png" width="18"
                  :class="{'reverse': displayContents[1]}"
                )
            p.ml-3(v-show="displayContents[1]")
              | 過日は○○のアンケートにご回答いただき、誠にありがとうございました。
              br
              | ご回答を拝見し、是非もっと詳しくお話をお伺いできればと思っております。
            p
              span.pointer(@click="toggleDisplayContents(2)")
                span ・チャットのやり方を伝える
                img.display-contents-btn.ml-2(
                  src="../../../assets/images/excheck/chevron-down.png" width="18"
                  :class="{'reverse': displayContents[2]}"
                )
            p.ml-3(v-show="displayContents[2]")
              | こちらの画面でやりとりしますが、私が質問やコメントをすると
              br
              | ○○さんにメールで通知がいきますので、お答えできるタイミングで
              br
              | メール内の回答用URLをクリックください。
              br
              br
              | こちらの画面が立ち上がりますので、ご回答やコメントをお願いいたします。
              br
              | ※パスワードを求められる場合で、忘れてしまった場合は
              br
              |  「パスワードを忘れた方はこちら」をクリックしてパスワードを再設定ください。
            p
              span.pointer(@click="toggleDisplayContents(3)")
                span ・開始を宣言する
                img.display-contents-btn.ml-2(
                  src="../../../assets/images/excheck/chevron-down.png" width="18"
                  :class="{'reverse': displayContents[3]}"
                )
            p.ml-3(v-show="displayContents[3]")
              | ○○さん、それではよろしくお願いいたします。
              br
              | いただいたご意見やコメントについては、取り扱いについて十分に注意をしますので、
              br
              | ○○さんがお感じになっていること、考えていることなどを忌憚なくお話しいただけると幸いです。
            
          p.font-weight-bold 2. チャット実施のTIPS
          .ml-2
            p
              span.pointer(@click="toggleDisplayContents(4)")
                span ・原則、回答に対するコメントは感謝から。
                img.display-contents-btn.ml-2(
                  src="../../../assets/images/excheck/chevron-down.png" width="18"
                  :class="{'reverse': displayContents[4]}"
                )
            p.ml-3(v-show="displayContents[4]")
              | ○○さん、リアルなご意見ありがとうございます！
              br
              | ○○さん、大変な中、状況をご共有いただきありがとうございます！
            p
              span.pointer(@click="toggleDisplayContents(5)")
                span ・あいまいな言葉を具体化する
                img.display-contents-btn.ml-2(
                  src="../../../assets/images/excheck/chevron-down.png" width="18"
                  :class="{'reverse': displayContents[5]}"
                )
            p.ml-3(v-show="displayContents[5]")
              | 「疑問を感じる」ということですが、昇給制度のどんなところに疑問を感じるのでしょうか
            p
              span.pointer(@click="toggleDisplayContents(6)")
                span ・原因や理由、背景を具体化する
                img.display-contents-btn.ml-2(
                  src="../../../assets/images/excheck/chevron-down.png" width="18"
                  :class="{'reverse': displayContents[6]}"
                )
            p.ml-3(v-show="displayContents[6]")
              | 「疑問を感じる」ようになったきっかけや原因などがあれば詳しく教えていただけないでしょうか。
            p
              span.pointer(@click="toggleDisplayContents(7)")
                span ・TPOでシーンを具体化する
                img.display-contents-btn.ml-2(
                  src="../../../assets/images/excheck/chevron-down.png" width="18"
                  :class="{'reverse': displayContents[7]}"
                )
            p.ml-3(v-show="displayContents[7]")
              | 「ストレスを感じる」のはどんなシーンで、でしょうか、タイミングや場所、誰と、どんなシーンでなどがあれば教えてください。
              br
              | 大体1週間に何回くらい
          p.font-weight-bold 3. クロージング
          .ml-2
            p
              span.pointer(@click="toggleDisplayContents(8)")
                span ・内容の取扱いに注意することを伝える
                img.display-contents-btn.ml-2(
                  src="../../../assets/images/excheck/chevron-down.png" width="18"
                  :class="{'reverse': displayContents[8]}"
                )
            p.ml-3(v-show="displayContents[8]")
              | いただいたご意見やコメントについては、取り扱いについて十分に注意をしますので、ご安心ください。
              br
              | 今後施策につなげていく場合においても、個人が特定されない形で、当社内の一般化した課題として取り扱いますのでご安心ください。
            p
              span.pointer(@click="toggleDisplayContents(9)")
                span ・いただいたご意見や内容は、すぐに対応するとは限らない旨を伝える
                img.display-contents-btn.ml-2(
                  src="../../../assets/images/excheck/chevron-down.png" width="18"
                  :class="{'reverse': displayContents[9]}"
                )
            p.ml-3(v-show="displayContents[9]")
              | いただいたご意見、コメント、アイデアはその他多くの方よりいただいている意見なども含め、総合的に勘案の上、
              br
              | 今後の施策検討のインプットとさせていただければと存じます。
            p
              span.pointer(@click="toggleDisplayContents(10)")
                span ・感謝でクローズ
                img.display-contents-btn.ml-2(
                  src="../../../assets/images/excheck/chevron-down.png" width="18"
                  :class="{'reverse': displayContents[10]}"
                )
            p.ml-3(v-show="displayContents[10]")
              | それではお忙しい中、長い間お時間頂戴し、また貴重なご意見やアイデア、コメントをいただき、誠にありがとうございました。
              br
              | 今後またご意見をお伺いする際は、どうぞご協力のほどよろしくお願いいたします。
</template>

<script>
export default {
  props: {},
  data() {
    return {
      modalWidth: 0,
      modalHeight: 0,
      displayContents: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false
      }
    };
  },
  created() {
    this.modalWidth = window.outerWidth * 0.7;
    this.modalHeight = window.outerHeight * 0.65;
  },
  methods: {
    modalOpen() {
      const keys = Object.keys(this.displayContents);
      keys.forEach(k => {
        this.displayContents[k] = false;
      });
      this.$modal.show('modalInterviewTips');
    },
    toggleDisplayContents(index) {
      this.displayContents[index] = !this.displayContents[index];
    }
  }
};
</script>
<style scoped>
.modal-header {
  display: block;
}
.modal-contents {
  height: 590px;
  overflow: scroll;
}
.modal-contents p {
  margin-bottom: 0.5rem;
}
.modal-contents .pointer {
  cursor: pointer;
}
.modal-contents img.display-contents-btn {
  margin-bottom: 2px;
}
.modal-contents img.display-contents-btn.reverse {
  -webkit-transform: rotatex(180deg);
  transform: rotatex(180deg);
}
</style>
