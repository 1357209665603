import { render, staticRenderFns } from "./UserOpinionClassMapDashboard.vue?vue&type=template&id=00f83852&scoped=true&lang=pug&"
import script from "./UserOpinionClassMapDashboard.vue?vue&type=script&lang=js&"
export * from "./UserOpinionClassMapDashboard.vue?vue&type=script&lang=js&"
import style0 from "./UserOpinionClassMapDashboard.vue?vue&type=style&index=0&id=00f83852&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f83852",
  null
  
)

export default component.exports