import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_control_settings');

export default {
  namespaced: true,

  state: {
    controlSettings: {}
  },

  getters: {},

  actions: {
    /**
     * 制御一覧取得
     */
    loadControlSettings({ commit }) {
      return gateway.get({ action: 'index' }).then(response => {
        if (response.status === 200) {
          const control_settings = response.body.control_settings;
          commit('setControlSettings', control_settings);
        }
        return response;
      });
    }
  },

  mutations: {
    setControlSettings(state, control_settings) {
      state.controlSettings = control_settings;
    }
  }
};
