import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_questions');

export default {
  namespaced: true,

  state: {
    questionsList: [],
    survey: {},
    question: {},
    totalCount: 0
  },

  getters: {},

  actions: {
    loadQuestions({ commit }, params) {
      return gateway
        .get(
          { action: 'index', survey_id: params.survey_id },
          params.pageParams
        )
        .then(response => {
          if (response.status === 200) {
            const questionsList = response.body.questions;
            const survey = response.body.survey;

            commit('setQuestionsList', questionsList);
            commit('setSurvey', survey);
          }
          return response;
        });
    },
    loadQuestion({ commit }, params) {
      return gateway
        .get({ action: 'show', survey_id: params.survey_id, id: params.id })
        .then(response => {
          if (response.status === 200) {
            const question = response.body.question;
            const totalCount = response.body.total_count;

            commit('setQuestion', question);
            commit('setTotalCount', totalCount);
          }
        });
    },
    loadEditQuestion({ commit }, id) {
      return gateway.get({ action: 'edit', id }).then(response => {
        if (response.status === 200) {
          const question = response.body.question;
          const survey = response.body.survey;

          commit('setQuestion', question);
          commit('setSurvey', survey);
        }
        return response;
      });
    },
    createQuestion(_, params) {
      return gateway
        .post(
          {
            action: 'create'
          },
          params
        )
        .then(response => {
          return response;
        });
    },
    updateQuestion(_, params) {
      return gateway
        .put(
          {
            action: 'update',
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },
    deleteQuestion(_, id) {
      return gateway.delete({ action: 'destroy', id }).then(response => {
        return response;
      });
    },
    exportSentencesCsv(_, params) {
      return gateway
        .put(
          { action: 'export_csv', survey_id: params.survey_id, id: params.id },
          params
        )
        .then(response => {
          return response;
        });
    },
    exportCurrentResponsesData(_, params) {
      return gateway
        .put(
          {
            action: 'export_current_responses',
            survey_id: params.survey_id,
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },
    exportCurrentOpinionsData(_, params) {
      return gateway
        .put(
          {
            action: 'export_current_opinions',
            survey_id: params.survey_id,
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },
    requestClassificationsApi(_, params) {
      return gateway
        .put({
          action: 'request_classifications_api',
          survey_id: params.survey_id,
          id: params.id
        })
        .then(response => {
          return response;
        });
    },
    downloadClassificationsApi(_, params) {
      return gateway
        .put({
          action: 'download_classifications_api',
          survey_id: params.survey_id,
          id: params.id
        })
        .then(response => {
          return response;
        });
    },
    exportOpinionsDataWithClassifications(_, params) {
      return gateway
        .put({
          action: 'export_opinions_with_classifications',
          survey_id: params.survey_id,
          id: params.id
        })
        .then(response => {
          return response;
        });
    },
    requestResponseRankingApi(_, params) {
      return gateway
        .put({
          action: 'request_response_ranking_api',
          survey_id: params.survey_id,
          id: params.id
        })
        .then(response => {
          return response;
        });
    },
    exportResponseRanking(_, params) {
      return gateway
        .put({
          action: 'export_response_ranking',
          survey_id: params.survey_id,
          id: params.id
        })
        .then(response => {
          return response;
        });
    },
    requestFeatureWordsApi(_, params) {
      return gateway
        .put({
          action: 'request_feature_words_api',
          survey_id: params.survey_id,
          id: params.id
        })
        .then(response => {
          return response;
        });
    }
  },

  mutations: {
    setQuestionsList(state, questionsList) {
      state.questionsList = questionsList;
    },
    setSurvey(state, survey) {
      state.survey = survey;
    },
    setQuestion(state, question) {
      state.question = question;
    },
    setTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    }
  }
};
