import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_organization_versions');

export default {
  namespaced: true,

  state: {
    organizationVersions: [],
    organizations: [],
    roles: [],
    organizationVersion: {}
  },

  getters: {},

  actions: {
    loadOrganizationVersions({ commit }) {
      return gateway.get({ action: 'index' }).then(response => {
        if (response.status === 200) {
          const organizationVersions = response.body.organization_versions;
          commit('setOrganizationVersions', organizationVersions);
        }
        return response;
      });
    },

    loadOrganizationVersion({ commit }, id) {
      return gateway
        .get({
          action: 'show',
          id
        })
        .then(response => {
          if (response.status === 200) {
            const organizationVersion = response.body.organization_version;
            const organizations = response.body.organizations;

            commit('setOrganizationVersion', organizationVersion);
            commit('setOrganizations', organizations);
          }
          return response;
        });
    },

    createOrganizationVersion(_, params) {
      return gateway
        .post(
          {
            action: 'create'
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    editOrganizationVersion({ commit }, id) {
      return gateway.get({ action: 'edit', id: id }).then(response => {
        if (response.status === 200) {
          const organization_version = response.body.organization_version;
          commit('setOrganizationVersion', organization_version);
        }
        return response;
      });
    },

    updateOrganizationVersion(_, params) {
      return gateway
        .put(
          {
            action: 'update',
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    deleteOrganizationVersion(_, id) {
      return gateway.delete({ action: 'remove', id }).then(response => {
        return response;
      });
    }
  },

  mutations: {
    setOrganizationVersions(state, organizationVersions) {
      state.organizationVersions = organizationVersions;
    },
    setOrganizationVersion(state, organizationVersion) {
      state.organizationVersion = organizationVersion;
    },
    setOrganizations(state, organizations) {
      state.organizations = organizations;
    }
  }
};
