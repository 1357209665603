<template lang="pug">
  .row.vc-dashboard-box-content
    .col-12.vc-dashboard-box-title
      h4.vc-midashi.mb-2
        | {{ parentName + (parentName ? ' - ' : '') + topicData[0] }}
    .dashboard-comp-graph-col.vc-dashboard-box-graph-wrap.topic-comp-graph-col
      div
        pie-chart(:chart-data="chartData || {}" :options="chartOptions")
    .dashboard-comp-table-col.vc-dashboard-box-table-wrap.topic-total-comp-table-col
      .row
        .col-12.vc-dashboard-box-title
          h5.vc-midashi.mb-2 感情意見割合
      .row.no-gutters.vc-respondent-table
        .col-6.text-left.overflow-hidden.text-nowrap 感情分類
        .col-6
          .row.no-gutters
            .col-4
            .col-4 割合
            .col-4 件数
      .vc-dashboard-box-table-data-row.no-scroll
        .row.no-gutters.vc-respondent-table(v-for="data in topicData[1]")
          .col-6.text-left.overflow-hidden.text-nowrap.table-column-name
            div
              | {{ data[0] }}
          .col-6
            .row.no-gutters.vc-respondent-table-num
              .col-4
                a(
                  role="button"
                  @click="toOpinionSearch(emotionIds[data[0]])"
                )
                  svg.to-opinion-search(
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                  )
                    g
                      rect(fill="none" height="24" width="24")
                    g
                      g
                        rect(height="2" width="9" x="13" y="7")
                        rect(height="2" width="9" x="13" y="15")
                        rect(height="2" width="6" x="16" y="11")
                        polygon(points="13,12 8,7 8,11 2,11 2,13 8,13 8,17")
              .col-4
                div
                  | {{ data[1] }}%
              .col-4
                div
                  | {{ data[2] }}
        .row.no-gutters.vc-respondent-table
          .col-6.text-left.overflow-hidden.text-nowrap.table-column-name 合計
          .col-6
            .row.no-gutters.vc-respondent-table-num
              .col-8
              .col-4
                | {{ dataLength() }}
</template>
<script>
import { mapState } from 'vuex';
import PieChart from '../../../components/u/chartJs/PieChart';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    PieChart
  },
  props: {
    chartOptions: {
      type: Object,
      required: true,
      default: () => ({})
    },
    parentName: {
      type: String,
      default: ''
    },
    orgId: {
      type: Number,
      default: null
    },
    chartData: {
      type: Object,
      default: () => ({})
    },
    topicData: {
      type: Array,
      default: () => []
    },
    attributeValueIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      started: false
    };
  },
  computed: {
    ...mapState('resources/u_topic', ['emotionIds'])
  },
  created() {
    this.dataLength();
  },
  methods: {
    toOpinionSearch(emotionId) {
      const vcd = defaultCookie.get();
      let params = {
        emotion_classification: emotionId,
        organization_id: this.orgId,
        survey_id: vcd['surveyId'],
        backToName: 'u_topic'
      };
      if (this.attributeValueIds.length > 0)
        params.attribute_values = this.attributeValueIds;

      this.$router.push({
        name: 'u_opinion_search',
        params: params
      });
    },
    dataLength() {
      return this.topicData[1].reduce((sum, data) => {
        return sum + data[2];
      }, 0);
    }
  }
};
</script>
