<template lang="pug">
  div
    modal(
      ref="modalRequestChatInterview"
      name="modalRequestChatInterview"
      id="modalRequestChatInterview"
      :minWidth="600"
      :minHeight="320"
      :width="600"
      :height="320"
      :reset="true"
      :scrollable="true"
    )
      header.modal-header.align-items-end
        h6.modal-title.vc-midashi
          | チャットインタビューの依頼
        dl.respondent-num.align-items-center
          dt
            button(type="button" @click="$modal.hide('modalRequestChatInterview')" class="close vc-close")
              | ×
      .row.no-gutters.modal-content
        .col-12.mt-5.mb-5.ml-3
          p
            | この回答者にチャットインタビューへのご協力（参加）を依頼します。
          p
            | （「依頼する」を押すと回答者にインタビュー承諾依頼メールが送信されます）
        .col-12.mt-5
          .row
            .offset-2.col-8
              .row
                .col-6
                  button.btn.btn-primary.btn-block.vc-primary-btn.vc-chat-btn(type="button" @click.prevent="requestChat()")
                    | 依頼する
                .col-6
                  button.btn.btn-primary.btn-block.vc-primary-btn.gray-btn.vc-cancel-btn(
                    type="button"
                    @click.prevent="$modal.hide('modalRequestChatInterview')"
                  )
                    | やめる


</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  components: {},

  props: {},

  data() {
    return {
      response: {},
      type: ''
    };
  },

  computed: {
    ...mapState('resources/u_opinion_search', ['opinionArr', 'conditions'])
  },

  watch: {},

  mounted() {},

  methods: {
    ...mapActions('resources/u_inquiries', ['requestChatInterview']),
    ...mapMutations('resources/u_opinion_search', ['setOpinionArr']),
    modalOpen(response, type) {
      this.response = response;
      this.type = type;
      this.$modal.show('modalRequestChatInterview');
    },
    requestChat() {
      const params = {
        response_id: this.response.id,
        responder_code: this.response.responder_code,
        conditions: this.conditions,
        response_type: this.type
      };
      this.requestChatInterview(params).then(response => {
        if (this.type === 'survey') {
          let res = response.body.response;
          const currentRes = this.opinionArr.find(r => r.code === res.code);
          let newOpinionArr = this.opinionArr.concat();
          let idx = this.opinionArr.indexOf(currentRes);
          newOpinionArr[idx] = res;
          this.setOpinionArr(newOpinionArr);
        }
      });
      this.$modal.hide('modalRequestChatInterview');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-header {
  display: block;
  span {
    font-size: 0.8rem;
  }
  span.organization-wrap {
    float: right;
    margin-top: 1px;
  }
}
.modal-content {
  overflow-y: scroll;
  height: 75%;
  display: block;
  flex-direction: initial;
  border: none;
}
</style>
