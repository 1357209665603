import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_analyse_heatmap');

export default {
  namespaced: true,

  state: {
    opinionClassifications: [],
    emotionClassifications: [],
    heatmapDataArray: [],
    totalHeatmapDataArray: [],
    organizationIds: []
  },

  getters: {},

  actions: {
    /**
     * HEATMAPのラベルデータ取得
     */
    loadLabelData({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          commit(
            'setOpinionClassifications',
            response.body.opinion_classifications
          );
          commit(
            'setEmotionClassifications',
            response.body.emotion_classifications
          );
        }
        return response;
      });
    },

    /**
     * HEATMAPデータ取得
     */
    loadHeatmapData({ commit }, params) {
      return gateway.post({ action: 'heatmap_data' }, params).then(response => {
        if (response.status === 200) {
          commit('setHeatmapDataArray', response.body);
        }
        return response;
      });
    }
  },

  mutations: {
    setHeatmapDataArray(state, data) {
      state.heatmapDataArray = data;
    },
    setTotalHeatmapDataArray(state, data) {
      state.heatmapDataArray = data;
    },
    setOpinionClassifications(state, data) {
      state.opinionClassifications = data;
    },
    setEmotionClassifications(state, data) {
      state.emotionClassifications = data;
    },
    setOrganizationIds(state, data) {
      state.organizationIds = data;
    }
  }
};
