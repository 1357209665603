<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | {{ survey.name }}
  .row
    .col-md-12.grid-margin.stretch-card.clearfix
      .card
        .card-body.vc(v-if="errorMessages.length > 0 || message")
          .row
            .col-12
              error-message(:errors="errorMessages" v-show="errorMessages.length > 0")
              ul.alert.alert-success(v-show="message")
                button.close.vc-message-close(@click="resetMessages")
                  span(aria-hidden='true') ×
                li.ml-1
                  | {{ message }}
        //- .card-body.vc
          .row.mb-4
            .col-12
              h5.vc-midashi.mb-3 定性分析用データ登録
            .col-12.section
              .row.mb-4
                .col-3
                  p.cc-label-mid ①回答データアップロード
                .col-6
                  p
                    | テンプレートをダウンロードし、その形式に沿って回答者ごとの属性、部署、自由回答を入力してください。
                    br
                    | 入力が完了したら、CSVファイルとして保存し、「CSVアップロード」ボタンを押して、アップロードしてください
                .col-3
                  div
                    label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                      input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event, 'responses')")
                  div
                    a.link(href="" @click.prevent="exportTempCsv('surveyResponses')")
                      | テンプレートCSVをダウンロード
            .col-12.section
              p.cc-label-mid ②意見データアップロード
              .row.mb-4
                .col-3
                  p 1. センテンス分割
                .col-6
                  p
                    | 「①回答データアップロード」の後、こちらのプロセスを進めてください。完了するとメールが届きます。
                    br
                    | センテンス分割を実行すると、意見ベースで具体意見のAI分析を行います。
                    br
                    | また、回答データを新しくした場合は必ずもう一度センテンス分割を行ってください。
                .col-3
                  a.cc-primary-btn.cc-inline-btn.action-btns(href="" @click.prevent="exportCsv(true)" :class="{'btn-disabled': totalCount < 1}") 分割 実行
                  a.cc-primary-btn.cc-inline-btn.action-btns(href="" @click.prevent="exportCsv(false)" :class="{'btn-disabled': totalCount < 1}") 分割せず進行
              .row.mb-4
                .col-3
                  p 2. 意見分類・感情分類・AI分析実行
                .col-6
                  p
                    | 「1. センテンス分割」が完了したら、意見分類と感情分類のAI分析を行います。「AI分析 実行」を押してください。完了するとダウンロードリンクの付いたメールが届きます。
                .col-3
                  div
                    a.cc-primary-btn.cc-inline-btn.action-btns(href="" @click.prevent="requestClassifications") AI分析 実行
                  div
                    a.link(href="" @click.prevent="exportOpinionsWithClassifications") AI分析付意見データダウンロード
              .row.mb-4
                .col-3
                  p 3. 意見データアップロード
                .col-6
                  p
                    | 分析結果をダウンロードしたら、意見分類・感情分類が間違っていないかチェックしてください。必要に応じて分類を補正してください。補正が済んだら、意見データアップロードの「CSVアップロード」を押して、CSVファイルをアップロードしてください。
                .col-3
                  label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                    input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event, 'opinions')")
        //- .card-body.vc
          .row.mb-4
            .col-12
              h5.vc-midashi.mb-3 登録済み定性データ取得
            .col-12.section
              p.cc-label-mid 回答データ取得
              .row.mb-4
                .col-3
                  p コード形式
                .col-6
                  p
                    | コード形式は上記の「①回答データアップロード」に使用できます。回答データをアップロードし直した場合は、必ず「②意見データアップロード」を再度行ってください。
                .col-3
                  a.cc-primary-btn.cc-inline-btn.action-btns(
                    href=""
                    @click.prevent="exportCurrentResponsesCsv(0)"
                    :class="{'btn-disabled': totalCount < 1}"
                  )
                    | ダウンロード
              .row.mb-4
                .col-3
                  p テキスト形式
                .col-6
                  p
                    | テキスト形式は人の目で内容を確認するためのものです。
                .col-3
                  a.cc-primary-btn.cc-inline-btn.action-btns(
                    href=""
                    @click.prevent="exportCurrentResponsesCsv(1)"
                    :class="{'btn-disabled': totalCount < 1}"
                  )
                    | ダウンロード
            .col-12.section
              p.cc-label-mid 意見データ取得
              .row.mb-4
                .col-3
                  p コード形式
                .col-6
                  p
                    | コード形式は上記の「②意見データアップロード」の「3.意見データアップロード」で使用できます。分類を修正する際などに使用してください。
                    br
                    | 回答データを更新した後はセンテンス分割から再度行ってください。
                .col-3
                  a.cc-primary-btn.cc-inline-btn.action-btns(
                    href=""
                    @click.prevent="exportCurrentOpinionsCsv(0)"
                    :class="{'btn-disabled': totalCount < 1}"
                  )
                    | ダウンロード
              .row.mb-4
                .col-3
                  p テキスト形式
                .col-6
                  p
                    | テキスト形式は人の目で内容を確認するためのものです。
                .col-3
                  a.cc-primary-btn.cc-inline-btn.action-btns(
                    href=""
                    @click.prevent="exportCurrentOpinionsCsv(1)"
                    :class="{'btn-disabled': totalCount < 1}"
                  )
                    | ダウンロード
        .card-body.vc
          .row.mb-4
            .col-12
              h5.vc-midashi.mb-3 定量分析用データ登録
            .col-12.section
              p.cc-label-mid 定量分析用データ登録
              .row.mb-4
                .col-3
                  p 1. 設問データのアップロード
                .col-6
                  p
                    | 設問データのテンプレートをダウンロードし、その形式に沿って定量分析をする設問の情報を入力してください。入力が完了したら、CSVファイルとして保存し、「CSVアップロード」ボタンを押して、アップロードしてくださいください
                .col-3
                  div
                    label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                      input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event, 'quantitativeQuestions')")
                  div
                    a.link(href="" @click.prevent="exportQuanQuestionTempCsv")
                      | テンプレートCSVダウンロード
              .row.mb-4
                .col-3
                  p 2. 定量分析用回答データのアップロード
                .col-6
                  p
                    | 定量分析用回答データのテンプレートをダウンロードし、回答者ごとの定量分析対象の回答を入力してください。入力が完了したら、CSVファイルとして保存し、「CSVアップロード」ボタンを押して、アップロードしてください
                .col-3
                  div
                    label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                      input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event, 'quantitativeResponses')")
                  div
                    a.link(href="" @click.prevent="exportQuanResponseTempCsv")
                      | テンプレートCSVダウンロード
              .row.mb-4
                .col-3
                  p 3. 計算済み定量分析結果推移データのアップロード
                .col-6
                  p
                    | 計算済み定量分析結果推移データのテンプレートをダウンロードし、各部署ごとの計算結果を入力してください。入力が完了したら、CSVファイルとして保存し、「CSVアップロード」ボタンを押して、アップロードしてください
                .col-3
                  div
                    label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                      input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event, 'quantitativePoints')")
                  div
                    a.link(href="" @click.prevent="exportQuanPointTempCsv")
                      | テンプレートCSVダウンロード
              .row.mb-4
                .col-3
                  p 4. 計算済み定量分析結果設問別データのアップロード
                .col-6
                  p
                    | 計算済み定量分析結果設問別データのテンプレートをダウンロードし、各選択肢ごとの計算結果を入力してください。入力が完了したら、CSVファイルとして保存し、「CSVアップロード」ボタンを押して、アップロードしてください
                .col-3
                  div
                    label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                      input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event, 'quantitativeCounts')")
                  div
                    a.link(href="" @click.prevent="exportQuanCountTempCsv")
                      | テンプレートCSVダウンロード
        //- .card-body.vc
          .row.mb-4
            .col-12
              h5.vc-midashi.mb-3 具体意見用データ登録
            .col-12.section
              p.cc-label-mid 具体意見データのアップロード
              p 「②意見データアップロード」の「センテンス分割」が終わっていない場合はそちらを先に実行してください
              .row.mb-4
                .col-3
                  p 1. 具体意見データ分析
                .col-6
                  p
                    | 「AI分析 実行」を押してください。完了するとダウンロードリンクの付いたメールが届きます。
                .col-3
                  div
                    a.cc-primary-btn.cc-inline-btn.action-btns(href="" @click.prevent="requestResponseRanking")
                      | AI分析 実行
                  div
                    a.link(href="" @click.prevent="exportResponseRankingCsv")
                      | 具体意見分析結果ダウンロード
              .row.mb-4
                .col-3
                  p 2. 具体意見データアップロード
                .col-6
                  p
                    | 分析結果をダウンロードしたら、必要に応じて補正してください。補正が済んだら、意見データアップロードの「CSVアップロード」を押して、CSVファイルをアップロードしてください。
                .col-3
                  label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                    input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event, 'responseRanking')")
        //- .card-body.vc
          .row.mb-4
            .col-12
              h5.vc-midashi.mb-3 テキストマイニング画像・データ登録
            .col-12.section
              p.cc-label-mid テキストマイニング画像・データのアップロード
              p あらかじめ「②意見データアップロード」の「2. 意見分類・感情分類 AI分析実行」を完了し、感情分類結果からKH Coderによるテキストマイニングを行ってください。が終わっていない場合はそちらを先に実行してください
              .row.mb-4
                .col-3
                  p 1. 画像登録
                .col-6
                  p
                    | KH Coderで出力した画像を、それぞれ「ネガティブ画像アップロード」「ポジティブ画像アップロードを押して、アップロードしてください。
                    br
                    | 登録する画像はKH Coderの凡例部分を覗いた、正方形にトリミングしてください。
                .col-3
                  .mb-2
                    label.cc-primary-btn.cc-inline-btn ネガティブ画像アップロード
                      input.d-none(type="file" accept="image/*" @click="fileInitialize($event)" @change="onFileChange($event, 'negaImages')")
                  div
                    label.cc-primary-btn.cc-inline-btn ポジティブ画像アップロード
                      input.d-none(type="file" accept="image/*" @click="fileInitialize($event)" @change="onFileChange($event, 'posiImages')")
              .row.mb-4
                .col-3
                  p 2. データ登録
                .col-6
                  p
                    | テンプレートCSVをダウンロードし、形式にそってデータを作成してください。
                    br
                    | カラーコードはKH Coderで出力した画像の凡例部分からスポイト機能などで取得し、同じ色を指定できるようにしてください。
                    br
                    | コメントは後で画面上で編集することも可能です。
                .col-3
                  div
                    label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                      input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event, 'textMinings')")
                  div
                    a.link(href="" @click.prevent="exportTempCsv('textMinings')")
                      | テンプレートCSVダウンロード
        //- .card-body.vc
          .row.mb-4
            .col-12
              h5.vc-midashi.mb-3 カテゴリ別のTF/IDF値の計算
            .col-12.section
              p.cc-label-mid カテゴリ別のTF/IDF値のダウンロード
              p 「②意見データアップロード」の「センテンス分割」が終わっていない場合はそちらを先に実行してください
              .row.mb-4
                .col-3
                  p AI分析・計算
                .col-6
                  p 「AI分析 実行」を押してください。完了するとダウンロードリンクの付いたメールが届きます。
                .col-3
                  div
                    a.cc-primary-btn.cc-inline-btn.action-btns(href="" @click.prevent="requestFeatureWords")
                      | AI分析 実行
  ImportFileModal(ref="importFileModal" @importCsv="importCsv" :file-name="currentFilename")

</template>
<script>
import { mapState, mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';
import i18n from '../../../locales/index';
import moment from 'moment';
import CDL from '../../../lib/csv_download';
import ImportFileModal from '../../../components/x/surveys/ImportFileModal';
const CSVDownload = new CDL();

export default {
  components: {
    ErrorMessage,
    ImportFileModal
  },

  data() {
    return {
      errorMessages: [],
      message: '',
      processing: false,
      quantitativeQuestionCsvFile: '',
      quantitativeResponseCsvFile: '',
      quantitativePointCsvFile: '',
      quantitativeCountCsvFile: '',
      currentFilename: ''
    };
  },

  computed: {
    ...mapState('resources/x_surveys', ['survey', 'responses', 'totalCount']),

    quantitativeQuestionsCsvParams() {
      const formData = new FormData();
      formData.append('csv_file', this.quantitativeQuestionCsvFile);

      return formData;
    },

    quantitativeResponsesCsvParams() {
      const formData = new FormData();
      formData.append('csv_file', this.quantitativeResponseCsvFile);

      return formData;
    },

    quantitativePointsCsvParams() {
      const formData = new FormData();
      formData.append('csv_file', this.quantitativePointCsvFile);

      return formData;
    },

    quantitativeCountsCsvParams() {
      const formData = new FormData();
      formData.append('csv_file', this.quantitativeCountCsvFile);

      return formData;
    }
  },

  created() {
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_surveys', ['loadSurvey']),

    load() {
      let params = this.$route.params;
      this.loadSurvey(params.id);
    },

    resetMessages() {
      this.errorMessages = [];
      this.message = '';
    },

    importCsv(fileType) {
      this.resetMessages();

      let path = '/api/x/surveys/' + this.$route.params.id;
      let body;
      if (fileType === 'quantitativeQuestions') {
        path += '/import_quantitative_questions';
        body = this.quantitativeQuestionsCsvParams;
      } else if (fileType === 'quantitativeResponses') {
        path += '/import_quantitative_responses';
        body = this.quantitativeResponsesCsvParams;
      } else if (fileType === 'quantitativePoints') {
        path += '/import_quantitative_points';
        body = this.quantitativePointsCsvParams;
      } else if (fileType === 'quantitativeCounts') {
        path += '/import_quantitative_counts';
        body = this.quantitativeCountsCsvParams;
      }

      fetch(path, {
        method: 'PUT',
        body: body
      }).then(response => {
        if (response.status === 200) {
          this.message = i18n.tc('admin.surveys.messages.processing');
        } else if (response.status === 422) {
          response.json().then(body => {
            this.errorMessages = body.errors;
          });
        }
      });
    },

    onFileChange(e, fileType) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      if (fileType === 'quantitativeQuestions')
        this.quantitativeQuestionCsvFile = files[0];
      else if (fileType === 'quantitativeResponses')
        this.quantitativeResponseCsvFile = files[0];
      else if (fileType === 'quantitativePoints')
        this.quantitativePointCsvFile = files[0];
      else if (fileType === 'quantitativeCounts')
        this.quantitativeCountCsvFile = files[0];

      this.currentFilename = files[0].name;
      this.modalOpen(fileType);
    },

    exportQuanQuestionTempCsv() {
      const head = [
        'survey_id',
        'quantitative_question_code',
        'question_text',
        'title',
        'description',
        'question_type',
        'calculate_type',
        'unit',
        'order',
        'matrix_category_code',
        'matrix_category_text',
        'answer_text',
        'answer_num',
        'point'
      ];

      CSVDownload.downloadCsv(
        head,
        [],
        'voiscope_quantitative_questions_' +
          moment().format('YYYYMMDD_HHmmss') +
          '.csv'
      );
    },

    exportQuanResponseTempCsv() {
      const response_attributes = this.survey.account_group.response_attributes;
      const quantitative_questions = this.survey.quantitative_questions;
      let head = ['question_code', 'code'];
      for (let i = 0; i < quantitative_questions.length; i++) {
        const q = quantitative_questions[i];
        head.push('Q_' + q.code);
      }
      for (let i = 0; i < response_attributes.length; i++) {
        head.push('response_attributes_code' + (i + 1));
        head.push('attribute_values_code' + (i + 1));
      }
      head = head.concat(['organization_code', 'responder_code']);

      CSVDownload.downloadCsv(
        head,
        [],
        'voiscope_quantitative_responses_' +
          moment().format('YYYYMMDD_HHmmss') +
          '.csv'
      );
    },

    exportQuanPointTempCsv() {
      const questions = this.survey.quantitative_questions;
      let head = [
        'question_code',
        'organization_version_code',
        'parent_code',
        'organization_code',
        'organization_name'
      ];
      for (let i = 0; i < questions.length; i++) {
        const q = questions[i];
        head.push('Q_' + q.code);
      }

      CSVDownload.downloadCsv(
        head,
        [],
        'voiscope_quantitative_points_' +
          moment().format('YYYYMMDD_HHmmss') +
          '.csv'
      );
    },

    exportQuanCountTempCsv() {
      const questions = this.survey.quantitative_questions;
      let head = ['question_code'];
      for (let i = 0; i < questions.length; i++) {
        const q = questions[i];
        const mtxs = q.matrix_categories;
        const answers = q.answers;
        if (mtxs.length > 0) {
          for (let i = 0; i < mtxs.length; i++) {
            const mtx = mtxs[i];
            for (let i = 0; i < answers.length; i++) {
              const answer = answers[i];
              head.push(q.code + '|' + mtx.code + '|' + answer.code);
            }
          }
        } else {
          for (let i = 0; i < answers.length; i++) {
            const answer = answers[i];
            head.push(q.code + '|' + answer.code);
          }
        }
      }

      CSVDownload.downloadCsv(
        head,
        [],
        'voiscope_quantitative_counts_' +
          moment().format('YYYYMMDD_HHmmss') +
          '.csv'
      );
    },

    modalOpen(fileType) {
      this.$refs.importFileModal.modalOpen(fileType);
    },

    fileInitialize(e) {
      e.target.value = null;
    }
  }
};
</script>
<style lang="scss" scoped>
.img-upload-wrap {
  padding: 1rem;
  border: 0.5px #999 solid;
}
.section {
  background-color: #ececec;
  padding: 1.5rem !important;
  margin-bottom: 1rem !important;
}
.link {
  color: #4bafaf;
  font-size: 0.85rem;
}
.col-3 p,
.col-6 p {
  margin-bottom: 0;
}
.action-btns {
  width: 200px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
</style>
