import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_response_attributes');

export default {
  namespaced: true,

  state: {
    response_attributes: {}
  },

  getters: {},

  actions: {
    /**
     * 回答属性一覧取得
     */
    loadResponseAttributes({ commit }) {
      return gateway.get({ action: 'index' }).then(response => {
        if (response.status === 200) {
          const response_attributes = response.body.response_attributes;
          commit('setResponseAttributes', response_attributes);
        }
        return response;
      });
    }
  },

  mutations: {
    setResponseAttributes(state, response_attributes) {
      state.response_attributes = response_attributes;
    }
  }
};
