import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_public_activities');

export default {
  namespaced: true,
  actions: {
    createPublicActivity(_, params) {
      gateway.post({ action: 'create' }, params);
    }
  }
};
