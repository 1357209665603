<script>
import { Scatter, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Scatter,
  mixins: [reactiveProp],
  props: {
    options: { type: Object, required: true }
  },
  watch: {
    chartData() {
      this.$data._chart.update();
    }
  },
  mounted() {
    this.renderScatterChart();
  },
  methods: {
    renderScatterChart: function() {
      this.addPlugin({
        beforeDraw: function(c) {
          var ctx = c.chart.ctx;
          ctx.fillStyle = 'rgba(255,255,255,1)';
          ctx.fillRect(0, 0, c.chart.width, c.chart.height);
        },
        beforeRender: function(chart) {
          if (chart.config.options.showAllTooltips) {
            // create an array of tooltips
            // we can't use the chart tooltip because there is only one tooltip per chart
            chart.pluginTooltips = [];
            chart.config.data.datasets.forEach(function(dataset, i) {
              chart.getDatasetMeta(i).data.forEach(function(sector) {
                chart.pluginTooltips.push(
                  new Chart.Tooltip(
                    {
                      _chart: chart.chart,
                      _chartInstance: chart,
                      _data: chart.data,
                      _options: chart.options.tooltips,
                      _active: [sector]
                    },
                    chart
                  )
                );
              });
            });

            // turn off normal tooltips
            chart.options.tooltips.enabled = false;
          }
        },
        afterDraw: function(chart, easing) {
          if (chart.config.options.showAllTooltips) {
            // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
            if (!chart.allTooltipsOnce) {
              if (easing !== 1) return;
              chart.allTooltipsOnce = true;
            }

            // turn on tooltips
            chart.options.tooltips.enabled = true;
            Chart.helpers.each(chart.pluginTooltips, function(tooltip) {
              tooltip.initialize();
              tooltip.update();
              // we don't actually need this since we are not animating tooltips
              tooltip.pivot();
              tooltip.transition(easing).draw();
            });
            chart.options.tooltips.enabled = false;
          }
        }
      });
      this.renderChart(this.chartData, this.options);
    },
    update: function() {
      this.$data._chart.options = this.options;
      this.$data._chart.update(0);
    }
  }
};
</script>
