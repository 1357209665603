import user from './user';

export default {
  namespaced: true,

  modules: {
    user: user
  },

  state: {},

  getters: {},

  mutations: {}
};
