<template lang="pug">
  ul.alert.alert-danger
    li.ml-1(v-for="error in errors")
      | {{ error }}
</template>

<script>
export default {
  props: {
    errors: { type: Array, required: true }
  }
};
</script>

<style scoped lang="scss"></style>
