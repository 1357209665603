import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_surveys');

export default {
  namespaced: true,

  state: {
    surveysList: [],
    survey: {},
    responses: [],
    totalCount: 0,
    accountGroups: [],
    organizationVersions: []
  },

  getters: {},

  actions: {
    /**
     * Survey一覧取得
     */
    loadSurveys({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          const surveysList = response.body.surveys;

          commit('setSurveysList', surveysList);
        }
        return response;
      });
    },

    /**
     * Survey詳細取得
     */
    loadSurvey({ commit }, id) {
      return gateway
        .get({
          action: 'show',
          id
        })
        .then(response => {
          if (response.status === 200) {
            const survey = response.body.survey;
            const responses = response.body.responses;
            const totalCount = response.body.total_count;

            commit('setSurvey', survey);
            commit('setResponses', responses);
            commit('setTotalCount', totalCount);
          }
          return response;
        });
    },

    loadEditSurvey({ commit }, id) {
      return gateway.get({ action: 'edit', id }).then(response => {
        if (response.status === 200) {
          const survey = response.body.survey;
          const accountGroups = response.body.account_groups;

          commit('setSurvey', survey);
          commit('setAccountGroups', accountGroups);
        }
        return response;
      });
    },

    /**
     * 新規作成
     *
     * @param {Object} params パラメタ
     * @return {Promise} Promise
     */
    createSurvey(_, params) {
      return gateway
        .post(
          {
            action: 'create'
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    updateSurvey(_, params) {
      return gateway
        .put(
          {
            action: 'update',
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    loadAccountGroups({ commit }) {
      return gateway.get({ action: 'new' }).then(response => {
        if (response.status === 200) {
          const accountGroups = response.body.account_groups;
          commit('setAccountGroups', accountGroups);
        }

        return response;
      });
    },

    exportSentencesCsv(_, params) {
      return gateway
        .put({ action: 'export_csv', id: params.id }, params)
        .then(response => {
          return response;
        });
    },

    activateSurvey(_, id) {
      return gateway.put({ action: 'activate', id: id }).then(response => {
        return response;
      });
    },

    undoSurvey(_, id) {
      return gateway.put({ action: 'undo', id: id }).then(response => {
        return response;
      });
    },

    exportCurrentResponsesData(_, params) {
      return gateway
        .put(
          { action: 'export_current_responses', id: params.surveyId },
          params
        )
        .then(response => {
          return response;
        });
    },

    exportCurrentOpinionsData(_, params) {
      return gateway
        .put({ action: 'export_current_opinions', id: params.surveyId }, params)
        .then(response => {
          return response;
        });
    },

    requestClassificationsApi(_, id) {
      return gateway
        .put({ action: 'request_classifications_api', id: id })
        .then(response => {
          return response;
        });
    },

    exportOpinionsDataWithClassifications(_, id) {
      return gateway
        .put({ action: 'export_opinions_with_classifications', id: id })
        .then(response => {
          return response;
        });
    },

    requestResponseRankingApi(_, id) {
      return gateway
        .put({ action: 'request_response_ranking_api', id: id })
        .then(response => {
          return response;
        });
    },

    exportResponseRanking(_, id) {
      return gateway
        .put({ action: 'export_response_ranking', id: id })
        .then(response => {
          return response;
        });
    },

    requestFeatureWordsApi(_, id) {
      return gateway
        .put({ action: 'request_feature_words_api', id: id })
        .then(response => {
          return response;
        });
    }
  },

  mutations: {
    setSurveysList(state, surveysList) {
      state.surveysList = surveysList;
    },

    setSurvey(state, survey) {
      state.survey = survey;
    },

    setResponses(state, responses) {
      state.responses = responses;
    },

    setTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    setAccountGroups(state, accountGroups) {
      state.accountGroups = accountGroups;
    },

    setOrganizationVersions(state, accountGroupId) {
      let accountGroup = state.accountGroups.find(
        accountGroup => accountGroup.id === accountGroupId
      );
      state.organizationVersions = accountGroup.organization_versions;
    }
  }
};
