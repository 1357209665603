import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_surveys');

export default {
  namespaced: true,

  state: {
    surveys: [],
    reports: []
  },

  getters: {},

  actions: {
    /**
     * 調査一覧取得
     */
    loadSurveys({ commit }) {
      return gateway.get({ action: 'index' }).then(response => {
        if (response.status === 200) {
          const surveys = response.body.surveys;
          commit('setSurveys', surveys);
        }
        return response;
      });
    },

    loadSurveyReports({ commit }, params) {
      return gateway
        .get({
          action: 'reports',
          id: params.id
        })
        .then(response => {
          if (response.status === 200) {
            const reports = response.body.reports;
            commit('setReports', reports);
          }
          return response;
        });
    },

    deleteSurveyReport({ commit }, params) {
      return gateway
        .delete(
          {
            action: 'delete_report',
            id: params.id
          },
          params
        )
        .then(response => {
          if (response.status === 200) {
            const reports = response.body.reports;
            commit('setReports', reports);
          }
          return response;
        });
    }
  },

  mutations: {
    setSurveys(state, surveys) {
      state.surveys = surveys;
    },
    setReports(state, reports) {
      state.reports = reports;
    }
  }
};
