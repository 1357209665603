<template lang="pug">
  .content-wrapper
    .content-header.d-flex.flex-column.flex-md-row
      h4.mb-0
        | メルマガプレビュー
    .row
      .col-md-12.grid-margin.stretch-card.clearfix
        .card
          .card-body(v-html="previewHtml")

</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState('resources/x_newsletter_templates', ['previewHtml'])
  },
  created() {
    this.load();
  },
  mounted() {},
  methods: {
    ...mapActions('resources/x_newsletter_templates', ['loadPreviewHtml']),
    load() {
      this.loadPreviewHtml(this.$route.params.id);
    }
  }
};
</script>
