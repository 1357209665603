<template lang="pug">
  .content-wrapper
    .row.flex-grow
      .col-6
        .card
          .card-body
            h4.card-title
              | {{ $t('admin.admins.edit.title') }}

            form(v-if="ready")
              .alert.alert-danger(v-if="errorMessages.length > 0")
                ul.mb-0
                  li(v-for="error in errorMessages")
                    | {{ error }}
              validation-observer(v-slot="{ invalid }")
                validation-provider(name="name" rules="required" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.admins.name') }}
                    input(
                      type="text"
                      placeholder="Suzuki Taro"
                      :class="{ 'form-control': true, 'form-control-danger': errors.length}"
                      v-model="adminName"
                    )
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                validation-provider(name="email" rules="required|email" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.admins.email') }}
                    input(
                      type="email"
                      placeholder="admin@example.com"
                      :class="{ 'form-control': true, 'form-control-danger': errors.length}"
                      v-model="adminEmail"
                    )
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                .mt-1.float-right
                  router-link.btn.btn-light.mr-2(:to="{ name: 'x_admins' }")
                    | {{ $t('actions.cancel') }}
                  button.btn.btn-success(
                    :disabled="processing || invalid"
                    @click.prevent="submit"
                  )
                    | {{ $t('actions.update') }}

</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      ready: false,
      errorMessages: [],
      processing: false
    };
  },

  computed: {
    ...mapState('pages/x_admins/admin', { admin: state => state }),

    adminName: {
      get() {
        return this.admin.name;
      },
      set(newName) {
        this.setAdmin({ admin: { name: newName } });
      }
    },

    adminEmail: {
      get() {
        return this.admin.email;
      },
      set(newName) {
        this.setAdmin({ admin: { email: newName } });
      }
    },

    /**
     * サーバサイドに渡す admin のパラメータを生成
     *
     * @return {object}
     */
    adminParams() {
      return {
        id: this.admin.id,
        admin: {
          name: this.admin.name,
          email: this.admin.email
        }
      };
    }
  },

  created() {
    this.loadAdmin();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_admins', ['editAdmin', 'updateAdmin']),
    ...mapMutations('pages/x_admins/admin', ['setAdmin']),

    loadAdmin() {
      this.editAdmin(this.$route.params.id).then(response => {
        if (response.status === 200) {
          this.setAdmin({
            admin: response.body.admin
          });
          this.ready = true;
        }
      });
    },

    resetErrorMessages() {
      this.errorMessages = [];
    },

    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.updateAdmin(this.adminParams)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'x_admins' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
