import Vue from 'vue';
import Vuex from 'vuex';
import VModal from 'vue-js-modal';

import 'babel-polyfill';

import config from './modules/config';

// -- resources --
import xAccountGroups from './modules/resources/x/account_groups';
import xUsers from './modules/resources/x/users';
import xAdmins from './modules/resources/x/admins';
import xProfile from './modules/resources/x/profile';
import xSurveys from './modules/resources/x/surveys';
import xQuestions from './modules/resources/x/questions';
import xQuantitativeQuestions from './modules/resources/x/quantitative_questions';
import xQuantitativeAnswers from './modules/resources/x/quantitative_answers';
import xOrganizationVersions from './modules/resources/x/organization_versions';
import xClassificationRequests from './modules/resources/x/classification_requests';
import xInquiries from './modules/resources/x/inquiries';
import xClassificationRequestItems from './modules/resources/x/classification_request_items';
import xDashboard from './modules/resources/x/dashboard';
import xNewsletterTemplates from './modules/resources/x/newsletter_templates';
import xNewsletters from './modules/resources/x/newsletters';
import xCoOccurrenceNetworks from './modules/resources/x/co_occurrence_networks';
import xChatGPTClassifications from './modules/resources/x/chatgpt_classifications';
import xChatGPTSentimentAnalysis from './modules/resources/x/chatgpt_sentiment_analysis';

import uControlSettings from './modules/resources/u/control_settings';
import uRoles from './modules/resources/u/roles';
import uDashboard from './modules/resources/u/dashboard';
import uMypage from './modules/resources/u/mypage';
import uAboutData from './modules/resources/u/about_data';
import uOrganizations from './modules/resources/u/organizations';
import uSurveys from './modules/resources/u/surveys';
import uResponseAttributes from './modules/resources/u/response_attributes';
import uAnalyseTeam from './modules/resources/u/analyse_team';
import uAnalyseCompare from './modules/resources/u/analyse_compare';
import uAnalyseMatrix from './modules/resources/u/analyse_matrix';
import uAnalyseHeatmap from './modules/resources/u/analyse_heatmap';
import uOpinionSearch from './modules/resources/u/opinion_search';
import uUsers from './modules/resources/u/users';
import uTopic from './modules/resources/u/topic';
import uTextMining from './modules/resources/u/text_mining';
import uAccountGroups from './modules/resources/u/account_groups';
import uSelectAccountGroup from './modules/resources/u/select_account_group';
import uClassificationRequests from './modules/resources/u/classification_requests';
import uClassificationRequestItems from './modules/resources/u/classification_request_items';
import uPublicActivities from './modules/resources/u/public_activities';
import uQuantitativeAnalyse from './modules/resources/u/quantitative_analyse';
import uChatInterview from './modules/resources/u/chat_interviews';
import uInquiry from './modules/resources/u/inquiries';
import uResponseRanking from './modules/resources/u/response_ranking';
import uQuantitative from './modules/resources/u/quantitative';
import uAnalyse from './modules/resources/u/analyse';
import uCoOccurrences from './modules/resources/u/co_occurrences';

import rInquiry from './modules/resources/r/inquiries';
import rChatInterview from './modules/resources/r/chat_interviews';
import rSelectNickname from './modules/resources/r/select_nickname';

// -- pages --
import xPageUsers from './modules/pages/x/users';
import xPageAdmins from './modules/pages/x/admins';
import xPageSurveys from './modules/pages/x/surveys';
import xPageAccountGroups from './modules/pages/x/account_groups';
import xPageOrganizationVersions from './modules/pages/x/organization_versions';
import xPageClassificationRequests from './modules/pages/x/classification_requests';
import xPageDashboard from './modules/pages/x/dashboard';
import xPageInquiries from './modules/pages/x/inquiries';
import xPageNewsletterTemplates from './modules/pages/x/newsletter_templates';
import xPageNewsletters from './modules/pages/x/newsletters';
import xPageCoOccurrenceNetworks from './modules/pages/x/co_occurrence_networks';
import xPageQuestions from './modules/pages/x/questions';
import xPageQuantitativeQuestions from './modules/pages/x/quantitative_questions';
import xPageQuantitativeAnswers from './modules/pages/x/quantitative_answers';

import uPageOrganizations from './modules/pages/u/organizations';
import uPageSurveys from './modules/pages/u/surveys';
import uPageUsers from './modules/pages/u/users';
import uPageClassificationRequests from './modules/pages/u/classification_requests';

Vue.use(Vuex);
Vue.use(VModal);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    config,
    resources: {
      namespaced: true,
      modules: {
        x_account_groups: xAccountGroups,
        x_users: xUsers,
        x_admins: xAdmins,
        x_profile: xProfile,
        x_surveys: xSurveys,
        x_questions: xQuestions,
        x_quantitative_questions: xQuantitativeQuestions,
        x_quantitative_answers: xQuantitativeAnswers,
        x_organization_versions: xOrganizationVersions,
        x_classification_requests: xClassificationRequests,
        x_classification_request_items: xClassificationRequestItems,
        x_inquiries: xInquiries,
        x_dashboard: xDashboard,
        x_newsletter_templates: xNewsletterTemplates,
        x_newsletters: xNewsletters,
        x_co_occurrence_networks: xCoOccurrenceNetworks,
        x_chatgpt_classifications: xChatGPTClassifications,
        x_chatgpt_sentiment_analysis: xChatGPTSentimentAnalysis,
        u_control_settings: uControlSettings,
        u_roles: uRoles,
        u_dashboard: uDashboard,
        u_mypage: uMypage,
        u_about_data: uAboutData,
        u_organizations: uOrganizations,
        u_surveys: uSurveys,
        u_response_attributes: uResponseAttributes,
        u_analyse_team: uAnalyseTeam,
        u_analyse_compare: uAnalyseCompare,
        u_analyse_matrix: uAnalyseMatrix,
        u_analyse_heatmap: uAnalyseHeatmap,
        u_opinion_search: uOpinionSearch,
        u_users: uUsers,
        u_topic: uTopic,
        u_text_mining: uTextMining,
        u_account_groups: uAccountGroups,
        u_select_account_group: uSelectAccountGroup,
        u_classification_requests: uClassificationRequests,
        u_classification_request_items: uClassificationRequestItems,
        u_public_activities: uPublicActivities,
        u_quantitative_analyse: uQuantitativeAnalyse,
        u_chat_interviews: uChatInterview,
        u_inquiries: uInquiry,
        u_response_ranking: uResponseRanking,
        u_quantitative: uQuantitative,
        u_analyse: uAnalyse,
        u_co_occurrences: uCoOccurrences,
        r_inquiries: rInquiry,
        r_chat_interviews: rChatInterview,
        r_select_nickname: rSelectNickname
      }
    },
    pages: {
      namespaced: true,
      modules: {
        x_users: xPageUsers,
        x_admins: xPageAdmins,
        x_surveys: xPageSurveys,
        x_account_groups: xPageAccountGroups,
        x_organization_versions: xPageOrganizationVersions,
        x_classification_requests: xPageClassificationRequests,
        x_dashboard: xPageDashboard,
        x_inquiries: xPageInquiries,
        x_newsletter_templates: xPageNewsletterTemplates,
        x_newsletters: xPageNewsletters,
        x_co_occurrence_networks: xPageCoOccurrenceNetworks,
        x_questions: xPageQuestions,
        x_quantitative_questions: xPageQuantitativeQuestions,
        x_quantitative_answers: xPageQuantitativeAnswers,
        u_surveys: uPageSurveys,
        u_organizations: uPageOrganizations,
        u_users: uPageUsers,
        u_classification_requests: uPageClassificationRequests
      }
    }
  }
});
