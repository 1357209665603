<template lang="pug">
  div
    modal(
      ref="modalUploadReports"
      name="modalUploadReports"
      id="modalUploadReports"
      :minWidth="modalWidth"
      :minHeight="modalHeight"
      :width="modalWidth"
      :height="modalHeight"
      :reset="true"
      :resizable="false"
      :clickToClose="true"
      :scrollable="true"
      :draggable="true"
      :pivotY="0.7"
    )
      header.modal-header.align-items-end
        h6.modal-title.vc-midashi
          | {{ survey.name }}
        dl.respondent-num.align-items-center
          dt
            button(type="button" @click="$modal.hide('modalUploadReports')" class="close vc-close")
              | ×
      .modal-contents.row.no-gutters#modal-contents(:style="{height: modalHeight - 57 + 'px'}")
        .upload-file-wrap.col-12
          h5 ファイルをアップロード
          .alert-wrap
            notice-message(:messages="noticeMessages" v-if="noticeMessages.length > 0")
            error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
          .upload-file.flex.items-center.justify-center.w-full.h-screen.text-center(
            :class="{ enter: isEnter }"
            @dragover="dragOver($event)"
            @dragleave="dragLeave()"
            @drop="drop($event)"
          )
            p.text-left.small ドラッグアンドドロップまたはファイルを選択してアップロード
            .cloud-upload-img
              img(src="../../../assets/images/excheck/cloud_upload.png")
            label.btn.btn-primary.vc-primary-btn.select-file-btn ファイルを選択する
              input.d-none(
                type="file"
                :accept="acceptFiletypes"
                @change="onFileChange($event)"
                multiple
              )
            .row
              .col-2
              .filenames-wrap.col-6.p-2.border
                .filenames
                  span.mr-1.align-self-center(v-for="file in reportFiles")
                    | {{ file.name }}
              .col-2
                a.btn.btn-primary.vc-primary-btn(href='javascript:void(0)' @click="uploadReport()") アップロード
              .col-2
              .col-2
              .col-10.text-left.mt-2.pl-0.small
                | ※ファイルサイズ上限は10MBです
              .col-2
              .col-10.text-left.mt-2.pl-0.small
                | ※ファイルは5つまでアップロードできます。新しくアップロードする場合は古いファイルを削除してください。
        .report-list-wrap.col-12
          h5 アップロード済みファイル
          .report-list(v-show="reports.length > 0")
            .list
              .dashboard-comp-table-col.vc-dashboard-box-table-wrap.topic-total-comp-table-col
                .row.no-gutters.vc-respondent-table.text-left
                  .col-2 アップロード日
                  .col-6 ファイル名
                  .col-4
                .vc-dashboard-box-table-data-row.no-scroll
                  .row.no-gutters.vc-respondent-table.text-left(v-for="file in reports")
                    .col-2.text-left.overflow-hidden.text-nowrap.table-column-name
                      div
                        | {{ file.uploaded_at }}
                    .col-6
                      div
                        | {{ file.name }}
                    .col-4
                      .actions
                        a.btn.btn-primary.vc-primary-btn.del-btn.mr-1(href='javascript:void(0)' @click="showDeleteModal(file)") 削除
                        a.btn.btn-primary.vc-primary-btn(href='javascript:void(0)' @click="downloadReport(file.id)") ダウンロード
          .empty-list(v-show="reports.length < 1")
            | アップロード済みファイルがありません。
    DeleteReportModal(:deleteTarget="deleteTarget")
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';
import NoticeMessage from 'src/components/NoticeMessage';
import DeleteReportModal from 'src/components/u/aboutData/DeleteReportModal';

export default {
  components: {
    ErrorMessage,
    NoticeMessage,
    DeleteReportModal
  },

  data() {
    return {
      modalWidth: 0,
      modalHeight: 0,
      survey: {},
      reportFiles: [],
      isEnter: false,
      isReports: false,
      acceptFiletypes:
        '\
        image/jpeg,\
        image/png,\
        image/gif,\
        text/plain,\
        text/csv,\
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,\
        application/vnd.ms-excel,\
        application/vnd.openxmlformats-officedocument.presentationml.presentation,\
        application/vnd.ms-powerpoint,\
        application/vnd.openxmlformats-officedocument.wordprocessingml.document,\
        application/msword,\
        application/pdf\
      ',
      errorMessages: [],
      noticeMessages: [],
      deleteTarget: {}
    };
  },

  computed: {
    ...mapState('resources/u_surveys', ['reports'])
  },

  watch: {
    reports: function() {
      if (this.reports.length > 0) this.isReports = true;
      else this.isReports = false;
    }
  },

  created() {
    this.modalWidth = window.outerWidth * 0.75;
    this.modalHeight = window.outerHeight * 0.75;
  },

  methods: {
    ...mapActions('resources/u_surveys', ['loadSurveyReports']),
    modalOpen: function(survey) {
      this.survey = survey;
      this.loadSurveyReports({ id: survey.id });
      this.reportFiles = [];
      this.errorMessages = [];
      this.noticeMessages = [];
      this.$modal.show('modalUploadReports');
    },
    onFileChange(e) {
      this.resetErrorMessages();
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.reportFiles = files;
    },
    dragLeave() {
      this.isEnter = false;
    },
    dragOver(e) {
      e.preventDefault();
      this.isEnter = true;
    },
    drop(e) {
      e.preventDefault();
      this.isEnter = false;
      this.onFileChange(e);
    },
    uploadReport() {
      this.resetErrorMessages();
      if (this.reportFiles.length < 1) return null;
      if (this.reports.length + this.reportFiles.length > 5) {
        let el = document.getElementById('modal-contents');
        el.scrollTop = 0;
        this.errorMessages = ['アップロードできるファイルは５個までです。'];
        return null;
      }
      this.noticeMessages = [
        '処理中...。アップロードの完了までもう少しお待ちください。'
      ];
      let path = '/api/u/surveys/' + this.survey.id + '/upload_report';
      const formData = new FormData();
      for (let idx = 0; idx < this.reportFiles.length; idx++) {
        formData.append('reports[]', this.reportFiles[idx]);
      }

      fetch(path, {
        method: 'PUT',
        body: formData
      }).then(response => {
        this.noticeMessages = [];
        if (response.status === 200) {
          this.loadSurveyReports({ id: this.survey.id });
        } else {
          response.json().then(body => {
            this.errorMessages = body.errors;
          });
        }
        this.reportFiles = [];
      });
    },

    downloadReport(reportId) {
      this.resetErrorMessages();
      let hash = {
        name: 'u_survey_download_report',
        params: { id: this.survey.id, report_id: reportId }
      };
      let routeData = this.$router.resolve(hash);
      window.open(routeData.href, '_blank');
    },

    showDeleteModal(report) {
      this.deleteTarget = report;
      this.$bvModal.show('delete-report-modal');
    },

    resetErrorMessages() {
      this.errorMessages = [];
    }
  }
};
</script>

<style scoped>
.modal-contents {
  overflow: scroll;
  padding-bottom: 20px;
}
.upload-file-wrap {
  border-bottom: 1px solid #dee2e6;
}
.upload-file-wrap .upload-file {
  margin: 0 1.5rem 1.5rem;
  padding: 1rem;
  border: 2px dashed #dee2e6;
  background-color: #f7f7f9;
}
.upload-file-wrap .upload-file .filenames-wrap {
  font-size: 8px;
}
.upload-file-wrap .upload-file .filenames-wrap .filenames {
  display: flex;
  flex-wrap: wrap;
  height: 100% !important;
  justify-content: center;
}
.modal-contents .upload-file-wrap .upload-file label.btn {
  cursor: pointer;
  color: gray;
  border: 1px solid gray;
}
.modal-contents .upload-file-wrap .upload-file label.btn:hover {
  cursor: pointer;
  background: #fff !important;
  color: gray !important;
  border: 1px solid gray !important;
}
.modal-contents .upload-file-wrap .upload-file a.btn:hover {
  background: #fff !important;
  color: #f3154a !important;
  border: 1px solid #f3154a !important;
}
.modal-contents .upload-file-wrap .upload-file label.btn.disabled,
.modal-contents .upload-file-wrap .upload-file a.btn.disabled {
  cursor: not-allowed;
  pointer-events: auto;
}
.upload-file-wrap .upload-file.enter {
  border: 2px dashed #dee2e6;
  background-color: #dee2e6;
}
.upload-file-wrap h5,
.report-list-wrap h5 {
  margin: 1.5rem 1.5rem 1rem;
}
.report-list-wrap .report-list .list {
  margin: 0 1.5rem;
  padding: 1rem;
  border: 1px solid #dee2e6;
}
.report-list-wrap .report-list .list .actions {
  float: right;
}
.report-list-wrap .report-list .list .actions a {
  font-size: 0.3rem;
  padding: 9px;
}
.report-list-wrap .report-list .list .actions .del-btn {
  color: gray;
  border: 1px solid gray;
}
.empty-list {
  margin: 1.5rem;
}
.alert-wrap {
  margin: 0 1.5rem 0;
}
.cloud-upload-img img {
  max-width: 150px;
}
</style>
