import Vue from 'vue';

// -- bootstrap vue --
import 'bootstrap';
import BootstrapVue from 'bootstrap-vue';

// -- application scss --
import '../assets/stylesheets/application.scss';

// -- font awesome --
import '@fortawesome/fontawesome-free/js/all';

// -- mdi --
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(BootstrapVue);
