<template lang="pug">
  nav#sidebar.sidebar.sidebar-offcanvas.dynamic-active-class-disabled
    ul.nav
      li.nav-item
        a.nav-link(data-toggle="collapse" href="#dashboard-dropdown" aria-expanded="false" aria-controls="dashboard-dropdown")
          i.menu-icon.mdi.mdi-speedometer
          span.menu-title
            | {{ $t('user.leftNav.dashboard') }}
          i.menu-arrow
        #dashboard-dropdown.collapse
          ul.nav.flex-column.sub-menu
            li.nav-item
              router-link.nav-link(:to="{ name: 'p_dashboard' }")
                | {{ $t('user.leftNav.overview') }}

</template>

<script>
export default {
  components: {},

  props: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {}
};
</script>
