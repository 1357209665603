<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content.vc-inquiries
        .vc-main-container.container
          .vc-content-col
            .container
              .row
                .col-12
                  h3.vc-midashi.mb-3
                    | チャットインタビュー一覧
                  hr.vc-full-divider
                .col-md-12.grid-margin.stretch-card.clearfix(v-if="started")
                  .table-responsive(v-if="inquiries.length > 0")
                    table.table.table-striped.table-fixed
                      thead
                        tr
                          th(width="130px") 調査
                          th(width="100px") 回答者ID
                          th(width="130px") 所属
                          th 意見
                          th(width="130px") ステータス
                          th(width="100px") 質問者
                          th(width="100px") 開始日時
                          th(width="150px") アクション
                          th(width="50px") 未読
                      tbody
                        tr(v-for="inquiry in inquiries")
                          td
                            | {{ inquiry.survey_name }}
                          td
                            | {{ inquiry.responder ? inquiry.responder.code : '-' }}
                          td
                            | {{ inquiry.organization_name }}
                          td(v-if="inquiry.survey_response")
                            | {{ truncate(inquiry.survey_response.contents, 50) }}
                          td(v-else)
                            | {{ inquiry.quantitative_response.quantitative_answer.select_num + '：' +
                            | inquiry.quantitative_response.quantitative_answer.select_text }}
                          td(:class="inquiry.status")
                            | {{ status[inquiry.status] }}
                          td
                            | {{ inquiry.user ? inquiry.user.email : '-' }}
                          td
                            | {{ inquiry.created_at }}
                          td
                            button.btn.btn-primary.vc-primary-btn.btn-block.vc-action-btn(
                              :class="{'btn-disabled': ['created', 'invited', 'declined'].includes(inquiry.status)}"
                              @click.prevent="toChatRoom(inquiry)"
                            )
                              | {{ btnText(inquiry.status) }}
                          td.text-right
                            i.fas.fa-exclamation-circle.unread-icon(v-show="inquiry.unread")

                  //- .mt-1.float-right
                  //-   paginate(
                  //-     :page-count="pageParams.pages"
                  //-     :click-handler="clickCallback"
                  //-     containerClass="pagination"
                  //-     pageClass="page-item"
                  //-     pageLinkClass="page-link"
                  //-     prevText="«"
                  //-     prevClass="page-item"
                  //-     prevLinkClass="page-link"
                  //-     nextText="»"
                  //-     nextClass="page-item"
                  //-     nextLinkClass="page-link"
                  //-   )

                  div(v-else)
                    p チャットインタビュー許諾依頼がありません。
                .col-md-12.grid-margin.stretch-card.clearfix(v-else)
                  p 読み込み中です。しばらくお待ちください。

</template>

<script>
import { mapState, mapActions } from 'vuex';
import SurveyOrganizationSelect from '../../../components/u/SurveyOrganizationSelect';

export default {
  components: {
    SurveyOrganizationSelect
  },

  data() {
    return {
      started: false,
      status: {
        created: 'チャット未依頼',
        invited: 'チャット依頼中',
        loggedin: 'チャット許諾済',
        answering: 'チャット中',
        completed: 'チャット完了',
        reopened: 'チャット依頼中',
        stopped: 'チャット停止',
        declined: 'チャット不可'
      }
    };
  },

  computed: {
    ...mapState('resources/u_inquiries', ['inquiries'])
  },

  watch: {},

  created() {
    this.loadInquiries().then(() => {
      this.started = true;
    });
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_inquiries', ['loadInquiries']),
    btnText(status) {
      if (status === 'completed') return '結果確認';
      else return 'チャット';
    },
    toChatRoom(inquiry) {
      location.href = 'inquiries/' + inquiry.code + '/chat_interviews';
    },
    truncate(str, len) {
      return str.length <= len ? str : str.substr(0, len) + '...';
    }
  }
};
</script>
<style scoped>
.vc-inquiries {
  height: calc(100vh - 63px);
}
.vc-inquiries .vc-inquiries-wrap {
  width: 95%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
.vc-inquiries .vc-content-col,
.vc .vc-inquiries .card {
  box-shadow: none;
}
.table-fixed {
  table-layout: fixed;
}
.table-fixed td {
  white-space: break-spaces;
  word-break: break-all;
  font-weight: 400;
}
.vc-action-btn {
  font-size: 0.7rem;
  padding: 7px !important;
}
.midashi-wrap,
.table-wrap {
  margin: 0 70px;
}
.unread-icon {
  color: #00a897;
}
td.created {
  color: #999;
}
td.invited,
td.reopened {
  font-weight: bold;
}
td.loggedin {
  color: #00c3b1;
  font-weight: bold;
}
td.answering {
  font-weight: bold;
}
td.declined {
  color: #cf5555;
  font-weight: bold;
}
</style>
