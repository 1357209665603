import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_analyse_compare');

export default {
  namespaced: true,

  state: {
    emotion_classifications: [],
    source: {},
    destinations: [],
    emotionClassificationTopic: [],
    sourceTopic: {},
    destinationTopics: [],
    popupPosition: {}
  },

  getters: {},

  actions: {
    /**
     * 分析対象の意見分類の比較データ取得
     */
    loadOpinionTendencies({ commit }, params) {
      return gateway
        .get({ action: 'opinion_tendencies' }, params)
        .then(response => {
          if (response.status === 200) {
            commit(
              'setEmotionClassifications',
              response.body.emotion_classifications
            );
            commit('setSource', response.body.source);
            commit('setDestination', response.body.destinations);
          }
          return response;
        });
    },
    /**
     * 分析対象の意見分類の比較で表示するトピックデータ取得
     */
    loadTopic({ commit }, params) {
      return gateway.get({ action: 'topic' }, params).then(response => {
        if (response.status === 200) {
          commit(
            'setEmotionclassificationTopic',
            response.body.emotion_classifications
          );
          commit('setSourceTopic', response.body.source);
          commit('setDestinationTopics', response.body.destinations);
        }
        return response;
      });
    }
  },

  mutations: {
    setEmotionClassifications(state, emotion_classifications) {
      state.emotion_classifications = emotion_classifications;
    },
    setSource(state, source) {
      state.source = source;
    },
    setDestination(state, destinations) {
      state.destinations = destinations;
    },
    setEmotionclassificationTopic(state, emotion_classifications) {
      state.emotionClassificationTopic = emotion_classifications;
    },
    setSourceTopic(state, source) {
      state.sourceTopic = source;
    },
    setDestinationTopics(state, destinations) {
      state.destinationTopics = destinations;
    },
    updatePopupPosition(state, rect) {
      state.popupPosition = rect;
    }
  }
};
