<template lang="pug">
  b-modal(
    id="inquiry-delete-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    :title="$t('admin.inquiries.deleteModal.title')"
  )
    b-container
      p
        | アカウントグループ：{{ deleteTarget.account_group_name }}
        br
        | 調査名：{{ deleteTarget.survey_name }}
        br
        | 回答者ID：{{ deleteTarget.responder ? deleteTarget.responder.code : '-' }}
        br
        | 質問者：{{ deleteTarget.user ? deleteTarget.user.email : '-' }}
      p
        | {{ $t('admin.inquiries.deleteModal.confirm') }}
    div(slot="modal-footer")
      button.btn.btn-secondary(@click="close")
        | {{ $t('actions.close') }}
      button.btn.btn-danger.ml-2(@click.prevent="destroy")
        | {{ $t('actions.delete') }}
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    deleteTarget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions('resources/x_inquiries', ['deleteInquiry']),

    close() {
      this.$bvModal.hide('inquiry-delete-modal');
    },

    destroy() {
      this.deleteInquiry(this.deleteTarget.id).then(response => {
        if (response.status === 200) {
          this.$bvModal.hide('inquiry-delete-modal');
          this.$router.go({ name: 'x_inquiries' });
        }
      });
    }
  }
};
</script>
