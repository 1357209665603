<template lang="pug">
  .content-wrapper
    .row.flex-grow
      .col-6
        .card
          .card-body
            h4.card-title
              | {{ $t('admin.users.new.title') }}

            form
              error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
              validation-observer(v-slot="{ invalid }")
                validation-provider(name="name" rules="required" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.users.name') }}
                    input(
                      type="text"
                      placeholder="Suzuki Taro"
                      :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      v-model="name"
                    )
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                validation-provider(name="email" rules="required|email" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.users.email') }}
                    input(
                      type="email"
                      placeholder="admin@example.com"
                      :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      v-model="email"
                    )
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                validation-provider(name="accountGroupId" rules="required" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.users.accountGroup') }}
                    select(
                      :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      v-model="accountGroupId"
                    )
                      option(
                        v-for="accountGroup in accountGroups"
                        v-bind:value="accountGroup.id"
                        v-bind:key="accountGroup.id"
                      )
                        | {{accountGroup.name}}
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                .form-group
                  label
                    | {{ $t('admin.users.organization') }}
                  select.form-control(
                    v-model="organizationId"
                  )
                    optgroup(
                      v-for="organizations in versionOrganizations"
                      v-bind:label="organizations[0].organization_version_name"
                      )
                      option(
                        v-for="organization in organizations"
                        v-bind:value="organization.id"
                        v-bind:key="organization.id"
                      )
                        | {{organization.code}} {{organization.name}}

                validation-provider(name="roleId" rules="required" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.users.role') }}
                    select(
                      :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      v-model="roleId"
                    )
                      option(
                        v-for="role in roles"
                        v-bind:value="role.id"
                        v-bind:key="role.id"
                      )
                        | {{role.name}}
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                .mt-1.float-right
                  router-link.btn.btn-light.mr-2(:to="{ name: 'x_users' }")
                    | {{ $t('actions.cancel') }}
                  button.btn.btn-success(
                    :disabled="processing || invalid"
                    @click.prevent="submit"
                  )
                    | {{ $t('actions.create') }}

</template>

<script>
import { mapState, mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';

export default {
  components: {
    ErrorMessage
  },

  data() {
    return {
      name: '',
      email: '',
      errorMessages: [],
      processing: false,
      versionOrganizations: {},
      accountGroupId: '',
      organizationId: '',
      roleId: ''
    };
  },

  computed: {
    ...mapState('resources/x_account_groups', [
      'accountGroups',
      'organizations',
      'roles'
    ]),
    /**
     * サーバサイドに渡す user のパラメータを生成
     *
     * @return {object}
     */
    userParams() {
      return {
        user: {
          name: this.name,
          email: this.email,
          account_group_id: this.accountGroupId,
          organization_id: this.organizationId,
          role_id: this.roleId
        }
      };
    }
  },

  watch: {
    accountGroupId: function() {
      this.loadOrganizations(this.accountGroupId);
      this.loadRoles(this.accountGroupId);
    },
    organizations: function() {
      var versionOrganizations = {};
      for (let organization of this.organizations) {
        if (!versionOrganizations[organization.organization_version_id])
          versionOrganizations[organization.organization_version_id] = [];
        versionOrganizations[organization.organization_version_id].push(
          organization
        );
      }
      this.versionOrganizations = versionOrganizations;
    }
  },

  created() {
    this.load();
  },

  methods: {
    ...mapActions('resources/x_account_groups', [
      'loadAccountGroups',
      'loadOrganizations',
      'loadRoles'
    ]),
    ...mapActions('resources/x_users', ['createUser', 'addAccountGroup']),

    load() {
      this.loadAccountGroups();
    },

    resetErrorMessages() {
      this.errorMessages = [];
    },

    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.createUser(this.userParams)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'x_users' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
