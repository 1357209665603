<template lang="pug">
  .container-fruid
    .main-panel(v-if="accountGroupName != ''")
      section.vc-content
        .vc-main-container.container
          .vc-content-col#userList
            .container
              .row
                .col-12
                  h3.vc-midashi.mb-3
                    | ユーザー管理
                  hr.vc-full-divider
                .col-6
                  h3.vc-midashi
                    | アカウントグループ: 
                    | {{ accountGroupName }}
                .col-6.ml-0.ml-md-auto.my-auto.d-flex.align-items-center.pt-4.pt-md-0
                  router-link.btn.btn-primary.vc-primary-btn.btn-sm.ml-auto(:to="{ name: 'u_new_user' }")
                    | 新しくユーザーを招待する
              .row
                .col-md-12.grid-margin.stretch-card.clearfix
                  .card
                    .card-body.pb-0
                      .table-responsive
                        table.table.table-striped
                          thead
                            tr
                              th
                                | メールアドレス
                              th
                                | ユーザー名
                              //- th
                              //-   | 所属組織
                              th
                                | ステータス
                              th
                                | ロック
                              th
                                | 編集
                          tbody
                            tr(v-for="user in usersList")
                              td
                                | {{ user.email }}
                              td
                                | {{ user.name }}
                              //- td
                              //-   | {{ user.organization ? user.organization.name : '-' }}
                              td
                                span(v-if="user.invitation_accepted_at")
                                  | {{ user.current_sign_in_at | moment('YYYY.MM.DD')}} 
                                  | ログイン
                                span(v-else)
                                  | 未ログイン
                              td
                                span(v-if="user.locked_at" v-show="user.locked_at")
                                  i.fa.fa-lock.font-size-12
                                span(v-else)
                                  | -
                              td
                                router-link(
                                  :to="{ name: 'u_edit_user', params : { id: user.id } }")
                                    i.fas.fa-edit.font-size-12

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {};
  },

  computed: {
    ...mapState('resources/u_users', ['usersList', 'accountGroupName'])
  },

  created() {
    this.loadUsers();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_users', ['loadUsers'])
  }
};
</script>
