<template lang="pug">
  b-modal(
    id="survey-activate-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    :title="$t('admin.surveys.activate.title')"
  )
    b-container
      p
        | {{$t('actions.confirm')}}
      p
        span.text-danger
          | {{ activateTarget.name }}
    div(slot="modal-footer")
      button.btn.btn-secondary(@click="close")
        | {{$t('actions.close')}}
      button.btn.btn-primary.ml-2(@click.once="activate")
        | {{$t('actions.activate')}}
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    activateTarget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions('resources/x_surveys', ['activateSurvey']),

    close() {
      this.$bvModal.hide('survey-activate-modal');
    },

    activate() {
      this.activateSurvey(this.activateTarget.id).then(response => {
        if (response.status === 200) {
          this.$bvModal.hide('survey-activate-modal');
          this.$router.go({ name: 'x_surveys' });
        }
      });
    }
  }
};
</script>
