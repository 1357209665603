import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_roles');

export default {
  namespaced: true,

  state: {
    roleName: null,
    capabilityCode: [],
    roles: []
  },

  getters: {},

  actions: {
    /**
     * 権限情報取得
     */
    loadRoles({ commit }) {
      return gateway.get({ action: 'index' }).then(response => {
        if (response.status === 200) {
          const role_name = response.body.role_name;
          commit('setRoleName', role_name);
          const capability_code = response.body.capability_code;
          commit('setCapabilityCode', capability_code);
          const roles = response.body.roles;
          commit('setRoles', roles);
        }
        return response;
      });
    }
  },

  mutations: {
    setRoleName(state, role_name) {
      state.roleName = role_name;
    },
    setCapabilityCode(state, capability_code) {
      state.capabilityCode = capability_code;
    },
    setRoles(state, roles) {
      state.roles = roles;
    }
  }
};
