import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_newsletters');

export default {
  namespaced: true,

  state: {
    newsletters: [],
    currentPage: 0,
    accountGroups: [],
    surveys: {},
    templates: [],
    newsletter: {},
    bodyTxt: ''
  },

  getters: {},

  actions: {
    loadNewsletters({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          let newsletters = response.body.newsletters;
          let currentPage = response.body.current_page;
          commit('setNewsletters', newsletters);
          commit('setCurrentPage', currentPage);
        }
        return response;
      });
    },
    deleteNewsletter(_, id) {
      return gateway.delete({ action: 'remove', id }).then(response => {
        return response;
      });
    },
    loadSurveys({ commit }) {
      return gateway.get({ action: 'surveys' }).then(response => {
        if (response.status === 200) {
          let accountGroups = response.body.account_groups;
          let surveys = response.body.surveys;
          commit('setAccountGroups', accountGroups);
          commit('setSurveys', surveys);
        }
        return response;
      });
    },
    loadTemplates({ commit }) {
      return gateway.get({ action: 'templates' }).then(response => {
        if (response.status === 200) {
          let templates = response.body.templates;
          commit('setTemplates', templates);
        }
        return response;
      });
    },
    editNewsletter({ commit }, id) {
      return gateway.get({ action: 'edit', id }).then(response => {
        if (response.status === 200) {
          let newsletter = response.body.newsletter;
          commit('setNewsletter', newsletter);
        }
        return response;
      });
    },
    previewNewsletter({ commit }, id) {
      return gateway.get({ action: 'preview', id }).then(response => {
        if (response.status === 200) {
          let newsletter = response.body.newsletter;
          commit('setNewsletter', newsletter);
        }
        return response;
      });
    },
    sendNewsletter(_, id) {
      return gateway.post({ action: 'send', id }).then(response => {
        return response;
      });
    },
    testSendNewsletter(_, params) {
      return gateway
        .post({ action: 'test_send', id: params.id }, params)
        .then(response => {
          return response;
        });
    },
    copyNewsletter(_, id) {
      return gateway.post({ action: 'copy', id }).then(response => {
        return response;
      });
    }
  },

  mutations: {
    setNewsletters(state, newsletters) {
      state.newsletters = newsletters;
    },
    setCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
    },
    setAccountGroups(state, accountGroups) {
      state.accountGroups = accountGroups;
    },
    setSurveys(state, surveys) {
      state.surveys = surveys;
    },
    setTemplates(state, templates) {
      state.templates = templates;
    },
    setNewsletter(state, newsletter) {
      state.newsletter = newsletter;
    }
  }
};
