import Vue from 'vue';
import VueRouter from 'vue-router';
import defaults from './defaults';

Vue.use(VueRouter);

const routes = [...defaults];

const router = new VueRouter({ base: '/', mode: 'history', routes });

export default router;
