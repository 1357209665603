<template lang="pug">
  .vc-main-container.container
    .vc-topic-content.mb-4
      .vc-left-col
        QualitativeAttributeValues(:surveyId="surveyId")
    .vc-content-col
      div(v-if="attributeValueIds.length < 1")
        | 属性を1つ以上選択してください
      div(v-else-if="survey_response_count <= getNumberOfDisplayableData()")
        | 回答が{{getNumberOfDisplayableData()}}件以下は表示できません。組織を選びなおしてください
      div(v-else)
        .row.mt-2.mb-4
          .col-5
            ul.vc-parameter-nav
              li
                a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': displayType === 1}" @click="setDisplayType(1)") 率（同属性内）
              li
                a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': displayType === 0}" @click="setDisplayType(0)") 率（全体）
              li
                a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': displayType === 2}" @click="setDisplayType(2)") 件数
          .col-7.text-right.mt-3.pr-3
            span.small(v-show="[0, 1].includes(displayType)")
              | ※ 割合の算出方法：{{calculateFormula()}}
        OpinionTendenciesUserTeam(
          @modalOpen="modalOpen"
          @modalOpenNS="modalOpenNS"
          @toOpinionSearch="toOpinionSearch"
          :survey-id="surveyId"
          :display-type="displayType"
          :is-organizations-page="false"
        )
        hr.my-team-divider
        OpinionTrendsUserAttributeValue(
          @modalOpen="modalOpen"
          @modalOpenNS="modalOpenNS"
          @toOpinionSearch="toOpinionSearch"
          :survey-id="surveyId"
          :display-type="displayType"
          :is-organizations-page="false"
        )
    ModalOpinionAttrValUserTeam(ref="modalOpinionAttrVal")
    ModalNsOpinionAttrValUserTeam(ref="modalNsOpinionAttrVal")
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import QualitativeAttributeValues from '../../../components/u/qualitative/QualitativeAttributeValues';
import OpinionTendenciesUserTeam from '../../../components/u/analyse/OpinionTendenciesUserTeam';
import OpinionTrendsUserAttributeValue from '../../../components/u/analyse/OpinionTrendsUserAttributeValue';
import ModalOpinionAttrValUserTeam from '../../../components/u/analyse/ModalOpinionAttrValUserTeam';
import ModalNsOpinionAttrValUserTeam from '../../../components/u/analyse/ModalNsOpinionAttrValUserTeam';
import dc from '../../../lib/default_cookie';

const defaultCookie = new dc();

export default {
  components: {
    QualitativeAttributeValues,
    OpinionTendenciesUserTeam,
    OpinionTrendsUserAttributeValue,
    ModalOpinionAttrValUserTeam,
    ModalNsOpinionAttrValUserTeam
  },

  data() {
    return {
      selectOpinionClass: 'opinion_count',
      displayType: null
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('resources/u_analyse_team', ['survey_response_count']),
    ...mapState('resources/u_opinion_search', ['keepedAttributeValues']),
    ...mapState('pages/u_surveys/select', ['surveyId']),
    ...mapState('resources/u_quantitative', [
      'questionId',
      'attributeValueIds'
    ]),
    ...mapState('pages/u_organizations/select', ['started'])
  },

  watch: {
    displayType: 'updateDisplayTypeCookie',
    surveyId: 'handleSurveyIdChange',
    questionId: 'handleQuestionIdChange',
    attributeValueIds: 'handleAttributeValueIdsChange'
  },

  created() {
    this.initializeComponent();
  },

  methods: {
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapActions('resources/u_analyse_team', [
      'loadOpinionTendencies',
      'loadOpinionTrends'
    ]),
    ...mapActions('resources/u_quantitative', ['loadQuestions']),
    ...mapMutations('pages/u_organizations/select', ['setStarted']),

    getNumberOfDisplayableData() {
      const name = 'number_of_displayable_data';
      if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
        return this.controlSettings[name]['values']
          ? this.controlSettings[name]['values']
          : 0;
      }
      return 0;
    },

    modalOpen(surveyId) {
      const params = {
        survey_id: surveyId,
        attribute_value_ids: this.attributeValueIds
      };
      this.$refs.modalOpinionAttrVal.modalOpen(params);
    },

    modalOpenNS(surveyId, opinionClassificationId) {
      const params = {
        survey_id: surveyId,
        organization_id: this.organizationId,
        opinion_classification_id: opinionClassificationId,
        attribute_value_ids: this.attributeValueIds
      };
      this.$refs.modalNsOpinionAttrVal.modalOpen(params);
    },

    toOpinionSearch(
      surveyId,
      opinionClassificationId,
      emotionClassificationId,
      _organizationId,
      attributeValueId
    ) {
      this.$router.push({
        name: 'u_qualitative_opinion_search',
        params: {
          opinion_classification: opinionClassificationId,
          emotion_classification: emotionClassificationId,
          attribute_values: [attributeValueId],
          survey_id: surveyId,
          question_id: this.questionId,
          backToName: 'u_qualitative_emotion_classifications_attribute_values'
        }
      });
    },

    handleAttributeValueIdsChange() {
      this.changeAttributeValueIds();
    },

    handleSurveyIdChange() {
      if (this.started) this.loadQuestions({ survey_id: this.surveyId });
    },

    handleQuestionIdChange() {
      if (this.started) this.changeAttributeValueIds();
    },

    updateDisplayTypeCookie() {
      const vcd = defaultCookie.get();
      if (vcd['analyze_team_displayType'] !== this.displayType) {
        defaultCookie.set({ displayType: this.displayType });
      }
    },

    changeAttributeValueIds() {
      const vcd = defaultCookie.get();
      const params = {
        survey_id: vcd['surveyId'] || this.surveyId,
        question_id: this.questionId,
        attribute_value_ids: this.attributeValueIds,
        search_by: 'attribute_values'
      };
      this.loadOpinionTendencies(params);
      this.loadOpinionTrends(params);
      this.setStarted(true);
    },

    setDisplayType(type) {
      this.displayType = type;
    },

    setDefaultDisplayType() {
      const vcd = defaultCookie.get();
      this.displayType = vcd['displayType'] || 0;
    },

    calculateFormula() {
      return this.displayType === 0
        ? '各属性の感情分類ごとの意見数/全属性の総意見数'
        : '各属性における「感情分類ごとの意見数/全意見数」';
    },

    initializeComponent() {
      this.loadControlSettings();
      this.setStarted(false);
      this.setDefaultDisplayType();
      const vcd = defaultCookie.get();
      if (vcd['surveyId'] || this.surveyId) {
        this.loadQuestions({ survey_id: vcd['surveyId'] || this.surveyId });
      }
      if (this.questionId) this.changeAttributeValueIds();
    }
  }
};
</script>

<style scoped>
.vc-parameter-nav .vc-ranking-btn {
  text-align: center;
}

.vc ul.vc-parameter-nav li a.vpn-all:hover {
  background: rgba(243, 21, 74, 0.5) !important;
}

.vc ul.vc-parameter-nav li a.vpn-all.active:hover {
  background: rgba(243, 21, 74, 1) !important;
}

.vc ul.vc-parameter-nav li a.vpn-all.active {
  background: #f3154a !important;
}
</style>
