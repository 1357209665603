<template lang="pug">
  b-modal(
    id="import-file-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    hide-header-close=true
    title="ファイルアップロード"
  )
    b-container
      p.font-weight-bold.mb-2
        | 選択したファイル
      p.mb-4
        | {{ fileName }}
      p
        | 上記のファイルをアップロードします。よろしいですか？
    div(slot="modal-footer")
      button.btn.btn-secondary(@click="close")
        | {{$t('actions.cancel')}}
      button.btn.btn-primary.ml-2(@click.once="importFile")
        | {{$t('actions.upload')}}
</template>

<script>
export default {
  props: {
    fileName: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    modalOpen() {
      this.$bvModal.show('import-file-modal');
    },

    close() {
      this.$emit('cancelFile');
      this.$bvModal.hide('import-file-modal');
    },

    importFile() {
      this.$emit('importCsv');
      this.$bvModal.hide('import-file-modal');
    }
  }
};
</script>
