<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | {{ $t('admin.coOccurrenceNetworks.new.title') }}
  .row.flex-grow
    .col-8
      .card
        .card-body.vc
          form
            .alert.alert-danger(v-if="errorMessages.length > 0")
              ul.mb-0
                li(v-for="error in errorMessages")
                  | {{ error }}
            ul.alert.alert-success(v-show="message")
                button.close.vc-message-close(@click="resetMessages")
                  span(aria-hidden='true') ×
                li.ml-1
                  | {{ message }}
            validation-observer(v-slot="{ invalid }")
              validation-provider(name="name" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.coOccurrenceNetworks.new.label.projectName') }}
                  input.mb-2(
                    type="text"
                    placeholder="プロジェクト名をご入力ください"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="projectName"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}
                .form-group.mt-4(:class="{ 'has-danger': errors.length}")
                  label
                    .mb-1
                      | {{ $t('admin.coOccurrenceNetworks.new.label.upload') }}
                    .mt-1
                      small
                        | {{ $t('admin.coOccurrenceNetworks.new.label.uploadDescription') }}
              .row.justify-content-end
                .col-12.text-right
                  div
                    label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                      input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event)")
                  div
                    a.link(href="" @click.prevent="exportTempCsv")
                      | テンプレートCSVをダウンロード
                  div(v-if="isFile")
                    p
                      | {{ 'アップロード済み： ' + currentFilename }}
              .row.justify-content-end.transition-link-wrap
                .col-4.text-right
                  a.link.transition-link(href="" @click.prevent="submit" :class="{'disable-link': processing || invalid || !isFile}")
                      |  形態素解析実行画面へ

  ImportFileModal(ref="importFileModal" @importCsv="importCsv" :file-name="currentFilename" @cancelFile="cancelFile")

</template>
<script>
import { mapState, mapActions } from 'vuex';
import i18n from '../../../locales/index';
import ImportFileModal from '../../../components/x/co_occurrence_networks/ImportFileModal';
import moment from 'moment';
import CDL from '../../../lib/csv_download';
const CSVDownload = new CDL();

export default {
  components: {
    ImportFileModal
  },
  data() {
    return {
      projectName: '',
      errorMessages: '',
      message: '',
      csvFile: '',
      currentFilename: '',
      processing: false,
      isFile: false
    };
  },
  computed: {
    ...mapState('resources/x_co_occurrence_networks', ['network']),
    csvParams() {
      const formData = new FormData();
      formData.append('csv_file', this.csvFile);
      formData.append('filename', this.currentFilename);

      return formData;
    }
  },
  created() {
    this.load();
  },
  mounted() {},
  methods: {
    ...mapActions('resources/x_co_occurrence_networks', [
      'loadNetwork',
      'updateNetwork'
    ]),
    load() {
      const params = {
        id: this.$route.params.id
      };
      this.loadNetwork(params).then(response => {
        if (response.status === 200) {
          const filename = response.body.filename;
          const projectName = response.body.network.project_name;
          if (filename) {
            this.isFile = true;
            this.currentFilename = filename;
            this.projectName = projectName;
          }
        }
      });
    },
    submit() {
      if (!this.projectName || !this.isFile) return false;
      this.processing = true;
      const params = {
        id: this.network.id,
        project_name: this.projectName
      };
      this.updateNetwork(params).then(response => {
        if (response.status === 200) {
          this.$router.push({
            name: 'x_request_api_co_occurrence_networks',
            params: { id: this.network.id }
          });
        }
      });
    },
    onFileChange(e) {
      this.processing = true;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.csvFile = files[0];
      this.currentFilename = files[0].name;
      this.modalOpen();
      this.processing = false;
      this.isFile = true;
    },
    fileInitialize(e) {
      e.target.value = null;
    },
    exportTempCsv() {
      this.processing = true;
      let head = ['id', 'opinion'];

      CSVDownload.downloadCsv(
        head,
        [],
        'voiscope_quantitative_responses_' +
          moment().format('YYYYMMDD_HHmmss') +
          '.csv'
      );
      this.processing = false;
    },
    modalOpen(fileType) {
      this.$refs.importFileModal.modalOpen(fileType);
    },
    importCsv() {
      this.processing = true;
      this.resetMessages();
      this.message = i18n.tc('admin.surveys.messages.processing');

      let path =
        '/api/x/co_occurrence_networks/' + this.network.id + '/import_csv';
      fetch(path, {
        method: 'PUT',
        body: this.csvParams
      }).then(response => {
        if (response.status === 200) {
          this.processing = false;
          this.resetMessages();
        } else if (response.status === 422) {
          response.json().then(body => {
            this.errorMessages = body.errors;
            this.cancelFile();
          });
        }
      });
      this.processing = true;
    },
    resetMessages() {
      this.errorMessages = [];
      this.message = '';
    },
    cancelFile() {
      this.isFile = false;
      this.currentFilename = '';
    }
  }
};
</script>
<style scoped>
.action-btns {
  width: 200px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.link {
  color: #4bafaf;
  font-size: 0.85rem;
}
.link:hover {
  color: #4bafaf;
}
.transition-link {
  font-size: 1rem;
}
.transition-link-wrap {
  margin-top: 70px;
}
.disable-link {
  cursor: not-allowed;
}
</style>
