<template lang="pug">
  .alert.alert-success.alert-dismissible.mb-0(v-show='innerMessage')
    | {{ innerMessage }}
    button.close(
      @click.prevent="hideMessage"
    )
      span(aria-hidden="true")
        | &times;
</template>

<script>
export default {
  props: {
    message: { type: String, required: true }
  },

  computed: {
    innerMessage: {
      get() {
        return this.message;
      },
      set(msg) {
        this.$emit('update:message', msg);
      }
    }
  },

  methods: {
    hideMessage() {
      // 子の変更を親に伝える
      this.innerMessage = '';
    }
  }
};
</script>

<style scoped lang="scss"></style>
