var Encoding = require('encoding-japanese');

export default class CSVDownload {
  downloadCsv(head, items, filename) {
    var csv = '';
    for (const item of items) {
      csv += item.join(',') + '\n';
    }
    csv = head.join(',') + '\n' + csv;
    csv = new Encoding.stringToCode(csv);
    csv = Encoding.convert(csv, 'SJIS');
    csv = new Uint8Array(csv);
    this.downloadCsvBlob(csv, filename);
  }

  downloadCsvBlob(blob, fileName) {
    if (window.navigator.msSaveOrOpenBlob) {
      // for IE,Edge
      var blob = new Blob([blob], { type: 'text/csv' });
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      // for chrome, firefox
      const url = URL.createObjectURL(new Blob([blob], { type: 'text/csv' }));
      const linkEl = document.createElement('a');
      linkEl.href = url;
      linkEl.setAttribute('download', fileName);
      document.body.appendChild(linkEl);
      linkEl.click();
      URL.revokeObjectURL(url);
      linkEl.parentNode.removeChild(linkEl);
    }
  }
}
