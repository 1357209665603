export default {
  namespaced: true,

  modules: {},

  state: {
    beforeSelected: [],
    started: false
  },

  getters: {},

  mutations: {
    setSelect(state, payload) {
      if (payload.beforeSelected) {
        state.beforeSelected = payload.beforeSelected;
      }
      if (payload.started) {
        state.started = payload.started;
      }
    },
    setStarted(state, started) {
      state.started = started;
    }
  }
};
