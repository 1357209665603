<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content
        SurveyOrganizationSelect(@input="changeSurveyOrganization")
        .vc-main-container.container(v-if="survey_response_count != null && !isTopic")
          .vc-left-col
            AttributeValuesSelect(@input="changeAttributeValueIds" ref="attributeValuesSelect")
          .vc-content-col
            div(v-if="survey_response_count <= getNumberOfDisplayableData()")
              | 回答が{{getNumberOfDisplayableData()}}件以下は表示できません。組織を選びなおしてください
            div(v-else)
              .row.mt-2.mb-4
                .col-3
                  ul.vc-parameter-nav.text-left
                    li
                      a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': displayType === 0}" @click="setDisplayType(0)") 率
                    li
                      a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': displayType === 1}" @click="setDisplayType(1)") 件数
                .col-9.text-right.mt-3.pr-3
                  span.small
                    | ※ 割合の算出方法：該当チームのある意見分類に関する全体意見数 ／ 該当チームの全体意見数
              OpinionTendenciesUserTeam(
                @modalOpen="modalOpen"
                @modalOpenNS="modalOpenNS"
                @toOpinionSearch="toOpinionSearch"
                :survey-id="surveyId"
                :display-type="displayType"
              )
              hr.my-team-divider
              OpinionTrendsUserTeam(
                @modalOpen="modalOpen"
                @modalOpenNS="modalOpenNS"
                @toOpinionSearch="toOpinionSearch"
                :survey-id="surveyId"
                :display-type="displayType"
              )
              TopicUserTeam(:survey-id="surveyId")
        .vc-main-container.container(v-if="isTopic")
          .vc-content-col
            .container
              | トピック調査については、この画面はご利用できません。調査選択から、トピック調査以外の調査を選択ください。
    ModalOpinionAttrValUserTeam(ref="modalOpinionAttrVal")
    ModalNsOpinionAttrValUserTeam(ref="modalNsOpinionAttrVal")

</template>

<script>
import { mapState, mapActions } from 'vuex';
import SurveyOrganizationSelect from '../../../components/u/SurveyOrganizationSelect';
import OpinionTendenciesUserTeam from '../../../components/u/analyse/OpinionTendenciesUserTeam';
import OpinionTrendsUserTeam from '../../../components/u/analyse/OpinionTrendsUserTeam';
import TopicUserTeam from '../../../components/u/analyse/TopicUserTeam';
import ModalOpinionAttrValUserTeam from '../../../components/u/analyse/ModalOpinionAttrValUserTeam';
import ModalNsOpinionAttrValUserTeam from '../../../components/u/analyse/ModalNsOpinionAttrValUserTeam';
import AttributeValuesSelect from '../../../components/u/analyse/AttributeValuesSelect';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    SurveyOrganizationSelect,
    OpinionTendenciesUserTeam,
    OpinionTrendsUserTeam,
    TopicUserTeam,
    ModalOpinionAttrValUserTeam,
    ModalNsOpinionAttrValUserTeam,
    AttributeValuesSelect
  },

  data() {
    return {
      selectOpinionClass: 'opinion_count',
      surveyId: null,
      isTopic: false,
      organizationId: null,
      attributeValueIds: [],
      attributeValueNames: [],
      displayType: null
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('resources/u_analyse_team', ['survey_response_count']),
    ...mapState('resources/u_opinion_search', ['keepedAttributeValues'])
  },

  watch: {
    displayType: function() {
      const vcd = defaultCookie.get();
      if (vcd['analyze_team_displayType'] !== this.displayType)
        defaultCookie.set({ displayType: this.displayType });
    }
  },

  created() {
    this.loadControlSettings();
    this.setDefaultDisplayType();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapActions('resources/u_analyse_team', [
      'loadOpinionTendencies',
      'loadOpinionTrends',
      'loadTopic'
    ]),
    changeSurveyOrganization: function(datas) {
      this.surveyId = datas.surveyId;
      this.organizationId = datas.organizationId;
      if (this.keepedAttributeValues.length > 0)
        this.attributeValueIds = this.keepedAttributeValues;
      else {
        this.attributeValueIds = [];
        if (this.$refs.attributeValuesSelect)
          this.$refs.attributeValuesSelect.clearSelect();
      }
      const params = {
        survey_id: datas.surveyId,
        organization_id: datas.organizationId,
        attribute_value_ids: this.attributeValueIds
      };
      this.isTopic = datas.isTopic;
      if (!this.isTopic) {
        this.loadOpinionTendencies(params);
        this.loadOpinionTrends(params);
        this.loadTopic(params);
      }
    },
    getNumberOfDisplayableData() {
      const name = 'number_of_displayable_data';
      if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
        return this.controlSettings[name]['values']
          ? this.controlSettings[name]['values']
          : 0;
      }
      return 0;
    },
    modalOpen: function(
      surveyId,
      opinionClassificationId,
      emotionClassificationId
    ) {
      const params = {
        survey_id: surveyId,
        organization_id: this.organizationId,
        opinion_classification_id: opinionClassificationId,
        attribute_value_ids: this.attributeValueIds,
        emotion_classification_id: emotionClassificationId
      };
      this.$refs.modalOpinionAttrVal.modalOpen(params);
    },
    modalOpenNS: function(surveyId, opinionClassificationId) {
      const params = {
        survey_id: surveyId,
        organization_id: this.organizationId,
        opinion_classification_id: opinionClassificationId,
        attribute_value_ids: this.attributeValueIds
      };
      this.$refs.modalNsOpinionAttrVal.modalOpen(params);
    },
    toOpinionSearch: function(
      surveyId,
      opinionClassificationId,
      emotionClassificationId
    ) {
      this.$router.push({
        name: 'u_opinion_search',
        params: {
          opinion_classification: opinionClassificationId,
          emotion_classification: emotionClassificationId,
          attribute_values: this.attributeValueIds,
          survey_id: surveyId,
          backToName: 'u_team_analyse'
        }
      });
    },

    changeAttributeValueIds: function(attributeValueArr) {
      this.attributeValueIds = attributeValueArr[0];
      this.attributeValueNames = attributeValueArr[1];
      const params = {
        survey_id: this.surveyId,
        organization_id: this.organizationId,
        attribute_value_ids: this.attributeValueIds
      };
      if (!this.isTopic) {
        this.loadOpinionTendencies(params);
        this.loadOpinionTrends(params);
        this.loadTopic(params);
      }
    },

    setDisplayType: function(type) {
      this.displayType = type;
    },

    setDefaultDisplayType: function() {
      const vcd = defaultCookie.get();
      this.displayType = vcd['displayType'] || 0;
    }
  }
};
</script>
<style scoped>
.vc-parameter-nav .vc-ranking-btn {
  width: 50px;
  text-align: center;
}
.vc ul.vc-parameter-nav li a.vpn-all:hover {
  background: rgba(243, 21, 74, 0.5) !important;
}
.vc ul.vc-parameter-nav li a.vpn-all.active:hover {
  background: rgba(243, 21, 74, 1) !important;
}
.vc ul.vc-parameter-nav li a.vpn-all.active {
  background: #f3154a !important;
}
</style>
