export default {
  namespaced: true,

  modules: {},

  state: {
    id: null,
    email: '',
    name: ''
  },

  getters: {},

  mutations: {
    setAdmin(state, payload) {
      if (payload.admin.id != null) {
        state.id = payload.admin.id;
      }

      if (payload.admin.email != null) {
        state.email = payload.admin.email;
      }

      if (payload.admin.name != null) {
        state.name = payload.admin.name;
      }
    }
  }
};
