import list from './list';
import admin from './admin';

export default {
  namespaced: true,

  modules: {
    list: list,
    admin: admin
  },

  state: {},

  getters: {},

  mutations: {}
};
