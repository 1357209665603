<template lang="pug">
  #container.container-scroller
    UserNavbar(:operator="operator")
    .container-fluid
      .main-panel(v-if="displayMenu()")
        .container-fruid(:class="{ 'vc-dashboard': isRankings() }")
          .main-panel
            section.vc-content.vc-quantitative(:class="{ 'vc-dashboard': isTextMinings() }")
              .vc-top-col(:class="{ 'border-bottom-0': borderBottomZero() }")
                .container
                  SurveySelect
                .container.vc-dashboard-sub-select-wrap
                  AnalyseNav
                .container-fluid.vc-analyse-3rd-tab-wrap(v-show="isDisplaySecondNav()")
                  AnalyseSecondNav
              router-view
      .main-panel(v-else)
        router-view
</template>

<script>
// -- global header --
import UserNavbar from 'src/components/u/UserNavbar';
import UserLeftNav from 'src/components/u/UserLeftNav';
import SurveySelect from 'src/components/u/SurveySelect';
import AnalyseNav from 'src/components/u/AnalyseNav';
import AnalyseSecondNav from 'src/components/u/AnalyseSecondNav';

import { mapState, mapMutations } from 'vuex';

export default {
  components: {
    UserNavbar,
    UserLeftNav,
    SurveySelect,
    AnalyseNav,
    AnalyseSecondNav
  },

  data() {
    return {};
  },

  computed: {
    ...mapState('config', ['operator'])
  },

  created() {
    history.replaceState(
      null,
      document.getElementsByTagName('title')[0].innerHTML,
      null
    );
    this.initConfig(this.$parent.config);
  },

  mounted() {},

  methods: {
    ...mapMutations('config', { initConfig: 'init' }),
    borderBottomZero() {
      const arr = [
        'u_qualitative_analyse_team',
        'u_qualitative_analyse_compare',
        'u_qualitative_opinion_search'
      ];
      return arr.includes(this.$route.name);
    },
    isDisplaySecondNav() {
      const arr = [
        'u_qualitative_analyse_compare',
        'u_qualitative_analyse_heatmap',
        'u_qualitative_analyse_matrix',
        'u_qualitative_analyse_matrix_attrs',
        'u_qualitative_analyse_rankings',
        'u_qualitative_analyse_team',
        'u_qualitative_emotion_classifications_team',
        'u_qualitative_emotion_classifications_attribute_values'
      ];
      return arr.includes(this.$route.name);
    },
    isRankings() {
      return this.$route.name === 'u_qualitative_analyse_rankings';
    },
    isTextMinings() {
      return this.$route.name === 'u_qualitative_text_mining';
    },
    displayMenu() {
      const arr = [
        'u_about_data',
        'u_mypage',
        'u_users',
        'u_new_user',
        'u_edit_user',
        'u_select_account_group'
      ];
      return !arr.includes(this.$route.name);
    }
  }
};
</script>
