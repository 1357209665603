import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_inquiries');

export default {
  namespaced: true,

  state: {
    inquiries: [],
    currentPage: 0
  },

  getters: {},

  actions: {
    loadInquiries({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          let inquiries = response.body.inquiries;
          let currentPage = response.body.current_page;
          commit('setInquiries', inquiries);
          commit('setCurrentPage', currentPage);
        }
        return response;
      });
    },
    stopInquiry(_, id) {
      return gateway.put({ action: 'stop', id }).then(response => {
        return response;
      });
    },
    reopenInquiry(_, id) {
      return gateway.put({ action: 'reopen', id }).then(response => {
        return response;
      });
    },
    deleteInquiry(_, id) {
      return gateway.delete({ action: 'remove', id }).then(response => {
        return response;
      });
    }
  },

  mutations: {
    setInquiries(state, inquiries) {
      state.inquiries = inquiries;
    },
    setCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
    }
  }
};
