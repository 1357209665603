<template lang="pug">
  b-modal(
    id="user-delete-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    :title="$t('admin.users.delete.title')"
  )
    b-container
      p
        | {{ deleteTarget.name }}
        | (
        | {{ deleteTarget.email }}
        | )
        | を削除します。
      p
        | 本当によろしいですか？
    div.text-center.w-100(slot="modal-footer")
      button.btn.btn-danger(@click.once="destroy")
        | はい
      button.btn.btn-secondary.ml-2(@click="close")
        | いいえ
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    deleteTarget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions('resources/u_users', ['deleteUser']),

    close() {
      this.$bvModal.hide('user-delete-modal');
    },

    destroy() {
      this.deleteUser(this.deleteTarget.id).then(response => {
        if (response.status === 200) {
          this.$bvModal.hide('user-delete-modal');
          this.$router.push({ name: 'u_users' });
        }
      });
    }
  }
};
</script>
