<template lang="pug">
  div(v-if="ready && inquiry")
    form
      .research-page.interview-page.dashboard-body
        .container
          .card.dashboard-card.research-card
            .card-header
              .row
                .col-6
                  .interview-people
                    p.card-title
                      | {{ inquiry.survey_name }}
                      | ({{ inquiry.survey_period }})
                .col-6
                  ul.research-head-btn
                    li
                      a.cc-normal-btn(href="" @click.prevent="exportCsv")
                        | CSV出力
                    li
                      a.cc-normal-btn(href="" @click.prevent="exportPdf")
                        | PDF出力
                    li
                      a.cc-normal-btn(href="" @click.prevent="completeInquiry" v-if="inquiry.status !== 'completed'")
                        | 完了する
                      a.cc-normal-btn.btn-disabled(href="" v-else)
                        | 完了済み
              .row
                .col-12
                  span.interview-tips(@click.prevent="showTipsModal")
                    i.fas.fa-exclamation-circle.mr-1
                    | インタビューTips
              .row
                .col-12.mt-1
                  error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
                  flash-message(:message.sync="flashMessage" v-if="flashMessage !== ''")
              .row
                .col-12
                  p
                    | ニックネーム： {{ inquiry.responder.name }}
                .col-12
                  p
                    span
                      | {{ inquiry.attribute_value_names }}
                    span.float-right
                      | 回答者ID： {{ inquiry.responder.code }}
                .col-12
                  p
                    | {{ inquiry.organization_name }}
                .col-12
                  .row.mt-3(v-if="inquiry.survey_response")
                    .col-2(v-if="inquiry.question_text" :class="{'mt-2': inquiry.question_text}")
                      p.font-weight-bold 調査時の質問：
                    .col-10(v-if="inquiry.question_text" :class="{'mt-2': inquiry.question_text}")
                      .row
                        .col-11
                          p
                            | {{ displayQuestionText ? inquiry.question_text : truncate(inquiry.question_text, 60) }}
                        .col-1.text-right
                          img.question-text-hide-btn.ml-2(
                            src="../../../assets/images/excheck/chevron-down.png" width="18"
                            :class="{'reverse': displayQuestionText}"
                            @click.prevent="toggleQuestionText"
                          )
                    .col-2(:class="{'mt-2': inquiry.question_text}")
                      p.font-weight-bold あなたの自由記述回答：
                    .col-10(:class="{'mt-2': inquiry.question_text}")
                      p.p-3.response-contents-box(
                        v-if="inquiry.survey_response"
                        :class="{'pb-5': inquiry.survey_response.contents.length > 150}"
                      )
                        | {{ displayResponseText ? inquiry.survey_response.contents : truncate(inquiry.survey_response.contents, 150) }}
                        span.text-right.response-text-hide-btn(
                          v-show="inquiry.survey_response.contents.length > 150"
                          @click.prevent="toggleResponseText"
                        )
                          | 全文章を表示
                          img.question-text-hide-btn.ml-2(
                            src="../../../assets/images/excheck/chevron-down.png" width="10"
                            :class="{'reverse': displayResponseText}"
                            @click.prevent="toggleQuestionText"
                          )
                  .row.mt-3(v-else)
                    .col-12(v-if="inquiry.question_text")
                      .row
                        .col-11
                          p.font-weight-bold
                            | {{ displayQuestionText ? 'Q.' + inquiry.question_text : truncate(inquiry.question_text) }}
                        .col-1.text-right
                          img.question-text-hide-btn.ml-2(
                            src="../../../assets/images/excheck/chevron-down.png" width="18"
                            :class="{'reverse': displayQuestionText}"
                            @click.prevent="toggleQuestionText"
                          )
                    .col-2(:class="{'mt-2': inquiry.question_text}")
                      p.font-weight-bold あなたの自由記述回答：
                    .col-10(:class="{'mt-2': inquiry.question_text}")
                      p.p-3.response-contents-box
                        | {{ inquiry.quantitative_response.quantitative_answer.select_num + '：' +
                        | inquiry.quantitative_response.quantitative_answer.select_text }}

            .card-body
              .interview-timeline
                ChatItem(
                  v-for="chat in chatInterviews"
                  :chat="chat"
                  :user-ids="userIds"
                  :key="chat.id"
                )
                #chat-bottom
            .card-footer
              .row
                .col-sm-10.col-12
                  textarea.chat-msg-box(
                    placeholder="入力してください（Shift + Enter で改行します）"
                    v-model="msgBox"
                    :disabled="['completed', 'stopped'].includes(inquiry.status)"
                    :class="{'disabled': ['completed', 'stopped'].includes(inquiry.status)}"
                    @keyup.enter.exact="onKeyupEnter($event)"
                    @keydown.enter.exact="onKeydownEnter($event)"
                    @compositionstart="onCompositionStart"
                  )
                .col-sm-2.col-12.text-center.mt-2
                  .row
                    .col-sm
                      a.cc-primary-btn.cc-inline-btn.btn-block.ml-3.m-1(
                        href="#"
                        role="button"
                        v-if="messageChannel"
                        :disabled="['completed', 'stopped'].includes(inquiry.status)"
                        :class="{'btn-disabled': ['completed', 'stopped'].includes(inquiry.status)}"
                        @click.prevent="speak"
                      )
                        | 送信する
            .card-body
              .row
                .col-12.text-center
                  a(role="button" @click.prevent="toggleMemo")
                    | メモを登録
                    img.ml-2(src="../../../assets/images/excheck/chevron-down.png" width="18")
            .card-footer.memo-footer
              .row
                .col-12.mt-2(v-show="displayMemoTextBox")
                  .row
                    .col-10
                      textarea(
                        placeholder='登録するメモを入力してください'
                        v-model="memoText"
                      )
                    .col-2.text-center
                      a.cc-primary-btn.cc-inline-btn.btn-block.ml-3.m-1(
                        href="#"
                        role="button"
                        @click.prevent="updateMemo"
                        :class="{'btn-disabled': processing}"
                      )
                        | メモ登録
    TipsModal(ref="modalInterviewTips")
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import ChatItem from 'src/components/u/chat_interviews/ChatItem';
import ErrorMessage from 'src/components/ErrorMessage';
import FlashMessage from 'src/components/FlashMessage';
import moment from 'moment';
import CDL from '../../../lib/csv_download';
const CSVDownload = new CDL();
import i18n from 'src/locales/index';
import TipsModal from '../../../components/u/chat_interviews/TipsModal';

export default {
  components: {
    ChatItem,
    ErrorMessage,
    FlashMessage,
    TipsModal
  },

  data() {
    return {
      ready: false,
      msgBox: '',
      messageChannel: null,
      flashMessage: '',
      errorMessages: [],
      processing: false,
      isComposing: false,
      displayMemoTextBox: false,
      memoText: '',
      userIds: [],
      displayQuestionText: false,
      displayResponseText: false
    };
  },

  computed: {
    ...mapState('resources/u_chat_interviews', ['chatInterviews', 'inquiry'])
  },

  created() {
    this.$on('remove-chat-item', this.removeChatItem);

    this.messageChannel = this.$cable.subscriptions.create(
      'ChatInterviewChannel',
      {
        connected: () => {
          this.followCurrentChannel();
        },

        received: data => {
          this.setChatInterview(data);
          this.moveToChatButtom();
          this.updateStatus(this.$route.params.inquiry_id);
        }
      }
    );

    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_chat_interviews', [
      'loadChatInterviews',
      'removeChatInterview',
      'requestExportPdf',
      'requestCompleteInquiry',
      'requestUpdateMemo',
      'updateStatus'
    ]),
    ...mapActions('resources/u_public_activities', ['createPublicActivity']),
    ...mapMutations('resources/u_chat_interviews', [
      'setChatInterview',
      'spliceChatInterview'
    ]),

    /**
     * ユーザー一覧の更新
     *
     * @return { void }
     */
    load() {
      this.ready = true;
      this.loadChatInterviews(this.$route.params.inquiry_id).then(response => {
        if (response.status === 200) {
          this.ready = true;
          this.memoText = this.inquiry.memo;
          this.userIds = response.body.user_ids;
          this.moveToChatButtom();
          this.updateStatus(this.$route.params.inquiry_id);
        }
      });
    },

    followCurrentChannel() {
      this.messageChannel.perform('follow', {
        channel_id: this.$route.params.inquiry_id
      });
    },

    speak() {
      if (!this.msgBox) return;
      this.messageChannel.perform('speak', {
        channel_id: this.$route.params.inquiry_id,
        message: this.msgBox
      });

      this.resetChatBox();
    },

    onKeyupEnter(e) {
      if (e.keyCode !== 13) return;
      if (this.isComposing) this.onCompositionEnd();
      else {
        e.preventDefault();
        this.speak();
      }
    },

    onKeydownEnter(e) {
      if (e.keyCode !== 13) return;
      e.preventDefault();
    },

    onCompositionStart() {
      this.isComposing = true;
    },

    onCompositionEnd() {
      this.isComposing = false;
    },

    moveToChatButtom() {
      var bottom = this.$el.querySelector('#chat-bottom');
      this.$scrollTo(bottom, 800, {
        container: '.interview-timeline',
        easing: 'ease-in-out'
      });
    },

    removeChatItem(item) {
      this.removeChatInterview({
        inquiry_id: this.$route.params.inquiry_id,
        id: item.id
      }).then(response => {
        if (response.status === 200) {
          this.spliceChatInterview({ item: item });
        } else if (response.status === 422) {
          this.errorMessages = response.body.errors;
        }
      });
    },

    /**
     * CSV出力依頼
     */
    exportCsv() {
      let head = ['#', 'コメンター', 'テキスト', '作成日時'];
      const datas = [];
      const chatInterviews = this.chatInterviews;
      for (const i in chatInterviews) {
        let items = [];
        items.push(Number(i) + 1);
        items.push(chatInterviews[i].commenter.name);
        items.push(chatInterviews[i].contents);
        items.push(
          moment(chatInterviews[i].update_at).format('YYYY-MM-DD HH:mm:ss')
        );
        datas.push(items);
      }

      CSVDownload.downloadCsv(
        head,
        datas,
        'ChatInterview_' + moment().format('YYYYMMDDHHmmss') + '.csv'
      );
      const params = {
        trackable_type: 'survey',
        trackable_id: this.inquiry.survey_response.survey_id,
        key: 'survey.export_chat_csv'
      };
      this.createPublicActivity(params);
    },

    /**
     * PDF出力依頼
     */
    exportPdf() {
      this.resetMessages();
      this.requestExportPdf({
        inquiry_id: this.$route.params.inquiry_id
      }).then(response => {
        if (response.status === 200) {
          this.flashMessage = i18n.tc('actions.processing');
          // 3秒後に自動的にメッセージを消す
          setTimeout(() => {
            this.flashMessage = '';
          }, 3000);
        } else if (response.status === 422) {
          this.errorMessages = response.body.errors;
        }
      });
    },

    /**
     * 質問の完了
     */
    completeInquiry() {
      this.resetMessages();
      this.requestCompleteInquiry({
        inquiry_id: this.$route.params.inquiry_id
      }).then(response => {
        if (response.status === 200) {
          this.$router.push({ name: 'u_inquiries' });
        } else if (response.status === 422) {
          this.errorMessages = response.body.errors;
        }
      });
    },

    /**
     * メッセージのクリア
     */
    resetMessages() {
      this.errorMessages = [];
      this.flashMessage = '';
    },

    /**
     * チャット入力ボックスをリセット
     */
    resetChatBox() {
      this.msgBox = '';
      this.noReply = false;
      this.itemId = null;
    },

    /**
     * 画像モーダルを表示する
     *
     * @return {void}
     */
    showImageModal(url) {
      this.imageUrl = url;
      this.$bvModal.show('image-modal');
    },

    toggleMemo() {
      this.displayMemoTextBox = !this.displayMemoTextBox;
    },

    updateMemo() {
      this.processing = true;
      this.requestUpdateMemo({
        inquiry_id: this.$route.params.inquiry_id,
        memo: this.memoText
      }).then(response => {
        if (response.status === 200) {
          this.displayMemoTextBox = false;
          this.processing = false;
        } else if (response.status === 422) {
          this.errorMessages = response.body.errors;
        }
      });
    },

    showTipsModal() {
      this.$refs.modalInterviewTips.modalOpen();
    },

    truncate(str, num) {
      return str.length <= num ? str : str.substr(0, num) + '....';
    },

    toggleQuestionText() {
      this.displayQuestionText = !this.displayQuestionText;
    },

    toggleResponseText() {
      this.displayResponseText = !this.displayResponseText;
    }
  }
};
</script>
<style scoped>
.response-contents-box {
  background: #f7f7f7;
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 8px;
}
.card-footer.memo-footer {
  border-top: none !important;
  background-color: #fff;
}
.float-right {
  float: right;
}
.chat-msg-box.disabled {
  border-color: #ddd;
}
.interview-tips {
  color: #00a897;
  cursor: pointer;
}
.question-text-hide-btn,
.response-text-hide-btn {
  cursor: pointer;
}
.question-text-hide-btn.reverse,
.response-text-hide-btn.reverse {
  -webkit-transform: rotatex(180deg);
  transform: rotatex(180deg);
}
span.response-text-hide-btn {
  position: absolute;
  right: 40px;
  bottom: 15px;
}
</style>
