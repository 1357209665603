<template lang="pug">
  div
    modal(
      ref="modalShowOpinion"
      name="modalShowOpinion"
      id="modalShowOpinion"
      :minWidth="modalWidth"
      :minHeight="modalHeight"
      :width="modalWidth"
      :height="modalHeight"
      :reset="true"
      :resizable="false"
      :clickToClose="true"
      :draggable="true"
      :pivotY="0.6"
    )
      .modal-contents.vc-dashboard
        .vc-dashboard-box
          dl.respondent-num.align-items-center
            dt
              button(type="button" @click="$modal.hide('modalShowOpinion')" class="close vc-close")
                | ×
          .vc-dashboard-box-content
            .caret-left(@click="changeResponse('prev')")
              i.fa.fa-caret-left.fa-10x(aria-hidden="true")
            .organizations
              | {{ response.grand_parent_organization_name }}
              span.subspan(v-if="response.grand_parent_organization_name")
                |  >>
              | {{ response.parent_organization_name }}
              span.subspan(v-if="response.parent_organization_name")
                |  >>
              | {{ response.organization_name }}
            .sentences-wrap
              .sentences
                | {{response.original_sentences}}
            .attribute-values
              span.mr-2(v-for="name in response.attribute_value_names")
                | {{ name }}
            .caret-right(@click="changeResponse('next')")
              i.fa.fa-caret-right.fa-10x(aria-hidden="true")

</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},

  data() {
    return {
      modalWidth: 0,
      modalHeight: 0,
      response: {},
      currentIndex: 0
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_opinion_search', ['opinionArr'])
  },

  created() {
    this.modalWidth = window.outerWidth * 0.5;
    this.modalHeight = window.outerHeight * 0.5;
  },

  methods: {
    modalOpen(i) {
      this.response = this.opinionArr[i];
      this.currentIndex = i;
      this.$modal.show('modalShowOpinion');
    },
    changeResponse(type) {
      const idx = this.currentIndex;
      const currentIdx = type === 'prev' ? idx - 1 : idx + 1;
      const lastIdx = this.opinionArr.length - 1;
      if (currentIdx < 0 || lastIdx < currentIdx) return;

      this.currentIndex = currentIdx;
      this.response = this.opinionArr[this.currentIndex];
    }
  }
};
</script>

<style lang="scss" scoped>
.vc-close {
  position: absolute;
  right: 45px;
  top: 55px;
}
div {
  height: 100%;
}
.sentences-wrap {
  height: 80% !important;
  margin-bottom: 20px !important;
  overflow: scroll;
}
.sentences {
  height: 100%;
  align-items: center;
  display: flex;
  text-align: left;
  font-size: 16px;
  line-height: 1.9;
  letter-spacing: 0.045rem;
  padding: 0 65px;
}
.vc-dashboard-box-content .organizations {
  height: 10% !important;
}
.vc-dashboard-box-content .attribute-values {
  height: 10% !important;
}
.caret-left {
  position: absolute;
  top: 35%;
  left: 20px;
  opacity: 10%;
  height: auto;
}
.caret-right {
  position: absolute;
  top: 35%;
  right: 20px;
  opacity: 10%;
  height: auto;
}
.caret-left:hover,
.caret-right:hover {
  opacity: 60%;
  cursor: pointer;
}
span.subspan {
  display: inline-block;
  color: #999;
  margin: 0 5px;
}
</style>
