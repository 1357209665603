<template lang="pug">
  div.vc-top-col
    .container
      SurveySelect()
    .container-fluid.vc-dashboard-sub-select-wrap(v-if="this.$route.path.indexOf('quantitative')<1")
      .row
        .col-12
          .vc-top-tab.container
            router-link.nav-link.vc-midashi(
              :to="{ name: 'u_dashboard' }"
              :class="{'active': this.$route.path.indexOf('emotion_class_per')<1 &&\
                                 this.$route.path.indexOf('volume_map')<1 &&\
                                 this.$route.path.indexOf('opinion_class_map')<1}"
            )
              | ランキング
            router-link.nav-link.vc-midashi(
              :to="{ name: 'u_emotion_class_per_dashboard' }"
              :class="{'active': this.$route.path.indexOf('emotion_class_per')>0}")
              | ネガポジ感情構成
            router-link.nav-link.vc-midashi(
              :to="{ name: 'u_volume_map_dashboard' }"
              :class="{'active': this.$route.path.indexOf('volume_map')>0}")
              | ネガポジ感情マップ
            router-link.nav-link.vc-midashi(
              :to="{ name: 'u_opinion_class_map_dashboard' }"
              :class="{'active': this.$route.path.indexOf('opinion_class_map')>0}")
              | 意見別ネガポジ感情マップ
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SurveySelect from './../SurveySelect';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    SurveySelect
  },
  props: {},
  computed: {
    ...mapState('pages/u_surveys/select', ['surveyId', 'isTopic'])
  },
  watch: {
    surveyId: function() {
      const vcd = defaultCookie.get();
      if (vcd && vcd['surveyId'] && this.surveyId != vcd['surveyId']) {
        const data = {
          surveyId: this.surveyId,
          organizationId: null,
          isTopic: this.isTopic
        };
        defaultCookie.set(data);
      }
      this.$emit('input', this.surveyId);
    }
  },
  created() {
    if (this.surveyId) {
      this.$emit('input', this.surveyId);
    }
  },
  mounted() {},
  methods: {
    ...mapMutations('pages/u_organizations/select', ['setSelect'])
  }
};
</script>
