import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_admins');

export default {
  namespaced: true,

  state: {
    adminsList: []
  },

  getters: {},

  actions: {
    /**
     * Admin一覧取得
     */
    loadAdmins({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          const adminsList = response.body.admins;

          commit('setAdminsList', adminsList);
        }
        return response;
      });
    },

    /**
     * 新規作成
     *
     * @param {Object} params パラメタ
     * @return {Promise} Promise
     */
    createAdmin(_, params) {
      return gateway
        .post(
          {
            action: 'create'
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    /**
     * 管理者取得(編集)
     */
    editAdmin(_, id) {
      return gateway
        .get({
          action: 'edit',
          id
        })
        .then(response => {
          return response;
        });
    },

    /**
     * 更新
     *
     * @param {Object} params パラメタ
     * @return {Promise} Promise
     */
    updateAdmin(_, params) {
      return gateway
        .put(
          {
            action: 'update',
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    /**
     * 管理者論理削除
     *
     * @param {Number} id adminID
     * @return {Promise} Promise
     */
    deleteAdmin(_, id) {
      return gateway.delete({ action: 'remove', id }).then(response => {
        return response;
      });
    }
  },

  mutations: {
    setAdminsList(state, adminsList) {
      state.adminsList = adminsList;
    }
  }
};
