<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content.vc-dashboard
        SurveySelect
        .vc-main-container.container(v-if="!isTopic && started")
          .vc-dashboard-box.mb-4
            .container-fluid
              div
                .row
                  .col-12.vc-midashi-wrap
                    h3.vc-midashi.mb-4 ネガポジ感情構成
                    span.vc-midashi-desc.mb-4 会社全体の感情傾向がわかります
                .row.vc-dashboard-box-content
                  .col-12.vc-dashboard-box-title
                    h4.vc-midashi.mt-3.mb-2
                      | {{ emotionClassTotalPer[0] }}
                  .dashboard-comp-graph-col.vc-dashboard-box-graph-wrap
                    div
                      pie-chart(:chart-data="totalChartData || {}" :options="chartOptions")
                  .dashboard-comp-table-col.vc-dashboard-box-table-wrap
                    .row
                      .col-12.vc-dashboard-box-title
                        h5.vc-midashi.mt-3.mb-2 感情意見割合
                    .row.no-gutters.vc-respondent-table
                      .col-7.text-left.overflow-hidden.text-nowrap 感情分類
                      .col-5
                        .row.no-gutters
                          .col-6 割合
                          .col-6 増減
                    .vc-dashboard-box-table-data-row.no-scroll
                      .row.no-gutters.vc-respondent-table(v-for="data in emotionClassTotalPer[1]")
                        .col-7.text-left.overflow-hidden.text-nowrap.table-column-name
                          div
                            | {{ data[0] }}
                        .col-5
                          .row.no-gutters.vc-respondent-table-num
                            .col-6
                              div
                                | {{ data[1].toFixed(1) }}%
                            .col-6
                              .vc-dashboard-box-fluctuation
                                div(:class="{'over-5pt': data[2] >= 5 || data[2] <= -5}")
                                  | {{ data[2] > 0 ? '+' + data[2].toFixed(1) : data[2].toFixed(1) }}pt
                                .vc-fluctuation-margin
                                svg(
                                  :class="{'large-fluctuation': data[2] >= 5}"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enable-background="new 0 0 24 24"
                                  viewBox="0 0 24 24"
                                  width="14px"
                                  height="14px"
                                  fill="darkgray"
                                  v-if="0 < data[2]"
                                )
                                  rect(fill="none" height="24" width="24")
                                  path(d="M9,5v2h6.59L4,18.59L5.41,20L17,8.41V15h2V5H9z")
                                svg(
                                  :class="{'large-fluctuation': data[2] <= -5}"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enable-background="new 0 0 24 24"
                                  viewBox="0 0 24 24"
                                  width="14px"
                                  height="14px"
                                  fill="darkgray"
                                  v-if="data[2] < 0"
                                )
                                  rect(fill="none" height="24" width="24")
                                  path(d="M19,9h-2v6.59L5.41,4L4,5.41L15.59,17H9v2h10V9z")
                                img(src="../../../assets/images/excheck/vc-hyphen.png" class="vc-arrow" v-if="0 == data[2]")
                  .dashboard-comp-table-col.vc-dashboard-box-table-wrap
                    .row
                      .col-12.vc-dashboard-box-title
                        h5.vc-midashi.vc-dashboard-midashi-nega.mt-3.mb-2 ネガティブ意見構成比TOP3
                    .row.no-gutters.vc-respondent-table
                      .col-7.text-left.overflow-hidden.text-nowrap 意見分類
                      .col-5
                        .row.no-gutters
                          .col-6 割合
                          .col-6 増減
                    .vc-dashboard-box-table-data-row.no-scroll
                      .row.no-gutters.vc-respondent-table(v-for="data in emotionClassTotalPer[2]")
                        .col-7.text-left.overflow-hidden.text-nowrap.table-column-name
                          div
                            | {{ data[0] }}
                        .col-5
                          .row.no-gutters.vc-respondent-table-num
                            .col-6
                              div
                                | {{ data[1].toFixed(1) }}%
                            .col-6
                              .vc-dashboard-box-fluctuation
                                div(:class="{'over-5pt': data[2] >= 5 || data[2] <= -5}")
                                  | {{ data[2] > 0 ? '+' + data[2].toFixed(1) : data[2].toFixed(1) }}pt
                                .vc-fluctuation-margin
                                svg(
                                  :class="{'large-fluctuation': data[2] >= 5}"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enable-background="new 0 0 24 24"
                                  viewBox="0 0 24 24"
                                  width="14px"
                                  height="14px"
                                  fill="darkgray"
                                  v-if="0 < data[2]"
                                )
                                  rect(fill="none" height="24" width="24")
                                  path(d="M9,5v2h6.59L4,18.59L5.41,20L17,8.41V15h2V5H9z")
                                svg(
                                  :class="{'large-fluctuation': data[2] <= -5}"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enable-background="new 0 0 24 24"
                                  viewBox="0 0 24 24"
                                  width="14px"
                                  height="14px"
                                  fill="darkgray"
                                  v-if="data[2] < 0"
                                )
                                  rect(fill="none" height="24" width="24")
                                  path(d="M19,9h-2v6.59L5.41,4L4,5.41L15.59,17H9v2h10V9z")
                                img(src="../../../assets/images/excheck/vc-hyphen.png" class="vc-arrow" v-if="0 == data[2]")
                  .dashboard-comp-table-col.vc-dashboard-box-table-wrap
                    .row
                      .col-12.vc-dashboard-box-title
                        h5.vc-midashi.vc-dashboard-midashi-posi.mt-3.mb-2 ポジティブ意見構成比TOP3
                    .row.no-gutters.vc-respondent-table
                        .col-7.text-left.overflow-hidden.text-nowrap 意見分類
                        .col-5
                          .row.no-gutters
                            .col-6 割合
                            .col-6 増減
                    .vc-dashboard-box-table-data-row.no-scroll
                      .row.no-gutters.vc-respondent-table(v-for="data in emotionClassTotalPer[3]")
                        .col-7.text-left.overflow-hidden.text-nowrap.table-column-name
                          div
                            | {{ data[0] }}
                        .col-5
                          .row.no-gutters.vc-respondent-table-num
                            .col-6
                              div
                                | {{ data[1].toFixed(1) }}%
                            .col-6
                              .vc-dashboard-box-fluctuation
                                div(:class="{'over-5pt': data[2] >= 5 || data[2] <= -5}")
                                  | {{ data[2] > 0 ? '+' + data[2].toFixed(1) : data[2].toFixed(1) }}pt
                                .vc-fluctuation-margin
                                svg(
                                  :class="{'large-fluctuation': data[2] >= 5}"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enable-background="new 0 0 24 24"
                                  viewBox="0 0 24 24"
                                  width="14px"
                                  height="14px"
                                  fill="darkgray"
                                  v-if="0 < data[2]"
                                )
                                  rect(fill="none" height="24" width="24")
                                  path(d="M9,5v2h6.59L4,18.59L5.41,20L17,8.41V15h2V5H9z")
                                svg(
                                  :class="{'large-fluctuation': data[2] <= -5}"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enable-background="new 0 0 24 24"
                                  viewBox="0 0 24 24"
                                  width="14px"
                                  height="14px"
                                  fill="darkgray"
                                  v-if="data[2] < 0"
                                )
                                  rect(fill="none" height="24" width="24")
                                  path(d="M19,9h-2v6.59L5.41,4L4,5.41L15.59,17H9v2h10V9z")
                                img(src="../../../assets/images/excheck/vc-hyphen.png" class="vc-arrow" v-if="0 == data[2]")
          .vc-dashboard-box.borderb1(v-for="contents in emotionClassOrgPer")
            .container-fluid
              div
                .row.vc-dashboard-box-content
                  .col-12.vc-dashboard-box-title
                    h4.vc-midashi.mt-3.mb-2
                      | {{ emotionClassTotalPer[0] }}
                      img(src="../../../assets/images/excheck/vc-icon-gt.png")
                      | {{ contents[0] }}
                  .dashboard-comp-graph-col.vc-dashboard-box-graph-wrap
                    div
                      pie-chart(:chart-data="orgChartDatas[contents[0]] || {}" :options="chartOptions")
                  .dashboard-comp-table-col.vc-dashboard-box-table-wrap
                    .row
                      .col-12.vc-dashboard-box-title
                        h5.vc-midashi.mt-3.mb-2 感情意見割合
                    .row.no-gutters.vc-respondent-table
                      .col-7.text-left.overflow-hidden.text-nowrap 感情分類
                      .col-5
                        .row.no-gutters
                          .col-6 割合
                          .col-6 増減
                    .vc-dashboard-box-table-data-row.no-scroll
                      .row.no-gutters.vc-respondent-table(v-for="data in contents[1]")
                        .col-7.text-left.overflow-hidden.text-nowrap.table-column-name
                          div
                            | {{ data[0] }}
                        .col-5
                          .row.no-gutters.vc-respondent-table-num
                            .col-6
                              div
                                | {{ data[1].toFixed(1) }}%
                            .col-6
                              .vc-dashboard-box-fluctuation
                                div(:class="{'over-5pt': data[2] >= 5 || data[2] <= -5}")
                                  | {{ data[2] > 0 ? '+' + data[2].toFixed(1) : data[2].toFixed(1) }}pt
                                .vc-fluctuation-margin
                                svg(
                                  :class="{'large-fluctuation': data[2] >= 5}"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enable-background="new 0 0 24 24"
                                  viewBox="0 0 24 24"
                                  width="14px"
                                  height="14px"
                                  fill="darkgray"
                                  v-if="0 < data[2]"
                                )
                                  rect(fill="none" height="24" width="24")
                                  path(d="M9,5v2h6.59L4,18.59L5.41,20L17,8.41V15h2V5H9z")
                                svg(
                                  :class="{'large-fluctuation': data[2] <= -5}"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enable-background="new 0 0 24 24"
                                  viewBox="0 0 24 24"
                                  width="14px"
                                  height="14px"
                                  fill="darkgray"
                                  v-if="data[2] < 0"
                                )
                                  rect(fill="none" height="24" width="24")
                                  path(d="M19,9h-2v6.59L5.41,4L4,5.41L15.59,17H9v2h10V9z")
                                img(src="../../../assets/images/excheck/vc-hyphen.png" class="vc-arrow" v-if="0 == data[2]")
                  .dashboard-comp-table-col.vc-dashboard-box-table-wrap
                    .row
                      .col-12.vc-dashboard-box-title
                        h5.vc-midashi.vc-dashboard-midashi-nega.mt-3.mb-2 ネガティブ意見構成比TOP3
                    .row.no-gutters.vc-respondent-table
                      .col-7.text-left.overflow-hidden.text-nowrap 意見分類
                      .col-5
                        .row.no-gutters
                          .col-6 割合
                          .col-6 増減
                    .vc-dashboard-box-table-data-row.no-scroll
                      .row.no-gutters.vc-respondent-table(v-for="data in contents[2]")
                        .col-7.text-left.overflow-hidden.text-nowrap.table-column-name
                          div
                            | {{ data[0] }}
                        .col-5
                          .row.no-gutters.vc-respondent-table-num
                            .col-6
                              div
                                | {{ data[1].toFixed(1) }}%
                            .col-6
                              .vc-dashboard-box-fluctuation
                                div(:class="{'over-5pt': data[2] >= 5 || data[2] <= -5}")
                                  | {{ data[2] > 0 ? '+' + data[2].toFixed(1) : data[2].toFixed(1) }}pt
                                .vc-fluctuation-margin
                                svg(
                                  :class="{'large-fluctuation': data[2] >= 5}"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enable-background="new 0 0 24 24"
                                  viewBox="0 0 24 24"
                                  width="14px"
                                  height="14px"
                                  fill="darkgray"
                                  v-if="0 < data[2]"
                                )
                                  rect(fill="none" height="24" width="24")
                                  path(d="M9,5v2h6.59L4,18.59L5.41,20L17,8.41V15h2V5H9z")
                                svg(
                                  :class="{'large-fluctuation': data[2] <= -5}"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enable-background="new 0 0 24 24"
                                  viewBox="0 0 24 24"
                                  width="14px"
                                  height="14px"
                                  fill="darkgray"
                                  v-if="data[2] < 0"
                                )
                                  rect(fill="none" height="24" width="24")
                                  path(d="M19,9h-2v6.59L5.41,4L4,5.41L15.59,17H9v2h10V9z")
                                img(src="../../../assets/images/excheck/vc-hyphen.png" class="vc-arrow" v-if="0 == data[2]")
                  .dashboard-comp-table-col.vc-dashboard-box-table-wrap
                    .row
                      .col-12.vc-dashboard-box-title
                        h5.vc-midashi.vc-dashboard-midashi-posi.mt-3.mb-2 ポジティブ意見構成比TOP3
                    .row.no-gutters.vc-respondent-table
                      .col-7.text-left.overflow-hidden.text-nowrap 意見分類
                      .col-5
                        .row.no-gutters
                          .col-6 割合
                          .col-6 増減
                    .vc-dashboard-box-table-data-row.no-scroll
                      .row.no-gutters.vc-respondent-table(v-for="data in contents[3]")
                        .col-7.text-left.overflow-hidden.text-nowrap.table-column-name
                          div
                            | {{ data[0] }}
                        .col-5
                          .row.no-gutters.vc-respondent-table-num
                            .col-6
                              div
                                | {{ data[1].toFixed(1) }}%
                            .col-6
                              .vc-dashboard-box-fluctuation
                                div(:class="{'over-5pt': data[2] >= 5 || data[2] <= -5}")
                                  | {{ data[2] > 0 ? '+' + data[2].toFixed(1) : data[2].toFixed(1) }}pt
                                .vc-fluctuation-margin
                                svg(
                                  :class="{'large-fluctuation': data[2] >= 5}"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enable-background="new 0 0 24 24"
                                  viewBox="0 0 24 24"
                                  width="14px"
                                  height="14px"
                                  fill="darkgray"
                                  v-if="0 < data[2]"
                                )
                                  rect(fill="none" height="24" width="24")
                                  path(d="M9,5v2h6.59L4,18.59L5.41,20L17,8.41V15h2V5H9z")
                                svg(
                                  :class="{'large-fluctuation': data[2] <= -5}"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enable-background="new 0 0 24 24"
                                  viewBox="0 0 24 24"
                                  width="14px"
                                  height="14px"
                                  fill="darkgray"
                                  v-if="data[2] < 0"
                                )
                                  rect(fill="none" height="24" width="24")
                                  path(d="M19,9h-2v6.59L5.41,4L4,5.41L15.59,17H9v2h10V9z")
                                img(src="../../../assets/images/excheck/vc-hyphen.png" class="vc-arrow" v-if="0 == data[2]")
        .vc-main-container.container(v-if="!isTopic && !started")
          .vc-dashboard-box.mb-4
            .container-fluid
              div
                .row
                  .col-12.vc-midashi-wrap
                    h3.vc-midashi.mb-4 ネガポジ感情構成
                    span.vc-midashi-desc.mb-4 会社全体の感情傾向がわかります
                .row.vc-dashboard-box-content
                  .col-12
                    p.mt-4 読み込み中です。しばらくお待ちください。
        .vc-main-container.container(v-if="isTopic")
          .vc-content-col
            .container
              | トピック調査については、この画面はご利用できません。調査選択から、トピック調査以外の調査を選択ください。
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SurveySelect from '../../../components/u/dashboard/SurveySelect';
import PieChart from '../../../components/u/chartJs/PieChart';

export default {
  components: {
    SurveySelect,
    PieChart
  },

  data() {
    return {
      totalChartData: {},
      orgChartDatas: {},
      chartOptions: {
        responsive: true,
        layout: {
          padding: {
            left: 12,
            right: 12,
            top: 0,
            bottom: 0
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          align: 'start'
        }
      }
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_dashboard', [
      'emotionClassTotalPer',
      'emotionClassOrgPer'
    ]),
    ...mapState('pages/u_surveys/select', ['surveyId', 'isTopic']),
    ...mapState('pages/u_organizations/select', ['started'])
  },

  watch: {
    emotionClassOrgPer: function() {
      this.pieGraphUpdate();
    },
    surveyId: function() {
      if (!this.isTopic) {
        this.loadEmotionClassPer({ survey_id: this.surveyId }).then(() => {
          this.pieGraphUpdate();
          this.setStarted(true);
        });
      }
    }
  },

  created() {
    this.setStarted(false);
    if (this.surveyId && !this.isTopic) {
      this.loadEmotionClassPer({ survey_id: this.surveyId }).then(() => {
        this.pieGraphUpdate();
        this.setStarted(true);
      });
    }
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_dashboard', ['loadEmotionClassPer']),
    ...mapMutations('pages/u_organizations/select', ['setStarted']),

    pieGraphUpdate: function() {
      const labels = ['ネガティブ', 'ポジティブ', '中立'];
      const backgroundColors = [
        'rgba(232, 94, 94, 0.8)',
        'rgba(34, 190, 211, 0.8)',
        'rgba(133, 133, 133, 0.8)'
      ];

      this.totalChartData = {
        labels: labels,
        datasets: [
          {
            backgroundColor: backgroundColors,
            data: this.emotionClassTotalPer[1].map(x => x[1])
          }
        ]
      };

      for (const contents of this.emotionClassOrgPer) {
        this.orgChartDatas[contents[0]] = {
          labels: labels,
          datasets: [
            {
              backgroundColor: backgroundColors,
              data: contents[1].map(x => x[1])
            }
          ]
        };
      }
    }
  }
};
</script>
<style scoped>
.vc-dashboard-box-fluctuation .vc-fluctuation-margin {
  margin-right: 2px;
}
.vc-dashboard-box-fluctuation svg {
  margin-right: -2px;
}
.vc-dashboard-box-fluctuation svg.large-fluctuation {
  fill: #f3154a;
}
</style>
