<template lang="pug">
  .content-wrapper
    .row.flex-grow
      .col-6
        .card
          .card-body
            h4.card-title
              | {{ $t('admin.organizationVersions.edit.title') }}

            form(v-if="ready")
              .alert.alert-danger(v-if="errorMessages.length > 0")
                ul.mb-0
                  li(v-for="error in errorMessages")
                    | {{ error }}
              validation-observer(v-slot="{ invalid }")
                validation-provider(name="name" rules="required" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.organizationVersions.name') }}
                    input(
                      type="text"
                      placeholder="組織バージョン名"
                      :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      v-model="name"
                    )
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                validation-provider(name="code" rules="required" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.organizationVersions.code') }}
                    input(
                      type="text"
                      placeholder="組織バージョンコード"
                      :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      v-model="code"
                    )
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                validation-provider(name="organization_first_level_name" rules="required" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.organizationVersions.organization_first_level_name') }}
                    input(
                      type="text"
                      placeholder="第一階層名"
                      :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      v-model="firstLevelName"
                    )
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                .form-group
                  label
                    | {{ $t('admin.organizationVersions.organization_second_level_name') }}
                  input.form-control(
                    type="text"
                    placeholder="第二階層名"
                    v-model="secondLevelName"
                  )

                .form-group
                  label
                    | {{ $t('admin.organizationVersions.organization_third_level_name') }}
                  input.form-control(
                    type="text"
                    placeholder="第三階層名"
                    v-model="thirdLevelName"
                  )

                validation-provider(name="accountGroupId" rules="required" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.surveys.accountGroup') }}
                    select(
                      :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      v-model="accountGroupId"
                    )
                      option(
                        v-for="accountGroup in accountGroups"
                        v-bind:value="accountGroup.id"
                        v-bind:key="accountGroup.id"
                      )
                        | {{accountGroup.name}}
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                .mt-1.float-right
                  router-link.btn.btn-light.mr-2(:to="{ name: 'x_organization_versions' }")
                    | {{ $t('actions.cancel') }}
                  button.btn.btn-success(
                    :disabled="processing || invalid"
                    @click.prevent="submit"
                  )
                    | {{ $t('actions.update') }}

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      ready: false,
      errorMessages: [],
      processing: false,
      name: '',
      code: '',
      firstLevelName: '',
      secondLevelName: '',
      thirdLevelName: '',
      accountGroupId: ''
    };
  },

  computed: {
    ...mapState('resources/x_surveys', ['accountGroups']),
    ...mapState('resources/x_organization_versions', ['organizationVersion']),
    organizationVersionParams() {
      return {
        id: this.organizationVersion.id,
        organization_version: {
          name: this.name,
          code: this.code,
          organization_first_level_name: this.firstLevelName,
          organization_second_level_name: this.secondLevelName,
          organization_third_level_name: this.thirdLevelName,
          account_group_id: this.accountGroupId
        }
      };
    }
  },

  watch: {
    organizationVersion: function() {
      this.name = this.organizationVersion.name;
      this.code = this.organizationVersion.code;
      this.firstLevelName = this.organizationVersion.organization_first_level_name;
      this.secondLevelName = this.organizationVersion.organization_second_level_name;
      this.thirdLevelName = this.organizationVersion.organization_third_level_name;
      this.accountGroupId = this.organizationVersion.account_group_id;
    }
  },

  created() {
    this.load();
    this.editOrganizationVersion(this.$route.params.id).then(() => {
      this.ready = true;
    });
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_surveys', ['loadAccountGroups']),
    ...mapActions('resources/x_organization_versions', [
      'editOrganizationVersion',
      'updateOrganizationVersion'
    ]),

    load() {
      this.loadAccountGroups();
    },

    resetErrorMessages() {
      this.errorMessages = [];
    },

    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.updateOrganizationVersion(this.organizationVersionParams)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'x_organization_versions' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
