<template lang="pug">
  .vc-content-main-box-voice(:class="{'box-with-attrs': displayAttrs}")
    label.attribute-value-names
      span(v-if="displayAttrs")
        span.mr-2(v-for="name in item.attribute_value_names")
          | {{ name }}
    input.hide-trigger(
      type="checkbox"
      :id="'hide' + item.id"
      :value="item.id"
      v-model="textId"
      v-if="displayHideBtn"
    )
    label.hide-btn(:for="'hide' + item.id" v-if="displayHideBtn")
    .vc-content-main-box-voice-box(:id="'vc-content-main-box-voice-box-'+item.id")
      .row
        .col-12
          p(:id="'vc-content-main-box-voice-text-'+item.id")
            span(v-for="opinion in item.contents" v-html="opinion+'。'")

</template>
<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
      default: null
    },
    textIds: {
      type: Array,
      required: true,
      default: null
    },
    displayAttrs: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      displayHideBtn: false
    };
  },
  computed: {
    textId: {
      get() {
        if (!this.textIds || !this.item) return null;
        else return this.textIds.includes(this.item.id);
      },
      set(_) {
        this.$emit('input', this.item.id);
      }
    }
  },
  watch: {
    item() {
      this.setDisplayHideBtn();
    }
  },
  mounted() {
    this.setDisplayHideBtn();
  },
  methods: {
    setDisplayHideBtn() {
      this.displayHideBtn = false;
      const el = document.getElementById(
        'vc-content-main-box-voice-text-' + this.item.id
      );
      const wrapEl = document.getElementById(
        'vc-content-main-box-voice-box-' + this.item.id
      );
      const actionEl = document.getElementById('action-btns-' + this.item.id);
      actionEl.classList.add('mt-1');
      if (el.offsetHeight < 86) {
        wrapEl.style.height = null;
      } else this.displayHideBtn = true;
    }
  }
};
</script>
<style scoped>
.vc-content-main-box .hide-trigger ~ .vc-content-main-box-voice-box {
  margin-bottom: 1.5rem !important;
}
.vc-content-main-box-voice .vc-content-main-box-voice-box p {
  margin-bottom: 0 !important;
  line-height: 1.9;
  letter-spacing: 0.045rem;
  padding: 0 1.75rem 18px;
}
.vc-content-main-box .vc-content-main-box-voice .attribute-value-names {
  position: absolute;
  bottom: -0.5rem;
  z-index: 1;
  width: 100%;
  text-align: left;
  padding-left: 1.75rem;
  font-size: 14px;
  line-height: 24px;
  color: #444;
}
.box-with-attrs {
  padding-bottom: 18px;
}
</style>
