import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_classification_requests');

export default {
  namespaced: true,

  state: {
    requests: []
  },

  getters: {},

  actions: {
    loadRequests({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          let requests = response.body.requests;
          commit('setRequests', requests);
        }
        return response;
      });
    }
  },

  mutations: {
    setRequests(state, requests) {
      state.requests = requests;
    }
  }
};
