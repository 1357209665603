<template lang="pug">
  .interview-timeline-item(
    :class="{ 'itsme': itsme() }"
  )
    .row
      .col-6
        .interview-timeline-flex
          p
            | {{ chat.commenter.name }}
      .col-6.text-right
        .interview-timeline-item-time
          time
            | {{ chat.created_at | moment('YY/MM/DD HH:mm:ss') }}

    .interview-timeline-item-text
      pre.p-0.mb-0(v-show="!editMode")
        | {{ chat.contents }}
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';

export default {
  components: {
    ErrorMessage
  },

  props: {
    chat: { type: Object, required: true }
  },

  data() {
    return {
      editMode: false,
      processing: false,
      errorMessages: [],
      chatContents: ''
    };
  },

  computed: {
    ...mapState('config', ['operator']),

    /**
     * Userの作成したコメントかどうか
     * @return {boolean}
     */
    isMyComment() {
      return this.chat.commenter.commenter_type === 'Responder';
    },

    /**
     * User以外が作成したコメントかどうか
     * @return {boolean}
     */
    isNotMyComment() {
      return this.chat.commenter.commenter_type !== 'Responder';
    },

    /**
     * サーバサイドに渡す chat_interview のパラメータを生成
     *
     * @return {object}
     */
    itemParams() {
      return {
        inquiry_id: this.$route.params.inquiry_id,
        id: this.chat.id,
        chat_interview: {
          contents: this.chatContents
        }
      };
    }
  },

  created() {
    this.chatContents = this.chat.contents;
  },

  methods: {
    ...mapActions('resources/u_chat_interviews', ['updateChatInterview']),
    ...mapMutations('resources/u_chat_interviews', ['setChatInterviews']),

    /**
     * 編集モードON
     */
    activateEditMode() {
      this.editMode = true;
    },

    /**
     * 編集モードOFF
     */
    cancelEditMode() {
      this.editMode = false;
    },

    resetErrorMessages() {
      this.errorMessages = [];
    },

    /**
     * インタビュー内容を更新する
     */
    updateItem() {
      this.processing = true;
      this.resetErrorMessages();
      this.updateChatInterview(this.itemParams)
        .then(response => {
          if (response.status === 200) {
            // NOTE : 更新の場合、item単体ではなく、全itemを更新してしまう
            this.setChatInterviews(response.body.chat_interviews);
            this.editMode = false;
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },

    /**
     * インタビューを削除する
     */
    removeItem() {
      this.$parent.$emit('remove-chat-item', this.chat);
    },

    onClickImage() {
      this.$parent.$emit('show-image-modal', this.chat.attachment_url);
    },

    itsme() {
      return this.operator.id === this.chat.commenter.id;
    }
  }
};
</script>
<style scoped>
.vc .interview-timeline-item-text {
  margin-left: 0 !important;
}
.itsme {
  margin: 0 0 15px auto;
  text-align: right;
}
.interview-timeline-item-text pre {
  white-space: pre-wrap;
  text-align: left;
}
</style>
