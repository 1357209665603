<template lang="pug">
  div
    .row.vs-row-ex-heatmap.vc-respondent-table(v-if="visible")
      .col-2.text-left.vs-col-ex-heatmap-header-first
        | チーム/組織課題分類
      .col-10
        .row
          .col.vs-col-ex-heatmap-header(
            v-for="cls in opinionClassifications"
          )
            | {{ cls['name'] }}
          .col.vs-col-ex-heatmap-header
            | 小計
    .row.vs-row-ex-heatmap.vc-respondent-table(v-show="currentOrganizationIds.includes('all')")
      .col-2.vs-col-ex-heatmap.vs-col-ex-heatmap-first.text-left.overflow-hidden.text-nowrap 全体
      .col-10
        .row.vc-respondent-table-num
          .col.vs-col-ex-heatmap(
            v-for="cls in opinionClassifications"
          )
            .vs-ex-heatmap-data
              a.vs-ex-heatmap-data.dropdown-toggle(
                type="button"
                data-toggle="dropdown"
                :style="getStyle('subtotal', cls['id'])"
              )
                | {{ cellValue('subtotal', cls['id']) }}
              .dropdown-menu
                a.dropdown-item(
                  @click.prevent="toOpinionSearch(null, cls['id'])"
                )
                  | 意見検索
          .col.vs-col-ex-heatmap
            .vs-ex-heatmap-data
              | {{ cellValue('subtotal', 'subtotal') }}
    .row.vs-row-ex-heatmap.vc-respondent-table(
      v-for="organization in organizations"
      v-show="currentOrganizationIds.includes(organization['id']) && organizationHierarchy === organization['organization_hierarchy']"
    )
      .col-2.vs-col-ex-heatmap.vs-col-ex-heatmap-first.text-left.overflow-hidden.text-nowrap
        | {{ organization['name'] }}
      .col-10
        .row.vc-respondent-table-num
          .col.vs-col-ex-heatmap(
            v-for="cls in opinionClassifications"
          )
            .vs-ex-heatmap-data
              a.vs-ex-heatmap-data.dropdown-toggle(
                type="button"
                data-toggle="dropdown"
                :style="getStyle(organization['id'], cls['id'])"
              )
                | {{ cellValue(organization['id'], cls['id']) }}
              .dropdown-menu
                a.dropdown-item(
                  @click.prevent="toOpinionSearch(organization['id'], cls['id'])"
                )
                  | 意見検索
          .col.vs-col-ex-heatmap
            .vs-ex-heatmap-data
              | {{ cellValue(organization['id'], 'subtotal') }}
    //- .row.vs-row-ex-heatmap-last.vc-respondent-table
      .col-2.vs-col-ex-heatmap.vs-col-ex-heatmap-first.text-left.overflow-hidden.text-nowrap
        | 小計
      .col-10
        .row.vc-respondent-table-num
          .col.vs-col-ex-heatmap(
            v-for="cls in opinionClassifications"
          )
            .vs-ex-heatmap-data
              | {{ cellValue('subtotal', cls['id']) }}
          .col.vs-col-ex-heatmap
            .vs-ex-heatmap-data
              | {{ cellValue('subtotal', 'subtotal') }}
</template>

<script>
import { mapState } from 'vuex';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    opinionClassifications: {
      type: Array,
      required: true,
      default: () => []
    },
    emotionClassificationId: {
      type: Number,
      required: true,
      default: null
    },
    organizations: {
      type: Array,
      required: true,
      default: () => []
    },
    matrixData: {
      type: Object,
      required: true,
      default: null
    },
    cellMode: {
      type: String,
      required: true,
      default: 'count'
    },
    currentOrganizationIds: {
      type: Array,
      required: true,
      default: () => []
    },
    organizationHierarchy: {
      type: Number,
      required: true,
      default: 2
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('pages/u_surveys/select', ['surveyId'])
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    fetchCellData(orgId, clsId) {
      var rowObj = this.matrixData[orgId];

      if (
        rowObj !== null &&
        typeof rowObj === 'object' &&
        Object.keys(rowObj).length !== 0
      ) {
        return rowObj[clsId];
      } else {
        return null;
      }
    },

    cellValue(orgId, clsId) {
      var cell = this.fetchCellData(orgId, clsId);
      if (
        cell !== null &&
        typeof cell === 'object' &&
        Object.keys(cell).length !== 0
      ) {
        if (this.cellMode == 'count') {
          return cell['cnt'];
        } else {
          return cell['percentage'];
        }
      } else {
        return 0;
      }
    },

    cellRatio(orgId, clsId) {
      var cell = this.fetchCellData(orgId, clsId);
      var ratio = 0;

      if (
        cell !== null &&
        typeof cell === 'object' &&
        Object.keys(cell).length !== 0
      ) {
        ratio = cell['ratio'];
      }

      return ratio;
    },

    getStyle(orgId, clsId) {
      var ratio;
      if (orgId === 'all') ratio = 1;
      else ratio = this.cellRatio(orgId, clsId);
      /* eslint-disable　*/
      return this.bgColor(ratio) + "; " + this.txtColor(ratio);
      /* eslint-enable　*/
    },

    bgColor(ratio) {
      return 'background: rgba(232, 94, 94, ' + ratio + ')';
    },

    txtColor(ratio) {
      if (ratio > 0.7) {
        return 'color: #fff';
      } else {
        return '';
      }
    },

    /**
     * 意見検索へ遷移
     * @param {*} organization_id
     * @param {*} classification_id
     */
    toOpinionSearch(organization_id, classification_id) {
      const vcd = defaultCookie.get();
      this.$router.push({
        name: 'u_qualitative_opinion_search',
        params: {
          survey_id: vcd['surveyId'],
          organization_id: organization_id,
          emotion_classification: this.emotionClassificationId,
          opinion_classification: classification_id,
          backToName: 'u_qualitative_analyse_matrix'
        }
      });
    }
  }
};
</script>

<style scoped></style>
