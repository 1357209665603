import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_about_data');

export default {
  namespaced: true,

  state: {
    surveys: {},
    emotion_classifications: {},
    opinion_classifications: {}
  },

  getters: {},

  actions: {
    /**
     * データについてで使用するデータ取得
     */
    loadAboutData({ commit }) {
      return gateway.get({ action: 'index' }).then(response => {
        if (response.status === 200) {
          const surveys = response.body.surveys;
          commit('setSurveys', surveys);
          const emotion_classifications = response.body.emotion_classifications;
          commit('setEmotionClassifications', emotion_classifications);
          const opinion_classifications = response.body.opinion_classifications;
          commit('setOpinionClassifications', opinion_classifications);
        }
        return response;
      });
    }
  },

  mutations: {
    setSurveys(state, surveys) {
      state.surveys = surveys;
    },
    setEmotionClassifications(state, emotion_classifications) {
      state.emotion_classifications = emotion_classifications;
    },
    setOpinionClassifications(state, opinion_classifications) {
      state.opinion_classifications = opinion_classifications;
    }
  }
};
