import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_chat_interviews');

export default {
  namespaced: true,

  state: {
    survey: null,
    characteristics: [],
    responder: null,
    chatInterviews: [],
    todoCategories: [],
    inquiry: null
  },

  getters: {},

  actions: {
    /**
     * ChatInterview一覧取得
     */
    loadChatInterviews({ commit }, inquiry_id) {
      return gateway.get({ action: 'index', inquiry_id }).then(response => {
        if (response.status === 200) {
          const chatInterviews = response.body.chat_interviews;
          const inquiry = response.body.inquiry;
          commit('setChatInterviews', chatInterviews);
          commit('setInquiry', inquiry);
        }
        return response;
      });
    },

    /**
     * ChatInterviewの更新
     * @param {*} _
     * @param {*} params パラメーター
     */
    updateChatInterview(_, params) {
      return gateway
        .put(
          {
            action: 'update',
            inquiry_id: params.inquiry_id,
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    /**
     * ChatInterviewの削除
     * @param {*} _
     * @param {*} params パラメーター
     */
    removeChatInterview(_, params) {
      return gateway
        .delete({
          action: 'remove',
          inquiry_id: params.inquiry_id,
          id: params.id
        })
        .then(response => {
          return response;
        });
    },

    /**
     * CSV出力依頼
     * @param {*} _
     * @param {*} params
     */
    requestExportCsv(_, params) {
      return gateway
        .post(
          {
            action: 'export_csv',
            inquiry_id: params.inquiry_id
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    /**
     * PDF出力依頼
     * @param {*} _
     * @param {*} params
     */
    requestExportPdf(_, params) {
      return gateway
        .put(
          {
            action: 'export_pdf',
            inquiry_id: params.inquiry_id
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    /**
     * 質問の完了
     * @param {*} _
     * @param {*} params
     */
    requestCompleteInquiry(_, params) {
      return gateway
        .put(
          {
            action: 'complete',
            inquiry_id: params.inquiry_id
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    requestUpdateMemo(_, params) {
      return gateway
        .put(
          {
            action: 'update_memo',
            inquiry_id: params.inquiry_id
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    updateStatus(_, inquiry_id) {
      return gateway
        .put({
          action: 'update_status',
          inquiry_id: inquiry_id
        })
        .then(response => {
          return response;
        });
    }
  },

  mutations: {
    setSurvey(state, survey) {
      state.survey = survey;
    },

    setCharacteristics(state, charas) {
      state.characteristics = charas;
    },

    setResponder(state, responder) {
      state.responder = responder;
    },

    setChatInterviews(state, chatInterviews) {
      state.chatInterviews = chatInterviews;
    },

    setChatInterview(state, chatInterview) {
      state.chatInterviews.push(chatInterview);
    },

    setTodoCategories(state, todoCategories) {
      state.todoCategories = todoCategories;
    },

    addTodoCategory(state, category) {
      state.todoCategories.push(category);
    },

    /**
     * chatInterviewsのリストから削除アイテムを取り除く
     * @param {*} state
     * @param {*} params
     */
    spliceChatInterview(state, params) {
      state.chatInterviews.splice(state.chatInterviews.indexOf(params.item), 1);
    },

    setInquiry(state, inquiry) {
      state.inquiry = inquiry;
    }
  }
};
