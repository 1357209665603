<script>
import { Pie, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    options: { type: Object, required: true }
  },
  watch: {
    chartData() {
      this.$data._chart.update();
    }
  },
  mounted() {
    this.addPlugin({
      beforeDraw: function(c) {
        var ctx = c.chart.ctx;
        ctx.fillStyle = 'rgba(255,255,255,1)';
        ctx.fillRect(0, 0, c.chart.width, c.chart.height);
      },
      afterDatasetsDraw: function(chart) {
        // To only draw at the end of animation, check for easing === 1
        var ctx = chart.ctx;
        chart.data.datasets.forEach(function(dataset, i) {
          var meta = chart.getDatasetMeta(i);
          if (!meta.hidden) {
            meta.data.forEach(function(element, index) {
              // Draw the text in black, with the specified font
              ctx.fillStyle = 'rgb(30, 30, 30)';

              var fontSize = 9;
              var fontStyle = 'normal';
              var fontFamily = 'Helvetica Neue';
              ctx.font = Chart.helpers.fontString(
                fontSize,
                fontStyle,
                fontFamily
              );

              // Make sure alignment settings are correct
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';

              var model = element._model,
                total = meta.total,
                mid_radius =
                  model.innerRadius +
                  (model.outerRadius - model.innerRadius) / 2,
                start_angle = model.startAngle,
                end_angle = model.endAngle,
                mid_angle = start_angle + (end_angle - start_angle) / 2;

              // We calculate the right positions for our text
              var x = mid_radius * 1.5 * Math.cos(mid_angle);
              var y = mid_radius * 1.5 * Math.sin(mid_angle);

              // We calculate the percentage
              var percent =
                String(Math.round((dataset.data[index] / total) * 100)) + '%';
              // And display it

              // var lavel = chart.data.labels[index].toString();

              if (dataset.data[index] > 0) {
                ctx.fillText(percent, model.x + x, model.y + y + fontSize / 2);
                // ctx.fillText(
                //   lavel,
                //   model.x + x,
                //   model.y + y - fontSize / 2 - 3
                // );
              }
            });
          }
        });
      }
    });
    let options = this.options;

    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, options);
  }
};
</script>
