<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | 設問データ作成
  .row.flex-grow
    .col-6
      .card
        .card-body
          form
            error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
            validation-observer(v-slot="{ invalid }")
              validation-provider(name="code" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問コード
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="code"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="title" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問タイトル
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="title"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="shortTitle" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問タイトル（短縮版）
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="shortTitle"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="questionText" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問文
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="questionText"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="description" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問説明文
                  textarea(
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    rows="10"
                    v-model="description"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="questionType" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問タイプ
                  select(
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="questionType"
                  )
                    option(
                      value="company"
                      key="company"
                    )
                      | 会社への意見の質問
                    option(
                      value="etc"
                      key="etc"
                    )
                      | その他ＦＡの質問
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="targetCount" rules="numeric|required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.targetCount') }}
                  input(
                    type="text"
                    placeholder="対象者数を入力してください。"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="targetCount"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              .mt-1.float-right
                router-link.btn.btn-light.mr-2(:to="{ name: 'x_questions' }")
                  | {{ $t('actions.cancel') }}
                button.btn.btn-success(
                  :disabled="processing || invalid"
                  @click.prevent="submit"
                )
                  | {{ $t('actions.create') }}

</template>
<script>
import { mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';

export default {
  components: {
    ErrorMessage
  },
  data() {
    return {
      code: '',
      title: '',
      shortTitle: '',
      questionText: '',
      description: '',
      questionType: '',
      targetCount: '',
      errorMessages: [],
      processing: false
    };
  },
  computed: {
    questionParams() {
      return {
        question: {
          survey_id: this.$route.params.id,
          code: this.code,
          title: this.title,
          short_title: this.shortTitle,
          question_text: this.questionText,
          description: this.description,
          question_type: this.questionType,
          target_count: this.targetCount
        }
      };
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    ...mapActions('resources/x_questions', ['createQuestion']),
    resetErrorMessages() {
      this.errorMessages = [];
    },
    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.createQuestion(this.questionParams)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'x_questions' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
