import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_select_account_group');

export default {
  namespaced: true,

  state: {
    accountGroups: []
  },

  getters: {},

  actions: {
    /**
     * マイページのデータ取得
     */
    loadAccountGroups({ commit }) {
      return gateway.get({ action: 'index' }).then(response => {
        if (response.status === 200) {
          const accountGroups = response.body.account_groups;
          commit('setAccountGroups', accountGroups);
        }
        return response;
      });
    },

    updateMainAccountGroup(_, params) {
      return gateway
        .put(
          {
            action: 'update_account_group',
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    }
  },

  mutations: {
    setAccountGroups(state, accountGroups) {
      state.accountGroups = accountGroups;
    }
  }
};
