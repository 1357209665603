import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_opinion_search');

function attributes() {
  return {
    organizationId: '',
    opinionArr: {},
    conditions: {},
    attributeValues: [],
    emotionValues: '',
    opinionValues: '',
    totalAttributeValues: [],
    totalEmotionValues: [],
    totalOpinionValues: [],
    checkedEmotionValues: '',
    checkedOpinionValues: '',
    page: 1,
    per: 50,
    totalCount: 0,
    keywords: '',
    quickKeywords: [],
    orAnd: 'OR',
    message: '',
    totalResId: [],
    keepedAttributeValues: [],
    keepedEmotionValues: '',
    sort: 0,
    responderCount: -1,
    responses: [],
    nodes: [],
    nodeSearchTypes: [],
    opinionCount: 0,
    opinionClassifications: [],
    emotionClassifications: [],
    displayOc: false
  };
}

export default {
  namespaced: true,

  state: attributes(),

  getters: {},

  actions: {
    /**
     * 意見取得
     */
    loadOpinions({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          const opinionArr = response.body.opinion_arr;
          const totalCount = parseInt(response.body.total_count);
          const responderCount = parseInt(response.body.responder_count);
          const opinionCount = parseInt(response.body.opinion_count);
          const displayOc = response.body.display_oc;
          commit('setOpinionArr', opinionArr);
          commit('setTotalCount', totalCount);
          commit('setResponderCount', responderCount);
          commit('setOpinionCount', opinionCount);
          commit('setQuickKeywords', []);
          commit('setTotalResId', opinionArr);
          commit('setDisplayOc', displayOc);
        }
        return response;
      });
    },

    /**
     * さらに表示
     */
    addMoreOpinions({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          const opinionArr = response.body.opinion_arr;
          const totalCount = parseInt(response.body.total_count);
          commit('addOpinionArr', [opinionArr, totalCount]);
          commit('setTotalResId', opinionArr);
        }
        return response;
      });
    },

    /**
     * 回答属性取得
     */
    loadConditions({ commit }) {
      return gateway.get({ action: 'conditions' }).then(response => {
        if (response.status === 200) {
          const conditions = response.body.conditions;
          commit('setConditions', conditions);
          commit('setTotalValues', conditions);
        }
        return response;
      });
    },

    /**
     * CSV出力
     */
    exportCsv({ commit }, params) {
      return gateway.put({ action: 'export_csv' }, params).then(response => {
        if (response.status === 200) {
          const message = response.body.message;
          commit('setMessage', message);
        }
        return response;
      });
    },

    /**
     * PDF出力
     */
    exportPdf({ commit }, params) {
      return gateway.put({ action: 'export_pdf' }, params).then(response => {
        if (response.status === 200) {
          const message = response.body.message;
          commit('setMessage', message);
        }
        return response;
      });
    },

    loadDefaultOrganizationId(_, params) {
      return gateway
        .get({ action: 'default_organization_id' }, params)
        .then(response => {
          return response;
        });
    },

    loadSurveyResponses({ commit }, params) {
      return gateway
        .get({ action: 'other_survey_responses' }, params)
        .then(response => {
          if (response.status === 200) {
            const responses = response.body.responses;
            commit('setSurveyResponses', responses);
          }
          return response;
        });
    },

    loadClassifications({ commit }, params) {
      return gateway
        .get({ action: 'total_classifications' }, params)
        .then(response => {
          if (response.status === 200) {
            const classifications = response.body.classifications;
            commit('setClassifications', classifications);
          }
          return response;
        });
    },

    requestClassifications(_, params) {
      return gateway
        .put({ action: 'request_classifications' }, params)
        .then(response => {
          return response;
        });
    }
  },

  mutations: {
    setOrganizationId(state, organizationId) {
      state.organizationId = organizationId;
    },

    setOpinionArr(state, opinionArr) {
      state.opinionArr = opinionArr;
    },

    setTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    setResponderCount(state, responderCount) {
      state.responderCount = responderCount;
    },

    setOpinionCount(state, opinionCount) {
      state.opinionCount = opinionCount;
    },

    setConditions(state, conditions) {
      state.conditions = conditions;
    },

    setTotalValues(state, conditions) {
      Object.values(conditions.attribute_values).forEach(val =>
        val.forEach(val2 => state.totalAttributeValues.push(val2.id))
      );
      Object.values(conditions.emotion_values).forEach(val =>
        state.totalEmotionValues.push(val)
      );
      Object.values(conditions.opinion_values).forEach(val =>
        state.totalOpinionValues.push(val)
      );
    },

    setAttributeValues(state, attributeValues) {
      state.attributeValues = attributeValues;
    },

    setEmotionValues(state, emotionValues) {
      state.emotionValues = emotionValues;
    },

    setOpinionValues(state, opinionValues) {
      state.opinionValues = opinionValues;
    },

    addOpinionArr(state, opinionValues) {
      state.opinionArr = [...state.opinionArr, ...opinionValues[0]];
      state.totalCount = opinionValues[1];
    },

    setPer(state, per) {
      state.per = per;
    },

    setCheckedEmotionValues(state, values) {
      state.checkedEmotionValues = values;
    },

    setCheckedOpinionValues(state, values) {
      state.checkedOpinionValues = values;
    },

    incrementPage(state) {
      state.page += 1;
    },

    resetPage(state) {
      state.page = 1;
    },

    setKeywords(state, keywords) {
      state.keywords = keywords;
    },

    setQuickKeywords(state, keywords) {
      state.quickKeywords = keywords;
    },

    setOrAnd(state, orAnd) {
      state.orAnd = orAnd;
    },

    setMessage(state, message) {
      state.message = message;
    },

    setTotalResId(state, opinionArr) {
      state.totalResId = opinionArr.map(x => x.id);
    },

    initializeState(state) {
      const attr = attributes();
      Object.keys(attr).forEach(key => {
        if (state[key]) state[key] = attr[key];
      });
    },

    setKeepedAttributeValues(state, avs) {
      state.keepedAttributeValues = avs;
    },

    setKeepedEmotionValues(state, evs) {
      state.keepedEmotionValues = evs;
    },

    setSort(state, sort) {
      state.sort = sort;
    },

    setSurveyResponses(state, responses) {
      state.responses = responses;
    },

    setNodes(state, nodes) {
      state.nodes = nodes;
    },

    setNodeSearchTypes(state, types) {
      state.nodeSearchTypes = types;
    },

    setClassifications(state, classifications) {
      state.opinionClassifications = classifications[0];
      state.emotionClassifications = classifications[1];
    },

    updateResponse(state, response) {
      const currentRes = state.opinionArr.find(r => r.code === response.code);
      let opinionArr = state.opinionArr;
      let idx = opinionArr.indexOf(currentRes);
      opinionArr[idx] = response;
      state.setOpinionArr(opinionArr);
    },

    setDisplayOc(state, bool) {
      state.displayOc = bool;
    }
  }
};
