<template lang="pug">
  .vc-content-col
    .container
      .vc-quick-access
        .row
          .col-11
            p.vc-contet-col-head キーワード絞り込み
          .col-1.text-right
            img.keywords-hide-btn.ml-2(
              src="../../../assets/images/excheck/chevron-down.png" width="18"
              :class="{'reverse': displayKeywords}"
              @click.prevent="toggleKeywords"
            )
        .vc-content-main-wrap(v-if="displayKeywords && conditions.quick_access.length > 0")
          .row.align-items-center
            .col-3 クイックアクセス
            .col-9
              ul.vc-quick-access-list.p-0
                li(v-for="[name, keywords] in conditions.quick_access")
                  a(href="javascript:void(0)" @click.prevent="quickAccess(keywords)")
                    | {{ name }}
        .vc-content-main-wrap(:class="{'mt-3': displayKeywords && conditions.quick_access.length > 0}" v-show="displayKeywords")
          .form-group.row.align-items-center
            label.col-3.col-form-label(for="exampleInputPassword1") 検索ワード
            .col-6
              input#exampleInputPassword1.form-control.m-0.keyword-search-input(type="text" v-model="currentKeywords" @keydown.enter="keywordSearch")
            .col-3
              .custom-control.custom-checkbox.vc-keyword-check
                input#customCheck-or-and-1.custom-control-input(type="radio" name="vc-or-and" v-model="currentOrAnd" value="OR" checked="")
                label.custom-control-label(for="customCheck-or-and-1") OR
              .custom-control.custom-checkbox.vc-keyword-check
                input#customCheck-or-and-2.custom-control-input(type="radio" name="vc-or-and" v-model="currentOrAnd" value="AND")
                label.custom-control-label(for="customCheck-or-and-2") AND
          .row
            .col-3.offset-6.mt-3
              button.btn.btn-primary.btn-block.vc-primary-btn.gray-btn(@click.prevent="clearKeyword") クリア
            .col-3.mt-3
              button.btn.btn-primary.btn-block.vc-primary-btn.red-btn(@click.prevent="keywordSearch") 絞り込み検索
      .vc-content-main.mt-4
        .row.align-items-center
          .col-6
            p.vc-contet-col-head 従業員の声
          .col-6.text-right
            ul.vc-content-quantity
              li
                a(href="javascript:void(0)" :class="{'font-weight-bold': per === 50}" @click.prevent="paginate(50)") 50件
              li
                a(href="javascript:void(0)" :class="{'font-weight-bold': per === 100}" @click.prevent="paginate(100)") 100件
              li
                a(href="javascript:void(0)" :class="{'font-weight-bold': per === 200}" @click.prevent="paginate(200)") 200件
        .vc-content-main-wrap(v-if="!started || (started && responderCount < 0)")
          .row.align-items-center.mb-1
            .col-6
              | 読み込み中です。しばらくお待ちください。
        .vc-content-main-wrap(v-else-if="(responderCount > -1 && responderCount <= diplayableNum()) && !currentResponseId")
          .vc-content-main-box
            | 回答数が{{diplayableNum()}}以下の場合は表示できません。分析対象の組織を選びなおしてください。
        .vc-content-main-wrap(v-else)
          .row.align-items-center.mb-1
            .col-6
              | 回答者数 {{ totalCount }}件（意見数 {{ opinionCount }}件）ヒットしました
            .col-6.text-right
              .form-control-sm.row.align-items-center
                .col-12.vc-caret.pr-0.pl-0
                  a(href="javascript:void(0)" @click.prevent="toggleVisibleOrgs")
                    | {{ visibleOrgs ? '組織オフ' : '組織オン' }}
                  a(href="javascript:void(0)" @click.prevent="toggleVisibleAttrs")
                    | {{ visibleAttrs ? '属性オフ' : '属性オン' }}
                  a(href="javascript:void(0)" @click.prevent="toggleTextOpen()" v-if="textIds.length == 0") 全文章を表示
                  a(href="javascript:void(0)" @click.prevent="toggleTextOpen()" v-else) 全文章を非表示
                  span.ml-2
                    | | 
                  a(href="javascript:void(0)" @click.prevent="sortTexts(0)" :class="{'font-weight-bold': sort === 0}") ランダム順
                  a(href="javascript:void(0)" @click.prevent="sortTexts(1)" :class="{'font-weight-bold': sort === 1}") 文字数降順
                  a.mr-0.response-rank-btn(
                    v-show="displayResponseRankSort()"
                    href="javascript:void(0)"
                    @click.prevent="sortTexts(2)"
                    :class="{'font-weight-bold': sort === 2}"
                  )
                    | 具体性順
            .col-6
              ul.vc-content-quantity
                li
                  a(href="javascript:void(0)" @click="checkAll") すべて選択
                  | 　／
                li
                  a(href="javascript:void(0)" @click="uncheckAll") すべてクリア
            .col-6.mt-3
              .row
                .offset-10
                .col-2
                  .dropdown
                    a.dropdown-toggle.no-style.text-right.printout-btn(
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    )
                      | 出力する
                    .dropdown-menu(aria-labelledby="dropdownMenuButton")
                      a(class="dropdown-item" @click.prevent="exportOpinions")
                        | 選択意見をCSV出力
                      a(class="dropdown-item" @click.prevent="exportOpinionsPdf")
                        | 選択意見をPDF出力
                      a(class="dropdown-item" @click.prevent="generateCoOccurrenceNetwork")
                        | 選択意見の共起ネットワークを作成
              //- .row
              //-   .col-6
              //-     button.btn.btn-primary.vc-primary-btn.btn-block.vc-export-btn.gray-btn(@click.prevent="exportOpinions") 選択意見をCSV出力
              //-   .col-6
              //-     button.btn.btn-primary.vc-primary-btn.btn-block.vc-export-btn.gray-btn(@click.prevent="exportOpinionsPdf") 選択意見をPDF出力
          .row
            .col-12(v-show="message")
              ul.alert.alert-success
                button.close.vc-message-close(@click="resetMessage")
                  span(aria-hidden='true') ×
                li.ml-1
                  | {{ message }}
          .vc-content-main-box(v-for="(item, i) in opinionArr")
            .row
              .col-8
                .custom-control.custom-checkbox
                  input.custom-control-input(
                    type="checkbox"
                    :id="'response-check-' + item.id"
                    :value="item.id"
                    v-model="responseIds"
                  )
                  label.custom-control-label(:for="'response-check-' + item.id")
                    | {{ visibleOrgs ? item.grand_parent_organization_name : '' }}
                    span.subspan(v-if="item.grand_parent_organization_name && visibleOrgs")
                      |  >>
                    | {{ visibleOrgs ? item.parent_organization_name : '' }}
                    span.subspan(v-if="item.parent_organization_name && visibleOrgs")
                      |  >>
                    | {{ visibleOrgs ? item.organization_name : '' }}
                  //- .col-6(v-if="displayChats")
                  //-   .text-right
                  //-     span.mr-4
                  //-       | {{ inquiryStatus(item) }}
                  //-     button.btn.btn-primary.vc-primary-btn.vc-chat-btn(
                  //-       type="button"
                  //-       :class="{'btn-disabled': item.inquiry && ['invited', 'declined'].includes(item.inquiry.status)}"
                  //-       @click.prevent="chatAction(item)"
                  //-     )
                  //-       | {{ chatBtnText(item) }}
              .offset-3
              .col-1
                .text-right(:id="'action-btns-'+item.id")
                  input(type="hidden" :id="'original-sentence' + item.id" :value="item.original_sentences")
                  .dropdown
                    a.dropdown-toggle.no-style(
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    )
                      i.fa.fa-ellipsis-h(aria-hidden="true")
                    .dropdown-menu(aria-labelledby="dropdownMenuButton")
                      a(class="dropdown-item" @click.prevent="modalOpen('fix_classification_requests', item)")
                        | 分類修正リクエスト
                      a(class="dropdown-item" @click.prevent="copy(item.id)")
                        | コピー
                      a(class="dropdown-item" @click.prevent="modalOpen('show_opinion', i)")
                        | 拡大表示
                  //- span.mr-1(v-if="isTopic")
                  //-   input(type="hidden" :id="'original-sentence' + item.id" :value="item.original_sentences")
                  //-   a.vc-normal-btn-sm(href="javascript:void(0)" @click.prevent="modalOpen('other_survey_responses', item)")
                  //-     | 別調査回答を見る
                  //-     i.fas.fa-search.ml-1
                  //- span(v-if="isTopic")
                  //-   | 　|　
            div
              ResponseItem(:item="item" :textIds="textIds" :displayAttrs="displayAttrs()" @input="changeTextIds($event)")
            //-   span.ml-2(v-if="displayChats")
            //-     button.btn.btn-primary.vc-primary-btn.gray-btn.vc-chat-btn(
            //-       type="button"
            //-       :class="{'btn-disabled': !item.inquiry || ['invited', 'declined'].includes(item.inquiry.status)}"
            //-       @click.prevent="memoAction(item)"
            //-     )
            //-       | チャットメモを表示
            //- .vc-memo-wrap(v-show="displayChatMemo[item.id]")
            //-   p
            //-     | {{ item.inquiry ? item.inquiry.memo : '' }}
            //- .text-right.mt-3
            //-   button.btn.btn-primary.vc-primary-btn.vc-chat-btn(
            //-     v-show="displayChatMemo[item.id]"
            //-     type="button"
            //-     @click.prevent="editMemo(item.inquiry)"
            //-   )
            //-     | メモを編集
          .vc-submit-wrap(v-show="isLoadable()")
            button.btn.btn-primary.btn-block.vc-primary-btn.mb-2(@click.prevent="addMore")
              | さらに表示する
          //- button.btn.btn-primary.btn-block.vc-primary-btn(@click.prevent="back" :class="{'btn-disabled': !backToName}")
            | {{ backToName == 'u_opinion_class_map_dashboard' ? '<< 一つ前の画面に戻る' : '<< 分析画面に戻る'}}
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ResponseItem from '../../../components/u/response_ranking/ResponseItem';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    ResponseItem
  },

  props: {
    backToName: {
      type: String,
      default: null
    },
    backToSurveyId: {
      type: Number,
      default: null
    },
    backToOrganizationId: {
      type: Number,
      default: null
    },
    dMainView: {
      type: Boolean,
      default: null
    }
  },

  data() {
    return {
      currentOrAnd: 'OR',
      currentKeywords: '',
      responseIds: [],
      textIds: [],
      displayChatMemo: {},
      displayChats: false,
      displayKeywords: false,
      visibleOrgs: true,
      visibleAttrs: true
    };
  },

  computed: {
    ...mapState('resources/u_opinion_search', [
      'conditions',
      'opinionArr',
      'checkedEmotionValues',
      'checkedOpinionValues',
      'totalCount',
      'opinionCount',
      'page',
      'per',
      'keywords',
      'orAnd',
      'message',
      'totalResId',
      'sort',
      'responderCount'
    ]),
    ...mapState('pages/u_surveys/select', ['surveyId']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('resources/u_response_ranking', ['currentResponseId']),
    ...mapState('pages/u_organizations/select', ['started']),
    ...mapState('resources/u_quantitative', ['questionId'])
  },

  watch: {
    currentKeywords: function() {
      this.setKeywords(this.currentKeywords);
    },
    currentOrAnd: function() {
      this.setOrAnd(this.currentOrAnd);
    },
    surveyId: function() {
      this.responseIds = [];
    }
    // opinionArr: function() {
    //   this.opinionArr.forEach(response => {
    //     this.$set(this.displayChatMemo, response.id, false);
    //     // this.displayChatMemo[response.id] = false;
    //   });
    // },
    // controlSettings: function() {
    //   this.displayChatBtn();
    // }
  },

  created() {
    this.loadControlSettings();
    if (this.backToName === 'TextMinings') {
      this.currentKeywords = this.keywords;
      this.currentOrAnd = this.orAnd;
    }
  },

  methods: {
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapActions('resources/u_opinion_search', ['exportCsv', 'exportPdf']),
    // ...mapActions('resources/u_inquiries', ['requestChatInterview']),
    ...mapMutations('resources/u_opinion_search', [
      'setPer',
      'setOrAnd',
      'setKeywords',
      'setQuickKeywords',
      'setMessage',
      'setSort',
      'setOpinionArr'
    ]),
    ...mapMutations('pages/u_organizations/select', ['setStarted']),
    ...mapMutations('pages/u_surveys/select', ['setSurveyId']),

    paginate(per) {
      this.resetMessage();
      this.setPer(per);
      this.$emit('input');
    },

    addMore() {
      this.resetMessage();
      this.$emit('addMore');
    },

    isLoadable() {
      let currentCount = this.page * this.per;
      return currentCount < this.totalCount;
    },

    keywordSearch() {
      if (event.keyCode === 229) return;
      this.resetMessage();
      this.$emit('input');
    },

    quickAccess(quickKeywords) {
      this.resetMessage();
      this.setQuickKeywords(quickKeywords);
      this.currentKeywords = quickKeywords.join(' ');
      this.$emit('input');
    },

    copy(resId) {
      let originalSentence = document.querySelector(
        '#original-sentence' + resId
      );

      originalSentence.setAttribute('type', 'text');
      originalSentence.select();

      document.execCommand('copy');

      originalSentence.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },

    exportOpinions() {
      this.resetMessage();
      if (this.responseIds.length === 0) return false;

      const vcd = defaultCookie.get();
      let params = {
        survey_id: vcd['surveyId'],
        question_id: this.questionId,
        response_ids: this.responseIds,
        sort: this.sort
      };
      this.exportCsv(params);
    },

    checkAll() {
      this.responseIds = this.totalResId;
    },

    uncheckAll() {
      this.responseIds = [];
    },

    diplayableNum() {
      const name = 'number_of_displayable_data';
      if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
        return this.controlSettings[name]['values']
          ? this.controlSettings[name]['values']
          : 0;
      }
      return 0;
    },

    displayAttrs() {
      const name = 'hide_opinion_search_attributes';
      if (this.controlSettings[name] && this.controlSettings[name].enabled)
        return this.controlSettings[name].values && this.visibleAttrs;
      else return this.visibleAttrs;
    },

    resetMessage() {
      this.setMessage('');
    },

    exportOpinionsPdf() {
      this.resetMessage();
      if (this.responseIds.length === 0) return false;

      const vcd = defaultCookie.get();
      let params = {
        survey_id: vcd['surveyId'],
        question_id: this.questionId,
        response_ids: this.responseIds,
        sort: this.sort
      };
      this.exportPdf(params);
    },

    back() {
      if (this.backToName) {
        if (this.backToSurveyId) {
          this.setSurveyId(this.backToSurveyId);
        }
        if (this.backToOrganizationId || this.backToOrganizationId === 0) {
          this.setStarted(false);
          defaultCookie.set({ organizationId: this.backToOrganizationId });
        }
        if (this.backToName === 'u_dashboard') {
          this.$router.push({
            name: this.backToName,
            params: { dMainView: this.dMainView }
          });
        } else {
          this.$router.push({ name: this.backToName });
        }
      } else {
        this.$router.push({ name: 'u_compare_analyse' });
      }
    },

    clearKeyword() {
      this.setQuickKeywords([]);
      this.setKeywords('');
      this.currentKeywords = '';
      this.$emit('input');
    },

    toggleTextOpen() {
      if (this.textIds.length > 0) this.textIds = [];
      else this.textIds = this.totalResId;
    },

    sortTexts: function(sort) {
      this.setSort(sort);
      this.$emit('input');
    },

    modalOpen(type, response) {
      this.$emit('modalOpen', type, response);
    },

    toggleKeywords() {
      this.displayKeywords = !this.displayKeywords;
    },

    // inquiryStatus(response) {
    //   const inquiry = response.inquiry;
    //   if (inquiry) {
    //     const status = inquiry.status;
    //     if (status === 'created') return '[チャット未依頼]';
    //     else if (status === 'invited') return '[チャット依頼中]';
    //     else if (status === 'loggedin') return '[チャット許諾済]';
    //     else if (status === 'answering') return '[チャット中]';
    //     else if (status === 'completed') return '[チャット完了]';
    //     else return '[チャット不可]';
    //   } else return '[チャット未依頼]';
    // },

    // chatBtnText(response) {
    //   const inquiry = response.inquiry;
    //   if (inquiry) {
    //     const status = inquiry.status;
    //     if (['created', 'invited'].includes(status)) return 'チャットを依頼';
    //     else if (['loggedin', 'answering', 'declined'].includes(status))
    //       return 'チャット';
    //     else if (status === 'completed') return 'チャット結果確認';
    //     else return 'チャット';
    //   } else return 'チャットを依頼';
    // },

    // chatAction(response) {
    //   if (!response.inquiry || response.inquiry.status === 'created') {
    //     this.modalOpen('requestChatInterview', response);
    //   } else this.toChatInterview(response.inquiry);
    // },

    // toChatInterview(inquiry) {
    //   this.$router.push({
    //     name: 'u_chat_interviews',
    //     params: { inquiry_id: inquiry.code }
    //   });
    // },

    // memoAction(response) {
    //   this.$set(
    //     this.displayChatMemo,
    //     response.id,
    //     !this.displayChatMemo[response.id]
    //   );
    // },

    // editMemo(inquiry) {
    //   this.$router.push({
    //     name: 'u_chat_interviews',
    //     params: { inquiry_id: inquiry.code, edit_memo: true }
    //   });
    // },

    // displayChatBtn() {
    //   const name = 'display_chats';
    //   if (this.controlSettings[name] && this.controlSettings[name].enabled)
    //     this.displayChats = this.controlSettings[name].values;
    //   else this.displayChats = true;
    // },

    changeTextIds(id) {
      this.textIds.push(id);
    },

    displayResponseRankSort() {
      let arr = this.opinionArr.map(opi => opi.response_rank);
      let set = new Set(arr);
      let ranks = Array.from(set);
      return ranks.length > 0 && ranks[0];
    },

    toggleVisibleOrgs() {
      this.visibleOrgs = !this.visibleOrgs;
    },

    toggleVisibleAttrs() {
      this.visibleAttrs = !this.visibleAttrs;
    },

    generateCoOccurrenceNetwork() {
      if (this.responseIds.length < 1) return;

      this.$router.push({
        name: 'u_qualitative_co_occurrences',
        params: { question_id: this.questionId, response_ids: this.responseIds }
      });
    }
  }
};
</script>
<style scoped>
.vc-content-main-box .vc-chat-btn {
  font-size: 0.7rem;
  width: 150px;
  padding: 7px;
}
.vc-content-main-box .vc-memo-wrap {
  border: 1px #999 solid;
  margin-top: 15px;
  padding: 15px;
}
.vc-content-main-box .vc-memo-wrap p {
  margin: 0;
}
.keywords-hide-btn {
  cursor: pointer;
}
.keywords-hide-btn.reverse {
  -webkit-transform: rotatex(180deg);
  transform: rotatex(180deg);
}
.vc-content-col .vc-content-main-wrap .red-btn {
  color: #f3154a !important;
  border: 1px solid #f3154a !important;
}
.vc-content-col .vc-content-main-wrap .red-btn:hover {
  color: #fff !important;
  background: #f3154a !important;
  border: 1px solid #f3154a !important;
}
.vc .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #f3154a !important;
  background: #f3154a !important;
}
.response-rank-btn {
  border: 1px #999 solid;
  padding: 6px;
}
.dropdown .dropdown-toggle:after {
  content: '' !important;
}
.printout-btn {
  font-size: 12px !important;
  color: #999 !important;
}
</style>
