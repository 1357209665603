import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_quantitative_questions');

export default {
  namespaced: true,

  state: {
    quantitativeQuestionsList: [],
    survey: {},
    question: {},
    quantitativeQuestion: {}
  },

  getters: {},

  actions: {
    loadQuantitativeQuestions({ commit }, params) {
      return gateway
        .get(
          {
            action: 'index',
            survey_id: params.survey_id,
            question_id: params.question_id
          },
          params.pageParams
        )
        .then(response => {
          if (response.status === 200) {
            const quantitativeQuestionsList =
              response.body.quantitative_questions;
            const survey = response.body.survey;
            const question = response.body.question;

            commit('setQuantitativeQuestionsList', quantitativeQuestionsList);
            commit('setSurvey', survey);
            commit('setQuestion', question);
          }
          return response;
        });
    },
    loadEditQuantitativeQuestion({ commit }, id) {
      return gateway.get({ action: 'edit', id }).then(response => {
        if (response.status === 200) {
          const quantitativeQuestion = response.body.quantitative_question;
          const question = response.body.question;

          commit('setQuantitativeQuestion', quantitativeQuestion);
          commit('setQuestion', question);
        }
        return response;
      });
    },
    createQuantitativeQuestion(_, params) {
      return gateway
        .post(
          {
            action: 'create',
            question_id: params.quantitative_question.question_id
          },
          params
        )
        .then(response => {
          return response;
        });
    },
    updateQuantitativeQuestion(_, params) {
      return gateway
        .put(
          {
            action: 'update',
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },
    deleteQuantitativeQuestion(_, id) {
      return gateway.delete({ action: 'destroy', id }).then(response => {
        return response;
      });
    }
  },

  mutations: {
    setQuantitativeQuestionsList(state, quantitativeQuestionsList) {
      state.quantitativeQuestionsList = quantitativeQuestionsList;
    },
    setSurvey(state, survey) {
      state.survey = survey;
    },
    setQuestion(state, question) {
      state.question = question;
    },
    setQuantitativeQuestion(state, quantitativeQuestion) {
      state.quantitativeQuestion = quantitativeQuestion;
    }
  }
};
