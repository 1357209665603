<template lang="pug">
  div
    modal(
      ref="modalOpinionCounts"
      name="modalOpinionCounts"
      id="modalOpinionCounts"
      :minWidth="450"
      :minHeight="280"
      :width="450"
      :height="280"
      :reset="true"
      :scrollable="true"
      :pivotX="0.9"
      :pivotY="positionTop"
    )
      header.modal-header.align-items-end
        h6.modal-title.vc-midashi
          | 各チームの意見数と回答者数
        dl.respondent-num.align-items-center
          dt
            button(type="button" @click="$modal.hide('modalOpinionCounts')" class="close vc-close")
              | ×
      .row.no-gutters.modal-content
        .col-12.mt-3.mb-3
          dt
            | 分析対象意見数（回答者数）
          dd(v-if="source")
            | {{source.organization_name}}：
            | {{source.total[selectOpinionCountName] | separate}}
            | ({{source.total[selectSurveyResponsenCountName] | separate}})
          dt
            | 比較対象意見数（回答者数）
          dd(v-for="(destination, index) in destinations")
            | {{destination.organization_name}}：
            | {{destination.total[selectOpinionCountName] | separate}}
            | ({{destination.total[selectSurveyResponsenCountName] | separate}})

</template>

<script>
export default {
  props: {
    positionTop: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data() {
    return {
      source: null,
      destinations: null,
      selectOpinionCountName: null,
      selectSurveyResponsenCountName: null
    };
  },

  mounted() {},

  methods: {
    modalOpen: function(
      source,
      destinations,
      selectOpinionCountName,
      selectSurveyResponsenCountName
    ) {
      this.source = source;
      this.destinations = destinations;
      this.selectOpinionCountName = selectOpinionCountName;
      this.selectSurveyResponsenCountName = selectSurveyResponsenCountName;
      this.$modal.show('modalOpinionCounts');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-content {
  overflow-y: scroll;
  height: 75%;
  display: block;
  flex-direction: initial;
  border: none;
  padding: 1rem;
}
</style>
