import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('r_select_nickname');

export default {
  namespaced: true,

  state: {
    nicknames: [],
    inquiryCode: '',
    isChatInterviews: false
  },

  getters: {},

  actions: {
    loadNicknames({ commit }) {
      return gateway.get({ action: 'index' }).then(response => {
        if (response.status === 200) {
          const nicknames = response.body.nicknames;
          commit('setNicknames', nicknames);
        }
        return response;
      });
    },

    loadIsChatInterviews({ commit }, inquiry_id) {
      return gateway
        .get({ action: 'is_chat_interviews', id: inquiry_id })
        .then(response => {
          if (response.status === 200) {
            const isChatInterviews = response.body.is_chat_interviews;
            commit('setIsChatInterviews', isChatInterviews);
          }
          return response;
        });
    },

    updateNickname(_, params) {
      return gateway
        .put(
          {
            action: 'update'
          },
          params
        )
        .then(response => {
          return response;
        });
    }
  },

  mutations: {
    setNicknames(state, nicknames) {
      state.nicknames = nicknames;
    },

    setInquiryCode(state, code) {
      state.inquiryCode = code;
    },

    setIsChatInterviews(state, isChatInterviews) {
      state.isChatInterviews = isChatInterviews;
    }
  }
};
