export default {
  namespaced: true,

  modules: {},

  state: {
    id: null,
    email: '',
    name: '',
    accountGroupId: null,
    organizationId: null,
    roleId: null
  },

  getters: {},

  mutations: {
    setUser(state, payload) {
      if (payload.user.id != null) {
        state.id = payload.user.id;
      }

      if (payload.user.email != null) {
        state.email = payload.user.email;
      }

      if (payload.user.name != null) {
        state.name = payload.user.name;
      }

      if (payload.user.account_group_id != null) {
        state.accountGroupId = payload.user.account_group_id;
      }

      if (payload.user.organization_id != null) {
        state.organizationId = payload.user.organization_id;
      }

      if (payload.user.role_id != null) {
        state.roleId = payload.user.role_id;
      }
    }
  }
};
