<template lang="pug">
  .content-wrapper
    .content-header.d-flex.flex-column.flex-md-row
      h4.mb-0
        | {{ $t('admin.inquiries.title') }}
    .row
      .col-md-12.grid-margin.stretch-card.clearfix
        .card
          .card-body.pb-0
            .table-responsive
              table.table.table-striped
                thead
                  tr
                    th(width="80px") ID
                    th(width="250px") アカウントグループ
                    th(width="250px") 調査名
                    th(width="150px") 回答者ID
                    th(width="150px") ステータス
                    th アクション
                tbody
                  tr(v-for="inquiry in inquiries")
                    td
                      | {{ inquiry.id }}
                    td
                      | {{ inquiry.account_group_name }}
                    td
                      | {{ inquiry.survey_name }}
                    td
                      | {{ inquiry.responder.code }}
                    td(:class="inquiry.status")
                      | {{ $t('admin.inquiries.status')[inquiry.status] }}
                    td
                      button.btn.btn-outline-primary.mr-2(
                        :class="{'btn-disabled': $t('admin.inquiries.disabledStatus').includes(inquiry.status)}"
                        @click.prevent="modalOpen(inquiry, 'changeStatus')"
                      )
                        | {{ $t('admin.inquiries.changeStatus')[inquiry.status] }}
                      button.btn.btn-outline-danger(@click.prevent="modalOpen(inquiry, 'delete')")
                        | {{$t('actions.delete')}}
            .mt-1.float-right
              paginate(
                v-model="cPage"
                :page-count="pageParams.pages"
                :click-handler="clickCallback"
                containerClass="pagination"
                pageClass="page-item"
                pageLinkClass="page-link"
                prevText="«"
                prevClass="page-item"
                prevLinkClass="page-link"
                nextText="»"
                nextClass="page-item"
                nextLinkClass="page-link"
              )

    InquiryStopModal(:stopTarget="target")
    InquiryReopenModal(:reopenTarget="target")
    InquiryDeleteModal(:deleteTarget="target")
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Paginate from 'vuejs-paginate';
import InquiryStopModal from 'src/components/x/inquiries/InquiryStopModal';
import InquiryReopenModal from 'src/components/x/inquiries/InquiryReopenModal';
import InquiryDeleteModal from 'src/components/x/inquiries/InquiryDeleteModal';

export default {
  components: {
    Paginate,
    InquiryStopModal,
    InquiryReopenModal,
    InquiryDeleteModal
  },

  data() {
    return {
      defaultPer: 10,
      target: {},
      cPage: -1
    };
  },

  computed: {
    ...mapState('resources/x_inquiries', ['inquiries', 'currentPage']),
    ...mapState('pages/x_inquiries/list/pagination', ['pageParams'])
  },

  created() {
    this.setPer(this.defaultPer);
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_inquiries', ['loadInquiries']),
    ...mapMutations('pages/x_inquiries/list/pagination', [
      'setTotal',
      'setPages',
      'setPage',
      'setPer'
    ]),

    load() {
      this.loadInquiries(this.pageParams).then(response => {
        this.setTotal(response.body.page_options.total);
        this.setPages(response.body.page_options.pages);
        this.cPage = this.currentPage;
      });
    },

    clickCallback(pageNum) {
      this.setPage(pageNum);
      this.load();
    },

    modalOpen(inquiry, type) {
      this.target = inquiry;
      const stoppableStatus = ['loggedin', 'answering', 'reopened'];
      const reopenableStatus = ['completed', 'stopped'];
      if (type === 'changeStatus') {
        if (stoppableStatus.includes(inquiry.status))
          this.$bvModal.show('inquiry-stop-modal');
        else if (reopenableStatus.includes(inquiry.status))
          this.$bvModal.show('inquiry-reopen-modal');
      } else if (type === 'delete') this.$bvModal.show('inquiry-delete-modal');
    }
  }
};
</script>
<style scoped>
td.created {
  color: #999;
}
td.invited,
td.reopened {
  font-weight: bold;
}
td.loggedin {
  color: #00c3b1;
  font-weight: bold;
}
td.answering {
  font-weight: bold;
}
td.declined,
td.stopped {
  color: #cf5555;
  font-weight: bold;
}
</style>
