<template lang="pug">
.vc-main-container.container
  .vc-topic-content.mb-4
    .vc-left-col
      QualitativeOrganizationsHeatmap
  .vc-content-col
    .row.mt-2.mb-4
      .col-5
        ul.vc-parameter-nav
          li(v-for="em in emotionClassifications")
            a.vpn-all.vc-ranking-btn(
              :class="{ 'active': emotionClassificationId === em.id }"
              @click="setEmotionClassificationId(em)"
            )
              | {{ em.name }}

    .row.no-gutters.mb-5
      .col-12.vc-my-team.heatmap2-par1
        .row.vs-ex-heatmap2-box
          .vs-ex-heatmap2-org.col-6(v-for="(heatmapData, idx) in heatmapDataArray")
            .row.mb-2
              .col-12
                h4.vc-midashi.h4
                  | {{ findOrganizationFor(organizationIds[idx]) }}

            OpinionHeatmap(
              :visible="visible"
              :organization-id="organizationIds[idx]"
              :emotion-classification-id="emotionClassificationId"
              :opinion-classifications="opinionClassifications"
              :heatmap-data="heatmapData"
            )

        .vs-ex-heatmap2-scale
          .heatmap-scale-container
            .heatmap-scale
              .heatmap-scale-color.scale0-10
              .heatmap-scale-text 0-10
            .heatmap-scale
              .heatmap-scale-color.scale11-20
              .heatmap-scale-text 11-20
            .heatmap-scale
              .heatmap-scale-color.scale21-30
              .heatmap-scale-text 21-30
            .heatmap-scale
              .heatmap-scale-color.scale31-40
              .heatmap-scale-text 31-40
            .heatmap-scale
              .heatmap-scale-color.scale41-50
              .heatmap-scale-text 41-50
            .heatmap-scale
              .heatmap-scale-color.scale51-60
              .heatmap-scale-text 51-60
            .heatmap-scale
              .heatmap-scale-color.scale61-70
              .heatmap-scale-text 61-70
            .heatmap-scale
              .heatmap-scale-color.scale71-80
              .heatmap-scale-text 71-80
            .heatmap-scale
              .heatmap-scale-color.scale81-90
              .heatmap-scale-text 81-90
            .heatmap-scale
              .heatmap-scale-color.scale91-100
              .heatmap-scale-text 91-100
</template>

<script>
import { mapState, mapActions } from 'vuex';
import QualitativeOrganizationsHeatmap from '../../../components/u/qualitative/QualitativeOrganizationsHeatmap';
import OpinionHeatmap from '../../../components/u/qualitative/OpinionHeatmap';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    QualitativeOrganizationsHeatmap,
    OpinionHeatmap
  },

  data() {
    return {
      displayType: null,
      visible: false,
      emotionClassificationId: 0
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('pages/u_surveys/select', ['surveyId']),
    ...mapState('resources/u_quantitative', ['questionId']),
    ...mapState('resources/u_quantitative', ['navOrganizations']),
    ...mapState('resources/u_analyse_heatmap', [
      'opinionClassifications',
      'emotionClassifications',
      'heatmapDataArray',
      'organizationIds'
    ])
  },

  created() {
    this.$on('check-item', this.load);

    this.checkRequiredParams();
    this.loadControlSettings();
    this.setDefaultDisplayType();

    const vcd = defaultCookie.get();
    // 検索用データを取得する
    this.loadQuestions({ survey_id: vcd['surveyId'] || this.surveyId }).then(
      _response => {
        // ラベル表示に使うデータを取得する
        this.loadLabelData({
          survey_id: vcd['surveyId'] || this.surveyId
        }).then(_response => {
          // ポジネガの初期値を設定する
          this.setEmotionClassificationId(this.emotionClassifications[0]);
        });
      }
    );
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapActions('resources/u_quantitative', ['loadQuestions']),
    ...mapActions('resources/u_analyse_heatmap', [
      'loadLabelData',
      'loadHeatmapData'
    ]),

    /**
     * 必要なパラメーターが取得できなかったら、強制的に遷移させる
     */
    checkRequiredParams() {
      const vcd = defaultCookie.get();
      if (
        (vcd['surveyId'] || this.surveyId) === null ||
        (vcd['surveyId'] || this.surveyId) === '' ||
        (this.questionId || vcd['questionId']) === null ||
        (this.questionId || vcd['questionId']) === ''
      ) {
        window.location.href = this.$router.resolve({
          name: 'u_qualitative_emotion_classifications_team'
        }).href;
        return;
      }
    },

    /**
     * デフォルトの表示タイプを設定する
     */
    setDefaultDisplayType() {
      const vcd = defaultCookie.get();
      this.displayType = vcd['displayType'] || 0;
    },

    /**
     * ヒートマップデータを取得する
     */
    load() {
      const vcd = defaultCookie.get();
      const params = {
        survey_id: vcd['surveyId'] || this.surveyId,
        question_id: this.questionId || vcd['questionId'],
        emotion_classification_id: this.emotionClassificationId,
        organization_ids: this.organizationIds
      };

      this.loadHeatmapData(params).then(_response => {
        if (!this.visible) {
          this.visible = true;
        }
      });
    },

    /**
     * 感情分類を変更した時の処理
     */
    setEmotionClassificationId(em) {
      this.emotionClassificationId = em.id;
      this.load();
    },

    /**
     * 組織IDから組織名を取得する
     */
    findOrganizationFor(id) {
      if (id === undefined) {
        return '';
      }

      if (id == -1) {
        // all
        return this.$t('user.qualitative.analyse_matrix.all');
      } else {
        return this.navOrganizations.find(org => org.id === id).name;
      }
    }
  }
};
</script>

<style scoped></style>
