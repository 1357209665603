<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | 定量設問作成
  .row.flex-grow
    .col-6
      .card
        .card-body
          form
            error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
            validation-observer(v-slot="{ invalid }")
              validation-provider(name="code" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問コード
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="code"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="questionText" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問文
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="questionText"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="description" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問説明文
                  textarea(
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    rows="10"
                    v-model="description"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="questionType" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問タイプ
                  select(
                    class="form-control"
                    v-model="questionType"
                  )
                    option(
                      v-for="questionType in questionTypes"
                      :value="questionType"
                      :key="questionType"
                    )
                      | {{ questionType }}
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="graphType" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | グラフタイプ
                  select(
                    class="form-control"
                    v-model="graphType"
                  )
                    option(
                      v-for="graphType in graphTypes"
                      :value="graphType.key"
                      :key="graphType.key"
                    )
                      | {{ graphType.val }}
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              .form-group
                label
                  | 表示単位
                input.form-control(
                  type="text"
                  v-model="unit"
                )

              .mt-1.float-right
                router-link.btn.btn-light.mr-2(:to="{ name: 'x_quantitative_questions', params: { survey_id: surveyId, id: questionId } }")
                  | {{ $t('actions.cancel') }}
                button.btn.btn-success(
                  :disabled="processing || invalid"
                  @click.prevent="submit"
                )
                  | {{ $t('actions.create') }}

</template>
<script>
import { mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';

export default {
  components: {
    ErrorMessage
  },
  data() {
    return {
      code: '',
      questionText: '',
      description: '',
      questionType: '',
      graphType: '',
      unit: '',
      questionTypes: ['SA', 'MA', 'MTSA', 'MTMA', 'NPS'],
      graphTypes: [
        {
          key: 'pie',
          val: '円グラフ'
        },
        {
          key: 'horizontal',
          val: '横棒グラフ'
        },
        {
          key: 'vertical',
          val: '縦棒グラフ'
        }
      ],
      errorMessages: [],
      processing: false,
      surveyId: '',
      questionId: ''
    };
  },
  computed: {
    quantitativeQuestionParams() {
      return {
        quantitative_question: {
          question_id: this.$route.params.id,
          code: this.code,
          question_text: this.questionText,
          description: this.description,
          question_type: this.questionType,
          graph_type: this.graphType,
          unit: this.unit
        }
      };
    }
  },
  watch: {},
  created() {
    const params = this.$route.params;
    this.surveyId = params.survey_id;
    this.questionId = params.id;
  },
  mounted() {},
  methods: {
    ...mapActions('resources/x_quantitative_questions', [
      'createQuantitativeQuestion'
    ]),
    resetErrorMessages() {
      this.errorMessages = [];
    },
    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.createQuantitativeQuestion(this.quantitativeQuestionParams)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'x_quantitative_questions' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
