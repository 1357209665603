<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content.vc-dashboard
        SurveySelect
        .vc-main-container.container(v-if="!isTopic")
          .vc-dashboard-box(
            v-for="(outputOpinionClassMap, i) in outputOpinionClassMaps"
            v-if="outputOpinionClassMap.organizationName!='' && outputOpinionClassMap.AggregatedDatas.length > 0"
            :class="{'mb-4': i==0,'borderb1': i>0}"
          )
            .container-fluid
              .row(v-if="i==0")
                .col-12.vc-midashi-wrap
                  h3.vc-midashi.mb-4
                    | 意見別ネガポジ感情マップ
                  span.vc-midashi-desc.mb-4
                    | 意見分類（何についての意見か）ごとにネガポジの傾向がわかります
              .row.vc-dashboard-box-content
                .col-12.vc-dashboard-box-title
                  h4.vc-midashi.mt-3.mb-2(v-if="outputOpinionClassMap.parentOrganizationName")
                    | {{outputOpinionClassMap.parentOrganizationName}}
                    img(src="../../../assets/images/excheck/vc-icon-gt.png")
                    | {{outputOpinionClassMap.organizationName}}
                  h4.vc-midashi.mt-3.mb-2(v-else)
                    | {{outputOpinionClassMap.organizationName}}全体
                .col-12
                  p.mt-3.mb-1.small.graph-description
                    | 斜線の下側はネガティブな意見が多いチーム、上側がポジティブな意見が多いチーム
                .col-8.vc-dashboard-box-graph-wrap
                  scatter-chart(
                    :chart-data="datacollections[i]" 
                    :options="options[i]"
                    ref="scatterChart"
                    :id="'scatter-chart'+i"
                    )
                  .text-right
                    a.graph-mode.mr-1(
                      @click.prevent="changeShowAllTooltips(i)"
                      :class="{active: showAllTooltips[i]}"
                    )
                      | ラベル表示
                .col-4.vc-dashboard-box-table-wrap
                  .row.no-gutters.vc-respondent-table
                    .col-8.text-left.overflow-hidden.text-nowrap
                      | 意見分類
                    .col-4
                      .row.no-gutters
                        .col-6.vc-negative-color
                          | ネガ
                        .col-6.vc-positive-color
                          | ポジ
                        .col-6
                  .vc-dashboard-box-table-data-row.no-scroll
                    .row.no-gutters.vc-respondent-table(v-for="(AggregatedDatas, ii) in outputOpinionClassMap.AggregatedDatas")
                      .col-8.text-left.overflow-hidden.text-nowrap.table-column-name
                        div
                          span.datasetColor(
                            :style="{backgroundColor: colors[ii]}"
                          )
                          | {{AggregatedDatas.opinionClassificationName}}
                        a(@click.prevent="toOpinionSearch(outputOpinionClassMap.organizationId, AggregatedDatas.opinionClassificationId)")
                          svg.to-opinion-search(
                            xmlns="http://www.w3.org/2000/svg"
                            enable-background="new 0 0 24 24"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#000000"
                          )
                            g
                              rect(fill="none" height="24" width="24")
                            g
                              g
                                rect(height="2" width="9" x="13" y="7")
                                rect(height="2" width="9" x="13" y="15")
                                rect(height="2" width="6" x="16" y="11")
                                polygon(points="13,12 8,7 8,11 2,11 2,13 8,13 8,17")
                      .col-4
                        .row.no-gutters.vc-respondent-table-num
                          .col-6.vc-negative-color
                            | {{AggregatedDatas.negaCount | separate}}
                          .col-6.vc-positive-color
                            | {{AggregatedDatas.posiCount | separate}}
                    .row.vc-export-btn-wrap
                      .offset-9.col-3.mt-3
                        .text-right
                          .dropdown
                            a.dropdown-toggle.btn.btn-primary.vc-primary-btn.btn-block.vc-export-btn(
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              )
                              span エクスポート
                              i.fas.fa-caret-down
                            .dropdown-menu(
                              aria-labelledby="dropdownMenuButton"
                              )
                              a(
                                class="dropdown-item"
                                @click.prevent="copyImage('scatter-chart'+i)"
                                )
                                | グラフをコピー
                              a(
                                class="dropdown-item"
                                @click.prevent="downloadImage('scatter-chart'+i, outputOpinionClassMap)"
                                )
                                | グラフを画像保存
                              a#download-img-link
        .vc-main-container.container(v-if="isTopic")
          .vc-content-col
            .container
              | トピック調査については、この画面はご利用できません。調査選択から、トピック調査以外の調査を選択ください。

</template>

<script>
import { mapState, mapActions } from 'vuex';
import SurveySelect from '../../../components/u/dashboard/SurveySelect';
import ScatterChart from '../../../components/u/chartJs/ScatterChart';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    SurveySelect,
    ScatterChart
  },

  data() {
    return {
      outputOpinionClassMaps: [],
      datacollections: [],
      options: [],
      colors: [
        '#6DAFD2',
        '#C28BDD',
        '#FF6C6C',
        '#FF292B',
        '#8C4CD0',
        '#229BEE',
        '#FFFF6D',
        '#6FC8F6',
        '#FF9022',
        '#FFB757',
        '#A0EE5A',
        '#E46D2A',
        '#6DE42A',
        '#2A6DE4',
        '#A05AEE',
        '#91C2DC',
        '#CBA0E2',
        '#FC8888',
        '#FC6565',
        '#AC7FDD',
        '#64B8F2',
        '#FFFF85',
        '#8AD0F7',
        '#FFAE61'
      ],
      showAllTooltips: []
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_dashboard', ['opinionClassMap']),
    ...mapState('pages/u_surveys/select', ['surveyId', 'isTopic'])
  },

  watch: {
    opinionClassMap: function() {
      const outputOpinionClassMaps = [];
      const opinionClassMap = this.opinionClassMap;
      for (let i in opinionClassMap) {
        const AggregatedDatas = opinionClassMap[i].aggregated_datas;
        var onp = [];
        for (let ii in AggregatedDatas) {
          let data = {
            opinionClassificationId: AggregatedDatas[ii].id,
            opinionClassificationName: AggregatedDatas[ii].name,
            negaCount: AggregatedDatas[ii].negative_count,
            posiCount: AggregatedDatas[ii].positive_count
          };
          onp.push(data);
        }
        outputOpinionClassMaps.push({
          parentOrganizationName:
            i > 0 ? opinionClassMap[0].organization_name : null,
          organizationName: opinionClassMap[i].organization_name,
          organizationId: opinionClassMap[i].organization_id,
          AggregatedDatas: onp
        });
      }
      this.outputOpinionClassMaps = outputOpinionClassMaps;
      this.graphUpdate();
    },
    surveyId: function() {
      if (!this.isTopic) {
        this.loadOpinionClassMap({ survey_id: this.surveyId });
      }
    }
  },

  created() {
    if (this.surveyId && !this.isTopic) {
      this.loadOpinionClassMap({ survey_id: this.surveyId });
    }
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_dashboard', ['loadOpinionClassMap']),
    ...mapActions('resources/u_public_activities', ['createPublicActivity']),
    graphUpdate: function() {
      const outputOpinionClassMaps = this.outputOpinionClassMaps;
      const datacollections = [];
      const options = [];

      for (let i in outputOpinionClassMaps) {
        let datasets = [];
        const AggregatedDatas = outputOpinionClassMaps[i].AggregatedDatas;

        var max = 0;
        for (const ii in AggregatedDatas) {
          datasets.push({
            label: AggregatedDatas[ii].opinionClassificationName,
            data: [
              {
                x: AggregatedDatas[ii].negaCount,
                y: AggregatedDatas[ii].posiCount
              }
            ],
            // backgroundColor: 'rgba(110, 162, 178, 0.3)',
            backgroundColor: this.colors[ii],
            pointHoverRadius: 6,
            pointRadius: 6,
            // pointBorderColor: '#f3154a',
            pointBorderWidth: '2px'
          });
          if (max < AggregatedDatas[ii].negaCount)
            max = AggregatedDatas[ii].negaCount;
          if (max < AggregatedDatas[ii].posiCount)
            max = AggregatedDatas[ii].posiCount;
        }

        var pm = 10;
        for (var step = 1; step < String(max).length - 1; step++) {
          pm *= 10;
        }
        max = Math.ceil(max / pm) * pm;

        datasets.push({
          label: '',
          data: [
            {
              x: 0,
              y: 0
            },
            {
              x: max,
              y: max
            }
          ],
          borderColor: 'rgba(213,213,213,1)',
          backgroundColor: 'rgba(213,213,213,1)',
          borderDash: [3, 3],
          borderWidth: 1,
          pointRadius: 0,
          tension: 0,
          showLine: true,
          fill: false
        });

        datacollections.push({
          datasets: datasets
        });
        options.push({
          title: {
            display: false
          },
          legend: {
            display: false
          },
          showAllTooltips: this.showAllTooltips[i],
          tooltips: {
            enabled: true,
            mode: 'point',
            // yAlign: 'bottom',
            displayColors: false,
            intersect: false,
            backgroundColor: 'rgba(0,0,0,0.6)',
            filter: function(tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].label != '';
            },
            // backgroundColor: 'rgba(0,0,0,0)',
            // bodyFontColor: '#5a5a5a',
            callbacks: {
              label: function(tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                if (label) {
                  label += ', ';
                }
                label +=
                  'ネガ:' + tooltipItem.xLabel + ', ポジ:' + tooltipItem.yLabel;
                return label;
              }
            }
          },
          responsive: true,
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'ネガティブ意見数'
                },
                ticks: {
                  suggestedMin: 0,
                  beginAtZero: true,
                  max: max,
                  callback: function(value) {
                    return value;
                  }
                }
              }
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'ポジティブ意見数'
                },
                ticks: {
                  suggestedMin: 0,
                  beginAtZero: true,
                  max: max,
                  callback: function(value) {
                    return value;
                  }
                }
              }
            ]
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          }
        });

        setTimeout(() => {
          if (this.$refs.scatterChart[i]) this.$refs.scatterChart[i].update();
        }, 500);
      }

      this.datacollections = datacollections;
      this.options = options;
    },
    toOpinionSearch(organizationId, opinionClassificationId) {
      defaultCookie.set({
        surveyId: this.surveyId,
        organizationId: organizationId
      });
      this.$router.push({
        name: 'u_opinion_search',
        params: {
          opinion_classification: opinionClassificationId,
          survey_id: this.surveyId,
          backToName: 'u_opinion_class_map_dashboard',
          default: true
        }
      });
    },
    changeShowAllTooltips: function(i) {
      this.showAllTooltips[i] = this.showAllTooltips[i] ? false : true;
      this.options[i].showAllTooltips = this.showAllTooltips[i];
      this.showAllTooltips = this.showAllTooltips.slice();
      this.$refs.scatterChart[i].update();
    },

    downloadImage(id, map) {
      let chartWrap = document.getElementById(id);
      let els = chartWrap.getElementsByTagName('*');
      let canvas = {};
      for (let i = 0; i < els.length; i++) {
        if (els[i].id === 'scatter-chart') canvas = els[i];
      }
      if (canvas.width > 959 || canvas.height > 959) {
        let newCanvas = document.createElement('canvas');
        newCanvas.height = 960;
        newCanvas.width = 960;
        let context = newCanvas.getContext('2d');
        context.drawImage(canvas, 0, 0, 960, 960);
        canvas = newCanvas;
      }
      if (canvas.msToBlob) {
        let blob = canvas.msToBlob();
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let downloadLink = document.getElementById('download-img-link');
        let filename = '';
        if (map.parentOrganizationName)
          filename = map.parentOrganizationName + '_' + map.organizationName;
        else filename = map.organizationName + '_全体';
        downloadLink.href = canvas.toDataURL('image/png');
        downloadLink.download = filename + '.png';
        downloadLink.click();
      }
      const params = {
        trackable_type: 'survey',
        trackable_id: this.surveyId,
        key: 'survey.download_opinion_class_map_image'
      };
      this.createPublicActivity(params);
    },

    copyImage(id) {
      let chartWrap = document.getElementById(id);
      let els = chartWrap.getElementsByTagName('*');
      let canvas = {};
      for (let i = 0; i < els.length; i++) {
        if (els[i].id === 'scatter-chart') canvas = els[i];
      }
      let img = document.createElement('img');
      img.src = canvas.toDataURL();

      let div = document.createElement('div');
      div.contentEditable = true;
      div.appendChild(img);
      document.body.appendChild(div);

      this.selectForCopy(div);
      document.execCommand('Copy');
      document.body.removeChild(div);
      const params = {
        trackable_type: 'survey',
        trackable_id: this.surveyId,
        key: 'survey.copy_opinion_class_map_image'
      };
      this.createPublicActivity(params);
    },

    selectForCopy(element) {
      var doc = document;
      if (doc.body.createTextRange) {
        let range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        let selection = window.getSelection();
        let range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
button.small {
  font-size: 0.9em;
}
.datasetColor {
  display: inline-block;
  content: ' ';
  width: 14px;
  height: 14px;
  margin-right: 5px;
  vertical-align: middle;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle {
  background: #fff !important;
  border: 1px solid #f3154a !important;
  color: #f3154a;
  font-size: 0.85rem;
  width: 150px;
  float: right;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle span {
  margin: auto;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle:hover {
  background: #fff !important;
  border-radius: 2px;
  border: 1px solid #f3154a;
  padding: 12px;
  color: #f3154a;
  font-weight: 600;
  transition: 0.15s ease;
  white-space: nowrap;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle:after {
  content: none;
}
.vc-export-btn-wrap .dropdown .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem !important;
}
</style>
