import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_quantitative_analyse');

export default {
  namespaced: true,

  state: {
    surveys: [],
    questions: [],
    responses: [],
    question: {},
    compares: [],
    keepedAttributeValues: [],
    surveyName: '',
    prevSurveyName: '',
    responseAttributeNames: [],
    allResponses: [],
    organizations: [],
    parentIdByIds: {},
    orgLevels: [],
    displayAttributeValues: false,
    keepedSelectedOrganizations: [],
    organizationIds: [],
    started: false,
    navOrganizations: [],
    calculated: false,
    responseAttributes: [],
    analyseData: [],
    quantitativeQuestions: [],
    questionId: ''
  },

  getters: {},

  actions: {
    loadQuantitativeAnalyse({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          commit('setSurveys', response.body.surveys);
          commit('setResponses', response.body.responses);
          commit('setNavOrganizations', response.body.organizations);
          commit('setResponseAttributes', response.body.response_attributes);
          commit(
            'setQuantitativeQuestions',
            response.body.quantitative_questions
          );
        }
        return response;
      });
    },
    loadQuantitativeDetails({ commit }, params) {
      return gateway.get({ action: 'details' }, params).then(response => {
        if (response.status === 200) {
          commit('setQuestion', response.body.question);
          commit('setCompares', response.body.compares);
          commit('setResponseAttributeNames', response.body.ra_names);
          commit('setOrganizations', response.body.organizations);
          commit('setOrgLevels', response.body.org_levels);
        }
        return response;
      });
    },
    loadSurveyResponses({ commit }, params) {
      return gateway.get({ action: 'all_responses' }, params).then(response => {
        if (response.status === 200) {
          commit('setAllResponses', response.body.responses);
        }
        return response;
      });
    },
    loadRespondersInfo(_, params) {
      return gateway
        .get({ action: 'responders_info' }, params)
        .then(response => {
          if (response.status === 200) {
            return response;
          }
        });
    },
    loadOrganizations({ commit }, params) {
      return gateway.get({ action: 'organizations' }, params).then(response => {
        if (response.status === 200) {
          commit('setOrganizations', response.body.organizations);
        }
      });
    },
    loadAnalyseData({ commit }, params) {
      return gateway.get({ action: 'analyse' }, params).then(response => {
        if (response.status === 200) {
          commit('setAnalyseData', JSON.parse(response.body.analyse_data));
          commit(
            'setQuantitativeQuestions',
            response.body.quantitative_questions
          );
        }
        return response;
      });
    },
    loadQuestions({ commit }, params) {
      return gateway.get({ action: 'questions' }, params).then(response => {
        if (response.status === 200) {
          commit('setQuestions', response.body.questions);
        }
        return response;
      });
    },
    loadAnalyseDataChanges({ commit }, params) {
      return gateway
        .get({ action: 'analyse_changes' }, params)
        .then(response => {
          if (response.status === 200) {
            commit(
              'setAnalyseDataChanges',
              JSON.parse(response.body.analyse_data_changes)
            );
            commit(
              'setQuantitativeQuestions',
              response.body.quantitative_questions
            );
          }
        });
    }
  },

  mutations: {
    setSurveys(state, surveys) {
      state.surveys = surveys;
    },
    setQuestions(state, questions) {
      state.questions = questions;
    },
    setResponses(state, responses) {
      state.responses = responses;
    },
    setQuestion(state, question) {
      state.question = question;
    },
    setCompares(state, compares) {
      state.compares = compares;
    },
    setKeepedAttributeValues(state, avs) {
      state.keepedAttributeValues = avs;
    },
    setResponseAttributeNames(state, names) {
      state.responseAttributeNames = names;
    },
    setAllResponses(state, responses) {
      state.allResponses = responses;
    },
    setOrganizations(state, organizations) {
      const _organizations = {};
      const _parentIdByIds = {};
      for (const i in organizations) {
        _parentIdByIds[organizations[i].id] = organizations[i].parent_id;
        const index = organizations[i].parent_id
          ? organizations[i].parent_id
          : 0;
        if (!_organizations[index]) _organizations[index] = [];
        _organizations[index].push(organizations[i]);
      }
      state.organizations = _organizations;
      state.parentIdByIds = _parentIdByIds;
    },
    setOrgLevels(state, levels) {
      state.orgLevels = levels;
    },
    setOrganizationIds(state, organizationIds) {
      state.organizationIds = organizationIds;
    },
    setDisplayAttributeValues(state, display) {
      state.displayAttributeValues = display;
    },
    setKeepedSelectedOrganizations(state, organizations) {
      state.keepedSelectedOrganizations = organizations;
    },
    setStarted(state, started) {
      state.started = started;
    },
    setNavOrganizations(state, organizations) {
      state.navOrganizations = organizations;
    },
    setCalculated(state, calculated) {
      state.calculated = calculated;
    },
    setResponseAttributes(state, response_attributes) {
      state.responseAttributes = response_attributes;
    },
    setAnalyseData(state, data) {
      state.analyseData = data;
    },
    setQuantitativeQuestions(state, quantitative_questions) {
      state.quantitativeQuestions = quantitative_questions;
    },
    setQuestionId(state, questionId) {
      state.questionId = questionId;
    }
  }
};
