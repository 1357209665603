<template lang="pug">
  div
    .vc-top-col.vc-top-col-sub.container.mt-0(v-for="i in targetOrganizationCount")
      .vc-top-col-sub-inner
        .form-group.row.d-flex.align-items-center.mb-0
          .col-3
            .custom-control.custom-checkbox.vc-custom-checkbox
              input.custom-control-input(
                type="checkbox"
                :id="'target-organization-'+i"
                :value="i"
                v-model="selectedTargetOrganizations"
                )
              label.col-form-label.custom-control-label(:for="'target-organization-'+i")
                | 比較対象のチーム
          .col-9
            .row(
              v-if="targetOrganizations[0]"
              )
              .col-3.vc-sub-select-box
                select(
                  class="form-control vc-sub-select"
                  v-model="selected(i)[0]"
                  :disabled="!selectedTargetOrganizations.includes(i)"
                )
                  option(
                    v-if="organizationId != 0"
                    value="undefined"
                    )
                    | すべて
                  option(
                    v-for="organization in targetOrganizations[0]"
                    v-bind:value="organization.id"
                    v-bind:key="organization.id"
                  )
                    | {{organization.name}}
              .col-3.vc-sub-select-box(
                v-for="(parent_id, index) in selected(i)"
                v-bind:value="parent_id"
                v-bind:key="index"
                v-if="selected(i)[index] && targetOrganizations[parent_id]"
              )
                select(
                  class="form-control vc-sub-select"
                  v-model="selected(i)[index + 1]"
                  :disabled="!selectedTargetOrganizations.includes(i)"
                )
                  option(
                    v-if="isUseComparisonParent(parent_id)"
                    value="undefined"
                    )
                    | すべて
                  option(
                    v-for="organization in targetOrganizations[parent_id]"
                    v-bind:value="organization.id"
                    v-bind:key="organization.id"
                    v-if="isUseComparison(organization.id)"
                  )
                    | {{organization.name}}
            .row(v-else)
              .col-12
                | 対象チームがありません
    .row
      .col-10
      .col-2.text-center
        button.btn.btn-primary.vc-primary-btn.red-btn(
          :disabled="processing"
          @click.prevent="submit"
          v-if="targetOrganizations[0]"
        )
          | 適用

</template>
<script>
import { mapState } from 'vuex';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {},

  props: {
    organizationId: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data() {
    return {
      selected1: [],
      selected2: [],
      selected3: [],
      beforeSelected1: [],
      beforeSelected2: [],
      beforeSelected3: [],
      processing: false,
      targetOrganizations: [],
      started: false,
      beforeSelectOrganizationId: [],
      targetOrganizationCount: 3,
      selectedTargetOrganizations: []
    };
  },

  computed: {
    ...mapState('resources/u_organizations', [
      'organizations',
      'parentIdByIds'
    ]),
    ...mapState('pages/u_surveys/select', ['surveyId'])
  },

  watch: {
    organizationId: function() {
      this.setTargetOrganizations();
    },
    selected1: function() {
      this.setSelected(1);
    },
    selected2: function() {
      this.setSelected(2);
    },
    selected3: function() {
      this.setSelected(3);
    },
    surveyId: function() {
      if (this.started) {
        const arr = this.selectedTargetOrganizations
          .sort()
          .map(i => this['selected' + i].slice(-1)[0]);
        this.$emit('input', arr, this.targetOrganizationCount);
      }
    },
    organizations() {
      if (Object.keys(this.organizations).length) this.setTargetOrganizations();
    }
  },

  created() {},

  mounted() {
    if (Object.keys(this.organizations).length) {
      this.setTargetOrganizations();
    }
  },

  methods: {
    setTargetOrganizations() {
      const organizations = this.organizations;
      const targetOrganizations = {};
      if (organizations) {
        for (const i in organizations) {
          for (const ii in organizations[i]) {
            const index = organizations[i][ii].parent_id
              ? organizations[i][ii].parent_id
              : 0;
            if (!targetOrganizations[index]) targetOrganizations[index] = [];
            targetOrganizations[index].push(organizations[i][ii]);
          }
        }
        if (
          this.organizationId == 0 &&
          targetOrganizations[0] &&
          !this.started
        ) {
          this.selected1 = [targetOrganizations[0][0].id];
          this.selected2 = [targetOrganizations[0][1].id];
          this.selected3 = [targetOrganizations[0][2].id];
        }
      }
      if (
        this.organizationId != 0 &&
        (this.beforeSelectOrganizationId.length < 1 ||
          this.beforeSelectOrganizationId.includes(this.organizationId))
      ) {
        this.selected1 = [targetOrganizations[0][0].id];
        this.selected2 = [targetOrganizations[0][1].id];
        this.selected3 = [targetOrganizations[0][2].id];
      }
      this.beforeSelectOrganizationId = [
        this.selected1.slice(-1)[0],
        this.selected2.slice(-1)[0],
        this.selected3.slice(-1)[0]
      ];

      this.targetOrganizations = targetOrganizations;
    },
    isUseComparisonParent(parentId) {
      return this.organizationId != parentId;
    },
    isUseComparison(organizationId) {
      return !(
        this.organizationId == organizationId &&
        !this.targetOrganizations[organizationId]
      );
    },
    defaultSelect(organizationId) {
      let selected = [];
      const parentIdByIds = this.parentIdByIds;
      const organizations = this.organizations;

      var idChk = false;
      idChk_loop: for (const i in organizations) {
        for (const ii in organizations[i]) {
          if (organizations[i][ii].id == organizationId) {
            idChk = true;
            break idChk_loop;
          }
        }
      }
      if (!idChk) {
        defaultCookie.set({ targetOrganizationId: null });
        const arr = this.selectedTargetOrganizations
          .sort()
          .map(i => this['selected' + i].slice(-1)[0]);
        return this.$emit('input', arr, this.targetOrganizationCount);
      }

      [...Array(3)].map((_, i) => {
        if (organizations && parentIdByIds && organizationId) {
          selected = [organizationId];
          let targetId = organizationId;
          while (parentIdByIds[targetId]) {
            selected.unshift(parentIdByIds[targetId]);
            targetId = parentIdByIds[targetId];
          }
        }
        this['beforeSelected' + (i + 1)] = Array.from(selected);
        this.beforeSelectOrganizationId.splice(
          i,
          1,
          this['selected' + (i + 1)].slice(-1)[0]
        );
        this['selected' + (i + 1)] = selected;
      });

      const arr = this.selectedTargetOrganizations
        .sort()
        .map(i => this['selected' + i].slice(-1)[0]);
      this.$emit('input', arr, this.targetOrganizationCount);
    },
    submit() {
      this.processing = true;
      const arr = this.selectedTargetOrganizations
        .sort()
        .map(i => this['selected' + i].slice(-1)[0]);
      this.$emit('input', arr, this.targetOrganizationCount);

      defaultCookie.set({
        targetOrganizationId: [
          this.selected1.slice(-1)[0],
          this.selected2.slice(-1)[0],
          this.selected3.slice(-1)[0]
        ]
      });

      this.processing = false;
    },
    addTargetOrganization() {
      if (this.targetOrganizationCount > 2) return false;
      this.targetOrganizationCount += 1;
    },
    removeTargetOrganization() {
      if (this.targetOrganizationCount < 2) return false;
      this.targetOrganizationCount -= 1;
      const organizations = this.organizations;
      const targetOrganizations = {};
      if (organizations) {
        for (const i in organizations) {
          for (const ii in organizations[i]) {
            const index = organizations[i][ii].parent_id
              ? organizations[i][ii].parent_id
              : 0;
            if (!targetOrganizations[index]) targetOrganizations[index] = [];
            targetOrganizations[index].push(organizations[i][ii]);
          }
        }
      }
      if (this.targetOrganizationCount < 3)
        this.selected3 = [targetOrganizations[0][2].id];
      if (this.targetOrganizationCount < 2)
        this.selected2 = [targetOrganizations[0][1].id];
    },
    selected(idx) {
      return this['selected' + idx];
    },
    setSelected(idx) {
      let selected = this['selected' + idx];
      var del_index = null;
      var del_count = 0;
      if (!selected) selected = [];
      for (var i = 0; i < selected.length; i++) {
        if (selected[i] == 'undefined') selected[i] = '';
        if (del_index) {
          del_count++;
        } else {
          if (this['beforeSelected' + idx][i] != selected[i]) del_index = i + 1;
        }
      }
      if (del_index && del_count > 0) {
        selected.splice(del_index, del_count);
      }
      if (!selected.slice(-1)[0] && selected.length > 0) selected.pop();

      const _selected = selected[selected.length - 1];
      if (this.organizationId == _selected && _selected) {
        selected[selected.length] = this.targetOrganizations[_selected][0].id;
      }
      this['beforeSelected' + idx] = Array.from(selected);
      this.beforeSelectOrganizationId.splice(idx - 1, 1, selected.slice(-1)[0]);
      if (!this.started) {
        const vcd = defaultCookie.get();
        if (
          vcd &&
          vcd['targetOrganizationId'] &&
          vcd['targetOrganizationId'] != this.organizationId
        ) {
          this.defaultSelect(vcd['targetOrganizationId']);
        } else {
          const arr = this.selectedTargetOrganizations
            .sort()
            .map(i => this['selected' + i].slice(-1)[0]);
          this.$emit('input', arr, this.targetOrganizationCount);
        }
        this['selected' + idx] = selected;
        this.started = true;
      }
    }
  }
};
</script>
<style scoped>
.vc-top-col-sub-inner .vc-icon-wrap {
  font-size: 1.2rem;
  color: #444;
  cursor: pointer;
}
.vc .col-form-label.custom-control-label:after,
.vc .col-form-label.custom-control-label:before {
  top: 13px !important;
}
.vc-top-col {
  padding-top: 0px;
  border-bottom: none !important;
}
button.btn.btn-primary.vc-primary-btn.red-btn {
  color: #f3154a !important;
  border: 1px solid #f3154a !important;
}
button.btn.btn-primary.vc-primary-btn.red-btn:hover {
  color: #fff !important;
  background: #f3154a !important;
}
</style>
