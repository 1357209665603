<template lang="pug">
  .vc-main-container.container(v-show="ready")
    .vc-content-col(v-if="responseCount < 1")
      .container
        | 回答が{{diplayableNum()}}件以下は表示できません。分析対象の組織を選びなおしてください
    .vc-content-col(v-else-if="!isExists")
      .container
        | 共起ネットワークのテキストマイニングデータがありません。データをアップロードしてください。
    div(v-else)
      .vc-dashboard-box.mb-4
        .container-fluid
          .row.ml-1
            .col-12(v-if="survey.question_text")
              | {{ survey.question_text }}
            .col-12(v-else)
              | アンケートの設問文が登録されていません。
      .vc-dashboard-box.mb-4
        .container-fluid
          .row
            .col-6
            .col-6.text-right
              ul.vc-parameter-nav.mb-4
                li
                  a.vpn-nega(
                    @click.prevent="changeEmotionType('negative')"
                    :class="{'active': emotionType === 'negative'}"
                  )
                    | ネガティブ
                li
                  a.vpn-posi(
                    @click.prevent="changeEmotionType('positive')"
                    :class="{'active': emotionType === 'positive'}"
                  )
                    | ポジティブ
          .container(v-if="emotionType === 'negative' ? negaTextMinings.length < 1 : posiTextMinings.length < 1")
            | 該当するデータがありません。
          .row.no-gutters.mb-5(v-else)
            .col-5.pr-4
              img.w-100(:src='survey.nega_tm_image_url' v-if="emotionType === 'negative'")
              img.w-100(:src='survey.posi_tm_image_url' v-else)
            .col-7
              .opinion-search-form.mb-3
                .word-input
                  input.form-control(type="text" v-model="n1")
                .search-type-select
                  .vc-sub-select-box
                    select.form-control.vc-sub-select(v-model="t1")
                      option(value="OR")
                        | OR
                      option(value="AND")
                        | AND
                .word-input
                  input.form-control(type="text" v-model="n2")
                .search-type-select
                  .vc-sub-select-box
                    select.form-control.vc-sub-select(v-model="t2")
                      option(value="OR")
                        | OR
                      option(value="AND")
                        | AND
                .word-input
                  input.form-control(type="text" v-model="n3")
                .search-btn
                  button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="toOpinionSearch") 意見検索
              .row.no-gutters.vc-respondent-table
                .col-12.table-header
                  .row
                    .col-2
                      .row
                        .col-3
                        .col-9
                          | 単語数
                    .col-8
                      | 解釈コメント
              .row.no-gutters.vc-respondent-table(v-for="mining in (emotionType === 'negative' ? negaTextMinings : posiTextMinings)")
                TextMiningItems(:mining="mining" @updateComment="updateComment")
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import TextMiningItems from '../../../components/u/textMining/Items';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();
export default {
  components: {
    TextMiningItems
  },

  data() {
    return {
      n1: '',
      n2: '',
      n3: '',
      t1: '',
      t2: '',
      ready: false
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('pages/u_surveys/select', ['surveyId']),
    ...mapState('resources/u_text_mining', [
      'survey',
      'negaTextMinings',
      'posiTextMinings',
      'emotionTypes',
      'emotionType',
      'responseCount',
      'isExists',
      'node1',
      'node2',
      'node3',
      'type1',
      'type2'
    ]),
    ...mapState('resources/u_quantitative', ['questionId']),
    ...mapState('resources/u_analyse', ['isDisplay']),
    ...mapState('pages/u_organizations/select', ['started'])
  },

  watch: {
    n1() {
      this.setNode1(this.n1);
    },
    n2() {
      this.setNode2(this.n2);
    },
    n3() {
      this.setNode3(this.n3);
    },
    t1() {
      this.setType1(this.t1);
    },
    t2() {
      this.setType2(this.t2);
    },
    surveyId() {
      if (!this.started) this.loadQuestions({ survey_id: this.surveyId });
    },
    questionId() {
      this.preLoad('questionId');
    }
  },

  created() {
    this.loadControlSettings();
    this.setStarted(false);
  },

  mounted() {
    const vcd = defaultCookie.get();
    if (vcd['surveyId'] || this.surveyId)
      this.loadQuestions({ survey_id: vcd['surveyId'] || this.surveyId });
    if (vcd['surveyId'] || this.surveyId) this.preLoad('surveyId');
    if (this.questionId) this.preLoad('questionId');
  },

  methods: {
    ...mapActions('resources/u_text_mining', [
      'loadTextMining',
      'updateTextComment'
    ]),
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapActions('resources/u_quantitative', ['loadQuestions']),
    ...mapMutations('pages/u_organizations/select', ['setStarted']),
    ...mapMutations('resources/u_text_mining', [
      'setNode1',
      'setNode2',
      'setNode3',
      'setType1',
      'setType2',
      'setEmotionType'
    ]),
    load() {
      const vcd = defaultCookie.get();
      const params = {
        survey_id: vcd['surveyId'] || this.surveyId,
        question_id: this.questionId
      };
      this.loadTextMining(params).then(() => {
        this.n1 = this.node1;
        this.n2 = this.node2;
        this.n3 = this.node3;
        this.t1 = this.type1;
        this.t2 = this.type2;
        this.ready = true;
      });
      this.setStarted(true);
    },

    toOpinionSearch() {
      const vcd = defaultCookie.get();
      this.$router.push({
        name: 'u_qualitative_opinion_search',
        params: {
          survey_id: vcd['surveyId'] || this.surveyId,
          emotion_classification: this.emotionClassificationId(),
          emotion_type: this.emotionType,
          nodes: [this.node1, this.node2, this.node3],
          node_search_types: [this.type1, this.type2],
          backToName: 'u_qualitative_text_mining'
        }
      });
    },

    diplayableNum() {
      const name = 'number_of_displayable_data';
      if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
        return this.controlSettings[name]['values']
          ? this.controlSettings[name]['values']
          : 0;
      }
      return 0;
    },

    updateComment(params) {
      this.updateTextComment(params);
    },

    changeEmotionType(emotionType) {
      this.setEmotionType(emotionType);
    },

    emotionClassificationId() {
      return this.emotionTypes[
        this.emotionType === 'negative' ? 'ネガティブ' : 'ポジティブ'
      ];
    },

    preLoad(type) {
      setTimeout(() => {
        if (this.isDisplay['text_mining'].includes(this.questionId)) {
          if (type === 'surveyId') {
            const vcd = defaultCookie.get();
            this.loadQuestions({ survey_id: vcd['surveyId'] || this.surveyId });
          } else if (type === 'questionId') this.load();
        } else {
          this.$router.push({
            name: 'u_qualitative_emotion_classifications_team'
          });
        }
      }, 1000);
    }
  }
};
</script>
<style lang="scss" scoped>
.opinion-search-form .word-input {
  display: inline-block;
  width: 20%;
  margin-right: 0.3rem;
}
.opinion-search-form .search-type-select {
  display: inline-block;
  width: 10%;
  margin-right: 0.3rem;
}
.opinion-search-form .search-btn {
  display: inline-block;
  width: 15%;
  // float: right;
}
.opinion-search-form input {
  padding: 5px 8px !important;
  margin: 0;
  height: 100%;
}
.opinion-search-form select {
  padding: 5px 8px !important;
  margin: 0;
  height: 100%;
}
.opinion-search-form .btn {
  height: 100%;
}
.vc-respondent-table .table-header {
  font-weight: bold;
  text-align: left;
}
.vc-dashboard .vc-main-container {
  padding-top: 35px !important;
}
</style>
