<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | {{ $t('admin.chatGPTSentimentAnalysis.list.title') }}

  .row.flex-grow
    .col-12
      .card.mb-4
        .card-body
          .row.mb-4
            .col-3
              | ID
            .col-9
              | {{ task.id }}
          .row.mb-4
            .col-3
              | ステータス
            .col-9
              | {{ task.status }}

  .row.flex-grow
    .col-12
      .card.mb-4
        .card-body
          .row.mb-4
            .col-12
              form
                .mt-5.float-right
                  button.btn.btn-success(
                    :disabled="task.status !== 'finished' || processing"
                    @click.prevent="importResult"
                  )
                    | インポート

          .row.mb-4
            .col-12
              .mt-5.float-right
                router-link(:to="{ name: 'x_result_chatgpt_sentiment_analysis' }")
                  | 分類結果確認

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      errorMessages: [],
      processing: false
    };
  },

  computed: {
    ...mapState('resources/x_chatgpt_sentiment_analysis', ['task'])
  },

  created() {
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_chatgpt_sentiment_analysis', [
      'fetchTask',
      'requestImport'
    ]),

    load() {
      let params = this.$route.params;
      this.fetchTask(params.id);
    },

    importResult() {
      if (this.task.status !== 'finished') {
        return;
      }

      this.processing = true;
      this.requestImport(this.task).then(() => {
        this.processing = false;
      });
    }
  }
};
</script>

<style scoped></style>
