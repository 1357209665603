import select from './select';

export default {
  namespaced: true,

  modules: {
    select: select
  },

  state: {},

  getters: {},

  mutations: {}
};
