<template lang="pug">
  .card.select-wrap
    .card-header.select-account-group-header
      h4.vc-midashi 回答者名設定
    .card-body
      .row
        .col-8
          .form-group
            p.mb-2
              | チャットインタビューに表示するお名前をご入力ください。
              br
              | （実名を表示したくない場合は、ニックネームをご入力ください）
            input.form-control.nickname-input.mt-0(
              type="text"
              v-model="nickname_self"
              placeholder="お名前をご入力ください"
            )
            //- ※今後復活する可能性があるので、完全に削除せずコメントアウトをしておく
            //-
            //- p.mb-2 チャットインタビューに実名とニックネームのどちらを表示するか選んでください。
            //- .vc
            //-   .custom-checkbox
            //-     .custom-control.custom-checkbox.vc-keyword-check
            //-       input#radio-name.custom-control-input(type="radio" name="responder-name" v-model="nicknameType" value="name")
            //-       label.custom-control-label(for="radio-name") 実名使用
            //-     .custom-control.custom-checkbox.vc-keyword-check
            //-       input#radio-nickname.custom-control-input(type="radio" name="responder-name" v-model="nicknameType" value="nickname")
            //-       label.custom-control-label(for="radio-nickname") ニックネーム使用
            //- div(v-show="nicknameType === 'name'")
            //-   p.mt-3.mb-2 お名前をご入力ください。
            //-   input.form-control.nickname-input.mt-0(
            //-     type="text"
            //-     v-model="nickname_self"
            //-     placeholder="お名前をご入力ください"
            //-   )
            //- div(v-show="nicknameType === 'nickname'")
            //-   p.mt-3.mb-2 実名を表示したくない場合は、以下からニックネームを選択してください。
            //-   select.form-control(v-model="nickname")
            //-     option(:value="''")
            //-       | ニックネームを選択してください
            //-     option(
            //-       v-for="nickname in nicknames"
            //-       v-bind:value="nickname"
            //-       v-bind:key="nickname"
            //-     )
            //-       | {{ nickname }}
            .mt-3.text-right
              button.btn.btn-primary.vc-primary-btn.btn-select(
                :disabled="processing || (nicknameType === 'name' && !nickname_self) || (nicknameType === 'nickname' && !nickname)"
                @click.prevent="update"
              )
                | {{ $t('actions.select') }}
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  components: {},

  data() {
    return {
      nickname: '',
      nickname_self: '',
      nicknameType: 'name',
      processing: false
    };
  },

  computed: {
    ...mapState('resources/r_select_nickname', [
      'nicknames',
      'inquiryCode',
      'isChatInterviews'
    ])
  },

  created() {
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/r_select_nickname', [
      'loadNicknames',
      'loadIsChatInterviews',
      'updateNickname'
    ]),
    ...mapMutations('resources/r_select_nickname', ['setInquiryCode']),
    load() {
      const inquiryId = this.$route.query.inquiry_id;
      this.loadNicknames();
      this.setInquiryCode(inquiryId);
      this.loadIsChatInterviews(inquiryId);
    },

    update() {
      let currentNickname = '';
      if (this.nicknameType === 'name') currentNickname = this.nickname_self;
      else currentNickname = this.nickname;
      if (!currentNickname) return;

      this.processing = true;
      const params = {
        nickname: currentNickname
      };
      this.updateNickname(params).then(response => {
        if (response.status === 200) {
          if (this.isChatInterviews) {
            this.$router.push({
              name: 'r_chat_interviews',
              params: { inquiry_id: this.inquiryCode }
            });
          } else {
            this.$router.push({
              name: 'r_inquiry_accept',
              params: { inquiry_id: this.inquiryCode }
            });
          }
        }
      });
    }
  }
};
</script>
<style scoped>
.select-wrap {
  width: 60%;
  margin: auto;
  margin-top: 200px;
}
.vc .card .card-header.select-account-group-header {
  padding: 20px 40px;
}
.select-wrap .btn.btn-primary.vc-primary-btn.btn-select {
  padding: 0.4rem 1rem;
  cursor: pointer;
}
.nickname-input {
  height: 35px !important;
}
</style>
