<template lang="pug">
  .vc-left-col
    //- .back-btn-wrap
      button#back-btn.btn.btn-primary.btn-block.vc-primary-btn.red-btn(@click.prevent="back" :class="{'btn-disabled': !backToName}")
        | {{ backToName == 'u_opinion_class_map_dashboard' ? '<< 一つ前の画面に戻る' : '<< 分析画面に戻る'}}
    p.vc-left-col-title フィルタ
    .vc-cat
      .row
        .col-6
          button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn.red-btn(@click="checkAll") すべて選択
        .col-6
          button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn.red-btn(@click="uncheckAll") 選択解除
      .mt-2
        button.btn.btn-primary.btn-block.vc-primary-btn.red-btn(@click.prevent="submit") 絞り込み
    .vc-cat(v-for="(attrs, category, idx) in conditions.attribute_values")
      p.vc-left-head-1
        | {{ category }}
      ul
        li(v-for="attr in attrs.slice(0, 4)")
          .custom-control.custom-checkbox.vc-custom-checkbox
            input.custom-control-input(type="checkbox" :id="'attr-check-' + category + '-' + attr.id" :value="attr.id" v-model="currentAttributeValues")
            label.custom-control-label.attribute-label(:for="'attr-check-' + category + '-' + attr.id")
              | {{ attr.name }}
      a.collapsed.vc-collapse(:href="'#collapse' + idx" data-toggle="collapse" v-if="attrs.length >= 5") すべて表示
      .collapse(:id="'collapse' + idx" v-if="attrs.length >= 5")
        ul
          li(v-for="attr in attrs.slice(4)")
            .custom-control.custom-checkbox.vc-custom-checkbox
              input.custom-control-input(type="checkbox" :id="'attr-check-' + category + '-' + attr.id" :value="attr.id" v-model="currentAttributeValues")
              label.custom-control-label.attribute-label(:for="'attr-check-' + category + '-' + attr.id")
                | {{ attr.name }}
        a.collapsed.vc-collapse-open(:href="'#collapse' + idx" data-toggle="collapse") 閉じる
    p.vc-left-col-title 意見絞り込み
      .vc-cat
        p.vc-left-head-1 感情
        ul
          li(v-for="[id, name] in conditions.emotion_values")
            .custom-control.custom-checkbox.vc-custom-checkbox
              input.custom-control-input(type="radio" name="vc-radio-1" :id="'emotion-check-' + id" :value="id" v-model="currentEmotionValues")
              label.custom-control-label(:for="'emotion-check-' + id")
                | {{ name ? name : 'すべて' }}
      .vc-cat(v-show="displayOc")
        p.vc-left-head-1 意見分類
        ul
          li(v-for="[id, name] in conditions.opinion_values")
            .custom-control.custom-checkbox.vc-custom-checkbox
              input.custom-control-input(type="radio" name="vc-radio-2" :id="'opinion-check-' + id" :value="id" v-model="currentOpinionValues")
              label.custom-control-label.attribute-label(:for="'opinion-check-' + id")
                | {{ name ? name : 'すべて' }}
    .vc-submit-wrap
      .row
        .col-6
          button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="checkAll") すべて選択
        .col-6
          button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="uncheckAll") 選択解除
      .mt-2
        button.btn.btn-primary.btn-block.vc-primary-btn(@click.prevent="submit") 絞り込み
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import dc from '../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {},
  props: {
    backToName: {
      type: String,
      default: null
    },
    backToSurveyId: {
      type: Number,
      default: null
    },
    backToOrganizationId: {
      type: Number,
      default: null
    },
    dMainView: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      currentAttributeValues: [],
      currentEmotionValues: '',
      currentOpinionValues: ''
    };
  },
  computed: {
    ...mapState('resources/u_opinion_search', [
      'conditions',
      'totalAttributeValues',
      'totalEmotionValues',
      'totalOpinionValues',
      'displayOc'
    ])
  },
  watch: {
    currentAttributeValues: function() {
      this.setAttributeValues(this.currentAttributeValues);
    },
    currentEmotionValues: function() {
      this.setEmotionValues(this.currentEmotionValues);
    },
    currentOpinionValues: function() {
      this.setOpinionValues(this.currentOpinionValues);
    }
  },
  created() {
    const params = this.$route.params;
    if (
      params.attribute_values ||
      params.emotion_classification ||
      params.opinion_classification
    ) {
      if (params.attribute_values)
        this.currentAttributeValues = params.attribute_values;
      if (params.emotion_classification)
        this.currentEmotionValues = params.emotion_classification;
      if (params.opinion_classification)
        this.currentOpinionValues = params.opinion_classification;
    }
  },
  mounted() {},

  methods: {
    ...mapMutations('resources/u_opinion_search', [
      'setAttributeValues',
      'setEmotionValues',
      'setOpinionValues',
      'setKeepedAttributeValues',
      'setKeepedEmotionValues'
    ]),
    ...mapMutations('pages/u_organizations/select', ['setStarted']),
    ...mapMutations('pages/u_surveys/select', ['setSurveyId']),

    submit() {
      this.$emit('input');
    },

    checkAll() {
      this.currentAttributeValues = this.totalAttributeValues;
      this.currentEmotionValues = '';
      this.currentOpinionValues = '';
    },

    uncheckAll() {
      this.currentAttributeValues = [];
      this.currentEmotionValues = '';
      this.currentOpinionValues = '';
    },

    back() {
      if (this.backToName) {
        if (this.backToSurveyId) {
          this.setSurveyId(this.backToSurveyId);
        }
        if (this.backToOrganizationId || this.backToOrganizationId === 0) {
          this.setStarted(false);
          defaultCookie.set({ organizationId: this.backToOrganizationId });
        }
        if (this.backToName === 'u_dashboard') {
          this.$router.push({
            name: this.backToName,
            params: { dMainView: this.dMainView }
          });
        } else {
          this.$router.push({ name: this.backToName });
        }
      } else {
        this.$router.push({ name: 'u_compare_analyse' });
      }
    }
  }
};
</script>
<style scoped>
.vc-left-col .red-btn {
  color: #f3154a !important;
  border: 1px solid #f3154a !important;
}
.vc-left-col .red-btn:hover {
  color: #fff !important;
  background: #f3154a !important;
  border: 1px solid #f3154a !important;
}
.vc .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #f3154a !important;
  background: #f3154a !important;
}
</style>
