<template lang="pug">
  b-modal(
    id="quantitative-question-delete-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    title="設問の削除"
  )
    b-container
      p
        | {{$t('actions.confirm')}}
      p
        span.text-danger
          | {{ deleteTarget.question_text }}
    div(slot="modal-footer")
      button.btn.btn-secondary(@click="close")
        | {{$t('actions.close')}}
      button.btn.btn-danger.ml-2(@click.once="destroy")
        | {{$t('actions.delete')}}
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    deleteTarget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions('resources/x_quantitative_questions', [
      'deleteQuantitativeQuestion'
    ]),

    close() {
      this.$bvModal.hide('quantitative-question-delete-modal');
    },

    destroy() {
      this.deleteQuantitativeQuestion(this.deleteTarget.id).then(response => {
        if (response.status === 200) {
          this.$bvModal.hide('quantitative-question-delete-modal');
          this.$router.go({ name: 'x_quantitative_questions' });
        }
      });
    }
  }
};
</script>
