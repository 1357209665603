<template lang="pug">
  .vc-left-col
    .vc-cat
      p.vc-left-head-1 意見分類
      ul
        li(v-for="[id, name] in opinionValues")
          .custom-control.custom-checkbox.vc-custom-checkbox
            input.custom-control-input(type="radio" name="vc-radio-2" :id="'opinion-check-' + id" :value="id" v-model="currentOpiVal")
            label.custom-control-label.attribute-label(:for="'opinion-check-' + id")
              | {{ name ? name : 'すべて' }}
    .vc-submit-wrap
      .mt-2
        button.btn.btn-primary.btn-block.vc-primary-btn(@click.prevent="submit") 絞り込み
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  components: {},
  props: {},
  data() {
    return {
      currentOpiVal: ''
    };
  },
  computed: {
    ...mapState('resources/u_response_ranking', [
      'opinionValues',
      'currentOpinionValue'
    ])
  },
  watch: {
    currentOpiVal: function() {
      this.setCurrentOpinionValue(this.currentOpiVal);
    }
  },
  created() {
    this.loadOpinionValues();
    if (this.currentOpinionValue) this.currentOpiVal = this.currentOpinionValue;
  },
  mounted() {},

  methods: {
    ...mapActions('resources/u_response_ranking', ['loadOpinionValues']),
    ...mapMutations('resources/u_response_ranking', ['setCurrentOpinionValue']),
    submit() {
      this.$emit('input');
    }
  }
};
</script>
<style scoped>
.vc-left-col {
  position: sticky;
  top: 100px;
}
</style>
