<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | {{ $t('admin.surveys.new.title') }}
  .row.flex-grow
    .col-6
      .card
        .card-body
          form
            error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
            validation-observer(v-slot="{ invalid }")
              validation-provider(name="name" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.name') }}
                  input(
                    type="text"
                    placeholder="2018年7月クラシエ社インナー施策従業員調査"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="name"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="shortName" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.shortName') }}
                  input(
                    type="text"
                    placeholder="2018年7月従業員調査"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="shortName"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="serialNo" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.serialNo') }}
                  input(
                    type="text"
                    placeholder="1"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="serialNo"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="startAt" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.startAt') }}
                  input(
                    type="date"
                    placeholder="2020/06/03"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="startAt"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="endAt" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.endAt') }}
                  input(
                    type="date"
                    placeholder="2020/06/03"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="endAt"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="accountGroupId" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.accountGroup') }}
                  select(
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="accountGroupId"
                  )
                    option(
                      v-for="accountGroup in accountGroups"
                      v-bind:value="accountGroup.id"
                      v-bind:key="accountGroup.id"
                    )
                      | {{accountGroup.name}}
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="organizationVersionId" rules="required" v-slot="{ errors }")
                .form-group
                  label
                    | {{ $t('admin.surveys.organizationVersion') }}
                  select.form-control(v-model="organizationVersionId")
                    option(
                      v-for="organizationVersion in organizationVersions"
                      v-bind:value="organizationVersion.id"
                      v-bind:key="organizationVersion.id"
                    )
                      | {{organizationVersion.name}}


              validation-provider(name="questionText")
                .form-group
                  label
                    | {{ $t('admin.surveys.questionText') }}
                  textarea.form-control(
                    rows="10"
                    placeholder="質問文を入力してください。"
                    v-model="questionText"
                  )

              validation-provider(name="targetCount" rules="numeric|required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | {{ $t('admin.surveys.targetCount') }}
                  input(
                    type="text"
                    placeholder="対象者数を入力してください。"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="targetCount"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              .mt-1.float-right
                router-link.btn.btn-light.mr-2(:to="{ name: 'x_surveys' }")
                  | {{ $t('actions.cancel') }}
                button.btn.btn-success(
                  :disabled="processing || invalid"
                  @click.prevent="submit"
                )
                  | {{ $t('actions.create') }}
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';

export default {
  components: {
    ErrorMessage
  },

  data() {
    return {
      name: '',
      shortName: '',
      serialNo: '',
      startAt: '',
      endAt: '',
      accountGroupId: '',
      organizationVersionId: '',
      questionText: '',
      targetCount: '',
      errorMessages: [],
      processing: false
    };
  },

  computed: {
    ...mapState('resources/x_surveys', [
      'accountGroups',
      'organizationVersions'
    ]),

    surveyParams() {
      return {
        survey: {
          name: this.name,
          short_name: this.shortName,
          serial_no: this.serialNo,
          start_at: this.startAt,
          end_at: this.endAt,
          account_group_id: this.accountGroupId,
          organization_version_id: this.organizationVersionId,
          question_text: this.questionText,
          target_count: this.targetCount
        }
      };
    }
  },

  watch: {
    accountGroupId() {
      this.setOrganizationVersions(this.accountGroupId);
    }
  },

  created() {
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_surveys', ['loadAccountGroups', 'createSurvey']),
    ...mapMutations('resources/x_surveys', ['setOrganizationVersions']),

    load() {
      this.loadAccountGroups();
    },

    resetErrorMessages() {
      this.errorMessages = [];
    },

    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.createSurvey(this.surveyParams)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'x_surveys' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
