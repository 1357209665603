<template lang="pug">
  div
    div(v-if="dataLoaded")
      .vc-main-container.container(v-if="displayable")
        .vc-topic-content.mb-4
          .vc-left-col(:class="displayAttributeValues ? 'mr-1' : ''")
            QuantitativeOrganizations(:surveyId="surveyId")
          .vc-left-col(v-show="displayAttributeValues")
            QuantitativeAttributeValues(:surveyId="surveyId")
        .vc-content-col
          .container
            .row
              .col-12(v-for="qq in quantitativeQuestions" :class="graphClass(qq)")
                .h4.vc-midashi.mt-2.mb-2(:id="'quantitative-question-text'+qq.code")
                  | {{ qq.title }}
                p.small(:id="'quantitative-question-text'+qq.code")
                  | {{ qq.question_text }}
                //- p.small(:id="'quantitative-question-description'+qq.code")
                  | {{ qq.description }}
                .row
                  .col-11
                    .row
                      .col-8
                        pie-chart(
                          :chart-data="graphData[qq.id] || {}"
                          v-if="graphCheck(qq, graphData[qq.id], 'pie')"
                          :options="pieChartOptions"
                          :styles="pieChartStyle"
                          :height="200"
                        )
                        vertical-bar-chart(
                          :chart-data="graphData[qq.id] || {}"
                          v-if="graphCheck(qq, graphData[qq.id], 'vertical')"
                          :options="horizontalBarChartOptions"
                          :styles="chartStyle(qq)"
                          :height="200"
                        )
                        horizontal-bar-chart(
                          :chart-data="graphData[qq.id] || {}"
                          v-if="graphCheck(qq, graphData[qq.id], 'horizontal')"
                          :options="horizontalBarChartOptions"
                          :styles="chartStyle(qq)"
                          :height="200"
                        )
                        horizontal-bar-chart(
                          :chart-data="graphData[qq.id] || {}"
                          v-if="graphCheck(qq, graphData[qq.id], 'horizontalWithMatrix')"
                          :options="matrixHorizontalBarChartOptions"
                          :styles="chartStyle(qq)"
                          :height="200"
                        )
                      .col-4.vc-my-team(v-if="!graphCheck(qq, graphData[qq.id], 'horizontalWithCountVertical')")
                        .row.no-gutters.vc-respondent-table
                          .col-12
                            .row.no-gutters
                              .col-6
                                | 要素
                              .col-3
                                | 数値
                              .col-3
                        .row.no-gutters.vc-respondent-table(v-for="selection in Object.keys(analyseData[qq.id]).reverse()")
                          .col-12
                            .row.no-gutters.vc-respondent-table-num
                              .col-6
                                | {{ selection }}: {{ quantitativeAnswerSelections[qq.id][selection] }}
                              .col-3
                                | {{ analyseData[qq.id][selection] }}
                              .col-3
                                .dropdown
                                  a.dropdown-toggle.no-style(
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  )
                                    | 意見検索
                                  .dropdown-menu(
                                    aria-labelledby="dropdownMenuButton"
                                  )
                                    //- a(
                                    //-   class="dropdown-item"
                                    //-   @click.prevent="toOpinionSearch(qq, selection)"
                                    //- )
                                    //-   | 会社への意見
                                    a(
                                      class="dropdown-item"
                                      @click.prevent="toOpinionSearch(qq, selection)"
                                      v-for="ques in questions"
                                    )
                                      | {{ ques.short_title }}のその他意見
                    vertical-bar-chart(
                      :chart-data="graphData[qq.id] || {}"
                      v-if="graphCheck(qq, graphData[qq.id], 'horizontalWithCountVertical')"
                      :options="verticalBarChartOptions(qq)"
                      :styles="verticalChartStyle"
                    )
      .vc-main-container.container(v-else)
        .vc-content-col
          .container
            | 表示できるデータがありません
    .vc-main-container.container(v-else)
        .vc-content-col
          .container
            | 読み込み中です。しばらくお待ちください。
</template>
<script>
import Vue from 'vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import PieChart from '../../../components/u/chartJs/PieChart';
import HorizontalBarChart from '../../../components/u/chartJs/HorizontalBarChart';
import VerticalBarChart from '../../../components/u/chartJs/VerticalBarChart';
import QuantitativeOrganizations from '../../../components/u/quantitativeAnalyse/QuantitativeOrganizations';
import QuantitativeAttributeValues from '../../../components/u/quantitativeAnalyse/QuantitativeAttributeValues';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    QuantitativeOrganizations,
    QuantitativeAttributeValues,
    PieChart,
    HorizontalBarChart,
    VerticalBarChart
  },
  data() {
    return {
      question: {},
      dataLoaded: false,
      displayable: true,
      graphData: {},
      pieChartOptions: {
        responsive: true,
        tooltips: {
          enabled: true,
          xAlign: 'center',
          yAlign: 'bottom',
          backgroundColor: 'rgba(0,0,0,0.6)',
          intersect: false
        },
        layout: {
          padding: {
            left: 12,
            right: 12,
            top: 0,
            bottom: 0
          }
        },
        maintainAspectRatio: false
      },
      horizontalBarChartOptions: {
        legend: false,
        tooltips: {
          enabled: true,
          mode: 'point',
          xAlign: 'center',
          yAlign: 'bottom',
          backgroundColor: 'rgba(0,0,0,0.6)',
          intersect: false
        },
        responsive: true,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              stepSize: 5
            }
          ],
          yAxes: [
            {
              stacked: true,
              barThickness: 11
            }
          ]
        },
        maintainAspectRatio: false
      },
      matrixHorizontalBarChartOptions: {
        tooltips: {
          enabled: true,
          mode: 'point',
          xAlign: 'center',
          yAlign: 'bottom',
          backgroundColor: 'rgba(0,0,0,0.6)',
          intersect: false
        },
        responsive: true,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              stepSize: 5
            }
          ],
          yAxes: [
            {
              stacked: true,
              barThickness: 11
            }
          ]
        }
      },
      pieChartStyle: { height: '250px', position: 'relative' },
      verticalChartStyle: { height: '250px', position: 'relative' },
      graphBackgroundColors: [
        '#22BED3',
        '#61D8E8',
        '#BEE6FB',
        '#D1D1D1',
        '#E3DEDE'
      ]
    };
  },
  computed: {
    ...mapState('pages/u_surveys/select', ['surveyId']),
    ...mapState('resources/u_quantitative', ['questions']),
    ...mapState('resources/u_quantitative', [
      'analyseData',
      'quantitativeQuestions',
      'quantitativeAnswerSelections',
      'displayAttributeValues',
      'organizationIds',
      'attributeValueIds',
      'navOrganizations',
      'responseIds'
    ])
  },
  watch: {
    organizationIds() {
      this.load();
    },
    attributeValueIds() {
      this.load();
    },
    surveyId() {
      this.displayable = true;
      this.loadNavOrganizations({ survey_id: this.surveyId }).then(_ => {
        if (this.surveyId) this.setOrganizations();
      });
    },
    quantitativeQuestions() {
      if (Object.keys(this.quantitativeQuestions).length <= 0)
        this.displayable = false;
    }
  },
  created() {
    const vcd = defaultCookie.get();
    if (vcd['surveyId'] || this.surveyId) {
      this.loadNavOrganizations({
        survey_id: vcd['surveyId'] || this.surveyId
      }).then(_ => {
        this.setOrganizations();
      });
    }
  },
  mounted() {},
  methods: {
    ...mapActions('resources/u_quantitative', [
      'loadAnalyseData',
      'loadNavOrganizations'
    ]),
    ...mapMutations('resources/u_quantitative', ['setOrganizationIds']),
    ...mapMutations('resources/u_response_ranking', ['setCurrentResponseId']),
    load() {
      const vcd = defaultCookie.get();
      const params = {
        survey_id: vcd['surveyId'] || this.surveyId,
        organization_ids: this.organizationIds,
        attribute_value_ids: this.attributeValueIds
      };
      this.loadAnalyseData(params).then(() => {
        this.updateGraph();
        this.dataLoaded = true;
      });
    },
    updateGraph() {
      for (let i = 0; i < this.quantitativeQuestions.length; i++) {
        const qq = this.quantitativeQuestions[i];
        let datasets = [];
        let labels = [];
        let backgroundColors = [];
        if (['SA', 'MA'].includes(qq.question_type)) {
          let isVertical =
            (qq.question_type === 'SA' && qq.answers_size > 5) ||
            qq.question_type === 'MA';
          labels = Object.keys(this.analyseData[qq.id]).reverse();
          if (isVertical) {
            for (let i = 0; i < labels.length; i++) {
              backgroundColors.push('#00ead4');
            }
          } else backgroundColors = this.graphBackgroundColors;
          datasets = [
            {
              backgroundColor: backgroundColors,
              data: Object.values(this.analyseData[qq.id]).reverse()
            }
          ];
          if (qq.calculate_type === 'count_vertical')
            labels = labels.map(v => v.split(''));
        } else if (['MTSA', 'MTMA'].includes(qq.question_type)) {
          const categories = qq.matrix_categories;
          labels = categories.map(c => c.category_text);
          let select_nums = qq.answers.map(a => a.select_num);
          select_nums = [...new Set(select_nums)].sort();
          for (let i = 0; i < select_nums.length; i++) {
            const num = select_nums[i];
            let d = {
              label: num,
              borderWidth: 1,
              backgroundColor: 'rgba(232, 94, 94, 0.8)',
              width: 2,
              backgroundColor: this.graphBackgroundColors[i],
              data: Object.values(this.analyseData[qq.id][num])
            };
            datasets.push(d);
          }
        }
        let data = {
          labels: labels,
          datasets: datasets
        };
        Vue.set(this.graphData, qq.id, data);
      }
    },
    setOrganizations() {
      const orgs = this.navOrganizations;
      let selected = [];
      for (let i in orgs) {
        const children = orgs[i].children;
        selected.push(orgs[i].id);
        for (let ii in children) {
          const grandChildren = children[ii].children;
          selected.push(children[ii].id);
          for (let iii in grandChildren) {
            selected.push(grandChildren[iii].id);
          }
        }
      }
      this.setOrganizationIds(selected.sort());
    },
    graphClass(qq) {
      if (this.graphData[qq.id] && qq.question_type !== 'SA') {
        if (qq.answers_size > 5) {
          if (!this.displayAttributeValues) return 'graph-margin-bottom-max';
        } else {
          if (this.displayAttributeValues)
            return 'graph-margin-bottom-with-avs';
          if (!this.displayAttributeValues) return 'graph-margin-bottom';
        }
      } else return '';
    },
    graphCheck(qq, qqId, graphType) {
      if (qq.graph_type === graphType) return true;
      if (!qq.graph_type) {
        if (graphType === 'pie')
          return (
            qqId &&
            qq.calculate_type !== 'count_vertical' &&
            qq.question_type === 'SA' &&
            qq.answers_size <= 5
          );
        else if (graphType === 'horizontal')
          return (
            qqId &&
            qq.calculate_type !== 'count_vertical' &&
            (qq.question_type === 'MA' ||
              (qq.question_type === 'SA' && qq.answers_size > 5) ||
              qq.question_type === 'NPS')
          );
        else if (graphType === 'horizontalWithMatrix')
          return qqId && ['MTSA'].includes(qq.question_type);
        else if (graphType === 'horizontalWithCountVertical')
          return qqId && qq.calculate_type === 'count_vertical';
      }
    },
    verticalBarChartOptions(qq) {
      return {
        title: {
          display: false
        },
        tooltips: {
          enabled: true,
          backgroundColor: 'rgba(0,0,0,0.6)',
          callbacks: {
            title: function(tooltipItem) {
              return tooltipItem[0].label.replaceAll(',', '');
            },
            label: function(tooltipItem) {
              if (tooltipItem.xLabel == 0) return;
              let num = tooltipItem.yLabel;
              return num.toFixed(1) + (qq.unit ? qq.unit : '');
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: -1,
            bottom: 0
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.3,
              ticks: {
                fontSize: 10
              }
            }
          ],
          yAxes: [
            {
              barPercentage: 0.5,
              ticks: {
                beginAtZero: true,
                fontSize: 10,
                maxTicksLimit: 4,
                callback: function(value) {
                  return value.toFixed(1) + (qq.unit ? qq.unit : '');
                }
              }
            }
          ]
        }
      };
    },
    chartStyle(qq) {
      const size = Object.keys(this.quantitativeAnswerSelections[qq.id]).length;
      return { height: size * 35 + 'px', positon: 'relative' };
    },
    toOpinionSearch(qq, selection) {
      const resIds = this.responseIds[qq.id][selection];
      this.setCurrentResponseId(resIds);
      const vcd = defaultCookie.get();
      this.$router.push({
        name: 'u_qualitative_opinion_search',
        params: {
          survey_id: vcd['surveyId'] || this.surveyId,
          attribute_values: this.attributeValueIds,
          backToName: 'u_quantitative_analyse'
        }
      });
    }
  }
};
</script>
<style scoped>
.vc-topic-content {
  display: flex;
  align-items: flex-start;
}
.graph-margin-bottom {
  margin-bottom: 150px !important;
}
.graph-margin-bottom-max {
  margin-bottom: 250px !important;
}
.graph-margin-bottom-with-avs {
  margin-bottom: 50px !important;
}
.vc-respondent-table {
  text-align: left;
}
</style>
