<script>
import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    options: { type: Object, required: true }
  },
  watch: {
    chartData() {
      this.$data._chart.update();
    }
  },
  mounted() {
    this.addPlugin({
      beforeDraw: function(c) {
        var ctx = c.chart.ctx;
        ctx.fillStyle = 'rgba(255,255,255,1)';
        ctx.fillRect(0, 0, c.chart.width, c.chart.height);
      }
    });
    let options = this.options;
    this.renderChart(this.chartData, options);
  }
};
</script>
