<template lang="pug">
  .row
    .col-xs-6
      | TOP
    .col-xs-6
      | HOGE
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {}
};
</script>
