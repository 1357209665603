<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content
        .container
          .card
            .card-body
              .row.m-5
                .col-12.text-center
                  p
                    | 設定ありがとうございました。
                    br
                    | インタビュー開始のご連絡は一両日中に届きますので少々お待ちください。
                    br
                    | メールにて通知されますので、メール内にあるURLからインタビューにご参加ください。

</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  mounted() {
    this.load();
  },

  methods: {
    ...mapActions('resources/r_inquiries', ['acceptChatInterview']),
    load() {
      this.acceptChatInterview(this.$route.params.inquiry_id);
    }
  }
};
</script>
