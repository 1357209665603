<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | {{ question.title }}
  .row
    .col-md-12.grid-margin.stretch-card.clearfix
      .card
        .card-body.vc(v-if="errorMessages.length > 0 || message")
          .row
            .col-12
              error-message(:errors="errorMessages" v-show="errorMessages.length > 0")
              ul.alert.alert-success(v-show="message")
                button.close.vc-message-close(@click="resetMessages")
                  span(aria-hidden='true') ×
                li.ml-1
                  | {{ message }}
        .card-body.vc
          .row.mb-4
            .col-12
              h5.vc-midashi.mb-3 定性分析用データ登録
            .col-12.section
              .row.mb-4
                .col-3
                  p.cc-label-mid ①回答データアップロード
                .col-6
                  p
                    | テンプレートをダウンロードし、その形式に沿って回答者ごとの属性、部署、自由回答を入力してください。
                    br
                    | 入力が完了したら、CSVファイルとして保存し、「CSVアップロード」ボタンを押して、アップロードしてください
                .col-3
                  div
                    label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                      input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event, 'responses')")
                  div
                    a.link(href="" @click.prevent="exportTempCsv('surveyResponses')")
                      | テンプレートCSVをダウンロード
            .col-12.section
              p.cc-label-mid ②意見データアップロード
              .row.mb-4
                .col-3
                  p 1. センテンス分割
                .col-6
                  p
                    | 「①回答データアップロード」の後、こちらのプロセスを進めてください。完了するとメールが届きます。
                    br
                    | センテンス分割を実行すると、意見ベースで具体意見のAI分析を行います。
                    br
                    | また、回答データを新しくした場合は必ずもう一度センテンス分割を行ってください。
                .col-3
                  a.cc-primary-btn.cc-inline-btn.action-btns(href="" @click.prevent="exportCsv(true)" :class="{'btn-disabled': totalCount < 1}") 分割 実行
                  a.cc-primary-btn.cc-inline-btn.action-btns(href="" @click.prevent="exportCsv(false)" :class="{'btn-disabled': totalCount < 1}") 分割せず進行
              .row.mb-4
                .col-3
                  p 2. 意見分類・感情分類・AI分析実行
                .col-6
                  p
                    | 「1. センテンス分割」が完了したら、意見分類と感情分類のAI分析を行います。「AI分析 実行」を押してください。完了するとダウンロードリンクの付いたメールが届きます。
                .col-3
                  div
                    a.cc-primary-btn.cc-inline-btn.action-btns(href="" @click.prevent="requestClassifications") AI分析 実行
                  div
                    a.cc-primary-btn.cc-inline-btn.action-btns(href="" @click.prevent="downloadClassifications") AI分析の結果を出力
                  div
                    a.link(href="" @click.prevent="exportOpinionsWithClassifications") AI分析付意見データダウンロード
              .row.mb-4
                .col-3
                  p 3. 意見データアップロード
                .col-6
                  p
                    | 分析結果をダウンロードしたら、意見分類・感情分類が間違っていないかチェックしてください。必要に応じて分類を補正してください。補正が済んだら、意見データアップロードの「CSVアップロード」を押して、CSVファイルをアップロードしてください。
                .col-3
                  label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                    input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event, 'opinions')")
        .card-body.vc
          .row.mb-4
            .col-12
              h5.vc-midashi.mb-3 登録済み定性データ取得
            .col-12.section
              p.cc-label-mid 回答データ取得
              .row.mb-4
                .col-3
                  p コード形式
                .col-6
                  p
                    | コード形式は上記の「①回答データアップロード」に使用できます。回答データをアップロードし直した場合は、必ず「②意見データアップロード」を再度行ってください。
                .col-3
                  a.cc-primary-btn.cc-inline-btn.action-btns(
                    href=""
                    @click.prevent="exportCurrentResponsesCsv(0)"
                    :class="{'btn-disabled': totalCount < 1}"
                  )
                    | ダウンロード
              .row.mb-4
                .col-3
                  p テキスト形式
                .col-6
                  p
                    | テキスト形式は人の目で内容を確認するためのものです。
                .col-3
                  a.cc-primary-btn.cc-inline-btn.action-btns(
                    href=""
                    @click.prevent="exportCurrentResponsesCsv(1)"
                    :class="{'btn-disabled': totalCount < 1}"
                  )
                    | ダウンロード
            .col-12.section
              p.cc-label-mid 意見データ取得
              .row.mb-4
                .col-3
                  p コード形式
                .col-6
                  p
                    | コード形式は上記の「②意見データアップロード」の「3.意見データアップロード」で使用できます。分類を修正する際などに使用してください。
                    br
                    | 回答データを更新した後はセンテンス分割から再度行ってください。
                .col-3
                  a.cc-primary-btn.cc-inline-btn.action-btns(
                    href=""
                    @click.prevent="exportCurrentOpinionsCsv(0)"
                    :class="{'btn-disabled': totalCount < 1}"
                  )
                    | ダウンロード
              .row.mb-4
                .col-3
                  p テキスト形式
                .col-6
                  p
                    | テキスト形式は人の目で内容を確認するためのものです。
                .col-3
                  a.cc-primary-btn.cc-inline-btn.action-btns(
                    href=""
                    @click.prevent="exportCurrentOpinionsCsv(1)"
                    :class="{'btn-disabled': totalCount < 1}"
                  )
                    | ダウンロード
        .card-body.vc
          .row.mb-4
            .col-12
              h5.vc-midashi.mb-3 具体意見用データ登録
            .col-12.section
              p.cc-label-mid 具体意見データのアップロード
              p 「②意見データアップロード」の「センテンス分割」が終わっていない場合はそちらを先に実行してください
              .row.mb-4
                .col-3
                  p 1. 具体意見データ分析
                .col-6
                  p
                    | 「AI分析 実行」を押してください。完了するとダウンロードリンクの付いたメールが届きます。
                .col-3
                  div
                    a.cc-primary-btn.cc-inline-btn.action-btns(href="" @click.prevent="requestResponseRanking")
                      | AI分析 実行
                  div
                    a.link(href="" @click.prevent="exportResponseRankingCsv")
                      | 具体意見分析結果ダウンロード
              .row.mb-4
                .col-3
                  p 2. 具体意見データアップロード
                .col-6
                  p
                    | 分析結果をダウンロードしたら、必要に応じて補正してください。補正が済んだら、意見データアップロードの「CSVアップロード」を押して、CSVファイルをアップロードしてください。
                .col-3
                  label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                    input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event, 'responseRanking')")
        .card-body.vc
          .row.mb-4
            .col-12
              h5.vc-midashi.mb-3 テキストマイニング画像・データ登録
            .col-12.section
              p.cc-label-mid テキストマイニング画像・データのアップロード
              p あらかじめ「②意見データアップロード」の「2. 意見分類・感情分類 AI分析実行」を完了し、感情分類結果からKH Coderによるテキストマイニングを行ってください。が終わっていない場合はそちらを先に実行してください
              .row.mb-4
                .col-3
                  p 1. 画像登録
                .col-6
                  p
                    | KH Coderで出力した画像を、それぞれ「ネガティブ画像アップロード」「ポジティブ画像アップロードを押して、アップロードしてください。
                    br
                    | 登録する画像はKH Coderの凡例部分を覗いた、正方形にトリミングしてください。
                .col-3
                  .mb-2
                    label.cc-primary-btn.cc-inline-btn ネガティブ画像アップロード
                      input.d-none(type="file" accept="image/*" @click="fileInitialize($event)" @change="onFileChange($event, 'negaImages')")
                  div
                    label.cc-primary-btn.cc-inline-btn ポジティブ画像アップロード
                      input.d-none(type="file" accept="image/*" @click="fileInitialize($event)" @change="onFileChange($event, 'posiImages')")
              .row.mb-4
                .col-3
                  p 2. データ登録
                .col-6
                  p
                    | テンプレートCSVをダウンロードし、形式にそってデータを作成してください。
                    br
                    | カラーコードはKH Coderで出力した画像の凡例部分からスポイト機能などで取得し、同じ色を指定できるようにしてください。
                    br
                    | コメントは後で画面上で編集することも可能です。
                .col-3
                  div
                    label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                      input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event, 'textMinings')")
                  div
                    a.link(href="" @click.prevent="exportTempCsv('textMinings')")
                      | テンプレートCSVダウンロード
              p.cc-label-mid 共起ネットワークの設定
              .row.mb-4
                .col-3
                  p データ登録
                .col-6
                  p
                    | テンプレートCSVをダウンロードし、形式にそってデータを作成してください。
                .col-3
                  div
                    label.cc-primary-btn.cc-inline-btn.action-btns CSVアップロード
                      input.d-none(type="file" accept="text/csv" @click="fileInitialize($event)" @change="onFileChange($event, 'coOccurrences')")
                  div
                    a.link(href="" @click.prevent="exportTempCsv('coOccurrences')")
                      | テンプレートCSVダウンロード
        .card-body.vc
          .row.mb-4
            .col-12
              h5.vc-midashi.mb-3 カテゴリ別のTF/IDF値の計算
            .col-12.section
              p.cc-label-mid カテゴリ別のTF/IDF値のダウンロード
              p 「②意見データアップロード」の「センテンス分割」が終わっていない場合はそちらを先に実行してください
              .row.mb-4
                .col-3
                  p AI分析・計算
                .col-6
                  p 「AI分析 実行」を押してください。完了するとダウンロードリンクの付いたメールが届きます。
                .col-3
                  div
                    a.cc-primary-btn.cc-inline-btn.action-btns(href="" @click.prevent="requestFeatureWords")
                      | AI分析 実行
  ImportFileModal(ref="importFileModal" @importCsv="importCsv" @importImg="uploadImg" :file-name="currentFilename")

</template>
<script>
import { mapState, mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';
import i18n from '../../../locales/index';
import ImportFileModal from '../../../components/x/surveys/ImportFileModal';
import CDL from '../../../lib/csv_download';
const CSVDownload = new CDL();

export default {
  components: {
    ErrorMessage,
    ImportFileModal
  },

  data() {
    return {
      errorMessages: [],
      message: '',
      processing: false,
      responsesCsvFile: '',
      opinionsCsvFile: '',
      textMiningsCsvFile: '',
      coOccurrencesCsvFile: '',
      negaImageFile: '',
      posiImageFile: '',
      responseRankingCsvFile: '',
      currentFilename: ''
    };
  },

  computed: {
    ...mapState('resources/x_questions', ['question', 'totalCount']),

    responsesCsvParams() {
      const formData = new FormData();
      formData.append('csv_file', this.responsesCsvFile);

      return formData;
    },

    opinionsCsvParams() {
      const formData = new FormData();
      formData.append('csv_file', this.opinionsCsvFile);

      return formData;
    },

    textMiningsCsvParams() {
      const formData = new FormData();
      formData.append('csv_file', this.textMiningsCsvFile);

      return formData;
    },

    coOccurrencesCsvParams() {
      const formData = new FormData();
      formData.append('csv_file', this.coOccurrencesCsvFile);

      return formData;
    },

    responseRankingCsvParams() {
      const formData = new FormData();
      formData.append('csv_file', this.responseRankingCsvFile);

      return formData;
    }
  },

  created() {
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_questions', [
      'exportSentencesCsv',
      'exportCurrentResponsesData',
      'exportCurrentOpinionsData',
      'requestClassificationsApi',
      'downloadClassificationsApi',
      'exportOpinionsDataWithClassifications',
      'requestResponseRankingApi',
      'exportResponseRanking',
      'requestFeatureWordsApi'
    ]),
    ...mapActions('resources/x_questions', ['loadQuestion']),

    load() {
      let params = this.$route.params;
      this.loadQuestion({ survey_id: params.survey_id, id: params.id });
    },

    resetMessages() {
      this.errorMessages = [];
      this.message = '';
    },

    importCsv(fileType) {
      this.resetMessages();

      const surveyId = this.$route.params.survey_id;
      const questionId = this.$route.params.id;
      let path = '/api/x/surveys/' + surveyId + '/questions/' + questionId;
      let body;
      if (fileType === 'responses') {
        path += '/import_responses_csv';
        body = this.responsesCsvParams;
      } else if (fileType === 'opinions') {
        path += '/import_opinions_csv';
        body = this.opinionsCsvParams;
      } else if (fileType === 'textMinings') {
        path += '/import_text_minings_csv';
        body = this.textMiningsCsvParams;
      } else if (fileType === 'coOccurrences') {
        path += '/import_co_occurrences_csv';
        body = this.coOccurrencesCsvParams;
      } else if (fileType === 'responseRanking') {
        path += '/import_response_ranking';
        body = this.responseRankingCsvParams;
      }

      fetch(path, {
        method: 'PUT',
        body: body
      }).then(response => {
        if (response.status === 200) {
          this.message = i18n.tc('admin.surveys.messages.processing');
        } else if (response.status === 422) {
          response.json().then(body => {
            this.errorMessages = body.errors;
          });
        }
      });
    },

    onFileChange(e, fileType) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      if (fileType === 'responses') this.responsesCsvFile = files[0];
      else if (fileType === 'opinions') this.opinionsCsvFile = files[0];
      else if (fileType === 'textMinings') this.textMiningsCsvFile = files[0];
      else if (fileType === 'coOccurrences')
        this.coOccurrencesCsvFile = files[0];
      else if (fileType === 'negaImages') this.negaImageFile = files[0];
      else if (fileType === 'posiImages') this.posiImageFile = files[0];
      else if (fileType === 'responseRanking')
        this.responseRankingCsvFile = files[0];

      this.currentFilename = files[0].name;
      this.modalOpen(fileType);
    },

    exportCsv(isSplit) {
      const params = {
        survey_id: this.$route.params.survey_id,
        id: this.$route.params.id,
        split: isSplit
      };
      this.exportSentencesCsv(params).then(response => {
        if (response.status)
          this.message = i18n.tc('admin.surveys.messages.processing');
      });
    },

    exportTempCsv(fileType) {
      let hash;
      if (fileType === 'surveyResponses') {
        hash = {
          name: 'x_export_template_question',
          params: {
            survey_id: this.$route.params.survey_id,
            id: this.$route.params.id
          }
        };
      } else if (fileType === 'textMinings') {
        hash = {
          name: 'x_export_text_minings_template'
        };
      } else if (fileType === 'coOccurrences') {
        let head = ['id', 'opinion'];

        CSVDownload.downloadCsv(
          head,
          [],
          'voiscope_co_occurrence_networks_' +
            moment().format('YYYYMMDD_HHmmss') +
            '.csv'
        );
        return;
      }
      let routeData = this.$router.resolve(hash);
      window.open(routeData.href, '_blank');
    },

    uploadImg(fileType) {
      this.resetMessages();

      const emotionType = fileType === 'negaImages' ? 'negative' : 'positive';
      const surveyId = this.$route.params.survey_id;
      const questionId = this.$route.params.id;
      let path =
        '/api/x/surveys/' +
        surveyId +
        '/questions/' +
        questionId +
        '/upload_image';
      const formData = new FormData();
      formData.append('emotion_type', emotionType);

      if (emotionType == 'negative') {
        formData.append('image', this.negaImageFile);
      } else {
        formData.append('image', this.posiImageFile);
      }

      fetch(path, {
        method: 'PUT',
        body: formData
      }).then(response => {
        if (response.status === 200) {
          this.message = i18n.tc('admin.surveys.messages.processing');
        } else if (response.status === 422) {
          response.json().then(body => {
            this.errorMessages = body.errors;
          });
        }
      });
    },

    exportCurrentResponsesCsv(type) {
      this.resetMessages();
      const params = {
        survey_id: this.$route.params.survey_id,
        id: this.$route.params.id,
        filetype: type
      };
      this.exportCurrentResponsesData(params).then(response => {
        if (response.status === 200)
          this.message = i18n.tc('admin.surveys.messages.processing');
      });
    },

    exportCurrentOpinionsCsv(type) {
      this.resetMessages();
      const params = {
        survey_id: this.$route.params.survey_id,
        id: this.$route.params.id,
        filetype: type
      };
      this.exportCurrentOpinionsData(params).then(response => {
        if (response.status === 200)
          this.message = i18n.tc('admin.surveys.messages.processing');
      });
    },

    requestClassifications() {
      this.resetMessages();
      const params = {
        survey_id: this.$route.params.survey_id,
        id: this.$route.params.id
      };
      this.requestClassificationsApi(params).then(response => {
        if (response.status === 200)
          this.message = i18n.tc('admin.surveys.messages.processing');
      });
    },

    downloadClassifications() {
      this.resetMessages();
      const params = {
        survey_id: this.$route.params.survey_id,
        id: this.$route.params.id
      };
      this.downloadClassificationsApi(params).then(response => {
        if (response.status === 200)
          this.message = i18n.tc('admin.surveys.messages.processing');
      });
    },

    exportOpinionsWithClassifications() {
      this.resetMessages();
      const params = {
        survey_id: this.$route.params.survey_id,
        id: this.$route.params.id
      };
      this.exportOpinionsDataWithClassifications(params).then(response => {
        if (response.status === 200)
          this.message = i18n.tc('admin.surveys.messages.processing');
      });
    },

    requestResponseRanking() {
      this.resetMessages();
      const params = {
        survey_id: this.$route.params.survey_id,
        id: this.$route.params.id
      };
      this.requestResponseRankingApi(params).then(response => {
        if (response.status === 200)
          this.message = i18n.tc('admin.surveys.messages.processing');
      });
    },

    exportResponseRankingCsv() {
      this.resetMessages();
      const params = {
        survey_id: this.$route.params.survey_id,
        id: this.$route.params.id
      };
      this.exportResponseRanking(params).then(response => {
        if (response.status === 200)
          this.message = i18n.tc('admin.surveys.messages.processing');
      });
    },

    modalOpen(fileType) {
      this.$refs.importFileModal.modalOpen(fileType);
    },

    fileInitialize(e) {
      e.target.value = null;
    },

    requestFeatureWords() {
      this.resetMessages();
      const params = {
        survey_id: this.$route.params.survey_id,
        id: this.$route.params.id
      };
      this.requestFeatureWordsApi(params).then(response => {
        if (response.status === 200)
          this.message = i18n.tc('admin.surveys.messages.processing');
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.img-upload-wrap {
  padding: 1rem;
  border: 0.5px #999 solid;
}
.section {
  background-color: #ececec;
  padding: 1.5rem !important;
  margin-bottom: 1rem !important;
}
.link {
  color: #4bafaf;
  font-size: 0.85rem;
}
.col-3 p,
.col-6 p {
  margin-bottom: 0;
}
.action-btns {
  width: 200px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
</style>
