import Vue from 'vue';
import VueRouter from 'vue-router';
import responders from './responders';

Vue.use(VueRouter);

const routes = [...responders];

const router = new VueRouter({ base: '/', mode: 'history', routes });

export default router;
