<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content.vc-dashboard
        SurveySelect
        .vc-main-container.container(v-if="!isTopic && started")
          .vc-dashboard-box
            .container-fluid
              .row
                .col-12.vc-midashi-wrap
                  h3.vc-midashi.mb-4
                    | ネガポジ感情マップ
                  span.vc-midashi-desc.mb-4
                    | ネガポジ意見のボリューム分布を見ることができます
              .row.vc-dashboard-box-content
                .col-12.vc-dashboard-box-sub-select-wrap
                  .row
                    .col-12
                      .vc-box-sub-select.container
                        a.nav-link.vc-midashi(
                          v-for="(organizationLevelName, i) in volumeMap.organization_level_names"
                          @click.prevent="setOrganizationHierarchy(i+1)"
                          :class="{ 'active': (organizationHierarchy == i+1)}"
                          )
                          | {{organizationLevelName}}
                .col-12.vc-organization-select-wrap.text-center(v-show="organizationHierarchy === 2")
                  .vc-organization-select.p-2.mb-3
                    span.mr-5
                      .custom-control.custom-checkbox.vc-custom-checkbox.float-left.mr-3(v-for="organization in volumeMap.top_level_organizations")
                        input.custom-control-input(
                          type="checkbox"
                          :id="'top-level-organization-' + organization.id"
                          :value="organization.id"
                          v-model="topLevelOrganizationIds"
                        )
                        label.custom-control-label.attribute-label(:for="'top-level-organization-' + organization.id")
                          | {{ organization.name }}
                    button.btn.btn-primary.vc-primary-btn(
                      :disabled="processing"
                      @click.prevent="selectTopOrg"
                    )
                      | 絞り込み
                .col-12
                  p.mb-1.ml-1.small.graph-description
                    | 斜線の下側はネガティブな意見が多いチーム、上側がポジティブな意見が多いチーム
                .col-8.vc-dashboard-box-graph-wrap
                  scatter-chart(:chart-data="datacollection" :options="options" ref="scatterChart")
                  .text-right
                    a.graph-mode.mr-1(
                      @click.prevent="changeShowAllTooltips()"
                      :class="{active: showAllTooltips}"
                    )
                      | ラベル表示
                .col-4.vc-dashboard-box-table-wrap
                  .row.no-gutters.vc-respondent-table
                    .col-8.text-left.overflow-hidden.text-nowrap
                      | 事業
                    .col-4
                      .row.no-gutters
                        .col-6.vc-negative-color
                          | ネガ
                        .col-6.vc-positive-color
                          | ポジ
                  .vc-dashboard-box-table-data-row
                    .row.no-gutters.vc-respondent-table(v-for="(outputVolumeMap, i) in outputVolumeMaps")
                      .col-8.text-left.overflow-hidden.text-nowrap.table-column-name
                        div
                          span.datasetColor(
                            :style="{backgroundColor: colors[i]}"
                          )
                          | {{outputVolumeMap.organizationName}}
                        a(@click.prevent="toTeamAnalyse(outputVolumeMap.organizationId)")
                          svg(xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="darkgray")
                            path(d="M0 0h24v24H0z" fill="none")
                            path(d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z")
                      .col-4
                        .row.no-gutters.vc-respondent-table-num
                          .col-6.vc-negative-color
                            | {{outputVolumeMap.negaCount | separate}}
                          .col-6.vc-positive-color
                            | {{outputVolumeMap.posiCount | separate}}
                    .row.vc-export-btn-wrap
                      .offset-9.col-3.mt-3
                        .text-right
                          .dropdown
                            a.dropdown-toggle.btn.btn-primary.vc-primary-btn.btn-block.vc-export-btn(
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              )
                              span エクスポート
                              i.fas.fa-caret-down
                            .dropdown-menu(
                              aria-labelledby="dropdownMenuButton"
                              )
                              a(
                                class="dropdown-item"
                                @click.prevent="copyImage"
                                )
                                | グラフをコピー
                              a(
                                class="dropdown-item"
                                @click.prevent="downloadImage"
                                )
                                | グラフを画像保存
                              a#download-img-link
        .vc-main-container.container(v-if="!isTopic && !started")
          .vc-dashboard-box
            .container-fluid
              .row
                .col-12.vc-midashi-wrap
                  h3.vc-midashi.mb-4
                    | ネガポジ感情マップ
                  span.vc-midashi-desc.mb-4
                    | ネガポジ意見のボリューム分布を見ることができます
              .row.vc-dashboard-box-content
                .col-12
                  p.mt-4 読み込み中です。しばらくお待ちください。
        .vc-main-container.container(v-if="isTopic")
          .vc-content-col
            .container
              | トピック調査については、この画面はご利用できません。調査選択から、トピック調査以外の調査を選択ください。

</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SurveySelect from '../../../components/u/dashboard/SurveySelect';
import ScatterChart from '../../../components/u/chartJs/ScatterChart';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    SurveySelect,
    ScatterChart
  },

  data() {
    return {
      organizationHierarchy: 1,
      outputVolumeMaps: [],
      datacollection: {},
      options: {},
      colors: [
        '#6DAFD2',
        '#C28BDD',
        '#FF6C6C',
        '#FF292B',
        '#8C4CD0',
        '#229BEE',
        '#FFFF6D',
        '#6FC8F6',
        '#FF9022',
        '#FFB757',
        '#A0EE5A',
        '#E46D2A',
        '#6DE42A',
        '#2A6DE4',
        '#A05AEE',
        '#91C2DC',
        '#CBA0E2',
        '#FC8888',
        '#FC6565',
        '#AC7FDD',
        '#64B8F2',
        '#FFFF85',
        '#8AD0F7',
        '#FFAE61'
      ],
      showAllTooltips: false,
      processing: false,
      topLevelOrganizationIds: []
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_dashboard', ['volumeMap']),
    ...mapState('pages/u_surveys/select', ['surveyId', 'isTopic']),
    ...mapState('pages/u_organizations/select', ['started'])
  },

  watch: {
    volumeMap: function() {
      const outputVolumeMaps = [];
      const aggregatedDatas = this.volumeMap.aggregated_datas;
      for (let i in aggregatedDatas) {
        let negaCount = 0;
        let posiCount = 0;
        const opinionCount = aggregatedDatas[i].opinion_count;
        for (let ii in opinionCount) {
          if (opinionCount[ii].code == '1') {
            negaCount = opinionCount[ii].opinion_count;
          } else if (opinionCount[ii].code == '2') {
            posiCount = opinionCount[ii].opinion_count;
          }
        }
        let data = {
          organizationId: aggregatedDatas[i].organization_id,
          organizationName: aggregatedDatas[i].organization_name,
          negaCount: negaCount,
          posiCount: posiCount
        };
        if (negaCount > 0 && posiCount > 0) outputVolumeMaps.push(data);
      }
      this.outputVolumeMaps = outputVolumeMaps;
      this.graphUpdate();
    },
    surveyId: function() {
      if (!this.isTopic) {
        this.loadVolumeMap({
          survey_id: this.surveyId,
          organization_hierarchy: this.organizationHierarchy
        }).then(() => {
          this.setStarted(true);
        });
      }
    }
  },

  created() {
    this.setStarted(false);
    if (this.surveyId && !this.isTopic) {
      this.loadVolumeMap({
        survey_id: this.surveyId,
        organization_hierarchy: this.organizationHierarchy
      }).then(() => {
        this.setStarted(true);
      });
    }
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_dashboard', ['loadVolumeMap']),
    ...mapActions('resources/u_public_activities', ['createPublicActivity']),
    ...mapMutations('pages/u_organizations/select', ['setStarted']),
    setOrganizationHierarchy: function(organizationHierarchy) {
      this.organizationHierarchy = organizationHierarchy;
      this.loadVolumeMap({
        survey_id: this.surveyId,
        organization_hierarchy: this.organizationHierarchy,
        parent_organization_ids:
          organizationHierarchy === 2 ? this.topLevelOrganizationIds : []
      }).then(() => {
        this.setStarted(true);
      });
    },
    graphUpdate: function() {
      const outputVolumeMaps = this.outputVolumeMaps;

      let datasets = [];
      var max = 0;
      for (const i in outputVolumeMaps) {
        datasets.push({
          label: outputVolumeMaps[i].organizationName,
          data: [
            {
              x: outputVolumeMaps[i].negaCount,
              y: outputVolumeMaps[i].posiCount
            }
          ],
          // backgroundColor: 'rgba(110, 162, 178, 0.3)',
          backgroundColor: this.colors[i],
          pointHoverRadius: 6,
          pointRadius: 6,
          // pointBorderColor: '#f3154a',
          pointBorderWidth: '2px'
        });
        if (max < outputVolumeMaps[i].negaCount)
          max = outputVolumeMaps[i].negaCount;
        if (max < outputVolumeMaps[i].posiCount)
          max = outputVolumeMaps[i].posiCount;
      }

      var pm = 10;
      for (var step = 1; step < String(max).length - 1; step++) {
        pm *= 10;
      }
      max = Math.ceil(max / pm) * pm;

      datasets.push({
        label: '',
        data: [
          {
            x: 0,
            y: 0
          },
          {
            x: max,
            y: max
          }
        ],
        borderColor: 'rgba(213,213,213,1)',
        backgroundColor: 'rgba(213,213,213,1)',
        borderDash: [3, 3],
        borderWidth: 1,
        pointRadius: 0,
        tension: 0,
        showLine: true,
        fill: false
      });

      this.datacollection = {
        datasets: datasets
      };
      this.options = {
        title: {
          display: false
        },
        legend: {
          display: false
        },
        showAllTooltips: this.showAllTooltips,
        tooltips: {
          enabled: true,
          mode: 'point',
          // yAlign: 'bottom',
          displayColors: false,
          intersect: false,
          backgroundColor: 'rgba(0,0,0,0.6)',
          filter: function(tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].label != '';
          },
          // backgroundColor: 'rgba(0,0,0,0)',
          // bodyFontColor: '#5a5a5a',
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ', ';
              }
              label +=
                'ネガ:' + tooltipItem.xLabel + ', ポジ:' + tooltipItem.yLabel;
              return label;
            }
          }
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'ネガティブ意見数'
              },
              ticks: {
                suggestedMin: 0,
                beginAtZero: true,
                max: max,
                callback: function(value) {
                  return value;
                }
              }
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'ポジティブ意見数'
              },
              ticks: {
                suggestedMin: 0,
                beginAtZero: true,
                max: max,
                callback: function(value) {
                  return value;
                }
              }
            }
          ]
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      };
      setTimeout(() => {
        if (this.$refs.scatterChart) this.$refs.scatterChart.update();
      }, 500);
    },
    toTeamAnalyse: function(organizationId) {
      defaultCookie.set({
        surveyId: this.surveyId,
        organizationId: organizationId
      });
      this.$router.push({
        name: 'u_team_analyse',
        params: {
          default: true
        }
      });
    },
    changeShowAllTooltips: function() {
      this.showAllTooltips = this.showAllTooltips ? false : true;
      this.options.showAllTooltips = this.showAllTooltips;
      this.$refs.scatterChart.update();
    },
    downloadImage() {
      let canvas = document.getElementById('scatter-chart');
      if (canvas.width > 959 || canvas.height > 959) {
        let newCanvas = document.createElement('canvas');
        newCanvas.height = 960;
        newCanvas.width = 960;
        let context = newCanvas.getContext('2d');
        context.drawImage(canvas, 0, 0, 960, 960);
        canvas = newCanvas;
      }
      if (canvas.msToBlob) {
        let blob = canvas.msToBlob();
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let downloadLink = document.getElementById('download-img-link');
        let filename = 'ネガポジ感情マップ';
        if (this.organizationHierarchy === 1)
          filename += '（' + this.volumeMap.organization_level_names[0] + '）';
        else
          filename += '（' + this.volumeMap.organization_level_names[1] + '）';
        downloadLink.href = canvas.toDataURL('image/png');
        downloadLink.download = filename + '.png';
        downloadLink.click();
      }
      const params = {
        trackable_type: 'survey',
        trackable_id: this.surveyId,
        key: 'survey.download_volume_map_image'
      };
      this.createPublicActivity(params);
    },
    copyImage() {
      let canvas = document.getElementById('scatter-chart');
      let img = document.createElement('img');
      img.src = canvas.toDataURL();

      let div = document.createElement('div');
      div.contentEditable = true;
      div.appendChild(img);
      document.body.appendChild(div);

      this.selectForCopy(div);
      document.execCommand('Copy');
      document.body.removeChild(div);
      const params = {
        trackable_type: 'survey',
        trackable_id: this.surveyId,
        key: 'survey.copy_volume_map_image'
      };
      this.createPublicActivity(params);
    },
    selectForCopy(element) {
      var doc = document;
      if (doc.body.createTextRange) {
        let range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        let selection = window.getSelection();
        let range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    },
    selectTopOrg() {
      this.processing = true;
      this.loadVolumeMap({
        survey_id: this.surveyId,
        organization_hierarchy: this.organizationHierarchy,
        parent_organization_ids: this.topLevelOrganizationIds
      }).then(() => {
        this.processing = false;
        this.setStarted(true);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
button.small {
  font-size: 0.9em;
}
.datasetColor {
  display: inline-block;
  content: ' ';
  width: 14px;
  height: 14px;
  margin-right: 5px;
  vertical-align: middle;
}
.vc-organization-select-wrap {
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: stretch;
  align-items: stretch;
}
.vc-organization-select-wrap .vc-organization-select {
  border: 1px solid #dee2e6 !important;
}
.vc-organization-select-wrap .vc-organization-select button {
  padding: 0.4rem 1rem !important;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle {
  background: #fff !important;
  border: 1px solid #f3154a !important;
  color: #f3154a;
  font-size: 0.85rem;
  width: 150px;
  float: right;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle span {
  margin: auto;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle:hover {
  background: #fff !important;
  border-radius: 2px;
  border: 1px solid #f3154a;
  padding: 12px;
  color: #f3154a;
  font-weight: 600;
  transition: 0.15s ease;
  white-space: nowrap;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle:after {
  content: none;
}
.vc-export-btn-wrap .dropdown .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem !important;
}
</style>
