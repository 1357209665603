import Paginate from '../../paginate';

export default {
  namespaced: true,

  modules: {
    pagination: new Paginate()
  },

  state: {},

  getters: {},

  mutations: {}
};
