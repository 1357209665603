import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_classification_requests');

export default {
  namespaced: true,

  state: {
    requests: [],
    accountGroups: []
  },

  getters: {},

  actions: {
    loadRequests({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          let requests = response.body.requests;
          let accountGroups = response.body.account_groups;
          commit('setRequests', requests);
          commit('setAccountGroups', accountGroups);
        }
        return response;
      });
    }
  },

  mutations: {
    setRequests(state, requests) {
      state.requests = requests;
    },
    setAccountGroups(state, accountGroups) {
      state.accountGroups = accountGroups;
    }
  }
};
