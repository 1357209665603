<template lang="pug">
  b-modal(
    id="survey-undo-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    :title="$t('admin.surveys.undo.title')"
  )
    b-container
      p
        | {{$t('actions.confirm')}}
      p
        span.text-danger
          | {{ undoTarget.name }}
    div(slot="modal-footer")
      button.btn.btn-secondary(@click="close")
        | {{$t('actions.close')}}
      button.btn.btn-primary.ml-2(@click.once="undo")
        | {{$t('actions.undo')}}
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    undoTarget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions('resources/x_surveys', ['undoSurvey']),

    close() {
      this.$bvModal.hide('survey-undo-modal');
    },

    undo() {
      this.undoSurvey(this.undoTarget.id).then(response => {
        if (response.status === 200) {
          this.$bvModal.hide('survey-undo-modal');
          this.$router.go({ name: 'x_surveys' });
        }
      });
    }
  }
};
</script>
