import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_users');

export default {
  namespaced: true,

  state: {
    usersList: [],
    organizations: [],
    roles: [],
    accountGroupName: ''
  },

  getters: {},

  actions: {
    /**
     * ユーザー一覧取得
     */
    loadUsers({ commit }) {
      return gateway.get({ action: 'index' }).then(response => {
        if (response.status === 200) {
          const usersList = response.body.users;
          commit('setUsersList', usersList);
          const accountGroupName = response.body.account_group_name;
          commit('setAccountGroupName', accountGroupName);
        }
        return response;
      });
    },

    /**
     * 新規作成
     *
     * @param {Object} params パラメタ
     * @return {Promise} Promise
     */
    createUser(_, params) {
      return gateway
        .post(
          {
            action: 'create'
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    /**
     * ユーザー取得(編集)
     */
    editUser(_, id) {
      return gateway
        .get({
          action: 'edit',
          id
        })
        .then(response => {
          return response;
        });
    },

    /**
     * 更新
     *
     * @param {Object} params パラメタ
     * @return {Promise} Promise
     */
    updateUser(_, params) {
      return gateway
        .put(
          {
            action: 'update',
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    /**
     * ユーザー論理削除
     *
     * @param {Number} id userID
     * @return {Promise} Promise
     */
    deleteUser(_, id) {
      return gateway.delete({ action: 'remove', id }).then(response => {
        return response;
      });
    },

    /**
     * ロック解除
     *
     * @param {Number} id userID
     * @return {Promise} Promise
     */
    unlock(_, id) {
      return gateway.put({ action: 'unlock', id: id }).then(response => {
        return response;
      });
    },

    /**
     * ユーザー招待メール再送信
     *
     * @param {Number} id userID
     * @return {Promise} Promise
     */
    sendInvitation(_, id) {
      return gateway
        .put({ action: 'send_invitation', id: id })
        .then(response => {
          return response;
        });
    },

    /**
     * 組織一覧取得
     */
    loadOrganizations({ commit }) {
      return gateway.get({ action: 'organizations' }).then(response => {
        if (response.status === 200) {
          const organizations = response.body.organizations;
          commit('setOrganizations', organizations);
        }
        return response;
      });
    }
  },

  mutations: {
    setUsersList(state, usersList) {
      state.usersList = usersList;
    },
    setOrganizations(state, organizations) {
      state.organizations = organizations;
    },
    setAccountGroupName(state, accountGroupName) {
      state.accountGroupName = accountGroupName;
    }
  }
};
