import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_classification_request_items');

export default {
  namespaced: true,

  state: {
    request: {}
  },

  getters: {},

  actions: {
    loadRequest({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          let request = response.body.request;
          commit('setRequest', request);
        }
        return response;
      });
    },

    rejectRequest(_, id) {
      return gateway.put({ action: 'reject', id: id }).then(response => {
        return response;
      });
    },

    approveRequest(_, id) {
      return gateway.put({ action: 'approve', id: id }).then(response => {
        return response;
      });
    }
  },

  mutations: {
    setRequest(state, request) {
      state.request = request;
    }
  }
};
