<template lang="pug">
  .vc-main-container.container
    .vc-topic-content.mb-4
      .vc-left-col
        QualitativeOrganizations(:surveyId="surveyId" :page-name="'matrix'")
    .vc-content-col
      .row.mt-2.mb-2
        .col-5
          ul.vc-parameter-nav
            li(v-for="em in emotionClassifications")
              a.vpn-all.vc-ranking-btn(
                :class="{ 'active': emotionClassificationId === em.id }"
                @click="setEmotionClassificationId(em)"
              )
                | {{ em.name }}

      .row.mb-2
        .col-5
          ul.vc-parameter-nav
            li
              a.vpn-all.vc-ranking-btn(
                :class="{ 'active': cellMode === 'ratio' }"
                @click="setCellMode('ratio')"
              )
                | 率
            li
              a.vpn-all.vc-ranking-btn(
                :class="{ 'active': cellMode === 'count' }"
                @click="setCellMode('count')"
              )
                | 件数

      .row.mb-4
        .col-5
          ul.vc-parameter-nav
            li
              a.vpn-all.vc-ranking-btn(
                :class="{ 'active': pageMode === 'matrix' }"
                @click="setPageMode('matrix')"
              )
                | クロス表
            li
              a.vpn-all.vc-ranking-btn(
                :class="{ 'active': pageMode === 'heatmap' }"
                @click="setPageMode('heatmap')"
              )
                | ヒートマップ

      .row.no-gutters.mb-5(v-show="pageMode === 'matrix'")
        .col-12.vc-my-team(v-if="matrixDataArray.length > 0")
          .vs-ex-heatmap-box(v-for="(matrixData, idx) in matrixDataArray")
            .row.mb-2
              .col-12
                OpinionMatrix(
                  :visible="matrixVisible",
                  :opinion-classifications="opinionClassifications",
                  :organizations="organizations",
                  :emotion-classification-id="emotionClassificationId"
                  :matrix-data="matrixData",
                  :cell-mode="cellMode",
                  :current-organization-ids="organizationIds"
                  :organization-hierarchy="orgHierarchy"
                )
        .col-12.vc-my-team(v-else)
          p データを読み込んでいます。少々お待ちください。

      .row.no-gutters.mb-5(v-show="pageMode === 'heatmap'")
        .col-12.vc-my-team.heatmap2-par1(v-if="heatmapVisible")
          .row.vs-ex-heatmap2-box
            .vs-ex-heatmap2-org.col-6(
              v-for="(heatmapData, idx) in heatmapDataArray"
              v-show="(heatmapData[0].organization_hierarchy === orgHierarchy && organizationIds.includes(heatmapData[0].id)) ||\
              (heatmapData[0].id === 0 && organizationIds.includes('all'))"
            )
              .row.mb-2
                .col-12
                  h4.vc-midashi.h4(v-if="heatmapData[0].id === 0 && organizationIds.includes('all')")
                    | 全社
                  h4.vc-midashi.h4(v-else)
                    | {{ heatmapData[0].name }}

              OpinionHeatmap(
                :visible="heatmapVisible"
                :organization="heatmapData[0]"
                :emotion-classification-id="emotionClassificationId"
                :opinion-classifications="opinionClassifications"
                :heatmap-data="heatmapData[1]"
              )

          .vs-ex-heatmap2-scale
            .heatmap-scale-container
              .heatmap-scale
                .heatmap-scale-color.scale0-10
                .heatmap-scale-text 0-10
              .heatmap-scale
                .heatmap-scale-color.scale11-20
                .heatmap-scale-text 11-20
              .heatmap-scale
                .heatmap-scale-color.scale21-30
                .heatmap-scale-text 21-30
              .heatmap-scale
                .heatmap-scale-color.scale31-40
                .heatmap-scale-text 31-40
              .heatmap-scale
                .heatmap-scale-color.scale41-50
                .heatmap-scale-text 41-50
              .heatmap-scale
                .heatmap-scale-color.scale51-60
                .heatmap-scale-text 51-60
              .heatmap-scale
                .heatmap-scale-color.scale61-70
                .heatmap-scale-text 61-70
              .heatmap-scale
                .heatmap-scale-color.scale71-80
                .heatmap-scale-text 71-80
              .heatmap-scale
                .heatmap-scale-color.scale81-90
                .heatmap-scale-text 81-90
              .heatmap-scale
                .heatmap-scale-color.scale91-100
                .heatmap-scale-text 91-100
        .col-12.vc-my-team.heatmap2-par1(v-else)
          p データを読み込んでいます。少々お待ちください。

</template>

<script>
import { mapState, mapActions } from 'vuex';
import QualitativeOrganizations from '../../../components/u/qualitative/QualitativeOrganizations';
import OpinionMatrix from '../../../components/u/qualitative/OpinionMatrix';
import OpinionHeatmap from '../../../components/u/qualitative/OpinionHeatmap';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    QualitativeOrganizations,
    OpinionMatrix,
    OpinionHeatmap
  },

  data() {
    return {
      selectOpinionClass: 'opinion_count',
      displayType: null,
      matrixVisible: false,
      heatmapVisible: false,
      organizationId: '',
      emotionClassificationId: 0,
      cellMode: 'count',
      pageMode: 'matrix'
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('pages/u_surveys/select', ['surveyId']),
    ...mapState('resources/u_quantitative', [
      'questionId',
      'organizationIds',
      'orgHierarchy',
      'attributeValueIds',
      'responseAttributes',
      'navOrganizations'
    ]),
    ...mapState('resources/u_analyse_matrix', [
      'organizations',
      'opinionClassifications',
      'emotionClassifications',
      'matrixDataArray'
    ]),
    ...mapState('resources/u_analyse_heatmap', [
      'heatmapDataArray',
      'totalHeatmapDataArray'
    ]),
    ...mapState('resources/u_analyse', ['isDisplay'])
  },

  watch: {
    questionId() {
      this.preLoad('questionId');
    },
    orgHierarchy() {
      this.load();
    }
  },

  created() {
    this.loadControlSettings();
    const vcd = defaultCookie.get();
    if (vcd['surveyId'] || this.surveyId) this.preLoad('surveyId');
    if (this.questionId) this.preLoad('questionId');
    this.$on('check-item', this.load);
    this.checkRequiredParams();
    this.setDefaultDisplayType();
    this.loadQuestions({ survey_id: vcd['surveyId'] || this.surveyId });
    this.loadOuterFrameData({
      survey_id: vcd['surveyId'] || this.surveyId,
      page_name: 'organizations'
    });
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapActions('resources/u_quantitative', ['loadQuestions']),
    ...mapActions('resources/u_analyse_matrix', [
      'loadOuterFrameData',
      'loadMatrixData'
    ]),
    ...mapActions('resources/u_analyse_heatmap', ['loadHeatmapData']),

    checkRequiredParams() {
      const vcd = defaultCookie.get();

      // 必要なパラメーターが取得できなかったら、強制的に遷移させる
      if (
        (vcd['surveyId'] || this.surveyId) === null ||
        (vcd['surveyId'] || this.surveyId) === '' ||
        (this.questionId || vcd['questionId']) === null ||
        (this.questionId || vcd['questionId']) === ''
      ) {
        window.location.href = this.$router.resolve({
          name: 'u_qualitative_emotion_classifications_team'
        }).href;
        return;
      }
    },

    setDefaultDisplayType() {
      const vcd = defaultCookie.get();
      this.displayType = vcd['displayType'] || 0;
    },

    load() {
      this.matrixVisible = false;
      this.heatmapVisible = false;
      const vcd = defaultCookie.get();
      const params = {
        survey_id: vcd['surveyId'] || this.surveyId,
        question_id: this.questionId || vcd['questionId'],
        organization_ids: this.organizations.map(org => org.id),
        emotion_classification_id: this.emotionClassificationId,
        organization_hierarchy: this.orgHierarchy,
        page_name: 'organizations'
      };

      this.loadMatrixData(params).then(_response => {
        if (!this.matrixVisible) {
          this.matrixVisible = true;
        }
      });

      this.loadHeatmapData(params).then(_response => {
        if (!this.heatmapVisible) {
          this.heatmapVisible = true;
        }
      });
    },

    setEmotionClassificationId(em) {
      this.emotionClassificationId = em.id;
      this.load();
    },

    setCellMode(mode) {
      this.cellMode = mode;
    },

    setPageMode(mode) {
      this.pageMode = mode;
    },

    findAttributeName(attrValId) {
      if (attrValId === 'all') {
        return this.$t('user.qualitative.analyse_matrix.all');
      }

      for (let i = 0; i < this.responseAttributes.length; i++) {
        var el = this.responseAttributes[i];
        for (let j = 0; j < el.attribute_values.length; j++) {
          var item = el.attribute_values[j];
          if (item.id === attrValId) {
            return item.name;
          }
        }
      }
    },

    preLoad(type) {
      setTimeout(() => {
        if (
          this.isDisplay['opinion_classifications'].includes(this.questionId)
        ) {
          if (type === 'surveyId') {
            const vcd = defaultCookie.get();
            this.loadQuestions({ survey_id: vcd['surveyId'] || this.surveyId });
          } else if (type === 'questionId') {
            if (this.organizations.length > 0) this.load();
            else {
              setTimeout(() => {
                this.load();
              }, 1000);
            }
          }
        } else {
          const cs = this.controlSettings.default_qualitative_menu;
          const pageName = cs.enabled
            ? 'u_' + cs.values
            : 'u_qualitative_emotion_classifications_team';
          this.$router.push({ name: pageName });
        }
      }, 1000);
    }
  }
};
</script>

<style scoped>
.vc-parameter-nav .vc-ranking-btn {
  /* width: 50px; */
  text-align: center;
}
.vc ul.vc-parameter-nav li a.vpn-all:hover {
  background: rgba(243, 21, 74, 0.5) !important;
}
.vc ul.vc-parameter-nav li a.vpn-all.active:hover {
  background: rgba(243, 21, 74, 1) !important;
}
.vc ul.vc-parameter-nav li a.vpn-all.active {
  background: #f3154a !important;
}
</style>
