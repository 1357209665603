import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_quantitative');

export default {
  namespaced: true,

  state: {
    organizations: [],
    parentIdByIds: {},
    displayAttributeValues: false,
    organizationIds: [],
    started: false,
    navOrganizations: [],
    questions: [],
    analyseData: [],
    analyseChangesData: [],
    calculatedAnalyseChangesData: [],
    quantitativeQuestions: [],
    quantitativeAnswerSelections: {},
    questionId: '',
    quantitativeQuestion: {},
    compares: [],
    surveyName: '',
    prevSurveyName: '',
    responseAttributeNames: [],
    orgLevels: [],
    responseAttributes: [],
    attributeValueIds: [],
    responseAttributeId: null,
    responseIds: [],
    orgHierarchy: 0
  },

  getters: {},

  actions: {
    loadAnalyseData({ commit }, params) {
      return gateway.get({ action: 'analyse' }, params).then(response => {
        if (response.status === 200) {
          commit('setAnalyseData', JSON.parse(response.body.analyse_data));
          commit(
            'setQuantitativeQuestions',
            response.body.quantitative_questions
          );
          commit(
            'setQuantitativeAnswerSelections',
            response.body.answer_selections
          );
          commit('setResponseIds', response.body.response_ids);
        }
        return response;
      });
    },
    loadQuestions({ commit }, params) {
      return gateway.get({ action: 'questions' }, params).then(response => {
        if (response.status === 200) {
          // commit('setQuestions', response.body.questions);
          commit('setNavOrganizations', response.body.organizations);
          commit('setResponseAttributes', response.body.response_attributes);
          commit('setOrgLevels', response.body.org_levels);
        }
        return response;
      });
    },
    loadAnalyseChangesData({ commit }, params) {
      return gateway
        .get({ action: 'analyse_changes' }, params)
        .then(response => {
          if (response.status === 200) {
            commit('setSurveys', response.body.surveys);
            commit('setAnalyseChangesData', response.body.analyse_changes_data);
            commit(
              'setQuantitativeQuestions',
              response.body.quantitative_questions
            );
          }
        });
    },
    loadCalculatedAnalyseData({ commit }, params) {
      return gateway
        .get({ action: 'calculated_analyse' }, params)
        .then(response => {
          if (response.status === 200) {
            commit('setAnalyseData', JSON.parse(response.body.analyse_data));
            commit(
              'setQuantitativeQuestions',
              response.body.quantitative_questions
            );
          }
          return response;
        });
    },
    loadCalculatedAnalyseChangesData({ commit }, params) {
      return gateway
        .get({ action: 'calculated_analyse_changes' }, params)
        .then(response => {
          if (response.status === 200) {
            commit(
              'setCalculatedAnalyseChangesData',
              response.body.calculated_analyse_changes_data
            );
            commit(
              'setQuantitativeQuestions',
              response.body.quantitative_questions
            );
            commit('setSurveys', response.body.surveys);
          }
        });
    },
    loadCalculatedAnalyseDetails({ commit }, params) {
      return gateway
        .get({ action: 'calculated_analyse_details' }, params)
        .then(response => {
          if (response.status === 200) {
            commit(
              'setQuantitativeQuestion',
              response.body.quantitative_question
            );
            commit('setCompares', response.body.compares);
            commit('setResponseAttributeNames', response.body.ra_names);
            commit('setOrganizations', response.body.organizations);
            commit('setOrgLevels', response.body.org_levels);
          }
          return response;
        });
    },
    loadOrganizations({ commit }, params) {
      return gateway.get({ action: 'organizations' }, params).then(response => {
        if (response.status === 200) {
          commit('setOrganizations', response.body.organizations);
        }
      });
    },
    loadRespondersInfo(_, params) {
      return gateway
        .get({ action: 'responders_info' }, params)
        .then(response => {
          if (response.status === 200) {
            return response;
          }
        });
    },
    loadNavOrganizations({ commit }, params) {
      return gateway
        .get({ action: 'nav_organizations' }, params)
        .then(response => {
          if (response.status === 200) {
            commit('setNavOrganizations', response.body.organizations);
            return response;
          }
        });
    }
  },

  mutations: {
    setSurveys(state, surveys) {
      state.surveys = surveys;
    },
    setOrganizations(state, organizations) {
      const _organizations = {};
      const _parentIdByIds = {};
      for (const i in organizations) {
        _parentIdByIds[organizations[i].id] = organizations[i].parent_id;
        const index = organizations[i].parent_id
          ? organizations[i].parent_id
          : 0;
        if (!_organizations[index]) _organizations[index] = [];
        _organizations[index].push(organizations[i]);
      }
      state.organizations = _organizations;
      state.parentIdByIds = _parentIdByIds;
    },
    setOrganizationIds(state, organizationIds) {
      state.organizationIds = organizationIds;
    },
    setDisplayAttributeValues(state, display) {
      state.displayAttributeValues = display;
    },
    setResponseAttributes(state, response_attributes) {
      state.responseAttributes = response_attributes;
    },
    setNavOrganizations(state, organizations) {
      state.navOrganizations = organizations;
    },
    setStarted(state, started) {
      state.started = started;
    },
    setQuestions(state, questions) {
      state.questions = questions;
    },
    setAnalyseData(state, data) {
      state.analyseData = data;
    },
    setQuantitativeQuestions(state, quantitative_questions) {
      state.quantitativeQuestions = quantitative_questions;
    },
    setQuantitativeAnswerSelections(state, selections) {
      state.quantitativeAnswerSelections = selections;
    },
    setQuestionId(state, questionId) {
      state.questionId = questionId;
    },
    setAnalyseChangesData(state, data) {
      state.analyseChangesData = data;
    },
    setCalculatedAnalyseChangesData(state, data) {
      state.calculatedAnalyseChangesData = data;
    },
    setQuantitativeQuestion(state, question) {
      state.quantitativeQuestion = question;
    },
    setCompares(state, compares) {
      state.compares = compares;
    },
    setResponseAttributeNames(state, names) {
      state.responseAttributeNames = names;
    },
    setOrgLevels(state, levels) {
      state.orgLevels = levels;
    },
    setAttributeValueIds(state, attributeValueIds) {
      state.attributeValueIds = attributeValueIds;
    },
    setResponseAttributeId(state, response_attributeId) {
      state.responseAttributeId = response_attributeId;
    },
    setResponseIds(state, responseIds) {
      state.responseIds = responseIds;
    },
    setOrganizationHierarchy(state, hierarchy) {
      state.orgHierarchy = hierarchy;
    }
  }
};
