<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content
        .vc-main-container.container
          .vc-content-col#userList
            .container
              .row
                .col-12
                  h3.vc-midashi.mb-3
                    | 分類修正リクエスト一覧
                  hr.vc-full-divider
              .row
                .col-md-12.grid-margin.stretch-card.clearfix
                  .card
                    .card-body.pb-0
                      .table-responsive
                        table.table.table-striped
                          thead
                            tr
                              th
                                | 調査名
                              th
                                | 申請ユーザー
                              th
                                | ステータス
                              th
                          tbody
                            tr(v-for="request in requests")
                              td
                                | {{ request.survey.name }}
                              td
                                | {{ request.requester.name }}
                              td
                                | {{ status(request.status) }}
                              td.text-right
                                router-link.btn.btn-primary.vc-primary-btn.btn-sm.ml-auto.p-2(
                                  :to="{ name: 'u_classification_request_show', params: { id: request.id } }"
                                )
                                  | 詳細
                      .mt-1.float-right
                        paginate(
                          :page-count="pageParams.pages"
                          :click-handler="clickCallback"
                          containerClass="pagination"
                          pageClass="page-item"
                          pageLinkClass="page-link"
                          prevText="«"
                          prevClass="page-item"
                          prevLinkClass="page-link"
                          nextText="»"
                          nextClass="page-item"
                          nextLinkClass="page-link"
                        )
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Paginate from 'vuejs-paginate';

export default {
  components: {
    Paginate
  },

  data() {
    return {
      defaultPer: 20
    };
  },

  computed: {
    ...mapState('resources/u_classification_requests', ['requests']),
    ...mapState('pages/u_classification_requests/list/pagination', [
      'pageParams'
    ])
  },

  created() {
    this.setPer(this.defaultPer);
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_classification_requests', ['loadRequests']),
    ...mapMutations('pages/u_classification_requests/list/pagination', [
      'setTotal',
      'setPages',
      'setPage',
      'setPer'
    ]),

    load() {
      this.loadRequests(this.pageParams).then(response => {
        this.setTotal(response.body.page_options.total);
        this.setPages(response.body.page_options.pages);
      });
    },

    status(status) {
      if (status === 0) return '申請中';
      else if (status === 1) return '修正済';
      else if (status === 2) return '却下';
      else return '申請中';
    },

    clickCallback(pageNum) {
      this.setPage(pageNum);
      this.load();
    }
  }
};
</script>
