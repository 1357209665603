<template lang="pug">
  .content-wrapper
    .content-header.d-flex.flex-column.flex-md-row
      h4.mb-0
        | {{ accountGroup.name }}
    .row
      .col-md-12.grid-margin.stretch-card.clearfix
        .card
          .card-body.vc
            .row.mb-4
              .col-12
                error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
                ul.alert.alert-success(v-show="message")
                  button.close.vc-message-close(@click="resetMessages")
                    span(aria-hidden='true') ×
                  li.ml-1
                    | {{ message }}
                .mb-3
                  p.cc-label-mid 属性CSVのアップロード
                  a.cc-primary-btn.cc-inline-btn.mr-2(href="" @click.prevent="exportTempCsv('surveyResponses')")
                    | テンプレートCSVをダウンロード
                  label.cc-normal-btn.cc-file-btn.mr-2 ファイルを添付
                    input.d-none(type="file" accept="text/csv" @change="onFileChange($event)")
                  a.cc-primary-btn.cc-inline-btn(href="" @click.prevent="importCsv('responses')") 属性CSVのアップロード
            .row(v-show="attributeValues.length > 0")
              .col-12
                p.cc-label-mid 保存済属性データ
                .interview-detail
                  .table-responsive.scrollable-tabele.vc-responses-result
                    span
                      | {{ attributeValues.length }}件
                    table.table.table-bordered
                      thead
                        tr
                          th 属性分類
                          th Code
                          th 属性名
                      tbody
                        tr(v-for="val in attributeValues")
                          td
                            | {{ val.response_attribute.name }}
                          td
                            | {{ val.code }}
                          td
                            | {{ val.name }}
</template>
<script>
import { mapState, mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';
import i18n from '../../../locales/index';
import moment from 'moment';
import CDL from '../../../lib/csv_download';
const CSVDownload = new CDL();

export default {
  components: {
    ErrorMessage
  },

  data() {
    return {
      errorMessages: [],
      message: '',
      csvFile: ''
    };
  },

  computed: {
    ...mapState('resources/x_account_groups', [
      'accountGroup',
      'attributeValues'
    ])
  },

  created() {
    this.loadAttributeValues(this.$route.params.id);
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_account_groups', ['loadAttributeValues']),

    resetErrorMessages() {
      this.errorMessages = [];
    },

    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.updateAccountGroup(this.accountGroupParams)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'x_account_groups' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },

    resetMessages() {
      this.errorMessages = [];
      this.message = '';
    },

    importCsv() {
      this.resetMessages();

      let path =
        '/api/x/account_groups/' +
        this.$route.params.id +
        '/create_attribute_values';
      const formData = new FormData();
      formData.append('csv_file', this.csvFile);

      fetch(path, {
        method: 'PUT',
        body: formData
      }).then(response => {
        if (response.status === 200) {
          this.message = i18n.tc('admin.surveys.messages.processing');
        } else if (response.status === 422) {
          response.json().then(body => {
            this.errorMessages = body.errors;
          });
        }
      });
    },

    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.csvFile = files[0];
    },

    exportTempCsv() {
      const head = [
        'response_attribute_code',
        'response_attribute_name',
        'attribute_value_code',
        'attribute_value_name'
      ];

      CSVDownload.downloadCsv(
        head,
        [],
        'voiscope_attribute_values_' +
          moment().format('YYYYMMDD_HHmmss') +
          '.csv'
      );
    }
  }
};
</script>
