<template lang="pug">
.row.vs-ex-heatmap2-classification(v-if="visible")
  .col-12
    .vs-ex-heatmap2-class-row(v-for="(group, index) in groups" :key="index")
      .vs-ex-heatmap2-class-col(
        v-for="cls in group" :key="cls.id"
      )
        a.vs-ex-heatmap2-class-col-a(
          type="button"
          data-toggle="dropdown"
          :class="classfor(cls.id)"
        )
          .vs-ex-heatmap2-class-title
            | {{ cls.name }}
          .vs-ex-heatmap2-class-value.dropdown-toggle
            | {{ cellValue(cls.id) }}
        .dropdown-menu
          a.dropdown-item(
            @click.prevent="toOpinionSearch(cls['id'])"
          )
            | 意見検索
</template>

<script>
import { mapState } from 'vuex';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    organization: {
      type: Object,
      required: true,
      default: null
    },
    emotionClassificationId: {
      type: Number,
      required: true,
      default: 0
    },
    opinionClassifications: {
      type: Array,
      required: true,
      default: () => []
    },
    heatmapData: {
      type: Object,
      required: true,
      default: null
    }
  },
  computed: {
    ...mapState('pages/u_surveys/select', ['surveyId']),

    /**
     * opinionClassificationsを3つずつに分割する
     * @return {Array}
     */
    groups() {
      const result = [];
      const length = this.opinionClassifications.length;
      for (let i = 0; i < length; i += 3) {
        result.push(this.opinionClassifications.slice(i, i + 3));
      }
      return result;
    }
  },
  methods: {
    /**
     * heatmapDataからidの値を取得する
     * @param {*} id
     */
    fetchHeatmapData(id) {
      return this.heatmapData[id];
    },

    /**
     * heatmapDataからclsIdの値を取得する
     * @param {*} clsId
     */
    cellValue(clsId) {
      var data = this.fetchHeatmapData(clsId);
      if (
        data !== null &&
        typeof data === 'object' &&
        Object.keys(data).length !== 0
      ) {
        return data['percentage'];
      } else {
        return '0%';
      }
    },

    /**
     * clsIdの値に応じてclassを返す
     */
    classfor(clsId) {
      var val = this.cellValue(clsId);
      var num = parseInt(val.slice(0, -1));

      if (num >= 0 && num <= 10) {
        return 'scale0-10';
      } else if (num >= 11 && num <= 20) {
        return 'scale11-20';
      } else if (num >= 21 && num <= 30) {
        return 'scale21-30';
      } else if (num >= 31 && num <= 40) {
        return 'scale31-40';
      } else if (num >= 41 && num <= 50) {
        return 'scale41-50';
      } else if (num >= 51 && num <= 60) {
        return 'scale51-60';
      } else if (num >= 61 && num <= 70) {
        return 'scale61-70';
      } else if (num >= 71 && num <= 80) {
        return 'scale71-80';
      } else if (num >= 81 && num <= 90) {
        return 'scale81-90';
      } else if (num >= 91 && num <= 100) {
        return 'scale91-100';
      }
    },

    /**
     * 意見検索へ遷移
     * @param {*} classification_id
     */
    toOpinionSearch(classification_id) {
      const vcd = defaultCookie.get();
      const params = {
        survey_id: vcd['surveyId'],
        emotion_classification: this.emotionClassificationId,
        opinion_classification: classification_id,
        backToName: 'u_qualitative_analyse_heatmap'
      };

      // all以外の場合 organization_idを追加
      if (this.organization.id > 0) {
        params.organization_id = this.organization.id;
      }

      this.$router.push({
        name: 'u_qualitative_opinion_search',
        params: params
      });
    }
  }
};
</script>
