<template lang="pug">
  div.vc-sub-select-wrap
    .form-group.row.d-flex.align-items-center
      label.col-2.col-form-label.pl-1
        | 分析対象のチーム
      .col-8
        .row
          .col-3.vc-sub-select-box
            select(
              class="form-control vc-sub-select"
              v-model="selected[0]"
            )
              option(value="undefined")
                | すべて
              option(
                v-for="organization in organizations[0]"
                v-bind:value="organization.id"
                v-bind:key="organization.id"
              )
                | {{organization.name}}
          .col-3.vc-sub-select-box(
            v-for="(parent_id, index) in selected"
            v-bind:value="parent_id"
            v-bind:key="index"
            v-if="selected[index] && organizations[parent_id]"
          )
            select(
              class="form-control vc-sub-select"
              v-model="selected[index + 1]"
            )
              option(value="undefined")
                | すべて
              option(
                v-for="organization in organizations[parent_id]"
                v-bind:value="organization.id"
                v-bind:key="organization.id"
              )
                | {{organization.name}}
      //- .col-2.text-center(v-if="this.$route.path != '/analyse/compare' || isDisplayButton")
        button.btn.btn-primary.vc-primary-btn(
          :disabled="processing"
          @click.prevent="submit"
        )
          | 適用

</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import dc from '../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {},

  props: {
    isDisplayButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      selected: [],
      processing: false
    };
  },

  computed: {
    ...mapState('resources/u_organizations', [
      'defaultOrganizationId',
      'organizationIds',
      'parentIdByIds',
      'organizations'
    ]),
    ...mapState('resources/u_surveys', ['surveys']),
    ...mapState('pages/u_organizations/select', ['beforeSelected', 'started']),
    ...mapState('pages/u_surveys/select', ['surveyId'])
  },

  watch: {
    selected: function() {
      var del_index = null;
      var del_count = 0;
      for (var i = 0; i < this.selected.length; i++) {
        if (this.selected[i] == 'undefined') this.selected[i] = '';
        if (del_index) {
          del_count++;
        } else {
          if (this.beforeSelected[i] != this.selected[i]) del_index = i + 1;
        }
      }
      if (del_index && del_count > 0) {
        this.selected.splice(del_index, del_count);
      }
      if (!this.selected.slice(-1)[0] && this.selected.length > 0)
        this.selected.pop();
      this.setSelect({ beforeSelected: Array.from(this.selected) });
      if (this.started && this.$route.path.indexOf('analyse/compare') > 0) {
        this.$emit('setOrganizationId', this.selected.slice(-1)[0]);
      }
      this.submit();
    },
    surveyId: function() {
      this.loadOrganizations(this.surveyId);
      this.selected = [];
      this.setSelect({ beforeSelected: [] });
      if (this.started)
        this.$emit('setOrganizationId', this.selected.slice(-1)[0]);
    },
    organizations: function() {
      if (!this.started) {
        const vcd = defaultCookie.get();
        if (!this.started && vcd) {
          this.defaultSelect(
            vcd['organizationId'] ? vcd['organizationId'] : null
          );
        } else {
          this.defaultSelect(null);
        }
        //所属組織デフォルト設定 今後使用予定
        // this.defaultSelect(this.defaultOrganizationId);
        this.setSelect({ started: true });
      }
    }
  },

  created() {
    const vcd = defaultCookie.get();
    if (this.$route.params.default) {
      if (!this.organizations[0]) this.loadOrganizations(vcd['surveyId']);
      if (vcd && this.organizations && this.organizations[0]) {
        this.defaultSelect(
          vcd['organizationId'] ? vcd['organizationId'] : null
        );
      }
    } else {
      if (this.beforeSelected.length > 0) {
        this.selected = this.beforeSelected;
      }

      if (this.$route.params.organizationId) {
        this.defaultSelect(this.$route.params.organizationId);
      }

      const vcd = defaultCookie.get();

      // 調査選択済みかつ組織データがない場合、組織データを取得
      if (!this.organizations[0] && vcd['surveyId'])
        this.loadOrganizations(vcd['surveyId']);

      // 組織データのデフォルトが空になっている場合、強制的に組織選択をリセット
      if (
        vcd &&
        this.organizations &&
        this.organizations[0] &&
        vcd['organizationId'] == null
      ) {
        this.defaultSelect(null);

        // 初期表示以外の時に実行
      } else if (this.started) {
        this.$emit('setOrganizationId', this.selected.slice(-1)[0]);

        // 組織データが取得済みの場合、デフォルト設定を反映
      } else if (vcd && this.organizations && this.organizations[0]) {
        this.defaultSelect(
          vcd['organizationId'] ? vcd['organizationId'] : null
        );
        this.setSelect({ started: true });
      }
    }
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_organizations', ['loadOrganizations']),
    ...mapMutations('pages/u_organizations/select', ['setSelect']),
    defaultSelect(organizationId) {
      let selected = [];
      const parentIdByIds = this.parentIdByIds;
      const organizations = this.organizations;
      if (organizations && parentIdByIds && organizationId) {
        selected = [organizationId];
        let targetId = organizationId;
        while (parentIdByIds[targetId]) {
          selected.unshift(parentIdByIds[targetId]);
          targetId = parentIdByIds[targetId];
        }
      }
      this.setSelect({ beforeSelected: selected });
      this.selected = selected;
      const setOrganizationId = this.selected.slice(-1)[0];
      if (this.organizationIds.includes(setOrganizationId)) {
        this.$emit('setOrganizationId', setOrganizationId);
      } else {
        this.$emit('setOrganizationId', null);
      }
    },
    submit() {
      this.processing = true;
      if (this.$route.path.indexOf('analyse/compare') < 1)
        this.$emit('setOrganizationId', this.selected.slice(-1)[0]);
      else {
        this.$emit('changeOrganization', this.selected.slice(-1)[0]);
      }
      this.processing = false;
    }
  }
};
</script>
<style scoped>
.col-form-label {
  margin-left: 12.5px;
}
</style>
