<template lang="pug">
  div
    modal(
      ref="modalOpinionAttrVal"
      name="modalOpinionAttrVal"
      id="modalOpinionAttrVal"
      :minWidth="750"
      :minHeight="780"
      :width="750"
      :height="780"
      :reset="true"
      :resizable="false"
      :clickToClose="false"
      :draggable="true")
      header.modal-header.align-items-end
        h6.modal-title.vc-midashi
          | 属性別の意見数の内訳グラフ
          br
          | {{organizationName}} {{opinionClassificationName}}
        dl.respondent-num.align-items-center
          dt
            | 意見数
          dd
            | {{opinionCount | separate}}
          dt
            | 回答者数
          dd.mr-0
            | {{surveyResponseCount | separate}}
          dt.ml-0.mb-4
            button(type="button" @click="$modal.hide('modalOpinionAttrVal')" class="close vc-close")
              | ×
      .row.no-gutters
        .col-4.mt-3.mb-3(
          v-for="aggregateData of aggregateDatas"
          )
          h5.vc-midashi.text-center
            | {{aggregateData[0].response_attribute_name}}
          pie-chart(:chart-data="aggregateData.datacollection" :options="aggregateData.options")
        .col-12.mt-2.d-flex.align-items-center.justify-content-center
          p.font-weight-bold
            | 【数字の見方】
          p.small.inline-block
            | 例えば性別の場合、女性の
            span.font-weight-bold
              | 意見数
            | がxx%、男性の
            span.font-weight-bold
              | 意見数
            | がxx%という見方をします
            br
            |（女性回答者数や男性
            span.font-weight-bold
              | 回答者数の内訳グラフではありません
            | ）
      //- .row
      //-   .col(v-for="aggregateData of aggregateDatas")
      //-     .row.no-gutters.vc-respondent-table
      //-       .col-6.text-left
      //-         | {{aggregateData[0].response_attribute_name}}
      //-       .col-3.text-center
      //-         | 意見数
      //-       .col-3.text-center
      //-         | 回答者数
      //-     .row.no-gutters.vc-respondent-table(v-for="ad in aggregateData")
      //-       .col-6.text-left
      //-         | {{ad.attribute_value_name}}
      //-       .col-3.text-center
      //-         | {{ad.opinion_count | separate}}
      //-       .col-3.text-center
      //-         | {{ad.survey_response_count | separate}}

</template>

<script>
import { mapState, mapActions } from 'vuex';
import PieChart from '../chartJs/PieChart';

export default {
  components: {
    PieChart
  },

  data() {
    return {
      organizationName: '',
      opinionClassificationName: '',
      surveyResponseCount: 0,
      opinionCount: 0,
      aggregateDatas: {}
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_analyse_team', ['opinion_per_attribute_values'])
  },

  watch: {
    opinion_per_attribute_values: function() {
      this.organizationName = this.opinion_per_attribute_values.organization_name;
      this.opinionClassificationName = this.opinion_per_attribute_values.opinion_classification_name;
      this.surveyResponseCount = this.opinion_per_attribute_values.survey_response_count;
      this.opinionCount = this.opinion_per_attribute_values.opinion_count;
      const aggregateDatas = {};
      for (const aggregateData of this.opinion_per_attribute_values
        .aggregate_datas) {
        if (!aggregateDatas[aggregateData.id])
          aggregateDatas[aggregateData.id] = [];
        aggregateDatas[aggregateData.id].push(aggregateData);
      }
      this.aggregateDatas = aggregateDatas;
      this.pieGraphUpdate();
    }
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_analyse_team', [
      'loadOpinionPerAttributeValues'
    ]),
    modalOpen: function(data) {
      const params = {
        survey_id: data.survey_id,
        question_id: data.question_id,
        organization_id: data.organization_id,
        opinion_classification_id: data.opinion_classification_id,
        attribute_value_ids: data.attribute_value_ids
          ? data.attribute_value_ids
          : [],
        emotion_classification_id: data.emotion_classification_id
          ? data.emotion_classification_id
          : ''
      };

      this.loadOpinionPerAttributeValues(params);
      this.$modal.show('modalOpinionAttrVal');
    },
    pieGraphUpdate: function() {
      const colors = [
        '#A6CEE3',
        '#E4CCF0',
        '#FFC1C1',
        '#FF6F70',
        '#AE81DE',
        '#5DB6F2',
        '#FFFFC2',
        '#BEE6FB',
        '#FFB266',
        '#FFD9A7',
        '#C8F5A0',
        '#EB9362',
        '#93EB62',
        '#6293EB',
        '#C8A0F5'
      ];
      for (const i in this.aggregateDatas) {
        const labels = [];
        const data = [];
        for (const ad of this.aggregateDatas[i]) {
          labels.push(ad.attribute_value_name);
          data.push(ad.opinion_count);
        }
        this.aggregateDatas[i]['datacollection'] = {
          labels: labels,
          datasets: [
            {
              backgroundColor: colors,
              data: data
            }
          ]
        };
        this.aggregateDatas[i]['options'] = {
          responsive: true,
          layout: {
            padding: {
              left: 12,
              right: 12,
              top: 0,
              bottom: 0
            }
          },
          legend: {
            display: false,
            position: 'bottom',
            align: 'start'
          }
        };
      }
    }
  }
};
</script>
