<template lang="pug">
  .content-wrapper
    .content-header.d-flex.flex-column.flex-md-row
      h4.mb-0
        | {{ $t('admin.classificationRequests.title') }}
    .row
      .col-md-12.grid-margin.stretch-card.clearfix
        .card
          .card-body.pb-0
            .row.vc-sub-select-wrap
              .col-12
                .vc-sub-select-box
                  span.mr-3.font-weight-bold.small アカウントグループ
                  select.form-control.vc-sub-select.w-auto(v-model="accountGroupId")
                    option(value="undefined")
                      | すべて
                    option(
                      v-for="ag in accountGroups"
                      :value="ag.id"
                      :key="ag.id"
                    )
                      | {{ag.name}}
                  button.btn.btn-primary.vc-primary-btn.btn-sm.p-2.detail-btn.ml-3(@click="load")
                    | 検索
            .table-responsive
              table.table.table-striped
                thead
                  tr
                    th アカウントグループ
                    th 調査名
                    th 申請ユーザー
                    th ステータス
                    th
                tbody
                  tr(v-for="request in requests")
                    td
                      | {{ request.account_group.name }}
                    td
                      | {{ request.survey.name }}
                    td
                      | {{ request.requester.name }}
                    td
                      | {{ status(request.status) }}
                    td.text-right
                      router-link.btn.btn-primary.vc-primary-btn.btn-sm.ml-auto.p-2.detail-btn(
                        :to="{ name: 'x_show_classification_request', params: { id: request.id } }"
                      )
                        | 詳細
            .mt-1.float-right
              paginate(
                :page-count="pageParams.pages"
                :click-handler="clickCallback"
                containerClass="pagination"
                pageClass="page-item"
                pageLinkClass="page-link"
                prevText="«"
                prevClass="page-item"
                prevLinkClass="page-link"
                nextText="»"
                nextClass="page-item"
                nextLinkClass="page-link"
              )
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Paginate from 'vuejs-paginate';

export default {
  components: {
    Paginate
  },

  data() {
    return {
      defaultPer: 20,
      accountGroupId: undefined
    };
  },

  computed: {
    ...mapState('resources/x_classification_requests', [
      'requests',
      'accountGroups'
    ]),
    ...mapState('pages/x_classification_requests/list/pagination', [
      'pageParams'
    ])
  },

  created() {
    this.setPer(this.defaultPer);
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_classification_requests', ['loadRequests']),
    ...mapMutations('pages/x_classification_requests/list/pagination', [
      'setTotal',
      'setPages',
      'setPage',
      'setPer'
    ]),

    load() {
      const hash = { account_group_id: this.accountGroupId };
      const params = Object.assign(this.pageParams, hash);
      this.loadRequests(params).then(response => {
        this.setTotal(response.body.page_options.total);
        this.setPages(response.body.page_options.pages);
      });
    },

    status(status) {
      if (status === 0) return '申請中';
      else if (status === 1) return '修正済';
      else if (status === 2) return '却下';
      else return '申請中';
    },

    clickCallback(pageNum) {
      this.setPage(pageNum);
      this.load();
    }
  }
};
</script>
<style scoped>
.detail-btn {
  background: #fff;
  border-radius: 2px;
  border: 1px solid #f3154a;
  padding: 12px;
  color: #f3154a;
  font-weight: 600;
  -webkit-transition: 0.15s ease;
  transition: 0.15s ease;
  white-space: nowrap;
}
.detail-btn:hover {
  text-decoration: none;
  background: #f3154a;
  color: #fff;
  border: 1px solid #f3154a;
}
</style>
