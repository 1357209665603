import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_topic');

export default {
  namespaced: true,

  state: {
    topicPer: [],
    emotionIds: {},
    survey: {},
    organizations: [],
    selected: [],
    displayAttributeValues: false,
    responseAttributes: [],
    keepedSelectedOrganizations: [],
    started: false,
    topicRanking: []
  },

  getters: {},

  actions: {
    loadTopicPer({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          commit('setTopicPer', response.body.topic_per);
          commit('setEmotionIds', response.body.emotion_ids);
          commit('setSurvey', response.body.survey);
          commit('setOrganizations', response.body.organizations);
          commit('setResponseAttributes', response.body.response_attributes);
        }
      });
    },
    loadTopicRanking({ commit }, params) {
      return gateway.get({ action: 'ranking' }, params).then(response => {
        if (response.status === 200) {
          commit('setTopicRanking', response.body.topic_ranking);
          commit('setEmotionIds', response.body.emotion_ids);
          commit('setSurvey', response.body.survey);
        }
      });
    }
  },

  mutations: {
    setTopicPer(state, topic_per) {
      state.topicPer = topic_per;
    },

    setEmotionIds(state, ids) {
      state.emotionIds = ids;
    },

    setSurvey(state, survey) {
      state.survey = survey;
    },

    setOrganizations(state, organizations) {
      state.organizations = organizations;
    },

    setSelected(state, selected) {
      state.selected = selected;
    },

    setDisplayAttributeValues(state, display) {
      state.displayAttributeValues = display;
    },

    setResponseAttributes(state, response_attributes) {
      state.responseAttributes = response_attributes;
    },

    setKeepedSelectedOrganizations(state, organizations) {
      state.keepedSelectedOrganizations = organizations;
    },

    setStarted(state, started) {
      state.started = started;
    },

    setTopicRanking(state, ranking) {
      state.topicRanking = ranking;
    }
  }
};
