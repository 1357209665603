<template lang="pug">
  .content-wrapper
    .row.flex-grow
      .col-6
        .card
          .card-body
            h4.card-title
              | {{ $t('admin.admins.new.title') }}

            form
              .alert.alert-danger(v-if="errorMessages.length > 0")
                ul.mb-0
                  li(v-for="error in errorMessages")
                    | {{ error }}
              validation-observer(v-slot="{ invalid }")
                validation-provider(name="name" rules="required" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.admins.name') }}
                    input(
                      type="text"
                      placeholder="Suzuki Taro"
                      :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      v-model="name"
                    )
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                validation-provider(name="email" rules="required|email" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.admins.email') }}
                    input(
                      type="email"
                      placeholder="admin@example.com"
                      :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      v-model="email"
                    )
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                .mt-1.float-right
                  router-link.btn.btn-light.mr-2(:to="{ name: 'x_admins' }")
                    | {{ $t('actions.cancel') }}
                  button.btn.btn-success(
                    :disabled="processing || invalid"
                    @click.prevent="submit"
                  )
                    | {{ $t('actions.create') }}

</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      name: '',
      email: '',
      errorMessages: [],
      processing: false
    };
  },

  computed: {
    /**
     * サーバサイドに渡す admin のパラメータを生成
     *
     * @return {object}
     */
    adminParams() {
      return {
        admin: {
          name: this.name,
          email: this.email
        }
      };
    }
  },

  created() {},

  mounted() {},

  methods: {
    ...mapActions('resources/x_admins', ['createAdmin']),

    resetErrorMessages() {
      this.errorMessages = [];
    },

    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.createAdmin(this.adminParams)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'x_admins' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
