<template lang="pug">
  div
    div
      .vc-parameter-nav-wrap.d-flex.justify-content-between.align-items-center.pb-4.flex-wrap
        ul.vc-parameter-nav
          li
            a.vpn-all(
              @click.prevent="changeSelectEmotionClassificationId('0')"
              :class="{ 'active': (selectEmotionClassificationId == '0')}"
              )
              | 全体
          li(v-for="emotion_classification in emotion_classifications")
            a(
              @click.prevent="changeSelectEmotionClassificationId(emotion_classification.id, emotion_classification.code)"
              :class="[{'active': (selectEmotionClassificationId == emotion_classification.id)}, btnVpnClass[emotion_classification.code]]"
              )
              | {{emotion_classification.name}}
        .vc-sort-select.d-flex.justify-content-between.align-items-center
          div.text-nowrap.font-weight-bold.mr-3
            | 並び替え
          select(
            class="form-control vc-sub-select"
            v-model="selectedSort"
          )
            option(
              v-for="(ss, index) in selectSort"
              v-bind:value="index"
              v-bind:key="index"
            )
              | {{ss.name}}
        .text-right.w-100.pt-3.pr-3.small
          | ※ 割合の算出方法：該当チームのある意見分類に関する{{emotionClassificationNames[selectEmotionClassificationId]}}意見数
          | ／
          | 該当チームの全体意見数
      .container
        .row
          .col-12.mt-3
            .respondent-num
              a#popup-1(href="javascript:void(0)" @click="modalOpinionCountsOpen(1)") 各チームの意見数と回答者数を確認する
        h3.vc-midashi.pb-0.mb-0
          | 意見傾向の比較
        p.small
          | 他のチームと比較して、自分のチームはどのような意見が多いのかをみる
        .row.no-gutters.mb-5
          .col-12
            vertical-bar-chart#vertical-chart1(:chart-data="datacollection2" :options="options2")
          .col-12
            .row.no-gutters.vc-respondent-table(style="min-height: 32px;")
              .col-5
              .col-7
                .row.no-gutters
                  .table-title(:class="gridClass('count', Object.keys(destinations).length)")
                    | {{source_organization_name}}
                  .table-title(
                    :class="gridClass('count', Object.keys(destinations).length)"
                    v-for="destination in destinations"
                  )
                    | {{destination.organization_name}}
            .row.no-gutters.vc-respondent-table(v-for="opinion_tendency in opinion_tendencies")
              .col-5.text-left.overflow-hidden.text-nowrap
                | {{opinion_tendency.source.name}}
              .col-7
                .row.no-gutters.vc-respondent-table-num
                  .compare-table(:class="gridClass('count', Object.keys(destinations).length)")
                    .dropdown
                      a.dropdown-toggle.no-style(
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        )
                        | {{opinion_tendency.source[selectOpinionRatioName]}} %
                      .dropdown-menu(
                        aria-labelledby="dropdownMenuButton"
                        )
                        a(
                          class="dropdown-item"
                          @click.prevent="toOpinionSearch(opinion_tendency.source.opinion_classification_id, 0)"
                          )
                          | 意見検索
                        a(
                          class="dropdown-item"
                          @click.prevent="modalOpen(source_organization_id,opinion_tendency.source.opinion_classification_id)"
                          )
                          | 内訳グラフ
                  .compare-table(
                    :class="gridClass('count', Object.keys(destinations).length)"
                    v-for="(destination, i) in opinion_tendency.destinations"
                  )
                    .dropdown
                      a.dropdown-toggle.no-style(
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        )
                        | {{destination[selectOpinionRatioName] | separate}} %
                      .dropdown-menu(
                        aria-labelledby="dropdownMenuButton"
                        )
                        a(
                          class="dropdown-item"
                          @click.prevent="toOpinionSearch(destination.opinion_classification_id, 1)"
                          )
                          | 意見検索
                        a(
                          class="dropdown-item"
                          @click.prevent="modalOpen(destinations[i].organization_id,destination.opinion_classification_id)"
                          )
                          | 内訳グラフ
            .row.vc-export-btn-wrap
              .offset-9.col-3.mt-3
                .text-right
                  .dropdown
                    a.dropdown-toggle.btn.btn-primary.vc-primary-btn.btn-block.vc-export-btn(
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      )
                      span エクスポート
                      i.fas.fa-caret-down
                    .dropdown-menu(
                      aria-labelledby="dropdownMenuButton"
                      )
                      a(
                        class="dropdown-item"
                        @click.prevent="copyImage('vertical-chart1')"
                        )
                        | グラフをコピー
                      a(
                        class="dropdown-item"
                        @click.prevent="downloadImage('vertical-chart1', '意見傾向の比較')"
                        )
                        | グラフを画像保存
                      a#download-img-link
                      a(
                        class="dropdown-item"
                        @click.prevent="downloadCSVByTeams"
                        )
                        | 表をCSV出力
        .row
          .col-12.mt-3
            .respondent-num
              a#popup-2(href="javascript:void(0)" @click="modalOpinionCountsOpen(2)") 各チームの意見数と回答者数を確認する
        h3.vc-midashi.mb-0.pb-0
          | 意見傾向の比較（差分）
        p.small
          | 他のチームと比較して、差分が大きい意見が何かをみる
        .row.no-gutters.mb-5
          .col-12
            vertical-bar-chart#verticalbar-chart2(:chart-data="datacollection" :options="options")
          .col-12
            .row.no-gutters.vc-respondent-table(style="min-height: 32px;")
              .col-4
              .col-2
                .row.no-gutters
                  .col-9.table-title
                    | {{source_organization_name}}
              .col-6
                .row.no-gutters
                  .difference-table(
                    :class="gridClass('ratio', Object.keys(destinations).length)"
                    v-for="destination in destinations"
                  )
                    .row
                      .col-6.table-title
                        | {{destination.organization_name}}
                      .col-6.table-title
                        | 差分
            .row.no-gutters.vc-respondent-table(v-for="opinion_tendency in opinion_tendencies")
              .col-4.text-left.overflow-hidden.text-nowrap
                | {{opinion_tendency.source.name}}
              .col-2
                .row.no-gutters.vc-respondent-table-num
                  .col-9
                    .dropdown
                      a.dropdown-toggle.no-style(
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        )
                        | {{opinion_tendency.source[selectOpinionRatioName] | separate}} %
                      .dropdown-menu(
                        aria-labelledby="dropdownMenuButton"
                        )
                        a(
                          class="dropdown-item"
                          @click.prevent="toOpinionSearch(opinion_tendency.source.opinion_classification_id, 0)"
                          )
                          | 意見検索
                        a(
                          class="dropdown-item"
                          @click.prevent="modalOpen(source_organization_id,opinion_tendency.source.opinion_classification_id)"
                          )
                          | 内訳グラフ
              .col-6
                .row.no-gutters.vc-respondent-table-num
                  .difference-table(
                    :class="gridClass('ratio', Object.keys(destinations).length)"
                    v-for="(destination, i) in opinion_tendency.destinations"
                  )
                    .row
                      .col-6
                        .dropdown
                          a.dropdown-toggle.no-style(
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            )
                            | {{destination[selectOpinionRatioName] | separate}} %
                          .dropdown-menu(
                            aria-labelledby="dropdownMenuButton"
                            )
                            a(
                              class="dropdown-item"
                              @click.prevent="toOpinionSearch(destination.opinion_classification_id, 1)"
                              )
                              | 意見検索
                            a(
                              class="dropdown-item"
                              @click.prevent="modalOpen(destinations[i].organization_id,destination.opinion_classification_id)"
                              )
                              | 内訳グラフ
                      .col-6
                        | {{opinion_tendency.difference[i][0][selectOpinionRatioName] | separate}} %
            .row.vc-export-btn-wrap
              .offset-9.col-3.mt-3
                .text-right
                  .dropdown
                    a.dropdown-toggle.btn.btn-primary.vc-primary-btn.btn-block.vc-export-btn(
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      )
                      span エクスポート
                      i.fas.fa-caret-down
                    .dropdown-menu(
                      aria-labelledby="dropdownMenuButton"
                      )
                      a(
                        class="dropdown-item"
                        @click.prevent="copyImage('verticalbar-chart2')"
                        )
                        | グラフをコピー
                      a(
                        class="dropdown-item"
                        @click.prevent="downloadImage('verticalbar-chart2', '意見傾向の比較（差分）')"
                        )
                        | グラフを画像保存
                      a#download-img-link
                      a(
                        class="dropdown-item"
                        @click.prevent="downloadCSVBetweenTeams"
                        )
                        | 表をCSV出力
    //- div(v-else)
      .container
        .row
          div(v-if="source_organization_id != ''")
            | 表示できるデータがありません
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import VerticalBarChart from '../chartJs/VerticalBarChart';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();
import moment from 'moment';
import CDL from '../../../lib/csv_download';
const CSVDownload = new CDL();

export default {
  components: {
    VerticalBarChart
  },

  props: {
    surveyId: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data() {
    return {
      btnVpnClass: { 1: 'vpn-nega', 2: 'vpn-posi', 3: 'vpn-neu' },
      graphBarColors: {
        0: ['#00c3b1', '#008275', '#00423c', '#00a897'],
        1: ['#e85e5e', '#a84545', '#692b2b', '#cf5555'],
        2: ['#22bed3', '#188594', '#0d4c54', '#1ea8ba'],
        3: ['#858585', '#454545', '#6b6b6b', '#d1d1d1']
      },
      datacollection: {},
      options: {},
      datacollection2: {},
      options2: {},
      selectEmotionClassificationId: '1',
      selectEmotionClassificationCode: '1',
      selectSurveyResponsenCountName: 'qualitative_response_count',
      selectOpinionCountName: 'opinion_count',
      selectOpinionRatioName: 'opinion_ratio',
      emotionClassificationNames: {},
      source_survey_response_count: 0,
      destination_survey_response_count: 0,
      source_organization_name: '',
      destinations_organization_name: {},
      source_organization_id: '',
      destinations_organization_id: '',
      opinion_tendencies: {},
      selectSort: [
        { name: '全体 降順', order: 'desc', target: 'opinion_ratio' },
        { name: '全体 昇順', order: 'asc', target: 'opinion_ratio' }
      ],
      selectedSort: 2,
      settingSort: 'opinion_ratio'
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('resources/u_analyse_compare', [
      'emotion_classifications',
      'source',
      'destinations'
    ]),
    ...mapState('resources/u_opinion_search', ['keepedEmotionValues'])
  },

  watch: {
    source: function() {
      let opinion_tendencies = {};
      let opinion_tendencies_order = [];
      const emotion_classifications = this.emotion_classifications;

      let emotionClassificationNames = {};

      let count_total = {
        source: { opinion_count: 0 },
        destinations: { opinion_count: 0 }
      };

      var selectSort = [
        { name: '全体 降順', order: 'desc', target: 'opinion_ratio' },
        { name: '全体 昇順', order: 'asc', target: 'opinion_ratio' }
      ];

      emotionClassificationNames[0] = '全体';

      for (let ec of emotion_classifications) {
        count_total['source']['emotion_opinion_count' + ec.id] = 0;
        for (let i in this['destinations']) {
          count_total['destinations'][i] = {};
          count_total['destinations'][i]['opinion_count'] = 0;
          count_total['destinations'][i]['emotion_opinion_count' + ec.id] = 0;
        }
        selectSort.push({
          name: ec.name + ' 降順',
          order: 'desc',
          target: 'emotion_opinion_ratio' + ec.id
        });
        selectSort.push({
          name: ec.name + ' 昇順',
          order: 'asc',
          target: 'emotion_opinion_ratio' + ec.id
        });
        emotionClassificationNames[ec.id] = ec.name;
      }
      this.selectSort = selectSort;
      this.emotionClassificationNames = emotionClassificationNames;

      for (let key of ['source', 'destinations']) {
        if (key === 'source') {
          for (let opinion_tendency of this[key].opinion_tendencies) {
            if (!opinion_tendencies[opinion_tendency.code])
              opinion_tendencies[opinion_tendency.code] = {};
            opinion_tendencies[opinion_tendency.code][key] = opinion_tendency;
            count_total[key]['opinion_count'] += opinion_tendency.opinion_count;
            for (let ec of emotion_classifications) {
              count_total[key]['emotion_opinion_count' + ec.id] +=
                opinion_tendency['emotion_opinion_count' + ec.id];
            }
          }
          this[key + '_survey_response_count'] = this[
            key
          ].total.survey_response_count;
          this[key + '_organization_name'] = this[key].organization_name;
          this[key + '_organization_id'] = this[key].organization_id;
        } else {
          for (let i in this[key]) {
            for (let opinion_tendency of this[key][i].opinion_tendencies) {
              if (!opinion_tendencies[opinion_tendency.code])
                opinion_tendencies[opinion_tendency.code] = {};
              if (!opinion_tendencies[opinion_tendency.code][key])
                opinion_tendencies[opinion_tendency.code][key] = {};
              if (!count_total[key]) count_total[key] = {};
              opinion_tendencies[opinion_tendency.code][key][
                Number(i)
              ] = opinion_tendency;
              count_total[key][i]['opinion_count'] +=
                opinion_tendency.opinion_count;
              for (let ec of emotion_classifications) {
                count_total[key][i]['emotion_opinion_count' + ec.id] +=
                  opinion_tendency['emotion_opinion_count' + ec.id];
              }
            }
            this[key + '_survey_response_count'] = this[key][
              i
            ].total.survey_response_count;
            this[key + '_organization_name'][i] = this[key][
              i
            ].organization_name;
            this[key + '_organization_id'] = this[key][i].organization_id;
          }
        }
      }
      for (let key in opinion_tendencies) {
        for (let key2 of ['source', 'destinations']) {
          if (key2 === 'source') {
            let ratio = {
              opinion_ratio: Math.round(
                (opinion_tendencies[key][key2]['opinion_count'] /
                  count_total[key2]['opinion_count']) *
                  100
              )
            };
            for (let ec of emotion_classifications) {
              ratio['emotion_opinion_ratio' + ec.id] = Math.round(
                (opinion_tendencies[key][key2][
                  'emotion_opinion_count' + ec.id
                ] /
                  count_total[key2]['opinion_count']) *
                  100
              );
            }
            opinion_tendencies[key][key2] = Object.assign(
              opinion_tendencies[key][key2],
              ratio
            );
          } else {
            for (let i in opinion_tendencies[key][key2]) {
              let ratio = {
                opinion_ratio: Math.round(
                  (opinion_tendencies[key][key2][i]['opinion_count'] /
                    count_total[key2][i]['opinion_count']) *
                    100
                )
              };
              for (let ec of emotion_classifications) {
                ratio['emotion_opinion_ratio' + ec.id] = Math.round(
                  (opinion_tendencies[key][key2][i][
                    'emotion_opinion_count' + ec.id
                  ] /
                    count_total[key2][i]['opinion_count']) *
                    100
                );
              }
              opinion_tendencies[key][key2][i] = Object.assign(
                opinion_tendencies[key][key2][i],
                ratio
              );
            }
          }
        }
        let difference = {};
        for (let i in opinion_tendencies[key]['destinations']) {
          difference[i] = [];
          difference[i].push({
            opinion_ratio:
              opinion_tendencies[key]['source']['opinion_ratio'] -
              opinion_tendencies[key]['destinations'][i]['opinion_ratio']
          });
          for (let ec of emotion_classifications) {
            difference[i][0]['emotion_opinion_ratio' + ec.id] =
              opinion_tendencies[key]['source'][
                'emotion_opinion_ratio' + ec.id
              ] -
              opinion_tendencies[key]['destinations'][i][
                'emotion_opinion_ratio' + ec.id
              ];
          }
          opinion_tendencies[key]['difference'] = difference;
        }

        opinion_tendencies_order.push(opinion_tendencies[key]);
      }

      this.opinion_tendencies = opinion_tendencies_order;
      this.changeSort();
      this.graphUpdate();
      this.$emit('updateCount', this.source.total.survey_response_count, 5);
      if (this.keepedEmotionValues) {
        this.selectEmotionClassificationId = this.keepedEmotionValues;
        this.changeSelectEmotionClassificationId(this.keepedEmotionValues);
      }
    },
    selectedSort: function() {
      this.changeSort();
      this.graphUpdate();
    },
    emotion_classifications() {
      const negaId = this.emotion_classifications[0].id;
      this.selectEmotionClassificationId = negaId;
    }
  },

  created() {},

  mounted() {},

  methods: {
    ...mapMutations('resources/u_analyse_compare', ['updatePopupPosition']),
    ...mapActions('resources/u_public_activities', ['createPublicActivity']),
    changeSelectEmotionClassificationId: function(
      selectEmotionClassificationId,
      selectEmotionClassificationCode
    ) {
      this.selectEmotionClassificationId = selectEmotionClassificationId;
      if (selectEmotionClassificationCode)
        this.selectEmotionClassificationCode = selectEmotionClassificationCode;
      else this.selectEmotionClassificationCode = 0;

      const selectSurveyResponsenCountName =
        selectEmotionClassificationId != '0'
          ? 'emotion_survey_response_count' + selectEmotionClassificationId
          : 'survey_response_count';
      const selectOpinionCountName =
        selectEmotionClassificationId != '0'
          ? 'emotion_opinion_count' + selectEmotionClassificationId
          : 'opinion_count';
      const selectOpinionRatioName =
        selectEmotionClassificationId != '0'
          ? 'emotion_opinion_ratio' + selectEmotionClassificationId
          : 'opinion_ratio';

      this.selectSurveyResponsenCountName = selectSurveyResponsenCountName;
      this.selectOpinionCountName = selectOpinionCountName;
      this.selectOpinionRatioName = selectOpinionRatioName;
      this.graphUpdate();
    },
    changeSort: function() {
      var selectedSort = this.selectedSort;
      var selectSort = this.selectSort;

      const settingSort = selectSort[selectedSort]['target'];
      if (selectSort[selectedSort].order == 'asc') {
        this.opinion_tendencies.sort(function(a, b) {
          return a['source'][settingSort] > b['source'][settingSort] ? 1 : -1;
        });
      } else {
        this.opinion_tendencies.sort(function(a, b) {
          return a['source'][settingSort] < b['source'][settingSort] ? 1 : -1;
        });
      }
    },
    graphUpdate: function() {
      const labels = [];
      const difference1 = {};
      const difference2 = {};
      const sourceData = [];
      const destinationData = {};
      const opinion_tendencies = this.opinion_tendencies;
      const selectOpinionRatioName = this.selectOpinionRatioName;
      const graphBarColor = this.graphBarColors[
        this.selectEmotionClassificationCode
      ];
      for (let i in opinion_tendencies) {
        for (let ii in opinion_tendencies[i].destinations) {
          destinationData[Number(ii)] = [];
          difference1[Number(ii)] = [];
          difference2[Number(ii)] = [];
        }
      }
      for (let i in opinion_tendencies) {
        labels.push(opinion_tendencies[i].source.name);

        for (let ii in this.destinations) {
          const diff =
            opinion_tendencies[i].difference[ii][0][selectOpinionRatioName];
          difference1[ii].push(diff > 0 ? -diff : 0);
          difference2[ii].push(diff);
        }

        sourceData.push(opinion_tendencies[i].source[selectOpinionRatioName]);
        for (let ii in opinion_tendencies[i].destinations) {
          destinationData[Number(ii)].push(
            opinion_tendencies[i].destinations[Number(ii)][
              selectOpinionRatioName
            ]
          );
        }
      }

      let source_datasets = [
        {
          label: this.source_organization_name + 'の意見の割合',
          type: 'line',
          backgroundColor: graphBarColor[0],
          borderWidth: 1,
          borderColor: graphBarColor[0],
          fill: false,
          lineTension: 0,
          data: sourceData
        }
      ];
      let destination_datasets = [];
      for (let key in destinationData) {
        let i = Number(key) + 1;
        destination_datasets.push({
          label: this.destinations_organization_name[key] + 'の意見の割合',
          type: 'line',
          backgroundColor: graphBarColor[i],
          borderWidth: 1,
          borderColor: graphBarColor[i],
          fill: false,
          lineTension: 0,
          data: destinationData[key]
        });
      }
      const datasets = destination_datasets.concat(source_datasets);
      this.datacollection2 = {
        labels: labels.map(v => v.split('')),
        datasets: datasets
      };

      // 意見傾向の比較
      this.options2 = {
        title: {
          display: false
        },
        tooltips: {
          enabled: true,
          backgroundColor: 'rgba(0,0,0,0.6)',
          callbacks: {
            title: function(tooltipItem) {
              return tooltipItem[0].label.replaceAll(',', '');
            },
            label: function(tooltipItem, data) {
              var label =
                data.datasets[tooltipItem.datasetIndex].label.replace(
                  'の意見の割合',
                  ''
                ) +
                ': ' +
                Math.abs(tooltipItem.yLabel) +
                '%';
              return label;
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: -1,
            bottom: 0
          }
        },
        legend: {
          position: 'top',
          align: 'end',
          labels: {
            generateLabels: function(data) {
              const chartData = data.tooltip._data;
              const chartDataset = chartData.datasets;
              const legendArray = [];
              for (let i = 0; i < chartDataset.length; i++) {
                const dataset = chartDataset[i];
                const legend = {
                  text: dataset.label,
                  fillStyle: dataset.backgroundColor,
                  strokeStyle: dataset.borderColor
                };
                legend.pointStyle = 'line';
                legend.lineWidth = 2;
                legendArray.push(legend);
              }
              let leg = legendArray.pop();
              legendArray.unshift(leg);
              return legendArray;
            },
            boxWidth: 7,
            usePointStyle: true
          },
          onClick: function() {
            return false;
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              barPercentage: 0.3,
              ticks: {
                fontSize: 10
              }
            }
          ],
          yAxes: [
            {
              barPercentage: 0.5,
              ticks: {
                callback: function(value) {
                  return Math.abs(value) + '%';
                },
                fontSize: 10
              }
            }
          ]
        }
      };

      let difference1_datasets = [];
      let difference2_datasets = [];
      for (let key in destinationData) {
        let i = Number(key) + 1;
        difference1_datasets.push({
          label: this.source_organization_name + 'の意見の差分の割合',
          type: 'line',
          backgroundColor: graphBarColor[0],
          borderWidth: 1,
          borderColor: graphBarColor[0],
          fill: false,
          lineTension: 0,
          data: difference1[key]
        });
        difference2_datasets.push({
          label:
            this.destinations_organization_name[key] + 'の意見の差分の割合',
          type: 'line',
          backgroundColor: graphBarColor[i],
          borderWidth: 1,
          borderColor: graphBarColor[i],
          fill: false,
          lineTension: 0,
          data: difference2[key].map(i => (i === 0 ? 0.005 : i))
        });
      }

      // 意見傾向の比較（差分）
      this.datacollection = {
        labels: labels.map(v => v.split('')),
        datasets: difference2_datasets
      };
      this.options = {
        title: {
          display: false
        },
        tooltips: {
          enabled: true,
          backgroundColor: 'rgba(0,0,0,0.6)',
          callbacks: {
            title: function(tooltipItem) {
              return tooltipItem[0].label.replaceAll(',', '');
            },
            label: function(tooltipItem) {
              if (tooltipItem.xLabel == 0) return;
              let num = tooltipItem.yLabel === 0.005 ? 0 : tooltipItem.yLabel;
              var label = '差分: ' + num + '%';
              return label;
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: -1,
            bottom: 0
          }
        },
        legend: {
          position: 'top',
          align: 'end',
          labels: {
            generateLabels: function(data) {
              const chartData = data.tooltip._data;
              const chartDataset = chartData.datasets;
              const legendArray = [];
              for (let i = 0; i < chartDataset.length; i++) {
                const dataset = chartDataset[i];
                const legend = {
                  text: dataset.label,
                  fillStyle: dataset.backgroundColor,
                  strokeStyle: dataset.borderColor,
                  pointStyle: 'line',
                  lineWidth: 2
                };
                legendArray.push(legend);
              }
              return legendArray;
            },
            usePointStyle: true,
            boxWidth: 7
          },
          onClick: function() {
            return false;
          }
        },
        scales: {
          xAxes: [
            {
              barPercentage:
                this.destinations.length < 2
                  ? 0.3
                  : this.destinations.length < 3
                  ? 0.6
                  : 0.9,
              ticks: {
                fontSize: 10
              }
            }
          ],
          yAxes: [
            {
              barPercentage: 0.5,
              ticks: {
                callback: function(value) {
                  return value + '%';
                },
                fontSize: 10
              }
            }
          ]
        }
      };
    },
    modalOpen(organizationId, opinionClassificationId) {
      this.$emit(
        'modalOpen',
        organizationId,
        opinionClassificationId,
        this.selectEmotionClassificationId != '0'
          ? this.selectEmotionClassificationId
          : ''
      );
    },
    toOpinionSearch(opinionClassificationId, organizationType) {
      defaultCookie.set({ organizationType: organizationType });
      this.$emit(
        'toOpinionSearch',
        opinionClassificationId,
        this.selectEmotionClassificationId != '0'
          ? this.selectEmotionClassificationId
          : ''
      );
    },
    downloadCSVByTeams() {
      var head = ['意見分類'];
      head.push(this.source_organization_name);
      for (let i in this.destinations) {
        head.push(this.destinations[i].organization_name);
      }

      const datas = [];
      const opinion_tendencies = this.opinion_tendencies;
      for (const i in opinion_tendencies) {
        let items = [];
        items.push(opinion_tendencies[i].source.name);
        items.push(
          opinion_tendencies[i].source[this.selectOpinionRatioName] + '%'
        );
        for (let ii in opinion_tendencies[i].destinations) {
          items.push(
            opinion_tendencies[i].destinations[ii][
              this.selectOpinionRatioName
            ] + '%'
          );
        }
        datas.push(items);
      }

      CSVDownload.downloadCsv(
        head,
        datas,
        'OpinionClassComparisonByTeams' +
          moment().format('YYYYMMDDHHmmss') +
          '.csv'
      );
      const vcd = defaultCookie.get();
      const params = {
        trackable_type: 'survey',
        trackable_id: vcd['surveyId'],
        key: 'survey.export_compare_teams_csv'
      };
      this.createPublicActivity(params);
    },
    downloadCSVBetweenTeams() {
      var head = ['意見分類'];
      head.push(this.source_organization_name);
      for (let i in this.destinations) {
        head.push(this.destinations[i].organization_name);
        head.push('差分');
      }

      const datas = [];
      const opinion_tendencies = this.opinion_tendencies;
      for (const i in opinion_tendencies) {
        let items = [];
        items.push(opinion_tendencies[i].source.name);
        items.push(
          opinion_tendencies[i].source[this.selectOpinionRatioName] + '%'
        );
        for (let ii in opinion_tendencies[i].destinations) {
          items.push(
            opinion_tendencies[i].destinations[ii][
              this.selectOpinionRatioName
            ] + '%'
          );
          items.push(
            opinion_tendencies[i].difference[ii][0][
              this.selectOpinionRatioName
            ] + '%'
          );
        }
        datas.push(items);
      }

      CSVDownload.downloadCsv(
        head,
        datas,
        'OpinionClassComparisonBetweenTeams' +
          moment().format('YYYYMMDDHHmmss') +
          '.csv'
      );
      const vcd = defaultCookie.get();
      const params = {
        trackable_type: 'survey',
        trackable_id: vcd['surveyId'],
        key: 'survey.export_compare_between_teams_csv'
      };
      this.createPublicActivity(params);
    },

    downloadImage(id, filename) {
      let chartWrap = document.getElementById(id);
      let els = chartWrap.getElementsByTagName('*');
      let canvas = {};
      for (let i = 0; i < els.length; i++) {
        if (els[i].id === 'bar-chart') canvas = els[i];
      }
      if (canvas.msToBlob) {
        let blob = canvas.msToBlob();
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let downloadLink = document.getElementById('download-img-link');
        downloadLink.href = canvas.toDataURL('image/png');
        downloadLink.download = filename;
        downloadLink.click();
      }
      const vcd = defaultCookie.get();
      const params = {
        trackable_type: 'survey',
        trackable_id: vcd['surveyId'],
        key: 'survey.download_compare_image'
      };
      this.createPublicActivity(params);
    },

    copyImage(id) {
      let chartWrap = document.getElementById(id);
      let els = chartWrap.getElementsByTagName('*');
      let canvas = {};
      for (let i = 0; i < els.length; i++) {
        if (els[i].id === 'bar-chart') canvas = els[i];
      }
      let img = document.createElement('img');
      img.src = canvas.toDataURL();

      let div = document.createElement('div');
      div.contentEditable = true;
      div.appendChild(img);
      document.body.appendChild(div);

      this.selectForCopy(div);
      document.execCommand('Copy');
      document.body.removeChild(div);
      const vcd = defaultCookie.get();
      const params = {
        trackable_type: 'survey',
        trackable_id: vcd['surveyId'],
        key: 'survey.copy_compare_image'
      };
      this.createPublicActivity(params);
    },

    selectForCopy(element) {
      var doc = document;
      if (doc.body.createTextRange) {
        let range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        let selection = window.getSelection();
        let range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    },

    modalOpinionCountsOpen(type) {
      let popup;
      if (type === 1) popup = document.getElementById('popup-1');
      else popup = document.getElementById('popup-2');
      let rect = popup.getBoundingClientRect();
      let screenHeight = document.documentElement.clientHeight;
      let ratio = rect.top / screenHeight;
      let position = {
        top:
          ratio > 0.6
            ? 0.9
            : ratio > 0.4
            ? ratio + 0.3
            : ratio > 0.18
            ? ratio + 0.2
            : ratio + 0.15
      };
      this.updatePopupPosition(position);

      this.$emit(
        'modalOpinionCountsOpen',
        this.source,
        this.destinations,
        this.selectOpinionCountName,
        this.selectSurveyResponsenCountName
      );
    },

    gridClass(type, size) {
      if (type === 'count') {
        return size === 1 ? 'col-6' : size === 2 ? 'col-4' : 'col-3';
      } else {
        return size === 1 ? 'col-12' : size === 2 ? 'col-6' : 'col-4';
      }
    }
  }
};
</script>
<style scoped>
.vc-respondent-table .dropdown .dropdown-toggle:after {
  right: 20px;
}
.vc-respondent-table .compare-table.col-3 .dropdown .dropdown-toggle:after {
  right: 30px;
}
.vc-respondent-table .compare-table.col-4 .dropdown .dropdown-toggle:after {
  right: 50px;
}
.vc-respondent-table .compare-table.col-6 .dropdown .dropdown-toggle:after {
  right: 90px;
}
.vc-respondent-table .difference-table.col-4 .dropdown .dropdown-toggle:after {
  right: 0;
}
.vc-respondent-table .difference-table.col-6 .dropdown .dropdown-toggle:after {
  right: 15px;
}
.vc-respondent-table .difference-table.col-12 .dropdown .dropdown-toggle:after {
  right: 70px;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle {
  background: #fff !important;
  border: 1px solid #f3154a !important;
  color: #f3154a;
  font-size: 0.85rem;
  width: 150px;
  float: right;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle span {
  margin: auto;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle:hover {
  background: #fff !important;
  border-radius: 2px;
  border: 1px solid #f3154a;
  padding: 12px;
  color: #f3154a;
  font-weight: 600;
  transition: 0.15s ease;
  white-space: nowrap;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle:after {
  content: none;
}
.vc-export-btn-wrap .dropdown .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem !important;
}
.vc ul.vc-parameter-nav li a.vpn-all:hover {
  background: rgba(243, 21, 74, 0.5) !important;
}
.vc ul.vc-parameter-nav li a.vpn-all.active:hover {
  background: rgba(243, 21, 74, 1) !important;
}
.vc ul.vc-parameter-nav li a.vpn-all.active {
  background: #f3154a !important;
}
</style>
