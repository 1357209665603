import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_chatgpt_sentiment_analysis');

export default {
  namespaced: true,

  state: {
    task: {},
    taskList: [],
    opinions: []
  },

  getters: {},

  actions: {
    fetchTasks({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          commit('setTaskList', response.body.tasks);
        }
        return response;
      });
    },

    fetchTask({ commit }, id) {
      return gateway.get({ action: 'show', id }).then(response => {
        if (response.status === 200) {
          commit('setTask', response.body.task);
        }
        return response;
      });
    },

    requestClassification({}, params) {
      return gateway.post({ action: 'create' }, params).then(response => {
        return response;
      });
    },

    requestImport({}, params) {
      return gateway
        .put({ action: 'import', id: params.id }, params)
        .then(response => {
          return response;
        });
    },

    fetchResult({ commit }, id) {
      return gateway.get({ action: 'result', id }).then(response => {
        if (response.status === 200) {
          commit('setOpinions', response.body.opinions);
        }
        return response;
      });
    }
  },

  mutations: {
    setTask(state, task) {
      state.task = task;
    },

    setTaskList(state, tasks) {
      state.taskList = tasks;
    },

    setOpinions(state, opinions) {
      state.opinions = opinions;
    }
  }
};
