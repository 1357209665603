<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | 選択肢編集
  .row.flex-grow
    .col-6
      .card
        .card-body
          form
            error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
            validation-observer(v-slot="{ invalid }")
              validation-provider(name="code" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 選択肢コード
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="newQuantitativeAnswer.code"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="selectText" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 選択肢テキスト
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="newQuantitativeAnswer.select_text"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="selectNum" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 選択肢番号
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="newQuantitativeAnswer.select_num"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="point" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | ポイント
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="newQuantitativeAnswer.point"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              .mt-1.float-right
                router-link.btn.btn-light.mr-2(:to="{ name: 'x_quantitative_answers' }")
                  | {{ $t('actions.cancel') }}
                button.btn.btn-success(
                  :disabled="processing || invalid"
                  @click.prevent="submit"
                )
                  | {{ $t('actions.update') }}

</template>
<script>
import { mapState, mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';

export default {
  components: {
    ErrorMessage
  },
  data() {
    return {
      newQuantitativeAnswer: {},
      errorMessages: [],
      processing: false
    };
  },
  computed: {
    ...mapState('resources/x_quantitative_answers', ['quantitativeAnswer'])
  },
  watch: {},
  created() {
    this.load();
  },
  mounted() {},
  methods: {
    ...mapActions('resources/x_quantitative_answers', [
      'loadEditQuantitativeAnswer',
      'updateQuantitativeAnswer'
    ]),
    load() {
      const params = this.$route.params;
      this.loadEditQuantitativeAnswer(params.id).then(() => {
        this.newQuantitativeAnswer = {
          id: this.quantitativeAnswer.id,
          code: this.quantitativeAnswer.code,
          select_text: this.quantitativeAnswer.select_text,
          select_num: this.quantitativeAnswer.select_num,
          point: this.quantitativeAnswer.point
        };
      });
    },
    resetErrorMessages() {
      this.errorMessages = [];
    },
    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.updateQuantitativeAnswer(this.newQuantitativeAnswer)
        .then(response => {
          if (response.status === 200) {
            const surveyId = this.$route.params.survey_id;
            const questionId = this.$route.params.question_id;
            const quantitativeQuestionId = this.$route.params
              .quantitative_question_id;
            this.$router.push({
              name: 'x_quantitative_answers',
              params: {
                survey_id: surveyId,
                question_id: questionId,
                id: quantitativeQuestionId
              }
            });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
