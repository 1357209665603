export default {
  namespaced: true,

  modules: {},

  state: {
    surveyId: null,
    isTopic: false
  },

  getters: {},

  mutations: {
    setSurveyId(state, surveyId) {
      state.surveyId = surveyId;
    },
    setIsTopic(state, isTopic) {
      state.isTopic = isTopic;
    }
  }
};
