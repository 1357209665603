import Vue from 'vue';
import VueRouter from 'vue-router';
import users from './users';

Vue.use(VueRouter);

const routes = [...users];

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) return savedPosition;
    else return { x: 0, y: 0 };
  }
});

export default router;
