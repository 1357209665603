<template lang="pug">
  div
    .row
      .col-6
        h3.vc-midashi.mb-3
          | 意見数の推移
      .col-6.text-right
        ul.vc-parameter-nav.mb-4
          li(v-show="displayType !== 1")
            a.vpn-all(
              @click.prevent="changeSelectEmotionClassificationId(0)"
              :class="{ 'active': (selectEmotionClassificationId === 0)}"
              )
              | 全体
          li(v-for="emotion_classification in emotion_classifications")
            a(
              @click.prevent="changeSelectEmotionClassificationId(emotion_classification.code)"
              :class="[{'active': (selectEmotionClassificationId == emotion_classification.code)}, btnVpnClass[emotion_classification.code]]"
              )
              | {{emotion_classification.name}}
    .row
      .col-6.pr-4
        line-chart(:chart-data="datacollection" :options="[0, 1].includes(displayType) ? optionsForPercentage : options")
      .col-6
        .row.no-gutters.vc-respondent-table
          .col-4
          .col-8
            .row.no-gutters
              .col-3(v-for="survey in opinionTrendsForDrawing['surveys']")
                | {{survey.short_name}}
        .row.no-gutters.vc-respondent-table(
          v-for="opinion_tendencies of opinionTrendsForDrawing['opinion_tendencies']"
          )
          .col-4.text-left.overflow-hidden.text-nowrap
            | {{opinion_tendencies[opinion_tendencies.length - 1].attribute_value_name}}
          .col-8
            .row.no-gutters.vc-respondent-table-num
              .col-3(v-for="(opinion_tendency, key) in opinion_tendencies")
                .dropdown
                  a(
                    class="dropdown-toggle no-style"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    )
                    | {{valueForDisplay(opinion_tendency[selectOpinionCountName], key, opinion_tendency.opinion_count) + ([0, 1].includes(displayType) ? '%' : '')}}
                  .dropdown-menu(
                    aria-labelledby="dropdownMenuButton"
                    )
                    a.dropdown-item(
                      @click.prevent="\
                        toOpinionSearch(\
                          opinionTrendsForDrawing['surveys'][key].id,\
                          opinion_tendencies[0].organization_id,\
                          opinion_tendencies[0].opinion_classification_id,\
                          emotionClassificationIds[''+selectEmotionClassificationId],\
                          opinion_tendency.attribute_value_id\
                        )\
                      "
                      )
                      | 意見検索
                    a.dropdown-item(
                      v-show="isOrganizationsPage"
                      @click.prevent="modalOpen(opinionTrendsForDrawing['surveys'][key].id,opinion_tendencies[0].organization_id,emotionClassificationIds[''+selectEmotionClassificationId])"
                      )
                      | 内訳グラフ
        .row.no-gutters.vc-respondent-table(v-show="displayType === 2")
          .col-4.text-left.overflow-hidden.text-nowrap
            | 合計
          .col-8.vc-respondent-table-num
            .row.no-gutters
              .col-3(v-for="survey in opinionTrendsForDrawing['surveys']")
                | {{survey[selectOpinionCountCodeName] | separate}}
        .row.vc-export-btn-wrap
          .offset-9.col-3.mt-3
            .text-right
              .dropdown
                a.dropdown-toggle.btn.btn-primary.vc-primary-btn.btn-block.vc-export-btn(
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  )
                  span エクスポート
                  i.fas.fa-caret-down
                .dropdown-menu(
                  aria-labelledby="dropdownMenuButton"
                  )
                  a(
                    class="dropdown-item"
                    @click.prevent="copyImage"
                    )
                    | グラフをコピー
                  a(
                    class="dropdown-item"
                    @click.prevent="downloadImage"
                    )
                    | グラフを画像保存
                  a#download-img-link
                  a(
                    class="dropdown-item"
                    @click.prevent="downloadCSV"
                    )
                    | 表をCSV出力
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LineChart from '../chartJs/LineChart';
import moment from 'moment';
import CDL from '../../../lib/csv_download';
const CSVDownload = new CDL();
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    LineChart
  },

  props: {
    surveyId: {
      type: Number,
      required: false,
      default: 0
    },
    displayType: {
      type: Number,
      required: true,
      default: 0
    },
    isOrganizationsPage: {
      type: Boolean,
      required: true,
      default: true
    }
  },

  data() {
    return {
      btnVpnClass: { 1: 'vpn-nega', 2: 'vpn-posi', 3: 'vpn-neu' },
      datacollection: {},
      options: {},
      optionsForPercentage: {},
      opinionTrendsForDrawing: {},
      selectEmotionClassificationId: 0,
      selectEmotionClassificationCode: 0,
      selectOpinionCountName: 'opinion_count',
      selectOpinionCountCodeName: 'opinion_count',
      emotionClassificationIds: {}
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_analyse_team', [
      'emotion_classifications',
      'opinion_trends'
    ]),
    ...mapState('resources/u_quantitative', ['responseAttributeId'])
  },

  watch: {
    opinion_trends: function() {
      this.changeGraphHeight();
      this.updateOpinionTrendsForDrawing();
      this.opinionTrendsGraphUpdate();
    },
    displayType: function() {
      this.sortOpinionTendencies();
      if (this.displayType === 1 && this.selectEmotionClassificationId === 0)
        this.changeSelectEmotionClassificationId(
          this.emotion_classifications[0].code
        );
      this.opinionTrendsGraphUpdate();
    },
    emotion_classifications() {
      let emotionClassificationIds = {};
      for (const ec of this.emotion_classifications) {
        emotionClassificationIds[ec.code] = ec.id;
      }
      this.emotionClassificationIds = emotionClassificationIds;

      if (this.displayType === 1) {
        this.changeSelectEmotionClassificationId(
          this.emotion_classifications[0].code
        );
        this.updateOpinionTrendsForDrawing();
      }
    },
    selectEmotionClassificationId() {
      this.sortOpinionTendencies();
    }
  },

  created() {
    this.updateOpinionTrendsForDrawing();
    this.opinionTrendsGraphUpdate();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_public_activities', ['createPublicActivity']),
    updateOpinionTrendsForDrawing: function() {
      const opinionTrendsForDrawing = {};
      const opinion_trends = this.opinion_trends;
      opinionTrendsForDrawing['surveys'] = [];
      opinionTrendsForDrawing['opinion_tendencies'] = [];
      let idx = 0;
      for (const i in opinion_trends) {
        if (opinion_trends[i]['opinion_tendencies'].length > 0) {
          opinionTrendsForDrawing['surveys'].push({
            serial_no: opinion_trends[i].survey_serial_no,
            id: opinion_trends[i].survey_id,
            name: opinion_trends[i].survey_name,
            short_name: opinion_trends[i].survey_short_name,
            opinion_count: 0,
            negative_count: 0,
            positive_count: 0,
            neutral_count: 0
          });
          for (const ii in opinion_trends[i].opinion_tendencies) {
            let tendencies = opinion_trends[i].opinion_tendencies[ii];
            if (
              this.isOrganizationsPage ||
              (!this.isOrganizationsPage &&
                tendencies.response_attribute_id === this.responseAttributeId)
            ) {
              if (!opinionTrendsForDrawing['opinion_tendencies'][ii])
                opinionTrendsForDrawing['opinion_tendencies'][ii] = [];
              opinionTrendsForDrawing['opinion_tendencies'][ii].push(
                tendencies
              );
            }
            opinionTrendsForDrawing['surveys'][
              parseInt(i) + idx
            ].opinion_count +=
              opinion_trends[i].opinion_tendencies[ii].opinion_count;
            opinionTrendsForDrawing['surveys'][
              parseInt(i) + idx
            ].negative_count +=
              opinion_trends[i].opinion_tendencies[ii].negative_count;
            opinionTrendsForDrawing['surveys'][
              parseInt(i) + idx
            ].positive_count +=
              opinion_trends[i].opinion_tendencies[ii].positive_count;
            opinionTrendsForDrawing['surveys'][
              parseInt(i) + idx
            ].neutral_count +=
              opinion_trends[i].opinion_tendencies[ii].neutral_count;
          }
        } else idx -= 1;
      }
      let opinion_tendencies = opinionTrendsForDrawing['opinion_tendencies'][0];
      if (!opinion_tendencies) return;

      const length = opinion_tendencies ? opinion_tendencies.length - 1 : 0;
      opinionTrendsForDrawing['opinion_tendencies'].sort((a, b) => {
        if (!a[length]) return -1;
        else if (
          this.valueForDisplay(
            a[length][this.selectOpinionCountName],
            length,
            a[length].opinion_count
          ) >
          this.valueForDisplay(
            b[length][this.selectOpinionCountName],
            length,
            b[length].opinion_count
          )
        )
          return -1;
        else if (
          this.valueForDisplay(
            a[length][this.selectOpinionCountName],
            length,
            a[length].opinion_count
          ) <
          this.valueForDisplay(
            b[length][this.selectOpinionCountName],
            length,
            b[length].opinion_count
          )
        )
          return 1;
        else if (
          this.valueForDisplay(
            a[length][this.selectOpinionCountName],
            length,
            a[length].opinion_count
          ) ===
          this.valueForDisplay(
            b[length][this.selectOpinionCountName],
            length,
            b[length].opinion_count
          )
        )
          return 0;
      });
      this.opinionTrendsForDrawing = opinionTrendsForDrawing;
    },
    changeSelectEmotionClassificationId: function(
      selectEmotionClassificationId
    ) {
      this.selectEmotionClassificationId = selectEmotionClassificationId;

      if (parseInt(selectEmotionClassificationId) === 0) {
        this.selectOpinionCountName = 'opinion_count';
        this.selectOpinionCountCodeName = 'opinion_count';
      } else if (parseInt(selectEmotionClassificationId) === 1) {
        this.selectOpinionCountName = 'negative_count';
        this.selectOpinionCountCodeName = 'negative_count';
      } else if (parseInt(selectEmotionClassificationId) === 2) {
        this.selectOpinionCountName = 'positive_count';
        this.selectOpinionCountCodeName = 'positive_count';
      } else if (parseInt(selectEmotionClassificationId) === 3) {
        this.selectOpinionCountName = 'neutral_count';
        this.selectOpinionCountCodeName = 'neutral_count';
      }

      this.opinionTrendsGraphUpdate();
    },
    opinionTrendsGraphUpdate: function() {
      const labels = [];
      const surveys = this.opinionTrendsForDrawing['surveys'];
      for (const i in surveys) {
        labels.push(surveys[i]['short_name'] || surveys[i]['name']);
      }

      const opinion_tendencies = this.opinionTrendsForDrawing[
        'opinion_tendencies'
      ];
      const datasets = [];
      const colors = [
        '#A6CEE3',
        '#E4CCF0',
        '#FFC1C1',
        '#FF6F70',
        '#AE81DE',
        '#5DB6F2',
        '#FFFFC2',
        '#BEE6FB',
        '#FFB266',
        '#FFD9A7',
        '#C8F5A0',
        '#EB9362',
        '#93EB62',
        '#6293EB',
        '#C8A0F5'
      ];
      for (const i in opinion_tendencies) {
        const data = [];
        for (const ii in opinion_tendencies[i]) {
          data.push(
            this.valueForDisplay(
              opinion_tendencies[i][ii][this.selectOpinionCountName],
              ii,
              this.displayType === 1
                ? opinion_tendencies[i][ii].opinion_count
                : 0
            )
          );
        }
        datasets.push({
          label: opinion_tendencies[i][0].attribute_value_name,
          backgroundColor: colors[i],
          borderColor: colors[i],
          borderWidth: 2,
          data: data,
          pointRadius: 4,
          pointHoverRadius: 4,
          fill: false
        });
      }
      let newLabels = labels.map(v => {
        let l = v.split('').slice(0, 10);
        return l.length > 9 ? l.concat(['・', '・', '・']) : l;
      });
      this.datacollection = {
        labels: newLabels,
        datasets: datasets
      };
      this.options = {
        title: {
          display: false
        },
        tooltips: {
          enabled: true,
          mode: 'point',
          // yAlign: 'bottom',
          intersect: false,
          backgroundColor: 'rgba(0,0,0,0.6)',
          callbacks: {
            title: function(tooltipItem) {
              return labels[tooltipItem[0].index];
            },
            label: function(tooltipItem) {
              if (tooltipItem.xLabel == 0) return;
              let num = tooltipItem.yLabel;
              return num;
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            usePointStyle: true,
            boxWidth: 5
          },
          onHover: function() {
            document.getElementById('line-chart').style.cursor = 'pointer';
          }
        },
        scales: {
          xAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: true
              }
            }
          ]
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      };
      const scalesForPercentage = {
        scales: {
          xAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: true
              },
              ticks: {
                stepSize: 5
              }
            }
          ]
        }
      };
      this.optionsForPercentage = Object.assign({}, this.options);
      this.optionsForPercentage = Object.assign(
        this.optionsForPercentage,
        scalesForPercentage
      );
    },
    modalOpen(surveyId, opinionClassificationId) {
      this.$emit(
        'modalOpen',
        surveyId,
        opinionClassificationId,
        emotionClassificationId
      );
    },
    toOpinionSearch(
      surveyId,
      organizationId,
      opinionClassificationId,
      emotionClassificationId,
      attributeValueId
    ) {
      this.$emit(
        'toOpinionSearch',
        surveyId,
        opinionClassificationId,
        emotionClassificationId,
        organizationId,
        attributeValueId
      );
    },
    downloadCSV() {
      const surveys = this.opinionTrendsForDrawing['surveys'];
      const opinion_tendencies = this.opinionTrendsForDrawing[
        'opinion_tendencies'
      ];
      var head = ['意見分類'];
      for (const i in surveys) {
        head.push(surveys[i]['short_name'] || surveys[i]['name']);
      }

      const datas = [];
      for (const i in opinion_tendencies) {
        var data = [];
        data.push(opinion_tendencies[i][0].attribute_value_name);
        for (const ii in opinion_tendencies[i]) {
          data.push(
            this.valueForDisplay(
              opinion_tendencies[i][ii][this.selectOpinionCountName],
              ii,
              this.displayType === 1
                ? opinion_tendencies[i][ii].opinion_count
                : 0
            )
          );
        }
        datas.push(data);
      }

      if (this.displayType === 2) {
        var d = ['合計'];
        for (const i in surveys) {
          d.push(surveys[i][this.selectOpinionCountName]);
        }
        datas.push(d);
      }

      CSVDownload.downloadCsv(
        head,
        datas,
        'ChangeOpinions' + moment().format('YYYYMMDDHHmmss') + '.csv'
      );
      const vcd = defaultCookie.get();
      const params = {
        trackable_type: 'survey',
        trackable_id: vcd['surveyId'],
        key: 'survey.export_trends_team_csv'
      };
      this.createPublicActivity(params);
    },

    downloadImage() {
      let canvas = document.getElementById('line-chart');
      if (canvas.width > 959 || canvas.height > 959) {
        let newCanvas = document.createElement('canvas');
        newCanvas.height = 960;
        newCanvas.width = 960;
        let context = newCanvas.getContext('2d');
        context.drawImage(canvas, 0, 0, 960, 960);
        canvas = newCanvas;
      }
      if (canvas.msToBlob) {
        let blob = canvas.msToBlob();
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let downloadLink = document.getElementById('download-img-link');
        downloadLink.href = canvas.toDataURL('image/png');
        downloadLink.download = '意見数の推移.png';
        downloadLink.click();
      }
      const vcd = defaultCookie.get();
      const params = {
        trackable_type: 'survey',
        trackable_id: vcd['surveyId'],
        key: 'survey.download_trends_team_image'
      };
      this.createPublicActivity(params);
    },

    copyImage() {
      let canvas = document.getElementById('line-chart');
      let img = document.createElement('img');
      img.src = canvas.toDataURL();

      let div = document.createElement('div');
      div.contentEditable = true;
      div.appendChild(img);
      document.body.appendChild(div);

      this.selectForCopy(div);
      document.execCommand('Copy');
      document.body.removeChild(div);
      const vcd = defaultCookie.get();
      const params = {
        trackable_type: 'survey',
        trackable_id: vcd['surveyId'],
        key: 'survey.copy_trends_team_image'
      };
      this.createPublicActivity(params);
    },

    selectForCopy(element) {
      var doc = document;
      if (doc.body.createTextRange) {
        let range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        let selection = window.getSelection();
        let range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    },

    valueForDisplay(count, idx, opinionCount = 0) {
      if (
        !this.opinionTrendsForDrawing['surveys'] ||
        !this.opinionTrendsForDrawing['surveys'][idx]
      )
        return 0;

      const tc =
        this.displayType === 0
          ? this.opinionTrendsForDrawing['surveys'][idx]['opinion_count']
          : opinionCount;
      if ([0, 1].includes(this.displayType))
        return tc === 0 ? 0 : ((count / tc) * 100).toFixed(0);
      else return count;
    },

    changeGraphHeight() {
      const orgSize = this.opinion_trends[0].opinion_tendencies.length;
      let canvas = document.getElementById('line-chart');
      const height = (orgSize < 7 ? 17 : 30) * orgSize + 400;
      canvas.parentNode.style.height = height + 'px';
    },

    sortOpinionTendencies() {
      let opinion_tendencies = this.opinionTrendsForDrawing[
        'opinion_tendencies'
      ][0];
      const length = opinion_tendencies ? opinion_tendencies.length - 1 : 0;
      this.opinionTrendsForDrawing['opinion_tendencies'].sort((a, b) => {
        return (
          this.valueForDisplay(
            b[length][this.selectOpinionCountName],
            length,
            b[length].opinion_count
          ) -
          this.valueForDisplay(
            a[length][this.selectOpinionCountName],
            length,
            a[length].opinion_count
          )
        );
      });
    }
  }
};
</script>
<style scoped>
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle {
  background: #fff !important;
  border: 1px solid #f3154a !important;
  color: #f3154a;
  font-size: 0.85rem;
  width: 150px;
  float: right;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle span {
  margin: auto;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle:hover {
  background: #fff !important;
  border-radius: 2px;
  border: 1px solid #f3154a;
  padding: 12px;
  color: #f3154a;
  font-weight: 600;
  transition: 0.15s ease;
  white-space: nowrap;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle:after {
  content: none;
}
.vc-export-btn-wrap .dropdown .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem !important;
}
</style>
