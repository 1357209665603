<template lang="pug">
  b-modal(
    id="inquiry-reopen-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    :title="$t('admin.inquiries.reopenModal.title')"
  )
    b-container
      p
        | アカウントグループ：{{ reopenTarget.account_group_name }}
        br
        | 調査名：{{ reopenTarget.survey_name }}
        br
        | 回答者ID：{{ reopenTarget.responder ? reopenTarget.responder.code : '-' }}
        br
        | 質問者：{{ reopenTarget.user ? reopenTarget.user.email : '-' }}
      p
        | {{ $t('admin.inquiries.reopenModal.confirm') }}
    div(slot="modal-footer")
      button.btn.btn-secondary(@click="close")
        | {{ $t('actions.close') }}
      button.btn.btn-success.ml-2(@click.prevent="reopen")
        | {{ $t('admin.inquiries.reopenModal.reopen') }}
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    reopenTarget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions('resources/x_inquiries', ['reopenInquiry']),

    close() {
      this.$bvModal.hide('inquiry-reopen-modal');
    },

    reopen() {
      this.reopenInquiry(this.reopenTarget.id).then(response => {
        if (response.status === 200) {
          this.$bvModal.hide('inquiry-reopen-modal');
          this.$router.go({ name: 'x_inquiries' });
        }
      });
    }
  }
};
</script>
