<template lang="pug">
  nav.navbar.default-layout.col-lg-12.col-12.p-0.fixed-top.d-flex.flex-row
    .navbar-brand-wrapper.d-flex(v-if="this.$route.path.indexOf('select_account_group')<1")
      router-link.navbar-brand.brand-logo(
        :to="{ name: defaultMenu }"
        )
        img(src="../../assets/images/excheck/vc-logo.png" alt="logo")
      router-link.navbar-brand.brand-logo-mini(
        :to="{ name: defaultMenu }"
        )
        img(src="../../assets/images/excheck/vc-logo.png" alt="logo")
    .navbar-brand-wrapper.d-flex(v-else)
      .navbar-brand.brand-logo
        img(src="../../assets/images/excheck/vc-logo.png" alt="logo")
      .navbar-brand.brand-logo-mini
        img(src="../../assets/images/excheck/vc-logo.png" alt="logo")
    .navbar-menu-wrapper.d-flex.align-items-center(v-if="this.$route.path.indexOf('select_account_group')<1")
      ul.navbar-nav.vc-main-menu
        li.nav-item.d-xl-inline-flex
          router-link.nav-link.vc-midashi(
            :to="{ name: defaultMenu }"
            :class="{'link-active': this.$route.path.indexOf('dashboard')>0 || this.$route.path.indexOf('quantitative')>0 || this.$route.path.indexOf('qualitative')>0}"
          )
            span.icon-clipboard-list.icon-font
            | アンケート分析
        li.nav-item.d-xl-inline-flex
          router-link.nav-link.vc-midashi(
            :to="{ name: 'u_about_data' }"
            :class="{'link-active': this.$route.path.indexOf('about_data')>0}"
          )
            span.icon-clipboard-list.icon-font
            | アンケート一覧
        //- li.nav-item.d-xl-inline-flex
        //-   //- router-link.nav-link.vc-midashi(
        //-   //-   :to="{ name: 'u_dashboard' }"
        //-   //-   :class="{'link-active': this.$route.path.indexOf('newsletters')>0}"
        //-   //- )
        //-   a.nav-link.vc-midashi
        //-     span.icon-mail_24px.icon-font
        //-     | メルマガ
        //- li.nav-item.d-xl-inline-flex
        //-   //- router-link.nav-link.vc-midashi(
        //-   //-   :to="{ name: 'u_dashboard' }"
        //-   //-   :class="{'link-active': this.$route.path.indexOf('chats')>0}"
        //-   //- )
        //-   a.nav-link.vc-midashi
        //-     span.icon-comments.icon-font
        //-     | チャット
        li.nav-item.d-xl-inline-flex
          //- router-link.nav-link.vc-midashi(
          //-   :to="{ name: 'u_dashboard' }"
          //-   :class="{'link-active': this.$route.path.indexOf('help')>0}"
          //- )
          a.nav-link.vc-midashi
            span.icon-question-circle.icon-font
            | ヘルプ
        //- li.nav-item.d-xl-inline-flex
        //-   router-link.nav-link.vc-midashi(
        //-     :to="{ name: 'u_dashboard' }"
        //-     )
        //-     svg.dashboard(xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 20 20" height="20px" viewBox="0 0 20 20" width="20px" fill="#000000")
        //-       rect(fill="none" height="20" width="20")
        //-       path(d="M11,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h6V21z M13,21h6c1.1,0,2-0.9,2-2v-7h-8V21z M21,10V5c0-1.1-0.9-2-2-2h-6v7H21z")
        //-     | 意見分類ダッシュボード
        //- li.nav-item.d-xl-inline-flex
        //-   router-link.nav-link.vc-midashi(
        //-     :to="{ name: 'u_team_analyse' }"
        //-     :class="{'link-active': this.$route.path.indexOf('team')>0 || this.$route.path.indexOf('compare')>0}"
        //-     )
        //-     svg.svg-icon(height="394pt" viewBox="-5 0 394 394.00003" width="394pt" xmlns="http://www.w3.org/2000/svg")
        //-       path(:d="analyseIconPath()")
        //-     | 意見を分析する
        //- li.nav-item.d-xl-inline-flex(v-if="displayTopicSurveyMenu")
        //-   router-link.nav-link.vc-midashi.text-center(
        //-     :to="{ name: 'u_topic' }"
        //-     :class="{'link-active': this.$route.path.indexOf('topic')>0 || this.$route.path.indexOf('text_mining')>0}"
        //-     )
        //-     svg.svg-icon(height="394pt" viewBox="-5 0 394 394.00003" width="394pt" xmlns="http://www.w3.org/2000/svg")
        //-       path(:d="analyseIconPath()")
        //-     | 意見を分析する
        //-     br
        //-     | (トピック調査)
        //- li.nav-item.d-xl-inline-flex
        //-   router-link.nav-link.vc-midashi(
        //-     :to="{ name: 'u_opinion_search' }"
        //-     )
        //-     svg(xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" width="20px" fill="#000000")
        //-       path(d="M0 0h24v24H0z" fill="none")
        //-       path(d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z")
        //-     | 意見を探す
        //- li.nav-item.d-xl-inline-flex(v-if="quantitativeAnalyse")
        //-   .seperate-border
        //-   router-link.nav-link.vc-midashi(
        //-     :to="{ name: 'u_quantitative_analyse' }"
        //-     :class="{'link-active': this.$route.path.indexOf('quantitative')>0}"
        //-     )
        //-     svg.gray(xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" width="20px" fill="#000000")
        //-       path(d="M0 0h24v24H0z" fill="none")
        //-       path(d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z")
        //-     | 定量結果を見る
        //- li.nav-item.d-xl-inline-flex(v-if="displayChatsMenu")
        //-   router-link.nav-link.vc-midashi(
        //-     :to="{ name: 'u_inquiries' }"
        //-     )
        //-     svg.gray(xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" width="20px" fill="#000000")
        //-       path(d="M0 0h24v24H0z" fill="none")
        //-       path(d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z")
        //-     | チャット
        //- li.nav-item.d-xl-inline-flex
        //-   router-link.nav-link.vc-midashi(
        //-     :to="{ name: 'u_about_data' }"
        //-     )
        //-     svg.gray(xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" width="20px" fill="#000000")
        //-       path(d="M0 0h24v24H0z" fill="none")
        //-       path(:d='aboutDataIconPath()')
        //-     | データについて
      ul.navbar-nav
        li.nav-item.dropdown.d-none.d-xl-inline-flex
          a#UserDropdown.nav-link.dropdown-toggle.pl-4.d-flex.align-items-center(href="#" data-toggle="dropdown" aria-expanded="false")
            span.profile-text.font-weight-medium
              | {{ operator.email }}
          .dropdown-menu.dropdown-menu-right.navbar-dropdown(aria-labelledby="UserDropdown")
            router-link.dropdown-item.mt-2(:to="{ name: 'u_mypage' }")
              |  マイページ
            router-link.dropdown-item(:to="{ name: 'u_users' }" v-if="capabilityCode && capabilityCode.includes('01')")
              |  ユーザー管理
            //- router-link.dropdown-item(:to="{ name: 'u_classification_requests' }" v-if="capabilityCode && capabilityCode.includes('01')")
            //-   | 分類修正リクエスト
            a.dropdown-item(@click.prevent.stop="signOut")
              |  ログアウト
        //- li.nav-item.ml-3
        //-   a(href="")
        //-     img(src="../../assets/images/excheck/question-circle.png" width="20")
      button.navbar-toggler.navbar-toggler-right.d-lg-none.align-self-center(type="button" data-toggle="offcanvas")
        span.icon-menu
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},

  props: {
    operator: { type: Object, required: true }
  },

  data() {
    return {
      // displayTopicSurveyMenu: false,
      // displayChatsMenu: false
      defaultMenu: ''
    };
  },

  computed: {
    ...mapState('resources/u_roles', ['capabilityCode']),
    ...mapState('resources/u_control_settings', ['controlSettings'])
  },

  watch: {
    controlSettings: function() {
      // this.setControl();
      this.setDefaultMenu();
    }
  },

  created() {
    if (this.$route.path.indexOf('select_account_group') > 0) return;
    this.loadRoles();
    this.loadControlSettings();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_roles', ['loadRoles']),
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    signOut() {
      fetch(this.operator.sign_out_path, { method: 'DELETE' })
        .then(response => {
          if (response.status === 200) {
            return response.json().then(body => {
              return {
                status: response.status,
                redirect_path: body.redirect_url
              };
            });
          }
        })
        .then(response => {
          location.href = response.redirect_path;
        });
    },

    setControl() {
      const names = ['display_topic_survey_menu', 'display_chats'];
      for (let i = 0; i < names.length; i++) {
        const name = names[i];
        if (name === 'display_topic_survey_menu')
          if (
            this.controlSettings[name] &&
            this.controlSettings[name]['enabled']
          )
            this.displayTopicSurveyMenu = this.controlSettings[name].values;
          else this.displayTopicSurveyMenu = true;
        else {
          if (
            this.controlSettings[name] &&
            this.controlSettings[name]['enabled']
          )
            this.displayChatsMenu = this.controlSettings[name].values;
          else this.displayChatsMenu = true;
        }
      }
    },

    aboutDataIconPath() {
      return (
        'M20 13H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1zM7' +
        ' 19c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM20 3H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55' +
        ' 0 1-.45 1-1V4c0-.55-.45-1-1-1zM7 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z'
      );
    },

    analyseIconPath() {
      return (
        'm367.820312 0h-351.261718c-6.199219-.0117188-11.878906 3.449219-14.710938 8.960938-2.871094 5.585937-2.367187' +
        ' 12.3125 1.300782 17.414062l128.6875 181.285156c.042968.0625.089843.121094.132812.183594 4.675781 6.3125 7.207031' +
        ' 13.960938 7.21875 21.816406v147.800782c-.027344 4.375 1.691406 8.582031 4.773438 11.6875 3.085937 3.101562 7.28125 4.851562' +
        ' 11.65625 4.851562 2.222656-.003906 4.425781-.445312 6.480468-1.300781l72.3125-27.570313c6.476563-1.980468 10.777344-8.09375' +
        ' 10.777344-15.453125v-120.015625c.011719-7.855468 2.542969-15.503906 7.214844-21.816406.042968-.0625.089844-.121094.132812-.183594l128.' +
        '691406-181.289062c3.667969-5.097656 4.171876-11.820313 1.300782-17.40625-2.828125-5.515625-8.511719-8.9765628-14.707032-8.964844zm0 0'
      );
    },

    setDefaultMenu() {
      const cs = this.controlSettings.default_qualitative_menu;
      this.defaultMenu = cs.enabled
        ? 'u_' + cs.values
        : 'u_qualitative_emotion_classifications_team';
    }
  }
};
</script>
<style scoped>
svg {
  fill: #f3154a;
  margin-right: 10px;
}
svg.gray {
  fill: gray;
  margin-right: 10px;
}
.router-link-active svg,
.link-active svg,
.nav-link:hover svg {
  fill: #fff !important;
}
.seperate-border {
  margin: 0 10px;
  border-left: gray 1px solid;
  height: 50%;
}
.svg-icon {
  width: 18px;
  height: 18px;
}
.navbar.default-layout .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
  color: #2e384d;
  font-size: 14px;
  vertical-align: middle;
}

.vc .navbar-nav.vc-main-menu .nav-item a {
  padding: 0 12px;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
