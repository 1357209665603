<template lang="pug">
  b-modal(
    id="delete-report-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    title="ファイル削除"
  )
    b-container
      p
        | {{$t('actions.confirm')}}
      p
        span.text-danger
          | {{ deleteTarget.name }}
    div(slot="modal-footer")
      button.btn.btn-secondary(@click="close")
        | {{$t('actions.close')}}
      button.btn.btn-danger.ml-2(@click.once="destroy")
        | {{$t('actions.delete')}}
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    deleteTarget: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions('resources/u_surveys', ['deleteSurveyReport']),

    close() {
      this.$bvModal.hide('delete-report-modal');
    },

    destroy() {
      const params = {
        id: this.deleteTarget.survey_id,
        report_id: this.deleteTarget.id
      };
      this.deleteSurveyReport(params);
      this.$bvModal.hide('delete-report-modal');
    }
  }
};
</script>
