var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fruid"},[(_vm.capabilityCode && _vm.capabilityCode.includes('01'))?_c('div',{staticClass:"main-panel"},[_c('section',{staticClass:"vc-content"},[_c('div',{staticClass:"vc-main-container container"},[_c('div',{staticClass:"vc-content-col",attrs:{"id":"userList"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('form',[(_vm.errorMessages.length > 0)?_c('error-message',{attrs:{"errors":_vm.errorMessages}}):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',{staticClass:"cc-label-mid"},[_vm._v("メールアドレス")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"email","placeholder":"admin@example.com","maxlength":"64","required":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"userName","rules":"max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',{staticClass:"cc-label-mid"},[_vm._v("ユーザー名")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text","placeholder":"Suzuki Taro","maxlength":"16"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"roleId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("権限")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.roleId),expression:"roleId"}],class:{ 'form-control': true, 'form-control-danger': errors.length },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.roleId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.roles),function(role){return _c('option',{key:role.id,domProps:{"value":role.id}},[_vm._v(_vm._s(role.name))])}),0),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"mt-1 float-right"},[_c('button',{staticClass:"btn btn-primary vc-primary-btn",attrs:{"disabled":_vm.processing || invalid},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v("招待メールを送信")]),_c('router-link',{staticClass:"btn btn-light vc-btn ml-4",attrs:{"to":{ name: 'u_users' }}},[_vm._v("キャンセル")])],1)]}}],null,false,1900571456)})],1)])])])])])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"vc-midashi mb-3"},[_vm._v("ユーザー招待")]),_c('hr',{staticClass:"vc-full-divider"})])])}]

export { render, staticRenderFns }