import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_inquiries');

export default {
  namespaced: true,

  state: {
    inquiries: []
  },

  actions: {
    loadInquiries({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          commit('setInquiries', response.body.inquiries);
        }
        return response;
      });
    },
    requestChatInterview(_, params) {
      return gateway.post({ action: 'create' }, params).then(response => {
        return response;
      });
    }
  },

  mutations: {
    setInquiries(state, inquiries) {
      state.inquiries = inquiries;
    }
  }
};
