var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_vm._m(0),_c('div',{staticClass:"row flex-grow"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',[(_vm.errorMessages.length > 0)?_c('error-message',{attrs:{"errors":_vm.errorMessages}}):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("設問コード")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newQuestion.code),expression:"newQuestion.code"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text"},domProps:{"value":(_vm.newQuestion.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newQuestion, "code", $event.target.value)}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("設問タイトル")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newQuestion.title),expression:"newQuestion.title"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text"},domProps:{"value":(_vm.newQuestion.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newQuestion, "title", $event.target.value)}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"shortTitle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("設問タイトル（短縮版）")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newQuestion.short_title),expression:"newQuestion.short_title"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text"},domProps:{"value":(_vm.newQuestion.short_title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newQuestion, "short_title", $event.target.value)}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"questionText","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("設問文")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newQuestion.question_text),expression:"newQuestion.question_text"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text"},domProps:{"value":(_vm.newQuestion.question_text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newQuestion, "question_text", $event.target.value)}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("設問説明文")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newQuestion.description),expression:"newQuestion.description"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"rows":"10"},domProps:{"value":(_vm.newQuestion.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newQuestion, "description", $event.target.value)}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"questionType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("設問タイプ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.newQuestion.question_type),expression:"newQuestion.question_type"}],class:{ 'form-control': true, 'form-control-danger': errors.length },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.newQuestion, "question_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{key:"company",attrs:{"value":"company"}},[_vm._v("会社への意見の質問")]),_c('option',{key:"etc",attrs:{"value":"etc"}},[_vm._v("その他ＦＡの質問")])]),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"targetCount","rules":"numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v(_vm._s(_vm.$t('admin.surveys.targetCount')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newQuestion.target_count),expression:"newQuestion.target_count"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text","placeholder":"対象者数を入力してください。"},domProps:{"value":(_vm.newQuestion.target_count)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newQuestion, "target_count", $event.target.value)}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"mt-1 float-right"},[_c('router-link',{staticClass:"btn btn-light mr-2",attrs:{"to":{ name: 'x_questions', params: { id: _vm.surveyId } }}},[_vm._v(_vm._s(_vm.$t('actions.cancel')))]),_c('button',{staticClass:"btn btn-success",attrs:{"disabled":_vm.processing || invalid},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v(_vm._s(_vm.$t('actions.update')))])],1)]}}])})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-header d-flex flex-column flex-md-row"},[_c('h4',{staticClass:"mb-0"},[_vm._v("設問編集")])])}]

export { render, staticRenderFns }