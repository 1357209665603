import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_classification_request_items');

export default {
  namespaced: true,

  state: {
    request: {},
    opinionClassifications: [],
    emotionClassifications: []
  },

  getters: {},

  actions: {
    loadRequest({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          let request = response.body.request;
          let classifications = response.body.classifications;
          commit('setRequest', request);
          commit('setClassifications', classifications);
        }
        return response;
      });
    },

    updateClassifications(_, params) {
      return gateway
        .put({ action: 'update', id: params.id }, params)
        .then(response => {
          return response;
        });
    }
  },

  mutations: {
    setRequest(state, request) {
      state.request = request;
    },

    setClassifications(state, classifications) {
      state.opinionClassifications = classifications[0];
      state.emotionClassifications = classifications[1];
    }
  }
};
