<template lang="pug">
  .vc-main-container.container
    .vc-topic-content.mb-4
      .vc-left-col
        QualitativeOrganizations(:surveyId="surveyId")
    .vc-content-col(v-show="started")
      div(v-if="organizationIds.length < 1")
        | チームを1つ以上選択してください
      div(v-else-if="shouldDisplaySurveyResponseWarning")
        | 回答が{{getNumberOfDisplayableData()}}件以下は表示できません。組織を選びなおしてください
      div(v-else)
        .row.mt-2.mb-4
          .col-5
            ul.vc-parameter-nav
              li
                a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': displayType === 1}" @click="setDisplayType(1)") 率（チーム内）
              li
                a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': displayType === 0}" @click="setDisplayType(0)") 率（全体）
              li
                a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': displayType === 2}" @click="setDisplayType(2)") 件数
          .col-7.text-right.mt-3.pr-3
            span.small(v-show="[0, 1].includes(displayType)")
              | ※ 割合の算出方法：{{calculateFormula()}}
        OpinionTendenciesUserTeam(
          @modalOpen="modalOpen"
          @modalOpenNS="modalOpenNS"
          @toOpinionSearch="toOpinionSearch"
          @toAnalyseTeam="toAnalyseTeam"
          :survey-id="surveyId"
          :display-type="displayType"
          :is-organizations-page="true"
        )
        hr.my-team-divider
        OpinionTrendsUserTeam(
          @modalOpen="modalOpen"
          @modalOpenNS="modalOpenNS"
          @toOpinionSearch="toOpinionSearch"
          :survey-id="surveyId"
          :display-type="displayType"
          :is-organizations-page="true"
          :organization-ids="organizationIds"
        )
    ModalOpinionAttrValUserTeamForEc(ref="modalOpinionAttrVal")
    ModalNsOpinionAttrValUserTeamForEc(ref="modalNsOpinionAttrVal")
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import QualitativeOrganizations from '../../../components/u/qualitative/QualitativeOrganizations';
import OpinionTendenciesUserTeam from '../../../components/u/analyse/OpinionTendenciesUserTeam';
import OpinionTrendsUserTeam from '../../../components/u/analyse/OpinionTrendsUserTeam';
import ModalOpinionAttrValUserTeamForEc from '../../../components/u/analyse/ModalOpinionAttrValUserTeamForEc';
import ModalNsOpinionAttrValUserTeamForEc from '../../../components/u/analyse/ModalNsOpinionAttrValUserTeamForEc';
import dc from '../../../lib/default_cookie';

const defaultCookie = new dc();

export default {
  components: {
    QualitativeOrganizations,
    OpinionTendenciesUserTeam,
    OpinionTrendsUserTeam,
    ModalOpinionAttrValUserTeamForEc,
    ModalNsOpinionAttrValUserTeamForEc
  },

  data() {
    return {
      selectOpinionClass: 'opinion_count',
      displayType: null,
      processing: false
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('resources/u_analyse_team', ['survey_response_count']),
    ...mapState('resources/u_opinion_search', ['keepedAttributeValues']),
    ...mapState('pages/u_surveys/select', ['surveyId']),
    ...mapState('resources/u_quantitative', [
      'questionId',
      'organizationIds',
      'orgHierarchy'
    ]),
    ...mapState('pages/u_organizations/select', ['started']),

    shouldDisplaySurveyResponseWarning() {
      return (
        this.survey_response_count <= this.getNumberOfDisplayableData() &&
        this.getNumberOfDisplayableData() > 0 &&
        !this.processing
      );
    }
  },

  watch: {
    organizationIds: 'changeSurveyOrganization',
    displayType: 'updateDisplayTypeCookie',
    surveyId: 'loadQuestionsOnSurveyIdChange',
    questionId: 'changeSurveyOrganization'
  },

  created() {
    this.initializeCookies();
    this.setStarted(false);
    this.loadControlSettings();
    this.setDefaultDisplayType();
    this.setOpinionTendencies([]);
    if (this.questionId) this.changeSurveyOrganization();
  },

  methods: {
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapActions('resources/u_analyse_team', [
      'loadOpinionTendencies',
      'loadOpinionTrends'
    ]),
    ...mapActions('resources/u_quantitative', ['loadQuestions']),
    ...mapMutations('resources/u_analyse_team', ['setOpinionTendencies']),
    ...mapMutations('pages/u_organizations/select', ['setStarted']),

    getNumberOfDisplayableData() {
      const name = 'number_of_displayable_data';
      if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
        return this.controlSettings[name]['values']
          ? this.controlSettings[name]['values']
          : 0;
      }
      return 0;
    },

    modalOpen(surveyId, organizationId, emotionClassificationId) {
      const params = {
        survey_id: surveyId,
        question_id: this.questionId,
        organization_id: organizationId,
        attribute_value_ids: this.attributeValueIds,
        emotion_classification_id: emotionClassificationId
      };
      this.$refs.modalOpinionAttrVal.modalOpen(params);
    },

    modalOpenNS(surveyId, organizationId) {
      const params = {
        survey_id: surveyId,
        question_id: this.questionId,
        organization_id: organizationId,
        attribute_value_ids: this.attributeValueIds
      };
      this.$refs.modalNsOpinionAttrVal.modalOpen(params);
    },

    toOpinionSearch(
      surveyId,
      opinionClassificationId,
      emotionClassificationId,
      organizationId
    ) {
      this.$router.push({
        name: 'u_qualitative_opinion_search',
        params: {
          opinion_classification: opinionClassificationId,
          emotion_classification: emotionClassificationId,
          attribute_values: this.attributeValueIds,
          survey_id: surveyId,
          organization_id: organizationId,
          backToName: 'u_qualitative_emotion_classifications_team'
        }
      });
    },

    changeSurveyOrganization() {
      if (!this.started && this.organizationIds.length < 1) return;
      this.processing = true;
      const vcd = defaultCookie.get();
      const params = {
        survey_id: vcd.surveyId || this.surveyId,
        organization_ids: this.organizationIds,
        organization_hierarchy: this.orgHierarchy,
        question_id: this.questionId,
        search_by: 'organizations'
      };
      this.loadOpinionTendencies(params).then(() => {
        this.setStarted(true);
        this.processing = false;
      });
      this.loadOpinionTrends(params);
    },

    updateDisplayTypeCookie() {
      const vcd = defaultCookie.get();
      if (vcd.analyze_team_displayType !== this.displayType) {
        defaultCookie.set({ displayType: this.displayType });
      }
    },

    loadQuestionsOnSurveyIdChange() {
      if (!this.started) this.loadQuestions({ survey_id: this.surveyId });
    },

    setDisplayType(type) {
      this.displayType = type;
    },

    setDefaultDisplayType() {
      const vcd = defaultCookie.get();
      this.displayType = vcd.displayType || 0;
    },

    toAnalyseTeam(organizationId, sortType) {
      this.$router.push({
        name: 'u_qualitative_analyse_team',
        params: {
          organizationId,
          sortType
        }
      });
    },

    calculateFormula() {
      return this.displayType === 0
        ? '各チームの感情分類ごとの意見数/全チームの総意見数'
        : '各チームにおける「感情分類ごとの意見数/全意見数」';
    },

    initializeCookies() {
      const vcd = defaultCookie.get();
      if (vcd && (!vcd.readyForV3 || vcd.readyForV3 === undefined)) {
        defaultCookie.set({ surveyId: null });
        defaultCookie.set({ questionId: null });
        defaultCookie.set({ organizationId: null });
        defaultCookie.set({ organizationType: null });
        defaultCookie.set({ targetOrganizationId: null });
        defaultCookie.set({ orgHierarchy: null });
        defaultCookie.set({ displayType: null });
        defaultCookie.set({ sortType: null });
        defaultCookie.set({ currentType: null });
        defaultCookie.set({ quantitativeSortType: null });
        defaultCookie.set({ readyForV3: true });
      }
    }
  }
};
</script>
<style scoped>
.vc-parameter-nav .vc-ranking-btn {
  text-align: center;
}
.vc ul.vc-parameter-nav li a.vpn-all:hover {
  background: rgba(243, 21, 74, 0.5) !important;
}
.vc ul.vc-parameter-nav li a.vpn-all.active:hover {
  background: rgba(243, 21, 74, 1) !important;
}
.vc ul.vc-parameter-nav li a.vpn-all.active {
  background: #f3154a !important;
}
</style>
