<template lang="pug">
  .content-wrapper
    .content-header.d-flex.flex-column.flex-md-row
      h4.mb-0
        | メルマガプレビュー
    .row
      .col-md-12.grid-margin.stretch-card.clearfix
        .card
          .card-body(v-html="newsletter.body")
          .mt-5.m-4
            .text-left.float-left
              router-link.btn.btn-outline-secondary.action-btns(
                :to="{ name: 'x_edit_newsletter', params: { id: newsletter.id } }"
              )
                | 戻る
            .text-right
              button.btn.btn-outline-secondary.action-btns.mr-2(
                @click.prevent="submit"
              )
                | 下書き保存
              button.btn.btn-outline-secondary.action-btns.mr-2(@click.prevent="showTestModal(newsletter)")
                  | テスト送信
              button.btn.btn-success.action-btns(@click.prevent="showModal(newsletter)")
                  | 送信する

    SendNewsletterModal(:target="target")
    TestSendNewsletterModal(:target="target")

</template>
<script>
import { mapActions } from 'vuex';
import SendNewsletterModal from 'src/components/x/newsletters/SendNewsletterModal';
import TestSendNewsletterModal from 'src/components/x/newsletters/TestSendNewsletterModal';

export default {
  components: {
    SendNewsletterModal,
    TestSendNewsletterModal
  },
  data() {
    return {
      newsletter: {},
      target: {}
    };
  },
  computed: {},
  created() {
    const newsletterId = this.$route.params.id;
    this.previewNewsletter(newsletterId).then(response => {
      this.newsletter = response.body.newsletter;
    });
  },
  mounted() {},
  methods: {
    ...mapActions('resources/x_newsletters', [
      'previewNewsletter',
      'sendNewsletter'
    ]),
    submit() {
      const id = this.$route.params.id;
      let path = '/api/x/newsletters/' + id;
      const formData = new FormData();
      formData.append('template_id', this.template);
      formData.append('sender_email', this.senderEmail);
      formData.append('sender_name', this.senderName);
      formData.append('recipients', this.recipients);
      formData.append('name', this.name);
      formData.append('body', this.body);
      formData.append('thumbnail', this.thumbnailFile);

      fetch(path, {
        method: 'PUT',
        body: formData
      }).then(response => {
        if (response.status === 200) {
          this.$router.push({ name: 'x_newsletters' });
        }
      });
    },
    showModal(newsletter) {
      this.target = newsletter;
      this.$bvModal.show('send-newsletter-modal');
    },
    showTestModal(newsletter) {
      this.target = newsletter;
      this.$bvModal.show('test-send-newsletter-modal');
    }
  }
};
</script>
<style scoped>
.action-btns {
  width: 120px;
}
</style>
