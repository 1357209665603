import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_account_groups');

export default {
  namespaced: true,

  state: {
    onlyHasTopics: true,
    quantitativeAnalyse: false
  },

  getters: {},

  actions: {
    loadQuantitativeAnalyse({ commit }) {
      return gateway.get({ action: 'quantitative_analyse' }).then(response => {
        if (response.status === 200) {
          const quantitativeAnalyse = response.body.quantitative_analyse;
          commit('quantitativeAnalyse', quantitativeAnalyse);
        }
        return response;
      });
    }
  },

  mutations: {
    setOnlyHasTopics(state, onlyHasTopics) {
      state.onlyHasTopics = onlyHasTopics;
    },
    quantitativeAnalyse(state, quantitativeAnalyse) {
      state.quantitativeAnalyse = quantitativeAnalyse;
    }
  }
};
