import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_dashboard');

export default {
  namespaced: true,

  state: {
    volumeMap: [],
    opinionClassMap: [],
    emotionClassTotalPer: [],
    emotionClassOrgPer: [],
    ranking: [],
    opinionType: [],
    emotionIds: {},
    organizations: [],
    parentIdByIds: {},
    started: false,
    orgLevels: []
  },

  getters: {},

  actions: {
    /**
     * ネガポジ感情マップのデータ取得
     */
    loadVolumeMap({ commit }, params) {
      return gateway.get({ action: 'volume_map' }, params).then(response => {
        if (response.status === 200) {
          commit('setVolumeMap', response.body.volume_map);
        }
        return response;
      });
    },
    /**
     * 意見分類マップのデータ取得
     */
    loadOpinionClassMap({ commit }, params) {
      return gateway
        .get({ action: 'opinion_class_map' }, params)
        .then(response => {
          if (response.status === 200) {
            commit('setOpinionClassMap', response.body.opinion_class_map);
          }
          return response;
        });
    },
    /**
     * ネガポジ構成のデータ取得
     */
    loadEmotionClassPer({ commit }, params) {
      return gateway
        .get({ action: 'emotion_class_per' }, params)
        .then(response => {
          if (response.status === 200) {
            commit('setEmotionClassPer', response.body.emotion_class_per);
          }
        });
    },
    /**
     * ネガポジのランキング
     */
    loadRanking({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          commit('setRanking', response.body.ranking);
          commit('setOrganizations', response.body.organizations);
          commit('setOpinionType', response.body.opinion_type);
          commit('setEmotionIds', response.body.emotions);
          commit('setOrgLevels', response.body.org_levels);
        }
      });
    },
    loadOrganizations({ commit }, params) {
      return gateway.get({ action: 'organizations' }, params).then(response => {
        if (response.status === 200) {
          commit('setOrganizations', response.body.organizations);
        }
      });
    }
  },

  mutations: {
    setVolumeMap(state, volumeMap) {
      state.volumeMap = volumeMap;
    },
    setOpinionClassMap(state, opinionClassMap) {
      state.opinionClassMap = opinionClassMap;
    },
    setEmotionClassPer(state, emotionClassPer) {
      state.emotionClassTotalPer = emotionClassPer.total;
      state.emotionClassOrgPer = emotionClassPer.organizations;
    },
    setRanking(state, ranking) {
      state.ranking = ranking;
    },
    setOpinionType(state, opinionType) {
      const ots = opinionType.filter(type => type.parent_id);
      const arr = ots.map(ot => [ot.id, ot.name]).sort();
      state.opinionType = arr;
    },
    setEmotionIds(state, emotions) {
      const obj = emotions.reduce((result, current, _) => {
        result[current.name] = current.id;
        return result;
      }, {});
      state.emotionIds = obj;
    },
    setOrganizations(state, organizations) {
      const _organizations = {};
      const _parentIdByIds = {};
      for (const i in organizations) {
        _parentIdByIds[organizations[i].id] = organizations[i].parent_id;
        const index = organizations[i].parent_id
          ? organizations[i].parent_id
          : 0;
        if (!_organizations[index]) _organizations[index] = [];
        _organizations[index].push(organizations[i]);
      }
      state.organizations = _organizations;
      state.parentIdByIds = _parentIdByIds;
    },
    setStarted(state, started) {
      state.started = started;
    },
    setOrgLevels(state, levels) {
      state.orgLevels = levels;
    }
  }
};
