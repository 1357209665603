<template lang="pug">
  .content-wrapper
    .row.flex-grow
      .col-6
        .card
          .card-body
            h4.card-title
              | {{ $t('admin.users.edit.title') }}

            form(v-if="ready")
              error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
              validation-observer(v-slot="{ invalid }")
                validation-provider(name="name" rules="required" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.users.name') }}
                    input(
                      type="text"
                      placeholder="Suzuki Taro"
                      :class="{ 'form-control': true, 'form-control-danger': errors.length}"
                      v-model="userName"
                    )
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                validation-provider(name="email" rules="required|email" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.users.email') }}
                    input(
                      type="email"
                      placeholder="admin@example.com"
                      :class="{ 'form-control': true, 'form-control-danger': errors.length}"
                      v-model="userEmail"
                    )
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                .form-group
                  label
                    | {{ $t('admin.users.mainAccountGroup') }}
                  select.form-control(v-model="accountGroupId" @change="changedAccountGroupId")
                    option(
                      v-for="accountGroup in accountGroups"
                      v-bind:value="accountGroup.id"
                      v-bind:key="accountGroup.id"
                    )
                      | {{accountGroup.name}}

                validation-provider(name="roleId" rules="required" v-slot="{ errors }")
                  .form-group(:class="{ 'has-danger': errors.length}")
                    label
                      | {{ $t('admin.users.role') }}
                    select(
                      :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                      v-model="roleId"
                    )
                      option(
                        v-for="role in roles[accountGroupId]"
                        v-bind:value="role.id"
                        v-bind:key="role.id"
                      )
                        | {{role.name}}
                    label.error.mt-2.text-danger(v-show="errors.length")
                      | {{ errors[0] }}

                .form-group
                  label
                    | {{ $t('admin.users.accountGroups') }}
                  .account-groups-wrap
                    .small(v-for="account in accountGroups" @click="addRemoveAccount(account)")
                      | {{account.name}}
                      i.fas.fa-minus-circle.ml-1

                .mt-1.float-right
                  router-link.btn.btn-light.mr-2(:to="{ name: 'x_users' }")
                    | {{ $t('actions.cancel') }}
                  button.btn.btn-success(
                    :disabled="processing || invalid"
                    @click.prevent="submit"
                  )
                    | {{ $t('actions.update') }}

</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';

export default {
  components: {
    ErrorMessage
  },

  data() {
    return {
      ready: false,
      errorMessages: [],
      processing: false,
      accountGroups: [],
      accountGroupId: '',
      removeAccountGroupIds: [],
      roleId: ''
    };
  },

  computed: {
    ...mapState('pages/x_users/user', { user: state => state }),
    ...mapState('resources/x_users', ['roles']),

    userName: {
      get() {
        return this.user.name;
      },
      set(newName) {
        this.setUser({ user: { name: newName } });
      }
    },

    userEmail: {
      get() {
        return this.user.email;
      },
      set(newName) {
        this.setUser({ user: { email: newName } });
      }
    },

    /**
     * サーバサイドに渡す user のパラメータを生成
     *
     * @return {object}
     */
    userParams() {
      return {
        id: this.user.id,
        user: {
          name: this.user.name,
          email: this.user.email,
          account_group_id: this.accountGroupId,
          remove_ids: this.removeAccountGroupIds,
          role_id: this.roleId
        }
      };
    }
  },

  watch: {
    accountGroupId: function() {
      this.loadRoles({
        id: this.user.id,
        account_group_id: this.accountGroupId
      });
    }
  },

  created() {
    this.loadUser();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_users', ['editUser', 'updateUser', 'loadRoles']),
    ...mapMutations('pages/x_users/user', ['setUser']),

    loadUser() {
      this.editUser(this.$route.params.id).then(response => {
        if (response.status === 200) {
          this.setUser({
            user: response.body.user
          });
          this.accountGroups = response.body.account_groups;
          this.accountGroupId = this.user.accountGroupId;
          this.roleId = this.user.roleId;
          this.ready = true;
        }
      });
    },

    resetErrorMessages() {
      this.errorMessages = [];
    },

    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.updateUser(this.userParams)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'x_users' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },

    addRemoveAccount(account) {
      this.removeAccountGroupIds.push(account.id);
      let accounts = this.accountGroups;
      const idx = accounts.indexOf(account);
      delete accounts[idx];
      this.accountGroups = accounts.filter(el => {
        return el;
      });
    },

    changedAccountGroupId() {
      this.roleId = null;
    }
  }
};
</script>
<style scoped>
.account-groups-wrap {
  padding: 20px;
  border: 1px solid lightgrey;
}
</style>
