<template lang="pug">
  div
    .row
      .col-6
        h3.vc-midashi.mb-3
          | {{isOrganizationsPage ? 'チーム' : '属性別'}}の感情分析結果
      .col-6
        dl.respondent-num
          dt
            | 分析対象意見数（回答者数）
          dd
            | {{total_opinion_count | separate}} ({{survey_response_count | separate}})
    .row.mb-3
      .col-6
        .row.no-gutters.vc-respendent-table
          .col-12
            .vc-sub-select-box
              button.btn.btn-secondary(@click.prevent="setDisplayFullWidthGraphForEc(!displayFullWidthGraphForEc)" v-show="!displayFullWidthGraphForEc")
                | グラフのみ表示する
              button.btn.btn-secondary(@click.prevent="setDisplayFullWidthGraphForEc(!displayFullWidthGraphForEc)" v-show="displayFullWidthGraphForEc")
                | 表を一緒に表示する
      .col-6
        .row.no-gutters.vc-respendent-table.text-right
          .offset-8.col-4
            .vc-sub-select-box
              select(
                class="form-control vc-sub-select"
                v-model="sortType"
              )
                option(
                  v-for="(name, type) in sortTypes"
                  :value="type"
                  :key="type"
                )
                  | {{name}}
    .row.no-gutters.mb-5(v-if="started")
      .pr-4#bar-chart-wrap(:class="displayFullWidthGraphForEc ? 'col-12' : 'col-6'")
        horizontal-bar-chart(:chart-data="datacollection" :options="options" ref="horizontalBarChart" v-show="tendencies.length > 1")
        pie-chart(:chart-data="pieChartData" :options="pieChartOptions" :styles="pieChartStyle" :height="200" v-show="tendencies.length === 1")
        //- .text-right
        //-   a.graph-mode(
        //-     @click.prevent="changeGraphMode()"
        //-     :class="{active: graphPercentage}"
        //-   )
        //-    | 割合表示
      .col-6.vc-my-team(v-show="!displayFullWidthGraphForEc")
        .row.no-gutters.vc-respondent-table
          .col-4
          .col-8
            .row.no-gutters
              .col-3
                | 意見数
              .col-2.vc-negative-color
                | ネガ
              .col-2.vc-neutral-color
                | 中立
              .col-2.vc-positive-color
                | ポジ
              .col-3.vc-neutral-color
                | 回答者数
        .row.no-gutters.vc-respondent-table(v-for="opinion_tendency in tendencies")
          .col-4.text-left.overflow-hidden.text-nowrap
            | {{opinion_tendency.organization_name || opinion_tendency.attribute_value_name}}
          .col-8
            .row.no-gutters.vc-respondent-table-num
              .col-3
                .dropdown
                  a.dropdown-toggle.no-style(
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  )
                    | {{opinion_tendency.opinion_count}}
                  .dropdown-menu(
                    aria-labelledby="dropdownMenuButton"
                    )
                    a(
                      class="dropdown-item"
                      @click.prevent="toOpinionSearch(opinion_tendency.opinion_classification_id,'',opinion_tendency.organization_id,opinion_tendency.attribute_value_id)"
                      )
                      | 意見検索
                    a(
                      v-show="isOrganizationsPage"
                      class="dropdown-item"
                      @click.prevent="modalOpenNS(opinion_tendency.organization_id)"
                      )
                      | 内訳グラフ
                    a(
                      class="dropdown-item"
                      @click.prevent="toAnalyseTeam(opinion_tendency.organization_id, null)"
                      v-show="isOrganizationsPage"
                    )
                      | チーム分析
              .col-2.vc-negative-color
                .dropdown
                  a.dropdown-toggle.no-style(
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  )
                    | {{opinionDataForDisplay(opinion_tendency.negative_count, opinion_tendency.opinion_count) + ([0, 1].includes(displayType) ? '%' : '')}}
                  .dropdown-menu(
                    aria-labelledby="dropdownMenuButton"
                    )
                    a(
                      class="dropdown-item"
                      @click.prevent="toOpinionSearch(opinion_tendency.opinion_classification_id,emotionClassificationIds['1'],opinion_tendency.organization_id,opinion_tendency.attribute_value_id)"
                      )
                      | 意見検索
                    a(
                      v-show="isOrganizationsPage"
                      class="dropdown-item"
                      @click.prevent="modalOpen(opinion_tendency.organization_id,emotionClassificationIds['1'])"
                      )
                      | 内訳グラフ
                    a(
                      class="dropdown-item"
                      @click.prevent="toAnalyseTeam(opinion_tendency.organization_id, 'negative')"
                      v-show="isOrganizationsPage"
                    )
                      | チーム分析
              .col-2.vc-neutral-color
                .dropdown
                  a.dropdown-toggle.no-style(
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  )
                    | {{opinionDataForDisplay(opinion_tendency.neutral_count, opinion_tendency.opinion_count) + ([0, 1].includes(displayType) ? '%' : '')}}
                  .dropdown-menu(
                    aria-labelledby="dropdownMenuButton"
                    )
                    a(
                      class="dropdown-item"
                      @click.prevent="toOpinionSearch(opinion_tendency.opinion_classification_id,emotionClassificationIds['3'],opinion_tendency.organization_id,opinion_tendency.attribute_value_id)"
                      )
                      | 意見検索
                    a(
                      v-show="isOrganizationsPage"
                      class="dropdown-item"
                      @click.prevent="modalOpen(opinion_tendency.organization_id,emotionClassificationIds['3'])"
                      )
                      | 内訳グラフ
                    a(
                      class="dropdown-item"
                      @click.prevent="toAnalyseTeam(opinion_tendency.organization_id, 'neutral')"
                      v-show="isOrganizationsPage"
                    )
                      | チーム分析
              .col-2.vc-positive-color
                .dropdown
                  a.dropdown-toggle.no-style(
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  )
                    | {{opinionDataForDisplay(opinion_tendency.positive_count, opinion_tendency.opinion_count) + ([0, 1].includes(displayType) ? '%' : '')}}
                  .dropdown-menu(
                    aria-labelledby="dropdownMenuButton"
                    )
                    a(
                      class="dropdown-item"
                      @click.prevent="toOpinionSearch(opinion_tendency.opinion_classification_id,emotionClassificationIds['2'],opinion_tendency.organization_id,opinion_tendency.attribute_value_id)"
                      )
                      | 意見検索
                    a(
                      v-show="isOrganizationsPage"
                      class="dropdown-item"
                      @click.prevent="modalOpen(opinion_tendency.organization_id,emotionClassificationIds['2'])"
                      )
                      | 内訳グラフ
                    a(
                      class="dropdown-item"
                      @click.prevent="toAnalyseTeam(opinion_tendency.organization_id, 'positive')"
                      v-show="isOrganizationsPage"
                    )
                      | チーム分析
              .col-3.vc-neutral-color
                | {{opinion_tendency.respondents_count}}
        .row.vc-export-btn-wrap
          .offset-9.col-3.mt-3
            .text-right
              .dropdown
                a.dropdown-toggle.btn.btn-primary.vc-primary-btn.btn-block.vc-export-btn(
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  )
                  span エクスポート
                  i.fas.fa-caret-down
                .dropdown-menu(
                  aria-labelledby="dropdownMenuButton"
                  )
                  a(
                    class="dropdown-item"
                    @click.prevent="copyImage"
                    )
                    | グラフをコピー
                  a(
                    class="dropdown-item"
                    @click.prevent="downloadImage"
                    )
                    | グラフを画像保存
                  a#download-img-link
                  a(
                    class="dropdown-item"
                    @click.prevent="downloadCSV"
                    )
                    | 表をCSV出力
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import HorizontalBarChart from '../chartJs/HorizontalBarChart';
import PieChart from '../../../components/u/chartJs/PieChart';
import moment from 'moment';
import CDL from '../../../lib/csv_download';
const CSVDownload = new CDL();
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    HorizontalBarChart,
    PieChart
  },

  props: {
    surveyId: {
      type: Number,
      required: false,
      default: 0
    },
    displayType: {
      type: Number,
      required: true,
      default: 0
    },
    isOrganizationsPage: {
      type: Boolean,
      required: true,
      default: true
    }
  },

  data() {
    return {
      datacollection: {},
      options: {},
      emotionClassificationIds: {},
      graphPercentage: true,
      tendencies: [],
      sortTypes: {
        negative: 'ネガティブ降順',
        positive: 'ポジティブ降順',
        neutral: '中立降順'
      },
      sortType: 'negative',
      pieChartData: {},
      pieChartOptions: {
        responsive: true,
        tooltips: {
          enabled: true,
          xAlign: 'center',
          yAlign: 'bottom',
          backgroundColor: 'rgba(0,0,0,0.6)',
          intersect: false
        },
        layout: {
          padding: {
            left: 12,
            right: 12,
            top: 0,
            bottom: 0
          }
        },
        maintainAspectRatio: false
      },
      pieChartStyle: { height: '250px', position: 'relative' }
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_analyse_team', [
      'survey_response_count',
      'total_opinion_count',
      'emotion_classifications',
      'opinion_tendencies',
      'displayFullWidthGraphForEc'
    ]),
    ...mapState('resources/u_quantitative', ['responseAttributeId']),
    ...mapState('pages/u_organizations/select', ['started'])
  },

  watch: {
    opinion_tendencies() {
      this.opinionTendenciesGraphUpdate();
    },
    emotion_classifications() {
      let emotionClassificationIds = {};
      for (const ec of this.emotion_classifications) {
        emotionClassificationIds[ec.code] = ec.id;
      }
      this.emotionClassificationIds = emotionClassificationIds;
    },
    displayType() {
      if (this.started) this.opinionTendenciesGraphUpdate();
    },
    sortType() {
      if (this.started) this.opinionTendenciesGraphUpdate();
    }
  },

  created() {
    const obj = this.isOrganizationsPage
      ? { organizations: 'チーム順' }
      : { attributeValues: '属性順' };
    this.sortTypes = Object.assign(obj, this.sortTypes);
    if (this.opinion_tendencies.length > 0) this.opinionTendenciesGraphUpdate();
    // if (this.isOrganizationsPage || this.responseAttributeId)
    //   this.opinionTendenciesGraphUpdate();
  },

  mounted() {
    this.changeGraphHeight();
  },

  methods: {
    ...mapActions('resources/u_public_activities', ['createPublicActivity']),
    ...mapMutations('resources/u_analyse_team', [
      'setDisplayFullWidthGraphForEc'
    ]),
    opinionTendenciesGraphUpdate() {
      const labels = [];
      const negative_datas = [];
      const positive_datas = [];
      const mixed_datas = [];

      if (!this.isOrganizationsPage) {
        let tendencies = this.opinion_tendencies.filter(
          tend =>
            tend.response_attribute_id === this.responseAttributeId &&
            tend.opinion_count > 0
        );
        this.sortData(tendencies);
      } else {
        let tendencies = this.opinion_tendencies.filter(
          tend => tend.opinion_count > 0
        );
        this.sortData(tendencies);
      }

      if (this.displayType === 0) {
        for (const opinion_tendency of this.tendencies) {
          let name =
            opinion_tendency.organization_name ||
            opinion_tendency.attribute_value_name;
          let label = '';
          if (name.length > 9) label = name.substr(0, 9) + ' ...';
          else label = name;
          labels.push(label);
          const nc_r = Math.round(
            (opinion_tendency.negative_count / this.total_opinion_count) * 100
          );
          const pc_r = Math.round(
            (opinion_tendency.positive_count / this.total_opinion_count) * 100
          );
          const mc_r = Math.round(
            (opinion_tendency.neutral_count / this.total_opinion_count) * 100
          );
          negative_datas.push(nc_r);
          positive_datas.push(pc_r);
          mixed_datas.push(mc_r);
        }
      } else if (this.displayType === 1) {
        for (const opinion_tendency of this.tendencies) {
          labels.push(
            opinion_tendency.organization_name ||
              opinion_tendency.attribute_value_name
          );
          const nc_r =
            Math.floor(
              (
                (opinion_tendency.negative_count /
                  opinion_tendency.opinion_count) *
                100
              ).toFixed(2) * 10
            ) / 10;
          const pc_r =
            Math.floor(
              (
                (opinion_tendency.positive_count /
                  opinion_tendency.opinion_count) *
                100
              ).toFixed(2) * 10
            ) / 10;
          const mc_r =
            Math.floor(
              (
                (opinion_tendency.neutral_count /
                  opinion_tendency.opinion_count) *
                100
              ).toFixed(2) * 10
            ) / 10;
          negative_datas.push(nc_r);
          positive_datas.push(pc_r);
          mixed_datas.push(mc_r);
        }
      } else {
        for (const opinion_tendency of this.tendencies) {
          labels.push(
            opinion_tendency.organization_name ||
              opinion_tendency.attribute_value_name
          );
          negative_datas.push(opinion_tendency.negative_count);
          positive_datas.push(opinion_tendency.positive_count);
          mixed_datas.push(opinion_tendency.neutral_count);
        }
      }

      this.datacollection = {
        labels: labels,
        datasets: [
          {
            label: 'ネガティブ',
            borderColor: '#e85e5e',
            borderWidth: 1,
            backgroundColor: 'rgba(232, 94, 94, 0.8)',
            width: 2,
            data: negative_datas
          },
          {
            label: '中立',
            borderColor: '#858585',
            borderWidth: 1,
            backgroundColor: 'rgba(133, 133, 133, 0.8)',
            data: mixed_datas
          },
          {
            label: 'ポジティブ',
            borderColor: '#22BED3',
            borderWidth: 1,
            backgroundColor: 'rgba(34, 190, 211, 0.8)',
            data: positive_datas
          }
        ]
      };
      this.options = {
        title: {
          display: false
        },
        tooltips: {
          enabled: true,
          mode: 'point',
          xAlign: 'center',
          yAlign: 'bottom',
          backgroundColor: 'rgba(0,0,0,0.6)',
          intersect: false
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        legend: {
          position: 'bottom',
          align: 'start',
          onHover: function() {
            document.getElementById('line-chart').style.cursor = 'pointer';
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              stepSize: 5
            }
          ],
          yAxes: [
            {
              stacked: true,
              barThickness: 11
            }
          ]
        },
        onClick: this.clickEvent
      };

      const pieLabels = ['ネガティブ', 'ポジティブ', '中立'];
      const pieBackgroundColors = [
        'rgba(232, 94, 94, 0.8)',
        'rgba(34, 190, 211, 0.8)',
        'rgba(133, 133, 133, 0.8)'
      ];

      this.pieChartData = {
        labels: pieLabels,
        datasets: [
          {
            backgroundColor: pieBackgroundColors,
            data: [negative_datas, positive_datas, mixed_datas]
          }
        ]
      };
    },
    changeGraphMode() {
      this.graphPercentage = !this.graphPercentage;
      this.opinionTendenciesGraphUpdate();
    },
    modalOpen(organizationId, emotionClassificationId) {
      const vcd = defaultCookie.get();
      this.$emit(
        'modalOpen',
        vcd['surveyId'],
        organizationId,
        emotionClassificationId
      );
    },
    modalOpenNS(organizationId) {
      const vcd = defaultCookie.get();
      this.$emit('modalOpenNS', vcd['surveyId'], organizationId);
    },
    toOpinionSearch(
      opinionClassificationId,
      emotionClassificationId,
      organizationId,
      attributeValueId
    ) {
      const vcd = defaultCookie.get();
      this.$emit(
        'toOpinionSearch',
        vcd['surveyId'],
        opinionClassificationId,
        emotionClassificationId,
        organizationId,
        attributeValueId
      );
    },
    downloadCSV() {
      const head = ['意見分類', '意見数', 'ネガ', '中立', 'ポジ', '回答者数'];
      const datas = [];
      for (const opinion_tendency of this.opinion_tendencies) {
        datas.push([
          opinion_tendency.organization_name ||
            opinion_tendency.attribute_value_name,
          this.opinionDataForDisplay(
            opinion_tendency.opinion_count,
            opinion_tendency.opinion_count
          ),
          this.opinionDataForDisplay(
            opinion_tendency.negative_count,
            opinion_tendency.opinion_count
          ),
          this.opinionDataForDisplay(
            opinion_tendency.neutral_count,
            opinion_tendency.opinion_count
          ),
          this.opinionDataForDisplay(
            opinion_tendency.positive_count,
            opinion_tendency.opinion_count
          ),
          this.respondentsDataForDisplay(opinion_tendency.respondents_count)
        ]);
      }
      CSVDownload.downloadCsv(
        head,
        datas,
        'TeamOpinionClassResult' + moment().format('YYYYMMDDHHmmss') + '.csv'
      );
      const vcd = defaultCookie.get();
      const params = {
        trackable_type: 'survey',
        trackable_id: vcd['surveyId'],
        key: 'survey.export_tendencies_team_csv'
      };
      this.createPublicActivity(params);
    },

    downloadImage() {
      let canvas = document.getElementById('horizontalbar-chart');
      if (canvas.width > 959 || canvas.height > 959) {
        let newCanvas = document.createElement('canvas');
        newCanvas.height = 960;
        newCanvas.width = 960;
        let context = newCanvas.getContext('2d');
        context.drawImage(canvas, 0, 0, 960, 960);
        canvas = newCanvas;
      }
      if (canvas.msToBlob) {
        let blob = canvas.msToBlob();
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let downloadLink = document.getElementById('download-img-link');
        downloadLink.href = canvas.toDataURL('image/png');
        downloadLink.download = 'チームの意見分類結果.png';
        downloadLink.click();
      }
      const vcd = defaultCookie.get();
      const params = {
        trackable_type: 'survey',
        trackable_id: vcd['surveyId'],
        key: 'survey.download_tendencies_team_image'
      };
      this.createPublicActivity(params);
    },

    copyImage() {
      let canvas = document.getElementById('horizontalbar-chart');
      let img = document.createElement('img');
      img.src = canvas.toDataURL();

      let div = document.createElement('div');
      div.contentEditable = true;
      div.appendChild(img);
      document.body.appendChild(div);

      this.selectForCopy(div);
      document.execCommand('Copy');
      document.body.removeChild(div);
      const vcd = defaultCookie.get();
      const params = {
        trackable_type: 'survey',
        trackable_id: vcd['surveyId'],
        key: 'survey.copy_tendencies_team_image'
      };
      this.createPublicActivity(params);
    },

    selectForCopy(element) {
      let doc = document;
      if (doc.body.createTextRange) {
        let range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        let selection = window.getSelection();
        let range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    },

    opinionDataForDisplay(count, opinionCount = 0) {
      if (this.displayType === 0)
        return Math.round((count / this.total_opinion_count) * 100);
      else if (this.displayType === 1)
        return Math.round((count / opinionCount) * 100);
      else return count;
    },

    respondentsDataForDisplay(count) {
      if (this.displayType === 0)
        return ((count / this.survey_response_count) * 100).toFixed(0);
      else return count;
    },

    clickEvent(_point, _event) {
      // const [emoName, opiName, value] = this.$refs.horizontalBarChart.getDetails(
      //   point,
      //   event,
      //   this.datacollection
      // );
      // const attributeValues = this.$parent.$data.attributeValueNames;
      // const displayType = this.$parent.$data.displayType;
      // alert(
      //   '意見分類：' +
      //     opiName +
      //     '\n感情分類：' +
      //     emoName +
      //     '\n値：' +
      //     value +
      //     (displayType === 0 ? '%' : '') +
      //     '\n属性：' +
      //     (attributeValues.length > 0 ? attributeValues.join(', ') : '無し')
      // );
    },

    toggleSortType(type) {
      this.sortType = type;
    },

    sortData(tend = null) {
      let tendencies = [...tend];
      if (['organizations', 'attributeValues'].includes(this.sortType))
        this.tendencies = tendencies;
      else if (this.sortType === 'negative') {
        this.tendencies = tendencies.sort((a, b) => {
          if ([0, 1].includes(this.displayType)) {
            const aRate = this.opinionDataForDisplay(
              a.negative_count,
              a.opinion_count
            );
            const bRate = this.opinionDataForDisplay(
              b.negative_count,
              b.opinion_count
            );
            return bRate - aRate;
          } else return b.negative_count - a.negative_count;
        });
      } else if (this.sortType === 'positive') {
        this.tendencies = tendencies.sort((a, b) => {
          if ([0, 1].includes(this.displayType)) {
            const aRate = this.opinionDataForDisplay(
              a.positive_count,
              a.opinion_count
            );
            const bRate = this.opinionDataForDisplay(
              b.positive_count,
              b.opinion_count
            );
            return bRate - aRate;
          } else return b.positive_count - a.positive_count;
        });
      } else if (this.sortType === 'neutral') {
        this.tendencies = tendencies.sort((a, b) => {
          if ([0, 1].includes(this.displayType)) {
            const aRate = this.opinionDataForDisplay(
              a.neutral_count,
              a.opinion_count
            );
            const bRate = this.opinionDataForDisplay(
              b.neutral_count,
              b.opinion_count
            );
            return bRate - aRate;
          } else return b.neutral_count - a.neutral_count;
        });
      }
      this.changeGraphHeight();
    },

    toAnalyseTeam(organizationId, sortType) {
      this.$emit('toAnalyseTeam', organizationId, sortType);
    },

    changeGraphHeight() {
      const orgSize = this.tendencies.length;
      let canvas = document.getElementById('horizontalbar-chart');
      const height = 17 * orgSize + 100;
      if (canvas) canvas.parentNode.style.height = height + 'px';
    }
  }
};
</script>
<style scoped>
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle {
  background: #fff !important;
  border: 1px solid #f3154a !important;
  color: #f3154a;
  font-size: 0.85rem;
  width: 150px;
  float: right;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle span {
  margin: auto;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle:hover {
  background: #fff !important;
  border-radius: 2px;
  border: 1px solid #f3154a;
  padding: 12px;
  color: #f3154a;
  font-weight: 600;
  transition: 0.15s ease;
  white-space: nowrap;
}
.vc-export-btn-wrap .dropdown .vc-export-btn.dropdown-toggle:after {
  content: none;
}
.vc-export-btn-wrap .dropdown .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem !important;
}
</style>
