<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content.vc-dashboard.vc-topic(v-show="ready")
        SurveyOrganizationSelect(@input="changeSurveyId")
        TopicNav
        div(v-if="dataLoaded")
          .vc-main-container.container(v-if="isTopic")
            .vc-content-col(v-if="topicPer.length < 1")
              .container
                | 回答が{{diplayableNum()}}以下は表示できません。分析対象の組織を選びなおしてください
            div(v-else)
              .vc-dashboard-box.mb-4
                .container-fluid
                  .row.ml-1(v-show="survey.question_text")
                    .col-12
                      | {{ survey.question_text }}
              .vc-topic-content.mb-4
                .vc-left-col(:class="displayAttributeValues ? 'mr-1' : ''")
                  TopicOrganizations(:surveyId="surveyId")
                .vc-left-col(v-show="displayAttributeValues")
                  TopicAttributeValues(@input="changeAttributeValueIds" :surveyId="surveyId")
                .vc-dashboard-box
                  .container-fluid
                    .row
                      .mb-5(:class="displayAttributeValues ? 'col-8' : 'col-6'")
                        TopicData(
                          :chartData="orgChartDatas[topicData.total[0]]"
                          :topicData="topicData.total"
                          :chartOptions="chartOptions"
                          :attributeValueIds="attributeValueIds"
                        )
                      .mb-5(
                        v-for="org in originOrganizations"
                        :class="displayAttributeValues ? 'col-8' : 'col-6'"
                        v-if="selected.includes(org.id) && opinionCounts[org.id] > 0"
                      )
                        TopicData(
                          :chartData="orgChartDatas[topicData.organizations[org.id][0]]"
                          :topicData="topicData.organizations[org.id]"
                          :chartOptions="chartOptions"
                          :orgId="org.id"
                          :attributeValueIds="attributeValueIds"
                        )
          .vc-main-container.container(v-if="!isTopic")
            .vc-content-col
              .container
                | トピック調査以外の調査については、この画面はご利用できません。調査選択から、トピック調査を選択ください。
        .vc-main-container.container(v-else)
          .vc-content-col
            .container
              | 読み込み中です。しばらくお待ちください。
</template>
<script>
import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import SurveyOrganizationSelect from '../../../components/u/SurveyOrganizationSelect';
import TopicNav from '../../../components/u/topic/TopicNav';
import TopicOrganizations from '../../../components/u/topic/TopicOrganizations';
import TopicAttributeValues from '../../../components/u/topic/TopicAttributeValues';
import TopicData from '../../../components/u/topic/TopicData';
import PieChart from '../../../components/u/chartJs/PieChart';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    SurveyOrganizationSelect,
    TopicNav,
    TopicOrganizations,
    TopicAttributeValues,
    TopicData,
    PieChart
  },

  data() {
    return {
      surveyId: null,
      orgChartDatas: {},
      chartOptions: {
        responsive: true,
        layout: {
          padding: {
            left: 12,
            right: 12,
            top: 0,
            bottom: 0
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          align: 'start'
        }
      },
      ready: false,
      displayAll: {},
      attributeValueIds: [],
      topicData: {
        total: [],
        organizations: {}
      },
      opinionCounts: {},
      dataLoaded: false,
      originOrganizations: []
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_topic', [
      'topicPer',
      'emotionIds',
      'survey',
      'organizations',
      'selected',
      'displayAttributeValues',
      'responseAttributes'
    ]),
    ...mapState('pages/u_surveys/select', ['isTopic']),
    ...mapState('resources/u_control_settings', ['controlSettings'])
  },

  watch: {
    surveyId(newId, oldId) {
      if (newId !== oldId) this.loadTopic();
    },
    topicPer: function() {
      this.pieGraphUpdate();
    }
  },

  created() {
    const vcd = defaultCookie.get();
    if (vcd) this.surveyId = vcd['surveyId'];
    this.loadControlSettings();
  },

  mounted() {
    this.ready = true;
  },

  methods: {
    ...mapActions('resources/u_topic', ['loadTopicPer']),
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapMutations('resources/u_opinion_search', ['setKeepedAttributeValues']),
    loadTopic() {
      this.loadTopicPer({
        survey_id: this.surveyId
      }).then(() => {
        this.dataLoaded = true;
      });
    },

    changeSurveyId(data) {
      this.surveyId = data['surveyId'];
    },

    pieGraphUpdate: function() {
      const labels = ['ネガティブ', 'ポジティブ', '中立'];
      const backgroundColors = [
        'rgba(232, 94, 94, 0.8)',
        'rgba(34, 190, 211, 0.8)',
        'rgba(133, 133, 133, 0.8)'
      ];

      let totalData = this.topicPer;
      if (this.attributeValueIds.length > 0) {
        for (let i = 0; i < this.responseAttributes.length; i++) {
          const avIds = this.responseAttributes[i].attribute_values.map(
            av => av.id
          );
          let intersection = this.attributeValueIds.filter(id =>
            avIds.includes(id)
          );
          if (intersection.length < 1) continue;
          totalData = totalData.filter(d => {
            const raId = this.responseAttributes[i].id;
            const av = d.attribute_values.find(arr => arr[0] == raId);
            return this.attributeValueIds.includes(av[1][0].id);
          });
        }
      }
      const negaData = totalData.filter(
        d => d.emotion_classification === 'ネガティブ'
      );
      const posiData = totalData.filter(
        d => d.emotion_classification === 'ポジティブ'
      );
      const neutData = totalData.filter(
        d => d.emotion_classification === '中立'
      );

      const data = [
        '全社',
        [
          [
            'ネガティブ',
            negaData.length > 0
              ? ((negaData.length / totalData.length) * 100).toFixed(1)
              : (0.0).toFixed(1),
            negaData.length
          ],
          [
            'ポジティブ',
            posiData.length > 0
              ? ((posiData.length / totalData.length) * 100).toFixed(1)
              : (0.0).toFixed(1),
            posiData.length
          ],
          [
            '中立',
            neutData.length > 0
              ? ((neutData.length / totalData.length) * 100).toFixed(1)
              : (0.0).toFixed(1),
            neutData.length
          ]
        ]
      ];
      Vue.set(this.topicData, 'total', data);

      let originOrgs = [];
      this.organizations.forEach(async org => {
        let orgTotalData = this.topicPer.filter(d =>
          d.include_org_ids.includes(org.id)
        );
        if (this.attributeValueIds.length > 0) {
          for (let i = 0; i < this.responseAttributes.length; i++) {
            const avIds = this.responseAttributes[i].attribute_values.map(
              av => av.id
            );
            let intersection = this.attributeValueIds.filter(id =>
              avIds.includes(id)
            );
            if (intersection.length < 1) continue;
            orgTotalData = orgTotalData.filter(d => {
              const raId = this.responseAttributes[i].id;
              const av = d.attribute_values.find(arr => arr[0] == raId);
              return this.attributeValueIds.includes(av[1][0].id);
            });
          }
        }
        Vue.set(this.opinionCounts, org.id, orgTotalData.length);
        const orgNegaData = orgTotalData.filter(
          d => d.emotion_classification === 'ネガティブ'
        );
        const orgPosiData = orgTotalData.filter(
          d => d.emotion_classification === 'ポジティブ'
        );
        const orgNeutData = orgTotalData.filter(
          d => d.emotion_classification === '中立'
        );
        const orgData = [
          org.name,
          [
            [
              'ネガティブ',
              orgNegaData.length > 0
                ? ((orgNegaData.length / orgTotalData.length) * 100).toFixed(1)
                : (0.0).toFixed(1),
              orgNegaData.length
            ],
            [
              'ポジティブ',
              orgPosiData.length > 0
                ? ((orgPosiData.length / orgTotalData.length) * 100).toFixed(1)
                : (0.0).toFixed(1),
              orgPosiData.length
            ],
            [
              '中立',
              orgNeutData.length > 0
                ? ((orgNeutData.length / orgTotalData.length) * 100).toFixed(1)
                : (0.0).toFixed(1),
              orgNeutData.length
            ]
          ]
        ];
        Promise.resolve(Vue.set(this.topicData.organizations, org.id, orgData));
        originOrgs.push(org);
        Promise.resolve(
          org.children.forEach(async childOrg => {
            let childOrgTotalData = this.topicPer.filter(d =>
              d.include_org_ids.includes(childOrg.id)
            );
            if (this.attributeValueIds.length > 0) {
              for (let i = 0; i < this.responseAttributes.length; i++) {
                const avIds = this.responseAttributes[i].attribute_values.map(
                  av => av.id
                );
                let intersection = this.attributeValueIds.filter(id =>
                  avIds.includes(id)
                );
                if (intersection.length < 1) continue;
                childOrgTotalData = childOrgTotalData.filter(d => {
                  const raId = this.responseAttributes[i].id;
                  const av = d.attribute_values.find(arr => arr[0] == raId);
                  return this.attributeValueIds.includes(av[1][0].id);
                });
              }
            }
            Vue.set(this.opinionCounts, childOrg.id, childOrgTotalData.length);
            const childOrgNegaData = childOrgTotalData.filter(
              d => d.emotion_classification === 'ネガティブ'
            );
            const childOrgPosiData = childOrgTotalData.filter(
              d => d.emotion_classification === 'ポジティブ'
            );
            const childOrgNeutData = childOrgTotalData.filter(
              d => d.emotion_classification === '中立'
            );
            const childOrgData = [
              childOrg.name,
              [
                [
                  'ネガティブ',
                  childOrgNegaData.length > 0
                    ? (
                        (childOrgNegaData.length / childOrgTotalData.length) *
                        100
                      ).toFixed(1)
                    : (0.0).toFixed(1),
                  childOrgNegaData.length
                ],
                [
                  'ポジティブ',
                  childOrgPosiData.length > 0
                    ? (
                        (childOrgPosiData.length / childOrgTotalData.length) *
                        100
                      ).toFixed(1)
                    : (0.0).toFixed(1),
                  childOrgPosiData.length
                ],
                [
                  '中立',
                  childOrgNeutData.length > 0
                    ? (
                        (childOrgNeutData.length / childOrgTotalData.length) *
                        100
                      ).toFixed(1)
                    : (0.0).toFixed(1),
                  childOrgNeutData.length
                ]
              ]
            ];
            Promise.resolve(
              Vue.set(this.topicData.organizations, childOrg.id, childOrgData)
            );
            originOrgs.push(childOrg);
          })
        );
      });

      this.orgChartDatas[this.topicData.total[0]] = {
        labels: labels,
        datasets: [
          {
            backgroundColor: backgroundColors,
            data: this.topicData.total[1].map(x => x[1])
          }
        ]
      };

      Object.keys(this.topicData.organizations).forEach(k => {
        this.orgChartDatas[this.topicData.organizations[k][0]] = {
          labels: labels,
          datasets: [
            {
              backgroundColor: backgroundColors,
              data: this.topicData.organizations[k][1].map(x => x[1])
            }
          ]
        };
      });

      this.originOrganizations = originOrgs;
    },

    diplayableNum() {
      const name = 'number_of_displayable_data';
      if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
        return this.controlSettings[name]['values']
          ? this.controlSettings[name]['values']
          : 0;
      }
      return 0;
    },

    changeAttributeValueIds(attributeValueIds) {
      this.attributeValueIds = attributeValueIds;
      this.setKeepedAttributeValues(attributeValueIds);
      this.pieGraphUpdate();
    }
  }
};
</script>
<style scoped>
.vc-topic-content {
  display: flex;
  align-items: flex-start;
}
</style>
