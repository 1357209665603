import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_analyse_team');

export default {
  namespaced: true,

  state: {
    survey_response_count: null,
    total_opinion_count: null,
    emotion_classifications: [],
    opinion_tendencies: {},
    analyse_opinion_tendencies: {},
    opinion_trends: {},
    opinion_per_attribute_values: {},
    response_attributes: [],
    ns_opinion_per_attribute_values: {},
    topic: {},
    ranking: [],
    opinion_type: '',
    opinionIds: {},
    emotionIds: {},
    displayFullWidthGraph: false,
    displayFullWidthGraphForEc: false
  },

  getters: {},

  actions: {
    /**
     * 分析対象の意見傾向データ取得
     */
    loadOpinionTendencies({ commit }, params) {
      return gateway
        .get({ action: 'opinion_tendencies' }, params)
        .then(response => {
          if (response.status === 200) {
            commit(
              'setSurveyResponseCount',
              response.body.survey_response_count
            );
            commit('setTotalOpinionCount', response.body.total_opinion_count);
            commit('setOpinionTendencies', response.body.opinion_tendencies);
          }
          return response;
        });
    },
    loadOpinionTendenciesForAnalyse({ commit }, params) {
      return gateway
        .get({ action: 'opinion_tendencies' }, params)
        .then(response => {
          if (response.status === 200) {
            commit(
              'setSurveyResponseCount',
              response.body.survey_response_count
            );
            commit('setTotalOpinionCount', response.body.total_opinion_count);
            commit(
              'setAnalyseOpinionTendencies',
              response.body.opinion_tendencies
            );
          }
          return response;
        });
    },
    /**
     * 意見数の推移データ取得
     */
    loadOpinionTrends({ commit }, params) {
      return gateway
        .get({ action: 'opinion_trends' }, params)
        .then(response => {
          if (response.status === 200) {
            commit(
              'setEmotionClassifications',
              response.body.emotion_classifications
            );
            commit('setOpinionTrends', response.body.opinion_trends);
          }
          return response;
        });
    },
    /**
     * 指定意見の属性値別意見数データ取得
     */
    loadOpinionPerAttributeValues({ commit }, params) {
      return gateway
        .get({ action: 'opinion_per_attribute_values' }, params)
        .then(response => {
          if (response.status === 200) {
            commit(
              'setOpinionPerAttributeValues',
              response.body.opinion_per_attribute_values
            );
          }
          return response;
        });
    },
    /**
     * 指定意見の属性値別意見数データ取得 全体/ネガ/ポジ/中立
     */
    loadNsOpinionPerAttributeValues({ commit }, params) {
      return gateway
        .get({ action: 'ns_opinion_per_attribute_values' }, params)
        .then(response => {
          if (response.status === 200) {
            commit('setResponseAttributes', response.body.response_attributes);
            commit(
              'setNsOpinionPerAttributeValues',
              response.body.ns_opinion_per_attribute_values
            );
          }
          return response;
        });
    },
    /**
     * 指定意見の属性値別意見数データ取得
     */
    loadOpinionPerAttributeValuesForEc({ commit }, params) {
      return gateway
        .get({ action: 'opinion_per_attribute_values_for_ec' }, params)
        .then(response => {
          if (response.status === 200) {
            commit(
              'setOpinionPerAttributeValues',
              response.body.opinion_per_attribute_values
            );
          }
          return response;
        });
    },
    /**
     * 指定意見の属性値別意見数データ取得 全体/ネガ/ポジ/中立
     */
    loadNsOpinionPerAttributeValuesForEc({ commit }, params) {
      return gateway
        .get({ action: 'ns_opinion_per_attribute_values_for_ec' }, params)
        .then(response => {
          if (response.status === 200) {
            commit('setResponseAttributes', response.body.response_attributes);
            commit(
              'setNsOpinionPerAttributeValues',
              response.body.ns_opinion_per_attribute_values
            );
          }
          return response;
        });
    },
    /**
     * 分析対象チームで表示するトピックデータ取得
     */
    loadTopic({ commit }, params) {
      return gateway.get({ action: 'topic' }, params).then(response => {
        if (response.status === 200) {
          commit(
            'setEmotionClassifications',
            response.body.emotion_classifications
          );
          commit('setTopic', response.body.topic);
        }
        return response;
      });
    },
    loadRankingByOpinionClassifications({ commit }, params) {
      return gateway
        .get({ action: 'ranking_by_opinion_classifications' }, params)
        .then(response => {
          if (response.status === 200) {
            commit('setRanking', response.body.ranking);
            commit('setOpinionType', response.body.opinion_type);
            commit('setOpinionIds', response.body.opinions);
            commit('setEmotionIds', response.body.emotions);
          }
        });
    }
  },
  mutations: {
    setSurveyResponseCount(state, survey_response_count) {
      state.survey_response_count = survey_response_count;
    },
    setTotalOpinionCount(state, total_opinion_count) {
      state.total_opinion_count = total_opinion_count;
    },
    setEmotionClassifications(state, emotion_classifications) {
      state.emotion_classifications = emotion_classifications;
    },
    setOpinionTendencies(state, opinion_tendencies) {
      state.opinion_tendencies = opinion_tendencies;
    },
    setAnalyseOpinionTendencies(state, opinion_tendencies) {
      state.analyse_opinion_tendencies = opinion_tendencies;
    },
    setOpinionTrends(state, opinion_trends) {
      state.opinion_trends = opinion_trends;
    },
    setOpinionPerAttributeValues(state, opinion_per_attribute_values) {
      state.opinion_per_attribute_values = opinion_per_attribute_values;
    },
    setResponseAttributes(state, response_attributes) {
      state.response_attributes = response_attributes;
    },
    setNsOpinionPerAttributeValues(state, ns_opinion_per_attribute_values) {
      state.ns_opinion_per_attribute_values = ns_opinion_per_attribute_values;
    },
    setTopic(state, topic) {
      state.topic = topic;
    },
    setRanking(state, ranking) {
      state.ranking = ranking;
    },
    setOpinionType(state, opinion_type) {
      state.opinion_type = opinion_type;
    },
    setOpinionIds(state, opinions) {
      const obj = opinions.reduce((result, current, _) => {
        result[current.name] = current.id;
        return result;
      }, {});
      state.opinionIds = obj;
    },
    setEmotionIds(state, emotions) {
      const obj = emotions.reduce((result, current, _) => {
        result[current.name] = current.id;
        return result;
      }, {});
      state.emotionIds = obj;
    },
    setDisplayFullWidthGraph(state, bool) {
      state.displayFullWidthGraph = bool;
    },
    setDisplayFullWidthGraphForEc(state, bool) {
      state.displayFullWidthGraphForEc = bool;
    }
  }
};
