<template lang="pug">
#left-filter
  .vc-cat
    .row.mt-3
      .col-6
        button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="allSelect")
          | すべて選択
      .col-6
        button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="clearSelect")
          | 選択解除

  .vc-cat
    ul
      li
        .custom-control.custom-checkbox.vc-custom-checkbox
          input(
            type="checkbox"
            class="custom-control-input"
            id="attributeValue0"
            value="-1"
            v-model="selectedIds"
            @change="onCheckItem"
          )
          label(
            class="custom-control-label"
            for="attributeValue0"
          )
            | {{  $t('user.qualitative.analyse_matrix.all') }}

  .vc-cat
    ul
      li(v-for="(organization, i) in navOrganizations")
        .custom-control.custom-checkbox.vc-custom-checkbox
          input(
            type="checkbox"
            class="custom-control-input"
            :id="'organization'+organization.id"
            :value="organization.id"
            v-model="selectedIds"
            @change="onCheckItem"
          )
          label(
            class="custom-control-label"
            :for="'organization'+organization.id"
          )
            | {{ organization.name }}

</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  components: {},

  props: {},

  data() {
    return {
      selectedIds: []
    };
  },

  computed: {
    ...mapState('resources/u_quantitative', ['navOrganizations'])
  },

  created() {},

  mounted() {
    this.allSelect();
  },

  methods: {
    ...mapMutations('resources/u_analyse_heatmap', ['setOrganizationIds']),

    /**
     * Select all organizations
     */
    allSelect() {
      const orgs = this.navOrganizations;
      let selected = [-1];
      for (let i = 0; i < orgs.length; i++) {
        selected.push(orgs[i].id);
      }
      this.selectedIds = selected;
      this.setOrganizationIds(this.selectedIds);

      this.$parent.$emit('check-item');
    },

    /**
     * Clear all selected organizations
     *
     */
    clearSelect() {
      this.selectedIds = [];
    },

    /**
     * Check or uncheck an organization and its children
     */
    onCheckItem() {
      if (this.selectedIds.length > 0) {
        this.setOrganizationIds(this.selectedIds);
      } else {
        this.setOrganizationIds([]);
      }

      this.$parent.$emit('check-item');
    }
  }
};
</script>

<style scoped>
.organization-ul {
  margin-left: 1rem;
}
#left-filter .attribute-values-btn {
  cursor: pointer;
  color: #444 !important;
}
#left-filter .attribute-values-btn:hover {
  color: #444 !important;
}
.organizations-hide-btn {
  cursor: pointer;
}
.custom-control-label {
  width: 82%;
}
.vc .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #f3154a !important;
  background: #f3154a !important;
}
span.second-arrow {
  position: absolute;
}
</style>
