<template lang="pug">
  .content-wrapper
    .content-header.d-flex.flex-column.flex-md-row
      h4#users_list_title.mb-0
        | {{ $t('admin.users.title') }}
      .wrapper.ml-0.ml-md-auto.my-auto.d-flex.align-items-center.pt-4.pt-md-0
        router-link.btn.btn-success.btn-sm.ml-auto(:to="{ name: 'x_new_user' }")
          | {{ $t('admin.users.index.new') }}
    .row
      .col-md-12.grid-margin.stretch-card.clearfix
        .card
          .card-body.pb-0
            .table-responsive
              table.table.table-striped
                thead
                  tr
                    th
                      | {{ $t('admin.users.id') }}
                    th
                      | {{ $t('admin.users.mainAccountGroup') }}
                    th
                      | {{ $t('admin.users.name') }}
                    th
                      | {{ $t('admin.users.email') }}
                    th
                      | {{ $t('admin.users.roleName') }}
                    th
                      | {{ $t('admin.users.status') }}
                    th
                      | {{ $t('admin.users.signInCount') }}
                    th &nbsp
                tbody
                  tr(v-for="user in usersList")
                    td
                      | {{ user.id }}
                    td
                      | {{ user.account_group ? user.account_group.name : '-' }}
                    td
                      | {{ user.name }}
                    td
                      | {{ user.email }}
                    td
                      | {{ user.role_name }}
                    td(v-if="user.locked")
                      | {{ $t('admin.users.locked') }}
                    td(v-else-if="user.disabled_at || user.deleted_at")
                      | {{ $t('admin.users.disabled') }}
                    td(v-else-if="user.signed_in")
                      | {{ $t('admin.users.signedIn') }}
                    td(v-else)
                      | {{ $t('admin.users.notSignedIn') }}
                    td.text-center
                      | {{ user.sign_in_count }}
                    td
                      button.btn.btn-secondary.dropdown
                        a.dropdown-toggle.no-style(
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        )
                          | アクション
                        .dropdown-menu(aria-labelledby="dropdownMenuButton")
                          router-link.dropdown-item(
                            :to="{ name: 'x_edit_user', params : { id: user.id } }" :class="{'btn-disabled': user.deleted_at}")
                              | {{ $t('actions.edit') }}
                          router-link.dropdown-item.mt-1(
                            :to="{ name: 'x_add_account_group', params: { id: user.id } }" :class="{'btn-disabled': user.deleted_at}")
                              | {{ $t('actions.addAccountGroup') }}
                          a.dropdown-item.mt-1(@click="showDeleteModal(user)" :class="{'btn-disabled': user.deleted_at}")
                            | {{ $t('actions.delete') }}
                          a.dropdown-item.mt-1(@click="showUnlockModal(user)" :class="{'btn-disabled': !user.locked}")
                            | {{ $t('admin.users.unlock.btnText') }}
                          a.dropdown-item.mt-1(@click="showEnableModal(user)" :class="{'btn-disabled': !user.disabled_at}" v-show="user.disabled_at")
                            | {{ $t('admin.users.enable.btnText') }}
                          a.dropdown-item.mt-1(@click="showDisableModal(user)" :class="{'btn-disabled': user.disabled_at}" v-show="!user.disabled_at")
                            | {{ $t('admin.users.disable.btnText') }}

            .mt-1.float-right
              paginate(
                :page-count="pageParams.pages"
                :click-handler="clickCallback"
                containerClass="pagination"
                pageClass="page-item"
                pageLinkClass="page-link"
                prevText="«"
                prevClass="page-item"
                prevLinkClass="page-link"
                nextText="»"
                nextClass="page-item"
                nextLinkClass="page-link"
              )

    UserDeleteModal(:deleteTarget="deleteTarget")
    UserUnlockModal(:unlockTarget="unlockTarget")
    UserEnableModal(:enableTarget="enableTarget")
    UserDisableModal(:disableTarget="disableTarget")
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Paginate from 'vuejs-paginate';
import UserDeleteModal from 'src/components/x/users/UserDeleteModal';
import UserUnlockModal from 'src/components/x/users/UserUnlockModal';
import UserEnableModal from 'src/components/x/users/UserEnableModal';
import UserDisableModal from 'src/components/x/users/UserDisableModal';

export default {
  components: {
    Paginate,
    UserDeleteModal,
    UserUnlockModal,
    UserEnableModal,
    UserDisableModal
  },

  data() {
    return {
      defaultPer: 10,
      deleteTarget: {},
      unlockTarget: {},
      enableTarget: {},
      disableTarget: {}
    };
  },

  computed: {
    ...mapState('resources/x_users', ['usersList']),
    ...mapState('pages/x_users/list/pagination', ['pageParams'])
  },

  created() {
    this.setPer(this.defaultPer);
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_users', ['loadUsers']),
    ...mapMutations('pages/x_users/list/pagination', [
      'setTotal',
      'setPages',
      'setPage',
      'setPer'
    ]),

    /**
     * ユーザー一覧の更新
     *
     * @return { void }
     */
    load() {
      this.loadUsers(this.pageParams).then(response => {
        this.setTotal(response.body.page_options.total);
        this.setPages(response.body.page_options.pages);
      });
    },

    clickCallback: function(pageNum) {
      this.setPage(pageNum);
      this.load();
    },

    /**
     * 削除モーダルを表示する
     *
     * @return {void}
     */
    showDeleteModal(user) {
      this.deleteTarget = user;
      this.$bvModal.show('user-delete-modal');
    },

    showUnlockModal(user) {
      this.unlockTarget = user;
      this.$bvModal.show('user-unlock-modal');
    },

    showEnableModal(user) {
      this.enableTarget = user;
      this.$bvModal.show('user-enable-modal');
    },

    showDisableModal(user) {
      this.disableTarget = user;
      this.$bvModal.show('user-disable-modal');
    }
  }
};
</script>
