<template lang="pug">
  #container
    router-view
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  components: {},

  data() {
    return {};
  },

  computed: {
    ...mapState('config', ['operator'])
  },

  created() {
    this.initConfig(this.$parent.config);
  },

  mounted() {},

  methods: {
    ...mapMutations('config', { initConfig: 'init' })
  }
};
</script>
