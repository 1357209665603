<template lang="pug">
  #left-filter
    p.vc-left-col-title
    .text-right
      a.attribute-values-btn.mr-3.mb-0(v-show="!displayAttributeValues" @click="toggleDisplayAttributeValues") >> 属性でフィルタ
    .vc-cat
      ul
        li
          .custom-control.custom-checkbox.vc-custom-checkbox
            input#organization-all(
              type="checkbox"
              class="custom-control-input"
              v-model="selectAll"
            )
            label(
              class="custom-control-label"
              for="organization-all"
            )
              | 全社
          ul.organization-ul
            li(v-for="(organization, i) in organizations")
              .custom-control.custom-checkbox.vc-custom-checkbox
                input(
                  type="checkbox"
                  class="custom-control-input"
                  :id="'organization'+organization.id"
                  :value="organization.id"
                  v-model="currentSelected"
                )
                label(
                  class="custom-control-label"
                  :for="'organization'+organization.id"
                )
                  | {{organization.name}}
                span.float-right
                  img.organizations-hide-btn.ml-2(
                    src="../../../assets/images/excheck/chevron-down.png" width="18"
                    :class="{'reverse': displayOrganizations[organization.id]}"
                    @click.prevent="toggleDisplayOrganizations(organization.id)"
                  )
              ul.organization-ul(v-show="displayOrganizations[organization.id]")
                li(v-for="(childOrg, ii) in organization.children")
                  .custom-control.custom-checkbox.vc-custom-checkbox
                    input(
                      type="checkbox"
                      class="custom-control-input"
                      :id="'organization'+childOrg.id"
                      :value="childOrg.id"
                      v-model="currentSelected"
                    )
                    label(
                      class="custom-control-label"
                      :for="'organization'+childOrg.id"
                    )
                      | {{childOrg.name}}

    //- .vc-submit-wrap.mb-4
    //-   button(
    //-     class="btn btn-primary btn-block vc-primary-btn"
    //-     :disabled="processing"
    //-     @click.prevent="submit"
    //-   )
    //-     | フィルタを設定

</template>
<script>
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';

export default {
  components: {},

  props: {},

  data() {
    return {
      currentSelected: [],
      processing: false,
      selectAll: true,
      displayOrganizations: {}
    };
  },

  computed: {
    ...mapState('resources/u_topic', [
      'organizations',
      'displayAttributeValues',
      'keepedSelectedOrganizations'
    ])
  },

  watch: {
    selectAll() {
      if (this.selectAll) this.allSelect();
      else this.clearSelect();
    },
    currentSelected() {
      this.processing = true;
      if (this.currentSelected.length > 0)
        this.setSelected(this.currentSelected);
      else this.setSelected([]);
      this.setKeepedSelectedOrganizations(this.currentSelected);
      this.processing = false;
    }
  },

  created() {
    if (this.keepedSelectedOrganizations.length > 0)
      this.currentSelected = this.keepedSelectedOrganizations;
    else if (this.organizations.length > 0) this.defaultSelect();
    this.organizations.forEach(org => {
      Vue.set(this.displayOrganizations, org.id, false);
    });
  },

  mounted() {},

  methods: {
    ...mapMutations('resources/u_topic', [
      'setSelected',
      'setDisplayAttributeValues',
      'setKeepedSelectedOrganizations'
    ]),
    allSelect() {
      const orgs = this.organizations;
      let selected = [];
      for (let i in orgs) {
        const children = orgs[i].children;
        selected.push(orgs[i].id);
        for (let ii in children) {
          const grandChildren = children[ii].children;
          selected.push(children[ii].id);
          for (let iii in grandChildren) {
            selected.push(grandChildren[iii]);
          }
        }
      }
      this.currentSelected = selected.sort();
    },
    clearSelect() {
      this.currentSelected = [];
    },
    submit() {
      this.processing = true;
      if (this.currentSelected.length > 0)
        this.setSelected(this.currentSelected);
      else this.setSelected([]);
      this.processing = false;
    },
    toggleDisplayAttributeValues() {
      this.setDisplayAttributeValues(!this.displayAttributeValues);
    },
    toggleDisplayOrganizations(organizationId) {
      Vue.set(
        this.displayOrganizations,
        organizationId,
        !this.displayOrganizations[organizationId]
      );
    },
    // toggleSelectedOrganizations(organizationId) {
    //   const parent = this.organizations.find(org => org.id === organizationId);
    //   const children = parent.children;
    //   let ids = children.map(org => org.id);
    //   ids.push(organizationId);
    //   if (this.currentSelected.includes(organizationId))
    //     ids.forEach(
    //       id =>
    //         (this.currentSelected = this.currentSelected.filter(
    //           cId => cId !== id
    //         ))
    //     );
    //   else {
    //     const arr = this.currentSelected.concat(ids);
    //     this.currentSelected = arr;
    //   }
    // }
    defaultSelect() {
      const orgs = this.organizations;
      let selected = [];
      for (let i in orgs) {
        selected.push(orgs[i].id);
      }
      this.currentSelected = selected.sort();
    }
  }
};
</script>
<style scoped>
.organization-ul {
  margin-left: 1rem;
}
#left-filter .attribute-values-btn {
  cursor: pointer;
  color: #444 !important;
}
#left-filter .attribute-values-btn:hover {
  color: #444 !important;
}
.organizations-hide-btn {
  cursor: pointer;
}
</style>
