import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_dashboard');

export default {
  namespaced: true,
  state: {
    activities: []
  },
  actions: {
    loadPublicActivities({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          commit('setActivities', response.body.activities);
        }
        return response;
      });
    }
  },

  mutations: {
    setActivities(state, activities) {
      state.activities = activities;
    }
  }
};
