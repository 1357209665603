<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | {{ organizationVersion.name }}
  .row
    .col-md-12.grid-margin.stretch-card.clearfix
      .card
        .card-body.vc
          .row.mb-4
            .col-12
              error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
              ul.alert.alert-success(v-show="message")
                button.close.vc-message-close(@click="resetMessages")
                  span(aria-hidden='true') ×
                li.ml-1
                  | {{ message }}
              .mb-3
                p.cc-label-mid 取込用回答CSVのアップロード
                a.cc-primary-btn.cc-inline-btn.mr-2(href="" @click.prevent="exportTempCsv")
                  | テンプレートCSVをダウンロード
                label.cc-normal-btn.cc-file-btn.mr-2 ファイルを添付
                  input.d-none(type="file" accept="text/csv" @change="onFileChange($event)")
                a.cc-primary-btn.cc-inline-btn(href="" @click.prevent="importCsv") 組織データCSVのアップロード
          .row
            .col-12
              p.cc-label-mid 組織一覧
              .interview-detail
                .table-responsive.scrollable-tabele.vc-responses-result
                  span
                    | {{ organizations.length }}件
                  table.table.table-bordered
                    thead
                      tr
                        th Id
                        th Code
                        th 組織名
                        th 表示順
                        th 組織階層
                        th 親組織Code
                    tbody
                      tr(v-for="org in organizations")
                        td
                          | {{ org.id }}
                        td
                          | {{ org.code }}
                        td
                          | {{ org.name }}
                        td
                          | {{ org.order_no }}
                        td
                          | {{ org.organization_hierarchy }}
                        td
                          | {{ org.parent_code ? org.parent_code : '-' }}
</template>
<script>
import { mapState, mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';
import i18n from '../../../locales/index';

export default {
  components: {
    ErrorMessage
  },

  data() {
    return {
      errorMessages: [],
      message: '',
      processing: false,
      csvFile: ''
    };
  },

  computed: {
    ...mapState('resources/x_organization_versions', [
      'organizationVersion',
      'organizations'
    ]),

    responsesCsvParams() {
      const formData = new FormData();
      formData.append('csv_file', this.responsesCsvFile);

      return formData;
    },

    opinionsCsvParams() {
      const formData = new FormData();
      formData.append('csv_file', this.opinionsCsvFile);

      return formData;
    }
  },

  created() {
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_organization_versions', [
      'loadOrganizationVersion',
      'exportSentencesCsv',
      'exportTemplateCsv'
    ]),

    load() {
      let params = this.$route.params;
      this.loadOrganizationVersion(params.id);
    },

    resetMessages() {
      this.errorMessages = [];
      this.message = '';
    },

    importCsv() {
      this.resetMessages();

      let path =
        '/api/x/organization_versions/' +
        this.$route.params.id +
        '/import_organizations_csv';
      const formData = new FormData();
      formData.append('csv_file', this.csvFile);

      fetch(path, {
        method: 'PUT',
        body: formData
      }).then(response => {
        if (response.status === 200) {
          this.message = i18n.tc('admin.surveys.messages.processing');
        } else if (response.status === 422) {
          response.json().then(body => {
            this.errorMessages = body.errors;
          });
        }
      });
    },

    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.csvFile = files[0];
    },

    exportTempCsv() {
      let routeData = this.$router.resolve({
        name: 'x_export_template_organization_version',
        params: { id: this.$route.params.id }
      });
      window.open(routeData.href, '_blank');
    }
  }
};
</script>
