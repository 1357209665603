import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_co_occurrence_networks');

export default {
  namespaced: true,
  state: {
    networks: [],
    currentPage: 0,
    network: {},
    words: [],
    network: []
  },
  getters: {},
  actions: {
    loadNetworks({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          const networks = response.body.networks;
          const currentPage = response.body.current_page;
          commit('setNetworks', networks);
          commit('setCurrentPage', currentPage);
        }
        return response;
      });
    },
    createNetwork() {
      return gateway.post({ action: 'create' }).then(response => {
        return response;
      });
    },
    deleteNetwork({ commit }, id) {
      return gateway.delete({ action: 'destroy', id: id }).then(response => {
        if (response.status === 200) {
          const networks = response.body.networks;
          const currentPage = response.body.current_page;
          commit('setNetworks', networks);
          commit('setCurrentPage', currentPage);
        }
        return response;
      });
    },
    loadNetwork({ commit }, params) {
      return gateway.get({ action: 'show', id: params.id }).then(response => {
        if (response.status === 200) {
          const network = response.body.network;
          commit('setNetwork', network);
        }
        return response;
      });
    },
    updateNetwork(_, params) {
      return gateway
        .put({ action: 'update', id: params.id }, params)
        .then(response => {
          return response;
        });
    },
    requestApi({ commit }, params) {
      return gateway
        .post({ action: 'morphological_analysis', id: params.id }, params)
        .then(response => {
          const words = response.body.words;
          commit('setWords', words);
          return response;
        });
    },
    loadRequestApi({ commit }, id) {
      return gateway.get({ action: 'request_api', id: id }).then(response => {
        const network = response.body.network;
        commit('setNetwork', network);
        return response;
      });
    },
    requestCoOccurrence(_, params) {
      return gateway
        .post({ action: 'co_occurrence_networks', id: params.id }, params)
        .then(response => {
          return response;
        });
    },
    loadGenerateImage({ commit }, id) {
      return gateway
        .get({ action: 'generate_image', id: id })
        .then(response => {
          const network = response.body.network;
          commit('setNetwork', network);
          return response;
        });
    }
  },

  mutations: {
    setNetworks(state, networks) {
      state.networks = networks;
    },
    setCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
    },
    setNetwork(state, network) {
      state.network = network;
    },
    setWords(state, words) {
      state.words = words;
    }
  }
};
