<template lang="pug">
  div.vc-top-col.pt-0
    .container
      //- SurveySelect()
      OrganizationSelect(
        @setOrganizationId="setOrganizationId"
        @changeOrganization="changeOrganization"
        v-show="!isTopic"
        :is-display-button="isDisplayButton"
        )
</template>
<script>
import { mapState } from 'vuex';
import SurveySelect from './SurveySelect';
import OrganizationSelect from './OrganizationSelect';
import dc from '../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    SurveySelect,
    OrganizationSelect
  },
  props: {
    isDisplayButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      organizationId: null
    };
  },
  computed: {
    ...mapState('pages/u_surveys/select', ['surveyId', 'isTopic']),
    ...mapState('pages/u_organizations/select', ['beforeSelected', 'started'])
  },
  created() {},
  mounted() {},

  methods: {
    setOrganizationId: function(organizationId) {
      const vcd = defaultCookie.get();
      this.organizationId = organizationId;
      const data = {
        surveyId: vcd['surveyId'],
        organizationId: this.organizationId,
        isTopic: this.isTopic
      };
      defaultCookie.set(data);
      this.$emit('input', data);
    },
    changeOrganization: function(organizationId) {
      const vcd = defaultCookie.get();
      this.organizationId = organizationId;
      const data = {
        surveyId: vcd['surveyId'],
        organizationId: this.organizationId,
        isTopic: this.isTopic
      };
      defaultCookie.set(data);
      this.$emit('changeTargetOrganization', [this.organizationId]);
    }
  }
};
</script>
<style scoped>
.vc-top-col .vc-sub-select-wrap {
  border-top: none !important;
  padding: 0px !important;
}
</style>
