import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('r_inquiries');

export default {
  namespaced: true,

  state: {
    survey: {}
  },

  getters: {},

  actions: {
    acceptChatInterview(_, inquiryId) {
      return gateway.get({ action: 'accept', id: inquiryId }).then(response => {
        return response;
      });
    }
  },

  mutations: {
    setSurvey(state, survey) {
      state.survey = survey;
    }
  }
};
