<template lang="pug">
  div
    div(v-if="dataLoaded")
      .vc-main-container.container
        .vc-content-col(v-if="!emptyQuestions")
          .container
            .row
              .col-12.mt-3(v-for="qq in quantitativeQuestions" :class="graphClass(qq)")
                .h4.vc-midashi.mt-2.mb-2(:id="'quantitative-question-text'+qq.code")
                  | {{ qq.title }}
                p.small(:id="'quantitative-question-text'+qq.code")
                  | {{ qq.question_text }}
                p.small(:id="'quantitative-question-description'+qq.code")
                  | {{ qq.description }}
                .row
                  div(:class="graphCheck(qq, graphData[qq.id], 'pie') ? 'col-12' : 'offset-4 col-4'")
                    pie-chart(
                      :chart-data="graphData[qq.id] || {}"
                      v-if="graphCheck(qq, graphData[qq.id], 'pie')"
                      :options="pieChartOptions"
                      :styles="pieChartStyle" :height="300"
                    )
                    vertical-bar-chart(
                      :chart-data="graphData[qq.id] || {}"
                      v-if="graphCheck(qq, graphData[qq.id], 'vertical')"
                      :options="horizontalBarChartOptions"
                      :styles="verticalChartStyle"
                      :height="250"
                    )
                    horizontal-bar-chart(
                      :chart-data="graphData[qq.id] || {}"
                      v-if="graphCheck(qq, graphData[qq.id], 'horizontal')"
                      :options="horizontalBarChartOptions"
                      :styles="horizontalBarChartStyle"
                      :height="150"
                    )
                    horizontal-bar-chart(
                      :chart-data="graphData[qq.id] || {}"
                      v-if="graphCheck(qq, graphData[qq.id], 'horizontalWithMatrix')"
                      :options="matrixHorizontalBarChartOptions"
                      :styles="horizontalBarChartStyle"
                      :height="150"
                    )
        .vc-content-col(v-else)
          .container
            | 表示できるデータがありません
    .vc-main-container.container(v-else)
        .vc-content-col
          .container
            | 読み込み中です。しばらくお待ちください。
</template>
<script>
import Vue from 'vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import PieChart from '../../../components/u/chartJs/PieChart';
import HorizontalBarChart from '../../../components/u/chartJs/HorizontalBarChart';
import VerticalBarChart from '../../../components/u/chartJs/VerticalBarChart';
import QuantitativeOrganizations from '../../../components/u/quantitativeAnalyse/QuantitativeOrganizations';
import QuantitativeAttributeValues from '../../../components/u/quantitativeAnalyse/QuantitativeAttributeValues';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    QuantitativeOrganizations,
    QuantitativeAttributeValues,
    PieChart,
    HorizontalBarChart,
    VerticalBarChart
  },
  data() {
    return {
      dataLoaded: false,
      emptyQuestions: false,
      graphData: {},
      pieChartOptions: {
        responsive: true,
        tooltips: {
          enabled: true,
          xAlign: 'center',
          yAlign: 'bottom',
          backgroundColor: 'rgba(0,0,0,0.6)',
          intersect: false
        },
        layout: {
          padding: {
            left: 12,
            right: 12,
            top: 0,
            bottom: 0
          }
        },
        maintainAspectRatio: false
      },
      horizontalBarChartOptions: {
        legend: false,
        tooltips: {
          enabled: true,
          mode: 'point',
          xAlign: 'center',
          yAlign: 'bottom',
          backgroundColor: 'rgba(0,0,0,0.6)',
          intersect: false
        },
        responsive: true,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              stepSize: 5
            }
          ],
          yAxes: [
            {
              stacked: true,
              barThickness: 11
            }
          ]
        }
      },
      matrixHorizontalBarChartOptions: {
        tooltips: {
          enabled: true,
          mode: 'point',
          xAlign: 'center',
          yAlign: 'bottom',
          backgroundColor: 'rgba(0,0,0,0.6)',
          intersect: false
        },
        responsive: true,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              stepSize: 5
            }
          ],
          yAxes: [
            {
              stacked: true,
              barThickness: 11
            }
          ]
        }
      },
      pieChartStyle: { height: '300px', position: 'relative' },
      horizontalBarChartStyle: { height: '250px', positon: 'relative' },
      verticalChartStyle: { height: '250px', position: 'relative' },
      graphBackgroundColors: [
        '#22BED3',
        '#61D8E8',
        '#BEE6FB',
        '#D1D1D1',
        '#E3DEDE'
      ]
    };
  },
  computed: {
    ...mapState('pages/u_surveys/select', ['surveyId']),
    ...mapState('resources/u_quantitative', [
      'analyseData',
      'quantitativeQuestions',
      'displayAttributeValues',
      'organizationIds',
      'attributeValueIds'
    ])
  },
  watch: {
    surveyId() {
      this.emptyQuestions = false;
      this.load();
    },
    quantitativeQuestions() {
      if (Object.keys(this.quantitativeQuestions).length <= 0)
        this.emptyQuestions = true;
    }
  },
  created() {
    const vcd = defaultCookie.get();
    if (vcd['surveyId'] || this.surveyId) this.load();
  },
  mounted() {},
  methods: {
    ...mapActions('resources/u_quantitative', ['loadCalculatedAnalyseData']),
    ...mapMutations('resources/u_quantitative', ['setOrganizationIds']),
    load() {
      const vcd = defaultCookie.get();
      const params = {
        survey_id: vcd['surveyId'] || this.surveyId,
        organization_ids: this.organizationIds,
        attribute_value_ids: this.attributeValueIds
      };
      this.loadCalculatedAnalyseData(params).then(() => {
        this.updateGraph();
        this.dataLoaded = true;
      });
    },
    updateGraph() {
      for (let i = 0; i < this.quantitativeQuestions.length; i++) {
        const qq = this.quantitativeQuestions[i];
        if (Object.keys(this.analyseData).length > 0) {
          let datasets = [];
          let labels = [];
          let backgroundColors = [];
          if (['SA', 'MA'].includes(qq.question_type)) {
            let isVertical =
              (qq.question_type === 'SA' && qq.answers_size > 5) ||
              qq.question_type === 'MA';
            labels = Object.keys(this.analyseData[qq.id]).reverse();
            if (isVertical) {
              for (let i = 0; i < labels.length; i++) {
                backgroundColors.push('#00ead4');
              }
            } else backgroundColors = this.graphBackgroundColors;
            datasets = [
              {
                backgroundColor: backgroundColors,
                data: Object.values(this.analyseData[qq.id]).reverse()
              }
            ];
          } else if (['MTSA', 'MTMA'].includes(qq.question_type)) {
            labels = qq.matrix_categories.map(c => c.category_text);
            let answers = qq.answers;
            for (let i = 0; i < answers.length; i++) {
              const ans = answers[i];
              let d = {
                label: ans.select_num,
                borderWidth: 1,
                backgroundColor: 'rgba(232, 94, 94, 0.8)',
                width: 2,
                backgroundColor: this.graphBackgroundColors[i],
                data: Object.values(this.analyseData[qq.id][ans.select_num])
              };
              datasets.push(d);
            }
          }
          let data = {
            labels: labels,
            datasets: datasets
          };
          Vue.set(this.graphData, qq.id, data);
        } else Vue.set(this.graphData, qq.id, {});
      }
    },
    graphClass(qq) {
      if (this.graphData[qq.id] && qq.question_type !== 'SA')
        if (!this.displayAttributeValues) return 'graph-margin-bottom';
        else return '';
    },
    graphCheck(qq, qqId, graphType) {
      if (qq.graph_type === graphType) return true;
      if (!qq.graph_type) {
        if (graphType === 'pie')
          return (
            qqId &&
            qq.calculate_type !== 'count_vertical' &&
            qq.question_type === 'SA' &&
            qq.answers_size <= 5
          );
        else if (graphType === 'horizontal')
          return (
            qqId &&
            qq.calculate_type !== 'count_vertical' &&
            (qq.question_type === 'MA' ||
              (qq.question_type === 'SA' && qq.answers_size > 5) ||
              qq.question_type === 'NPS')
          );
        else if (graphType === 'horizontalWithMatrix')
          return qqId && ['MTSA'].includes(qq.question_type);
        else if (graphType === 'horizontalWithCountVertical')
          return qqId && qq.calculate_type === 'count_vertical';
      }
    },
    verticalBarChartOptions(qq) {
      return {
        title: {
          display: false
        },
        tooltips: {
          enabled: true,
          backgroundColor: 'rgba(0,0,0,0.6)',
          callbacks: {
            title: function(tooltipItem) {
              return tooltipItem[0].label.replaceAll(',', '');
            },
            label: function(tooltipItem) {
              if (tooltipItem.xLabel == 0) return;
              let num = tooltipItem.yLabel;
              return num.toFixed(1) + (qq.unit ? qq.unit : '');
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: -1,
            bottom: 0
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.3,
              ticks: {
                fontSize: 10
              }
            }
          ],
          yAxes: [
            {
              barPercentage: 0.5,
              ticks: {
                beginAtZero: true,
                fontSize: 10,
                maxTicksLimit: 4,
                callback: function(value) {
                  return value.toFixed(1) + (qq.unit ? qq.unit : '');
                }
              }
            }
          ]
        }
      };
    }
  }
};
</script>
<style scoped>
.vc-topic-content {
  display: flex;
  align-items: flex-start;
}
.graph-margin-bottom {
  margin-bottom: 250px !important;
}
</style>
