<template lang="pug">
  .content-wrapper
    .content-header.d-flex.flex-column.flex-md-row
      h4.mb-0
        | {{ $t('admin.newsletterTemplates.title') }}
      .wrapper.ml-0.ml-md-auto.my-auto.d-flex.align-items-center.pt-4.pt-md-0
        router-link.btn.btn-success.btn-sm.ml-auto(:to="{ name: 'x_new_newsletter_template' }")
          | {{ $t('admin.newsletterTemplates.index.new') }}
    .row
      .col-md-12.grid-margin.stretch-card.clearfix
        .card
          .card-body.pb-0
            .table-responsive
              table.table.table-striped
                thead
                  tr
                    th(width="100px")
                      | {{ $t('admin.newsletterTemplates.id') }}
                    th
                      | {{ $t('admin.newsletterTemplates.name') }}
                    th(width="300px")
                      | {{ $t('admin.newsletterTemplates.action') }}
                tbody
                  tr(v-for="template in templates")
                    td
                      | {{ template.id }}
                    td
                      | {{ template.name }}
                    td
                      router-link.btn.btn-outline-primary.mr-2(
                        :to="{ name: 'x_show_newsletter_template', params : { id: template.id } }")
                          | {{ $t('actions.show') }}
                      router-link.btn.btn-outline-primary.mr-2(
                        :to="{ name: 'x_preview_newsletter_template', params: { id: template.id } }"
                      )
                        | {{ $t('actions.preview') }}
                      button.btn.btn-outline-danger(@click="showDeleteModal(template)")
                        | {{ $t('actions.delete') }}

            .mt-1.float-right
              paginate(
                :page-count="pageParams.pages"
                :click-handler="clickCallback"
                containerClass="pagination"
                pageClass="page-item"
                pageLinkClass="page-link"
                prevText="«"
                prevClass="page-item"
                prevLinkClass="page-link"
                nextText="»"
                nextClass="page-item"
                nextLinkClass="page-link"
              )

    NewsletterTemplateDeleteModal(:deleteTarget="deleteTarget")
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Paginate from 'vuejs-paginate';
import NewsletterTemplateDeleteModal from 'src/components/x/newsletter_templates/NewsletterTemplateDeleteModal';

export default {
  components: {
    Paginate,
    NewsletterTemplateDeleteModal
  },

  data() {
    return {
      defaultPer: 10,
      deleteTarget: {}
    };
  },

  computed: {
    ...mapState('resources/x_newsletter_templates', ['templates']),
    ...mapState('pages/x_newsletter_templates/list/pagination', ['pageParams'])
  },

  created() {
    this.setPer(this.defaultPer);
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_newsletter_templates', ['loadTemplates']),
    ...mapMutations('pages/x_newsletter_templates/list/pagination', [
      'setTotal',
      'setPages',
      'setPage',
      'setPer'
    ]),
    load() {
      this.loadTemplates(this.pageParams).then(response => {
        this.setTotal(response.body.page_options.total);
        this.setPages(response.body.page_options.pages);
      });
    },
    clickCallback: function(pageNum) {
      this.setPage(pageNum);
      this.load();
    },
    showDeleteModal(newsletterTemplate) {
      this.deleteTarget = newsletterTemplate;
      this.$bvModal.show('newsletter-template-delete-modal');
    }
  }
};
</script>
