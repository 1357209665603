<template lang="pug">
  .content-wrapper
    .content-header.d-flex.flex-column.flex-md-row
      h4.mb-0
        | {{ $t('admin.newsletters.title') }}
      .wrapper.ml-0.ml-md-auto.my-auto.d-flex.align-items-center.pt-4.pt-md-0
        router-link.btn.btn-success.btn-sm.ml-auto(:to="{ name: 'x_new_newsletter' }")
          | {{ $t('admin.newsletters.index.new') }}
    .row
      .col-md-12.grid-margin.stretch-card.clearfix
        .card
          .card-body.pb-0
            .table-responsive
              table.table.table-striped
                thead
                  tr
                    th
                      | {{ $t('admin.newsletters.id') }}
                    th
                      | {{ $t('admin.newsletters.name') }}
                    th
                      | {{ $t('admin.newsletters.sender') }}
                    th
                      | {{ $t('admin.newsletters.accountGroup') }}
                    th
                      | {{ $t('admin.newsletters.surveyName') }}
                    th
                      | {{ $t('admin.newsletters.action') }}
                tbody
                  tr(v-for="newsletter in newsletters")
                    td
                      | {{ newsletter.id }}
                    td
                      | {{ newsletter.name }}
                    td
                      | {{ newsletter.sender_email }}
                    td
                      | {{ newsletter.account_group_name }}
                    td
                      | {{ newsletter.survey_name }}
                    td
                      router-link.btn.btn-outline-primary.mr-2(
                        v-if="newsletter.sent_at"
                        :to="{ name: 'x_show_newsletter', params : { id: newsletter.id } }"
                      )
                        | {{ $t('actions.show') }}
                      router-link.btn.btn-outline-primary.mr-2(
                        v-else
                        :to="{ name: 'x_edit_newsletter', params : { id: newsletter.id } }"
                      )
                        | {{ $t('actions.edit') }}
                      button.btn.btn-outline-primary(v-show="newsletter.sent_at" @click="copy(newsletter)")
                        | {{ $t('actions.copy') }}

            .mt-1.float-right
              paginate(
                :page-count="pageParams.pages"
                :click-handler="clickCallback"
                containerClass="pagination"
                pageClass="page-item"
                pageLinkClass="page-link"
                prevText="«"
                prevClass="page-item"
                prevLinkClass="page-link"
                nextText="»"
                nextClass="page-item"
                nextLinkClass="page-link"
              )

</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Paginate from 'vuejs-paginate';

export default {
  components: {
    Paginate
  },

  data() {
    return {
      defaultPer: 10
    };
  },

  computed: {
    ...mapState('resources/x_newsletters', ['newsletters']),
    ...mapState('pages/x_newsletters/list/pagination', ['pageParams'])
  },

  created() {
    this.setPer(this.defaultPer);
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_newsletters', [
      'loadNewsletters',
      'copyNewsletter'
    ]),
    ...mapMutations('pages/x_newsletters/list/pagination', [
      'setTotal',
      'setPages',
      'setPage',
      'setPer'
    ]),
    load() {
      this.loadNewsletters(this.pageParams).then(response => {
        this.setTotal(response.body.page_options.total);
        this.setPages(response.body.page_options.pages);
      });
    },
    clickCallback: function(pageNum) {
      this.setPage(pageNum);
      this.load();
    },
    copy(newsletter) {
      this.copyNewsletter(newsletter.id).then(response => {
        if (response.status === 200) {
          this.$router.go(this.$router.currentRoute);
        }
      });
    }
  }
};
</script>
