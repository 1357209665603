import Vue from 'vue';

// -- ActionCable --
import { createConsumer } from '@rails/actioncable';

const cable = createConsumer('/cable');
Vue.prototype.$cable = cable;

// -- Actors App --
import DefaultApp from '../app';
import AdminApp from '../AdminApp';
import UserApp from '../UserApp';
import ResponderApp from '../ResponderApp';

// -- vue-router --
import routerIndex from '../src/router/routes_index';
import routerUsers from '../src/router/routes_users';
import routerAdmins from '../src/router/routes_admins';
import routerResponders from '../src/router/routes_responders';

// -- vuex --
import store from '../src/store';

// -- style --
import '../src/lib/style';

// -- vue-18n --
import i18n from '../src/locales/index';

// -- validator --
import '../src/lib/validator';

// -- filters --
import '../src/lib/filters';

// -- scroll --
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

const host = window.location.host;
const parts = host.split('.');
const domainLength = 3;

const router = () => {
  let routes;
  if (parts.length === domainLength - 1 || parts[0] === 'www') {
    routes = routerIndex;
  } else if (parts[0] === 'app') {
    routes = routerUsers;
  } else if (parts[0] === 'pwfneupo') {
    routes = routerAdmins;
  } else if (parts[0] === 'answer') {
    routes = routerResponders;
  } else {
    routes = routerIndex;
  }
  return routes;
};

const App = () => {
  let app;
  if (parts.length === domainLength - 1 || parts[0] === 'www') {
    app = DefaultApp;
  } else if (parts[0] === 'app') {
    app = UserApp;
  } else if (parts[0] === 'pwfneupo') {
    app = AdminApp;
  } else if (parts[0] === 'answer') {
    app = ResponderApp;
  } else {
    app = DefaultApp;
  }
  return app;
};
const MyApp = new App();

Vue.prototype.$path = function(params) {
  return router.resolve(params).href;
};

document.addEventListener('DOMContentLoaded', () => {
  const config = Object.assign(
    {},
    document.querySelector('script[name="config"]').dataset
  );

  // change locale
  i18n.locale = config.locale;

  new Vue({
    components: {
      App: MyApp
    },
    data() {
      return {
        config
      };
    },
    template: '<App/>',
    render: h => h(MyApp),
    router: router(),
    store,
    i18n
  }).$mount('#app');
});
