import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_text_mining');

export default {
  namespaced: true,

  state: {
    survey: {},
    negaTextMinings: [],
    posiTextMinings: [],
    emotionTypes: {},
    emotionType: 'negative',
    responseCount: 0,
    isExists: false,
    node1: '',
    node2: '',
    node3: '',
    type1: 'OR',
    type2: 'OR'
  },

  getters: {},

  actions: {
    loadTextMining({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          commit('setSurvey', response.body.survey);
          commit('setTextMinings', response.body.text_minings);
          commit('setEmotionTypes', response.body.emotion_types);
          commit('setResponseCount', response.body.response_count);
          commit('setIsExists', response.body.is_exists);
        }
      });
    },

    updateTextComment({ commit }, params) {
      return gateway
        .put({ action: 'update', id: params.id }, params)
        .then(response => {
          if (response.status === 200) {
            commit('setTextMinings', response.body.text_minings);
          }
        });
    }
  },

  mutations: {
    setSurvey(state, survey) {
      state.survey = survey;
    },
    setTextMinings(state, text_minings) {
      state.negaTextMinings = text_minings[0];
      state.posiTextMinings = text_minings[1];
    },
    setEmotionTypes(state, emotion_types) {
      state.emotionTypes = emotion_types;
    },
    setEmotionType(state, emotion_type) {
      state.emotionType = emotion_type;
    },
    setResponseCount(state, response_count) {
      state.responseCount = response_count;
    },
    setIsExists(state, is_exists) {
      state.isExists = is_exists;
    },
    setNode1(state, node1) {
      state.node1 = node1;
    },
    setNode2(state, node2) {
      state.node2 = node2;
    },
    setNode3(state, node3) {
      state.node3 = node3;
    },
    setType1(state, type1) {
      state.type1 = type1;
    },
    setType2(state, type2) {
      state.type2 = type2;
    }
  }
};
