<template lang="pug">
  div(v-if="Object.keys(topic).length && Object.keys(surveyOpinionCounts).length")
    hr.my-team-divider
    .vc-dashboard
      .row
        .col-6.mt-4(v-for="(surveyOpinionCount, key1) of surveyOpinionCounts")
          .row.vc-dashboard-box-content
            .col-12.vc-dashboard-box-title
              h4.vc-midashi.mt-3.mb-2
                | {{ surveyOpinionCount['survey_name'] }}
              p.period
                | {{ surveyOpinionCount['start_at'] | moment('YYYY.MM.DD')}}
                | - 
                | {{ surveyOpinionCount['end_at'] | moment('YYYY.MM.DD')}}
            .dashboard-comp-graph-col.vc-dashboard-box-graph-wrap.topic-comp-graph-col(v-if="surveyOpinionCount['opinion_count']>0")
              pie-chart(:chart-data="orgChartDatas[key1]" :options="chartOptions")
            .dashboard-comp-table-col.vc-dashboard-box-table-wrap.topic-comp-table-col(v-if="surveyOpinionCount['opinion_count']>0")
              .row
                .col-12.vc-dashboard-box-title
                  h5.vc-midashi.mt-3.mb-2 感情意見割合
              .row.no-gutters.vc-respondent-table
                .col-7.text-left.overflow-hidden.text-nowrap 感情分類
                .col-5
                  .row.no-gutters
                    .col-6
                    .col-6 割合
              .vc-dashboard-box-table-data-row.no-scroll
                .row.no-gutters.vc-respondent-table(v-for="(d, i) of surveyOpinionCount['data']")
                  .col-7.text-left.overflow-hidden.text-nowrap.table-column-name
                    | {{ orgChartDatas[key1]['labels'][i] }}
                  .col-5
                    .row.no-gutters.vc-respondent-table-num
                      .col-6
                        a(
                          @click.prevent="toOpinionSearch(surveyOpinionCount['id'], topic.organization_id, surveyOpinionCount['ecId'][i])"
                        )
                          svg.to-opinion-search(
                            xmlns="http://www.w3.org/2000/svg"
                            enable-background="new 0 0 24 24"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#000000"
                          )
                            g
                              rect(fill="none" height="24" width="24")
                            g
                              g
                                rect(height="2" width="9" x="13" y="7")
                                rect(height="2" width="9" x="13" y="15")
                                rect(height="2" width="6" x="16" y="11")
                                polygon(points="13,12 8,7 8,11 2,11 2,13 8,13 8,17")
                      .col-6
                        | {{ ((d/surveyOpinionCount['opinion_count'])*100).toFixed(1) }}%
            .col-12(v-if="surveyOpinionCount['opinion_count']==0")
              | データがありません
</template>

<script>
import { mapState } from 'vuex';
import PieChart from '../../../components/u/chartJs/PieChart';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    PieChart
  },

  props: {
    surveyId: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data() {
    return {
      surveyOpinionCounts: {},
      orgChartDatas: {},
      chartOptions: {
        responsive: true,
        layout: {
          padding: {
            left: 12,
            right: 12,
            top: 0,
            bottom: 0
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          align: 'start'
        }
      }
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('resources/u_analyse_team', [
      'emotion_classifications',
      'topic'
    ]),
    ...mapState('resources/u_opinion_search', ['keepedEmotionValues'])
  },

  watch: {
    topic: function() {
      this.updateSurveyOpinionCounts();
    }
  },

  created() {
    this.updateSurveyOpinionCounts();
  },

  mounted() {},

  methods: {
    updateSurveyOpinionCounts: function() {
      if (!this.topic) return;
      const emotion_classifications = this.emotion_classifications;
      var surveyOpinionCounts = {};
      for (const survey_opinion_count of this.topic['survey_opinion_counts']) {
        surveyOpinionCounts[survey_opinion_count.id] = survey_opinion_count;
        surveyOpinionCounts[survey_opinion_count.id]['ecId'] = [];
        surveyOpinionCounts[survey_opinion_count.id]['labels'] = [];
        surveyOpinionCounts[survey_opinion_count.id]['data'] = [];
        for (const ec of emotion_classifications) {
          surveyOpinionCounts[survey_opinion_count.id]['ecId'].push(ec.id);
          surveyOpinionCounts[survey_opinion_count.id]['labels'].push(ec.name);
          surveyOpinionCounts[survey_opinion_count.id]['data'].push(
            survey_opinion_count['emotion_opinion_count' + ec.id]
          );
        }
      }
      this.surveyOpinionCounts = surveyOpinionCounts;
      this.pieGraphUpdate();
    },
    pieGraphUpdate: function() {
      const backgroundColors = [
        'rgba(232, 94, 94, 0.8)',
        'rgba(34, 190, 211, 0.8)',
        'rgba(133, 133, 133, 0.8)'
      ];
      var orgChartDatas = {};
      for (const key in this.surveyOpinionCounts) {
        orgChartDatas[key] = {
          labels: this.surveyOpinionCounts[key]['labels'],
          datasets: [
            {
              backgroundColor: backgroundColors,
              data: this.surveyOpinionCounts[key]['data']
            }
          ]
        };
      }
      this.orgChartDatas = orgChartDatas;
    },

    toOpinionSearch: function(
      surveyId,
      organizationId,
      emotionClassificationId
    ) {
      const vcd = defaultCookie.get();
      this.$router.push({
        name: 'u_opinion_search',
        params: {
          emotion_classification: emotionClassificationId,
          survey_id: surveyId,
          organization_id: organizationId,
          backToSurveyId: vcd['surveyId'],
          backToName: 'u_team_analyse'
        }
      });
    }
  }
};
</script>
