<template lang="pug">
.col-12
  .row
    .col-2.text-left.overflow-hidden.text-nowrap
      .row.mt-1
        .col-3
          .color-box(:style="'background-color:'+mining.color_code")
        .col-9
          | {{mining.opinion_count}}件
    .col-7.text-left(:class="{'mt-1': !editMode}")
      .edit-text-mining-comment(v-if="editMode")
        textarea.form-control(
          placeholder="解釈テキストを入力してください"
          v-model="newComment"
        )
      .pre-formatted(v-else)
        | {{mining.comment}}
    .col-3
      .row.no-gutters
        .col-4.pr-1
          a(
            href="javascript:void(0)"
            
            
          )
          .dropdown
            a.dropdown-toggle.no-style(
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :class="{'btn-disabled': !mining.keywords}"
            )
              svg.vc-dashboard-to-opinion-search(
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              )
                g
                  rect(fill="none" height="24" width="24")
                g
                  g
                    rect(height="2" width="9" x="13" y="7")
                    rect(height="2" width="9" x="13" y="15")
                    rect(height="2" width="6" x="16" y="11")
                    polygon(points="13,12 8,7 8,11 2,11 2,13 8,13 8,17")
            .dropdown-menu(
              aria-labelledby="dropdownMenuButton"
            )
              a(
                class="dropdown-item"
                @click.prevent="toOpinionSearch(mining.keywords, 'OR')"
              )
                | OR検索
              a(
                class="dropdown-item"
                @click.prevent="toOpinionSearch(mining.keywords, 'AND')"
              )
                | AND検索
        .col-4.pr-1
          button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="startEditMode") 編集
        .col-4.pl-1
          button.btn.btn-primary.btn-block.vc-primary-btn.select-all-btn(@click="updateComment") 保存
</template>
<script>
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();
export default {
  components: {},

  props: {
    mining: { type: Object, required: true }
  },

  data() {
    return {
      editMode: false,
      newComment: ''
    };
  },

  computed: {},
  watch: {
    mining: function() {
      this.newComment = this.mining.comment;
    }
  },
  created() {},
  mounted() {
    this.newComment = this.mining.comment;
  },

  methods: {
    startEditMode() {
      this.editMode = true;
    },
    updateComment() {
      this.editMode = false;
      const params = {
        id: this.mining.id,
        comment: this.newComment
      };
      this.$emit('updateComment', params);
    },
    toOpinionSearch(keywords, orAnd) {
      const vcd = defaultCookie.get();
      this.$router.push({
        name: 'u_qualitative_opinion_search',
        params: {
          survey_id: vcd['surveyId'] || this.surveyId,
          backToName: 'TextMinings',
          keywords: keywords,
          or_and: orAnd
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-text-mining-title input {
  padding: 5px 8px !important;
  margin: 0;
  height: 100%;
}
.color-box {
  width: 18px;
  height: 18px;
}
.pre-formatted {
  white-space: pre-wrap;
}
.vc-respondent-table .dropdown .dropdown-toggle {
  margin: 3px 5px;
}
.vc-respondent-table .dropdown .dropdown-toggle:after {
  bottom: 9px;
}
</style>
