import UserDashboard from '../pages/u/dashboard/index';
import UserEmotionClassPer from '../pages/u/dashboard/UserEmotionClassPer';
import UserVolumeMapDashboard from '../pages/u/dashboard/UserVolumeMapDashboard';
import UserOpinionClassMapDashboard from '../pages/u/dashboard/UserOpinionClassMapDashboard';
import UserMyPage from '../pages/u/UserMyPage';
import UserTeamAnalyse from '../pages/u/analyse/UserTeamAnalyse';
import UserCompareAnalyse from '../pages/u/analyse/UserCompareAnalyse';
import UserOpinionSearch from '../pages/u/opinion_search/UserOpinionSearch';
import UserAboutData from '../pages/u/UserAboutData';
import UsersList from '../pages/u/users/UsersList';
import NewUser from '../pages/u/users/NewUser';
import EditUser from '../pages/u/users/EditUser';
import UserTopic from '../pages/u/topic/index';
import UserTopicRanking from '../pages/u/topic/ranking';
import UserTextMining from '../pages/u/text_mining/index';
import UserSelectAccountGroup from '../pages/u/UserSelectAccountGroup';
import UserClassificationRequestList from '../pages/u/user_classification_requests/List';
import UserClassificationRequestShow from '../pages/u/user_classification_requests/Show';
import UserQuantitativeIndex from '../pages/u/quantitative_analyse/index';
import UserQuantitativeAnalyseDetails from '../pages/u/quantitative_analyse/details';
import UserInquiries from '../pages/u/inquiries/index';
import UserChatInterviews from '../pages/u/chat_interviews/index';
import UserResponseRanking from '../pages/u/response_ranking/index';
import UserQuantitativeAnalyse from '../pages/u/quantitative/Analyse';
import UserQuantitativeAnalyseChanges from '../pages/u/quantitative/AnalyseChanges';
import UserQuantitativeCalculatedAnalyse from '../pages/u/quantitative/CalculatedAnalyse';
import UserQuantitativeCalculatedAnalyseChanges from '../pages/u/quantitative/CalculatedAnalyseChanges';
import UserQuantitativeCalculatedAnalyseDetails from '../pages/u/quantitative/CalculatedAnalyseDetails';
import UserQualitativeOpinionSearch from '../pages/u/qualitative/UserOpinionSearch';
import UserQualitativeEmotionClassificationsTeam from '../pages/u/qualitative/UserQualitativeEmotionClassificationsTeam';
import UserQualitativeEmotionClassificationsAttributeValues from '../pages/u/qualitative/UserQualitativeEmotionClassificationsAttributeValues';
import UserQualitativeAnalyseRankings from '../pages/u/qualitative/UserQualitativeAnalyseRankings';
import UserQualitativeAnalyseTeam from '../pages/u/qualitative/UserQualitativeAnalyseTeam';
import UserQualitativeAnalyseCompare from '../pages/u/qualitative/UserQualitativeAnalyseCompare';
import UserQualitativeAnalyseTextMining from '../pages/u/qualitative/UserQualitativeAnalyseTextMining';
import UserQualitativeAnalyseMatrix from '../pages/u/qualitative/UserQualitativeAnalyseMatrix';
import UserQualitativeAnalyseMatrixAttributeValues from '../pages/u/qualitative/UserQualitativeAnalyseMatrixAttributeValues';
import UserQualitativeAnalyseHeatmap from '../pages/u/qualitative/UserQualitativeAnalyseHeatmap';
import UserCoOccurrenceNetwork from '../pages/u/qualitative/UserCoOccurrenceNetwork';

export default [
  {
    path: '/dashboard',
    component: UserDashboard,
    name: 'u_dashboard'
  },
  {
    path: '/dashboard/emotion_class_per',
    component: UserEmotionClassPer,
    name: 'u_emotion_class_per_dashboard'
  },
  {
    path: '/dashboard/volume_map',
    component: UserVolumeMapDashboard,
    name: 'u_volume_map_dashboard'
  },
  {
    path: '/dashboard/opinion_class_map',
    component: UserOpinionClassMapDashboard,
    name: 'u_opinion_class_map_dashboard'
  },
  {
    path: '/mypage',
    component: UserMyPage,
    name: 'u_mypage'
  },
  {
    path: '/analyse/team',
    component: UserTeamAnalyse,
    name: 'u_team_analyse'
  },
  {
    path: '/analyse/compare',
    component: UserCompareAnalyse,
    name: 'u_compare_analyse'
  },
  {
    path: '/opinion_search',
    component: UserOpinionSearch,
    name: 'u_opinion_search'
  },
  {
    path: '/about_data',
    component: UserAboutData,
    name: 'u_about_data'
  },
  {
    path: '/users',
    component: UsersList,
    name: 'u_users'
  },
  {
    path: '/users/new',
    component: NewUser,
    name: 'u_new_user'
  },
  {
    path: '/users/:id/edit',
    component: EditUser,
    name: 'u_edit_user'
  },
  {
    path: '/topic',
    component: UserTopic,
    name: 'u_topic'
  },
  {
    path: '/topic/ranking',
    component: UserTopicRanking,
    name: 'u_topic_ranking'
  },
  {
    path: '/text_mining',
    component: UserTextMining,
    name: 'u_text_mining'
  },
  {
    path: '/api/u/surveys/:id/reports/:report_id/download_report',
    name: 'u_survey_download_report'
  },
  {
    path: '/select_account_group',
    component: UserSelectAccountGroup,
    name: 'u_select_account_group'
  },
  {
    path: '/classification_requests',
    component: UserClassificationRequestList,
    name: 'u_classification_requests'
  },
  {
    path: '/classification_requests/:id',
    component: UserClassificationRequestShow,
    name: 'u_classification_request_show'
  },
  {
    path: '/quantitative_analyse',
    component: UserQuantitativeIndex,
    name: 'u_quantitative_index'
  },
  {
    path: '/quantitative_analyse/details',
    component: UserQuantitativeAnalyseDetails,
    name: 'u_quantitative_analyse_details'
  },
  {
    path: '/inquiries',
    component: UserInquiries,
    name: 'u_inquiries'
  },
  {
    path: '/inquiries/:inquiry_id/chat_interviews',
    component: UserChatInterviews,
    name: 'u_chat_interviews'
  },
  {
    path: '/response_ranking',
    component: UserResponseRanking,
    name: 'u_response_ranking'
  },
  {
    path: '/quantitative/analyse',
    component: UserQuantitativeAnalyse,
    name: 'u_quantitative_analyse'
  },
  {
    path: '/quantitative/analyse_changes',
    component: UserQuantitativeAnalyseChanges,
    name: 'u_quantitative_analyse_changes'
  },
  {
    path: '/quantitative/calculated_analyse',
    component: UserQuantitativeCalculatedAnalyse,
    name: 'u_quantitative_calculated_analyse'
  },
  {
    path: '/quantitative/calculated_analyse_changes',
    component: UserQuantitativeCalculatedAnalyseChanges,
    name: 'u_quantitative_calculated_analyse_changes'
  },
  {
    path: '/quantitative/calculated_analyse_details',
    component: UserQuantitativeCalculatedAnalyseDetails,
    name: 'u_quantitative_calculated_analyse_details'
  },
  {
    path: '/qualitative/opinion_search',
    component: UserQualitativeOpinionSearch,
    name: 'u_qualitative_opinion_search'
  },
  {
    path: '/qualitative/emotion_classifications/team',
    component: UserQualitativeEmotionClassificationsTeam,
    name: 'u_qualitative_emotion_classifications_team'
  },
  {
    path: '/qualitative/emotion_classifications/attribute_values',
    component: UserQualitativeEmotionClassificationsAttributeValues,
    name: 'u_qualitative_emotion_classifications_attribute_values'
  },
  {
    path: '/qualitative/analyse/rankings',
    component: UserQualitativeAnalyseRankings,
    name: 'u_qualitative_analyse_rankings'
  },
  {
    path: '/qualitative/analyse/team',
    component: UserQualitativeAnalyseTeam,
    name: 'u_qualitative_analyse_team'
  },
  {
    path: '/qualitative/analyse/compare',
    component: UserQualitativeAnalyseCompare,
    name: 'u_qualitative_analyse_compare'
  },
  {
    path: '/qualitative/text_mining',
    component: UserQualitativeAnalyseTextMining,
    name: 'u_qualitative_text_mining'
  },
  {
    path: '/qualitative/analyse/matrix',
    component: UserQualitativeAnalyseMatrix,
    name: 'u_qualitative_analyse_matrix'
  },
  {
    path: '/qualitative/analyse/matrix_attrs',
    component: UserQualitativeAnalyseMatrixAttributeValues,
    name: 'u_qualitative_analyse_matrix_attrs'
  },
  {
    path: '/qualitative/analyse/heatmap',
    component: UserQualitativeAnalyseHeatmap,
    name: 'u_qualitative_analyse_heatmap'
  },
  {
    path: '/qualitative/co_occurrences',
    component: UserCoOccurrenceNetwork,
    name: 'u_qualitative_co_occurrences'
  }
];
