<script>
import { HorizontalBar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: {
    options: { type: Object, required: true }
  },
  watch: {
    chartData() {
      this.$data._chart.update();
    }
  },
  mounted() {
    this.addPlugin({
      beforeDraw: function(c) {
        var ctx = c.chart.ctx;
        ctx.fillStyle = 'rgba(255,255,255,1)';
        ctx.fillRect(0, 0, c.chart.width, c.chart.height);
      }
    });
    let options = this.options;
    this.renderChart(this.chartData, options);
  },
  methods: {
    getDetails(point, event, data) {
      const c = this._data._chart;
      const datapoint = c.getElementAtEvent(point)[0];
      const indexBar = datapoint._index;
      const indexSegment = datapoint._datasetIndex;
      const emoName = data.labels[indexBar];
      const opiName = data.datasets[indexSegment].label;
      const value = data.datasets[indexSegment].data[indexBar];
      return [emoName, opiName, value];
    }
  }
};
</script>
