import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_newsletter_templates');

export default {
  namespaced: true,

  state: {
    templates: [],
    currentPage: 0,
    template: {},
    previewHtml: ''
  },

  getters: {},

  actions: {
    loadTemplates({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          let templates = response.body.templates;
          let currentPage = response.body.current_page;
          commit('setTemplates', templates);
          commit('setCurrentPage', currentPage);
        }
        return response;
      });
    },
    deleteNewsletterTemplate(_, id) {
      return gateway.delete({ action: 'remove', id }).then(response => {
        return response;
      });
    },
    loadTemplate({ commit }, id) {
      return gateway
        .get({
          action: 'show',
          id
        })
        .then(response => {
          if (response.status === 200) {
            const template = response.body.template;
            commit('setTemplate', template);
          }
          return response;
        });
    },
    deleteTemplateImgFile({ commit }, id) {
      return gateway
        .delete({ action: 'remove_img', image_id: id })
        .then(response => {
          if (response.status === 200) {
            const template = response.body.template;
            commit('setTemplate', template);
          }
          return response;
        });
    },
    loadPreviewHtml({ commit }, id) {
      return gateway
        .get({
          action: 'preview_html',
          id
        })
        .then(response => {
          if (response.status === 200) {
            const previewHtml = response.body.preview_html;
            commit('setPreviewHtml', previewHtml);
          }
          return response;
        });
    }
  },

  mutations: {
    setTemplates(state, templates) {
      state.templates = templates;
    },
    setCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
    },
    setTemplate(state, template) {
      state.template = template;
    },
    setPreviewHtml(state, previewHtml) {
      state.previewHtml = previewHtml;
    }
  }
};
