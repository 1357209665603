<template lang="pug">
  .content-wrapper
    .content-header.d-flex.flex-column.flex-md-row
      h4.mb-0
        | {{ $t('admin.organizationVersions.title') }}
      .wrapper.ml-0.ml-md-auto.my-auto.d-flex.align-items-center.pt-4.pt-md-0
        router-link.btn.btn-success.btn-sm.ml-auto(:to="{ name: 'x_new_organization_version' }")
          | {{ $t('admin.organizationVersions.index.new') }}
    .row
      .col-md-12.grid-margin.stretch-card.clearfix
        .card
          .card-body.pb-0
            .table-responsive
              table.table.table-striped
                thead
                  tr
                    th
                      | ID
                    th
                      | アカウントグループ名
                    th
                      | 組織バージョン名
                    th
                      | 組織バージョンコード
                    th
                      | 第一階層名
                    th
                      | 第二階層名
                    th &nbsp
                tbody
                  tr(v-for="version in organizationVersions")
                    td
                      | {{ version.id }}
                    td
                      | {{ version.account_group.name }}
                    td
                      | {{ version.name }}
                    td
                      | {{ version.code }}
                    td
                      | {{ version.organization_first_level_name }}
                    td
                      | {{ version.organization_second_level_name }}
                    td
                      router-link.btn.btn-outline-primary.mr-2(
                        :to="{ name: 'x_show_organization_version', params: { id: version.id } }")
                          | {{ $t('actions.show') }}
                      router-link.btn.btn-outline-primary.mr-2(
                        :to="{ name: 'x_edit_organization_version', params: { id: version.id } }")
                          | {{ $t('actions.edit') }}
                      button.btn.btn-outline-danger(@click="showDeleteModal(version)")
                        | {{ $t('actions.delete') }}

            .mt-1.float-right
              paginate(
                :page-count="pageParams.pages"
                :click-handler="clickCallback"
                containerClass="pagination"
                pageClass="page-item"
                pageLinkClass="page-link"
                prevText="«"
                prevClass="page-item"
                prevLinkClass="page-link"
                nextText="»"
                nextClass="page-item"
                nextLinkClass="page-link"
              )

    OrganizationVersionDeleteModal(:deleteTarget="deleteTarget")
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Paginate from 'vuejs-paginate';
import OrganizationVersionDeleteModal from 'src/components/x/organization_versions/OrganizationVersionDeleteModal';

export default {
  components: {
    Paginate,
    OrganizationVersionDeleteModal
  },

  data() {
    return {
      defaultPer: 10,
      deleteTarget: {}
    };
  },

  computed: {
    ...mapState('resources/x_organization_versions', ['organizationVersions']),
    ...mapState('pages/x_organization_versions/list/pagination', ['pageParams'])
  },

  created() {
    this.setPer(this.defaultPer);
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_organization_versions', [
      'loadOrganizationVersions'
    ]),
    ...mapMutations('pages/x_organization_versions/list/pagination', [
      'setTotal',
      'setPages',
      'setPage',
      'setPer'
    ]),

    load() {
      this.loadOrganizationVersions(this.pageParams).then(response => {
        this.setTotal(response.body.page_options.total);
        this.setPages(response.body.page_options.pages);
      });
    },

    clickCallback: function(pageNum) {
      this.setPage(pageNum);
      this.load();
    },

    showDeleteModal(version) {
      this.deleteTarget = version;
      this.$bvModal.show('organization-version-delete-modal');
    }
  }
};
</script>
