<template lang="pug">
  .form-group.row.vc-main-select-wrap(v-show="surveyId && surveys.length > 0 && started")
    .col-3.ml-set
      b-dropdown(
        variant="white"
        boundary="viewport"
        id="dropdown-dropup"
        size="sm"
        class="m-2 survey-select"
        :class="{'expanded': isExpanded}"
        @show="toggleIsOpened()"
        @hide="toggleIsOpened()"
      )
        template.select-value(v-slot:button-content)
          .text-left.display-title
            span.survey-title
              | {{getSurvey().name}}
        b-dropdown-text
          .expand-btn
            button.btn.btn-outline-secondary.btn-sm(@click.prevent="toggleIsExpanded")
              | {{ isExpanded ? 'ー' : '＋' }}
        b-dropdown-item-button(
          v-for="survey in surveys"
          :key="survey.id"
          @click="setSurvey(survey.id)"
        )
          .row.select-content(:class="getSurvey().id === survey.id ? 'font-color-red' : ''")
            .align-self-center.text-left.survey-name(:class="{'col-7': !isExpanded, 'col-10': isExpanded}")
              span.survey-title.dropdown-items.w-100
                | {{survey.name}}
              span.survey-date.w-100
                | {{survey.start_at | moment('YYYY.MM.DD')}}
                | - 
                | {{survey.end_at | moment('YYYY.MM.DD')}}
                span.survey-id.text-right
                  | ID: {{survey.serial_no}}
            div(:class="{'col-5': !isExpanded, 'col-2': isExpanded}")
              div.opinions-bl
                span.opinions-title
                  | 回答者数
                span
                 | {{survey.responders_count | separate}}
    .survey-data(:class="{'col-5': !isOpened, 'col-4': isOpened}")
      //- span
      //-   | 分析対象意見数
      //- span.survey-data-value.mr-4
      //-   | {{getSurvey().opinions_count | separate}}
      //- span
      //-   | 回答者数
      //- span.survey-data-value.mr-4
      //-   | {{getSurvey().survey_responses_count | separate}}
      span
        | 実施期間
      span.survey-date
        | {{getSurvey().start_at | moment('YYYY.MM.DD')}}
        | - 
        | {{getSurvey().end_at | moment('YYYY.MM.DD')}}
    .col-3.vc-analyse-tab-wrap
      .vc-analyse-first-tab-wrap
        .vc-analyse-top-tab
          //- router-link.nav-link.vc-midashi(
          //-   :to="{ name: 'u_dashboard' }"
          //-   :class="{'router-link-exact-active': this.$route.path.indexOf('dashboard')>0, 'router-link-active': this.$route.path.indexOf('dashboard')>0}"
          //- )
          //-   | ダッシュボード
          router-link.nav-link.vc-midashi.qualitative-link(
            :to="{ name: defaultQualitativePageName }"
            :class="{'router-link-exact-active': this.$route.path.indexOf('qualitative')>0, 'router-link-active': this.$route.path.indexOf('qualitative')>0}"
          )
            | 定性結果
          router-link.nav-link.vc-midashi.quantitative-link(
            :to="{ name: defaultQuantitativePageName }"
            :class="{'router-link-exact-active': this.$route.path.indexOf('quantitative')>0, 'router-link-active': this.$route.path.indexOf('quantitative')>0}"
          )
            | 定量結果
    //- .col-12(v-if="this.$route.path.indexOf('team')>0 || this.$route.path.indexOf('compare')>0")
    //-   .vc-top-tab.container
    //-     router-link.nav-link.vc-midashi(
    //-       :to="{ name: 'u_team_analyse' }"
    //-       :class="{'active': this.$route.path.indexOf('team')>0}")
    //-       | 分析対象のチーム
    //-     router-link.nav-link.vc-midashi(
    //-       :to="{ name: 'u_compare_analyse' }"
    //-       :class="{'active': this.$route.path.indexOf('compare')>0}")
    //-       | 他のチームとの比較

</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import dc from '../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {},

  data() {
    return {
      processing: false,
      defaultQualitativePageName: '',
      defaultQuantitativePageName: '',
      isOpened: false,
      isExpanded: false
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_surveys', ['surveys']),
    ...mapState('pages/u_surveys/select', ['surveyId']),
    ...mapState('resources/u_analyse', ['started']),
    ...mapState('resources/u_control_settings', ['controlSettings'])
  },

  watch: {
    surveys: function() {
      if (!this.surveyId) {
        const vcd = defaultCookie.get();
        if (vcd && vcd['surveyId'] && this.surveyId != vcd['surveyId']) {
          for (let survey of this.surveys) {
            if (survey.id == vcd['surveyId']) {
              this.setSurveyId(survey.id);
              this.setQuestions(survey.questions);
              return;
            }
          }
          defaultCookie.remove();
        }
        this.setSurveyId(this.surveys[0].id);
        this.setQuestions(this.surveys[0].questions);
      }
    },
    surveyId() {
      // this.setSurvey(this.surveyId);
      // if (this.started) this.transitToDefaultPage();
    },
    controlSettings() {
      const qual_cs = this.controlSettings.default_qualitative_menu;
      const quan_cs = this.controlSettings.default_quantitative_menu;
      this.defaultQualitativePageName = qual_cs.enabled
        ? 'u_' + qual_cs.values
        : 'u_qualitative_emotion_classifications_team';
      this.defaultQuantitativePageName = quan_cs.enabled
        ? 'u_' + quan_cs.values
        : 'u_quantitative_analyse';
    }
  },

  created() {
    this.loadSurveys();
    this.loadControlSettings();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_surveys', ['loadSurveys']),
    ...mapActions('resources/u_quantitative', ['loadQuestions']),
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapMutations('pages/u_surveys/select', ['setSurveyId', 'setIsTopic']),
    ...mapMutations('resources/u_quantitative', ['setQuestions']),

    getSurvey: function() {
      const surveys = this.surveys;
      for (const k in surveys) {
        if (surveys[k].id == this.surveyId) return surveys[k];
      }
      return {};
    },
    setSurvey: function(surveyId) {
      this.setSurveyId(surveyId);
      defaultCookie.set({ surveyId: surveyId });
      const survey = this.getSurvey();
      this.setQuestions(survey.questions);
    },
    transitToDefaultPage() {
      const isQualitative = this.$route.path.indexOf('qualitative') > 0;
      if (isQualitative) {
        const isDefaultPage =
          this.$route.name === this.defaultQualitativePageName;
        if (isDefaultPage) {
          const vcd = defaultCookie.get();
          if (vcd['surveyId'] || this.surveyId)
            this.loadQuestions({ survey_id: vcd['surveyId'] || this.surveyId });
        } else this.$router.push({ name: this.defaultQualitativePageName });
      } else this.$router.push({ name: this.defaultQuantitativePageName });
    },
    toggleIsOpened() {
      this.isOpened = !this.isOpened;
    },
    toggleIsExpanded() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>

<style lang="scss">
.vc {
  a.help {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: absolute;
    top: -18px;
    right: 14px;
    color: #444;
    font-weight: bold;
    text-decoration: none;
    .icon {
      margin-right: 5px;
      font-size: 1.2rem;
    }
    &:hover {
      color: #444;
      opacity: 0.8;
    }
  }
}
#dropdown-dropup.survey-select {
  border: none;
  border-radius: inherit;
  margin-left: 0 !important;
  .survey-title {
    font-size: 16px;
    font-weight: bolder;
    padding-bottom: 10px;
  }
  span {
    display: block;
  }
  .select-content {
    line-height: 16px;
    .survey-name {
      span {
        width: 220px;
        white-space: normal;
        line-height: 1.1;
      }
      margin-bottom: 10px;
    }
  }
  .opinions-bl {
    display: inline-block;
    min-width: 120px;
    text-align: center;
    border: 1px solid #f0f0f0;
    padding: 0.5rem;
    font-size: 22px;
    line-height: 20px;
    margin-left: 15px;
    .opinions-title {
      font-size: 11px;
    }
  }
  &.dropdown {
    .dropdown-toggle:after {
      font-size: 32px;
      color: #a3a3a3;
    }
  }
  .dropdown-menu {
    width: 410px;
    padding: 8px 0;
    max-height: 400px;
    overflow-y: auto;
    .dropdown-item {
      padding: 8px 15px 7px 20px;
    }
    .dropdown-item:hover {
      background: #dff3f1;
      .opinions-bl {
        border: 1px solid #e0e0e0;
      }
    }
    li:not(.topic-active) + li.topic-active {
      position: relative;
      margin-top: 10px;
      padding-top: 10px;
    }

    li:not(.topic-active) + li.topic-active::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 5%;
      width: 90%;
      height: 1px;
      background-color: #efefef;
    }
  }
}
.select-content .survey-id {
  position: absolute;
  bottom: 0;
}
.font-color-red,
#dropdown-dropup.survey-select .font-color-red .survey-date {
  color: #f3154a !important;
}
.display-title span.survey-title {
  padding: 0 !important;
}
.vc-main-select-wrap {
  margin-bottom: 0 !important;
}
.ml-set {
  margin-left: 25.5px;
}
.dropdown-toggle {
  padding-left: 0 !important;
  // padding-bottom: 5px !important;
}
// #dropdown-dropup.survey-select.dropdown .dropdown-toggle:after {
//   position: absolute;
//   right: -73px;
// }
.vc-analyse-top-tab-wrap {
  padding: 0 0 10px 0;
}
.vc-analyse-top-tab {
  display: flex;
}
.vc .vc-analyse-top-tab a {
  display: inline-block;
  color: #444;
  font-size: 12px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #f0f0f0;
  padding: 0 25px;
  transition: all 0.15s ease;
}
.vc-analyse-tab-wrap {
  display: flex;
  align-items: center;
}
.vc-analyse-first-tab-wrap {
  position: absolute;
  right: -60px;
}
#dropdown-dropup.survey-select.expanded .dropdown-menu {
  width: 80vw;
  max-width: 80vw;
  height: 80vh;
  max-height: 80vh;
}
.b-dropdown-text {
  padding-bottom: 25px !important;
}
.b-dropdown-text .expand-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.b-dropdown-text .expand-btn button.btn {
  border: 1px solid #dde4eb !important;
}
#dropdown-dropup.survey-select .survey-title.dropdown-items {
  width: 100% !important;
  max-width: 100% !important;
}
</style>
