<template lang="pug">
  .content-wrapper(v-if="dataLoaded")
    .content-header.d-flex.flex-column.flex-md-row
      h4.mb-0
        | {{ question.title }}
      .wrapper.ml-0.ml-md-auto.my-auto.d-flex.align-items-center.pt-4.pt-md-0
        //- .mr-3
        //-   i.fas.fa-search.search-icon
        //-   input.form-control.search-input(
        //-     type="text"
        //-     v-model="searchFilter"
        //-     placeholder="タイトル、グループコードを入力"
        //-     @keyup.enter.exact="onKeyupEnter($event)"
        //-     @keydown.enter.exact="onKeydownEnter($event)"
        //-     @compositionstart="onCompositionStart"
        //-   )
        router-link.btn.btn-success.btn-sm.ml-auto(:to="{ name: 'x_new_quantitative_question' }")
          | 定量設問作成
    .row
      .col-md-12.grid-margin.stretch-card.clearfix
        .card
          .card-body.pb-0
            .table-responsive
              table.table.table-striped.admin-table
                thead
                  tr
                    th
                      | ID
                    th
                      | 設問文
                    th &nbsp
                tbody
                  tr(v-for="qq in quantitativeQuestionsList")
                    td
                      | {{ qq.id }}
                    td.title
                      | {{ qq.question_text }}
                    td.text-right
                      button.btn.btn-secondary.dropdown
                        a.dropdown-toggle.no-style(
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        )
                          | アクション
                        .dropdown-menu(aria-labelledby="dropdownMenuButton")
                          router-link.dropdown-item.mt-1(
                            :to="{ name: 'x_edit_quantitative_question', params : { survey_id: survey.id, question_id: question.id, id: qq.id } }")
                              | {{ $t('actions.edit') }}
                          a.dropdown-item.mt-1(href="" @click.prevent="showDeleteModal(qq)")
                            | {{ $t('actions.delete') }}
                          router-link.dropdown-item.mt-1(
                            :to="{ name: 'x_quantitative_answers', params : { survey_id: survey.id, question_id: question.id, id: qq.id } }")
                              | 選択肢管理

            .mt-1.float-right
              paginate(
                :page-count="pageParams.pages"
                :click-handler="clickCallback"
                containerClass="pagination"
                pageClass="page-item"
                pageLinkClass="page-link"
                prevText="«"
                prevClass="page-item"
                prevLinkClass="page-link"
                nextText="»"
                nextClass="page-item"
                nextLinkClass="page-link"
              )
    QuantitativeQuestionDeleteModal(:deleteTarget="deleteTarget")
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Paginate from 'vuejs-paginate';
import QuantitativeQuestionDeleteModal from '../../../components/x/quantitative_questions/QuantitativeQuestionDeleteModal';

export default {
  components: {
    Paginate,
    QuantitativeQuestionDeleteModal
  },
  data() {
    return {
      defaultPer: 50,
      searchFilter: '',
      isComposing: false,
      deleteTarget: {},
      dataLoaded: false
    };
  },
  computed: {
    ...mapState('resources/x_quantitative_questions', [
      'quantitativeQuestionsList',
      'survey',
      'question'
    ]),
    ...mapState('pages/x_quantitative_questions/list/pagination', [
      'pageParams'
    ])
  },
  created() {
    this.setPer(this.defaultPer);
    this.load();
  },
  mounted() {},
  methods: {
    ...mapActions('resources/x_quantitative_questions', [
      'loadQuantitativeQuestions'
    ]),
    ...mapMutations('pages/x_quantitative_questions/list/pagination', [
      'setTotal',
      'setPages',
      'setPage',
      'setPer'
    ]),
    load() {
      const params = {
        survey_id: this.$route.params.survey_id,
        question_id: this.$route.params.id,
        pageParams: Object.assign(this.pageParams, {
          filter: this.searchFilter
        })
      };
      this.loadQuantitativeQuestions(params).then(response => {
        this.dataLoaded = true;
        this.setTotal(response.body.page_options.total);
        this.setPages(response.body.page_options.pages);
      });
    },
    clickCallback: function(pageNum) {
      this.setPage(pageNum);
      this.load();
    },
    onKeyupEnter(e) {
      if (e.keyCode !== 13) return;
      if (this.isComposing) this.onCompositionEnd();
      else {
        e.preventDefault();
        this.load();
      }
    },
    onKeydownEnter(e) {
      if (e.keyCode !== 13) return;
      e.preventDefault();
    },
    onCompositionStart() {
      this.isComposing = true;
    },
    onCompositionEnd() {
      this.isComposing = false;
    },
    showDeleteModal(quantitativeQuestion) {
      this.deleteTarget = quantitativeQuestion;
      this.$bvModal.show('quantitative-question-delete-modal');
    }
  }
};
</script>
<style scoped>
.admin-table td.title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 460px;
}
.search-input {
  width: 300px;
  padding-left: 30px;
}
.search-icon {
  margin-left: 10px;
  margin-top: 8px;
  position: absolute;
}
</style>
