<template lang="pug">
  b-modal(
    id="inquiry-stop-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    :title="$t('admin.inquiries.stopModal.title')"
  )
    b-container
      p
        | アカウントグループ：{{ stopTarget.account_group_name }}
        br
        | 調査名：{{ stopTarget.survey_name }}
        br
        | 回答者ID：{{ stopTarget.responder ? stopTarget.responder.code : '-' }}
        br
        | 質問者：{{ stopTarget.user ? stopTarget.user.email : '-' }}
      p
        | {{ $t('admin.inquiries.stopModal.confirm') }}
    div(slot="modal-footer")
      button.btn.btn-secondary(@click="close")
        | {{ $t('actions.close') }}
      button.btn.btn-danger.ml-2(@click.prevent="stop")
        | {{ $t('admin.inquiries.stopModal.stop') }}
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    stopTarget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions('resources/x_inquiries', ['stopInquiry']),

    close() {
      this.$bvModal.hide('inquiry-stop-modal');
    },

    stop() {
      this.stopInquiry(this.stopTarget.id).then(response => {
        if (response.status === 200) {
          this.$bvModal.hide('inquiry-stop-modal');
          this.$router.go({ name: 'x_inquiries' });
        }
      });
    }
  }
};
</script>
