<template lang="pug">
  .container-fruid
    .main-panel
      section.vc-content.vc-quantitative
        .vc-top-col
          .container
            SurveySelect
          .container.vc-dashboard-sub-select-wrap
            AnalyseNav
        .vc-main-container.container
          //- .vc-dashboard-box.ranking.mb-4
            .container-fluid
              div(v-if="mainView")
                .row
                  .col-12.vc-midashi-wrap
                    h3.vc-midashi.mb-4 ランキング
                    span.vc-midashi-desc.mb-4 意見分類ごとに、ネガティブな意見/ポジティブな意見が多いチームを発見できます。
                .row
                  .col-12.text-right.mt-3.pr-3
                    span.small
                      | ※ 割合の算出方法：該当チームのある意見分類に関する{{sortType == 0 ? 'ネガティブ' : 'ポジティブ'}}意見数
                      | ／
                      | 該当チームの全体意見数
                .row.vc-dashboard-ranking_submenu
                  .col-6.ml-4.mt-3.pl-3
                    .vc-dashboard-ranking_submenu-row
                      .col-4.mb-2 ランキング表示階層
                      .col-8.mb-2
                        ul.vc-parameter-nav.text-left
                          li
                            a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': orgHierarchy === 1}" @click="setOrgHierarchy(1)") 事業
                          li
                            a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': orgHierarchy === 2}" @click="setOrgHierarchy(2)") 拠点
                          li
                            a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': orgHierarchy === 3}" @click="setOrgHierarchy(3)") 部署
                    .vc-dashboard-ranking_submenu-row
                      .col-4.mb-2 表示単位
                      .col-8.mb-2
                        ul.vc-parameter-nav.text-left
                          li
                            a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': displayType === 0}" @click="setDisplayType(0)") 率
                          li
                            a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': displayType === 1}" @click="setDisplayType(1)") 件数
                    .vc-dashboard-ranking_submenu-row.vc-top-col.pt-0.mt-1
                      .col-4.mb-4 チームでフィルタ
                      .col-12.mb-4
                        .row
                          .vc-sub-select-box
                            select(
                              class="form-control vc-sub-select ranking-team-filter1"
                              v-model="selectedOrg[0]"
                              :disabled="orgHierarchy === 1"
                            )
                              option(value="undefined")
                                | すべて
                              option(
                                v-for="organization in organizations[0]"
                                :value="organization.id"
                                :key="organization.id"
                              )
                                | {{organization.name}}
                          .vc-sub-select-box
                            select(
                              class="form-control vc-sub-select ranking-team-filter2"
                              v-model="selectedOrg[1]"
                              :disabled="orgHierarchy !== 3"
                            )
                              option(value="undefined")
                                | すべて
                              option(
                                v-for="organization in organizations[selectedOrg[0]]"
                                :value="organization.id"
                                :key="organization.id"
                              )
                                | {{organization.name}}
                  .col-5.mt-3.pr-3.text-right
                    ul.vc-parameter-nav.mb-4
                      li
                        a.vpn-nega(href="javascript:void(0)" :class="{'active': sortType === 0}" @click="setSortType(0)") ネガティブ順
                      li
                        a.vpn-posi(href="javascript:void(0)" :class="{'active': sortType === 1}" @click="setSortType(1)") ポジティブ順
                .row.vc-dashboard-box-content(
                  v-if="started"
                  :class="{'ranking-negative': sortType === 0, 'ranking-positive': sortType === 1}"
                )
                  .col-6.vc-dashboard-box-ranking(v-for="type in opinionType")
                    h4.vc-midashi.mt-3.mb-2
                      | {{type[1]}}
                    .row.vc-dashboard-ranking-board.vc-dashboard-ranking-header.pl-3.pr-3
                      .col-1.p-0.text-center
                        .row
                          .col-6.p-0.text-center 今回
                          .col-6.p-0.text-center 変動
                      .col-6.p-0.offset-1 チーム
                      .col-2.p-0.negaposi-value-left
                        | {{sortType === 0 ? 'ネガティブ意見' + (displayType === 0 ? '率' : '数') : 'ポジティブ意見' + (displayType === 0 ? '率' : '数')}}
                      .col-2.p-0.negaposi-value-right
                        | {{sortType === 0 ? 'ポジティブ意見' + (displayType === 0 ? '率' : '数') : 'ネガティブ意見' + (displayType === 0 ? '率' : '数')}}
                    .row.vc-dashboard-ranking-board.pl-3.pr-3(v-for="attr in rankingOfType(type[1], false)")
                      .col-1.p-0
                        .row(v-if="displayType === 0")
                          .col-6
                            | {{sortType === 0 ? attr.nega_rate_rank : attr.posi_rate_rank}}
                          .col-6.p-0.text-center(
                            v-html='fluctuation(sortType === 0 ? attr.nega_rate_rank_fluctuation : attr.posi_rate_rank_fluctuation,attr.prev_display)'
                          )
                        .row(v-else)
                          .col-6
                            | {{sortType === 0 ? attr.nega_count_rank : attr.posi_count_rank}}
                          .col-6.p-0.text-center(
                            v-html='fluctuation(sortType === 0 ? attr.nega_count_rank_fluctuation : attr.posi_count_rank_fluctuation,attr.prev_display)'
                          )
                      .col-6.p-0.offset-1
                        | {{attr.organization_name}}
                      .col-2.negaposi-value-left
                        .row
                          .col-5.p-0
                            a(
                              href="javascript:void(0)"
                              @click="toOpinionSearch(attr.organization_id, type[0], emotionIds[sortType === 0 ? 'ネガティブ' : 'ポジティブ'])"
                            )
                              svg.vc-dashboard-to-opinion-search(
                                xmlns="http://www.w3.org/2000/svg"
                                enable-background="new 0 0 24 24"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#000000"
                              )
                                g
                                  rect(fill="none" height="24" width="24")
                                g
                                  g
                                    rect(height="2" width="9" x="13" y="7")
                                    rect(height="2" width="9" x="13" y="15")
                                    rect(height="2" width="6" x="16" y="11")
                                    polygon(points="13,12 8,7 8,11 2,11 2,13 8,13 8,17")
                          .col-7.p-0.pl-1.fluctuation-col(v-if="sortType === 0")
                            .row(v-if="displayType === 0")
                              .col-5
                                | {{toFloat(attr.nega_rate)}}
                              .col-6
                                | {{'(' + (attr.nega_rate_fluctuation * -1 > 0 ? '+' : '') + (toFloat(attr.nega_rate_fluctuation) * -1) + ')'}}
                            .row(v-else)
                              .col-5
                                | {{attr.nega_count}}
                              .col-6
                                | {{'(' + (attr.nega_count_fluctuation * -1 > 0 ? '+' : '') + (attr.nega_count_fluctuation * -1) + ')'}}
                          .col-7.p-0.pl-1.fluctuation-col(v-else)
                            .row(v-if="displayType === 0")
                              .col-5
                                | {{toFloat(attr.posi_rate)}}
                              .col-6
                                | {{'(' + (attr.posi_rate_fluctuation * -1 > 0 ? '+' : '') + (toFloat(attr.posi_rate_fluctuation) * -1) + ')'}}
                            .row(v-else)
                              .col-5
                                | {{attr.posi_count}}
                              .col-6
                                | {{'(' + (attr.posi_count_fluctuation * -1 > 0 ? '+' : '') + (attr.posi_count_fluctuation * -1) + ')'}}
                      .col-2.negaposi-value-right
                        .row
                          .col-5.p-0
                            a(
                              href="javascript:void(0)"
                              @click="toOpinionSearch(attr.organization_id, type[0], emotionIds[sortType === 0 ? 'ポジティブ' : 'ネガティブ'])"
                            )
                              svg.vc-dashboard-to-opinion-search(
                                xmlns="http://www.w3.org/2000/svg"
                                enable-background="new 0 0 24 24"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#000000"
                              )
                                g
                                  rect(fill="none" height="24" width="24")
                                g
                                  g
                                    rect(height="2" width="9" x="13" y="7")
                                    rect(height="2" width="9" x="13" y="15")
                                    rect(height="2" width="6" x="16" y="11")
                                    polygon(points="13,12 8,7 8,11 2,11 2,13 8,13 8,17")
                          .col-7.p-0.pl-1.fluctuation-col(v-if="sortType === 0")
                            .row(v-if="displayType === 0")
                              .col-5
                                | {{toFloat(attr.posi_rate)}}
                              .col-6
                                | {{'(' + (attr.posi_rate_fluctuation * -1 > 0 ? '+' : '') + (toFloat(attr.posi_rate_fluctuation) * -1) + ')'}}
                            .row(v-else)
                              .col-5
                                | {{attr.posi_count}}
                              .col-6
                                | {{'(' + (attr.posi_count_fluctuation * -1 > 0 ? '+' : '') + (attr.posi_count_fluctuation * -1) + ')'}}
                          .col-7.p-0.pl-1.fluctuation-col(v-else)
                            .row(v-if="displayType === 0")
                              .col-5
                                | {{toFloat(attr.nega_rate)}}
                              .col-6
                                | {{'(' + (attr.nega_rate_fluctuation * -1 > 0 ? '+' : '') + (toFloat(attr.nega_rate_fluctuation) * -1) + ')'}}
                            .row(v-else)
                              .col-5
                                | {{attr.nega_count}}
                              .col-6
                                | {{'(' + (attr.nega_count_fluctuation * -1 > 0 ? '+' : '') + (attr.nega_count_fluctuation * -1) + ')'}}
                    .row.vc-dashboard-ranking-more
                      .col-12.text-right
                        a.btn.btn-primary.vc-primary-btn.gray-btn(href="javascript:void(0)" @click="more(type)") more
                div(v-else)
                  .row.vc-dashboard-box-content
                    .col-12
                      p.mt-4 読み込み中です。しばらくお待ちください。
              div(v-else)
                .row
                  .col-12.mb-4.ranking-more-nav
                    .more-exit
                      a.btn.btn-primary.vc-primary-btn(href="javascript:void(0)" @click="backToMain()") 全意見分類ランキングに戻る
                .row.vc-dashboard-ranking_submenu
                  .col-5.ml-1.mr-4
                    .vc-dashboard-ranking_submenu-row
                      .col-5.mb-2 ランキング表示階層
                      .col-7.mb-2
                        ul.vc-parameter-nav.text-left
                          li
                            a.vpn-all.vc-ranking-btn.disabled-btn(
                              href="javascript:void(0)"
                              :class="{'active': orgHierarchy === 1}"
                              @click="setOrgHierarchy(1)"
                            )
                              | 事業
                          li
                            a.vpn-all.vc-ranking-btn.disabled-btn(
                              href="javascript:void(0)"
                              :class="{'active': orgHierarchy === 2}"
                              @click="setOrgHierarchy(2)"
                            )
                              | 拠点
                          li
                            a.vpn-all.vc-ranking-btn.disabled-btn(
                              href="javascript:void(0)"
                              :class="{'active': orgHierarchy === 3}"
                              @click="setOrgHierarchy(3)"
                            )
                              | 部署
                    .vc-dashboard-ranking_submenu-row
                      .col-5.mb-2 表示単位
                      .col-7.mb-2
                        ul.vc-parameter-nav.text-left
                          li
                            a.vpn-all.vc-ranking-btn.disabled-btn(
                              href="javascript:void(0)"
                              :class="{'active': displayType === 0}"
                              @click="setDisplayType(0)"
                            )
                              | 率
                          li
                            a.vpn-all.vc-ranking-btn.disabled-btn(
                              href="javascript:void(0)"
                              :class="{'active': displayType === 1}"
                              @click="setDisplayType(1)"
                            )
                              | 件数
                    .vc-dashboard-ranking_submenu-row.vc-top-col.pt-0.mt-1
                      .col-5.mb-4 チームでフィルタ
                      .col-12.mb-4
                        .row
                          .vc-sub-select-box
                            select(
                              class="form-control vc-sub-select ranking-team-filter1"
                              v-model="selectedOrg[0]"
                              disabled
                            )
                              option(value="undefined")
                                | すべて
                              option(
                                v-for="organization in organizations[0]"
                                :value="organization.id"
                                :key="organization.id"
                              )
                                | {{organization.name}}
                          .vc-sub-select-box
                            select(
                              class="form-control vc-sub-select ranking-team-filter2"
                              v-model="selectedOrg[1]"
                              disabled
                            )
                              option(value="undefined")
                                | すべて
                              option(
                                v-for="organization in organizations[selectedOrg[0]]"
                                :value="organization.id"
                                :key="organization.id"
                              )
                                | {{organization.name}}
                  .col-5.mt-4.pr-3.text-right
                    ul.vc-parameter-nav.mb-4
                      li
                        a.vpn-nega.disabled-btn(href="javascript:void(0)" :class="{'active': sortType === 0}" @click="setSortType(0)") ネガティブ順
                      li
                        a.vpn-posi.disabled-btn(href="javascript:void(0)" :class="{'active': sortType === 1}" @click="setSortType(1)") ポジティブ順
                .row.vc-dashboard-box-content(:class="{'ranking-negative': sortType === 0, 'ranking-positive': sortType === 1}")
                  .col-12.vc-dashboard-box-ranking.ranking-more-content
                    h4.vc-midashi.mt-3.mb-2
                      | {{currentType[1]}}
                    .row.vc-dashboard-ranking-board.vc-dashboard-ranking-header.pl-3.pr-3
                      .col-1.p-0.text-center
                        .row
                          .col-6.p-0.text-center 今回
                          .col-6.p-0.text-center 変動
                      .col-6.p-0.offset-1 チーム
                      .col-2.p-0
                        | {{sortType === 0 ? 'ネガティブ意見' + (displayType === 0 ? '率' : '数') : 'ポジティブ意見' + (displayType === 0 ? '率' : '数')}}
                      .col-2.p-0
                        | {{sortType === 0 ? 'ポジティブ意見' + (displayType === 0 ? '率' : '数') : 'ネガティブ意見' + (displayType === 0 ? '率' : '数')}}
                    .row.vc-dashboard-ranking-board.pl-3.pr-3(v-for="attr in rankingOfType(currentType[1], true)")
                      .col-1.p-0.text-center
                        .row(v-if="displayType === 0")
                          .col-6
                            | {{sortType === 0 ? attr.nega_rate_rank : attr.posi_rate_rank}}
                          .col-6(
                            v-html='fluctuation(sortType === 0 ? attr.nega_rate_rank_fluctuation : attr.posi_rate_rank_fluctuation,attr.prev_display)'
                          )
                        .row(v-else)
                          .col-6
                            | {{sortType === 0 ? attr.nega_count_rank : attr.posi_count_rank}}
                          .col-6(
                            v-html='fluctuation(sortType === 0 ? attr.nega_count_rank_fluctuation : attr.posi_count_rank_fluctuation,attr.prev_display)'
                          )
                      .col-6.p-0.offset-1
                        | {{attr.organization_name}}
                      .col-2.p-0
                        .row
                          .col-3
                            a(
                              href="javascript:void(0)"
                              @click="toOpinionSearch(attr.organization_id, currentType[0], emotionIds[sortType === 0 ? 'ネガティブ' : 'ポジティブ'])"
                            )
                              svg.vc-dashboard-to-opinion-search(
                                xmlns="http://www.w3.org/2000/svg"
                                enable-background="new 0 0 24 24"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#000000"
                              )
                                g
                                  rect(fill="none" height="24" width="24")
                                g
                                  g
                                    rect(height="2" width="9" x="13" y="7")
                                    rect(height="2" width="9" x="13" y="15")
                                    rect(height="2" width="6" x="16" y="11")
                                    polygon(points="13,12 8,7 8,11 2,11 2,13 8,13 8,17")
                          .col-6.fluctuation-col(v-if="sortType === 0")
                            .row(v-if="displayType === 0")
                              .col-5
                                | {{toFloat(attr.nega_rate)}}
                              .col-6
                                | {{'(' + (attr.nega_rate_fluctuation * -1 > 0 ? '+' : '') + (toFloat(attr.nega_rate_fluctuation) * -1) + ')'}}
                            .row(v-else)
                              .col-5
                                | {{attr.nega_count}}
                              .col-6
                                | {{'(' + (attr.nega_count_fluctuation * -1 > 0 ? '+' : '') + (attr.nega_count_fluctuation * -1) + ')'}}
                          .col-6.fluctuation-col(v-else)
                            .row(v-if="displayType === 0")
                              .col-5
                                | {{toFloat(attr.posi_rate)}}
                              .col-6
                                | {{'(' + (attr.posi_rate_fluctuation * -1 > 0 ? '+' : '') + (toFloat(attr.posi_rate_fluctuation) * -1) + ')'}}
                            .row(v-else)
                              .col-5
                                | {{attr.posi_count}}
                              .col-6
                                | {{'(' + (attr.posi_count_fluctuation * -1 > 0 ? '+' : '') + (attr.posi_count_fluctuation * -1) + ')'}}
                      .col-2.p-0
                        .row
                          .col-3
                            a(
                              href="javascript:void(0)"
                              @click="toOpinionSearch(attr.organization_id, currentType[0], emotionIds[sortType === 0 ? 'ポジティブ' : 'ネガティブ'])"
                            )
                              svg.vc-dashboard-to-opinion-search(
                                xmlns="http://www.w3.org/2000/svg"
                                enable-background="new 0 0 24 24"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#000000"
                              )
                                g
                                  rect(fill="none" height="24" width="24")
                                g
                                  g
                                    rect(height="2" width="9" x="13" y="7")
                                    rect(height="2" width="9" x="13" y="15")
                                    rect(height="2" width="6" x="16" y="11")
                                    polygon(points="13,12 8,7 8,11 2,11 2,13 8,13 8,17")
                          .col-6.fluctuation-col(v-if="sortType === 0")
                            .row(v-if="displayType === 0")
                              .col-5
                                | {{toFloat(attr.posi_rate)}}
                              .col-6
                                | {{'(' + (attr.posi_rate_fluctuation * -1 > 0 ? '+' : '') + (toFloat(attr.posi_rate_fluctuation) * -1) + ')'}}
                            .row(v-else)
                              .col-5
                                | {{attr.posi_count}}
                              .col-6
                                | {{'(' + (attr.posi_count_fluctuation * -1 > 0 ? '+' : '') + (attr.posi_count_fluctuation * -1) + ')'}}
                          .col-6.fluctuation-col(v-else)
                            .row(v-if="displayType === 0")
                              .col-5
                                | {{toFloat(attr.nega_rate)}}
                              .col-6
                                | {{'(' + (attr.nega_rate_fluctuation * -1 > 0 ? '+' : '') + (toFloat(attr.nega_rate_fluctuation) * -1) + ')'}}
                            .row(v-else)
                              .col-5
                                | {{attr.nega_count}}
                              .col-6
                                | {{'(' + (attr.nega_count_fluctuation * -1 > 0 ? '+' : '') + (attr.nega_count_fluctuation * -1) + ')'}}
        //- .vc-main-container.container(v-if="isTopic")
          .vc-content-col
            .container
              | トピック調査については、この画面はご利用できません。調査選択から、トピック調査以外の調査を選択ください。
</template>

<script>
// import { mapState, mapActions, mapMutations } from 'vuex';
import SurveySelect from '../../../components/u/SurveySelect';
import AnalyseNav from '../../../components/u/AnalyseNav';
// import dc from '../../../lib/default_cookie';
// const defaultCookie = new dc();

export default {
  components: {
    SurveySelect,
    AnalyseNav
  },

  data() {
    return {
      // emotionType: 0,
      // orgHierarchy: null,
      // displayType: null,
      // sortType: null,
      // mainView: true,
      // currentType: [],
      // selectedOrg: [],
      // currentRanking: []
    };
  },

  computed: {
    // ...mapState('config', ['operator']),
    // ...mapState('resources/u_dashboard', [
    //   'ranking',
    //   'opinionType',
    //   'emotionIds',
    //   'organizations',
    //   'parentIdByIds'
    // ]),
    // ...mapState('resources/u_control_settings', ['controlSettings']),
    // ...mapState('pages/u_organizations/select', ['beforeSelected', 'started']),
    // ...mapState('pages/u_surveys/select', ['surveyId', 'isTopic'])
  },

  watch: {
    // orgHierarchy: function() {
    //   const vcd = defaultCookie.get();
    //   if (vcd['orgHierarchy'] !== this.orgHierarchy)
    //     defaultCookie.set({ orgHierarchy: this.orgHierarchy });
    // },
    // displayType: function() {
    //   const vcd = defaultCookie.get();
    //   if (vcd['displayType'] !== this.displayType)
    //     defaultCookie.set({ displayType: this.displayType });
    // },
    // sortType: function() {
    //   const vcd = defaultCookie.get();
    //   if (vcd['sortType'] !== this.sortType)
    //     defaultCookie.set({ sortType: this.sortType });
    // },
    // currentType: function() {
    //   const vcd = defaultCookie.get();
    //   if (vcd['currentType'] !== this.currentType)
    //     defaultCookie.set({ currentType: this.currentType });
    // },
    // selectedOrg: function() {
    //   this.filterCurrentRanking();
    // },
    // surveyId: function() {
    //   this.load();
    // }
  },

  created() {
    // this.setStarted(false);
    // this.loadControlSettings().then(() => {
    //   this.defaultSelected();
    // });
    // const vcd = defaultCookie.get();
    // const surveyId = vcd['surveyId'];
    // if (surveyId) {
    //   this.setSurveyId(surveyId);
    // }
  },

  mounted() {
    // if (this.ranking.length > 0) this.load();
  },

  methods: {
    // ...mapActions('resources/u_dashboard', [
    //   'loadRanking',
    //   'loadOrganizations'
    // ]),
    // ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    // ...mapMutations('resources/u_dashboard', ['setOrganizations']),
    // ...mapMutations('pages/u_surveys/select', ['setSurveyId']),
    // ...mapMutations('pages/u_organizations/select', [
    //   'setSelect',
    //   'setStarted'
    // ]),
    // load() {
    //   if (this.ranking.length < 1) {
    //     this.loadRanking({ survey_id: this.surveyId }).then(() => {
    //       this.defaultOrgSelected();
    //     });
    //   } else {
    //     this.defaultOrgSelected();
    //     this.filterCurrentRanking();
    //   }
    // },
    // rankingOfType: function(type, more) {
    //   let that = this;
    //   const displayableNum = this.displayableNum();
    //   let ranks = this.currentRanking.filter(
    //     rank =>
    //       rank.opinion_type === type &&
    //       rank.organization_hierarchy === this.orgHierarchy &&
    //       rank.responder_count > displayableNum
    //   );
    //   ranks = ranks.sort(function(a, b) {
    //     if (that.displayType === 0) {
    //       if (that.sortType === 0) {
    //         if (a.nega_rate_rank < b.nega_rate_rank) return -1;
    //         if (a.nega_rate_rank > b.nega_rate_rank) return 1;
    //       } else {
    //         if (a.posi_rate_rank < b.posi_rate_rank) return -1;
    //         if (a.posi_rate_rank > b.posi_rate_rank) return 1;
    //       }
    //     } else {
    //       if (that.sortType === 0) {
    //         if (a.nega_count_rank < b.nega_count_rank) return -1;
    //         if (a.nega_count_rank > b.nega_count_rank) return 1;
    //       } else {
    //         if (a.posi_count_rank < b.posi_count_rank) return -1;
    //         if (a.posi_count_rank > b.posi_count_rank) return 1;
    //       }
    //     }
    //     return 0;
    //   });
    //   if (!more) ranks = ranks.slice(0, 10);
    //   return ranks;
    // },
    // fluctuation: function(fluc, prev_display) {
    //   let newFluc = fluc * -1;
    //   if (!prev_display) {
    //     return '<span>-</span>';
    //   } else if (Math.sign(newFluc) === 1) {
    //     if (newFluc >= 10)
    //       return (
    //         '<span class="ranking-item-rankup ranking-item-number-hilight">' +
    //         this.fluctuationIcon('up') +
    //         newFluc +
    //         '</span>'
    //       );
    //     else
    //       return (
    //         '<span class="ranking-item-rankup">' +
    //         this.fluctuationIcon('up') +
    //         newFluc +
    //         '</span>'
    //       );
    //   } else if (Math.sign(newFluc) === -1) {
    //     if (newFluc <= -10)
    //       return (
    //         '<span class="ranking-item-rankdown ranking-item-number-hilight">' +
    //         this.fluctuationIcon('down') +
    //         fluc +
    //         '</span>'
    //       );
    //     else
    //       return (
    //         '<span class="ranking-item-rankdown">' +
    //         this.fluctuationIcon('down') +
    //         fluc +
    //         '</span>'
    //       );
    //   } else {
    //     return '<span>0</span>';
    //   }
    // },
    // fluctuationIcon(upDown) {
    //   if (upDown === 'up') {
    //     return (
    //       '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24"' +
    //       ' width="14px" fill="#000000"><rect fill="none" height="24" width="24"/><path d="M9,5v2h6.59L4,18.59L5.41,20L17,8.41V15h2V5H9z"/></svg>'
    //     );
    //   } else {
    //     return (
    //       '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24"' +
    //       ' width="14px" fill="#000000"><rect fill="none" height="24" width="24"/><path d="M19,9h-2v6.59L5.41,4L4,5.41L15.59,17H9v2h10V9z"/></svg>'
    //     );
    //   }
    // },
    // setOrgHierarchy: function(hierarchy) {
    //   this.orgHierarchy = hierarchy;
    //   if (hierarchy === 1) this.selectedOrg = [undefined, undefined];
    //   if (hierarchy !== 3) this.selectedOrg = [this.selectedOrg[0], undefined];
    // },
    // setDisplayType: function(type) {
    //   this.displayType = type;
    // },
    // setSortType: function(type) {
    //   this.sortType = type;
    // },
    // toFloat: function(rate) {
    //   if (rate % 1 === 0) {
    //     return parseFloat(rate).toFixed(1);
    //   } else {
    //     return rate;
    //   }
    // },
    // toOpinionSearch: function(
    //   organizationId,
    //   opinionClassificationId,
    //   emotionClassificationId
    // ) {
    //   defaultCookie.set({
    //     surveyId: this.surveyId,
    //     organizationId: organizationId
    //   });
    //   this.$router.push({
    //     name: 'u_opinion_search',
    //     params: {
    //       opinion_classification: opinionClassificationId,
    //       emotion_classification: emotionClassificationId,
    //       survey_id: this.surveyId,
    //       backToName: 'u_dashboard',
    //       mainView: this.mainView,
    //       default: true
    //     }
    //   });
    // },
    // backToMain: function() {
    //   this.mainView = true;
    // },
    // more: function(type) {
    //   this.mainView = false;
    //   this.currentType = type;
    //   document.documentElement.scrollTop = 0;
    // },
    // hierarchyName: function(hierarchy) {
    //   if (hierarchy === 1) return '事業';
    //   else if (hierarchy === 2) return '拠点';
    //   else return '部署';
    // },
    // defaultSelected: function() {
    //   defaultCookie.set({ surveyId: this.surveyId });
    //   let vcd = defaultCookie.get();
    //   const name = 'default_ranking_filter';
    //   let defaultHierarchy = 1;
    //   if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
    //     if (this.controlSettings[name]['values'] === 'base')
    //       defaultHierarchy = 2;
    //     else if (this.controlSettings[name]['values'] === 'department')
    //       defaultHierarchy = 3;
    //   }
    //   this.orgHierarchy = vcd['orgHierarchy'] || defaultHierarchy;
    //   this.displayType = vcd['displayType'] || 0;
    //   this.sortType = vcd['sortType'] || 0;
    //   this.currentType = vcd['currentType'] || [];
    //   this.mainView =
    //     this.$route.params.dMainView !== undefined
    //       ? this.$route.params.dMainView
    //       : true;
    // },
    // defaultOrgSelected: function() {
    //   const vcd = defaultCookie.get();
    //   let selectedOrg = [];
    //   const parentIdByIds = this.parentIdByIds;
    //   const organizations = this.organizations;
    //   const organizationId = vcd['organizationId'];
    //   if (organizations && parentIdByIds && organizationId) {
    //     selectedOrg = [organizationId];
    //     let targetId = organizationId;
    //     while (parentIdByIds[targetId]) {
    //       selectedOrg.unshift(parentIdByIds[targetId]);
    //       targetId = parentIdByIds[targetId];
    //     }
    //   }
    //   this.setSelect({ beforeSelected: selectedOrg });
    //   this.selectedOrg = selectedOrg.slice(0, 2);
    // },
    // displayableNum() {
    //   const name = 'number_of_displayable_data';
    //   if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
    //     return this.controlSettings[name]['values']
    //       ? this.controlSettings[name]['values']
    //       : 0;
    //   }
    //   return 0;
    // },
    // filterCurrentRanking() {
    //   const vcd = defaultCookie.get();
    //   let del_index = null;
    //   let del_count = 0;
    //   for (let i = 0; i < this.selectedOrg.length; i++) {
    //     if (this.selectedOrg[i] == 'undefined') this.selectedOrg[i] = '';
    //     if (del_index) {
    //       del_count++;
    //     } else {
    //       if (this.beforeSelected[i] != this.selectedOrg[i]) del_index = i + 1;
    //     }
    //   }
    //   if (del_index && del_count > 0) {
    //     this.selectedOrg.splice(del_index, del_count);
    //   }
    //   if (!this.selectedOrg.slice(-1)[0] && this.selectedOrg.length > 0)
    //     this.selectedOrg.pop();
    //   this.setSelect({ beforeSelected: Array.from(this.selectedOrg) });
    //   const selectedOrg = this.selectedOrg.slice(-1)[0];
    //   if (selectedOrg) {
    //     this.currentRanking = this.ranking.filter(rank =>
    //       rank.organization_ids.includes(selectedOrg)
    //     );
    //   } else {
    //     this.currentRanking = this.ranking;
    //   }
    //   if (vcd['organizationId'] !== selectedOrg)
    //     defaultCookie.set({ organizationId: selectedOrg });
    //   this.setStarted(true);
    // }
  }
};
</script>
