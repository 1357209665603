<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | 選択肢作成
  .row.flex-grow
    .col-6
      .card
        .card-body
          form
            error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
            validation-observer(v-slot="{ invalid }")
              validation-provider(name="code" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 選択肢コード
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="code"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="selectText" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 選択肢テキスト
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="selectText"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="selectNum" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 選択肢番号
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="selectNum"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="point" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | ポイント
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="point"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              .mt-1.float-right
                router-link.btn.btn-light.mr-2(:to="{ name: 'x_quantitative_questions' }")
                  | {{ $t('actions.cancel') }}
                button.btn.btn-success(
                  :disabled="processing || invalid"
                  @click.prevent="submit"
                )
                  | {{ $t('actions.create') }}

</template>
<script>
import { mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';

export default {
  components: {
    ErrorMessage
  },
  data() {
    return {
      code: '',
      selectText: '',
      selectNum: '',
      point: '',
      errorMessages: [],
      processing: false
    };
  },
  computed: {
    quantitativeAnswerParams() {
      return {
        quantitative_answer: {
          quantitative_question_id: this.$route.params.id,
          code: this.code,
          select_text: this.selectText,
          select_num: this.selectNum,
          point: this.point
        }
      };
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    ...mapActions('resources/x_quantitative_answers', [
      'createQuantitativeAnswer'
    ]),
    resetErrorMessages() {
      this.errorMessages = [];
    },
    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.createQuantitativeAnswer(this.quantitativeAnswerParams)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'x_quantitative_answers' });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
