<template lang="pug">
  b-modal(
    id="send-newsletter-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    title="メルマガの送信"
  )
    b-container
      p
        | {{$t('actions.confirm')}}
    div(slot="modal-footer")
      button.btn.btn-secondary(@click="close")
        | {{$t('actions.close')}}
      button.btn.btn-success.ml-2(@click.once="send")
        | {{$t('actions.send')}}
</template>
<script>
import { mapActions } from 'vuex';

export default {
  props: {
    target: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions('resources/x_newsletters', ['sendNewsletter']),
    close() {
      this.$bvModal.hide('send-newsletter-modal');
    },
    send() {
      this.sendNewsletter(this.target.id).then(response => {
        if (response.status === 200) {
          this.$router.push({ name: 'x_newsletters' });
        }
      });
    }
  }
};
</script>
