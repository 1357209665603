var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_vm._m(0),_c('div',{staticClass:"row flex-grow"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',[(_vm.errorMessages.length > 0)?_c('div',{staticClass:"alert alert-danger"},[_c('ul',{staticClass:"mb-0"},_vm._l((_vm.errorMessages),function(error){return _c('li',[_vm._v(_vm._s(error))])}),0)]):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"name":"accountGroup","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("アカウントグループ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.accountGroup),expression:"accountGroup"}],staticClass:"form-control",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.accountGroup=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.changeAccountGroup]}},_vm._l((_vm.accountGroups),function(ag){return _c('option',{key:ag.id,domProps:{"value":ag.id}},[_vm._v(_vm._s(ag.name))])}),0),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"survey","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("調査")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.survey),expression:"survey"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.survey=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.currentSurveys),function(s){return _c('option',{key:s.id,domProps:{"value":s.id}},[_vm._v(_vm._s(s.name))])}),0),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("テンプレ選択")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.template),expression:"template"}],staticClass:"form-control",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.template=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.changeTemplate]}},_vm._l((_vm.templates),function(temp){return _c('option',{key:temp.id,domProps:{"value":temp.id}},[_vm._v(_vm._s(temp.name))])}),0)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('validation-provider',{attrs:{"name":"senderEmail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("送信者アドレス")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.senderEmail),expression:"senderEmail"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text"},domProps:{"value":(_vm.senderEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.senderEmail=$event.target.value}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"col-6"},[_c('validation-provider',{attrs:{"name":"senderName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("送信者名")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.senderName),expression:"senderName"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text"},domProps:{"value":(_vm.senderName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.senderName=$event.target.value}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]),_c('validation-provider',{attrs:{"name":"recipients","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("宛先アドレス")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.recipients),expression:"recipients"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text"},domProps:{"value":(_vm.recipients)},on:{"input":function($event){if($event.target.composing){ return; }_vm.recipients=$event.target.value}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"newsletterName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("タイトル")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"type":"text"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("サムネイル画像")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.thumbnailFileTxt),expression:"thumbnailFileTxt"}],staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.thumbnailFileTxt)},on:{"input":function($event){if($event.target.composing){ return; }_vm.thumbnailFileTxt=$event.target.value}}})]),_c('div',{staticClass:"col-2 text-right"},[_c('label',{staticClass:"btn btn-secondary"},[_vm._v("ファイル添付"),_c('input',{staticClass:"d-none",attrs:{"type":"file","accept":_vm.acceptThumbnailFiletypes},on:{"change":function($event){return _vm.onFileChange($event)}}})])]),(_vm.thumbnailUrl)?_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"row small"},[_c('div',{staticClass:"col-6"},[_c('input',{attrs:{"id":"copy-img-url","type":"hidden"},domProps:{"value":_vm.thumbnailUrl}}),_c('a',{staticClass:"img-action-link mr-2",on:{"click":_vm.copyImgFileUrl}},[_vm._v("URLコピー")])])])]):_vm._e()])]),_c('validation-provider',{attrs:{"name":"body","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v("本文")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.body),expression:"body"}],class:{ 'form-control': true, 'form-control-danger': errors.length },attrs:{"rows":"30"},domProps:{"value":(_vm.body)},on:{"input":function($event){if($event.target.composing){ return; }_vm.body=$event.target.value}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"text-left float-left"},[_c('router-link',{staticClass:"btn btn-outline-secondary action-btns",attrs:{"to":{ name: 'x_newsletters' }}},[_vm._v("戻る")])],1),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-outline-secondary mr-2 action-btns",on:{"click":function($event){$event.preventDefault();return _vm.submit('save')}}},[_vm._v("下書き保存")]),_c('button',{staticClass:"btn btn-success action-btns",on:{"click":function($event){$event.preventDefault();return _vm.submit('preview')}}},[_vm._v("プレビューへ")])])])]}}])})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-header d-flex flex-column flex-md-row"},[_c('h4',{staticClass:"mb-0"},[_vm._v("メルマガ編集")])])}]

export { render, staticRenderFns }