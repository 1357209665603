import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_quantitative_answers');

export default {
  namespaced: true,

  state: {
    quantitativeAnswersList: [],
    survey: {},
    question: {},
    quantitativeQuestion: {},
    quantitativeAnswer: {}
  },

  getters: {},

  actions: {
    loadQuantitativeAnswers({ commit }, params) {
      return gateway
        .get(
          {
            action: 'index',
            survey_id: params.survey_id,
            question_id: params.question_id,
            quantitative_question_id: params.id
          },
          params.pageParams
        )
        .then(response => {
          if (response.status === 200) {
            const quantitativeAnswersList = response.body.quantitative_answers;
            const survey = response.body.survey;
            const question = response.body.question;
            const quantitativeQuestion = response.body.quantitative_question;

            commit('setQuantitativeAnswersList', quantitativeAnswersList);
            commit('setSurvey', survey);
            commit('setQuestion', question);
            commit('setQuantitativeQuestion', quantitativeQuestion);
          }
          return response;
        });
    },
    loadEditQuantitativeAnswer({ commit }, id) {
      return gateway.get({ action: 'edit', id }).then(response => {
        if (response.status === 200) {
          const quantitativeAnswer = response.body.quantitative_answer;

          commit('setQuantitativeAnswer', quantitativeAnswer);
        }
        return response;
      });
    },
    createQuantitativeAnswer(_, params) {
      return gateway
        .post(
          {
            action: 'create'
          },
          params
        )
        .then(response => {
          return response;
        });
    },
    updateQuantitativeAnswer(_, params) {
      return gateway
        .put(
          {
            action: 'update',
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },
    deleteQuantitativeAnswer(_, id) {
      return gateway.delete({ action: 'destroy', id }).then(response => {
        return response;
      });
    }
  },

  mutations: {
    setQuantitativeAnswersList(state, quantitativeAnswersList) {
      state.quantitativeAnswersList = quantitativeAnswersList;
    },
    setSurvey(state, survey) {
      state.survey = survey;
    },
    setQuestion(state, question) {
      state.question = question;
    },
    setQuantitativeQuestion(state, quantitativeQuestion) {
      state.quantitativeQuestion = quantitativeQuestion;
    },
    setQuantitativeAnswer(state, quantitativeAnswer) {
      state.quantitativeAnswer = quantitativeAnswer;
    }
  }
};
