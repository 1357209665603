<template lang="pug">
  .vc-main-container.container
    .content-wrapper(v-show="ready")
      .row.flex-grow
        .col-12
          #network
            .vis-network
              canvas
    .content-wrapper(v-show="!ready")
      .row.flex-grow
        .col-12
          p データを読み込んでいます。しばらくお待ちください。

</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Network } from 'vis-network/dist/vis-network.min.js';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {},

  data() {
    // https://visjs.github.io/vis-network/docs/network/
    return {
      loading: false,
      options: {
        nodes: {
          shape: 'dot',
          font: {
            size: 50,
            multi: true,
            bold: {
              mod: '',
              color: '#ff0000'
            }
          },
          scaling: {
            customScalingFunction: function(min, max, total, value) {
              return value / max;
            },
            min: 10,
            max: 100
          }
        },
        edges: {
          font: {
            align: 'top'
          },
          scaling: {
            label: {
              min: 30,
              max: 30
            }
          }
        },
        physics: {
          forceAtlas2Based: {
            gravitationalConstant: -200, // 中心の重力 0が重力の最大
            centralGravity: 0.015, // 中心に集まる重力
            springLength: 200, // エッジの長さ
            springConstant: 0.18, //エッジの弾性力
            damping: 0.1 // ノードの減衰（間隔としては摩擦）
          },
          maxVelocity: 50,
          solver: 'forceAtlas2Based',
          timestep: 0.2,
          adaptiveTimestep: true,
          stabilization: {
            iterations: 1000,
            updateInterval: 10
          }
        }
      },
      ready: false
    };
  },

  computed: {
    ...mapState('resources/u_co_occurrences', ['nodes', 'edges']),
    ...mapState('resources/u_quantitative', ['questionId'])
  },

  created() {
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_co_occurrences', ['loadCoOccurenceNetwork']),

    /**
     * 初期画面ロード
     */
    load() {
      let requestParams = this.$route.params;
      if (!requestParams['question_id']) {
        const vcd = defaultCookie.get();
        requestParams['question_id'] = this.questionId || vcd['questionId'];
      }
      this.loading = true;
      this.loadCoOccurenceNetwork(requestParams).then(_response => {
        this.ready = true;
        this.draw();
        this.loading = false;
      });
    },

    /**
     * 共起ネットワークの描画
     */
    draw() {
      var container = document.getElementById('network');
      var data = {
        nodes: this.nodes,
        edges: this.edges
      };
      new Network(container, data, this.options);
    }
  }
};
</script>

<style scoped>
#network {
  width: 100%;
  height: 650px;
  border: 1px solid lightgray;
  background-color: white;
}

.vis-network {
  position: relative;
  overflow: hidden;
  touch-action: pan-y;
  user-select: none;
  width: 100%;
  height: 100%;
}
</style>
