<template lang="pug">
  div
    modal(
      ref="modalAllSurveyResponses"
      name="modalAllSurveyResponses"
      id="modalAllSurveyResponses"
      :minWidth="600"
      :minHeight="320"
      :width="600"
      :height="320"
      :reset="true"
      :scrollable="true"
      :pivotX="0.9"
      :pivotY="0.9"
    )
      header.modal-header.align-items-end
        h6.modal-title.vc-midashi
          | 回答者ID: {{responderCode}}
        dl.respondent-num.align-items-center
          dt
            button(type="button" @click="$modal.hide('modalAllSurveyResponses')" class="close vc-close")
              | ×
      .row.no-gutters.modal-content
        div(v-if="allResponses.length > 0")
          div(v-for="res in allResponses")
            .col-12.mt-2
              span.vc-midashi
                | {{res.survey.name}}
              span.float-right
                | {{newDate(res.survey.start_at) + ' - ' + newDate(res.survey.end_at)}}
            .col-12.mt-3.mb-3
              span
                | {{res.contents}}
        div(v-else)
          .col-12.mt-3.mb-3
            span
              | 別調査回答がありません

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      responderCode: ''
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_quantitative_analyse', ['allResponses'])
  },

  watch: {
    response: function() {
      this.attributeValues = this.response.attribute_values;
    }
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_quantitative_analyse', ['loadSurveyResponses']),
    modalOpen: function(survey_id, responder_code) {
      this.responderCode = responder_code;
      const params = {
        survey_id: survey_id,
        responder_code: responder_code
      };

      this.loadSurveyResponses(params);
      this.$modal.show('modalAllSurveyResponses');
    },
    newAttributeValues: function(values) {
      return values.filter(value =>
        ['性別', '役職', '年代'].includes(value.response_attribute.name)
      );
    },
    newDate: function(date) {
      let newDate = new Date(date);
      return (
        newDate.getFullYear() +
        '.' +
        (newDate.getMonth() + 1) +
        '.' +
        newDate.getDate()
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-header {
  display: block;
  span {
    font-size: 0.8rem;
  }
  span.organization-wrap {
    float: right;
    margin-top: 1px;
  }
}
.modal-content {
  overflow-y: scroll;
  height: 75%;
  display: block;
  flex-direction: initial;
  border: none;
}
</style>
