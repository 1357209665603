<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | 定量設問編集
  .row.flex-grow
    .col-6
      .card
        .card-body
          form
            error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
            validation-observer(v-slot="{ invalid }")
              validation-provider(name="code" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問コード
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="newQuantitativeQuestion.code"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="questionText" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問文
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="newQuantitativeQuestion.question_text"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="description" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問説明文
                  textarea(
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    rows="10"
                    v-model="newQuantitativeQuestion.description"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="questionType" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | 設問タイプ
                  select(
                    class="form-control"
                    v-model="newQuantitativeQuestion.question_type"
                  )
                    option(
                      v-for="questionType in questionTypes"
                      :value="questionType"
                      :key="questionType"
                    )
                      | {{ questionType }}
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="graphType" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | グラフタイプ
                  select(
                    class="form-control"
                    v-model="newQuantitativeQuestion.graph_type"
                  )
                    option(
                      v-for="graphType in graphTypes"
                      :value="graphType.key"
                      :key="graphType.key"
                    )
                      | {{ graphType.val }}
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              .form-group
                label
                  | 表示単位
                input.form-control(
                  type="text"
                  v-model="newQuantitativeQuestion.unit"
                )

              .mt-1.float-right
                router-link.btn.btn-light.mr-2(:to="{ name: 'x_quantitative_questions', params: { survey_id: surveyId, id: questionId } }")
                  | {{ $t('actions.cancel') }}
                button.btn.btn-success(
                  :disabled="processing || invalid"
                  @click.prevent="submit"
                )
                  | {{ $t('actions.update') }}

</template>
<script>
import { mapState, mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';

export default {
  components: {
    ErrorMessage
  },
  data() {
    return {
      newQuantitativeQuestion: {},
      questionTypes: ['SA', 'MA', 'MTSA', 'MTMA', 'NPS'],
      graphTypes: [
        {
          key: 'pie',
          val: '円グラフ'
        },
        {
          key: 'horizontal',
          val: '横棒グラフ'
        },
        {
          key: 'vertical',
          val: '縦棒グラフ'
        }
      ],
      errorMessages: [],
      processing: false,
      surveyId: '',
      questionId: ''
    };
  },
  computed: {
    ...mapState('resources/x_quantitative_questions', ['quantitativeQuestion'])
  },
  watch: {},
  created() {
    this.load();
  },
  mounted() {},
  methods: {
    ...mapActions('resources/x_quantitative_questions', [
      'loadEditQuantitativeQuestion',
      'updateQuantitativeQuestion'
    ]),
    load() {
      const params = this.$route.params;
      this.surveyId = params.survey_id;
      this.questionId = params.question_id;
      this.loadEditQuantitativeQuestion(params.id).then(() => {
        this.newQuantitativeQuestion = {
          id: this.quantitativeQuestion.id,
          code: this.quantitativeQuestion.code,
          question_text: this.quantitativeQuestion.question_text,
          description: this.quantitativeQuestion.description,
          question_type: this.quantitativeQuestion.question_type,
          graph_type: this.quantitativeQuestion.graph_type,
          unit: this.quantitativeQuestion.unit
        };
      });
    },
    resetErrorMessages() {
      this.errorMessages = [];
    },
    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.updateQuantitativeQuestion(this.newQuantitativeQuestion)
        .then(response => {
          if (response.status === 200) {
            const surveyId = this.surveyId;
            const questionId = this.questionId;
            this.$router.push({
              name: 'x_quantitative_questions',
              params: { survey_id: surveyId, id: questionId }
            });
          } else if (response.status === 422) {
            this.errorMessages = response.body.errors;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
