<template lang="pug">
  div
    modal(
      ref="modalFixClassificationRequests"
      name="modalFixClassificationRequests"
      id="modalFixClassificationRequests"
      :minWidth="modalWidth"
      :minHeight="modalHeight"
      :width="modalWidth"
      :height="modalHeight"
      :reset="true"
      :resizable="false"
      :clickToClose="true"
      :scrollable="true"
      :draggable="true"
      :pivotY="0.7"
    )
      header.modal-header.align-items-end
        h6.modal-title.vc-midashi
          | 分類修正リクエスト
        dl.respondent-num.align-items-center
          dt
            button(type="button" @click="$modal.hide('modalFixClassificationRequests')" class="close vc-close")
              | ×
      .modal-contents.vc-dashboard(:style="{height: (modalHeight * 0.89) - 57 + 'px', overflow: 'scroll'}")
        .vc-dashboard-box
          .vc-dashboard-box-content
            p.font-weight-bold 回答全文
            p.mb-4
              span(v-for="opinion in response.contents")
                span(v-html="opinion+'。'")
            hr
            .dashboard-comp-table-col.vc-dashboard-box-table-wrap.opinion-search-comp-table-col
              .row.no-gutters.vc-respondent-table.text-left.font-weight-bold
                .col-5
                .col-7
                  .row.no-gutters
                    .col-6
                      | {{ isOpinionClassification ? '意見分類' : '' }}
                    .col-6 感情分類
              .row.no-gutters.vc-respondent-table.text-left.font-weight-bold
                .col-5 意見
                .col-7
                  .row.no-gutters
                    .col-3
                      | {{ isOpinionClassification ? '現在の分類' : '' }}
                    .col-3
                      | {{ isOpinionClassification ? '修正後の分類' : '' }}
                    .col-3 現在の分類
                    .col-3 修正後の分類
              .vc-dashboard-box-table-data-row.no-scroll
                .row.no-gutters.vc-respondent-table.text-left(v-for="opinion in response.opinions")
                  .col-5.pr-3.align-self-center
                    | {{ opinion.sentence }}
                  .col-7
                    .row.no-gutters.vc-respondent-table-num
                      .col-3.pr-3.align-self-center(v-if="isOpinionClassification")
                        | {{ opinion.opinion_classification_name }}
                      .col-3.pr-3(v-else)
                      .col-3.pr-3(v-if="isOpinionClassification")
                        select.form-control(v-model="newOpinionClassification[opinion.id]" @change="setIsEmpty")
                          option(key="0" value="0") 変更なし
                          option(
                            v-for="opi in opinionClassifications"
                            :key="opi.id"
                            :value="opi.id"
                          )
                            | {{opi.name}}
                      .col-3.pr-3(v-else)
                      .col-3.pr-3.align-self-center
                        | {{ opinion.emotion_classification_name }}
                      .col-3.pr-3
                        select.form-control(v-model="newEmotionClassification[opinion.id]" @change="setIsEmpty")
                          option(key="0" value="0") 変更なし
                          option(
                            v-for="emo in emotionClassifications"
                            :key="emo.id"
                            :value="emo.id"
                          )
                            | {{emo.name}}
      footer.modal-footer
        .text-right
          button.btn.btn-primary.vc-primary-btn.btn-select(
            :disabled="processing || isEmpty"
            :class="{'btn-disabled': processing || isEmpty}"
            @click.prevent="submit"
          )
            | 修正リクエストを送信

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      modalWidth: 0,
      modalHeight: 0,
      response: {},
      newOpinionClassification: {},
      newEmotionClassification: {},
      processing: false,
      isEmpty: true,
      isOpinionClassification: true
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_opinion_search', [
      'opinionClassifications',
      'emotionClassifications'
    ])
  },

  watch: {
    response: function() {
      this.attributeValues = this.response.attribute_values;
    }
  },

  created() {
    this.modalWidth = window.outerWidth * 0.75;
    this.modalHeight = window.outerHeight * 0.65;
  },

  methods: {
    ...mapActions('resources/u_opinion_search', ['loadClassifications']),
    ...mapActions('resources/u_opinion_search', ['requestClassifications']),
    modalOpen: function(response) {
      const params = { survey_id: response.survey_id };
      this.response = response;
      this.loadClassifications(params);
      this.response.opinions.forEach(op => {
        this.newOpinionClassification[op.id] = 0;
        this.newEmotionClassification[op.id] = 0;
      });
      this.isEmpty = true;
      this.isOpinionClassification =
        this.response.opinions.filter(opi => opi.opinion_classification_name)
          .length > 0;
      this.$modal.show('modalFixClassificationRequests');
    },

    submit() {
      this.processing = true;
      const params = {
        survey_id: this.response.survey_id,
        new_opinion_classifications: this.newOpinionClassification,
        new_emotion_classifications: this.newEmotionClassification
      };
      this.requestClassifications(params).then(() => {
        this.newOpinionClassification = {};
        this.newEmotionClassification = {};
        this.processing = false;
        this.$modal.hide('modalFixClassificationRequests');
      });
    },

    setIsEmpty() {
      this.isEmpty =
        Object.values(this.newOpinionClassification).filter(ec => ec != 0)
          .length < 1 &&
        Object.values(this.newEmotionClassification).filter(ec => ec != 0)
          .length < 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-header {
  display: block;
  span {
    font-size: 0.8rem;
  }
}
.modal-footer {
  display: block;
}
</style>
