import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_profile');

export default {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    /**
     * プロフィール更新
     *
     * @param {Object} params パラメタ
     * @return {Promise} Promise
     */
    updateProfile(_, params) {
      return gateway
        .put(
          {
            action: 'update'
          },
          params
        )
        .then(response => {
          return response;
        });
    }
  },

  mutations: {}
};
