<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | テンプレートアップロード
  .row.flex-grow
    .col-12
      .card
        .card-body
          form
            .alert.alert-danger(v-if="errorMessages.length > 0")
              ul.mb-0
                li(v-for="error in errorMessages")
                  | {{ error }}
            validation-observer(v-slot="{ invalid }")
              validation-provider(name="templateName" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | テンプレート名
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="name"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              .form-group
                label
                  | テンプレート内画像
                .row
                  .col-10
                    input.form-control(
                      type="text"
                      v-model="imgFilesTxt"
                      readonly
                    )
                  .col-2.text-right
                    label.btn.btn-secondary ファイル添付
                      input.d-none(
                        type="file"
                        :accept="acceptImgFiletypes"
                        @change="onFileChange($event, 'imgs')"
                        multiple
                      )

              .form-group
                label
                  | HTMLファイル
                .row
                  .col-10
                    input.form-control(
                      type="text"
                      v-model="htmlFileTxt"
                      readonly
                    )
                  .col-2.text-right
                    label.btn.btn-secondary ファイル添付
                      input.d-none(
                        type="file"
                        :accept="acceptHtmlFiletypes"
                        @change="onFileChange($event, 'html')"
                        multiple
                      )

              .mt-5.float-right
                router-link.btn.btn-light.mr-2(:to="{ name: 'x_newsletter_templates' }")
                  | {{ $t('actions.cancel') }}
                button.btn.btn-success(
                  :disabled="processing || invalid"
                  @click.prevent="submit"
                )
                  | アップロード

</template>

<script>
export default {
  components: {},
  data() {
    return {
      name: '',
      imgFilesTxt: '',
      htmlFileTxt: '',
      imgFiles: [],
      htmlFile: '',
      errorMessages: [],
      processing: false,
      acceptImgFiletypes: 'image/jpeg,image/png,image/gif',
      acceptHtmlFiletypes: 'text/html'
    };
  },
  created() {},
  mounted() {},
  methods: {
    resetErrorMessages() {
      this.errorMessages = [];
    },
    onFileChange(e, filetype) {
      this.resetErrorMessages();
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      if (filetype === 'imgs') {
        this.imgFiles = files;
        let arr = [];
        for (let i = 0; i < this.imgFiles.length; i++) {
          const file = this.imgFiles[i];
          arr.push(file.name);
        }
        this.imgFilesTxt = arr.join(', ');
      }
      if (filetype === 'html') {
        this.htmlFile = files[0];
        this.htmlFileTxt = this.htmlFile.name;
      }
    },
    submit() {
      this.resetErrorMessages();
      this.noticeMessages = [
        '処理中...。アップロードの完了までもう少しお待ちください。'
      ];
      let path = '/api/x/newsletter_templates';
      const formData = new FormData();
      formData.append('name', this.name);
      if (this.htmlFile) formData.append('html_file', this.htmlFile);
      if (this.imgFiles.length > 0) {
        for (let idx = 0; idx < this.imgFiles.length; idx++) {
          formData.append('img_files[]', this.imgFiles[idx]);
        }
      }

      fetch(path, {
        method: 'PUT',
        body: formData
      }).then(response => {
        this.noticeMessages = [];
        if (response.status === 200) {
          this.$router.push({ name: 'x_newsletter_templates' });
        } else {
          response.json().then(body => {
            this.errorMessages = body.errors;
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.form-control[readonly] {
  background-color: #fff;
}
</style>
