import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('x_users');

export default {
  namespaced: true,

  state: {
    usersList: [],
    accountGroups: [],
    roles: {}
  },

  getters: {},

  actions: {
    /**
     * ユーザー一覧取得
     */
    loadUsers({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          const usersList = response.body.users;

          commit('setUsersList', usersList);
        }
        return response;
      });
    },

    /**
     * 新規作成
     *
     * @param {Object} params パラメタ
     * @return {Promise} Promise
     */
    createUser(_, params) {
      return gateway
        .post(
          {
            action: 'create'
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    /**
     * ユーザー取得(編集)
     */
    editUser(_, id) {
      return gateway
        .get({
          action: 'edit',
          id
        })
        .then(response => {
          return response;
        });
    },

    /**
     * 更新
     *
     * @param {Object} params パラメタ
     * @return {Promise} Promise
     */
    updateUser(_, params) {
      return gateway
        .put(
          {
            action: 'update',
            id: params.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    /**
     * ユーザー論理削除
     *
     * @param {Number} id userID
     * @return {Promise} Promise
     */
    deleteUser(_, id) {
      return gateway.delete({ action: 'remove', id }).then(response => {
        return response;
      });
    },

    unlockUser(_, id) {
      return gateway.put({ action: 'unlock', id }).then(response => {
        return response;
      });
    },

    enableUser(_, id) {
      return gateway.put({ action: 'enable', id }).then(response => {
        return response;
      });
    },

    disableUser(_, id) {
      return gateway.put({ action: 'disable', id }).then(response => {
        return response;
      });
    },

    loadAccountGroups({ commit }, id) {
      return gateway.get({ action: 'account_groups', id }).then(response => {
        if (response.status === 200) {
          const accountGroups = response.body.account_groups;

          commit('setAccountGroups', accountGroups);
        }
        return response;
      });
    },

    addAccountGroup(_, params) {
      return gateway
        .put(
          {
            action: 'add_account_group',
            id: params.user.id
          },
          params
        )
        .then(response => {
          return response;
        });
    },

    loadRoles({ commit }, params) {
      return gateway
        .get({ action: 'roles', id: params.id }, params)
        .then(response => {
          if (response.status === 200) {
            const roles = response.body.roles;
            commit('setRoles', roles);
          }
          return response;
        });
    }
  },

  mutations: {
    setUsersList(state, usersList) {
      state.usersList = usersList;
    },
    setAccountGroups(state, accountGroups) {
      state.accountGroups = accountGroups;
    },
    setRoles(state, roles) {
      state.roles = roles;
    }
  }
};
