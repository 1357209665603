<template lang="pug">
  .content-wrapper
    .content-header.d-flex.flex-column.flex-md-row
      h4.mb-0
        | {{ $t('admin.coOccurrenceNetworks.title') }}
      .wrapper.ml-0.ml-md-auto.my-auto.d-flex.align-items-center.pt-4.pt-md-0
        a.btn.btn-success.btn-sm.ml-auto.create-btn(@click.prevent="newNetwork")
          | {{ $t('admin.coOccurrenceNetworks.index.new') }}
    .row
      .col-md-12.grid-margin.stretch-card.clearfix
        .card
          .card-body.pb-0
            .table-responsive
              table.table.table-striped
                thead
                  tr
                    th(width="80px")
                      | {{ $t('admin.coOccurrenceNetworks.id') }}
                    th
                      | {{ $t('admin.coOccurrenceNetworks.projectName') }}
                    th
                      | {{ $t('admin.coOccurrenceNetworks.createdAt') }}
                    th
                tbody
                  tr(v-for="network in networks")
                    td
                      | {{ network.id }}
                    td
                      | {{ network.project_name }}
                    td
                      | {{ network.created_at }}
                    td.text-right
                      button.btn.btn-secondary.dropdown
                        a.dropdown-toggle.no-style(
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        )
                          | アクション
                        .dropdown-menu(aria-labelledby="dropdownMenuButton")
                          router-link.dropdown-item(
                            :to="{ name: 'x_show_co_occurrence_network', params : { id: network.id } }"
                          )
                            | {{ $t('actions.show') }}
                          router-link.dropdown-item.mt-1(
                            :to="{ name: 'x_request_api_co_occurrence_network', params : { id: network.id } }"
                          )
                            | {{ $t('actions.edit') }}
                          a.dropdown-item.mt-1.delete-btn(href="" @click.prevent="showDeleteModal(network)")
                            | {{ $t('actions.delete') }}

            .mt-1.float-right
              paginate(
                v-model="cPage"
                :page-count="pageParams.pages"
                :click-handler="clickCallback"
                containerClass="pagination"
                pageClass="page-item"
                pageLinkClass="page-link"
                prevText="«"
                prevClass="page-item"
                prevLinkClass="page-link"
                nextText="»"
                nextClass="page-item"
                nextLinkClass="page-link"
              )
    NetworkDeleteModal(:deleteTarget="deleteTarget" @remove="remove")
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Paginate from 'vuejs-paginate';
import NetworkDeleteModal from 'src/components/x/co_occurrence_networks/NetworkDeleteModal';

export default {
  components: {
    Paginate,
    NetworkDeleteModal
  },
  data() {
    return {
      defaultPer: 10,
      cPage: -1,
      deleteTarget: {}
    };
  },
  computed: {
    ...mapState('resources/x_co_occurrence_networks', [
      'networks',
      'currentPage'
    ]),
    ...mapState('pages/x_co_occurrence_networks/list/pagination', [
      'pageParams'
    ])
  },
  created() {
    this.setPer(this.defaultPer);
    this.load();
  },
  mounted() {},
  methods: {
    ...mapActions('resources/x_co_occurrence_networks', [
      'loadNetworks',
      'createNetwork',
      'deleteNetwork'
    ]),
    ...mapMutations('pages/x_co_occurrence_networks/list/pagination', [
      'setTotal',
      'setPages',
      'setPage',
      'setPer'
    ]),
    load() {
      this.loadNetworks(this.pageParams).then(response => {
        this.setTotal(response.body.page_options.total);
        this.setPages(response.body.page_options.pages);
        this.cPage = this.currentPage;
      });
    },
    clickCallback(pageNum) {
      this.setPage(pageNum);
      this.load();
    },
    newNetwork() {
      this.createNetwork().then(response => {
        this.$router.push({
          name: 'x_show_co_occurrence_network',
          params: { id: response.body.network.id }
        });
      });
    },
    remove(network) {
      this.deleteNetwork(network.id).then(response => {
        this.setTotal(response.body.page_options.total);
        this.setPages(response.body.page_options.pages);
        this.cPage = this.currentPage;
      });
    },
    showDeleteModal(network) {
      this.deleteTarget = network;
      this.$bvModal.show('network-delete-modal');
    }
  }
};
</script>
<style scoped>
.create-btn {
  color: #ffffff !important;
  cursor: pointer;
}
.delete-btn {
  color: red !important;
}
</style>
