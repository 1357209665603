<template lang="pug">
  .content-wrapper
    .content-header.d-flex.flex-column.flex-md-row
      h4.mb-0
        | {{ $t('admin.profiles.title') }}
    .row
      .col-md-4
        .card.text-center
          .card-body.d-flex.flex-column
            .wrapper
              img.img-lg.rounded-circle.mb-2(:src="operator.thumbnail_url")
              h4
                | {{ operator.name }}
              p.text-muted
                | {{ operator.email }}
      .col-md-8
        .card
          .card-body.d-flex.flex-column
            .wrapper
              form
                error-message(:errors="errorMessages" v-if="errorMessages.length > 0")
                b-form-group(:label="avatarLabel" label-for="avatar" label-cols-sm="2")
                  b-form-file#avatar(
                    v-model="thumbnailFile"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  )
                .row.border-top
                  .col-md-12
                    .mt-3.float-right
                      router-link.btn.btn-light.mr-2(:to="{ name: 'x_admins' }")
                        | {{ $t('actions.cancel') }}
                      button.btn.btn-success(
                        :disabled="processing"
                        @click.prevent="submit"
                      )
                        | {{ $t('actions.update') }}
</template>

<script>
import { mapState, mapActions } from 'vuex';
import i18n from '../../../src/locales/index';
import ErrorMessage from 'src/components/ErrorMessage';

export default {
  components: {
    ErrorMessage
  },

  data() {
    return {
      thumbnailFile: null,
      errorMessages: [],
      processing: false
    };
  },

  computed: {
    ...mapState('config', ['operator']),

    avatarLabel() {
      return i18n.tc('admin.profiles.avatar');
    },
    /**
     * サーバサイドに渡す profile のパラメータを生成
     *
     * @return {object}
     */
    profileParams() {
      const formData = new FormData();
      formData.append('thumbnail', this.thumbnailFile);

      return formData;
    }
  },

  created() {},

  mounted() {},

  methods: {
    ...mapActions('resources/x_profile', ['updateProfile']),

    resetErrorMessages() {
      this.errorMessages = [];
    },

    submit() {
      this.processing = true;
      this.resetErrorMessages();
      // ファイルの場合は、jsonで送らないため、gatewayが利用できない
      fetch('/api/x/profile', {
        method: 'PUT',
        body: this.profileParams
      })
        .then(response => {
          if (response.status === 200) {
            this.$router.go({ name: 'x_profile' });
          } else if (response.status === 422) {
            return response.json();
          }
        })
        .then(jsonResponse => {
          this.errorMessages = jsonResponse.errors;
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
