const Cookie = require('js-cookie');

export default class DefaultCookie {
  get() {
    return Cookie.get('_vc_default')
      ? JSON.parse(Cookie.get('_vc_default'))
      : null;
  }
  set(obj) {
    var vcd = this.get();
    Cookie.set('_vc_default', vcd ? Object.assign(vcd, obj) : obj, {
      expires: 93
    });
  }
  remove() {
    Cookie.remove('_vc_default', { expires: 93 });
  }
}
