<template lang="pug">
  div
    .vc-top-col.pt-0
      .container-fluid
        .container
          .ml-2
            SurveyOrganizationSelect(@input="changeSurveyOrganization")
    .vc-main-container.container(v-if="survey_response_count != null")
      .vc-topic-content.mb-4
        .vc-left-col
          AttributeValuesSelect(ref="attributeValuesSelect")
      .vc-content-col
        div(v-if="survey_response_count <= getNumberOfDisplayableData()")
          | 回答が{{getNumberOfDisplayableData()}}件以下は表示できません。組織を選びなおしてください
        div(v-else)
          .row.mt-2.mb-4
            .col-5
              ul.vc-parameter-nav
                li
                  a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': displayType === 1}" @click="setDisplayType(1)") 率（意見分類内）
                li
                  a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': displayType === 0}" @click="setDisplayType(0)") 率（全体）
                li
                  a.vpn-all.vc-ranking-btn(href="javascript:void(0)" :class="{'active': displayType === 2}" @click="setDisplayType(2)") 件数
            .col-7.text-right.mt-3.pr-3
              span.small(v-show="[0, 1].includes(displayType)")
                | ※ 割合の算出方法：{{calculateFormula()}}
          OpinionTendenciesUserTeam(
            @modalOpen="modalOpen"
            @modalOpenNS="modalOpenNS"
            @modalOpenNR="modalOpenNR"
            @modalOpenPR="modalOpenPR"
            @toOpinionSearch="toOpinionSearch"
            :survey-id="surveyId"
            :display-type="displayType"
            :isOrganizationsPage="true"
          )
          hr.my-team-divider
          OpinionTrendsUserTeam(
            @modalOpen="modalOpen"
            @modalOpenNS="modalOpenNS"
            @toOpinionSearch="toOpinionSearch"
            :survey-id="surveyId"
            :display-type="displayType"
          )
    ModalOpinionAttrValUserTeam(ref="modalOpinionAttrVal")
    ModalNsOpinionAttrValUserTeam(ref="modalNsOpinionAttrVal")
    ModalNrOpinionAttrValUserTeam(ref="modalNrOpinionAttrVal" @toOpinionSearch="toOpinionSearch")
    ModalPrOpinionAttrValUserTeam(ref="modalPrOpinionAttrVal" @toOpinionSearch="toOpinionSearch")

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SurveyOrganizationSelect from '../../../components/u/SurveyOrganizationSelect';
import OpinionTendenciesUserTeam from '../../../components/u/qualitative/OpinionTendenciesUserTeam';
import OpinionTrendsUserTeam from '../../../components/u/qualitative/OpinionTrendsUserTeam';
import ModalOpinionAttrValUserTeam from '../../../components/u/analyse/ModalOpinionAttrValUserTeam';
import ModalNsOpinionAttrValUserTeam from '../../../components/u/analyse/ModalNsOpinionAttrValUserTeam';
import ModalNrOpinionAttrValUserTeam from '../../../components/u/analyse/ModalNrOpinionAttrValUserTeam';
import ModalPrOpinionAttrValUserTeam from '../../../components/u/analyse/ModalPrOpinionAttrValUserTeam';
import AttributeValuesSelect from '../../../components/u/qualitative/QualitativeAttributeValuesForAnalyse';
import dc from '../../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {
    SurveyOrganizationSelect,
    OpinionTendenciesUserTeam,
    OpinionTrendsUserTeam,
    ModalOpinionAttrValUserTeam,
    ModalNsOpinionAttrValUserTeam,
    ModalNrOpinionAttrValUserTeam,
    ModalPrOpinionAttrValUserTeam,
    AttributeValuesSelect
  },

  data() {
    return {
      selectOpinionClass: 'opinion_count',
      displayType: null,
      organizationId: '',
      started: false
    };
  },

  computed: {
    ...mapState('config', ['operator']),
    ...mapState('resources/u_control_settings', ['controlSettings']),
    ...mapState('resources/u_analyse_team', ['survey_response_count']),
    ...mapState('resources/u_opinion_search', ['keepedAttributeValues']),
    ...mapState('pages/u_surveys/select', ['surveyId']),
    ...mapState('resources/u_quantitative', [
      'questionId',
      'organizationIds',
      'displayAttributeValues',
      'attributeValueIds'
    ]),
    ...mapState('resources/u_analyse', ['isDisplay'])
  },

  watch: {
    displayType() {
      const vcd = defaultCookie.get();
      if (vcd['analyze_team_displayType'] !== this.displayType)
        defaultCookie.set({ displayType: this.displayType });
    },
    surveyId() {
      if (!this.started) this.loadQuestions({ survey_id: this.surveyId });
    },
    questionId() {
      this.preLoad('questionId');
    },
    attributeValueIds() {
      this.load();
    }
  },

  created() {
    this.loadControlSettings();
    this.setOpinionTendencies([]);
    this.setDefaultDisplayType();
    const vcd = defaultCookie.get();
    if (vcd['surveyId'] || this.surveyId) this.preLoad('surveyId');
    if (this.questionId) this.preLoad('questionId');
  },

  mounted() {},

  methods: {
    ...mapActions('resources/u_control_settings', ['loadControlSettings']),
    ...mapActions('resources/u_analyse_team', [
      'loadOpinionTendenciesForAnalyse',
      'loadOpinionTrends'
    ]),
    ...mapActions('resources/u_quantitative', ['loadQuestions']),
    ...mapMutations('resources/u_analyse_team', ['setOpinionTendencies']),
    load() {
      const vcd = defaultCookie.get();
      const params = {
        survey_id: vcd['surveyId'] || this.surveyId,
        question_id: this.questionId,
        organization_id: this.organizationId,
        attribute_value_ids: this.attributeValueIds,
        search_by: 'opinion_classifications'
      };
      this.loadOpinionTendenciesForAnalyse(params).then(_response => {
        if (!this.started) this.started = true;
      });
      this.loadOpinionTrends(params);
    },
    changeSurveyOrganization(data) {
      this.organizationId = data.organizationId;
      if (this.started) this.load();
    },
    getNumberOfDisplayableData() {
      const name = 'number_of_displayable_data';
      if (this.controlSettings[name] && this.controlSettings[name]['enabled']) {
        return this.controlSettings[name]['values']
          ? this.controlSettings[name]['values']
          : 0;
      }
      return 0;
    },
    modalOpen(surveyId, opinionClassificationId, emotionClassificationId) {
      const params = {
        survey_id: surveyId,
        question_id: this.questionId,
        organization_id: this.organizationId,
        opinion_classification_id: opinionClassificationId,
        attribute_value_ids: this.attributeValueIds,
        emotion_classification_id: emotionClassificationId
      };
      this.$refs.modalOpinionAttrVal.modalOpen(params);
    },
    modalOpenNS(surveyId, opinionClassificationId) {
      const params = {
        survey_id: surveyId,
        question_id: this.questionId,
        organization_id: this.organizationId,
        opinion_classification_id: opinionClassificationId,
        attribute_value_ids: this.attributeValueIds
      };
      this.$refs.modalNsOpinionAttrVal.modalOpen(params);
    },
    modalOpenNR(surveyId, opinionClassificationId) {
      const params = {
        survey_id: surveyId,
        question_id: this.questionId,
        organization_id: this.organizationId,
        opinion_classification_id: opinionClassificationId,
        display_type: this.displayType
      };
      this.$refs.modalNrOpinionAttrVal.modalOpen(params);
    },
    modalOpenPR(surveyId, opinionClassificationId) {
      const params = {
        survey_id: surveyId,
        question_id: this.questionId,
        organization_id: this.organizationId,
        opinion_classification_id: opinionClassificationId,
        display_type: this.displayType
      };
      this.$refs.modalPrOpinionAttrVal.modalOpen(params);
    },
    toOpinionSearch(
      opinionClassificationId,
      emotionClassificationId,
      surveyId = undefined,
      organizationId = undefined
    ) {
      const vcd = defaultCookie.get();
      this.$router.push({
        name: 'u_qualitative_opinion_search',
        params: {
          opinion_classification: opinionClassificationId,
          emotion_classification: emotionClassificationId,
          attribute_values: this.attributeValueIds,
          survey_id: surveyId ? surveyId : vcd['surveyId'] || this.surveyId,
          organization_id: organizationId
            ? organizationId
            : this.organizationId,
          backToName: 'u_qualitative_analyse_team'
        }
      });
    },

    // changeAttributeValueIds(attributeValueArr) {
    //   this.attributeValueIds = attributeValueArr[0];
    //   this.attributeValueNames = attributeValueArr[1];
    //   const params = {
    //     survey_id: this.surveyId,
    //     organization_id: this.organizationId,
    //     attribute_value_ids: this.attributeValueIds
    //   };
    //   this.loadOpinionTendencies(params);
    //   this.loadOpinionTrends(params);
    // },

    setDisplayType(type) {
      this.displayType = type;
    },

    setDefaultDisplayType() {
      const vcd = defaultCookie.get();
      this.displayType = vcd['displayType'] || 0;
    },

    calculateFormula() {
      const txt =
        this.displayType === 0
          ? '該当チームの各意見分類における該当感情分類の意見数/該当チームの全意見数'
          : '該当チームの各意見分類における「感情分類ごとの意見数/全意見数」';
      return txt;
    },

    preLoad(type) {
      setTimeout(() => {
        if (
          this.isDisplay['opinion_classifications'].includes(this.questionId)
        ) {
          if (type === 'surveyId') {
            const vcd = defaultCookie.get();
            this.loadQuestions({ survey_id: vcd['surveyId'] || this.surveyId });
          } else if (type === 'questionId') this.load();
        } else {
          this.$router.push({
            name: 'u_qualitative_emotion_classifications_team'
          });
        }
      }, 1000);
    }
  }
};
</script>
<style scoped>
.vc-parameter-nav .vc-ranking-btn {
  /* width: 50px; */
  text-align: center;
}
.vc ul.vc-parameter-nav li a.vpn-all:hover {
  background: rgba(243, 21, 74, 0.5) !important;
}
.vc ul.vc-parameter-nav li a.vpn-all.active:hover {
  background: rgba(243, 21, 74, 1) !important;
}
.vc ul.vc-parameter-nav li a.vpn-all.active {
  background: #f3154a !important;
}
</style>
