<template lang="pug">
  b-modal(
    id="network-delete-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    :title="$t('admin.coOccurrenceNetworks.delete.title')"
  )
    b-container
      p
        | {{ deleteTarget.project_name || '「プロジェクト名指定なし」（ID：' + deleteTarget.id + '）' }}
        | を削除します。
      p
        | 本当によろしいですか？
    div.text-center.w-100(slot="modal-footer")
      button.btn.btn-danger(@click.once="destroy")
        | はい
      button.btn.btn-secondary.ml-2(@click="close")
        | いいえ
</template>

<script>
export default {
  props: {
    deleteTarget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    close() {
      this.$bvModal.hide('network-delete-modal');
    },

    destroy() {
      this.$emit('remove', this.deleteTarget);
      this.$bvModal.hide('network-delete-modal');
    }
  }
};
</script>
