<template lang="pug">
  b-modal(
    id="user-unlock-modal"
    centered
    no-close-on-backdrop=true
    no-close-on-esc=true
    :title="$t('admin.users.unlock.title')"
  )
    b-container
      p
        | {{$t('actions.unlockConfirm')}}
      p
        span.text-danger
          | {{ unlockTarget.name }}
    div(slot="modal-footer")
      button.btn.btn-secondary(@click="close")
        | {{$t('actions.close')}}
      button.btn.btn-danger.ml-2(@click.once="destroy")
        | {{$t('actions.unlock')}}
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    unlockTarget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions('resources/x_users', ['unlockUser']),

    close() {
      this.$bvModal.hide('user-unlock-modal');
    },

    destroy() {
      this.unlockUser(this.unlockTarget.id).then(response => {
        if (response.status === 200) {
          this.$bvModal.hide('user-unlock-modal');
          this.$router.go({ name: 'x_users' });
        }
      });
    }
  }
};
</script>
