<template lang="pug">
  .vc.container-fruid
    .main-panel(v-if="Object.keys(request).length > 1")
      section.vc-content
        .vc-main-container.container
          .vc-content-col
            .container.classification-request-items
              .row
                .col-12
                  h3.mb-3
                    | 分類修正リクエスト詳細
                  hr.vc-full-divider
              .row.mb-4
                .col-12
                  .label
                    | 調査名
                  | ：  {{ request.survey.name }}
                .col-12
                  .label
                    | 申請ユーザー
                  | ：  {{ request.requester.name }}（{{ request.requester.email }}）
              .row(v-show="request.status || request.status === 0")
                .col-12
                  .status
                    | {{ status(request.status) }}
              hr.hr
              .row
                .col-12
                  p.font-weight-bold 回答全文
                    p.mb-4
                      span
                        | {{ request.full_text }}
              .dashboard-comp-table-col.vc-dashboard-box-table-wrap.opinion-search-comp-table-col.mb-4
                .row.no-gutters.vc-respondent-table.text-left.font-weight-bold
                  .col-5
                  .col-7
                    .row.no-gutters
                      .col-6
                        | {{ isOpinionClassification ? '意見分類' : '' }}
                      .col-6 感情分類
                .row.no-gutters.vc-respondent-table.vc-respondent-table-header.text-left.font-weight-bold
                  .col-5 意見
                  .col-7
                    .row.no-gutters
                      .col-3
                        | {{ isOpinionClassification ? '現在の分類' : '' }}
                      .col-3
                        | {{ isOpinionClassification ? '修正後の分類' : '' }}
                      .col-3 現在の分類
                      .col-3 修正後の分類
                .vc-dashboard-box-table-data-row.no-scroll
                  .row.no-gutters.vc-respondent-table.with-border.text-left(v-for="item in request.items")
                    .col-5.pr-3.align-self-center
                      | {{ item.opinion.sentence }}
                    .col-7
                      .row.no-gutters.vc-respondent-table-num
                        .col-3.pr-3.request-classification.align-self-center(v-if="isOpinionClassification")
                          | {{ item.before_opinion_classification.name }}
                        .col-3.pr-3.request-classification(v-else)
                        .col-3.pr-3.request-classification(v-if="isOpinionClassification")
                          select.form-control(
                            v-if="request.status === 0"
                            v-model="newOpinionClassification[item.opinion.id]"
                            @change="setIsEmpty"
                          )
                            option(key="0" value="0") 変更なし
                            option(
                              v-for="opi in opinionClassifications"
                              :key="opi.id"
                              :value="opi.id"
                            )
                              | {{opi.name}}
                          span(v-else)
                            | {{ requestOpinionClassification(item) }}
                        .col-3.pr-3.request-classification(v-else)
                        .col-3.pr-3.align-self-center.request-classification
                          | {{ item.before_emotion_classification.name }}
                        .col-3.pr-3.request-classification
                          select.form-control(
                            v-if="request.status === 0"
                            v-model="newEmotionClassification[item.opinion.id]"
                            @change="setIsEmpty"
                          )
                            option(key="0" value="0") 変更なし
                            option(
                              v-for="emo in emotionClassifications"
                              :key="emo.id"
                              :value="emo.id"
                            )
                              | {{emo.name}}
                          span(v-else)
                            | {{ requestEmotionClassification(item) }}
              .row
                .col-12.text-right
                  button.btn.vc-primary-btn.gray.mr-2(@click="back") 戻る
                  button.btn.vc-primary-btn.submit(
                    v-show="request.status === 0"
                    :disabled="processing || isEmpty"
                    :class="{'btn-disabled': processing || isEmpty}"
                    @click.prevent="submit"
                  )
                    | 修正
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      isOpinionClassification: true,
      newOpinionClassification: {},
      newEmotionClassification: {},
      processing: false,
      isEmpty: true
    };
  },

  computed: {
    ...mapState('resources/x_classification_request_items', [
      'request',
      'opinionClassifications',
      'emotionClassifications'
    ])
  },

  created() {
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_classification_request_items', [
      'loadRequest',
      'updateClassifications'
    ]),

    load() {
      this.loadRequest({ id: this.$route.params.id }).then(response => {
        for (let idx = 0; idx < this.request.items.length; idx++) {
          const item = this.request.items[idx];
          const opiClasses = item.opinion_classification;
          const emoClasses = item.emotion_classification;
          this.newOpinionClassification[item.opinion.id] = opiClasses
            ? opiClasses.id
            : 0;
          this.newEmotionClassification[item.opinion.id] = emoClasses
            ? emoClasses.id
            : 0;
        }
        this.isOpinionClassification = response.body.is_opi_classification;
        this.setIsEmpty();
      });
    },

    status(status) {
      if (status === 0) return '申請中';
      else if (status === 1) return '修正済';
      else return '申請中';
    },

    requestOpinionClassification(item) {
      let oc = item.before_opinion_classification;
      let req_oc = item.opinion_classification;
      if (req_oc) {
        if (req_oc.name === oc.name) return '変更なし';
        else return req_oc.name;
      } else {
        return '変更なし';
      }
    },

    requestEmotionClassification(item) {
      let ec = item.before_emotion_classification;
      let req_ec = item.emotion_classification;
      if (req_ec) {
        if (req_ec.name === ec.name) return '変更なし';
        else return req_ec.name;
      } else {
        return '変更なし';
      }
    },

    back() {
      this.$router.push({ name: 'x_classification_requests' });
    },

    submit() {
      this.processing = true;
      const params = {
        id: this.$route.params.id,
        new_opinion_classifications: this.newOpinionClassification,
        new_emotion_classifications: this.newEmotionClassification
      };
      this.updateClassifications(params).then(() => {
        this.processing = false;
        this.$router.push({ name: 'x_classification_requests' });
      });
    },

    setIsEmpty() {
      this.isEmpty =
        Object.values(this.newOpinionClassification).filter(oc => oc != 0)
          .length < 1 &&
        Object.values(this.newEmotionClassification).filter(ec => ec != 0)
          .length < 1;
    }
  }
};
</script>
<style scoped>
.classification-request-items .label {
  flex: none;
  width: 100px;
  display: inline-block;
}
.classification-request-items .status {
  flex: none;
  width: 90px;
  padding: 0.2rem 0.5rem;
  background-color: #f3154a;
  color: #fff;
  text-align: center;
}
.classification-request-items .hr {
  border-top: 1px solid darkgray;
}
.vc
  .classification-request-items
  .vc-respondent-table.vc-respondent-table-header,
.vc .classification-request-items .vc-respondent-table:last-child {
  border-bottom: 1px solid darkgray;
}
.vc .classification-request-items .vc-respondent-table.with-border {
  border-bottom: 1px solid #f0f0f0;
}
.vc .vc-primary-btn.submit {
  width: 150px;
}
.vc .vc-primary-btn.gray,
.vc .vc-primary-btn.gray:hover {
  width: 150px;
  background: lightgray !important;
  color: #fff;
  border: 1px solid lightgray !important;
}
.request-classification {
  padding: 0.4375rem 0;
}
</style>
