<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | メルマガ詳細
  .row.flex-grow
    .col-12
      .card
        .card-body
          form
            .form-group
              label
                | アカウントグループ
              select.form-control(v-model="accountGroupName" disabled)
                option(
                  :key="accountGroupName"
                  :value="accountGroupName"
                )
                  | {{accountGroupName}}

            .form-group
              label
                | 調査
              select.form-control(v-model="surveyName" disabled)
                option(
                  :key="surveyName"
                  :value="surveyName"
                )
                  | {{surveyName}}

            .form-group
              label
                | テンプレ選択
              select.form-control(v-model="template" disabled)
                option(
                  :key="template"
                  :value="template"
                )
                  | {{template ? template.name : ''}}

            .row
              .col-6
                .form-group
                  label
                    | 送信者アドレス
                  input.form-control(
                    type="text"
                    v-model="senderEmail"
                    readonly
                  )
              .col-6
                .form-group
                  label
                    | 送信者名
                  input.form-control(
                    type="text"
                    v-model="senderName"
                    readonly
                  )

            .form-group
              label
                | 宛先アドレス
              input.form-control(
                type="text"
                v-model="recipients"
                readonly
              )

            .form-group
              label
                | タイトル
              input.form-control(
                type="text"
                v-model="name"
                readonly
              )

            .form-group
              label
                | サムネイル画像
              input.form-control(
                type="text"
                v-model="thumbnailFileTxt"
                readonly
              )

            .form-group
              label
                | 本文
              textarea.form-control(
                rows='30'
                v-model="body"
                readonly
              )

          .mt-5
            router-link.btn.btn-outline-secondary.mr-2.action-btns(
              :to="{ name: 'x_newsletters' }"
            )
              | 戻る

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},
  data() {
    return {
      accountGroupName: '',
      surveyName: '',
      template: '',
      senderEmail: '',
      senderName: '',
      recipients: '',
      name: '',
      body: '',
      thumbnailFileTxt: ''
    };
  },
  computed: {
    ...mapState('resources/x_newsletters', ['newsletter'])
  },
  created() {
    const newsletterId = this.$route.params.id;
    this.editNewsletter(newsletterId).then(response => {
      this.accountGroupName = response.body.newsletter.account_group_name;
      this.surveyName = response.body.newsletter.survey_name;
      this.template = response.body.newsletter.template;
      this.senderEmail = response.body.newsletter.sender_email;
      this.senderName = response.body.newsletter.sender_name;
      this.recipients = response.body.newsletter.recipients;
      this.name = response.body.newsletter.name;
      this.body = response.body.newsletter.body;
      this.thumbnailFileTxt = response.body.newsletter.thumbnail_name;
    });
  },
  mounted() {},
  methods: {
    ...mapActions('resources/x_newsletters', ['editNewsletter'])
  }
};
</script>
<style scoped>
.form-control[readonly] {
  background-color: #fff;
}
.action-btns {
  width: 120px;
}
select.form-control:disabled {
  background-color: #fff !important;
}
</style>
