<template lang="pug">
.content-wrapper(v-if="started")
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | {{ $t('admin.coOccurrenceNetworks.new.title') }}
  .row.flex-grow
    .col-8
      .card
        .card-body.vc(v-if="errorMessages.length > 0 || message")
          .row
            .col-12
              error-message(:errors="errorMessages" v-show="errorMessages.length > 0")
              ul.alert.alert-success(v-show="message")
                button.close.vc-message-close(@click="resetMessages")
                  span(aria-hidden='true') ×
                li.ml-1
                  | {{ message }}
        .card-body.vc
          .form-group
            p.mb-0 インテグレーションワード（カンマ区切りで入力）
            small 固有名詞や企業独自の言い回しなどを一つの単語として扱えるよう指定できます。
            textarea.form-control.mb-2(
              placeholder="インテグレーションワードを入力してください"
              rows="3"
              v-model="integrationWords"
            )
          .row.justify-content-end
            .col-4.text-right
              a.cc-primary-btn.cc-inline-btn.action-btns(href="" @click.prevent="request") 形態素解析API実行
          .row.justify-content-end.transition-link-wrap
            .col-4.text-right
              router-link.link.transition-link(
                :to="{ name: 'x_generate_image_co_occurrence_networks' }"
                :class="{ 'disable-link': processing || !tokenized }"
                :is="(processing || !tokenized) ? 'span' : 'router-link'"
              )
                | 共起ネットワーク画面へ
          .mt-5(v-if="tokenized")
            a.link(href="" @click.prevent="exportCsv")
              | 形態素解析の結果をCSVで出力する
          .vc-content-main-wrap.mt-5(v-else)
            .row.align-items-center.mb-1
              .col-6
                p 形態素解析APIの実行後表示できます。


</template>
<script>
import { mapState, mapActions } from 'vuex';
import ErrorMessage from 'src/components/ErrorMessage';
import i18n from '../../../locales/index';

export default {
  components: {
    ErrorMessage
  },
  data() {
    return {
      integrationWords: '',
      processing: false,
      started: false,
      filerId: '',
      tokenized: '',
      errorMessages: [],
      message: ''
    };
  },
  computed: {
    ...mapState('resources/x_co_occurrence_networks', ['network'])
  },
  created() {
    this.load();
  },
  mounted() {},
  methods: {
    ...mapActions('resources/x_co_occurrence_networks', [
      'loadRequestApi',
      'requestApi'
    ]),
    load() {
      const id = this.$route.params.id;
      this.loadRequestApi(id).then(response => {
        this.integrationWords = response.body.network.integration_words;
        const tokenized = response.body.network.tokenized;
        this.tokenized = tokenized
          ? tokenized.join(' ').replace(/ /g, '\n')
          : '';
        this.filerId = response.body.filer_id;
        this.started = true;
      });
    },
    request() {
      this.resetMessages();
      const id = this.$route.params.id;
      const params = {
        id: id,
        integration_words: this.integrationWords
      };
      this.requestApi(params).then(response => {
        if (response.status === 200) {
          this.message = i18n.tc('admin.surveys.messages.processing');
        } else if (response.status === 422) {
          response.json().then(body => {
            this.errorMessages = body.errors;
          });
        }
      });
    },
    exportCsv() {
      const hash = {
        name: 'x_download_csv_co_occurrence_networks',
        params: {
          id: this.network.id,
          filer_id: this.filerId
        }
      };
      let routeData = this.$router.resolve(hash);
      window.open(routeData.href, '_blank');
    },
    resetMessages() {
      this.errorMessages = [];
      this.message = '';
    }
  }
};
</script>
<style scoped>
.action-btns {
  width: 200px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.link {
  color: #4bafaf;
  font-size: 0.85rem;
}
.link:hover {
  color: #4bafaf;
}
.transition-link {
  font-size: 1rem;
}
.transition-link-wrap {
  margin-top: 50px;
}
.disable-link {
  cursor: not-allowed;
}
textarea {
  background-color: #fff !important;
}
</style>
