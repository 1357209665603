<template lang="pug">
  .container(v-show="started")
    .row
      .col-12(v-if="this.$route.path.indexOf('qualitative')>0")
        .vc-analyse-3rd-tab(v-if="this.$route.path.indexOf('emotion_classifications')>0")
          router-link.nav-link.vc-analyse-3rd-tab-item(:to="{ name: 'u_qualitative_emotion_classifications_team' }") チーム別
          router-link.nav-link.vc-analyse-3rd-tab-item(:to="{ name: 'u_qualitative_emotion_classifications_attribute_values' }") 属性別
        .vc-analyse-3rd-tab.float-left.mr-5(v-if="this.$route.path.indexOf('analyse')>0")
          .category 俯瞰分析
          router-link.nav-link.vc-analyse-3rd-tab-item(:to="{ name: 'u_qualitative_analyse_matrix' }") 課題分布
          router-link.nav-link.vc-analyse-3rd-tab-item(:to="{ name: 'u_qualitative_analyse_rankings' }") ランキング
        .vc-analyse-3rd-tab(v-if="this.$route.path.indexOf('analyse')>0")
          .category チーム別分析
          router-link.nav-link.vc-analyse-3rd-tab-item(:to="{ name: 'u_qualitative_analyse_team' }") チーム別分析
          router-link.nav-link.vc-analyse-3rd-tab-item(:to="{ name: 'u_qualitative_analyse_compare' }") チーム比較分析
    .row.mt-3
      .col-12(v-if="this.$route.path.indexOf('qualitative')>0")
        .vc-analyse-3rd-tab(v-if="this.$route.path.indexOf('matrix')>0")
          router-link.nav-link.vc-analyse-3rd-tab-item(:to="{ name: 'u_qualitative_analyse_matrix' }") チーム別
          router-link.nav-link.vc-analyse-3rd-tab-item(:to="{ name: 'u_qualitative_analyse_matrix_attrs' }") 属性別
        .vc-analyse-3rd-tab(v-if="this.$route.path.indexOf('heatmap')>0")
          router-link.nav-link.vc-analyse-3rd-tab-item(:to="{ name: 'u_qualitative_analyse_heatmap' }") チーム別
          router-link.nav-link.vc-analyse-3rd-tab-item(:to="{ name: 'u_qualitative_analyse_heatmap_attribute_values' }") 属性別
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('resources/u_analyse', ['started'])
  }
};
</script>
<style scoped>
.vc-analyse-3rd-tab-wrap .vc-analyse-3rd-tab .category {
  background-color: #f2f2f2;
  color: #444;
  font-weight: 700;
  display: block;
  padding: 10px;
  border-right: 1px solid #dfdfdf;
}
</style>
