<template lang="pug">
  ul.alert.alert-success
    li.ml-1(v-for="msg in messages")
      | {{ msg }}
</template>

<script>
export default {
  props: {
    messages: { type: Array, required: true }
  }
};
</script>

<style scoped lang="scss"></style>
