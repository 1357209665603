<template lang="pug">
  .content-wrapper
    .content-header.d-flex.flex-column.flex-md-row
      h4.mb-0
        | {{ $t('admin.admins.title') }}
      .wrapper.ml-0.ml-md-auto.my-auto.d-flex.align-items-center.pt-4.pt-md-0
        router-link.btn.btn-success.btn-sm.ml-auto(:to="{ name: 'x_new_admin' }")
          | {{ $t('admin.admins.index.new') }}
    .row
      .col-md-12.grid-margin.stretch-card.clearfix
        .card
          .card-body.pb-0
            .table-responsive
              table.table.table-striped
                thead
                  tr
                    th
                      | {{ $t('admin.admins.id') }}
                    th
                      | {{ $t('admin.admins.name') }}
                    th
                      | {{ $t('admin.admins.email') }}
                    th &nbsp
                    th &nbsp
                tbody
                  tr(v-for="admin in adminsList")
                    td
                      | {{ admin.id }}
                    td
                      | {{ admin.name }}
                    td
                      | {{ admin.email }}
                    td
                      router-link.btn.btn-outline-primary(
                        :to="{ name: 'x_edit_admin', params : { id: admin.id } }")
                          | {{ $t('actions.edit') }}
                    td
                      button.btn.btn-outline-danger(@click="showDeleteModal(admin)")
                        | {{ $t('actions.delete') }}

            .mt-1.float-right
              paginate(
                :page-count="pageParams.pages"
                :click-handler="clickCallback"
                containerClass="pagination"
                pageClass="page-item"
                pageLinkClass="page-link"
                prevText="«"
                prevClass="page-item"
                prevLinkClass="page-link"
                nextText="»"
                nextClass="page-item"
                nextLinkClass="page-link"
              )

    AdminDeleteModal(:deleteTarget="deleteTarget")
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Paginate from 'vuejs-paginate';
import AdminDeleteModal from 'src/components/x/admins/AdminDeleteModal';

export default {
  components: {
    Paginate,
    AdminDeleteModal
  },

  data() {
    return {
      defaultPer: 10,
      deleteTarget: {}
    };
  },

  computed: {
    ...mapState('resources/x_admins', ['adminsList']),
    ...mapState('pages/x_admins/list/pagination', ['pageParams'])
  },

  created() {
    this.setPer(this.defaultPer);
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_admins', ['loadAdmins']),
    ...mapMutations('pages/x_admins/list/pagination', [
      'setTotal',
      'setPages',
      'setPage',
      'setPer'
    ]),

    /**
     * ユーザー一覧の更新
     *
     * @return { void }
     */
    load() {
      this.loadAdmins(this.pageParams).then(response => {
        this.setTotal(response.body.page_options.total);
        this.setPages(response.body.page_options.pages);
      });
    },

    clickCallback: function(pageNum) {
      this.setPage(pageNum);
      this.load();
    },

    /**
     * 削除モーダルを表示する
     *
     * @return {void}
     */
    showDeleteModal(admin) {
      this.deleteTarget = admin;
      this.$bvModal.show('admin-delete-modal');
    }
  }
};
</script>
