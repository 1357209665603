var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"row flex-grow"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('admin.users.edit.title')))]),(_vm.ready)?_c('form',[(_vm.errorMessages.length > 0)?_c('error-message',{attrs:{"errors":_vm.errorMessages}}):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v(_vm._s(_vm.$t('admin.users.name')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userName),expression:"userName"}],class:{ 'form-control': true, 'form-control-danger': errors.length},attrs:{"type":"text","placeholder":"Suzuki Taro"},domProps:{"value":(_vm.userName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.userName=$event.target.value}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v(_vm._s(_vm.$t('admin.users.email')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userEmail),expression:"userEmail"}],class:{ 'form-control': true, 'form-control-danger': errors.length},attrs:{"type":"email","placeholder":"admin@example.com"},domProps:{"value":(_vm.userEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.userEmail=$event.target.value}}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('admin.users.mainAccountGroup')))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.accountGroupId),expression:"accountGroupId"}],staticClass:"form-control",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.accountGroupId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.changedAccountGroupId]}},_vm._l((_vm.accountGroups),function(accountGroup){return _c('option',{key:accountGroup.id,domProps:{"value":accountGroup.id}},[_vm._v(_vm._s(accountGroup.name))])}),0)]),_c('validation-provider',{attrs:{"name":"roleId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-danger': errors.length}},[_c('label',[_vm._v(_vm._s(_vm.$t('admin.users.role')))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.roleId),expression:"roleId"}],class:{ 'form-control': true, 'form-control-danger': errors.length },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.roleId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.roles[_vm.accountGroupId]),function(role){return _c('option',{key:role.id,domProps:{"value":role.id}},[_vm._v(_vm._s(role.name))])}),0),_c('label',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error mt-2 text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('admin.users.accountGroups')))]),_c('div',{staticClass:"account-groups-wrap"},_vm._l((_vm.accountGroups),function(account){return _c('div',{staticClass:"small",on:{"click":function($event){return _vm.addRemoveAccount(account)}}},[_vm._v(_vm._s(account.name)),_c('i',{staticClass:"fas fa-minus-circle ml-1"})])}),0)]),_c('div',{staticClass:"mt-1 float-right"},[_c('router-link',{staticClass:"btn btn-light mr-2",attrs:{"to":{ name: 'x_users' }}},[_vm._v(_vm._s(_vm.$t('actions.cancel')))]),_c('button',{staticClass:"btn btn-success",attrs:{"disabled":_vm.processing || invalid},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v(_vm._s(_vm.$t('actions.update')))])],1)]}}],null,false,2352102474)})],1):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }