<template lang="pug">
  .row.mt-2(v-if="started")
    .col-8(v-if="this.$route.path.indexOf('quantitative')>0 || this.$route.path.indexOf('qualitative')>0")
      .vc-analyse-second-tab-wrap.vc-second-menu-wrap
        .row
          .col-3.vc-tab-select-wrap.margin-left-10(v-show="!(this.$route.path.indexOf('quantitative')>0)")
            select.form-control(v-model="currentQuestionId")
              option(
                v-for="question in questions"
                :value="question.id"
                :key="question.id"
              )
                | {{ question.title }}
          div(:class="classForSecondTab()")
            .vc-analyse-second-tab(v-if="this.$route.path.indexOf('quantitative')>0")
              router-link.nav-link.vc-midashi(:to="{ name: 'u_quantitative_analyse' }")
                | 集計
              router-link.nav-link.vc-midashi(:to="{ name: 'u_quantitative_analyse_changes' }" :class="{'btn-disabled': !isDisplay['analyse']}")
                | 集計（推移）
              router-link.nav-link.vc-midashi(:to="{ name: 'u_quantitative_calculated_analyse' }" :class="{'btn-disabled': !isDisplay['analyse']}" v-show="isDisplay['custom_analyse']")
                | カスタム集計
              router-link.nav-link.vc-midashi(:to="{ name: 'u_quantitative_calculated_analyse_changes' }" v-show="isDisplay['custom_analyse']")
                | カスタム集計（推移）
            .vc-analyse-second-tab(v-if="this.$route.path.indexOf('qualitative')>0")
              router-link.nav-link.vc-midashi(
                :to="{ name: 'u_qualitative_emotion_classifications_team' }"
                :class="{'router-link-exact-active': this.$route.path.indexOf('emotion_classifications')>0, 'router-link-active': this.$route.path.indexOf('emotion_classifications')>0}"
              )
                | 感情分析
              router-link.nav-link.vc-midashi(:to="{ name: textMiningLink() }" v-show="isDisplayTm")
                | テキストマイニング分析
              router-link.nav-link.vc-midashi(
                :to="{ name: 'u_qualitative_analyse_matrix' }"
                :class="{'router-link-exact-active': this.$route.path.indexOf('analyse')>0, 'router-link-active': this.$route.path.indexOf('analyse')>0}"
                v-show="isDisplayOc"
              )
                | 組織課題分析
              router-link.nav-link.vc-midashi(:to="{ name: 'u_qualitative_opinion_search' }")
                | 意見検索
    .col-4.text-right(v-if="(isQualitative && currentQuestion) || (!isQualitative && currentSurvey)")
      .vc-main-select-wrap
        .survey-data.d-block
          span
            | 分析対象意見数
          span.survey-data-value.mr-4
            | {{isQualitative ? currentQuestion.opinions_count : currentSurvey.opinions_count | separate}}
          span
            | 回答者数
          span.survey-data-value.mr-4
            | {{isQualitative ? currentQuestion.qualitative_responses_count : currentSurvey.survey_responses_count | separate}}

</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import dc from '../../lib/default_cookie';
const defaultCookie = new dc();

export default {
  components: {},
  props: {},
  data() {
    return {
      currentSurvey: null,
      currentQuestionId: '',
      isDisplayOc: false,
      isDisplayTm: false,
      currentQuestion: null,
      isQualitative: true
    };
  },
  computed: {
    ...mapState('resources/u_quantitative', ['questions']),
    ...mapState('resources/u_analyse', ['isDisplay', 'started']),
    ...mapState('pages/u_surveys/select', ['surveyId'])
  },
  watch: {
    questions() {
      if (this.questions.length > 0) {
        const vcd = defaultCookie.get();
        let questionId = '';
        const ids = this.questions.map(q => {
          return q.id;
        });
        if (vcd && ids.includes(vcd['questionId']))
          questionId = vcd['questionId'];
        else questionId = this.questions[0].id;
        this.currentQuestionId = questionId;
      }
    },
    currentQuestionId() {
      const vcd = defaultCookie.get();
      if (
        this.currentQuestionId &&
        !(this.$route.path.indexOf('quantitative') > 0)
      )
        this.loadDisplayMenu(this.currentQuestionId);
      const ids = this.questions.map(q => {
        return q.id;
      });
      if (!ids.includes(this.currentQuestionId)) {
        ids.sort();
        this.currentQuestionId = ids[0];
        this.currentQuestion = this.questions.find(q => q.id === ids[0]);
        return;
      }
      this.currentQuestion = this.questions.find(
        q => q.id === this.currentQuestionId
      );
      if (
        (vcd && this.currentQuestionId !== vcd['questionId']) ||
        !vcd ||
        !vcd['questionId']
      )
        defaultCookie.set({ questionId: this.currentQuestionId });
      this.setQuestionId(this.currentQuestionId);
    },
    surveyId() {
      this.setStarted(true);
    },
    isDisplay() {
      this.isDisplayOc = this.isDisplay['opinion_classifications'].includes(
        this.currentQuestionId
      );
      this.isDisplayTm = this.isDisplay['text_mining'].includes(
        this.currentQuestionId
      );
      this.currentSurvey = this.isDisplay['survey'];
    },
    isDisplayTm() {
      this.setStarted(true);
    },
    isDisplayOc() {
      this.setStarted(true);
    }
  },
  created() {
    this.setIsQualitative();
    const vcd = defaultCookie.get();
    if (vcd && vcd['surveyId']) {
      this.loadDisplayMenu(vcd['surveyId']);
    }
  },
  mounted() {
    if (this.questions.length > 0) {
      // this.currentQuestionId = this.questions[0].id;
    }
  },
  methods: {
    ...mapActions('resources/u_analyse', ['loadDisplayMenu']),
    ...mapMutations('resources/u_quantitative', ['setQuestionId']),
    ...mapMutations('resources/u_analyse', ['setStarted']),
    classForSecondTab() {
      const classes = {
        'col-12': this.$route.path.indexOf('quantitative') > 0,
        'nav-without-question': this.$route.path.indexOf('quantitative') > 0,
        'col-8': !(this.$route.path.indexOf('quantitative') > 0),
        'margin-left-10': this.$route.path.indexOf('quantitative') > 0,
        'ml-5': !(this.$route.path.indexOf('quantitative') > 0)
      };
      return classes;
    },
    setIsQualitative() {
      this.isQualitative = this.$route.path.indexOf('qualitative') > 0;
    },
    textMiningLink() {
      const type = this.isDisplay['co_occurrence_type'];
      return type === 'khcoder'
        ? 'u_qualitative_text_mining'
        : 'u_qualitative_co_occurrences';
    }
  }
};
</script>
<style scoped>
.vc-analyse-top-tab-wrap {
  padding: 0 0 10px 0;
}
.vc-analyse-top-tab {
  display: flex;
}
.vc-analyse-second-tab a {
  display: inline-block;
  color: #444;
  font-size: 12px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #f0f0f0;
  padding: 0 25px;
  transition: all 0.15s ease;
}
.vc-analyse-second-tab-wrap {
  padding: 0 !important;
}
.vc-analyse-second-tab {
  display: flex;
  display: flex;
  height: 80%;
}
/* .vc-analyse-second-tab a {
  display: inline-block;
  color: #444;
  font-size: 17px;
  padding: 0 15px;
  transition: all 0.15s ease;
  align-self: flex-end;
} */
.nav-without-question {
  margin-left: -5px;
}
.vc-analyse-top-tab a.router-link-exact-active.qualitative-link {
  margin-left: 0.75rem !important;
}
.margin-left-10 {
  margin-left: 0.81rem !important;
}
.vc-analyse-second-tab-wrap select {
  font-size: 17px;
}
.vc-analyse-second-tab-wrap .vc-tab-select-wrap::after {
  width: 12px !important;
  height: 12px !important;
  top: 40% !important;
  right: -12% !important;
}
.vc-tab-select-wrap select {
  line-height: 17px;
}
.vc-analyse-second-tab-wrap.vc-second-menu-wrap {
  padding-bottom: 10px !important;
}
</style>
