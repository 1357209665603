<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | {{ $t('admin.chatGPTClassifications.list.title') }}
    .wrapper.ml-0.ml-md-auto.my-auto.d-flex.align-items-center.pt-4.pt-md-0
      router-link.btn.btn-success.btn-sm.ml-auto(:to="{ name: 'x_new_chatgpt_classification' }")
        | {{ $t('admin.chatGPTClassifications.list.new') }}

  .row.flex-grow
    .col-12
      .card
        .card-body.pb-0
          .table-responsive
            table.table.table-striped.admin-table
              thead
                tr
                  th
                    | {{ $t('admin.chatGPTClassifications.list.id') }}
                  th
                    | {{ $t('admin.chatGPTClassifications.list.surveyId') }}
                  th
                    | {{ $t('admin.chatGPTClassifications.list.questionId') }}
                  th
                    | {{ $t('admin.chatGPTClassifications.list.labelsNum') }}
                  th
                    | {{ $t('admin.chatGPTClassifications.list.status') }}
                  th
                    | {{ $t('admin.chatGPTClassifications.list.createdAt') }}
                  th
                    | {{ $t('admin.chatGPTClassifications.list.errorMessage') }}
              tbody
                tr(v-for="task in taskList")
                  td.text-right
                    | {{ task.id }}
                  td.text-right
                    | {{ task.survey_id }}
                  td.text-right
                    | {{ task.question_id }}
                  td.text-right
                    | {{ task.labels_num }}
                  td.text-center
                    | {{ task.status }}
                  td.text-center
                    | {{ task.created_at | moment('YY/MM/DD HH:mm:ss') }}
                  td
                    | {{ task.error_message }}
                  td
                    button.btn.btn-secondary.dropdown
                      a.dropdown-toggle.no-style(
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      )
                        | アクション
                      .dropdown-menu(aria-labelledby="dropdownMenuButton")
                        router-link.dropdown-item.mt-1(
                          :to="{ name: 'x_show_chatgpt_classification', params: { id: task.id } }"
                        )
                          | {{ $t('admin.chatGPTClassifications.list.show') }}

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      defaultPer: 50
    };
  },

  computed: {
    ...mapState('resources/x_chatgpt_classifications', ['taskList'])
  },

  created() {
    this.load();
  },

  mounted() {},

  methods: {
    ...mapActions('resources/x_chatgpt_classifications', ['fetchTasks']),

    load() {
      this.fetchTasks();
    }
  }
};
</script>

<style scoped></style>
