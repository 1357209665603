import Gateway from '../../../../../lib/api_gateway';

const gateway = new Gateway('u_response_ranking');

export default {
  namespaced: true,

  state: {
    loading: true,
    started: false,
    organizationId: '',
    responses: [],
    totalResId: [],
    opinionValues: [],
    currentOpinionValue: '',
    currentOpinionValueName: '',
    currentResponseId: '',
    message: ''
  },

  getters: {},

  actions: {
    loadResponses({ commit }, params) {
      return gateway.get({ action: 'index' }, params).then(response => {
        if (response.status === 200) {
          commit('setResponses', response.body.responses);
          commit('setTotalResId', response.body.responses);
        }
        return response;
      });
    },
    loadOpinionValues({ commit }) {
      return gateway.get({ action: 'opinion_values' }).then(response => {
        if (response.status === 200) {
          commit('setOpinionValues', response.body.opinion_values);
        }
      });
    },
    exportCsv({ commit }, params) {
      return gateway.put({ action: 'export_csv' }, params).then(response => {
        if (response.status === 200) {
          const message = response.body.message;
          commit('setMessage', message);
        }
        return response;
      });
    },
    exportPdf({ commit }, params) {
      return gateway.put({ action: 'export_pdf' }, params).then(response => {
        if (response.status === 200) {
          const message = response.body.message;
          commit('setMessage', message);
        }
        return response;
      });
    }
  },

  mutations: {
    setLoading(state, status) {
      state.loading = status;
    },
    setStarted(state, status) {
      state.started = status;
    },
    setOrganizationId(state, organizationId) {
      state.organizationId = organizationId;
    },
    setResponses(state, responses) {
      state.responses = responses;
    },
    setTotalResId(state, responses) {
      state.totalResId = responses.map(x => x.id);
    },
    setOpinionValues(state, values) {
      state.opinionValues = values;
    },
    setCurrentOpinionValue(state, value) {
      state.currentOpinionValue = value;
    },
    setCurrentOpinionValueName(state, name) {
      state.currentOpinionValueName = name;
    },
    setCurrentResponseId(state, responseId) {
      state.currentResponseId = responseId;
    },
    setMessage(state, message) {
      state.message = message;
    }
  }
};
